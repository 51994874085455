import { Col, Row } from "react-bootstrap";
import { FIELD_TYPES, FieldItem } from "../../widgets/fields";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setLoading } from "../../store/slices/auth";
import { getAnalysisVariables } from "./apiController";
import useFieldItem from "../../widgets/fields/UseFieldItem";

export default function VariableList(props) {
    const dispatch = useDispatch();
    const [variableData, SetVariableData] = useState<any>([]); 
    const {data} = props;
    const searchFields = {
        searchfield: {
            name: "searchfield",
            placeholder: "Search Variable"
        },
        variable: {
            name: "variable",
            placeholder: "Select",
            type: FIELD_TYPES.DROP_DOWN
        }
    }
    const [values, fields, handleChange, { validateValues, reset, updateValue, updateError }] = useFieldItem(searchFields, {}, { onValueChange });
   
    useEffect(() =>{
        console.log(props)
        fetchInitialData();
    },[])

        /**
         * fetch initial data
         */
        const fetchInitialData = async () =>{
            try {
                dispatch(setLoading(true));
                let response = await getAnalysisVariables(data?.formData?.id);
                SetVariableData(response.data);
                dispatch(setLoading(false))
            } catch (e:any) {
                dispatch(setLoading(false))
                console.log(e)
            }
        }


      /**
    * 
    * @param name 
    * @param value 
    * @param values 
    * @param fieldValues 
    * @returns 
    */
      function onValueChange(name, value, values, fieldValues = null) {
        let resetValue = {};
         
          if(value.length > 0){
            SetVariableData(variableData && variableData.filter(item => item.field_name.toLowerCase().includes(value.toLowerCase())))
          }else{
            fetchInitialData()
          } 
       
        return [{
            ...values,
            ...resetValue,
            [name]: value,
        }]
    }


    return (
        <>
            <div className="variable-container">
                <div className="search-bar">
                    <span id="variable-count">Variables({variableData && variableData?.length})</span>
                    <div id="search">
                        <FieldItem
                            {...searchFields.searchfield}
                            value={values.searchfield}
                            type={FIELD_TYPES.SEARCH}
                            onChange={(...e) => handleChange(searchFields.searchfield.name, ...e)}

                        />
                    </div>
                </div>
                {/* {props.analyseType && props?.analyseType?.value == "descriptive" ?  <Row className="m-0 variable-tab"></Row> :
                    <Row className="m-0 variable-tab">
                        <div className={isActive == "numeric" ? "p-2 head-active" : "p-2 head"} onClick={() => getVariables("numeric")}>Numeric</div>
                        <div className={isActive != "numeric" ? "p-2 head-active" : "p-2 head"} onClick={() => getVariables("categorical")}>Categorical</div>
                    </Row>
                } */}
                <div className="variable-list">
                    {
                        variableData && variableData?.map((item,index) =>{
                            return <Row className="m-0">
                            <Col className="items" md={10}>
                                <span id="variable-name">{item.field_name}</span>
                                {/* <span id="variable-value">10 values</span> */}
                            </Col>
                            <Col className="items-add" md={2}>
                                <span id="variable-add" onClick={() => props.addVariable(item)}>Add</span>
                            </Col>
                        </Row>  
                        })
                    }
                                       
                </div>
            </div>
        </>
    )
}