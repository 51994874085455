import { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { deleteComment, getTask, getTaskComments, saveComment, updateComment } from '../../planner/apiController';


const GanttChart = ({ data, userData, scale, setScale, onStatusSave, teamData, onAssignedToSave, getActualBarColors, actualBarsVisible, startrangedate, endrangedate, downloadChartRef,setaction,setCurrentSelectedTask,permissionParams }) => {
  const chartRef = useRef(null);
  const xAxisRef = useRef(null);
  const yAxisRef = useRef(null);
  const contentRef = useRef(null);
  const taskNameRef = useRef(null);
  const scrollContainerRef = useRef(null);
  const toolTipContainerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const chartColors = {
    dayLabelColor: "white",
    weekLabelColor: "white",
    monthLabelColor: "white",
    yearLabelColor: "white"
  }
  const marginTop = window.innerWidth || window.innerHeight <= 700 ? 80 : 40;
  const marginRight = window.innerWidth || window.innerHeight <= 700 ? 30 : 10;
  const marginBottom = window.innerWidth || window.innerHeight <= 700 ? 30 : 10;
  const marginLeft = window.innerWidth || window.innerHeight <= 700 ? 300 : 120;
  const margin = { top: marginTop, right: marginRight, bottom: marginBottom, left: marginLeft };

  useEffect(() => {
    if (data && data.length && chartRef.current) {
      drawChart();
    }
  }, [data, teamData, scale, actualBarsVisible, startrangedate, endrangedate]);



  const drawChart = () => {
    // console.log("Original data:", data);

    //Clear Previous chart
    d3.select(chartRef.current).selectAll("*").remove();
    d3.select(taskNameRef.current).selectAll("*").remove();
    d3.select(xAxisRef.current).selectAll("*").remove();
    d3.select(yAxisRef.current).selectAll("*").remove();
    d3.select(contentRef.current).selectAll("*").remove();
    d3.select(toolTipContainerRef.current).selectAll("*").remove();

    

    // Function to parse a date with multiple formats
    const parseDate = (dateStr) => {
      const date = new Date(dateStr);
      return date;
    };


    //Validate the task data to standard format
    const validData = data.filter(d => {
      const start = parseDate(d.planned_start_date);
      const end = parseDate(d.planned_end_date);
      return start && end && !isNaN(start) && !isNaN(end);
    }).map(d => ({
      ...d,
      plannedStart: parseDate(d.planned_start_date),
      plannedEnd: parseDate(d.planned_end_date),
      actualStart: d.actual_start_date ? parseDate(d.actual_start_date) : null,
      actualEnd: d.actual_end_date ? parseDate(d.actual_end_date) : null
    }));

    // Set the range of the chart i.e starting date and ending date
    const plannedStart = d3.min(validData, d => d.plannedStart);
    const plannedEnd = d3.max(validData, d => d.plannedEnd);
    let minDate;
    let maxDate;
    if (startrangedate && endrangedate) {
      minDate = startrangedate;
      maxDate = endrangedate;
    } else {
      minDate = startrangedate ? startrangedate : plannedStart;
      maxDate = endrangedate ? endrangedate : plannedEnd;
    }

    let startDate, endDate;
    let dayWidth = 40; // Width per day, adjust as needed

    if (scale === 'years') {
      startDate = new Date(minDate.getFullYear(), minDate.getMonth(), 1);
      endDate = new Date(maxDate.getFullYear() + 1, minDate.getMonth(), 0);
      dayWidth = 2;
    } else {
      startDate = new Date(minDate.getFullYear(), minDate.getMonth(), 1);
      endDate = new Date(maxDate.getFullYear(), maxDate.getMonth() + 1, 0);
      if (scale === 'days') {
        dayWidth = 40;
      } else {
        dayWidth = 10;
      }
    }

    if(window.innerWidth <= 700){
      dayWidth = dayWidth/2;
    }

    // Calculate the time range
    const timeRange = d3.timeDay.range(startDate, d3.timeDay.offset(endDate, 1));

    // Calculate dimensions
    const taskHeight = (window.innerWidth || window.innerHeight) <= 700 ? 20 : 60; // Height per task
    const minChartWidth = (window.innerWidth || window.innerHeight) <= 700 ? 800 : 1600;
    const minChartHeight = (window.innerWidth || window.innerHeight) <= 700 ? 300 : 650;

    const chartWidth = Math.max(minChartWidth, timeRange.length * dayWidth + margin.left + margin.right);
    const chartHeight = Math.max(minChartHeight, validData.length * taskHeight + margin.top + margin.bottom);

    const width = chartWidth - margin.left - margin.right;
    const height = chartHeight - margin.top - margin.bottom;


    const x = d3.scaleTime()
      .domain([
        startDate,
        endDate
      ])
      .range([0, width]);

    const y = d3.scaleBand()
      .domain(validData.map(d => d.id))
      .range([0, height])
      .padding(0.55)
      .align(0.55);

    const svg = d3.select(chartRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .attr("class", "svg-comp")
      .style("background", "white")
      .append("g")
      .attr("transform", `translate(0,0)`);

    if (validData.length === 0) {
      svg.append("text")
        .attr("x", width / 2)
        .attr("y", height / 2)
        .attr("text-anchor", "middle")
        .text("No valid data to display. Please check your date formats.");
      return;
    }

    const xAxisSvg = d3.select(xAxisRef.current)
      .append("svg")
      .attr("width", width + margin.right)
      .attr("height", margin.top);

    const yAxisSvg = d3.select(yAxisRef.current)
      .append("svg")
      .attr("width", margin.left)
      .attr("height", height + margin.bottom);

    const contentSvg = d3.select(contentRef.current)
      .attr("class", 'contentSvg')
      .append("svg")
      .attr("width", width + margin.right)
      .attr("height", height + margin.bottom);

    const taskNameSvg = d3.select(taskNameRef.current)
      .append("svg")
      .attr("width", margin.left)
      .attr("height", margin.top);

    taskNameSvg
      .append('rect')
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", margin.left)
      .attr("height", margin.top)
      .attr("fill", "#455570")

    taskNameSvg
      .append('text')
      .attr("x", margin.left / 2)
      .attr("y", margin.top / 2)
      .attr("text-anchor", "middle")
      .text("Task Name")
      .style("fill", "white")
      .style("font-weight", '500')

    // Create custom tick values for months and weeks
    const monthTicks = d3.timeMonths(...x.domain());
    // const weekTicks = d3.timeWeeks(...x.domain());


    let dayTicks;
    if (scale === 'days') {
      dayTicks = d3.timeDays(...x.domain());
    }
    // Calculate the width of each day
    const dayCellWidth = width / d3.timeDays(startDate, endDate).length;

    const truncateText = (text, maxLength) => {
      return text.length > maxLength ? text.slice(0, maxLength) : text;
    };

    const yAxis = d3.axisLeft(y)
      .tickSize(0)
      .tickFormat(d => '');  // Empty string as we'll create custom labels

    yAxisSvg.append("g")
      .attr("class", "y-axis")
      .attr("transform", `translate(${margin.left/2}, 0)`)
      .call(yAxis);

    // Remove the original text elements
    yAxisSvg.selectAll(".y-axis .tick text").remove();

    // Add foreignObject elements with divs for each tick
    yAxisSvg.selectAll(".y-axis .tick")
      .append("foreignObject")
      .attr("x", -margin.left / 2)  // Adjust this value to position the labels
      .attr("y", -taskHeight / 2)
      .attr("width", margin.left)  // Adjust this value to set the maximum width of labels
      .attr("height", taskHeight - 2)
      .append("xhtml:div")
      .style("height", "100%")
      .style("display", "flex")
      .style("align-items", "center")
      .style("justify-content", "center")  // Center the text horizontally
      .style("text-align", "center")  // Ensure text is centered within the div
      .style("padding", "0 5px")  // Add some padding on the sides
      .style("font-family", "-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'")
      .style("font-size", `${taskHeight/4}px`)  // Increased font size
      .style("font-weight", 600)  // Made the font bold
      .style("line-height", "1.2")  // Adjust for appropriate line spacing
      .html(d => {
        const task = validData.find(item => item.id === d);
        return task ? task.task_name : "";
      });

    // Add horizontal lines between tasks on yaxis
    yAxisSvg.selectAll(".task-separator")
      .data(validData.slice(0, -1)) // Exclude the last task
      .enter()
      .append("line")
      .attr("class", "task-separator")
      .attr("x1", 0)
      .attr("x2", margin.left)
      .attr("y1", d => y(d.id) + y.bandwidth() + y.bandwidth() / 2)
      .attr("y2", d => y(d.id) + y.bandwidth() + y.bandwidth() / 2)
      .attr("stroke", "black")
      .attr("stroke-opacity", "0.4")
      .attr("stroke-width", 1);

    // Draw axes
    if (scale === 'days') {
      // Month labels
      xAxisSvg.append("g")
        .attr("class", "x-axis-months")
        .attr("transform", `translate(0, 0)`)
        .selectAll(".month-label")
        .data(monthTicks)
        .enter().append("text")
        .attr("class", "month-label")
        .attr("x", d => x(d) + (x(new Date(d.getFullYear(), d.getMonth() + 1, 1)) - x(d)) / 2)
        .attr("y", margin.top / 3)
        .style("text-anchor", "start")
        .style("fill", chartColors.monthLabelColor)
        .text(d => d3.timeFormat("%B")(d));

      // Day labels
      xAxisSvg.append("g")
        .attr("class", "x-axis-days")
        .attr("transform", `translate(0, 0)`)
        .selectAll(".day-label")
        .data(dayTicks)
        .enter().append("text")
        .attr("class", "day-label")
        .attr("x", d => x(d) + dayCellWidth / 2) // Center within the day's space
        .attr("y", margin.top * 0.85)
        .style("text-anchor", "middle")
        .style("fill", chartColors.dayLabelColor)
        .text(d => d.getDate());

      // Add day separators on xaxis
      xAxisSvg.append("g")
        .attr("class", "day-separators")
        .selectAll("line")
        .data(dayTicks)
        .enter()
        .append("line")
        .attr("x1", d => x(d))
        .attr("x2", d => x(d))
        .attr("y1", margin.top / 2)
        .attr("y2", margin.top)
        .attr("stroke", "#606676")
        .attr("stroke-opacity", 1)
        .attr("stroke-width", 1);


      // Add month seperators on xaxis
      xAxisSvg.append("g")
        .attr("class", "grid-lines")
        .selectAll("line")
        .data(monthTicks)
        .enter()
        .append("line")
        .attr("x1", d => x(d))
        .attr("x2", d => x(d))
        .attr("y1", 0)
        .attr("y2", margin.top)
        .attr("stroke", "#606676")
        .attr("stroke-opacity", 1)
        .attr("stroke-width", 1)

      // Add day separators on contentSvg
      contentSvg.append("g")
        .attr("class", "day-separators")
        .selectAll("line")
        .data(dayTicks)
        .enter()
        .append("line")
        .attr("x1", d => x(d))
        .attr("x2", d => x(d))
        .attr("y1", 0)
        .attr("y2", height + margin.bottom)
        .attr("stroke", "#606676")
        .attr("stroke-opacity", "0.4")
        .attr("stroke-width", 1);


      // Add month seperators on contentSvg
      contentSvg.append("g")
        .attr("class", "grid-lines")
        .selectAll("line")
        .data(monthTicks)
        .enter()
        .append("line")
        .attr("x1", d => x(d))
        .attr("x2", d => x(d))
        .attr("y1", 0)
        .attr("y2", height + margin.bottom)
        .attr("stroke", "#606676")
        .attr("stroke-opacity", "0.8")
        .attr("stroke-width", 2)
    }
    else if (scale === "years") {
      const yearTicks = d3.timeYears(startDate, endDate);
      const monthTicks = d3.timeMonths(startDate, endDate);
      const yearLabelHeight = margin.top / 2;

      // Year labels
      xAxisSvg.append("g")
        .attr("class", "x-axis-years")
        .attr("transform", `translate(0, ${margin.top / 3})`)
        .selectAll(".year-label")
        .data(yearTicks)
        .enter().append("text")
        .attr("class", "year-label")
        .attr("x", d => x(d) + (x(new Date(d.getFullYear() + 1, 0, 1)) - x(d)) / 2)
        .attr("y", 0)
        .style("text-anchor", "start")
        .style("font-size", "14px")
        .style("font-weight", "bold")
        .style("fill", chartColors.yearLabelColor)
        .text(d => d.getFullYear());

      // Month labels (first three letters)
      xAxisSvg.append("g")
        .attr("class", "x-axis-months")
        .attr("transform", `translate(0, ${-margin.top / 5})`)
        .selectAll(".month-label")
        .data(monthTicks)
        .enter().append("text")
        .attr("class", "month-label")
        .attr("x", d => {
          const monthStart = x(d);
          const monthEnd = x(new Date(d.getFullYear(), d.getMonth() + 1, 0));
          return monthStart + (monthEnd - monthStart) / 2;
        })
        .attr("y", margin.top)
        .style("text-anchor", "middle")
        .style("font-size", "16px")
        .style("fill", chartColors.monthLabelColor)
        .text(d => d3.timeFormat("%b")(d));

      // Add year separators to Xaxis
      xAxisSvg.append("g")
        .attr("class", "year-separators")
        .selectAll("line")
        .data(yearTicks)
        .enter()
        .append("line")
        .attr("x1", d => x(d))
        .attr("x2", d => x(d))
        .attr("y1", 0)
        .attr("y2", margin.top)
        .attr("stroke", "#606676")
        .attr("stroke-opacity", 1)
        .attr("stroke-width", 1);

      // Add month separators Xaxis
      xAxisSvg.append("g")
        .attr("class", "month-separators")
        .selectAll("line")
        .data(monthTicks)
        .enter()
        .append("line")
        .attr("x1", d => x(d))
        .attr("x2", d => x(d))
        .attr("y1", margin.top/2)
        .attr("y2", margin.top)
        .attr("stroke", "#606676")
        .attr("stroke-opacity", 1)
        .attr("stroke-width", 1);

      // Add month separators to Content
      contentSvg.append("g")
        .attr("class", "month-separators")
        .selectAll("line")
        .data(monthTicks)
        .enter()
        .append("line")
        .attr("x1", d => x(d))
        .attr("x2", d => x(d))
        .attr("y1", 0)
        .attr("y2", height + margin.bottom)
        .attr("stroke", "#606676")
        .attr("stroke-opacity", "0.4")
        .attr("stroke-width", 1);

      // Add Year separators to content
      contentSvg.append("g")
        .attr("class", "year-separators")
        .selectAll("line")
        .data(yearTicks)
        .enter()
        .append("line")
        .attr("x1", d => x(d))
        .attr("x2", d => x(d))
        .attr("y1", 0)
        .attr("y2", height + margin.bottom)
        .attr("stroke", "#606676")
        .attr("stroke-opacity", "0.8")
        .attr("stroke-width", 1);

    }
    else {

      // Generate week tick positions for each month
      const weekTicks = monthTicks.flatMap(d => {
        const firstDay = new Date(d.getFullYear(), d.getMonth(), 1);
        const nextMonth = new Date(d.getFullYear(), d.getMonth() + 1, 1);
        const daysInMonth = (nextMonth - firstDay) / (1000 * 60 * 60 * 24);
        const weekInterval = daysInMonth / 4;
        return d3.range(0, 4).map(i => new Date(firstDay.getTime() + (weekInterval * i) * (1000 * 60 * 60 * 24)));
      });

      // Create week labels data
      const weekLabels = weekTicks.map((d, i) => ({
        date: new Date(d.getTime() + (weekTicks[1] - weekTicks[0]) / 2),
        label: `${(i % 4) + 1}w`
      }));

      // Compute the width of each month interval
      const monthWidth = (width / monthTicks.length);

      // Add month labels to the xaxis
      xAxisSvg.append("g")
        .attr("class", "x-axis-months")
        .attr("transform", `translate(0, ${-margin.top / 5})`)
        .selectAll(".month-label")
        .data(monthTicks)
        .enter().append("text")
        .attr("class", "month-label")
        .attr("x", d => x(d) + monthWidth / 2) // Adjust x position to center labels
        .attr("y", margin.top / 2)
        .style("text-anchor", "middle") // Center the text itself
        .style("fill", chartColors.monthLabelColor)
        .text(d => d3.timeFormat("%B")(d));

      // Add week labels to the xaxis
      xAxisSvg.append("g")
        .attr("class", "x-axis-weeks")
        .attr("transform", `translate(0, ${-margin.top / 5})`)
        .selectAll(".week-label")
        .data(weekLabels)
        .enter().append("text")
        .attr("class", "week-label")
        .attr("x", d => x(d.date))
        .attr("y", margin.top)
        .style("text-anchor", "middle")
        .style("fill", chartColors.weekLabelColor)
        .text(d => d.label);

      //week seperator on xaxis
      xAxisSvg.append("g")
        .attr("class", "week-separators")
        .selectAll("line")
        .data(weekTicks)
        .enter()
        .append("line")
        .attr("x1", d => x(d))
        .attr("x2", d => x(d))
        .attr("y1", margin.top / 2)
        .attr("y2", margin.top)
        .attr("stroke", "#606676")
        .attr("stroke-opacity", 1)
        .attr("stroke-width", 1)

      // Add month seperators on xaxis
      xAxisSvg.append("g")
        .attr("class", "grid-lines")
        .selectAll("line")
        .data(monthTicks)
        .enter()
        .append("line")
        .attr("x1", d => x(d))
        .attr("x2", d => x(d))
        .attr("y1", 0)
        .attr("y2", margin.top)
        .attr("stroke", "#606676")
        .attr("stroke-opacity", 1)
        .attr("stroke-width", 1)

      //week seperator on contentSvg
      contentSvg.append("g")
        .attr("class", "week-separators")
        .selectAll("line")
        .data(weekTicks)
        .enter()
        .append("line")
        .attr("x1", d => x(d))
        .attr("x2", d => x(d))
        .attr("y1", 0)
        .attr("y2", height + margin.bottom)
        .attr("stroke", "#606676")
        .attr("stroke-opacity", "0.4")
        .attr("stroke-width", 1)

      // Add month seperators on contentSvg
      contentSvg.append("g")
        .attr("class", "grid-lines")
        .selectAll("line")
        .data(monthTicks)
        .enter()
        .append("line")
        .attr("x1", d => x(d))
        .attr("x2", d => x(d))
        .attr("y1", 0)
        .attr("y2", height + margin.bottom)
        .attr("stroke", "#606676")
        .attr("stroke-opacity", "0.8")
        .attr("stroke-width", 1);
    }

    // Add horizontal line between months and weeks
    xAxisSvg.append("line")
      .attr("x1", 0)
      .attr("x2", width + margin.right)
      .attr("y1", margin.top / 2)
      .attr("y2", margin.top / 2)
      .attr("stroke", "#606676")
      .attr("stroke-opacity", "1")
      .attr("stroke-width", 2);

    // Add horizontal lines between tasks on contentSvg
    contentSvg.selectAll(".task-separator")
      .data(validData.slice(0, -1)) // Exclude the last task
      .enter()
      .append("line")
      .attr("class", "task-separator")
      .attr("x1", 0)
      .attr("x2", width + margin.right)
      .attr("y1", d => y(d.id) + y.bandwidth() + y.bandwidth() / 2)
      .attr("y2", d => y(d.id) + y.bandwidth() + y.bandwidth() / 2)
      .attr("stroke", "black")
      .attr("stroke-opacity", "0.4")
      .attr("stroke-width", 1);

    // Create tooltip
    const tooltip = d3
      .select(toolTipContainerRef.current)
      .append('div')
      .attr('class', 'tooltip')
      .style('position', 'absolute')
      .style('pointer-events', 'none')
      .style('opacity', 0)
      .style('display', 'none')

    // Create groups for actual and planned bars
    const taskGroups = contentSvg.selectAll(".task")
      .data(validData)
      .enter()
      .append("g")
      .attr("class", "task")
      .attr("transform", d => `translate(0, ${y(d.id)})`)

    const barHeight = y.bandwidth() / 2; // Adjust this value to control the height of the bars and the gap

    // Draw the planned bar
    taskGroups.append("rect")
      .attr("class", "planned")
      .attr("x", d => {
        let newPlannedStart = d.plannedStart;
        if (d.plannedStart < startDate) {
          newPlannedStart = startDate
        }
        return x(newPlannedStart)
      })
      .attr("y", 0)
      .attr("width", d => {
        let newPlannedStart = d.plannedStart;
        if (d.plannedStart < startDate) {
          newPlannedStart = startDate
        }
        return Math.max(0, x(d.plannedEnd) - x(newPlannedStart))
      })
      .attr("height", barHeight)
      .attr("fill", "royalblue")
      .style("display", d => "block")
      .on("click", function (event, d) {
        event.stopPropagation();
        showTooltipOnEvent(this, event, d);
      });


    let minBarWidth;// Set a minimum width for the bar
    if (scale === "days") {
      minBarWidth = 20;
    } else if (scale === "months") {
      minBarWidth = 6;
    } else {
      minBarWidth = 2;
    }

    function getActaulBarWidth(d){
      let width;
        if (d.actualStart && d.actualEnd) {
          let newActualStart = d.actualStart;
          if (d.actualStart < startDate) {
            minBarWidth = 0
            newActualStart = startDate
          }
          width = x(d.actualEnd) - x(newActualStart);
        } else if (d.actualStart) {
          let newActualStart = d.actualStart;
          if (d.actualStart < startDate) {
            minBarWidth = 0
            newActualStart = startDate
          }
          width = x(new Date()) - x(newActualStart);
        } else {
          let newPlannedStart = d.plannedStart;
          if (d.plannedStart < startDate) {
            minBarWidth = 0
            newPlannedStart = startDate
          }
          width = x(d.plannedEnd) - x(newPlannedStart);
        }
        return Math.max(minBarWidth, width);
    }

    // Draw actual bars
    taskGroups.append("rect")
      .attr("class", "actual")
      .attr("id",d => d.id)
      .attr("x", d => {
        let newPlannedStart = d.plannedStart;
        let newActualStart = d.actualStart;
        if (d.actualStart) {
          if (d.actualStart < startDate) {
            newActualStart = startDate
          }
          return x(newActualStart)
        } else {
          if (d.plannedStart < startDate) {
            newPlannedStart = startDate
          }
          return x(newPlannedStart)

        }
      })
      .attr("y", barHeight + 2)
      .attr("width", d => getActaulBarWidth(d))
      .attr("height", barHeight)
      .attr("fill", d => {
        return getActualBarColors(d);
      })
      .style("display", d => actualBarsVisible ? "block" : "none")
      .on("click", function (event, d) {
        event.stopPropagation();
        showTooltipOnEvent(this, event, d);
      });

    function showTooltipOnEvent(element, event, d) {
      hideTooltip();
      d3.select(element).attr('opacity', 0.6);
      const tooltipX = event.clientX;
      const tooltipY = event.clientY;
      console.log(tooltipX,tooltipY)
      showTooltip(d);
    }


    function showTooltip(d) {
      // Create the AssignedTo dropdown options
      const assignedToOptions = teamData && teamData.length > 0
        ? [
          `<option value="" selected>None</option>`,
          ...teamData.map(user =>
            `<option value="${user.invitee_id}" data-role="${user.invitee_role}" data-name="${user.invitee_name}" ${d.assigned_to && d.assigned_to === user.invitee_id ? 'selected' : ''}>${user.invitee_name}</option>`
          )
        ].join('')
        : `<option value="" selected>None</option>`;

      tooltip
        .attr('class', `${(window.innerWidth <= 700 || window.innerHeight <=700) ? 'tooltip tooltip-mobile' : 'tooltip'}`)
        .style('opacity', 1)
        .style('display', 'block')
        .style('pointer-events', 'auto')
        .html(`
        <div style="text-align: right;"><span class="close-btn" style="cursor: pointer;">&times;</span></div>
        <p><strong>Task Name:</strong> ${d.task_name}</p>
        <p><strong>Planned Start Date:</strong> ${d3.timeFormat("%Y-%m-%d")(d.plannedStart)}</p>
        <p><strong>Planned End Date:</strong> ${d3.timeFormat("%Y-%m-%d")(d.plannedEnd)}</p>
        <div class="actual-start-container"><p><strong>Actual Start Date:</strong> ${d.actualStart ? d3.timeFormat("%Y-%m-%d")(d.actualStart) : 'N/A'}</p>${permissionParams.add_actual_dates == "allowed" || Object.keys(permissionParams).length == 0 ?`<i id="add-actual-dates" class="icon icon-add_circle"></i>`:""}</div>
        <p><strong>Actual End Date:</strong> ${d.actualEnd ? d3.timeFormat("%Y-%m-%d")(d.actualEnd) : 'N/A'}</p>
        <p><strong>Assigned To:</strong>
          <select id="assigned-to-dropdown" ${!(permissionParams.progress_edit == "allowed" || Object.keys(permissionParams).length == 0) ? 'disabled' : ''}>
            ${assignedToOptions}
          </select>
          <button id="save-assigned-to" style="display:none;">Save</button>
        </p>
        <p><strong>Status:</strong>
          <select id="status-dropdown" ${!(permissionParams.progress_edit == "allowed" || Object.keys(permissionParams).length == 0) ? 'disabled' : ''}>
            <option value="Not Started" ${d.status === 'Not Started' ? 'selected' : ''}>Not Started</option>
            <option value="In Progress" ${d.status === 'In Progress' ? 'selected' : ''}>In Progress</option>
            <option value="Completed" ${d.status === 'Completed' ? 'selected' : ''}>Completed</option>
          </select>
          <button id="save-status" style="display:none;">Save</button>
        </p>
        <p><strong>Remarks:</strong></p>
        <div id="remarks-container"></div>
        ${permissionParams.add_comment == "allowed" || Object.keys(permissionParams).length == 0 ? `<div><input type="text" id="new-comment" placeholder="Add a comment..."><button id="add-comment">+</button></div>` : ""}
        
      `)
        .style('left', `${((window.innerWidth <= 700 || window.innerHeight <=700) ? (window.innerWidth/2) : (window.innerWidth / 2))}px`)
        .style('top', `${(window.innerWidth <= 700 || window.innerHeight <=700) ? window.innerHeight/2 : (window.innerHeight/2)}px`)
        .style('transform', 'translate(-100%, -100%)')
        .style('z-index', 9999);

      // Remove any existing event listeners before attaching new ones
      d3.select('#add-actual-dates').on('click', null);
      d3.select('#assigned-to-dropdown').on('change', null);
      d3.select('#save-assigned-to').on('click', null);
      d3.select('#status-dropdown').on('change', null);
      d3.select('#save-status').on('click', null);

      // Event listener for add actual dates button
      d3.select('#add-actual-dates').on('click', async function () {
        setCurrentSelectedTask(d);
        setaction("setActualDates");
      });


      // Event listener to show the save button when the Assigned To dropdown value changes
      d3.select('#assigned-to-dropdown').on('change', function () {
        d3.select('#save-assigned-to').style('display', 'inline');
      });
      // Add event listener for the save button next to AssignedTO dropdown
      d3.select('#save-assigned-to').on('click', async function () {
        const dropdown = d3.select('#assigned-to-dropdown').node();
        const selectedOption = dropdown.options[dropdown.selectedIndex];
        const assignedToId = selectedOption.value;
        const assignedToName = selectedOption.dataset.name;
        const assignedToRole = selectedOption.dataset.role;

        // Update the assignedTo in your data
        d.assigned_to = assignedToId;
        d.assignee_name = assignedToName;
        d.assignee_role = assignedToRole;

        await onAssignedToSave(d);

        // Hide the save button after saving
        d3.select('#save-assigned-to').style('display', 'none');
      });

      // Event listener to show the save button when dropdown value changes
      d3.select('#status-dropdown').on('change', function () {
        d3.select('#save-status').style('display', 'inline');
      });

      // Add event listener for save button
      d3.select('#save-status').on('click', async function () {
        const newStatus = d3.select('#status-dropdown').property('value');
        d.status = newStatus; // update the status in your data
        await onStatusSave(d);

        // Hide the save button after saving
        d3.select('#save-status').style('display', 'none');
      });

      //fetch comments of task when clicked on bar
      const remarksContainer = tooltip.select('#remarks-container');
      getComments();
      async function getComments() {
        // Populate remarks
        const { data: comments } = await getTaskComments(d.id)
        if (comments && comments.length > 0) {
          // First, create a map of comments by their IDs
          const commentMap = new Map(comments.map(comment => [comment.id, { ...comment, replies: [] }]));

          // Then, organize comments into a tree structure
          const rootComments = [];

          comments.forEach(comment => {
            const repliedToId = comment.replied_to_id === "000000000000000000000000" ? null : comment.replied_to_id;

            if (repliedToId) {
              const parentComment = commentMap.get(repliedToId);
              if (parentComment) {
                parentComment.replies.push(commentMap.get(comment.id));
              }
            } else {
              rootComments.push(commentMap.get(comment.id));
            }
          });

          // Function to recursively render comments
          function renderComments(comments, level = 0) {
            comments.forEach(comment => {
              const commentDiv = remarksContainer.append('div')
                .attr('class', `comment-div ${level === 0 ? 'comment-div-top-border' : ''}`)
                .attr('data-comment-id', comment.id)
                .attr('data-replied-to-id', comment.replied_to_id || '')
                .style('margin-left', `${level * 20}px`)
                .html(level === 0 ? `
                  <p><strong>${comment.commenter_name}:</strong> <span class="comment-text">${comment.comment}</span></p>
                  <div class="comment-options">
                    ${permissionParams.reply_comment == "allowed" || Object.keys(permissionParams).length == 0 ? '<button class="reply-comment">Reply</button>' : ''}
                    ${permissionParams.edit_comment == "allowed" || Object.keys(permissionParams).length == 0 && (comment.replies && comment.replies.length === 0) ? '<button class="edit-comment"><i class="icon icon-edit"></i></button>' : ''}
                    <button class="delete-comment"><i class="icon icon-delete"></i></button>
                  </div>
                ` : `<p><strong>${comment.commenter_name}:</strong> <span class="comment-text">${comment.comment}</span></p>`);
          
              // Recursively render replies
              if (comment.replies && comment.replies.length > 0) {
                renderComments(comment.replies, level + 1);
              }
            });
          }

          // Render the comment tree
          renderComments(rootComments);
        } else {
          remarksContainer.append('p').attr('class', 'no-comments').text('No comments yet.');
        }

        // Add event listeners for comment options
        addCommentOptionListeners();
      }
      // Function to add event listeners for comment options
      function addCommentOptionListeners() {
        remarksContainer.selectAll('.edit-comment').on('click', handleEditComment);
        remarksContainer.selectAll('.reply-comment').on('click', handleReplyComment);
        remarksContainer.selectAll('.delete-comment').on('click', handleDeleteComment);
      }

      // Handle edit comment
      function handleEditComment(event, d) {
        const commentDiv = d3.select(this.closest('.comment-div'));
        const commentText = commentDiv.select('.comment-text');
        const originalText = commentText.text();

        // Replace comment text with an input field
        commentText.html(`<input type="text" class="edit-comment-input" value="${originalText}">`);

        const currentReplyDiv = commentDiv.select('.reply-comment');
        currentReplyDiv.style('display', 'none');

        // Add save button
        commentDiv.select('.comment-options').append('button')
          .attr('class', 'save-edit-comment')
          .text('Save')
          .on('click', async function () {
            const newText = commentDiv.select('.edit-comment-input').property('value');
            const commentId = commentDiv.attr('data-comment-id');
            const repliedToId = commentDiv.attr('data-replied-to-id') || null;
            const newRepliedToId = repliedToId === "000000000000000000000000" ? null : repliedToId;
            try {
              // Call backend API to update comment
              const commentUpdateObj = {
                id: commentId,
                comment: newText,
                replied_to_id: newRepliedToId
              }
              console.log("commented obj", commentUpdateObj)
              const response = await updateComment(commentUpdateObj);
              console.log(response)

              // Update frontend
              currentReplyDiv.style('display', 'inline');
              commentText.html(newText);
              d3.select(this).remove(); // Remove save button
            } catch (error) {
              console.error('Error updating comment:', error);
            }
          });
      }

      // Handle reply comment
      function handleReplyComment() {
        const commentDiv = d3.select(this.closest('.comment-div'));

        // Add reply input and button
        commentDiv.append('div')
          .attr('class', 'reply-div')
          .html(`
          <input type="text" class="reply-input" placeholder="Type your reply...">
          <button class="post-reply">Post Reply</button>
        `);

        // Add event listener for post reply button
        commentDiv.select('.post-reply').on('click', async function () {
          const replyText = commentDiv.select('.reply-input').property('value');
          const parentCommentId = commentDiv.attr('data-comment-id');

          try {
            // Call backend API to post reply
            const newComment = {
              task_id: d.id,
              commenter_id: userData.id,
              commenter_name: userData.FullName,
              comment: replyText,
              replied_to_id: parentCommentId
            };
            const response = await saveComment(newComment);
            if (!response.success) {
              throw new Error('Failed to post comment');
            }

            // Update frontend
            if (response.success) {
              commentDiv.append('div')
                .attr('class', 'reply-comment')
                .html(`<p><strong>${userData.FullName}:</strong><span> ${replyText}</span></p>`);

              commentDiv.select('.reply-div').remove();
            }
          } catch (error) {
            console.error('Error posting reply:', error);
          }
        });
      }

      // Handle delete comment
      async function handleDeleteComment(event, d) {
        const commentDiv = d3.select(this.closest('.comment-div'));
        const commentId = commentDiv.attr('data-comment-id');

        try {
          console.log(commentId)
          // Call backend API to delete comment
          const response = await deleteComment(commentId);
          console.log(response)

          // Remove comment from frontend
          commentDiv.remove();
        } catch (error) {
          console.error('Error deleting comment:', error);
        }

      }


      // Add comment functionality
      tooltip.select('#add-comment').on('click', async function () {
        const newCommentInput = tooltip.select('#new-comment');
        const newCommentText = newCommentInput.property('value');

        if (newCommentText) {
          const newComment = {
            task_id: d.id,
            commenter_id: userData.id,
            commenter_name: userData.FullName,
            comment: newCommentText,
          };

          try {
            const response = await saveComment(newComment);
            if (!response.success) {
              throw new Error('Failed to post comment');
            }

            // If successful, update the frontend
            tooltip.select('.no-comments').remove();

            // Add the new comment to the display
            remarksContainer.append('div')
              .attr('class', 'comment-div')
              .attr('data-comment-id', response.commentId) // Assuming the backend returns the new comment's ID
              .html(`
            <p><strong>${newComment.commenter_name}:</strong> <span class="comment-text">${newComment.comment}</span></p>
            <div class="comment-options">
              <button class="reply-comment">Reply</button>  
              <button class="edit-comment"><i class="icon icon-edit"></i></button>
              <button class="delete-comment"><i class="icon icon-delete"></i></button>
            </div>
          `);


            // Clear the input
            newCommentInput.property('value', '');

            // Update your data structure
            if (!d.comments) d.comments = [];
            d.comments.push(newComment);

            // Add event listeners for the new comment's options
            addCommentOptionListeners();

          } catch (error) {
            console.error('Error posting comment:', error);
          }
        }
      });

      // Add close button functionality
      tooltip.select('.close-btn').on('click', function () {
        hideTooltip();
      });
    }


    // Prevent tooltip from disappearing when clicked
    tooltip.on("click", function (event) {
      event.stopPropagation();
    });

    // Add a click event listener to the document to hide the tooltip
    d3.select(document).on("click", function () {
      hideTooltip();
    });

    function hideTooltip() {
      tooltip
        .style('opacity', 0)
        .style('pointer-events', 'none')
        .style('display', 'none');
      taskGroups.selectAll("rect").attr('opacity', 1);
    }



    // Adjust font sizes based on screen width
    const baseFontSize = Math.max(16, Math.min(16, width / 100));
    svg.selectAll("text")
      .style("font-size", `${baseFontSize}px`);

    svg.selectAll(".month-label, .year-label")
      .style("font-size", `${baseFontSize}px`);
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - contentRef.current.offsetLeft);
    setScrollLeft(contentRef.current.scrollLeft);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - contentRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    contentRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleScroll = () => {
    if (contentRef.current && xAxisRef.current) {
      xAxisRef.current.scrollLeft = contentRef.current.scrollLeft;
    }
    if (contentRef.current && yAxisRef.current) {
      yAxisRef.current.scrollTop = contentRef.current.scrollTop;
    }
  };

  const handleTouchStart = (e) => {
    setIsDragging(true);
    setStartX(e.touches[0].pageX - contentRef.current.offsetLeft);
    setScrollLeft(contentRef.current.scrollLeft);
  };
  
  const handleTouchEnd = () => {
    setIsDragging(false);
  };
  
  const handleTouchMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.touches[0].pageX - contentRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    contentRef.current.scrollLeft = scrollLeft - walk;
  };


  return (
    <>
      <div ref={downloadChartRef} style={{ position: 'relative', width: '90%', height: `${(window.innerWidth <= 700 || window.innerHeight<= 700) ? '350px' : '650px'}`, border: '1px solid black', overflow: 'hidden' ,zIndex:100}}>
        <div ref={xAxisRef} style={{ backgroundColor: '#455570', position: 'sticky', top: 0, left: margin.left, right: 0, height: margin.top, overflow: 'hidden', zIndex: '3',  marginLeft: margin.left }} />
        <div ref={yAxisRef} style={{ backgroundColor: '#e6f3ff', position: 'absolute', top: margin.top, left: 0, bottom: 0, width: margin.left, overflow: 'hidden', zIndex: '3', borderRight: '1px solid #455570' }} />
        <div ref={taskNameRef} style={{ backgroundColor: '#e6f3ff', position: 'absolute', top: 0, left: 0, width: margin.left,overflow: 'hidden', zIndex: '3', }} />
        <div
          ref={contentRef}
          style={{ backgroundColor: '#e6f3ff', position: 'absolute', top: margin.top, left: margin.left, right: 0, bottom: 0, overflow: 'auto', overflowX: 'scroll', zIndex: '2',WebkitOverflowScrolling: 'touch', }}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
          onMouseMove={handleMouseMove}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          onTouchMove={handleTouchMove}
          onScroll={handleScroll}
        />
        <div ref={chartRef} style={{ position: 'sticky', top: 0, left: 0, bottom: 0, right: 0, width: '100%',minWidth: '1000px', height: '100%', zIndex: '0' }} />

        <div ref={toolTipContainerRef} />
      </div>
    </>
  );
};

export default GanttChart;