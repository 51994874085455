import { useEffect, useState } from "react";
import ModalComponent from "../common/Modal/modal";
import Treetest from 'react-d3-tree';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { getTreeData } from "./apiController";

export default function PreviewAction(props){
  let actionChart = []
  const [actions,Setactions] = useState([]);
  const [chartData, SetChartData] = useState([]);

 useEffect(()=>{
    console.log(props.formId)
    fetchInitialData()
 },[])
 
 /**
  * get call for tree data
  */
 const fetchInitialData = async () =>{
    var timestamp = new Date().getUTCMilliseconds();
    const treeData = await getTreeData("64fcacdb96887e7e9bb3cd84","1694199621344")
    SetChartData(treeData.tree)
}

const actionChartData = {
  "name": "64fcaceb96887e7e9bb3cd85",
  "attributes": {
      "label": "Name"
  },
  "field": {
      "id": "64fcaceb96887e7e9bb3cd85",
      "form_id": "000000000000000000000000",
      "field_name": "Name",
      "field_value": [],
      "field_type": "textBox",
      "field_use_type": "Analytical",
      "field_category": "jjj",
      "field_status": "draft",
      "action_enabled": false,
      "source_form_id": "64fcacdb96887e7e9bb3cd84"
  },
  "children": [
      {
          "name": "64fcacf996887e7e9bb3cd86",
          "attributes": {
              "label": "Age"
          },
          "field": {
              "id": "64fcacf996887e7e9bb3cd86",
              "form_id": "000000000000000000000000",
              "field_name": "Age",
              "field_value": [],
              "field_type": "number",
              "field_use_type": "Analytical",
              "field_category": "jjj",
              "field_status": "draft",
              "action_enabled": true,
              "source_form_id": "64fcacdb96887e7e9bb3cd84"
          },
          "children": [
              {
                  "name": "64fcad5096887e7e9bb3cd88",
                  "attributes": {
                      "label": "Employee"
                  },
                  "field": {
                      "id": "64fcad5096887e7e9bb3cd88",
                      "form_id": "000000000000000000000000",
                      "field_name": "Employee",
                      "field_value": [
                          "Yes",
                          "No"
                      ],
                      "field_type": "dropDown",
                      "field_use_type": "Analytical",
                      "action_enabled": true,
                      "source_form_id": "000000000000000000000000"
                  },
                  "children": [
                      {
                          "name": "64fcad7f96887e7e9bb3cd8a",
                          "attributes": {
                              "label": "Salary"
                          },
                          "field": {
                              "id": "64fcad7f96887e7e9bb3cd8a",
                              "form_id": "000000000000000000000000",
                              "field_name": "Salary",
                              "field_value": [],
                              "field_type": "number",
                              "field_use_type": "Analytical",
                              "action_enabled": false,
                              "source_form_id": "000000000000000000000000"
                          },
                          "children": [
                              {
                                  "name": "64ffbffb1bf1957dfa329f47",
                                  "attributes": {
                                      "label": "occupation"
                                  },
                                  "field": {
                                      "id": "64ffbffb1bf1957dfa329f47",
                                      "form_id": "000000000000000000000000",
                                      "field_name": "occupation",
                                      "field_value": [],
                                      "field_type": "textBox",
                                      "field_use_type": "Analytical",
                                      "field_category": "jjj",
                                      "field_status": "draft",
                                      "action_enabled": false,
                                      "source_form_id": "64fcacdb96887e7e9bb3cd84"
                                  },
                                  "children": [
                                      {
                                          "name": "64fcad9796887e7e9bb3cd8b",
                                          "attributes": {
                                              "label": "Feedback"
                                          },
                                          "field": {
                                              "id": "64fcad9796887e7e9bb3cd8b",
                                              "form_id": "000000000000000000000000",
                                              "field_name": "Feedback",
                                              "field_value": [],
                                              "field_type": "textArea",
                                              "field_use_type": "Analytical",
                                              "field_category": "jjj",
                                              "field_status": "draft",
                                              "action_enabled": true,
                                              "source_form_id": "64fcacdb96887e7e9bb3cd84"
                                          },
                                          "children": null
                                      }
                                  ]
                              }
                          ],
                          "option": "x == Yes"
                      },
                      {
                          "name": "64fcad9796887e7e9bb3cd8b",
                          "attributes": {
                              "label": "Feedback"
                          },
                          "field": {
                              "id": "64fcad9796887e7e9bb3cd8b",
                              "form_id": "000000000000000000000000",
                              "field_name": "Feedback",
                              "field_value": [],
                              "field_type": "textArea",
                              "field_use_type": "Analytical",
                              "action_enabled": true,
                              "source_form_id": "000000000000000000000000"
                          },
                          "children": null,
                          "option": "x == No"
                      }
                  ],
                  "option": "x > 20"
              },
              {
                  "name": "64fcad0d96887e7e9bb3cd87",
                  "attributes": {
                      "label": "Student"
                  },
                  "field": {
                      "id": "64fcad0d96887e7e9bb3cd87",
                      "form_id": "000000000000000000000000",
                      "field_name": "Student",
                      "field_value": [
                          "Yes",
                          "No"
                      ],
                      "field_type": "dropDown",
                      "field_use_type": "Analytical",
                      "action_enabled": true,
                      "source_form_id": "000000000000000000000000"
                  },
                  "children": [
                      {
                          "name": "64fcad6c96887e7e9bb3cd89",
                          "attributes": {
                              "label": "College"
                          },
                          "field": {
                              "id": "64fcad6c96887e7e9bb3cd89",
                              "form_id": "000000000000000000000000",
                              "field_name": "College",
                              "field_value": [],
                              "field_type": "textBox",
                              "field_use_type": "Analytical",
                              "action_enabled": false,
                              "source_form_id": "000000000000000000000000"
                          },
                          "children": null,
                          "option": "x == Yes"
                      },
                      {
                          "name": "64fcad9796887e7e9bb3cd8b",
                          "attributes": {
                              "label": "Feedback"
                          },
                          "field": {
                              "id": "64fcad9796887e7e9bb3cd8b",
                              "form_id": "000000000000000000000000",
                              "field_name": "Feedback",
                              "field_value": [],
                              "field_type": "textArea",
                              "field_use_type": "Analytical",
                              "action_enabled": true,
                              "source_form_id": "000000000000000000000000"
                          },
                          "children": null,
                          "option": "x == No"
                      }
                  ],
                  "option": "x < 20"
              }
          ]
      }
  ]
};

  return (
    <ModalComponent
            width="100%"
            height="1000px"
            isOpen={true}
            onClose={() => props.onClose()}
            header="Action Preview"
            centered={true}
            body={               
              <div style={{ width: '100%', height: '1200px' }}>
              {chartData && <Treetest
                data={chartData && chartData}
                orientation="vertical"
                translate={{ x: 700, y: 200 }}
                pathFunc="diagonal"
                nodeLabelComponent={{
                  render: "asdf",
                  foreignObjectWrapper: {
                    width: 400,
                    height: 100,
                  },
                }}
                nodeSvgShape={{
                  shape: 'square',
                  shapeProps: {
                    r: 10,
                    fill: 'lightblue',
                  },
                }}
              />
           
            }
              {/* <HighchartsReact options={chartOptions} /> */}

            </div>
              
            }/>
  );
}

// Custom node label component
// const NodeLabel = ({ nodeData }) => {
//   return (
//     <div>
//       <div>{nodeData.name}</div>
//       <div>{nodeData.attributes.label}</div>
//     </div>
//   );
// };
