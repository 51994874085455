import React, { useRef, useEffect, useState } from 'react';
import MonacoEditor, { EditorDidMount } from 'react-monaco-editor';
import * as monaco from 'monaco-editor';

interface Props {
  language: string;
  value: string;
  suggest: Array<any>;
  onChange: (value: string,valuecode: any) => void;
}

const MonacoEditorWrapper: React.FC<Props> = ({ language, value, suggest, onChange }) => {
  const editorRef = useRef<monaco.editor.IStandaloneCodeEditor | null>(null);
  const completionProviderRef = useRef<monaco.IDisposable | null>(null);


  const generateSuggestions = () => {
    const suggestions: any = [];
    for (let i = 0; i < suggest.length; i++){
      suggestions.push({
        label: `${suggest[i].label} `,
        kind: monaco.languages.CompletionItemKind.Text,
        insertText: suggest[i].value + " "
      });
    }
    return suggestions;
  };

  const registerCompletionProvider = () => {
    unregisterCompletionProvider(); // Ensure to unregister previous provider
    completionProviderRef.current = monaco.languages.registerCompletionItemProvider(language, {
      provideCompletionItems: () => {
        return {
          suggestions: generateSuggestions(),
        };
      },
    });
  };

  const unregisterCompletionProvider = () => {
    if (completionProviderRef.current) {
      completionProviderRef.current.dispose();
      completionProviderRef.current = null;
    }
  };

  const handleEditorDidMount: EditorDidMount = (editor, monaco) => {
    editorRef.current = editor;
    registerCompletionProvider();
  };

  useEffect(() => {
    return () => {
      unregisterCompletionProvider();
    };
  }, []);

  let temp = "";
  const handleEditorChange = (newValue: string, e: monaco.editor.IModelContentChangedEvent) => {
   onChange(newValue,e);
  //  console.log(e.changes[0].text)
  }




    return (
      <div className="monaco-editor-wrapper">
        <MonacoEditor
          className="editor"
          language={language}
          value={value}
          onChange={handleEditorChange}
          editorDidMount={handleEditorDidMount}
          height={200}
          theme='vs-dark'
          options={{
            minimap: { enabled: false },
            fontSize: 14,
            cursorStyle: "block",
            wordWrap: "on",
          }}
        />
      </div>

    );
  };

  export default MonacoEditorWrapper;
