// ScatterPlot.js
import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { Button } from 'reactstrap';
import { HuePicker } from 'react-color';
import { uploadChartAsImage } from '../../Editor/utils/UploadToServer';
import SectionModal from '../../Editor/utils/SectionModal';
import { downloadChartAsImage } from './downloadChartAsImage';

const ScatterPlot = ({ containerid,chartstyle,data,chartdatastyle,title,titlecolor,width,height,yaxislabelstyle,xaxislabelstyle,gridcolor,gridstatus,yaxisstyle,xaxisstyle,xaxistext,yaxistext }) => {
  const svgRef = useRef(null);
  const [showGridLines, setShowGridLines] = useState(false);
  const [ylabel,setylabel] = useState(yaxistext)
  const [xlabel,setxlabel] = useState(xaxistext)
	const [color, Setcolor] = useState("#000000")
  const [colorLabel, SetcolorLabel] = useState("black")
	const [hideColor, SethideColor] = useState(false)
  const [FontWeight, SetFontWeight] = useState(false)
  const [fontSize, SetFontSize] = useState('16');
  const [actionPush, setactionPush] = useState('');

  /**
  * function call to Push code to editor
  */
  const addToEditor = (data) => {
      console.log(data)
      uploadChartAsImage(data,containerid)
      setactionPush("")
  }

  useEffect(() => {
    const margin = { top: 20, right: 20, bottom: 30, left: 40 };
    //const width = 600 - margin.left - margin.right;
    //const height = 400 - margin.top - margin.bottom;

    const svg = d3
    .select(svgRef.current)
    .append('svg')
    .attr('width', width )
    .attr('height', height )
    .append('g')
    .style("overflow", "visible")
    .style('fill',chartstyle.color)
    .attr('transform', `translate(${width / 4},${height / 6})`);
    ;
   
    // Create scales
    // Calculate the maximum x-value in data
    var maxX = d3.max(data, d => d.x);
    
    // Define the interval size
    var interval = 50;
    
    // Calculate the number of ticks based on the interval
    var numTicks = Math.ceil(maxX / interval);
    
    // Generate an array of tick values
    var tickValues = d3.range(0, numTicks + 1).map(d => d * interval);
    
    // Create scales for x and y axes
    var xScale = d3.scaleLinear()
      .domain([0, maxX])
      .range([0, width - margin.right]);
    
    const yScale = d3.scaleLinear().domain([0, d3.max(data, d => d.y)+(d3.max(data, d => d.y)*10/100)]).range([height, 0]);

    // Create axes
    const xAxis = d3.axisBottom(xScale).tickValues(tickValues);
    const yAxis = d3.axisLeft(yScale);


    const line = svg.append('line')
    .attr('stroke', chartstyle.linecolor)
    .style("stroke-dasharray", "5 5")
    .attr('stroke-width', 2);

    const linehorizontal = svg.append('line')
    .attr('stroke', chartstyle.linecolor)
    .style("stroke-dasharray", "5 5")
    .attr('stroke-width', 2);

    // Draw gridlines
    svg
    .append('g')
    .attr('class', 'grid')
    .style('color',gridcolor)
    .attr('transform', `translate(2,${height})`)
    .style("stroke-dasharray", "5 5")
    .style("visibility",showGridLines  ? "visible" : "hidden")
    .call(d3.axisBottom(xScale).tickSize(-height).tickFormat(''));

    svg
      .append('g')
      .attr('class', 'grid')
      .attr("transform", `translate(${1})`)   
      .style("stroke-dasharray", "5 5")
      .style("color",gridcolor)
      .style("visibility",showGridLines  ? "visible" : "hidden")
      .call(d3.axisLeft(yScale).tickSize(-width).tickFormat(''));


    // Create circles
    svg.selectAll('circle')
      .data(data)
      .enter().append('circle')
      .attr('cx', d => xScale(d.x))
      .attr('cy', d => yScale(d.y))
      .attr('r', chartstyle.dotradius)
      .attr('fill',color)
      .on("mouseenter",function(d){
        d3.select(d.currentTarget)
        .style("fill", chartstyle.hover)
          .transition()
          .duration(400)
          .attr('opacity', 0.6);

      
       // Show tooltip on mouseover
       tooltip.transition().duration(500).style('opacity', 0.9);
       tooltip.html(`${xaxistext} : ${d.target.__data__.x}<br>${yaxistext} : ${d.target.__data__.y}`)
         .style('left', `${d.pageX}px`)
         .style('top', `${d.pageY}px`);
           
         const [x, y] = d3.pointer(d);


         // Update line coordinates to follow the mouse
        // Update line position
        line.attr('x1', x)
        .attr('y1', 0)
        .attr('x2', x)
        .attr('y2', height)
        .style('display', 'block'); 
  
        linehorizontal.attr('x1', 0)
        .attr('y1', y)
        .attr('x2', width)
        .attr('y2', y)
        .style('display', 'block');

        })
        .on("mouseout",function(d){
          d3.select(d.currentTarget)
          .style("fill", chartstyle.color)
            .transition()
            .duration(200)
            .attr('opacity',1)
             //remove tooltip
             d3.select(d.currentTarget)
             .style("fill", chartstyle.hover)
               .transition()
               .duration(400)
               .attr('opacity', 0);
          
             tooltip.transition().duration(500).style('opacity', 0);

             const [x, y] = d3.pointer(d);

             
             line.attr('x1', x)
             .attr('y1', 0)
             .attr('x2', x)
             .attr('y2', height)
             .style('display', 'none'); 
       
             linehorizontal.attr('x1', 0)
             .attr('y1', y)
             .attr('x2', width)
             .attr('y2', y)
             .style('display', 'none');
          });


           // Create tooltip
           const tooltip = d3
           .select(svgRef.current)
           .append('div')
           .style('position', 'absolute')
           .style('background', '#f4f4f4')
           .style('padding', '5px')
           .style('border', '1px solid #ccc')
           .style('border-radius', '5px')
           .style('opacity', 0);
  

    //add x axis label
    svg.append('text')
    .attr("class", "x label")
    .attr("text-anchor", "middle")
    .attr("x", width/2)
    .attr("y", height - 2)
    .attr("dy", xaxisstyle.dy)
    .style('fill',colorLabel)
    .style('font-weight',FontWeight ? "700":"100")
    .style('font-size',fontSize)
    .text(xlabel)
    .on("click",() =>{
      let label = prompt("Edit X Axis Label")
        if(label){
        setxlabel(label)
        }
    });

    //add y axis label
    svg.append("text")
    .attr("class", "y label")
    .attr('transform', `translate(20)`)
    .attr("text-anchor", "end")
    .attr("y", 6)
    .attr("dy", yaxisstyle.dy)
    .attr("x",-height/3)
    .attr("transform", "rotate(-90)")
    .style('fill',colorLabel)
    .style('font-weight',FontWeight ? "700":"100")
    .style('font-size',fontSize)
    .text(ylabel)
    .on("click",() =>{
      let label = prompt("Edit Y Axis Label")
      if(label){
        setylabel(label)
        }
    });


    svg.append('g')
      .attr('transform', `translate(0, ${height})`)
      .call(xAxis);

    svg.append('g')
      .call(yAxis);

       //title of the chart
     svg.append('text')
     .attr('class', 'title')
     .attr('x', width / 2)
     .attr('y', -margin.top)
     .attr('text-anchor', 'middle')
     .style('font-size',titlecolor.fontsize)
     .style('font-weight',titlecolor.fontweight)
     .style('text-decoration', 'underline')
     .style('fill',titlecolor.color)
     .text(title)

     return () => {
        d3.select(svgRef.current).selectAll('*').remove();
      };
      
  }, [data,showGridLines,xlabel,ylabel,color,colorLabel,FontWeight,fontSize]);

  const handleFontSizeChange = (event) =>{
    SetFontSize(event.target.value);

  }
 
  return (
    <>
     <div className="controller no-print">
            <Button className='grid no-print' style={{backgroundColor:color}} onClick={() => SethideColor(!hideColor)}>
               Color
            </Button>
            <Button className='grid no-print' onClick={() => setShowGridLines(!showGridLines)}>
                {showGridLines ? 'Hide Grid Lines' : 'Show Grid Lines'}
            </Button>
            <Button className='grid no-print' style={{fontWeight:FontWeight ? "700":"100"}} onClick={() => SetFontWeight(!FontWeight)}>
               B
            </Button>
            <select className='grid no-print' id="font-size" value={fontSize} onChange={handleFontSizeChange}>
              <option value="12">12px</option>
              <option value="14">14px</option>
              <option value="16">16px</option>
              <option value="18">18px</option>
              <option value="20">20px</option>
              <option value="24">24px</option>
              <option value="28">28px</option>
              <option value="32">32px</option>
            </select>
            {
                actionPush == "section" &&
                <SectionModal pushAction={addToEditor} onclose={() => setactionPush("")} />
            }
            
           
            <Button className='grid no-print' onClick={() => downloadChartAsImage(containerid)}>
                Download
            </Button>
            <Button className='grid no-print' onClick={() => setactionPush("section")}>
            Copy to Editor
            </Button>
        </div>  
        {hideColor ? 
        <div className='colorpicker no-print'>
            {/* <span>Bar</span>
            <HuePicker
                color={colorBar}
                onChange={(e)=>SetcolorBar(e.hex)}
            />  */}
            <span>Dots</span>
            <span style={{color:"blue", padding:"0px 5px"}} onClick={() => Setcolor("#000000")}>Reset</span>
               <HuePicker
                color={color}
                onChange={(e)=>Setcolor(e.hex)}
            />
             <span>Axis</span>
             <span style={{color:"blue", padding:"0px 5px"}} onClick={() => SetcolorLabel("#000000")}>Reset</span>
               <HuePicker
                color={colorLabel}
                onChange={(e)=>SetcolorLabel(e.hex)}
                presetColors={['#D0021B', '#F5A623', '#F8E71C', '#8B572A', '#7ED321', '#417505', '#BD10E0', '#9013FE', '#4A90E2', '#50E3C2', '#B8E986', '#000000', '#4A4A4A', '#9B9B9B', '#FFFFFF']}
            />
            </div>
            :""}
      <div ref={svgRef} id={containerid}></div>

      </>
  );
};


                                               
// ScatterPlot.prototype={
//   data:PropTypes.oneOfType([PropTypes.array,PropTypes.object]),
//   width:PropTypes.number,
//   heigth:PropTypes.number,
//   chartstyle:PropTypes.object,
//   chartdatastyle:PropTypes.object,
//   gridcolor:PropTypes.string,
//   gridstatus:PropTypes.string,
//   yaxistext:PropTypes.string,
//   yaxislabelstyle:PropTypes.object,
//   yaxisstyle:PropTypes.object,
//   xaxistext:PropTypes.string,
//   xaxislabelstyle:PropTypes.object,
//   xaxisstyle:PropTypes.object,
//   title:PropTypes.string,
//   titlecolor:PropTypes.object
// }

// ScatterPlot.defaultProps ={
//   data:[
//     { x: 10, y: 20 },
//     { x: 30, y: 40 }],
//   width:"600", 
//   height:"400", 
//   chartstyle:{color:"#000",hover:"#ccc"},
//   chartdatastyle:{fontsize:"16px",fontweight:"500",color:"#000"},
//   gridcolor:"#ccc",
//   gridstatus:"hidden",
//   yaxistext:"Y Label",
//   yaxislabelstyle:{fontsize:"14px",fontweight:"400",color:"red"},
//   yaxisstyle:{fontsize:"14px",fontweight:"600",color:"#000"},
//   xaxistext:"X Label",
//   xaxislabelstyle:{fontsize:"14px",fontweight:"400",color:"red"},
//   xaxisstyle:{fontsize:"14px",fontweight:"600",color:"#000"},
//   title:"Bar Chart",
//   titlecolor:{fontsize:"24px",fontweight:"600",color:"#000"}
// }

export default ScatterPlot;
