import moment from 'moment';
import { showToaster, toasterTypes } from '../widgets';

export const expectedDateFormat = (value, toFormat, fromFormat = '') => {
    if (fromFormat) {
        return moment(value, fromFormat).format(toFormat)
    } else {
        return moment(value).format(toFormat)
    }
}

export const copyData = (value, hideToast) => {
    console.log('value, hideToast',value, hideToast)
    var textField = document.createElement('textarea');
    textField.innerText = value;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove(); 
    if(!hideToast) {
        showToaster(toasterTypes.SUCCESS, 'Copied Successfully');
    }
}