import { Button, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function InviteSuccessMobile(){

    const navigate = useNavigate();

    const redirectAction = () =>{
        navigate("/project");
    }
    return(
        <>
            <div className="invite-success-container-mob">
                    <div className="success-image-sec">
                         <div className="success-checkmark">
                            <div className="check-icon">
                                <span className="icon-line line-tip"></span>
                                <span className="icon-line line-long"></span>
                                <div className="icon-circle"></div>
                                <div className="icon-fix"></div>
                            </div>                           
                        </div>                     
                    </div>
                    <div className="sucess-message">
                        <span className="message-invite">Invite Sent</span>
                    </div>
                    <div className="sucess-message-txt">
                        <span className="message-invite-txt">Successfully</span>
                    </div>

                    <div className="sucess-message-email">
                        <span className="message-invite-email">sample@gmail.com</span>
                    </div>
                              
                           
                    {/* <div className="sucess-message">
                        <span className="message-invite">Invite Sent</span>
                        <br></br>
                        <span className="success-text">Successfully</span>
                        <div className="sucess-email">
                        <span className="sent-email">samplework@gmail</span>
                    </div>
                    </div> */}
                   
                    <div className="timer-sec">
                        <Button onClick={() => redirectAction()}>Continue to Dashboard</Button>
                    </div>

            </div>
        </>
    );
}