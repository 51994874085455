import React from "react";
import ReactDOM from "react-dom/client";
import App from "./components/App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store/store";
import { FullScreenLoader } from "./components/common/FullScreenLoader/fullScreenLoader";
import "./fantasticon/icons-dist/icons.css";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { createRoot } from "react-dom/client";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <App />
      <FullScreenLoader />
    </Provider>
  // </React.StrictMode>
);

 // If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();