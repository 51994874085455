import { cloneDeep } from 'lodash';
import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { showToaster, toasterTypes } from '..';
import { MESSAGES } from '../../utils/Constants';


export default function FileUploadAll(props: any) {
    const [files, setfiles] = useState([]);

    function onUpload(acceptedFiles: any) {
        let files = props.acceptFiles.split('|');
        // for (let j = 0; j < acceptedFiles.length; j++) {
        //     if (!files.includes(acceptedFiles[j].type.split('/')[1])) {
        //         let fileTypes = props.acceptFiles.split('|');
        //         let message = '';
        //         if (!props.messages?.acceptFiles && fileTypes.length > 1) {
        //        // if (fileTypes.length > 1) {
        //             for (let i = 0; i < fileTypes.length; i++) {
        //                 if (i === (fileTypes.length - 1)) {
        //                     message = message + " and " + fileTypes[i];
        //                 }
        //                 else if (i === 0) {
        //                     message = fileTypes[i];
        //                 }
        //                 else {
        //                     message = message + ", " + fileTypes[i];
        //                 }
        //             }
        //         } else {
        //             message = fileTypes[0];
        //         }
        //         showToaster(toasterTypes.ERROR, props.messages?.acceptFiles || MESSAGES.ALLOWED_TYPES.replace('#{value}', message));
        //         return;
        //     } else if (acceptedFiles[j].size > props.maxSize) {
        //         showToaster(toasterTypes.ERROR, props.messages?.maxSize || MESSAGES.MAX_SIZE.replace('#value', props.maxSize));
        //         return;
        //     }
        // };
        setfiles(acceptedFiles);
        props.onUpload(acceptedFiles);
        showToaster(toasterTypes.SUCCESS, "File Uploaded Successfully!");
    }
    const deleteFile = (index: any, e: any) => {
        let temp = cloneDeep(files);
        temp.splice(index, 1);
        setfiles(temp);
        e.stopPropagation();
    }

    return (
        <div className='custom-file-upload-all'>
            <Dropzone onDrop={acceptedFiles => onUpload(acceptedFiles)} disabled={props.isDisabled}>
                {({ getRootProps, getInputProps }) => (
                    <section>
                        <div {...getRootProps()} className={`${(props.touched && props.error) ? 'error-field' : ''} upload-container`}>
                            <input {...getInputProps()} />

                            {files.length === 0 ?
                                <p><span className='upload-placeholder'>{props.placeholder}</span><u className='browse-text'>Browse Files</u></p> :
                                <div>
                                    {files.map((obj: any, index) => (
                                        <div  key={index}>{obj.name}
                                            <img alt="close" src={`${process.env.PUBLIC_URL}/images/close_circle.svg`} onClick={(e) => deleteFile(index, e)} />
                                        </div>
                                    ))}
                                </div>
                            }

                        </div>
                    </section>
                )}
            </Dropzone>
        </div>
    )
}
