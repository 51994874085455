import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'reactstrap'
import { setLoading } from '../../store/slices/auth';
import { CustomButton, showToaster, toasterTypes } from '../../widgets';
import { FieldItem } from '../../widgets/fields'
import useFieldItem from '../../widgets/fields/UseFieldItem';
import { getColleges, getCountries, getStates } from '../profile/apiController';
import { getProjectTypes } from './apiController';
import { AffiliationFormElements } from './FormElements'

export default function Affiliation(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [projectTypesList, setprojectTypesList] = useState([]);
    const [countryList, setcountryList] = useState([]);
    const [stateList, setstateList] = useState([]);
    const [collegeList, setcollegeList] = useState([]);

    const initialValues = useMemo(() => (
        props.previousState ?
            props.previousState :
            { country: null, state: null, city: '', college: null, projectType: null, funding: null, fundType: null, quantity: '', fundingAgency: '', approvalStatus: null }
    ), [props.previousState]);
    const [values, fields, handleChange, { validateValues, updateValue }] = useFieldItem(AffiliationFormElements, initialValues, { onValueChange });

    useEffect(() => {
        if (props.previousState?.countryList) {
            setcountryList(props.previousState.countryList);
            setstateList(props.previousState.stateList);
            setcollegeList(props.previousState.collegeList);
            setprojectTypesList(props.previousState.projectTypesList);
        } else {
            fetchProjectTypes();
            fetchCountries();
        }
        if (props.previousState?.funding && props.previousState?.funding?.value && !props.previousState.funding.label) {
            let funding = AffiliationFormElements.funding.values.filter(obj => obj.value === props.previousState.funding.value)[0];
            let fundType = AffiliationFormElements.fundType.values.filter(obj => obj.value === props.previousState.fundType.value)[0];
            let approvalStatus = AffiliationFormElements.approvalStatus.values.filter(obj => obj.value === props.previousState.approvalStatus.value)[0];
            updateValue({ funding, fundType, quantity: props.previousState.quantity, fundingAgency: props.previousState.fundingAgency, approvalStatus })
        }
    }, []);

    const fetchProjectTypes = async () => {
        try {
            dispatch(setLoading(true));
            let response = await getProjectTypes();
            let list: any = response.data?.map(res => ({ label: res.project_type, value: res.id })) || [];
            setprojectTypesList(prevData => list);
            if (props.previousState && !props.previousState.projectType.label) {
                updateValue({ projectType: list.filter(obj => obj.value === props.previousState.projectType.value)[0] });
            }
            dispatch(setLoading(false));
        } catch (e: any) {
            setprojectTypesList([]);
            dispatch(setLoading(false));
        }
    }

    const fetchCountries = async () => {
        try {
            dispatch(setLoading(true));
            let response = await getCountries();
            let list: any = response.data?.map(res => ({ label: res.Country, value: res.CountryCode })) || [];
            setcountryList(list);
            if (props.previousState && !props.previousState.country.label) {
                updateValue({ country: list.filter(obj => obj.value === props.previousState.country.value)[0] });
                fetchStates(props.previousState.country.value);
            }
            dispatch(setLoading(false));
        } catch (e: any) {
            setcountryList([]);
            dispatch(setLoading(false));
        }
    }

    const fetchStates = async (value) => {
        try {
            dispatch(setLoading(true));
            let response = await getStates(value);
            let list: any = response.data?.map(res => ({ label: res.State, value: res.StateCode })) || [];
            setstateList(list);
            if (props.previousState && !props.previousState.state.label) {
                updateValue({ state: list.filter(obj => obj.value === props.previousState.state.value)[0] });
                fetchColleges(props.previousState.state.value);
            }
            dispatch(setLoading(false));
        } catch (e: any) {
            setstateList([]);
            dispatch(setLoading(false));
        }
    }

    const fetchColleges = async (value) => {
        try {
            dispatch(setLoading(true));
            let response = await getColleges(value);
            setcollegeList(response.data?.map(res => ({ label: res.College, value: res.CollegeCode })) || []);
            dispatch(setLoading(false));
        } catch (e: any) {
            setcollegeList([]);
            dispatch(setLoading(false));
        }
    }

    function onValueChange(name, value, values, fieldValues = null) {
        let resetValue = {};
        if (name === 'country') {
            fetchStates(value.value);
            resetValue = { ...resetValue, state: null, college: null }
        } else if (name === 'state') {
            fetchColleges(value.value);
            resetValue = { ...resetValue, college: null }
        }
        return [{
            ...values,
            ...resetValue,
            [name]: value,
        }]
    }

    const next = () => {
        if (validateValues(["country", "state", "city", "projectType", "funding"])) {
            showToaster(toasterTypes.ERROR, "Please enter the proper values in the fields highlighted in red");
        } else if (values?.funding?.value === 'yes' && values?.fundType?.value != 'self' && validateValues(["fundType", "quantity", "fundingAgency", "approvalStatus"])) {
            showToaster(toasterTypes.ERROR, "Please enter the proper values in the fields highlighted in red");
        } else if (values?.funding?.value === 'yes' && values?.fundType?.value === 'self' && validateValues(["quantity"])) {
            showToaster(toasterTypes.ERROR, "Please enter the proper values in the fields highlighted in red");
        } else {
            let pageState = {
                country: values.country,
                state: values.state,
                city: values.city,
                college: values.college,
                projectType: values.projectType,
                funding: values.funding,
                fundType: values.fundType,
                quantity: values.quantity,
                fundingAgency: values.fundingAgency,
                approvalStatus: values.approvalStatus,
                countryList,
                stateList,
                projectTypesList,
                collegeList
            }
            props.next(pageState);
        }
    };

    return (
        <div className='affiliation-page'>
            <div className='page-header-project'>
                <img src={`${process.env.PUBLIC_URL}/images/coguide_main_logo.png`} alt='logo' className='logo' />
                <div className='header-text'>{props.projectData ? 'Edit Project' : 'Create Project'}</div>
            </div>
            <div className='affiliation-container'>
                <div>
                    <div className='page-text'>Affiliation & Funding info</div>
                    <div className='page-sub-text'>Affiliation info</div>
                    <Row>
                        <Col className='mb-3' md={4}>
                            <FieldItem
                                {...AffiliationFormElements.country}
                                value={values.country}
                                values={countryList}
                                onChange={(...e) => handleChange(AffiliationFormElements.country.name, ...e)}
                                touched={fields.country && fields.country.hasError}
                                error={fields.country && fields.country.errorMsg}
                            />
                        </Col>
                        <Col className='mb-3' md={4}>
                            <FieldItem
                                {...AffiliationFormElements.state}
                                value={values.state}
                                values={stateList}
                                onChange={(...e) => handleChange(AffiliationFormElements.state.name, ...e)}
                                touched={fields.state && fields.state.hasError}
                                error={fields.state && fields.state.errorMsg}
                            />
                        </Col>
                        <Col className='mb-3' md={4}>
                            <FieldItem
                                {...AffiliationFormElements.city}
                                value={values.city}
                                onChange={(...e) => handleChange(AffiliationFormElements.city.name, ...e)}
                                touched={fields.city && fields.city.hasError}
                                error={fields.city && fields.city.errorMsg}
                            />
                        </Col>
                        <Col md={8}>
                            <FieldItem
                                {...AffiliationFormElements.college}
                                value={values.college}
                                values={collegeList}
                                onChange={(...e) => handleChange(AffiliationFormElements.college.name, ...e)}
                                touched={fields.college && fields.college.hasError}
                                error={fields.college && fields.college.errorMsg}
                            />
                        </Col>
                        <Col className='mb-3' md={4}>
                            <FieldItem
                                {...AffiliationFormElements.projectType}
                                value={values.projectType}
                                values={projectTypesList}
                                onChange={(...e) => handleChange(AffiliationFormElements.projectType.name, ...e)}
                                touched={fields.projectType && fields.projectType.hasError}
                                error={fields.projectType && fields.projectType.errorMsg}
                            />
                        </Col>
                    </Row>
                    <div className='page-sub-text'>Funding Details</div>
                    <Row>
                        <Col className='mb-3' md={4}>
                            <FieldItem
                                {...AffiliationFormElements.funding}
                                value={values.funding}
                                onChange={(...e) => handleChange(AffiliationFormElements.funding.name, ...e)}
                                touched={fields.funding && fields.funding.hasError}
                                error={fields.funding && fields.funding.errorMsg}
                            />
                        </Col>
                        <Col className='mb-3' md={4}>
                            <FieldItem
                                {...AffiliationFormElements.fundType}
                                value={values.fundType}
                                isDisabled={values?.funding?.value === 'no'}
                                onChange={(...e) => handleChange(AffiliationFormElements.fundType.name, ...e)}
                                touched={fields.fundType && fields.fundType.hasError}
                                error={fields.fundType && fields.fundType.errorMsg}
                            />
                        </Col>
                        <Col className='mb-3' md={4}>
                            <FieldItem
                                {...AffiliationFormElements.quantity}
                                value={values.quantity}
                                isDisabled={values?.funding?.value === 'no'}
                                onChange={(...e) => handleChange(AffiliationFormElements.quantity.name, ...e)}
                                touched={fields.quantity && fields.quantity.hasError}
                                error={fields.quantity && fields.quantity.errorMsg}
                            />
                        </Col>
                        <Col md={8}>
                            <FieldItem
                                {...AffiliationFormElements.fundingAgency}
                                value={values.fundingAgency}
                                isDisabled={values?.funding?.value === 'no' || (values?.funding?.value === 'yes' && values?.fundType?.value === 'self')}
                                onChange={(...e) => handleChange(AffiliationFormElements.fundingAgency.name, ...e)}
                                touched={fields.fundingAgency && fields.fundingAgency.hasError}
                                error={fields.fundingAgency && fields.fundingAgency.errorMsg}
                            />
                        </Col>
                        <Col className='mb-3' md={4}>
                            <FieldItem
                                {...AffiliationFormElements.approvalStatus}
                                value={values.approvalStatus}
                                isDisabled={values?.funding?.value === 'no' || (values?.funding?.value === 'yes' && values?.fundType?.value === 'self')}
                                onChange={(...e) => handleChange(AffiliationFormElements.approvalStatus.name, ...e)}
                                touched={fields.approvalStatus && fields.approvalStatus.hasError}
                                error={fields.approvalStatus && fields.approvalStatus.errorMsg}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
            <Row className='affiliation-footer m-0'>
                <Col className='p-0'>
                <CustomButton className='cancel-text' onClick={() => navigate('/project')} text="Cancel"></CustomButton>
                </Col>
                <Col className='p-0 d-flex m-0 justify-content-end'>
                    <CustomButton type="primary" onClick={() => next()} className="w-100" text="Next"></CustomButton>
                </Col>
            </Row>
            <div className='page-footer'>
               
            </div>
        </div>
    )
}
