import axios, { AxiosRequestConfig, AxiosResponse, AxiosRequestHeaders } from 'axios';
import { environment } from '../environments/environment';
import loaderActions from '../components/common/FullScreenLoader/fullScreenLoader';
import { showToaster, toasterTypes } from "../widgets";
import * as CryptoJS from 'crypto-js';
import { isArray } from 'lodash';
import { useNavigate } from 'react-router-dom';

export interface Options {
  method?: any,
  data?: any,
  params?: any,
  isAuth?: boolean,
  isProfile?: boolean,
  isfile?: boolean,
  isAnalysis?: boolean,
  isAnalysisAI?: boolean,
  isDocument?: boolean,
  isBlob?: boolean,
  showError?: boolean,
  isUid?:boolean,
  isHeaders?:boolean,
  responseType?: any,
  contentType?: any
}
export interface SendRequest {
  url: string,
  request: Object,
  showLoading: boolean,
  options: Options
}

export class AjaxUtil {
  constructor() {
    this.sendRequest = this.sendRequest.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  encryptData = (value: string) => {
    let keyStr ="Secret Passphrase";
    var key = CryptoJS.enc.Utf8.parse(keyStr);
    var iv = CryptoJS.enc.Utf8.parse(keyStr);
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key,
    {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    return encrypted.toString();
  }
  gethHeaders(options: Options): AxiosRequestHeaders  {
    let headers: AxiosRequestHeaders  = {
      'Content-Type': options?.contentType || 'application/json'
    }
    if(!options.isAuth) {
      headers  = {...headers, 'Authorization': `Bearer ${localStorage.getItem('coguide_token')}`}
    }
    return headers;
  }

  getRmsHeaders(options: Options): AxiosRequestHeaders  {
    let headers: AxiosRequestHeaders  = {
      'Content-Type': options?.contentType || 'application/json'
    }
    if(!options.isAuth) {
      headers  = {...headers, 'Authorization': `Bearer ${localStorage.getItem('rms_token')}`}
    }
    return headers;
  }
  
  async sendRequest(url: string, request?: Object | null, showLoading: boolean=true, options: Options = {}) {
    const config: AxiosRequestConfig = {
      method: options?.method || 'get',
      baseURL: options.isDocument ? environment.documentUrl : options.isAnalysisAI ? environment.anaylsisAiUrl : options.isProfile ? environment.profileUrl : options.isAuth ? environment.authUrl : options.isAnalysis ? environment.anaylsisUrl : environment.baseUrl,
      url: url,
      data: request || null,
      headers: this.gethHeaders(options),
      params: options.params,
      responseType: options.isBlob ? 'blob' : options?.responseType || 'json'
    };
    if(showLoading) loaderActions.show('Loading...');

    return await axios(config).then((response: AxiosResponse) => {
      if(options.isAuth){
        localStorage.setItem("coguide_token", response.headers['token']);
				localStorage.setItem("coguide_expire", response.headers['expire']);
        localStorage.setItem('coguide_user', JSON.stringify(response.data["data"]));
      };

      
      loaderActions.hide();
      //response.data.head = response?.headers
      return options.isHeaders ? response: response.data;
    }).catch((err) => {
      loaderActions.hide();
      if(err?.response?.data.code === 401) {
        localStorage.removeItem("coguide_token");
				localStorage.removeItem("coguide_expire");
        localStorage.removeItem('coguide_user');
        window.location.replace("/login");
        showToaster(toasterTypes.ERROR, err?.response?.data?.message || 'Something went wrong');
        return;
      }
      if(options.showError){
        showToaster(toasterTypes.ERROR, isArray(err?.response?.data?.error) ? err?.response?.data?.error[0] : err?.response?.data?.message || 'Something went wrong');
      }
      throw err;
    });
  }


  async sendRequestFile(url: string, request?: Object | null, showLoading: boolean=true, options: Options = {}) {
    const config: AxiosRequestConfig = {
      method: options?.method || 'get',
      baseURL: options.isProfile ? environment.profileUrl : options.isAuth ? environment.authUrl : environment.baseUrl,
      url: url,
      data: request || null,
      headers: this.gethHeaders(options),
      params: options.params,
      responseType: 'arraybuffer'
    };
    console.log(options?.responseType)
    if(showLoading) loaderActions.show('Loading...');

    return await axios(config).then((response: AxiosResponse) => {
      if(options.isAuth){
        localStorage.setItem("coguide_token", response.headers['token']);
				localStorage.setItem("coguide_expire", response.headers['expire']);
        localStorage.setItem('coguide_user', JSON.stringify(response.data["data"]));
      };

      
      loaderActions.hide();
      //response.data.head = response?.headers
      return options.isHeaders ? response: response.data;
    }).catch((err) => {
      loaderActions.hide();
      if(err?.response?.data.code === 401) {
        localStorage.removeItem("coguide_token");
				localStorage.removeItem("coguide_expire");
        localStorage.removeItem('coguide_user');
        window.location.replace("/login");
        showToaster(toasterTypes.ERROR, err?.response?.data?.message || 'Something went wrong');
        return;
      }
      if(options.showError){
        showToaster(toasterTypes.ERROR, isArray(err?.response?.data?.error) ? err?.response?.data?.error[0] : err?.response?.data?.message || 'Something went wrong');
      }
      throw err;
    });
  }

  async fetchData(url: string, request?: Object | null, showLoading: boolean=true, options: Options = {}) {
    const config: AxiosRequestConfig = {
      method: 'get',

      //  baseURL: 'https://reapv2.coguide.in',
      //  baseURL: 'https://reaptest.coguide.in',
    baseURL: 'https://reapdev.coguide.in',
    //  baseURL: 'http://localhost:3000',
      url: url,
      data: null,
      headers: this.gethHeaders(options),
      params: options.params,
      responseType: options?.responseType || 'json'
    };
    if(showLoading) loaderActions.show('Loading...');
  
    return await axios(config).then((response: AxiosResponse) => {
      loaderActions.hide();
      return response.data;
    }).catch((err) => {
      loaderActions.hide();
      if(options.showError){
        showToaster(toasterTypes.ERROR, err?.response?.data?.message || 'Something went wrong');
      }
      throw err;
    });
  }

  async fetchDocumentData(url: string, request?: Object | null, showLoading: boolean=true, options: Options = {}) {
    const config: AxiosRequestConfig = {
      method: 'get',
      baseURL: environment.rmsBaseUrl,
      url: url,
      data: null,
      headers: this.getRmsHeaders(options),
      params: options.params,
      responseType: options?.responseType || 'json'
    };
    if(showLoading) loaderActions.show('Loading...');
  
    return await axios(config).then((response: AxiosResponse) => {
      loaderActions.hide();
      return response.data;
    }).catch((err) => {
      loaderActions.hide();
      if(options.showError){
        if(url.includes("c1")){
          showToaster(toasterTypes.ERROR, err?.response?.data?.message || 'Something went wrong');
        }
      }
      throw err;
    });
  }
}


export default new AjaxUtil();
