import AjaxUtil from '../../axios/axios';

/**
 * get course list for lms 
 */
export const getLMSCourses = async(userid) => {    
    try {
        return await AjaxUtil.sendRequest(`lmscourses/${userid}`, {}, false, { method: 'get', showError:true, isProfile:false});
    } catch (error) {
        throw error;
    }
 }

 /**
 * get LMS course modules list 
 */
export const getLMSCourseModule = async(id,userid) => {    
    try {
        return await AjaxUtil.sendRequest(`lmscourseModules/${userid}/${id}`, {}, false, { method: 'get', showError:true, isProfile:false});
    } catch (error) {
        throw error;
    }
 }

/**
 * post the use activity lms data
 */
export const postUserActivityLms = async(uid,moduleid,id,data) => {  
    try {
        return await AjaxUtil.sendRequest(`lmsActivity/${uid}/${moduleid}/${id}`, data, false, { method: 'put', showError:true, isProfile:false});
    } catch (error) {
        throw error;
    }
 }

 /**
 * post the use test activity lms data
 */
export const postUserTestActivityLms = async(data) => {    
    try {
        return await AjaxUtil.sendRequest(`lmstestActivity`, data, false, { method: 'put', showError:true, isProfile:false});
    } catch (error) {
        throw error;
    }
 }