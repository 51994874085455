import { demo } from "../demo";

/**
   * fucntion to push data to specific index
   * @FirstIndex : return the index of First section under which data to be added
   * @NextIndex : return next heading2 indexs
   * @FindNext : return the next heading index
   * splice the data to add to specific index
   * */
const PushToEditor = (newData, section) => {
  let documentSelected = localStorage.getItem('documentSelected');
  let parsedJSON = JSON.parse(localStorage.getItem(documentSelected));

  let demoData = parsedJSON;
  console.log(demoData)
  console.log(section)
  if (section != "newsection") {
    let FirstIndex = demoData.content.findIndex((item, i) => item.type == "heading2" && item.content && item.content[0].text == section)
    let NextIndex = demoData.content.slice(FirstIndex + 1) && demoData.content.slice(FirstIndex + 1).filter((item, i) => item.type == "heading2")
     console.log(section,FirstIndex,NextIndex)

    let FindNext = NextIndex.length > 0 ? demoData.content.findIndex((item, i) => item.type == "heading2" && item.content && item.content[0].text == (NextIndex && NextIndex[0].content && NextIndex[0].content[0].text)) : FirstIndex;
    //adding one pragraph with content to create space above the
    // let paragraph = {
    //   "type": "paragraph",
    //   "attrs": {
    //     "id": "",
    //     "class": "paragraph",
    //     "track": [],
    //     "group": "",
    //     "viewid": ""
    //   },
    // }

    if (NextIndex.length > 0) {
      //demoData.content.splice(FindNext - 1, 0, paragraph);
      demoData.content.splice(FindNext - 1, 0, newData);
    } else {
     // demoData.content.splice(demoData.content.length, 0, paragraph);
      demoData.content.splice(demoData.content.length, 0, newData);
    }
    console.log(demoData)
    localStorage.setItem(documentSelected, JSON.stringify(demoData))
  } else if (section == "newsection") {
    console.log(demoData)

    if(demoData != null){
      console.log(demoData,newData)
      demoData.content.splice(demoData.content.length, 0, newData);
      console.log(demoData)
      localStorage.setItem(documentSelected, JSON.stringify(demoData))

    }else{
      demoData = {
        type:"doc",
        content:[]
      }
      console.log(demoData)

      demoData.content.splice(demoData.content.length,0,newData);
      console.log(demoData)
      localStorage.setItem(documentSelected, JSON.stringify(demoData))

    }
  }
  return "success";
}

export default PushToEditor;