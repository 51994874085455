import React, { Suspense, useEffect, useMemo, useState } from "react";
import { Col, Image, Row } from "react-bootstrap";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import { AnalysisFormElements } from "./FieldElements";
import { FieldItem } from "../../widgets/fields";
import AnalysisVideo from "./AnalysisVideo";
import VariableList from "./VariableList";
import AddConfiguration from "./AddConfiguration";
import { getAnalysisChartTypes, getAnalysisTypes, getAnalysisVariables, getOutcomeVariable, postSelectedVariables, updateSelectedConfig } from "./apiController";
import { useDispatch } from "react-redux";
import { setLoading } from "../../store/slices/auth";
import { Loader } from "../common/FullScreenLoader/fullScreenLoader";
import { CustomButton, showToaster, toasterTypes } from "../../widgets";
import ConfigVariableModal from "./ConfigVariablesModal";
import { Label } from "reactstrap";
import { SelectChartModalMobile } from "./SelectChartModalMobile";
import ConfigurationListMobile from "./ConfigurationListMobile";


export default function ConfigureAnalysisMobile(props) {
    const initialValues = useMemo(() => (
        { analysisName: "", analysisType: "" }
    ), [props.previousState]);
    const [values, fields, handleChange, { validateValues, reset, updateValue, updateError }] = useFieldItem(AnalysisFormElements, initialValues, { onValueChange });
    const [projectData, SetProjectData] = useState<any>([]);
    const [popupType, SetPopupType] = useState("");
    const [selectedVariables, SetSelectedVariable] = useState<any>([]);
    const [selectedChart, SetSelectedChart] = useState<any>([]);
    const [videoStatus, SetVideoStatus] = useState(false);
    const [anaylsisType, SetAnalysisType] = useState<any>([]);
    const [isConfig, SetConfig] = useState(false);
    const [savedFieldId, SetSavedFieldId] = useState<any>("");
    const [selectedItem, SetselectedItem] = useState<any>({});
    const [count, Setcount] = useState<any>(0);
    const [outComeData, setoutComeData] = useState<any>("");
    const [Clicked, setClicked] = useState(false);
    const dispatch = useDispatch();
    const { data, group, formData } = props;

    useEffect(() => {
        console.log(props)
        fetchInitialData();
        SetProjectData(props.data)
    }, [])

    useEffect(() => {
    }, [selectedVariables, count])

    /**
     * fetch initial data
     */
    const fetchInitialData = async () => {
        try {
            dispatch(setLoading(true));

            let response = await getAnalysisTypes();
            SetAnalysisType(response.map(({
                key: value,
                name: label
            }) => ({
                value,
                label
            })))

            if (props?.selectedItem) {
                await fetchVariableData();
                SetSavedFieldId(props?.selectedItem.id)
                let initalValue = response.filter(item => item.name == props?.selectedItem.type)
                updateValue({ analysisName: props?.selectedItem.name, analysisType: { label: initalValue[0].name, value: initalValue[0].key } })
            }

            dispatch(setLoading(false))
        } catch (e: any) {
            dispatch(setLoading(false))
            console.log(e)
        }
    }
    /**
     * handle on change values of form elements
     * @param name 
     * @param value 
     * @param values 
     * @param fieldValues 
     * @returns 
     */
    function onValueChange(name, value, values, fieldValues = null) {
        const resetValue = {}
        console.log(values)
        return [{
            ...values,
            ...resetValue,
            [name]: value,
        }]
    }



    /**
    * get variable list data
    */
    const fetchVariableData = async () => {
        try {
            dispatch(setLoading(true));
            console.log(props)
            let variablesresponse = await getAnalysisVariables(props?.formData?.id);
            let outComeVariables = props?.selectedItem.id && await getOutcomeVariable(props?.selectedItem.id);
            let chartList = await getAnalysisChartTypes();
            setoutComeData(outComeVariables);

            console.log(variablesresponse, outComeVariables, chartList)
            //SetSelectedVariable
            let selectedVariables: any = [];
            outComeVariables && outComeVariables.fields.map(async (items, i) => {
                let variableValue = variablesresponse && variablesresponse.data.filter(filteritem => filteritem.id == items.field_id)[0];
                console.log(items)
                let chartsArray: any = [];
                await items.chart_type && items.chart_type.map((charts) => {
                    let chartFilteredItem = chartList.filter(chartitem => chartitem.key == charts);
                    chartsArray.push({
                        label: chartFilteredItem[0].name,
                        value: chartFilteredItem[0].key
                    })
                })
                selectedVariables.push({
                    "field_type": variableValue.field_type,
                    "field_use_type": variableValue.field_use_type,
                    "field_value": variableValue.field_value,
                    "label": variableValue.field_description,
                    "value": variableValue.id,
                    "charts": chartsArray
                });
            });
            SetSelectedVariable(selectedVariables);
            dispatch(setLoading(false))
        } catch (e: any) {
            dispatch(setLoading(false))
            console.log(e)
        }
    }

    const watchVideo = () => {
        SetPopupType("video")
    }

    const onClose = () => {
        SetPopupType("")
        fetchInitialData();
    }

    const onCloseChart = () => {
        SetPopupType("")
    }

    /**
     * call back function to props to get video status
     * @param status 
     */
    const getVideoStatus = (status) => {
        SetVideoStatus(status)
    }

    /**
     * onclick add configuration redirect to page
     */
    const addConfiguration = (id) => {
        SetSavedFieldId(id);
        SetConfig(true)
    }

    /**
      * set variables on click of add
      * get selcted variables from modal and update the state
    */
    const addVariables = (data) => {
        console.log(data)
        SetSelectedVariable(data)
    }

    /**
     * add charts
     */
    const addChartAction = (type) => {
        SetselectedItem(type)
        SetPopupType("chart")
    }

    /**
     * get charts selected
     */
    const getChartSelected = (data) => {
        let tempVariable = selectedVariables;
        var variableIndex = tempVariable.findIndex(function (varItem) {
            return varItem.value == selectedItem.value;
        });
        let Obj = tempVariable[variableIndex];
        tempVariable.splice(variableIndex, 1);
        Obj.charts = data;
        tempVariable.splice(variableIndex, 0, Obj)
        //add chart

        console.log(tempVariable)
        SetSelectedChart(data)
    }

    /**
     * remove variable from list
     */
    const removeVariableItem = (value) => {
        let removedVaraibles = selectedVariables.filter(item => item.value != value)
        SetSelectedVariable(removedVaraibles)
    }

    /**
     * remove chart item
     */
    const removeChartItem = (chart, item) => {
        let tempVariable = selectedVariables;
        var variableIndex = tempVariable.findIndex(function (varItem) {
            return varItem.value == item.value;
        });
        let Obj = tempVariable[variableIndex];
        tempVariable.splice(variableIndex, 1);
        Obj.charts = Obj.charts.filter(itemchart => itemchart.value != chart.value);
        tempVariable.splice(variableIndex, 0, Obj)
        console.log(tempVariable)
        SetSelectedVariable(tempVariable)
        Setcount(count + 1)
    }

    /**
    * on save submit fields
    */
    const saveFields = async () => {
        try {
            setClicked(true);
            dispatch(setLoading(true))
            let fieldsArray: any = [];
            selectedVariables && selectedVariables.map((item, i) => {
                let charts: any = [];
                item.charts && item.charts.map(chartitem => {
                    charts.push(chartitem.value)
                })

                if (values.analysisType.label == "Descriptive Analysis") {
                    fieldsArray.push({
                        "field_id": item?.value,
                        "chart_type": charts
                    })
                } else {
                    fieldsArray.push({
                        "field_id": item?.value
                    })
                }
            })
            console.log(values, data)

            let jsonObject = {
                "name": values.analysisName,
                "topic_id": group?.topicid,
                "type": values.analysisType.label,
                "group_id": group.id,
                "form_id": formData.id,
                "fields": fieldsArray
            }
            console.log(jsonObject)

            let submitResponse;
            if (Object.keys(props?.selectedItem).length != 0) {
                // jsonObject['configs'] = outComeData.configs;
                //console.log(outComeData)
                submitResponse = await updateSelectedConfig(outComeData?.id, jsonObject)
            } else {
                submitResponse = await postSelectedVariables(jsonObject);
            }

            if (submitResponse['success']) {
                if (values.analysisType.label == "Descriptive Analysis") {
                    if (Object.keys(props?.selectedItem).length != 0) {
                        showToaster(toasterTypes.SUCCESS, "Updated Descriptive Variables")
                    } else {
                        showToaster(toasterTypes.SUCCESS, "Saved Descriptive Variables")
                    }
                    SetSavedFieldId(submitResponse.id);
                } else {
                    console.log(submitResponse['id'])
                    SetSavedFieldId(submitResponse.id);
                    if (Object.keys(props?.selectedItem).length != 0) {
                        showToaster(toasterTypes.SUCCESS, "Updated Inferential Variables")
                    } else {
                        showToaster(toasterTypes.SUCCESS, "Saved Inferential Variables")
                    }
                }
            }
            dispatch(setLoading(false))
        } catch (error) {
            dispatch(setLoading(false))
            setClicked(false)
        }
    }

    /**
     * on add config open modal
     */
    const addConfigurationAction = async () => {
        SetPopupType("addconfig");
    }

    return (
        <>
            {
                popupType == "variable" ?
                    <ConfigVariableModal
                        data={props}
                        analyseType={values?.analysisType}
                        addVariable={addVariables}
                        selectedPrevData={selectedVariables}
                        onClose={onCloseChart}
                    />
                    :
                    popupType == "chart" ?
                        <SelectChartModalMobile
                            data={props}
                            selectedItem={selectedItem}
                            getSelectedData={getChartSelected}
                            onClose={onCloseChart} />
                        :
                        ""
            }

            {popupType == "video" ?
                <AnalysisVideo getVideoStatus={getVideoStatus} onClose={() => onClose()} />
                :
                popupType == "addconfig" ?
                    <ConfigurationListMobile {...props} configData={outComeData} id={savedFieldId} onClose={() => onClose()} />
                    :
                    <div className="analysis-container-mobile">
                        <div className="header-container">
                            <div className='pl-3 pt-4 form-name'>
                                <Image className='profile-image' alt='Profile' src={`${process.env.PUBLIC_URL}/images/logo.png`} />
                                Data Analysis
                            </div>
                            <div className='pt-4 closeicon'>
                                <i className='icon icon-close' onClick={() => props.onClose()} />
                            </div>
                        </div>
                        <div className="analysis-body">
                            <Row className="m-0 analysis-selector">
                                <Col className="mt-1" md={4}>
                                    <FieldItem
                                        {...AnalysisFormElements.analysisName}
                                        value={values.analysisName}
                                        isDisabled={isConfig}
                                        onChange={(...e) => handleChange(AnalysisFormElements.analysisName.name, ...e)}
                                        touched={fields.analysisName && fields.analysisName.hasError}
                                        error={fields.analysisName && fields.analysisName.errorMsg}
                                    />
                                </Col>
                                <Col className="mt-1" md={3}>
                                    <FieldItem
                                        {...AnalysisFormElements.analysisType}
                                        value={values.analysisType}
                                        values={anaylsisType}
                                        isDisabled={isConfig}
                                        onChange={(...e) => handleChange(AnalysisFormElements.analysisType.name, ...e)}
                                        touched={fields.analysisType && fields.analysisType.hasError}
                                        error={fields.analysisType && fields.analysisType.errorMsg}
                                    />
                                </Col>
                                {values && values?.analysisType?.value == "inferential_analysis" ?
                                    <Col className="mt-2">
                                        <div className="inferential-question">
                                            Do you know your outcome variable?
                                            {!videoStatus ?
                                                <>
                                                    <span style={{ color: "green", cursor: "pointer", padding: "0px 10px" }}>Yes</span>
                                                    <span style={{ color: "red", cursor: "pointer", padding: "0px 10px" }} onClick={() => watchVideo()}>No</span>
                                                </>
                                                :
                                                   <span style={{ color: "blue", cursor: "pointer", padding: "0px 10px" }} onClick={() => watchVideo()}>See Video</span>
                                            }
                                        </div>
                                    </Col>
                                    : ""}
                            </Row>
                        </div>
                        <div className="forms-container">
                            <div className="ml-3 mr-3 mt-1 p-4 variables" >
                                <div className="variable-head" onClick={() => SetPopupType("variable")}>
                                    <div style={{ width: "95%" }}>Variables</div>
                                    <div><i className='icon icon-add'></i></div>
                                </div>

                                <div className="variable-content">
                                    {
                                        selectedVariables && selectedVariables.map((items, i) => {
                                            return <div key={i}>
                                                <div className="variable-title">
                                                    <div style={{ width: "95%" }}>{items.label}</div>
                                                    <div><i className='icon icon-close' onClick={() => removeVariableItem(items.value)}></i></div>
                                                </div>
                                                <div className="data">
                                                    {
                                                        items.field_value && items.field_value.map(options => {
                                                            return <div className="options">
                                                                {options}
                                                            </div>
                                                        })
                                                    }
                                                </div>


                                                {
                                                    values && values.analysisType.value == "descriptive_analysis" ?
                                                        <>
                                                            <div className="chart-head" onClick={() => addChartAction(items)}>
                                                                <div style={{ width: "95%", color: "black" }}><Label style={{ fontSize: "12px" }}>Select Chart</Label></div>
                                                                <div><i className='icon icon-add'></i></div>Add
                                                            </div>
                                                            <div className="select-chart">
                                                                {
                                                                    items.charts && items?.charts.map(charts => {
                                                                        return <div className="chart-type">
                                                                            {charts.label}
                                                                            <i className='icon icon-close' onClick={() => removeChartItem(charts, items)}></i>
                                                                        </div>
                                                                    })
                                                                }


                                                            </div>
                                                            <hr></hr>
                                                        </>
                                                        : ""
                                                }

                                            </div>
                                        })
                                    }
                                </div>

                            </div>
                            <div className="submit-container mt-2 ml-3">
                                { selectedVariables.length > 0 &&
                                    <CustomButton  type="primary" text={Object.keys(props?.selectedItem).length != 0 && savedFieldId ? "Update" : "Submit"} onClick={() => saveFields()} />
                                }                          
                                {
                                    savedFieldId && selectedVariables.length > 0 && values.analysisType.value == "inferential_analysis" ?
                                    <CustomButton  type="primary" text="Add Configuration" onClick={() => addConfigurationAction()} /> : ""
                                }
                            </div>
                        </div>

                    </div>
            }
        </>
    )
}