import { ArrowLeftOutlined, CheckCircleFilled, CheckOutlined, ClockCircleFilled, CloseCircleOutlined, CodeOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom"
import { LoadingMessage } from "react-select/dist/declarations/src/components/Menu";
import { setLoading } from "../../store/slices/auth";
import { expectedDateFormat } from "../../utils/Utils";
import { CustomButton, showToaster, toasterTypes } from "../../widgets";
import { FieldItem, FIELD_TYPES } from "../../widgets/fields";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import { Loader } from "../common/FullScreenLoader/fullScreenLoader";
import { ProjectList } from "../dataCollection/FormElements";
import { getUserProjects } from "../project/apiController";
import AddDocument from "./AddDocument";
import { getDoctypes, getProjectEventDetail, getRegisteredProjects, RegisterEvent, uploadDocument, uploadSelectedFile } from "./apiController";
import ModalPopup from "../../widgets/modal";
import ModalComponent from "../common/Modal/modal";
import DocumentsModal from "./DocumentsModal";
import { environment } from "../../environments/environment";


export default function SubmissionDocument() {
    const initialValues = React.useMemo(() => ({ project: '' }), []);
    const [values, fields, handleChange, { validateValues, reset, updateValue, updateError, removeErrors }] =
        useFieldItem({}, {}, { onValueChange });
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [popupType, setpopupType] = useState('');
    const [selectedData, SetSelectedData] = useState<any>([]);
    const [scheduleData, SetScheduleData] = useState<any>([]);
    const [Regproject, SetRegProjects] = useState<any>([]);
    const [projectlist, SetProjectlist] = useState<any>([]);
    const [docTypes, SetDocTypes] = useState<any>([]);
    var selectedParentIndex: any = null;
    const [document, SetDocument] = useState<any>([]);
    const [active, Setactive] = useState(false);
    const [loader, Setloader] = useState(false);
    const [itemCount, SetItemCount] = useState(0);
    const [addComponent, SetAddComponent] = useState<any>([]);
    const [closeEvent, Setcloseevent] = useState(0);
    const [selectedIndex,setSelectedIndex] = useState('');
    useEffect(() => {
        fetchInitialData();
    }, [])

    useEffect(() => {

    }, [Regproject])

    /**
     * fetch intial data
     * read location data from state shared from navigation
     * update state with schedule api response
     */
    const fetchInitialData = async () => {
        try {
            dispatch(setLoading(true));
            SetProjectlist([]);
            const temp: any = location.state;
            SetSelectedData(temp.data);
            console.log(temp)
            const getSchedule = await getProjectEventDetail(temp.data.project_event_id);
            SetScheduleData(getSchedule.data)
            const getRegisteredProjectData = await getRegisteredProjects(temp.data.registration_id);
            SetRegProjects(getRegisteredProjectData.data);
            const getDocTypesData = await getDoctypes();
            SetDocTypes(getDocTypesData);
            SetDocument(getRegisteredProjectData.data.documents)
            dispatch(setLoading(false));

        } catch (e: any) {
            dispatch(setLoading(false));
            throw e;
        }
    }


    /**
     * On Register trigger
     * @obj
     * @scheduleId Id from schedule data selected id
     * @topicid TopicID of selected project
     * @pieactiveid Id from piractive api response of selected item
     */
    const onSave = async () => {

        let finalDocument = [...document, ...addComponent]
        console.log(finalDocument)
        if (finalDocument[0]?.document_link) {
            dispatch(setLoading(true));
            try {
                let response = await uploadDocument(finalDocument, Regproject.id);
                if (response) {
                    dispatch(setLoading(false));
                    showToaster(toasterTypes.SUCCESS, 'Documents submitted Successfully');
                    const temp: any = location.state;
                    navigate("/project");
                }
            } catch (e: any) {
                throw e
            }
        } else {
            showToaster(toasterTypes.ERROR, "Upload atleast mandatory document")
        }
    }

    /**
     * on skip redirect to submission page
     */
    const onSkip = () => {
        const temp: any = location.state;
        navigate("/registeredevents", { state: temp.projectdata });
    }

    /**
    * Upload the selected file on success update media URL and new_doc as true
    * @param name 
    * @param value 
    * @param values 
    * @param fieldValues 
    * @returns 
    */
    async function onValueChange(name, value, values, fieldValues = null) {
        let resetValue = {};

        if (name == "documentlink") {

        } else {
            const formData = new FormData();
            formData.append("file", value[0]);

            try {
                Setloader(true);
                let response = await uploadSelectedFile(value["0"].name.substr(value["0"].name.lastIndexOf('.') + 1), formData)
                if (response.success) {
                    Setactive(true);
                    document.filter(item => item.document_name == name).map(value => {
                        value.document_link = response.mediaUrl
                        value.new_doc = true
                    })
                    //showToaster(toasterTypes.SUCCESS,"File Uploaded successfully")
                }
                Setloader(false);

            } catch (e: any) {
                Setloader(false);
                throw e
            }
        }

        return [{
            ...values,
            ...resetValue,
            [name]: value,
       }]
    }

    /**
     * on Delete action remove component
     */
    const onDeleteAction = (index) => {
        addComponent.splice(index, 1)
        SetItemCount(itemCount - 1)
    }

    /**
     * on close action
     */
    const closeAction = (index) => {
        Regproject.documents[index].document_link = "";
        Regproject.documents[index].validity_status = "";
        Setcloseevent(closeEvent + 1);

    }

    /**
     * Add More actions
     */
    const addMoreAction = () => {
        let obj = {
            "document_name": "",
            "document_link": "",
            "new_doc": true,
            "other_doc": true
        };

        SetAddComponent((prev) => [...prev, obj]);
    }

    /**
     * callback function 
     * get media url from add component
     */
    const getMediaURL = async (url, i) => {
        console.log(url, i)
        const formData = new FormData();
        formData.append("file", url.image.file[0]);
        addComponent[i].document_name = url.doctypelist.label;

        try {
            Setloader(true);
            let response = await uploadSelectedFile(url.image.file[0].name.substr(url.image.file[0].name.lastIndexOf('.') + 1), formData)
            if (response.success) {
                addComponent[i].document_link = response.mediaUrl
            }
            Setloader(false);

        } catch (e: any) {
            Setloader(false);
            throw e
        }
    }   

    const onSelectLink = (id) =>{
        setSelectedIndex(id)
        setpopupType("documents")
    }

    /**
     * get selcted document from modal
     * @param data 
     */
    const getSelectedDoc = (data) => {
        const temp: any = location.state;
        console.log(data)
        console.log(document[selectedIndex])
        document[selectedIndex].document_link = environment.docBaseUrl+`rms&docId=${data.documentlink.value}`
        document[selectedIndex].new_doc = true
        console.log(document)
        SetDocument(document)
        onClose();
    }

    const onClose = () =>{
        setpopupType("")
    }

    return (
        <>
            <div className="submission-container">
                {
                    popupType == "documents" &&
                    <DocumentsModal onClose={onClose} getSelectedDoc={getSelectedDoc} projectData={location.state}/>
                }
                <Row>
                    <Col md={4}>
                        <div className="project-data">
                            <div className="summary-head">
                                <ArrowLeftOutlined onClick={() => onSkip()} />
                                <span style={{ padding: "0px 10px 0px 10px", marginTop: "10px" }}>Schedule Summary</span>
                            </div>
                            <h4 className="project-name">{selectedData && selectedData?.project_event_name}</h4>
                            <hr></hr>
                            <div className="institute-data">
                                <span className="institute-label">Institution Name:</span>
                                <span className="institude-name">{selectedData && selectedData?.institutename}</span>
                            </div>
                            <hr></hr>
                            <div className="document-data">
                                <span className="document-label">Document Submission Deadline:</span>
                                <span className="document-name">{expectedDateFormat(scheduleData && scheduleData?.document_submission_date, 'DD MMM YYYY')}</span>
                            </div>
                            <hr></hr>
                            <Row>
                                <Col md={4}>
                                    <div className="document-data">
                                        <span className="time-label">Schedule Date:</span>
                                        <span className="time-name">{expectedDateFormat(scheduleData?.day_plan && scheduleData?.day_plan?.schedule_date, 'DD MMM YYYY')}</span>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="document-data">
                                        <span className="time-label">From Time:</span>
                                        <span className="time-name">{scheduleData?.day_plan && scheduleData?.day_plan[0]?.start_time}</span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className="document-data">
                                        <span className="time-label">To Time:</span>
                                        <span className="time-name">{scheduleData?.day_plan && scheduleData?.day_plan[0]?.end_time}</span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>

                    <Col md={8}>
                        <div className="doc-detail">
                            <h5 className="doc-head">{Regproject?.project_owner_name}</h5>
                            <h6 className="doc-project">{Regproject?.topicdesc}</h6>
                            <hr></hr>
                            <h6 className="doc-upload">Document Upload</h6>
                            {
                                loader ? <Loader
                                    loaderText="Uploading..." />
                                    :
                                    ""
                            }

                            {
                                document && document?.map((item, i) => {
                                    if (!item.other_doc) {
                                        return <>
                                            <div className="upload-section">
                                                {item.document_link ?
                                                    <>
                                                        <span className="doc-title">{item.document_name}</span>
                                                        <span className="doc-name">{item.document_link.substring(item.document_link.lastIndexOf('/') + 1)}
                                                            {((item.validity_status && item.validity_status.toLowerCase() !== "approved") || (Regproject && Regproject?.submission_status?.toLowerCase() !== "approved")) ? <CloseCircleOutlined className="remove-file" onClick={() => closeAction(i)} /> : ""}</span>

                                                        {item.validity_status && item.validity_status?.toLowerCase() === "approved" && (Regproject?.submission_status?.toLowerCase() === "" || Regproject?.submission_status?.toLowerCase() === "approved") ? <span className="approve-status">Document has been {item.validity_status} </span> : ""}

                                                        {item.validity_status && item.validity_status.toLowerCase() !== "pending" && (Regproject?.submission_status?.toLowerCase() !== "" && Regproject?.submission_status?.toLowerCase() !== "approved") ? "Please review the submitted documents according to the comments of the committee" : ""}

                                                        {Regproject?.submission_status?.toLowerCase() === "" && item.validity_status && item.validity_status.toLowerCase() === "pending" || item.validity_status === "" ? <span className="pending-status">Document review pending</span> : ""}
                                                        {Regproject?.submission_status?.toLowerCase() === "" && item.validity_status && item.validity_status.toLowerCase() === "rejected" ? <div><span className="reject-status">Document has been {item.validity_status}</span><span><strong>Comments:</strong> {item.validator_comments}</span></div> : ""}
                                                        {item.validity_status && item.document_link != "" && item.new_doc == true && item.validity_status.toLowerCase() === "pending" || item.validity_status === "" ? <CheckCircleFilled className="uploaded-file" /> : ""}

                                                    </>
                                                    :

                                                    <>
                                                        <span className="doc-title">{item.document_name}</span>
                                                        <FieldItem
                                                            className="doc-file"
                                                            name={i}
                                                            type={FIELD_TYPES.FILE_UPLOAD_ALL}
                                                            onChange={(...e) => {
                                                                selectedParentIndex = i + 1;
                                                                handleChange(item.document_name, ...e);
                                                            }}
                                                            touched={fields.country && fields.country.hasError}
                                                            error={fields.country && fields.country.errorMsg}
                                                        />
                                                        <span className="m-2" style={{ fontSize: "12px" }}>OR</span>
                                                        <div className="selectDoc mt-0 p-2" onClick={() => onSelectLink(i)}>
                                                            <span>Select Document</span>
                                                        </div>

                                                    </>
                                                }
                                            </div>
                                        </>

                                    }
                                })

                            }



                            <hr></hr>
                            <div className="other-section">
                                <Row>
                                    <Col md={9}>
                                        <h6 className="other-upload">Other Upload</h6>
                                    </Col>

                                    <Col md={3}>
                                        <span
                                            className="addmore-btn"
                                            onClick={() => addMoreAction()}
                                        >Add More+ </span>
                                    </Col>
                                </Row>
                            </div>
                            <div className="upload-section-other">
                                {
                                    Regproject && Regproject?.documents?.map((item, i) => {
                                        if (item.other_doc) {
                                            return <>
                                                <div className="upload-section">
                                                    {item.document_link ?
                                                        <>
                                                            <span className="doc-title">{item.document_name}</span>
                                                            <span className="doc-name">{item?.document_link.substring(item?.document_link?.lastIndexOf('/') + 1)}<CloseCircleOutlined onClick={() => closeAction(i)} /></span>
                                                            <span className="approve-status">{item.validity_status}</span>
                                                        </>
                                                        :

                                                        <>
                                                            <span className="doc-title">{item.document_name}</span>
                                                            <FieldItem
                                                                className="doc-file"
                                                                name={i}
                                                                type={FIELD_TYPES.FILE_UPLOAD_ALL}
                                                                onChange={(...e) => {
                                                                    selectedParentIndex = i + 1;
                                                                    handleChange(item.document_name, ...e);
                                                                }}
                                                                touched={fields.country && fields.country.hasError}
                                                                error={fields.country && fields.country.errorMsg}
                                                            />

                                                        </>
                                                    }
                                                </div>
                                            </>

                                        }
                                    })

                                }
                                {
                                    addComponent && addComponent.map((item, i) => {
                                        return <>
                                            <AddDocument
                                                key={i}
                                                docData={docTypes}
                                                onDelete={() => onDeleteAction(i)}
                                                onDocUpload={getMediaURL}
                                                index={i}
                                            />
                                        </>
                                    })
                                }
                                {/* <span className="doc-title">Cover Letter</span>
                                <FieldItem   
                                    className="doc-file"                             
                                    type={FIELD_TYPES.FILE_UPLOAD}
                                    touched={fields.country && fields.country.hasError}
                                    error={fields.country && fields.country.errorMsg}
                                />                                  */}
                            </div>

                            <div className="doc-summary">
                                <h6 className="summary-title">Document validation status by coordinator:</h6>
                                <span className={Regproject?.document_validity_status?.toLowerCase() == "approved" ? "Forwarded to Committee" :
                                    Regproject?.document_validity_status?.toLowerCase() == "pending" ? "pending" :
                                        Regproject?.document_validity_status?.toLowerCase() == "rejected" ? "reject" :
                                            ""}>{Regproject?.document_validity_status?.toLowerCase() == "approved" ? "Forwarded to Committee" :
                                                Regproject?.document_validity_status || "-"}</span>
                            </div>

                            <div className="doc-summary">
                                <h6 className="summary-title">Document validation inputs by coordinator:</h6>
                                <span className="summary-value">{Regproject?.document_validity_comments || "-"}</span>
                            </div>

                            <div className="doc-summary">
                                <h6 className="summary-title">Committee review status:</h6>
                                <span className="summary-value">{Regproject?.submission_status}</span>
                            </div>

                            <div className="doc-summary">
                                <h6 className="summary-title">Committee review inputs: </h6>
                                {
                                    Regproject.summary && Regproject?.summary.map(item => {
                                        return <span className="summary-value">{item}</span>
                                    })
                                }
                            </div>
                            <div className="submit-bottom">
                                <CustomButton
                                    className="register-btn"
                                    type="primary"
                                    text="Save"
                                    isDisabled={document && document[0]?.document_link != "" ? false : true}
                                    onClick={() => onSave()}
                                ></CustomButton>
                            </div>
                        </div>
                    </Col>
                </Row>

            </div>
        </>
    )
}