import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from './en.json';
import id from './id.json';
i18n
    .use(initReactI18next) //passes i18n down to react-i18next
    .init({
        debug: true,
        lng: 'id',
        fallbackLng: 'id',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: en
            },
            id: {
                translation: id
            }
        },
         // have a common namespace used around the full app
        ns: ["translation"],
        defaultNS: ["translation"],
    })

export default i18n;