import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { MarkType } from 'prosemirror-model';
import { WaxContext } from 'wax-prosemirror-core';


const VersionSection = () => {
     const {
          app,
          pmViews: { main },
          activeViewId,
          activeView,
          placeholderPlugin
      } = useContext(WaxContext);

     const navigate = useNavigate();
     const params = new URLSearchParams(window.location.href);
     const docId = params.get('docId');
     const VersionAction = () => {
          navigate('/version',{state:"version"})
     }


     return (
          <>
               <div className="version-container">
                         <div title="Version history" className={docId != '' && docId != null ? 'version-disable p-2' : 'version p-2'} onClick={() => docId != '' && docId != null ? "" : VersionAction()}>Versions</div>
                    </div>
             
          </>
     )
}

export default VersionSection;