import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { setLoading } from '../../store/slices/auth';
import { expectedDateFormat } from '../../utils/Utils';
import { CustomButton, showToaster, toasterTypes } from '../../widgets';
import CustomTable from '../../widgets/table';
import { createOrder, createSubscriptionOrder, getActivePlans, getDiscount, getPayment, getSubscriptions, updateOrder } from './apiController';
import useRazorpay from "react-razorpay";
import ModalPopup from '../../widgets/modal';

export default function Subscriptions() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const Razorpay = useRazorpay();
    const columns = [
        {
            "id": "paymentid",
            "label": "Payment Id",
            "accessor": "paymentid",
            "sort": "asc"
        },
        {
            "id": "CreatedDate",
            "label": "Transaction Date",
            "accessor": (row: any) => {
                return expectedDateFormat(row.CreatedDate, 'DD MMM YYYY')
            },
            "sort": "asc"
        },
        {
            "id": "ExpiryDate",
            "label": "Expiry Date",
            "accessor": (row: any) => {
                return expectedDateFormat(row.ExpiryDate, 'DD MMM YYYY')
            },
            "sort": "asc"
        },
        {
            "id": "plan",
            "label": "Plan",
            "accessor": "plan",
            "sort": "asc"
        },
        {
            "id": "amount",
            "label": "Amount",
            "accessor": "amount",
            "sort": "asc"
        }
    ]
    const images = [`${process.env.PUBLIC_URL}/images/bg_1.svg`, `${process.env.PUBLIC_URL}/images/bg_2.svg`, `${process.env.PUBLIC_URL}/images/bg_3.svg`]
    const [paymentList, setpaymentList] = useState([]);
    const [plansData, setplansData] = useState([]);
    const [isAddPlan, setisAddPlan] = useState(false);
    const [subscriptions, setsubscriptions] = useState<any | []>([]);
    const [selectedPlan, setselectedPlan] = useState<any | null>(null);
    const [couponCode, setcouponCode] = useState("");
    const [discount, setdiscount] = useState(0);
    const [userData, setuserData] = useState<any | null>(null);
    const [popupType, setpopupType] = useState('');

    useEffect(() => {
        fetchActivePlans();
        fetchPaymentList();
    }, []);

    const fetchPaymentList = async () => {
        try {
            let userData: any = localStorage.getItem('coguide_user');
            if (userData) {
                userData = JSON.parse(userData);
                dispatch(setLoading(true));
                setuserData(userData);
                let response = await getPayment(userData.UserNumber);
                setpaymentList(response.data);
                dispatch(setLoading(false));
            } else {
                navigate('/login');
            }
        } catch (e: any) {
            setpaymentList([]);
            dispatch(setLoading(false));
        }
    }

    const fetchActivePlans = async () => {
        try {
            let userData: any = localStorage.getItem('coguide_user');
            if (userData) {
                userData = JSON.parse(userData);
                dispatch(setLoading(true));
                let response = await getActivePlans();
                if(response.data?.length > 0) {
                    setplansData(response.data);
                } else {
                showToaster(toasterTypes.ERROR, response.message || 'Something went wrong');
                }
                dispatch(setLoading(false));
            } else {
                navigate('/login');
            }
        } catch (e: any) {
            setplansData([]);
            dispatch(setLoading(false));
        }
    }

    const fetchSubscriptions = async() => {
        try {
            dispatch(setLoading(true));
            let response = await getSubscriptions();
            let temp: any = [];
            response.data.forEach((obj: any, index) => {
                obj.image = images[index%3];
                temp.push(obj)
            });
            setsubscriptions(temp);
            setisAddPlan(true)
            dispatch(setLoading(false));
        } catch (e: any) {
            dispatch(setLoading(false));
        }
    }

    const getRemaining = () => {
        let temp = cloneDeep(subscriptions);
        temp.splice(0, 1);
        return temp;
    }

    const onKeyDown = (e) => {
        if(e.key === 'Enter') {
            onEnterCoupon();
        }
    }

    const onEnterCoupon = async() => {
        try {
            dispatch(setLoading(true));
            let response = await getDiscount(couponCode, selectedPlan.type);
            let totalAmount = selectedPlan.total_amount + (selectedPlan.tax?.gst || 0);
            let discountValue = response?.data?.discount || 0;
            setdiscount(totalAmount * discountValue/100);
            if(response.data.discount === 0) {
			    showToaster(toasterTypes.ERROR, response.data.message);
            }
            dispatch(setLoading(false));
        } catch (e: any) {
            dispatch(setLoading(false));
        }
    }

    const createSubscription = async() => {
        try {
            dispatch(setLoading(true));
            let response = await createSubscriptionOrder(userData.UserNumber, selectedPlan.type);
            if(response.new_sub) {
                pay();
            } else {
                showToaster(toasterTypes.ERROR, 'Subscription already exists');
                setpaymentList([]);
                setplansData([]);
                setisAddPlan(false);
                setsubscriptions([]);
                setselectedPlan(null);
                setcouponCode("");
                setdiscount(0);
                setuserData(null);
                setpopupType('');
                fetchActivePlans();
                fetchPaymentList();
            }
        } catch (e: any) {
            dispatch(setLoading(false));
        }
    }

    const pay = async() => {
        try {
            let reqObj = {
                "currency": "INR",
                "amount": selectedPlan.total_amount + (selectedPlan.tax?.gst || 0) - discount,
                "plan": selectedPlan.type,
                "UserNumber": userData.UserNumber,
                "topicId": selectedPlan.type,
            }
            dispatch(setLoading(true));
            let response = await createOrder(reqObj);
            if(reqObj.amount === 0) {
                updateOrderData(response.data);
            } else {
                doPayment(response.data)
            }
        } catch (e: any) {
            dispatch(setLoading(false));
        }
    }

    const doPayment = (data) => {
        const options: any = {
            "key": "rzp_live_P1Wkm8qXNacGYs",
            "name": "coGuide",
            "description": "coGuide Event Payment",
            "order_id": data.id,
            "currency":"INR",
            "prefill": {
                "name": userData.FullName,
                "email": userData.EmailID,
                "contact": userData.MobileNumber
            },
            handler: async(res) => {
                updateOrderData(data, res);
            },
            "modal": {
                "ondismiss": function(){
                    dispatch(setLoading(false));
                }
            } ,
            "theme": {
                "color": "#0000ff"
            }
          };
          const rzpay = new Razorpay(options);
          rzpay.open();
    }

    const updateOrderData = async(data, res: any ='') => {
        try {
            let updatereqObj = {
                ...data,
                UserNumber: userData.UserNumber,
                paymentId: res?.razorpay_payment_id || '100% discount',
                id: res?.razorpay_order_id || '100DISC',
                orderId: res?.razorpay_order_id || '100DISC'
            }
            let response = await updateOrder(updatereqObj);
            if(response.message === 'Created') {
                showToaster(toasterTypes.SUCCESS, 'Payment Successful');
                setpaymentList([]);
                setplansData([]);
                setisAddPlan(false);
                setsubscriptions([]);
                setselectedPlan(null);
                setcouponCode("");
                setdiscount(0);
                setuserData(null);
                setpopupType('');
                fetchActivePlans();
                fetchPaymentList();
            }
            dispatch(setLoading(false));
        } catch(e: any) {
            dispatch(setLoading(false));
        }
    }


    const scrollTop =() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }
    return (
        <div className='subscriptions-page'>
            {popupType &&
                <ModalPopup
                    modalType='confirm'
                    modalText={`Payment is successfull.\nYour payment id is " + ${popupType}`}
                    actionText=""
                    buttonText="Ok"
                    closeModal={() => { setpopupType(''); setisAddPlan(false); }}
                    onAction={() => { setpopupType(''); setisAddPlan(false); }}
                />
            }
            {isAddPlan ?
                <>
                    <div className='subscription-header mb-3'>
                        <div className='text-right'>
                            <i className='icon icon-close' onClick={() => setisAddPlan(false)} />
                        </div>
                        <Row className='m-0 w-100 d-flex align-items-center'>
                            <Col className='p-0'>
                                <div className='subscriptions-text'>Subscription Plans</div>
                            </Col>
                            <Col className='p-0 text-right'>
                                <img src={`${process.env.PUBLIC_URL}/images/coguide_main_logo.png`} alt='logo' className='logo' />
                            </Col>
                        </Row>
                    </div>
                    {subscriptions.length > 0 &&
                        <Row className='p-3'>
                            <Col>
                                <div className='subscription-card'>
                                    <Row className='m-0 pl-4 pr-4'>
                                        <Col className='p-0' xs={8}>
                                            <div className='subscription-title'>{subscriptions[0].header}</div>
                                            <div className='subscription-planType'>{subscriptions[0].plan_type}</div>
                                        </Col>
                                        <Col className='p-0' xs={4}>
                                            <div className='subscription-amount'>₹ {subscriptions[0].total_amount}</div>
                                            <div className='subscription-payType'>{subscriptions[0].pay_type}</div>
                                        </Col>
                                    </Row>
                                    {subscriptions[0].data.map((obj, index) => (
                                        <div key={index} className="subscription-option pl-4 pr-4">{obj}</div>
                                    ))}
                                    <div className='subscription-footer' style={{backgroundImage: 'url(' + subscriptions[0].image + ')'}}>
                                        <CustomButton type="alert-primary" text="Add Plan" onClick={() => { setcouponCode(''); setselectedPlan(subscriptions[0]); scrollTop(); }}></CustomButton>
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div className='selected-card'>
                                    <div className='selected-text'>Selected Plan</div>
                                    {selectedPlan &&
                                        <Row className='m-0'>
                                            <Col className='p-0' xs={8}>
                                                <div className='selected-plan'>{selectedPlan.header}</div>
                                            </Col>
                                            <Col className='p-0' xs={4}>
                                                <div className='selected-amount'>₹ {selectedPlan.total_amount}</div>
                                            </Col>
                                        </Row>
                                    }
                                    {selectedPlan &&
                                        <Row className='m-0 tax-border'>
                                            <Col className='p-0' xs={8}>
                                                <div className="selected-tax-label">GST</div>
                                            </Col>
                                            <Col className='p-0' xs={4}>
                                                <div className="selected-tax-value">₹ {selectedPlan.tax.gst}</div>
                                            </Col>
                                        </Row>
                                    }
                                    {selectedPlan &&
                                        <Row className='m-0 coupon-row'>
                                            <Col className='p-0' xs={8}>
                                                <input
                                                    value={couponCode}
                                                    placeholder="COUPON CODE"
                                                    className="coupon-input"
                                                    onKeyDown={(e) => onKeyDown(e)}
                                                    onChange={(e) => setcouponCode(e.target.value)}
                                                />
                                            </Col>
                                            <Col className='p-0' xs={4}>
                                                <div className="discount-value">- ₹ {discount}</div>
                                            </Col>
                                        </Row>
                                    }
                                    {selectedPlan &&
                                        <Row className='m-0 total-row'>
                                            <Col className='p-0' xs={8}>
                                                <div className='selected-plan'>Total</div>
                                            </Col>
                                            <Col className='p-0' xs={4}>
                                                <div className='total-amount'>₹ {selectedPlan.total_amount + (selectedPlan.tax?.gst || 0) - discount}</div>
                                            </Col>
                                        </Row>
                                    }
                                    <Row className='selected-footer m-0'>
                                        <Col className='p-0 pr-2'>
                                            <CustomButton type="alert-primary" text="Cancel" onClick={() => setselectedPlan(null)}></CustomButton>
                                        </Col>
                                        <Col className='p-0 pl-2'>
                                            <CustomButton type="primary" text="Pay" onClick={() => createSubscription()}></CustomButton>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    }
                    <Row className='p-3'>
                        {getRemaining().map((obj, index) => (
                            <Col key={index} xs={6}>
                                <div className='subscription-card mb-4'>
                                    <Row className='m-0 pl-4 pr-4'>
                                        <Col className='p-0' xs={8}>
                                            <div className='subscription-title'>{obj.header}</div>
                                            <div className='subscription-planType'>{obj.plan_type}</div>
                                        </Col>
                                        <Col className='p-0' xs={4}>
                                            <div className='subscription-amount'>₹ {obj.total_amount}</div>
                                            <div className='subscription-payType'>{obj.pay_type}</div>
                                        </Col>
                                    </Row>
                                    {obj.data.map((subObj, subIndex) => (
                                        <div key={subIndex} className="subscription-option pl-4 pr-4">{subObj}</div>
                                    ))}
                                    <div className='subscription-footer' style={{backgroundImage: 'url(' + obj.image + ')'}}>
                                        <CustomButton type="alert-primary" text="Add Plan" onClick={() => { setcouponCode(''); setselectedPlan(obj);scrollTop(); }}></CustomButton>
                                    </div>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </> :
                <div className='p-3 m-3'>
                    {plansData.length === 0 ?
                        <div className='plans-container'>
                            <div className='no-plan-text mb-2'>No Subscription or plans Available</div>
                            <CustomButton type="alert-primary" text="Add Plan" onClick={() => fetchSubscriptions()}></CustomButton>
                        </div> :
                        null
                    }
                    <CustomTable header="Invoice" data={paymentList ? paymentList : []} tableProps={columns} isSearch={true} isFilter={true}></CustomTable>
                </div>
            }
        </div>
    )
}
