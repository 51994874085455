import FormItem from "antd/es/form/FormItem";
import { update } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLoading } from "../../store/slices/auth";
import { CustomButton, showToaster, toasterTypes } from "../../widgets";
import { FieldItem, FIELD_TYPES } from "../../widgets/fields";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import { getProjectTypes } from "../createProject/apiController";
import { AffiliationFormElements } from "../createProject/FormElements";
import { getColleges, getCountries, getStates } from "../profile/apiController";


export default function AffiliationFunding(props) {
    const initialValues = useMemo(() => (props.data.affiliation ? props.data.affiliation: { country: null, state: null, city: '', college: null, projectType: null, funding: null, fundType: null, quantity: '', fundingAgency: '', approvalStatus: null }), [])
    const [values, fields, handleChange, { validateValues, reset, updateValue }] = useFieldItem(AffiliationFormElements, initialValues, { onValueChange });
    const dispatch = useDispatch();
    const [projectTypesList, setprojectTypesList] = useState([]);
    const [countryList, setcountryList] = useState([]);
    const [stateList, setstateList] = useState([]);
    const [collegeList, setcollegeList] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        console.log(props.data.affiliation)
       
        fetchIntials();
      

    }, []);

    const fetchIntials = async () => {
        await fetchProjectTypes();
        await fetchCountries();
        updateValue({city:props.data.affiliation.city})
        if (props.data.affiliation?.funding?.value && !props.data.affiliation?.funding.label) {
            console.log(props.data.affiliation.funding)
            let funding = AffiliationFormElements.funding.values.filter(obj => obj.value === props.data.affiliation.funding.value);
            let fundType = AffiliationFormElements.fundType.values.filter(obj => obj.value === props.data.affiliation.fundType.value);
            let approvalStatus = AffiliationFormElements.approvalStatus.values.filter(obj => obj.value === props.data.affiliation.approvalStatus.value);
            updateValue({ funding, fundType, quantity: props.data.affiliation.quantity, fundingAgency: props.data.affiliation.fundingAgency, approvalStatus })
        }
    }
    /**
     * get project types
     */
    const fetchProjectTypes = async () => {
        try {
            dispatch(setLoading(true));
            let response = await getProjectTypes();
            let list: any = response.data?.map(res => ({ label: res.project_type, value: res.id })) || [];
            setprojectTypesList(list);
            console.log(list)
            if (props.data.affiliation && props.data?.affiliation?.projectType) {
                updateValue({ projectType: list.filter(obj => obj.value === props.data?.affiliation?.projectType.value)[0] });
                //updateValue({ projectType: props.data?.affiliation?.projectType});
            }
            dispatch(setLoading(false));
        } catch (e: any) {
            setprojectTypesList([]);
            dispatch(setLoading(false));
        }
    }

    /**
     * get countries data 
     */
    const fetchCountries = async () => {
        try {
            dispatch(setLoading(true));
            let response = await getCountries();
            let list: any = response.data?.map(res => ({ label: res.Country, value: res.CountryCode })) || [];
            setcountryList(list);
            console.log(list)
            console.log(props.data.affiliation.country)
            if (props.data.affiliation && props.data.affiliation.country) {
                updateValue({ country: list.filter(obj => obj.value === props.data.affiliation.country.value)[0] });
                //updateValue({ country: props.data.affiliation.country });
                fetchStates(props.data.affiliation.country.value);

            }
            dispatch(setLoading(false));
        } catch (e: any) {
            setcountryList([]);
            dispatch(setLoading(false));
        }
    }

    /**
     * fetch states list depending on the country
     * @param value 
     */
    const fetchStates = async (value) => {
        console.log(value)
        try {
            dispatch(setLoading(true));
            let response = await getStates(value);
            let list: any = response.data?.map(res => ({ label: res.State, value: res.StateCode })) || [];
            setstateList(list);
            if (props.data.affiliation && props.data.affiliation.state) {
                await updateValue({ state: list.filter(obj => obj.value === props.data.affiliation.state.value)[0]});
                //updateValue({ state: props.data.affiliation.state});
                await fetchColleges(props.data.affiliation.state.value);
            }
            dispatch(setLoading(false));
        } catch (e: any) {
            setstateList([]);
            dispatch(setLoading(false));
        }
    }

    /**
     * fetc college list
     * @param value 
     */
    const fetchColleges = async (value) => {
        try {
            dispatch(setLoading(true));
            let response = await getColleges(value);
            setcollegeList(response.data?.map(res => ({ label: res.College, value: res.CollegeCode })) || []);
            if (props.data.affiliation && props.data.affiliation.college) {
                updateValue({ college: props.data.affiliation.college});
            }

            dispatch(setLoading(false));
        } catch (e: any) {
            setcollegeList([]);
            dispatch(setLoading(false));
        }
    }

    /**
     * on value change get data and updateValues
     */
    function onValueChange(name, value, values, fieldValues = null) {
        let resetValue = {};    
        if (name === 'country') {
            fetchStates(value.value);
            resetValue = { ...resetValue, state: null, college: null }
        } else if (name === 'state') {
            fetchColleges(value.value);
            resetValue = { ...resetValue, college: null }
        }
        return [{
            ...values,
            ...resetValue,
            [name]: value,
        }]
    }

    /**
     * on next action validate form
     * pass values to parent props
     * navigate to next page
     */
    const next = () => {
        if (validateValues(["country", "state", "city", "college", "projectType", "funding"])) {
            showToaster(toasterTypes.ERROR, "Please enter the proper values in the fields highlighted in red");
        } else if (values?.funding?.value === 'yes' && values?.fundType?.value != 'self' && validateValues(["fundType", "quantity", "fundingAgency", "approvalStatus"])) {
            showToaster(toasterTypes.ERROR, "Please enter the proper values in the fields highlighted in red");
        } else if (values?.funding?.value === 'yes' && values?.fundType?.value === 'self' && validateValues(["quantity"])) {
            showToaster(toasterTypes.ERROR, "Please enter the proper values in the fields highlighted in red");
        } else {
            props.next(values,"affiliation");
        }
    }

    return (
        <>
            <div className="affiliation-fund-container">
                <div className="affiliation">
                    <h5>Affilitaion Info</h5>

                    <Row>
                        <Col className='mb-3' md={4}>
                            <FieldItem
                                {...AffiliationFormElements.country}
                                value={values.country}
                                values={countryList}
                                onChange={(...e) => handleChange(AffiliationFormElements.country.name, ...e)}
                                touched={fields.country && fields.country.hasError}
                                error={fields.country && fields.country.errorMsg}
                            />
                        </Col>
                        <Col className='mb-3' md={4}>
                            <FieldItem
                                {...AffiliationFormElements.state}
                                value={values.state}
                                values={stateList}
                                onChange={(...e) => handleChange(AffiliationFormElements.state.name, ...e)}
                                touched={fields.state && fields.state.hasError}
                                error={fields.state && fields.state.errorMsg}
                            />
                        </Col>
                        <Col className='mb-3' md={4}>
                            <FieldItem
                                {...AffiliationFormElements.city}
                                value={values.city}
                                onChange={(...e) => handleChange(AffiliationFormElements.city.name, ...e)}
                                touched={fields.city && fields.city.hasError}
                                error={fields.city && fields.city.errorMsg}
                            />
                        </Col>
                        <Col md={8}>
                            <FieldItem
                                {...AffiliationFormElements.college}
                                value={values.college}
                                values={collegeList}
                                onChange={(...e) => handleChange(AffiliationFormElements.college.name, ...e)}
                                touched={fields.college && fields.college.hasError}
                                error={fields.college && fields.college.errorMsg}
                            />
                        </Col>
                        <Col className='mb-3' md={4}>
                            <FieldItem
                                {...AffiliationFormElements.projectType}
                                value={values.projectType}
                                values={projectTypesList}
                                onChange={(...e) => handleChange(AffiliationFormElements.projectType.name, ...e)}
                                touched={fields.projectType && fields.projectType.hasError}
                                error={fields.projectType && fields.projectType.errorMsg}
                            />
                        </Col>
                    </Row>

                    <h5>Funding</h5>
                    <Row>
                        <Col className='mb-3' md={4}>
                            <FieldItem
                                {...AffiliationFormElements.funding}
                                value={values.funding}
                                onChange={(...e) => handleChange(AffiliationFormElements.funding.name, ...e)}
                                touched={fields.funding && fields.funding.hasError}
                                error={fields.funding && fields.funding.errorMsg}
                            />
                        </Col>
                        <Col className='mb-3' md={4}>
                            <FieldItem
                                {...AffiliationFormElements.fundType}
                                value={values.fundType}
                                isDisabled={values?.funding?.value === undefined || values?.funding?.value === 'no'}
                                onChange={(...e) => handleChange(AffiliationFormElements.fundType.name, ...e)}
                                touched={fields.fundType && fields.fundType.hasError}
                                error={fields.fundType && fields.fundType.errorMsg}
                            />
                        </Col>
                        <Col className='mb-3' md={4}>
                            <FieldItem
                                {...AffiliationFormElements.quantity}
                                value={values.quantity}
                                isDisabled={values?.funding?.value === undefined || values?.funding?.value === 'no'}
                                onChange={(...e) => handleChange(AffiliationFormElements.quantity.name, ...e)}
                                touched={fields.quantity && fields.quantity.hasError}
                                error={fields.quantity && fields.quantity.errorMsg}
                            />
                        </Col>
                        <Col md={8}>
                            <FieldItem
                                {...AffiliationFormElements.fundingAgency}
                                value={values.fundingAgency}
                                isDisabled={values?.funding?.value === undefined || values?.funding?.value === 'no' || (values?.funding?.value === 'yes' && values?.fundType?.value === 'self')}
                                onChange={(...e) => handleChange(AffiliationFormElements.fundingAgency.name, ...e)}
                                touched={fields.fundingAgency && fields.fundingAgency.hasError}
                                error={fields.fundingAgency && fields.fundingAgency.errorMsg}
                            />
                        </Col>
                        <Col className='mb-3' md={4}>
                            <FieldItem
                                {...AffiliationFormElements.approvalStatus}
                                value={values.approvalStatus}
                                isDisabled={values?.funding?.value === undefined || values?.funding?.value === 'no' || (values?.funding?.value === 'yes' && values?.fundType?.value === 'self')}
                                onChange={(...e) => handleChange(AffiliationFormElements.approvalStatus.name, ...e)}
                                touched={fields.approvalStatus && fields.approvalStatus.hasError}
                                error={fields.approvalStatus && fields.approvalStatus.errorMsg}
                            />
                        </Col>
                    </Row>

                </div>

            </div>
            <div className="footer-button">
                <Row className='affiliation-footer m-0'>
                    <Col className='p-0'>
                        <div className='cancel-text' onClick={() => navigate('/project')}>Cancel</div>
                    </Col>
                    <Col className='p-0 d-flex m-0 justify-content-end'>
                        <CustomButton type="primary" onClick={() => next()} className="w-100" text="Next"></CustomButton>
                    </Col>
                </Row>
            </div>
        </>
    )
}