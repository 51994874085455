import React, {useEffect, useMemo, useState} from 'react'
import { Col, Row } from 'reactstrap'
import { useDispatch } from 'react-redux';
import {FieldItem} from "../../widgets/fields";
import { FormElements } from './FormElements';
import useFieldItem from "../../widgets/fields/UseFieldItem";
import {setLoading} from "../../store/slices/auth";
import {getEvents} from "./apiController";
import {CustomButton} from "../../widgets";


interface IPriceProps {
    price: number;
}



const Price = (props: IPriceProps) => {

    return <>
        <span>
            <span>&#x20B9;</span>
            <span>{props.price}</span>
        </span>
    </>
}

export default function Events() {
    const [bookEvent,setbookEvent] = useState(true)
    const dispatch = useDispatch();
    const initialValues = useMemo(() => ({
        searchField: '',
    }), []);
    const [values, fields, handleChange, { validateValues, reset, updateValue, resetValue }] = useFieldItem(FormElements, initialValues, {});
    const [events,setevents] = useState([])

    useEffect(() => {
        fetchEvents(1);
    }, []);


    const fetchEvents = async(value, type='') => {
        try {
            dispatch(setLoading(true));
            let reqObj:  any = {
                "search_word": type ? '' : values.searchField,
                "Scopus": values.availableIndex.includes('scopus'),
                "PubMed": values.availableIndex.includes('pubmed'),
                "PMC": values.availableIndex.includes('pmx'),
                "Medline": values.availableIndex.includes('medline'),
                "Embase": values.availableIndex.includes('embase'),
                "Wos": values.availableIndex.includes('wos'),
                "DOAJ": values.availableIndex.includes('doaj'),
                "L_Cite_score": parseInt(values.cityMin || 0),
                "H_Cite_score": parseInt(values.cityMax || 0),
                "L_Impact_factor": parseInt(values.impactMin || 0),
                "H_Impact_factor": parseInt(values.impactMax || 0)
            }
            let response = await getEvents(value, reqObj);
            response.data.forEach(obj => {
                obj.available = [];
                obj.notAvailable = [];
                obj[obj.Scopus ? 'available' : 'notAvailable'].push('Scopus Indexed');
                obj[obj.PubMed ? 'available' : 'notAvailable'].push('PubMed Indexed');
                obj[obj.PMC ? 'available' : 'notAvailable'].push('PMC Indexed');
                obj[obj.Medline ? 'available' : 'notAvailable'].push('Medline Indexed');
                obj[obj.Embase ? 'available' : 'notAvailable'].push('Embase Indexed');
                obj[obj.Wos ? 'available' : 'notAvailable'].push('Wos Indexed');
                obj[obj.DOAJ ? 'available' : 'notAvailable'].push('DOAJ Indexed');
                obj[obj['Open Access'] ? 'available' : 'notAvailable'].push('Open Access');
                obj[obj['Submission fee'] ? 'available' : 'notAvailable'].push('Pay Submission');
            })
            setevents(response.data || []);
           /* setcurrentPage(value);
            settotalPages(response?.total_pages || 0);*/
            dispatch(setLoading(false));
        } catch (e: any) {
            setevents([]);
            dispatch(setLoading(false));
        }
    }

    const clearSearch = () => {
        resetValue({searchField: ''});
        fetchEvents(1, 'clear');
    }

    const cardArray = [
        {date:'29-09-2022',text:'Sample size calculation for your thesis',price:'299'},
        {date:'29-09-2022',text:'Sample size calculation for your thesis',price:'299'},
        {date:'29-09-2022',text:'Sample size calculation for your thesis',price:'299'},
        {date:'29-09-2022',text:'Sample size calculation for your thesis',price:'299'}]

    const eventConditin = () => {
console.log(bookEvent)
        setbookEvent(!bookEvent)
    }

    if(bookEvent){

        return(
            <div className ='event-page'>
                <Row className ='event-header'>
                    <Col className='col-md-12'>
                        <div className="card text-white event-header-card">
                            <img src="/images/BG.png" className="card-img" alt="..."></img>
                            <div className="card-img-overlay">
                                <h5 className="card-title header-title">Events</h5>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className='middle-row'>
                    <Col className='p-0' md={1}>
                        <div className='label-text'>Events</div>
                    </Col>
                    <Col className='event-search' md={4}>
                        <FieldItem
                            {...FormElements.searchField}
                            value={values.searchField}
                            onChange={(...e) => handleChange(FormElements.searchField.name, ...e)}
                            onClear={() => clearSearch()}
                            onKeyUp={() => fetchEvents(1)}
                        />
                    </Col>
                    <Col md={6}>
                        <div className='dropdown-header'>
                            <div className="dropdown">
                                <button className="btn btn-secondary dropdown-toggle" type="button"
                                        id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">
                                    Dropdown button
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a className="dropdown-item" href="#">Action</a>
                                    <a className="dropdown-item" href="#">Another action</a>
                                    <a className="dropdown-item" href="#">Something else here</a>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className='event-card-parent'>

                            <Row className='event-cards'>
                                <Col className='p-0' md={12}>
                                    {
                                        cardArray.map((arrVal) => {
                                            console.log('arrVal',arrVal)
                                            return(
                                    <div className='event-card-column'>
                                        <div className="card">
                                            <img src="/images/BG.png" className="card-img" alt="..." style={{height: "100%"}}></img>
                                            <div className="card-body">
                                                <Row>
                                                    <Col className='event-card-date' md={7}>{arrVal.date}</Col>
                                                    <Col className='event-card-btn' md={5}>
                                                        <div className='row m-0 justify-content-center'>
                                                            {/*onClick={() => fetchEvents(1)}*/}
                                                            <CustomButton type="primary"  text="Book" onClick={eventConditin}/>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <div className='event-footer'>
                                            <div className='event-footer-text'>{arrVal.text}</div>
                                            <div className='event-footer-price'>{arrVal.price}</div>
                                        </div>

                                    </div>
                                            )
                                        })
                                    }
                                </Col>
                            </Row>
                </div>
            </div>

        )
    }else{
        return(
            <div className='event-conditions-page'>
                <div className='event-back'>
                    <button onClick={()=>setbookEvent(!bookEvent)}>Back</button>
                </div>
                <Row className ='event-conditions-header' >
                    <Col className='col-md-12'>
                        <div className='event-card-text'>
                            Sample size calculation for your thesis
                        </div>
                        <Row>
                            <Col md={6}>
                                <div className='event-card-date'>29-09-2022</div>
                            </Col>
                            <Col md={6}>
                                <div className='event-card-price'>₹299</div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className='event-conditions-content'>
                    <Col className='event-conditions-text' md={6}>
                        <div className='text-header'>
                            Event Conditions
                        </div>
                        <div className='text-header-body'>
                        </div>
                        <div className='text-header-body'>
                        </div>
                    </Col>
                    <Col className='text-black-50 p-4' md={6}>
                        <Row>
                            <Col md={6}>Payment</Col>
                            <Col md={6}></Col>
                        </Row>
                        <Row>
                            <Col md={6}>Event Name</Col>
                            <Col className='text-right' md={6}>
                                <Price price={150}></Price>
                                <span>
                                    <svg id="Delete" xmlns="http://www.w3.org/2000/svg" width="12.922" height="17.786" viewBox="0 0 12.922 17.786">
                                      <g id="Group_5156" data-name="Group 5156">
                                        <g id="Group_5155" data-name="Group 5155" transform="translate(0)">
                                          <path id="Path_2526" data-name="Path 2526" d="M82.349,1.784H79.172V.58a.58.58,0,0,0-.58-.58H74.344a.58.58,0,0,0-.58.58v1.2H70.587a.58.58,0,0,0-.58.58V4.148a.58.58,0,0,0,.58.58H82.349a.58.58,0,0,0,.58-.58V2.364A.58.58,0,0,0,82.349,1.784Zm-4.337,0H74.924V1.16h3.088Z" transform="translate(-70.007 0)" fill="#77838f"/>
                                        </g>
                                      </g>
                                      <g id="Group_5158" data-name="Group 5158" transform="translate(0.805 5.888)">
                                        <g id="Group_5157" data-name="Group 5157" transform="translate(0)">
                                          <path id="Path_2527" data-name="Path 2527" d="M93.192,169.5l.481,11.343a.58.58,0,0,0,.579.555h9.191a.58.58,0,0,0,.579-.555L104.5,169.5Zm3.9,9.539a.58.58,0,0,1-1.16,0v-7.181a.58.58,0,0,1,1.16,0Zm2.333,0a.58.58,0,0,1-1.16,0v-7.181a.58.58,0,0,1,1.16,0Zm2.333,0a.58.58,0,0,1-1.16,0v-7.181a.58.58,0,0,1,1.16,0v7.181Z" transform="translate(-93.192 -169.497)" fill="#77838f"/>
                                        </g>
                                      </g>
                                    </svg>
                                </span>
                            </Col>
                        </Row>


                        <Row>
                            <Col md={12}>
                                <Row className='border-bottom'>
                                    <Col md={6}>Tax Break Up 1</Col>
                                    <Col className='text-right' md={6}>
                                        <Price price={50}></Price>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>Tax Break Up 2</Col>
                                    <Col className='text-right' md={6}>
                                        <Price price={50}></Price>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>Tax Break Up 3</Col>
                                    <Col className='text-right' md={6}>
                                        <Price price={50}></Price>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row className='py-3 border-bottom'>
                            <Col md={8}>
                                <input className='border-0 border-bottom w-75' placeholder='COUPAN CODE'/>
                            </Col>
                            <Col className='text-right' md={4}>
                                <Price price={50}></Price>
                            </Col>
                        </Row>


                        <Row className='py-3 border-bottom'>
                            <Col md={8}>
                                Total
                            </Col>
                            <Col className='text-right' md={4}>
                                <Price price={150}></Price>
                            </Col>
                        </Row>


                        <Row className='py-3 border-bottom'>
                            <Col md={6}>
                                <button className='btn w-100 btn-outline-primary'>Cancel</button>
                            </Col>
                            <Col className='text-right' md={6}>
                                <button className='btn w-100 btn-primary'>Pay</button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        )
    }
}

