import React from 'react'
import {
    Button, Modal, ModalFooter,
    ModalHeader, ModalBody
} from "reactstrap"

function ModalComponent(props) {
    const {
        isOpen,
        onClose,
        width,
        customClass,
        centered,
        footerText
    } = props;
    return (
        <Modal backdrop="static"
            isOpen={isOpen} centered={centered || false}
            toggle={onClose} className={`custom-modal ${customClass ? customClass : ''}`} style={{ maxWidth: width || '400px', width: width || '400px' }}>
            <ModalHeader>
                <span>
                    {props.header ? props.header : 'Header'}
                </span>

                <i className="icon icon-close" onClick={onClose}></i>
            </ModalHeader>
            <ModalBody>
                {props.body ? props.body :
                    'Sample Modal Body Text to display'}
            </ModalBody>
            <ModalFooter>
                {props.footer ? props.footer :
                    <Button color="primary" onClick={onClose}>{footerText || 'Close'}</Button>}
            </ModalFooter>
        </Modal>
    );
}

export default ModalComponent;