import FormValidator from './FormValidator';
import { useState, useEffect, useCallback } from "react";
import _, { cloneDeep } from 'lodash';

export default function useFieldItem(FIELDS: any, initialValues: any, options: any) {

    const { preValidation, postValidation, onValueChange } = options;
    const [values, setValues] = useState(initialValues);
    const [fields, setFields] = useState({});
    
    useEffect(() => {
        if (!_.isEmpty(initialValues))
            setValues(initialValues);
    }, [initialValues])

    const changeHandler = (name: any, value: any, obj: any) => {
        const { isTouched } = obj || {
            isTouched: false
        };
        if (isTouched) {
            return false;
        }

        const fieldValues: any = { ...fields };

        if (FIELDS[name] && fieldValues && Object.keys(fieldValues).length > 0) {
            const validate = FormValidator(name, value, FIELDS[name], preValidation, postValidation);
            if (validate) {
                fieldValues[name] = validate;
            } else {
                fieldValues[name] = {
                    hasError: false,
                    errorMsg: ''
                };
            }
        }

        const [newValues = null] = onValueChange ? onValueChange(name, value, values, fieldValues) || [] : [];

        setValues((values: any) => {
            if (newValues != null) return newValues;

            return ({
                ...values,
                [name]: value
            })

        })
        setFields(fieldValues);

        let temp: any = cloneDeep(fields);
        if (isTouched && temp[name] && temp[name].hasError) {
            setFields(temp);
            return false;
        }
    }

    const updateError = (name, data) => {
        const fieldValues: any = { ...fields };
        fieldValues[name] = data;
        setFields(fieldValues);
    }

    const removeErrors = (endIndex) => {
        let fieldValues: any = {...fields};
        fieldValues = {}
        setFields(fieldValues);
    }

    const validator = (keys: any,removedKeys=[]) => {
        const fieldValues: any = { ...fields };
        if(removedKeys.length>0) {
            removedKeys.forEach(key => {
                fieldValues[key]= {
                    hasError: false,
                    errorMsg: ''
                };
            })  
        }
        keys.forEach((key: any) => {
            const validate = FormValidator(key, values[key], FIELDS[key], preValidation, postValidation);
            if (validate) {
                fieldValues[key] = validate;
            } else {
                fieldValues[key] = {
                    hasError: false,
                    errorMsg: ''
                };
            }
        }
        );
        let validateValuesArray: any = [];
        Object.keys(fieldValues).forEach((obj: any) => {
            keys.forEach((key: any) => {
                if(obj === key) {
                    validateValuesArray.push(fieldValues[obj]);
                }
            })
        })
        const hasError = fieldValues ? validateValuesArray.some((f: any) => f.hasError) : false;
        setFields(fieldValues);
        return hasError;
    }

    const valuesReset = (resetArray: []) => {
        setValues(initialValues);
        setFields({});
    };

    const updateValues = (newValue: any) => {
        if (newValue) {
            const fieldValues: any = { ...fields };
            Object.keys(newValue).forEach(val => {
                if (FIELDS[val]) {
                    const validate = FormValidator(val, newValue[val], FIELDS[val], preValidation, postValidation);
                    if (validate) {
                        fieldValues[val] = validate;
                    } else {
                        fieldValues[val] = {
                            hasError: false,
                            errorMsg: ''
                        };
                    }
                }
            })
            setValues((values: any) => {
                return ({
                    ...values,
                    ...newValue
                })
            })
            setFields(fieldValues);
        }
    }

    const resetValues = (newValue: any) => {
        if (newValue) {
            const fieldValues: any = { ...fields };
            Object.keys(newValue).forEach(val => {
                if (FIELDS[val]) {
                    fieldValues[val] = {
                        hasError: false,
                        errorMsg: ''
                    };
                }
            })
            setValues((values: any) => {
                return ({
                    ...values,
                    ...newValue
                })
            })
            setFields(fieldValues);
        }
    }

    const handleChange = useCallback(
        changeHandler,
        [fields, FIELDS, onValueChange, values, preValidation, postValidation],
    );

    const validateValues = useCallback(
        validator,
        [fields, values, FIELDS, preValidation, postValidation],
    );

    const reset = useCallback(
        valuesReset,
        [initialValues],
    );

    const updateValue = useCallback(
        updateValues,
        [fields, FIELDS, preValidation, postValidation],
    )

    const resetValue = useCallback(
        resetValues,
        [fields, FIELDS, preValidation, postValidation],
    )

    return [values, fields, handleChange, { validateValues, reset, updateValue, resetValue, updateError, removeErrors }]
}
