import { useState } from "react";
import LearningMetrics from "./LearningMetrics";
import ProfileMetrics from "./ProfileMetrics";
import ProjectMetrics from "./ProjectMetrics";
import ProjectsList from "./ProjectsList";

export default function Dashboard() {
  const [action, setAction] = useState<string>("");
  const [selectedProjectStage, setSelectedProjectStage] = useState<any>("");

  const sampleData = [
    {
      stage: "Stage 1",
      projects: [{ name: "project 1" }, { name: "project 2" }, { name: "project 3" }],
    },
    {
      stage: "Stage 2",
      projects: [
        { name: "project 1" },
        { name: "project 2" },
        { name: "project 3" },
        { name: "project 4" },
        { name: "project 5" },
      ],
    },
    {
      stage: "Stage 3",
      projects: [{ name: "project 1" }, { name: "project 2" }],
    },
  ];

  const onAction = async (type: string, data: any = null) => {
    if (type === "projectslist") {
      const stageData = sampleData.find((item) => item.stage === data);
      setSelectedProjectStage(stageData);
    }
    setAction(type);
  };

  return (
    <>
      {action === "" && (
        <div className="dashboard-container">
          <div className="dashboard-container__header">Dashboard</div>
          <div className="dashboard-container__content">
            <div className="dashboard-container__content__left-column">
              <ProfileMetrics />
              <LearningMetrics />
            </div>
            <div className="dashboard-container__content__right-column">
              <ProjectMetrics onAction={onAction} data={sampleData} />
            </div>
          </div>
        </div>
      )}
      {action === "projectslist" && (
        <div className="dashboard-container__project-list-container">
          <ProjectsList selectedProjectStage={selectedProjectStage} onClose={() => onAction("")} />
        </div>
      )}
    </>
  );
}
