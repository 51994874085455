import AjaxUtil from '../../axios/axios';

export const getPlans = async(id) => {
    try {
        return await AjaxUtil.sendRequest(`project/plans?projectID=${id}`, {}, false, { method: 'get', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const createPlan = async(data) => {
    try {
        return await AjaxUtil.sendRequest(`project/plan`, data, false, { method: 'post', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const updatePlan = async(data) => {
    try {
        return await AjaxUtil.sendRequest(`project/plan`, data, false, { method: 'put', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const deletePlan = async(id) => {
    try {
        return await AjaxUtil.sendRequest(`project/plan/${id}`, {}, false, { method: 'delete', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const getPlanData = async(id) => {
    try {
        return await AjaxUtil.sendRequest(`project/plan/${id}`, {}, false, { method: 'get', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const getTasks = async(data) => {
    try {
        return await AjaxUtil.sendRequest(`project/plan/task`, data, false, { method: 'post', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const createTask = async(data) => {
    try {
        return await AjaxUtil.sendRequest(`project/plan/task`, data, false, { method: 'post', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const updateTask = async(data) => {
    try {
        return await AjaxUtil.sendRequest(`project/plan/task`, data, false, { method: 'put', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const getTask = async(id) => {
    try {
        return await AjaxUtil.sendRequest(`project/plan/task/${id}`, {}, false, { method: 'get', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const deleteTask = async(id) => {
    try {
        return await AjaxUtil.sendRequest(`project/plan/task/${id}`, {}, false, { method: 'delete', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const getProjectTeamList = async(projectid) => {
    try {
            return await AjaxUtil.sendRequest(`project/team?project_id=${projectid}`, {}, false, { method: 'get', showError:true, isProfile:true});
    } catch (error) {
        throw error;
    }
 }

 export const getPlanTemplates = async() => {
    try {
            return await AjaxUtil.sendRequest(`project/templates`, {}, false, { method: 'get', showError:true, isProfile:true});
    } catch (error) {
        throw error;
    }
 }
 export const getPlanTemplateTasks = async(id) => {
    try {
            return await AjaxUtil.sendRequest(`project/templatetasks/${id}`, {}, false, { method: 'get', showError:true, isProfile:true});
    } catch (error) {
        throw error;
    }
 }

 export const saveComment = async(data) => {
    try {
        return await AjaxUtil.sendRequest(`project/plan/task/comment`, data, false, { method: 'post', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const getTaskComments = async(id) => {
    try {
        return await AjaxUtil.sendRequest(`project/plan/task/comment/list/${id}`,{}, false, { method: 'get', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const updateComment = async(data) => {
    try {
        return await AjaxUtil.sendRequest(`project/plan/task/comment`, data, false, { method: 'put', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const deleteComment= async(id) => {
    try {
        return await AjaxUtil.sendRequest(`project/plan/task/comment/${id}`, {}, false, { method: 'delete', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

/**
 * get permissions data
 */
export const getPermissionData = async(usertype,userid,projectid) => {
    try {
        return await AjaxUtil.sendRequest(`permissions?role=${usertype}&user_id=${userid}&project_id=${projectid}`, {}, false, { method: 'get', showError:true, isProfile:true});

    } catch (error) {
        throw error;
    }
}