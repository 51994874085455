import { CloseOutlined, EditFilled, ForwardOutlined, RightCircleOutlined } from "@ant-design/icons";
import { useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Form } from "reactstrap";
import { CustomButton } from "../../widgets";
import { FieldItem } from "../../widgets/fields";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import { AffiliationFormElements, TitleElements } from "../createProject/FormElements";


export default function TitleGeneration(props) {
    const initialValues = useMemo(() => ({ studyGroup: '', interventionGroup: null, studyPopulation: '', outcome: '', groupName: '', studyDesign: '', timeline: '' }), []);
    const [values, fields, handleChange, { updateError }] = useFieldItem(TitleElements, initialValues, { onValueChange });
    const [titleData, settitleData] = useState([]);
    const [tempData, SettempData] = useState([]);
    const navigate = useNavigate();
    const [isGenerate, SetGenerate] = useState(false);

    function onValueChange(name, value, values, fieldValues) {
        checkValues(name, value);
        console.log(props)
    }

    /**
     * on Generate
     */
    const onGenerateAction = () => {
        SetGenerate(true)
        settitleData(tempData);

    }

    const onEdit = () => {
        SetGenerate(false)
        settitleData([]);
    }

    //check if study group greater than 2
    const onBlurValue = () => {
        if (values["studyGroup"] > 2) {
            updateError("studyGroup", { hasError: true, errorMsg: 'More than 2 groups will be coming soon' })
        }
    }

    /**
     * check data and create title
     */
    const checkValues = (name, value) => {
        values[name] = value;
        let topicNameData: any = [];

        if (values.studyPopulation && values.outcome && values.studyDesign && values.timeline && ((values.studyGroup === '2' && values.groupType) || values.studyGroup) && ((values.interventionGroup?.value === 'yes' && values.groupName) || values.interventionGroup?.value === 'no')) {
            var isVowel = ("aeiouAEIOU").includes(values.studyDesign.charAt(0));
            if (values.interventionGroup?.value === 'no') {
                values.groupName = '';
            }
            if (values.studyGroup === "1") {
                values.groupType = '';
                if (values.interventionGroup?.value === 'yes') {
                    topicNameData = [{
                        data: `To assess the impact of ${values.groupName} on ${values.outcome} among the ${values.studyPopulation} ${values.timeline ? 'for a duration of ' + values.timeline : ''}, ${isVowel ? 'an' : 'a'} ${values.studyDesign}`
                    }, {
                        data: `${isVowel ? 'An' : 'A'} ${values.studyDesign} to assess the impact of ${values.groupName} on ${values.outcome} among the study ${values.studyPopulation} ${values.timeline ? 'for a duration of ' + values.timeline : ''}`
                    }];
                } else {
                    topicNameData = [{
                        data: `To determine the ${values.outcome} among the ${values.studyPopulation}  ${values.timeline ? 'for a duration of ' + values.timeline : ''}, ${isVowel ? 'an' : 'a'} ${values.studyDesign}`
                    }, {
                        data: `To assess the incidence/prevalence of ${values.outcome} among the ${values.studyPopulation} ${values.timeline ? 'for a duration of ' + values.timeline : ''}, ${isVowel ? 'an' : 'a'} ${values.studyDesign}`
                    }, {
                        data: `To estimate the ${values.outcome} among the ${values.studyPopulation} ${values.timeline ? 'for a duration of ' + values.timeline : ''}, ${isVowel ? 'an' : 'a'} ${values.studyDesign}`
                    }, {
                        data: `${isVowel ? 'An' : 'A'} ${values.studyDesign} to determine the ${values.outcome} level among the ${values.studyPopulation} ${values.timeline ? 'for a duration of ' + values.timeline : ''}`
                    }, {
                        data: `${isVowel ? 'An' : 'A'} ${values.studyDesign} to assess the incidence/prevalence of ${values.outcome} among the ${values.studyPopulation} ${values.timeline ? 'for a duration of ' + values.timeline : ''}`
                    }, {
                        data: `${isVowel ? 'An' : 'A'} ${values.studyDesign} to estimate the ${values.outcome} among the ${values.studyPopulation} ${values.timeline ? 'for a duration of ' + values.timeline : ''}`
                    }];
                }
            } else {
                if (values.interventionGroup?.value === 'yes') {
                    topicNameData = [{
                        data: `To compare the ${values.timeline ? values.timeline : ''} ${values.outcome} between ${values.groupName} and ${values.groupType} groups among the ${values.studyPopulation}, ${isVowel ? 'an' : 'a'} ${values.studyDesign}`
                    }, {
                        data: `${isVowel ? 'An' : 'A'} ${values.studyDesign} to compare the ${values.timeline ? values.timeline : ''} ${values.outcome} between ${values.groupName} and ${values.groupType} groups among the study ${values.studyPopulation}`
                    }, {
                        data: `To compare the ${values.outcome} at/in the ${values.timeline ? values.timeline : ''} between ${values.groupName} and ${values.groupType} groups among the study ${values.studyPopulation}, ${isVowel ? 'an' : 'a'} ${values.studyDesign}`
                    }, {
                        data: `${isVowel ? 'An' : 'A'} ${values.studyDesign} to compare the ${values.outcome} at/in the ${values.timeline ? values.timeline : ''} between ${values.groupName} and ${values.groupType} groups among the study ${values.studyPopulation}`
                    }];
                } else {
                    topicNameData = [{
                        data: `${isVowel ? 'An' : 'A'} ${values.studyDesign} to compare the ${values.outcome} between ${values.studyPopulation} and ${values.groupType}`
                    }, {
                        data: `Compraging the ${values.outcome} levels between ${values.studyPopulation} and ${values.groupType}, ${isVowel ? 'an' : 'a'} ${values.studyDesign}`
                    }, {
                        data: `Association between ${values.outcome} and ${values.studyPopulation}, ${isVowel ? 'an' : 'a'} ${values.studyDesign}`
                    }, {
                        data: `${isVowel ? 'An' : 'A'} ${values.studyDesign} to assess the association between ${values.studyPopulation} and ${values.outcome}`
                    }];
                }
            }
        }
        SettempData(topicNameData);

        console.log(topicNameData)
    }

    return (
        <>
            <div className="title-generate-container">

                <div className="head">
                    <div className="title-div">
                        <span className="title-text">Title Generation</span>
                    </div>
                    <div>
                        <CloseOutlined className="title-close" onClick={() => props.onClose()} />
                    </div>
                </div>
                <div className={!isGenerate ? "title-body" : "title-body-view"}>
                    <FieldItem
                        {...TitleElements.studyGroup}
                        value={values.studyGroup}
                        onChange={(...e) => handleChange(TitleElements.studyGroup.name, ...e)}
                        touched={fields.studyGroup && fields.studyGroup.hasError}
                        error={fields.studyGroup && fields.studyGroup.errorMsg}
                        onBlur={onBlurValue}
                    />

                    <FieldItem
                        {...TitleElements.interventionGroup}
                        value={values.interventionGroup}
                        onChange={(...e) => handleChange(TitleElements.interventionGroup.name, ...e)}
                        touched={fields.interventionGroup && fields.interventionGroup.hasError}
                        error={fields.interventionGroup && fields.interventionGroup.errorMsg}
                    />

                    {values.studyGroup && values.interventionGroup?.value && values["studyGroup"] <= 2 ?
                        <div>
                            <div className='form-container'>

                                <FieldItem
                                    {...TitleElements.studyPopulation}
                                    value={values.studyPopulation}
                                    onChange={(...e) => handleChange(TitleElements.studyPopulation.name, ...e)}
                                    touched={fields.studyPopulation && fields.studyPopulation.hasError}
                                    error={fields.studyPopulation && fields.studyPopulation.errorMsg}
                                />

                                <FieldItem
                                    {...TitleElements.outcome}
                                    value={values.outcome}
                                    onChange={(...e) => handleChange(TitleElements.outcome.name, ...e)}
                                    touched={fields.outcome && fields.outcome.hasError}
                                    error={fields.outcome && fields.outcome.errorMsg}
                                />
                                {values.interventionGroup?.value === 'yes' &&
                                    <FieldItem
                                        {...TitleElements.groupName}
                                        value={values.groupName}
                                        onChange={(...e) => handleChange(TitleElements.groupName.name, ...e)}
                                        touched={fields.groupName && fields.groupName.hasError}
                                        error={fields.groupName && fields.groupName.errorMsg}
                                    />
                                }
                                {values.studyGroup === '2' &&
                                    <FieldItem
                                        {...TitleElements.groupType}
                                        value={values.groupType}
                                        onChange={(...e) => handleChange(TitleElements.groupType.name, ...e)}
                                        touched={fields.groupType && fields.groupType.hasError}
                                        error={fields.groupType && fields.groupType.errorMsg}
                                    />
                                }
                                <FieldItem
                                    {...TitleElements.studyDesign}
                                    value={values.studyDesign}
                                    onChange={(...e) => handleChange(TitleElements.studyDesign.name, ...e)}
                                    touched={fields.studyDesign && fields.studyDesign.hasError}
                                    error={fields.studyDesign && fields.studyDesign.errorMsg}
                                />

                                <FieldItem
                                    {...TitleElements.timeline}
                                    value={values.timeline}
                                    onChange={(...e) => handleChange(TitleElements.timeline.name, ...e)}
                                    touched={fields.timeline && fields.timeline.hasError}
                                    error={fields.timeline && fields.timeline.errorMsg}
                                />

                            </div>
                        </div>
                        : ""
                    }
                    {
                        tempData.length != 0 && !isGenerate ?
                            <CustomButton type="primary" onClick={() => onGenerateAction()} className="w-100" text="Generate"></CustomButton>
                            :
                            ""
                    }
                </div>

                <div className={titleData.length === 0 ? "title-footer" : "title-footer-data"}>

                    <span className="head-text">Title suggestion</span>
                    {titleData.length !== 0 ?
                        <EditFilled className="edit-data" onClick={() => onEdit()} />
                        : ""}
                    {titleData.length === 0 ?
                        <>
                            <div className="circle-dash"></div>
                            <div className='empty-text-hold'>Hold tight we are processing...</div>

                        </>
                        : ""}
                    <div className="data-content">
                        {titleData.map((obj: any, index) => (
                            <> <div key={index} className='title-container' >
                                <div className="content">
                                    <div className='title-text'>Title {index + 1}</div>
                                    <div className='title-value'>{obj.data}</div>
                                </div>
                                <div className="arrow">
                                    <RightCircleOutlined onClick={() => props.onSelect(obj.data)} />
                                </div>
                            </div>
                                <hr></hr>
                            </>
                        ))}
                    </div>
                </div>

            </div>
        </>
    )
}