import { ArrowDownOutlined, ArrowLeftOutlined, BackwardOutlined, CloseOutlined, CloudDownloadOutlined, DownloadOutlined, LeftOutlined, PrinterFilled, RightOutlined, SendOutlined, StarFilled } from "@ant-design/icons";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Card, Form, Image, ListGroup } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { Badge, Button, Col, Row } from "reactstrap";
import { FieldItem, FIELD_TYPES } from "../../widgets/fields";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import { getProjectTeamList, getUsersList, getUserTypes, revokeUserInvite, sendUserInvite } from "./apiController";
import InviteModal from "./InviteModal";
import TextArea from "antd/es/input/TextArea";
import { LeftArrow } from "../surveyForm/Arrow";
import Sheet from "react-modal-sheet";
import { Radio } from "antd";
import { expectedDateFormat } from "../../utils/Utils";
import { Loader } from "../common/FullScreenLoader/fullScreenLoader";
import { useDispatch } from "react-redux";
import { getRoles } from "../dataCollection/apiController";
import ModalComponent from "../common/Modal/modal";
import { CustomButton, showToaster, toasterTypes } from "../../widgets";



export default function PeopleInviteMobile(props) {
    const projectdetails = useLocation();
    const navigate = useNavigate();
    const [popUpType, SetPopUpType] = useState(false);
    const [popUpRevoke, SetPopUpRevoke] = useState("");
    const [projectId, SetProjectId] = useState<any>(projectdetails.state);
    const [pageNo, SetPageNo] = useState(1);
    const [perPage, SetPerPage] = useState(20);
    const [getProjectusers, SetProjectUsers] = useState<any>([]);
    const [userTypes, SetUserTypes] = useState<any | []>([]);
    const [emailValue, SetEmail] = useState("");
    const [typeValue, SetType] = useState("");
    const [sentRequest, SetsentRequest] = useState(false);
    const [loading, Setloading] = useState(false);
    const [status, SetStatus] = useState("accepted");
    const [errorInvite, SeterrorInvite] = useState("");
    const listuserInnerRef = useRef<HTMLInputElement>(null);
    const [userCounts, SetuserCounts] = useState(0);
    const [invokeName, SetinvokeName] = useState({});

    const dispatch = useDispatch();

    const FormElements = {
        search: {
            name: "search",
            placeholder: "Search",
            label: "",
            type: FIELD_TYPES.SEARCH,
            isMandatory: false,
        },
    }
    const [values, fields, handleChange] = useFieldItem(FormElements, {}, {});


    useEffect(() => {
        projectId.projectid = projectId.id;
        fetchIntialData();
        console.log(projectId)
    }, []);

    useEffect(() => {

    }, [getProjectusers, userTypes])


    const fetchIntialData = async () => {
        try {

            Setloading(true);
            let projectUsers = await getProjectTeamList(projectId, pageNo, perPage, "", status, "");
            SetProjectUsers(projectUsers);
            const userList = await getUsersList("", 1, 10);
            SetuserCounts(userList["total-count"])
            Setloading(false);
            const usertypes = await getRoles();
            SetUserTypes(usertypes.data);
        } catch (e: any) {
            Setloading(false);
            throw e
        }
    }

    function onInvite(type: '') {
        SetPopUpType(true);
    }

    function redirectInvite() {
        navigate("/peopleinvitemobile", { state: projectId });
    }

    const onChange = async (e) => {
        SetPageNo(1);
        SetPerPage(10);
        SetStatus(e.target.value);
        try {
            let projectUsers = await getProjectTeamList(projectId, 1, 10, "", e.target.value, "");
            SetProjectUsers(projectUsers);
        } catch (e: any) {
            throw e
        }

    };

    /**
     * send invite inside pop up container with email and user type
     * @param e 
     */
    const inviteAction = async (e) => {
        e.preventDefault();
        SeterrorInvite("")

        if (emailValue || typeValue) {
            try {
                const response = await sendUserInvite({ invitee_email: emailValue, invitee_role: typeValue, invitee_name: "", project_id: projectId, invitee_id: null });
                if (response.success) {
                    SetsentRequest(true);
                    navigate("/invitesuccess");
                } else {
                    SeterrorInvite(response.message)
                }
            } catch (e: any) {
                throw e
            }

        }
    }

    /**
     * 
     * @param id 
     * @param invitee_name 
     * @param user_dp 
     */
    const getMessageList = (id, invitee_name, user_dp) => {
        console.log(id)
        let data = { id: id, inviteename: invitee_name, userdp: user_dp };
        //localStorage.setItem("selectProject",data);
        if (status == "accepted") {
            navigate('/peoplechat', { state: { id: id, inviteename: invitee_name, userdp: user_dp, data: projectId?.projectdata, type: projectId.type ? projectId.type : "" } });
        }

    }

    /**
    * scroll users list to bottom get next set of data
    */
    const onScrollUsers = async () => {
        if (listuserInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listuserInnerRef.current;
            if (scrollTop + clientHeight === scrollHeight) {
                // TO SOMETHING HERE
                try {
                    SetPageNo(pageNo + 1);
                    SetPerPage(perPage + 10);
                    let projectUsers = await getProjectTeamList(projectId, pageNo + 1, perPage + 10, "", status, "");
                    SetProjectUsers(projectUsers);
                } catch (e: any) {
                    throw e
                }
            }
        }
    };

    /**
  * revoke invite 
  */
    const revokeInvite = (name) => {
        console.log(name)
        SetinvokeName(name)
        SetPopUpRevoke("revoke")
    }

    /**
     * procced to revoke permission
     */
    const ConfirmRevoke = async () => {
        console.log(invokeName)
        try {
            let revokeResponse = await revokeUserInvite(invokeName?.['id']);
            if (revokeResponse) {
                showToaster(toasterTypes.SUCCESS, "Invite revoked")
            }
            SetPopUpRevoke("")
        } catch (error) {
            console.log(error)
        }
    }


    return (
        <>
            {
                popUpRevoke == "revoke" ?
                    <ModalComponent
                        width={'95%'}
                        isOpen={true}
                        onClose={() => SetPopUpRevoke("")}
                        header="Revoke Invite"
                        centered={true}
                        customClass='invite-modal'
                        body={
                            <>
                                <span>
                                    Are you sure to revoke {invokeName?.['invitee_name']} invite ?
                                </span>
                            </>
                        }
                        footer={
                            <>
                                <CustomButton text="Procced" type="primary" onClick={() => ConfirmRevoke()} />
                            </>
                        }
                    />
                    : ""
            }
            <div className="people-invite-home-mob">
                <div className="info-bar" onClick={() => navigate("/projectmobile")}>
                    <LeftOutlined className="arrow-back" />
                    <span className="info-project-detail"> Project Details</span>
                </div>
                <Card className="project-detail-card">
                    <span className="project-name">{projectId.type != "invitee" ? projectId?.TopicDesc : projectId?.topicdesc}</span>
                    <hr></hr>
                    <span className="project-date">{expectedDateFormat(projectId.type != "invitee" ? projectId?.CreatedOn : projectId?.createdon, 'DD MMM YYYY', 'YYYY-MM-DD')}</span>
                </Card>
                {projectId.type != "invitee" ?
                    <>
                        <div className="head-title">
                            <Row>
                                <Col xs={9}>
                                    <span className="head-title-txt">People</span>
                                </Col>
                                <Col xs={2}>
                                    <Button className="invite-btn" onClick={() => SetPopUpType(true)}>Invite</Button>
                                </Col>
                            </Row>
                        </div>
                        <div className="sent-status">
                            <Radio.Group value={status} onChange={onChange} style={{ marginBottom: 16 }}>
                                <Radio.Button value="sent">Sent</Radio.Button>
                                <Radio.Button value="accepted">Accepted</Radio.Button>
                                <Radio.Button value="rejected">Rejected</Radio.Button>
                                <Radio.Button value="revoked">Revoked</Radio.Button>
                            </Radio.Group>
                        </div>
                    </>
                    :
                    <div className="sent-status">
                        <Radio.Group value={status} onChange={onChange} style={{ marginBottom: 16 }}>
                            <Radio.Button value="accepted">Accepted</Radio.Button>
                        </Radio.Group>
                    </div>
                }

                {!loading && getProjectusers?.users?.length ? "" : <div className="project-loader">No List Found</div>}
                {loading ? <div className="project-loader"><Loader loaderText="Fetching..." /></div> :
                    <ListGroup className="project-user-list" onScroll={() => onScrollUsers()} ref={listuserInnerRef}>
                        {getProjectusers?.users?.map((item, i) => {
                            return <>
                                <ListGroup.Item>
                                    <Row>
                                        <Col xs={8} >
                                            <div className="people-message" onClick={(e) => getMessageList(projectId.type == "invitee" ? item.inviter_id : item.invitee_id, projectId.type == "invitee" ? item.inviter_name : item.invitee_name, item.user_dp)}>
                                                <div className="invite-pic-name">
                                                    <div className="msg-icon">
                                                        <Image className="profile-img" src={item.user_dp} />
                                                    </div>
                                                    <div className="people-detail">
                                                        <span className="mentor-text">{item.invitee_role}</span>
                                                        <span className="mentor-name">{projectId.type == "invitee" ? item.inviter_name : item.invitee_name}</span>
                                                    </div>
                                                </div>
                                                <Row>
                                                    <Col xs={10}>
                                                        <div className="msg-content">
                                                            {item.status == "rejected" ? item.remarks : item.last_message}
                                                        </div>
                                                    </Col>
                                                    <Col xs={2} >

                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="message-info">
                                                <span className="sent-time">

                                                </span>
                                                <span className="badge-count"></span>
                                            </div>
                                            {
                                                status == "accepted" ?
                                                    <div className="revoke" onClick={() => revokeInvite(item)} >
                                                        Revoke
                                                    </div>
                                                    : ""
                                            }
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                            </>
                        })}

                    </ListGroup>
                }

                {getProjectusers?.users?.length == 0 ?

                    <div className="invite-empty-mob">
                        <div className="invite-content-empty-mob">
                            <span className="empty-text">No Mentors or Collabrators available</span>
                            <Button className="invite-btn" onClick={() => SetPopUpType(true)}>Invite</Button>
                        </div>
                    </div>
                    : ""}
            </div>

            <Sheet className="invite-pop-container" isOpen={popUpType} onClose={() => SetPopUpType(false)} >
                <Sheet.Container>
                    <h5 className="feedbackHead">
                        <span className="feedbackText">Mentors and Collabrators</span>

                        <CloseOutlined
                            onClick={() => SetPopUpType(false)}
                            style={{ fontSize: "small" }}
                            className="closeIcon"
                        />
                    </h5>
                    <b style={{ padding: "0px 20px" }}>Invites</b>

                    <Sheet.Header />

                    <div className="invite-popup">
                        <span className="popup-content"> </span>

                        <div className="available-invites" onClick={() => redirectInvite()}>
                            <span>{userCounts} Available mentors from Coguide</span><RightOutlined className="next-right" />
                        </div>

                        <h4>Or</h4>

                        <h5>{errorInvite}</h5>


                        <Card>
                            <Form
                                name="basic"
                                autoComplete="off"
                                className="invite-form-items"
                            >
                                <Form.Group as={Row} controlId="formHorizontalEmail">

                                    <Form.Select className="select-user" onChange={(e) => SetType(e.target.value)}>
                                        <option value="">User Type</option>

                                        {
                                            userTypes?.map((item, i) => {
                                                return <option value={item.key}>{item.key}</option>
                                            })
                                        }
                                    </Form.Select>

                                    <Form.Control type="email" onChange={(e) => SetEmail(e.target.value)} placeholder="Email" />

                                </Form.Group>

                            </Form>
                        </Card>
                        <Col sm={1}>
                            <Button className="invite-btn" type="submit" disabled={!emailValue || !typeValue ? true : false} onClick={(e) => inviteAction(e)}>Invite</Button>
                        </Col>

                    </div>
                    <Sheet.Content></Sheet.Content>
                </Sheet.Container>

                <Sheet.Backdrop />
            </Sheet>

        </>
    )
}