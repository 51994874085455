import { FIELD_TYPES } from "../../widgets/fields";

export const QuestionFormElements = {
    questiontype: {
        name: "questiontype",
        placeholder: "Enter",
        label: "",
        type : FIELD_TYPES.RADIO_BUTTON,
        isMandatory: true
    },
    
}