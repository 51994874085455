export const TabsData = [
     {
          title: "Project Details",
          id: 1,
          tab: "project details"
     },
     {
          title: "Study Design",
          id: 2,
          tab: "study design"
     },
     {
          title: "Data Collection",
          id: 3,
          tab: "Data collection"

     },
     {
          title: "Data Analysis",
          id: 4,
          tab: "Data analysis"
     },
     {
          title: "People",
          id: 5,
          tab: "people"
     },
     {
          title: "Planner",
          id: 6,
          tab: "planner"
     },
     {
          title: "Registered Events",
          id: 7,
          tab: "register events"
     },
     {
          title: "Document",
          id: 8,
          tab: "documents"
     },
     // {
     //   title: "Mentors",
     //   id: 8,
     //   tab: "mentors"
     // }

];