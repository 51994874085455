import AjaxUtil from '../../axios/axios';

export const send_otp = async(emailid, mobileNumber = "" ) => {
    try {
        let request = {emailid: emailid, mobilenumber: mobileNumber}
        return await AjaxUtil.sendRequest('validation', request, false, { method: 'post', showError: true} );
    } catch (error) {
        throw error;
    }
}

export const create_profile = async(display_name, emailid, mobileNumber = "", password, otp, id, account_type) => {
    try {
        let request = {display_name: display_name, emailid: emailid, mobileNumber: mobileNumber, password: AjaxUtil.encryptData(password), otp: otp,invite_id:id, account_type: account_type}
        return await AjaxUtil.sendRequest('v3/reguser', request, false, { method: 'post', showError: true} );
    } catch (error) {
        throw error;
    }
}

export const get_account_types = async() => {
    try {
        return await AjaxUtil.sendRequest('acctypes', {} , false, { method: 'get', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

/**
 * get initial data for invite
 */
export const getInviteUserInfo = async(id) => {
    try {
        return await AjaxUtil.sendRequest('invite/'+id, {}, false, { method: 'get', showError: true,isProfile: true} );
    } catch (error) {
        throw error;
    }
}