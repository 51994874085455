import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Input } from "reactstrap";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import { FieldItem, FIELD_TYPES } from "../../widgets/fields";
import { CustomButton, showToaster, toasterTypes } from "../../widgets";
import CriteriaForm from "./CriteriaForm";
import { useLocation, useNavigate } from "react-router-dom";
import CustomTable from "../../widgets/table";
import { deleteCriteriaItem, getCriteria, getForms, postApplyCriteria } from "./apiController";
import { useDispatch } from "react-redux";
import { setLoading } from "../../store/slices/auth";
import ModalComponent from "../common/Modal/modal";

export default function CreateCriteria(props) {
  const [clicked, setClicked] = useState<any | "">("");
  const [projectData, SetprojectData] = useState<any>([]);
  const [groupData, SetgroupData] = useState<any>([]);
  const [CriteriaData, SetCriteriaData] = useState<any>([]);
  const [selectedCriteria, SetselectedCriteria] = useState<any>("");
  const [selectedCriteriaItem, SetselectedCriteriaItem] = useState<any>({});
  const [formData, SetFormData] = useState<any>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const FormElements = {
    search: {
      name: "search",
      placeholder: "Search",
      label: "",
      type: FIELD_TYPES.SEARCH,
      isMandatory: false,
    },
  };
  const [values, fields, handleChange] = useFieldItem(FormElements, {}, {});

  useEffect(() => {
    SetprojectData(props.projectData);
    SetgroupData(props.groupData);
    fetchFormsData()
  }, [])

  const onCriteria = (data) => {
    SetselectedCriteriaItem(data)
    setClicked("4");
  };

  const closeCriteria = () => {
    props.onClose();
    //navigate('/project', { state: projectData })
  };

  const addData = (data) => {
    //setShowCriteriaDiv(data);
  }

  /**
   * fetch forms data
   */
  const fetchFormsData = async () => {
    try {
      dispatch(setLoading(true))
      let response = await getForms(props.groupData.id);
      if (response) {
        SetFormData(response)
        fetchCriteria(props.groupData.id);
      }
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setLoading(false))
    }
  }
  /**
   * fetch all criteria list
   * pass groupid as input
   */
  const fetchCriteria = async (id) => {
    try {
      dispatch(setLoading(true))
      let response = await getCriteria(id);
      if (response) {
        SetCriteriaData(response.data)
      }
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setLoading(false))
    }
  }

  /**
   * delete criteria
   */
  const deleteCriteria = async (id, confirm) => {
    setClicked("delete")
    SetselectedCriteria(id)
    if (confirm) {
      try {
        let repsonseDelete = await deleteCriteriaItem(id);
        if (repsonseDelete) {
          fetchCriteria(props.groupData.id);
          setClicked("");
        }
      } catch (error) {
        console.log("Delete Criteria====>", error)
      }
    }
  }

  /**
   * apply criteria
   */
  const applyCriteria = async (id, confirm) => {
    setClicked("apply")
    SetselectedCriteria(id)
    if (confirm) {
      try {
        let repsonseDelete = await postApplyCriteria(id);
        if (repsonseDelete) {
          showToaster(toasterTypes.SUCCESS,"Successfully scheduled to process criteria")
          setClicked("");          
        }
      } catch (error) {
        console.log("apply Criteria====>", error)
      }
    }
  }


  return (
    <>
      {clicked == "4" && (
        <CriteriaForm {...props} addData={addData} projectData={projectData} groupData={groupData} fetchFormsData={fetchFormsData} onClose={() => setClicked("")} selectedItem={selectedCriteriaItem} />
      )}

      {
        clicked == "delete" &&
        <ModalComponent
          onClose={() => setClicked("")}
          isOpen={true}
          header={<b>Delete Criteria</b>}
          centered={true}
          customClass="criteria-modal"
          body={
            <>
              <span>Are you sure you want to delete this item?</span>
            </>
          }
          footer={
            <>
              <CustomButton type="primary" text="Confirm" onClick={(id, confirm) => deleteCriteria(selectedCriteria, true)} />
            </>
          }
        />

      }

{
        clicked == "apply" &&
        <ModalComponent
          onClose={() => setClicked("")}
          isOpen={true}
          header={<b>Previous data will be cleared</b>}
          centered={true}
          customClass="criteria-modal"
          body={
            <>
              <span>Are you sure you want proceed?</span>
            </>
          }
          footer={
            <>
              <CustomButton type="primary" text="Confirm" onClick={(id, confirm) => applyCriteria(selectedCriteria, true)} />
            </>
          }
        />

      }

      <div className="profile-screen p-0">
        <div className="profile-header d-flex justify-content-between">
          <h2 className="text-white d-flex">
            Criterias{" "}
          </h2>
          <i
            className="text-white icon icon-close"
            onClick={closeCriteria}
            style={{ fontSize: "28px", cursor: "pointer" }}
          ></i>
        </div>
      </div>

      <Row className="m-3">
        <Col className="p-0 d-flex m-0" md={10} xs={12}>
          <div className="project-header mr-4 pt-0">Criterias</div>
          <FieldItem
            {...FormElements.search}
            value={values.search}
            onChange={(...e) => handleChange(FormElements.search.name, ...e)}
            touched={fields.search && fields.search.hasError}
            error={fields.search && fields.search.errorMsg}
          />
        </Col>
        <Col className="p-0 d-flex m-0" md={2} xs={12}>
          {
            CriteriaData &&
            <CustomButton
              className="mt-2"
              type="alert-primary"
              text="Create"
              onClick={() => onCriteria("")}
            ></CustomButton>

          }

        </Col>
      </Row>




      {!CriteriaData ?
        <div className="d-flex flex-column justify-content-md-center align-items-center ">
          <p>No Criterias available</p>
          <CustomButton
            className="mt-2"
            type="alert-primary"
            text="Create"
            onClick={() => onCriteria("")}
          ></CustomButton>
        </div>
        :
        <div className="criteria">
          {CriteriaData && CriteriaData?.map((item, i) => {
            return (<>
              <div className="criteria-container">
                <Row className="head">

                  <Col md={9} className="mt-1">
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span className="mr-3"><strong>Source: </strong>{formData && formData.filter(itemform => itemform.id == item.source_form_id)[0].form_name}</span>
                      <span className="mr-5"><strong>Destination: </strong>{formData && formData.filter(itemform => itemform.id == item.target_form_id)[0].form_name}</span>
                    </div>
                  </Col>

                  <Col md={3} className="mt-3">
                    <div>
                    <span className="mr-3">
                      <span className="apply-txt" style={{color:"#006bd9",cursor:"pointer"}} onClick={() => applyCriteria(item.id, false)} >Apply</span>

                      </span>
                      <span className="mr-3">
                        <i className="icon icon-edit" onClick={() => onCriteria(item)}></i>
                      </span>
                      <span className="mr-5">
                        <i className="icon icon-delete" onClick={() => deleteCriteria(item.id, false)}></i>
                      </span>
                    </div>
                  </Col>
                </Row>

                <Row className="content">
                  <Col md={12} className="mt-3">
                    {
                      item.criteria && item.criteria?.map((items, i) => {
                        return (
                          items.value_type == "expression" && items.key == "field" ?
                            `${items.condition_name} `
                            :
                            items.value_type == "string" || items.value_type == "list" && items.key == "field" ?
                            `${items.condition_name} == ${items.value} `
                            :
                            `${items.value} `
                        )
                      })
                    }
                  </Col>
                </Row>
              </div>
            </>)
          })
          }
        </div>
      }
    </>
  );
}
