import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'reactstrap'
import { CustomButton, showToaster, toasterTypes } from '../../widgets';
import { FieldItem } from '../../widgets/fields'
import { AffiliationFormElements } from "../createProject/FormElements";

export default function ObjectiveCreation(props) {
    const navigate = useNavigate();
    const [objectives, setobjectives] = useState(['']);
    const [index, setindex] = useState<any | null>(null);

    useEffect(() => {
        if(props) {
            setobjectives(props.data.objective || ['']);
        }
    }, []);
    

   /**
    * on click next action
    */
    const next = () => {
        if (!checkObjectives()) {
			showToaster(toasterTypes.ERROR, "Please enter the proper values in the fields highlighted in red");
		} else {
			props.next(objectives,"objective");
		}
    };

    const checkObjectives = () => {
        let isNotEmpty = true;
        objectives && objectives?.forEach((obj: any) => {
            if(!obj) {
                isNotEmpty = false;
            }
        })
        return isNotEmpty;
    }

    const changeObjective = (value, index) => {
        if(value !== null) {
            let temp: any = cloneDeep(objectives);
            temp[index] = value;
            setobjectives(temp);
        }
    }

    const addObjective = () => {
        let temp: any = cloneDeep(objectives);
        temp.push('');
        setobjectives(temp);
    }

    return (
        <>
        <div className='affiliation-page-mobile'>
          
            <div className='affiliation-container'>
                <Row className='m-0'>
                    <Col className='p-0'>
                        <div className='page-text'>Objective Creation</div>
                    </Col>
                    <Col className='p-0'>
                        <div className={`d-flex m-0 justify-content-end ${checkObjectives() ? '' : 'disabled'}`}><i className='icon-add_circle' onClick={() => addObjective()} /><div className='add-objective-text' onClick={() => addObjective()}>OBJECTIVE</div></div>
                    </Col>
                </Row>
                {objectives.length === 1 ?
                    <div>
                        <Row>
                            <Col>
                                <FieldItem
                                    {...AffiliationFormElements.objective}
                                    value={objectives[0]}
                                    onChange={(e) => changeObjective(e, 0)}
                                />
                            </Col>
                        </Row>
                        <div className='help-text'>Need any help in Objective Generation? Proceed with our</div>
                        <span className='link-text' onClick={() => props.onGenerate(0)}>Objective Generation</span>

                        
                    </div> :
                    <div className='objective-scroll'>
                        {objectives.map((obj, i) => (
                            <div key={i}>
                                <Row>
                                    <Col>
                                        <FieldItem
                                            {...AffiliationFormElements.objective}
                                            value={obj}
                                            onChange={(e) => changeObjective(e, i)}
                                            onBlur={() => setTimeout(() => {
                                                setindex(null)
                                            }, 200)}
                                            onFocus={() => setindex(i)}
                                        />
                                    </Col>
                                </Row>
                                {i === index && <div className='help-text'>Need any help in Objective Generation? Proceed with our <span className='link-text' onClick={() => props.onGenerate(i)}>Objective Generation</span></div>}
                            </div>
                        ))}
                    </div>
                }
                 <div className='video-footer'>
                <div style={{ textAlign: "center" }}>Watch video(s) to understand more about</div>
                <div style={{ textAlign: "center" }}><a target="_blank" href='https://reap.coguide.in/playvideo?rgt=https://f000.backblazeb2.com/file/cGbgmedia/Researchobjectives_lowbto743e4pse1u1l1u7xf.mp4#t=1'>How to write research objectives?</a></div>
                <div style={{ textAlign: "center" }}><a target="_blank" href='https://reap.coguide.in/playvideo?rgt=https://f000.backblazeb2.com/file/cGbgmedia/Researchquestion_lowb8cfm05ju8gppnwg5z79.mp4#t=1'>How to write research question?</a></div>
            </div>
            </div>
           
            <div className='page-footer'></div>
        </div>
       
            <div className="footer-button">
                <Row className='title-footer m-0'>
                    <Col className='p-0'>
                        <div className='cancel-text' onClick={() => navigate('/project')}>Cancel</div>
                    </Col>
                    <Col className='p-0 d-flex m-0 justify-content-end'>
                        <CustomButton type="primary" onClick={() => props.previous(objectives,"objective")} className="w-100" text="Previous"></CustomButton>
                        <CustomButton type="primary" onClick={() => next()} className="w-100" text="Next"></CustomButton>
                    </Col>
                </Row>
            </div>
        </>
    )
}
