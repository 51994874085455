import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Image, ListGroup } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { Badge, Button, Col, Row } from "reactstrap";
import { FieldItem, FIELD_TYPES } from "../../widgets/fields";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import { getChatHistory, getProjectTeamList, revokeUserInvite, sendMessage } from "./apiController";
import InviteModal from "./InviteModal";
import TextArea from "antd/es/input/TextArea";
import { CheckCircleFilled, CheckOutlined, DownloadOutlined, PrinterFilled, SendOutlined, StarFilled } from "@ant-design/icons";
import Search from "antd/es/input/Search";
import { Radio, Tooltip } from "antd";
import type { SizeType } from 'antd/es/config-provider/SizeContext';
import { useDispatch } from "react-redux";
import { setLoading } from "../../store/slices/auth";
import ModalComponent from "../common/Modal/modal";
import { CustomButton, showToaster, toasterTypes } from "../../widgets";


export default function PeopleInvite(props) {
    const projectdetails = useLocation();
    const dispatch = useDispatch();
    const [popUpType, SetPopUpType] = useState("");
    const [projectId, SetProjectId] = useState<any>();
    const [searchKey, SetsearchKey] = useState("");
    const [status, SetStatus] = useState("accepted");
    const [chatWithId, SetchatWithId] = useState("");
    const [chatName, SetChatName] = useState("");
    const [userDp, SetuserDp] = useState("");
    const [ChatHistory, SetgetChatHistory] = useState<any>([]);
    const [messageTxt, SetMessageTxt] = useState("");
    const [pageNo, SetPageNo] = useState(1);
    const [perPage, SetPerPage] = useState(20);
    const [getProjectusers, SetProjectUsers] = useState<any>([]);
    const [coguideUser, SetCoguideUser] = useState<any>({});
    const [reached, Setreached] = useState(false);
    const [invokeName, SetinvokeName] = useState({});
    const listInnerRef = useRef<HTMLInputElement>(null);
    const listuserInnerRef = useRef<HTMLInputElement>(null);

    const FormElements = {
        search: {
            name: "search",
            placeholder: "Search",
            label: "",
            type: FIELD_TYPES.SEARCH,
            isMandatory: false,
        },
    }
    const [values, fields, handleChange, onClear] = useFieldItem(FormElements, {}, {});

    useEffect(() => {
        let temp: any = projectdetails.state;
        console.log(temp)
        SetProjectId({ projectid: temp.id, type: temp?.type })
        const interval = setInterval(() => {
            console.log(status, chatName)

            if (status == "accepted" && chatName) {
                let temp: any = projectdetails.state;
                getUpdatedChat(temp.id)
            }

        }, 8000)
        return () => clearInterval(interval);
    }, [chatName, status]);


    const getUpdatedChat = async (id) => {
        try {
            let getMessageList = await getChatHistory(id, 1, 20, chatWithId);
            SetgetChatHistory(getMessageList.messages);
        } catch (e: any) {
            throw e
        }
    }

    useEffect(() => {

        fetchInitialData();

        SetCoguideUser(localStorage.getItem("coguide_user"));
    }, []);

    useEffect(() => {
    }, [getProjectusers, ChatHistory]);


    /**
    * scroll to bottom get next set of data
    */
    const onScrollMessages = async () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            console.log(scrollTop + clientHeight, scrollHeight)

            if (scrollTop === clientHeight - scrollHeight) {
                console.log("top")
                try {
                    SetPageNo(pageNo + 1);
                    SetPerPage(perPage);
                    let getMessageList = reached ? "" : await getChatHistory(projectId.projectid, pageNo + 1, perPage, chatWithId);

                    if (!getMessageList.messages) {
                        Setreached(true);
                    }
                    SetgetChatHistory([...ChatHistory, ...getMessageList.messages]);
                } catch (e: any) {
                    throw e
                }
            }
        }
    };

    /**
   * scroll users list to bottom get next set of data
   */
    const onScrollUsers = async () => {
        if (listuserInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listuserInnerRef.current;
            if (scrollTop + clientHeight === scrollHeight) {
                try {
                    SetPageNo(pageNo + 1);
                    SetPerPage(perPage);
                    let projectUsers = await getProjectTeamList(projectId, pageNo + 1, perPage, searchKey, status, "");
                    SetProjectUsers(projectUsers);
                } catch (e: any) {
                    throw e
                }
            }
        }
    };


    const fetchInitialData = async () => {
        try {
            let temp: any = projectdetails.state;

            console.log(temp)
            let projectUsers = await getProjectTeamList({ projectid: temp.id, type: temp?.type }, pageNo, perPage, searchKey, status, "");
            SetProjectUsers(projectUsers);
        } catch (e: any) {
            throw e
        }

    }

    function onInvite(type: '') {
        SetPopUpType('');
    }

    const searchUser = async (e) => {
        SetsearchKey(e);
        try {
            let projectUsers = await getProjectTeamList(projectId, pageNo, perPage, e, status, "");
            SetProjectUsers(projectUsers);
        } catch (e: any) {
            throw e
        }

    }

    const onChange = async (e) => {
        SetPageNo(1);
        SetStatus(e.target.value);
        try {
            dispatch(setLoading(true))
            let projectUsers = await getProjectTeamList(projectId, 1, 10, searchKey, e.target.value, "");
            SetProjectUsers(projectUsers);
            dispatch(setLoading(false))
        } catch (e: any) {
            throw e
        }

    };

    const sendMessageAction = async () => {
        try {
            SetPageNo(1);
            SetPerPage(20);
            Setreached(false);
            let sendmessage = await sendMessage(messageTxt, chatWithId, chatWithId, projectId?.projectid);
            let getMessageList = await getChatHistory(projectId.projectid, 1, 20, chatWithId);
            SetgetChatHistory(getMessageList.messages);
            SetMessageTxt("");
        } catch (e: any) {
            throw e
        }

    }

    const getMessageList = async (e, name, dp) => {
        if (status == "accepted") {
            SetuserDp(dp);
            Setreached(false);
            SetChatName(name);
            SetchatWithId(e);
            try {
                dispatch(setLoading(true))
                let getMessageList = await getChatHistory(projectId.projectid, 1, 20, e);
                SetgetChatHistory(getMessageList.messages);
                dispatch(setLoading(false))

            } catch (e: any) {
                throw e
            }

        }

    }

    /**
     * revoke invite 
     */
    const revokeInvite = (name) => {
        console.log(name)
        SetinvokeName(name)
        SetPopUpType("revoke")
    }

    /**
     * procced to revoke permission
     */
    const ConfirmRevoke = async () => {
        console.log(invokeName)
        try {
            let revokeResponse = await revokeUserInvite(invokeName?.['id']);
            if (revokeResponse) {
                showToaster(toasterTypes.SUCCESS, "Invite revoked")
            }
            SetPopUpType("")
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            {
                popUpType == "invite" ?
                    <InviteModal projectid={projectId} {...props} onClose={(type) => onInvite(type)} />
                    : ""
            }
            {
                popUpType == "revoke" ?
                    <ModalComponent
                        isOpen={true}
                        onClose={() => SetPopUpType("")}
                        header="Revoke Invite"
                        centered={true}
                        customClass='invite-modal'
                        body={
                            <>
                                <span>
                                    Are you sure to revoke {invokeName?.['invitee_name']} invite ?
                                </span>
                            </>
                        }
                        footer={
                            <>
                                <CustomButton text="Procced" type="primary" onClick={() => ConfirmRevoke()} />
                            </>
                        }
                    />
                    : ""
            }
            {/* {console.log(JSON.parse(coguideUser).id)} */}
            <div className="people-invite-home">
                <div className="invite-empty">
                    {projectId?.type != "invitee" ?
                        <>
                            <div className="invite-content">
                                <Search allowClear onSearch={(e) => searchUser(e)} placeholder="Search Users" />
                                <div className="invite-icon" onClick={() => SetPopUpType("invite")}>
                                    <Image className="invite-icon-img" src={`${process.env.PUBLIC_URL}/images/invite.svg`} />
                                </div>
                            </div>

                            <div className="sent-status">
                                <Radio.Group value={status} onChange={onChange} style={{ marginBottom: 16 }}>
                                    <Radio.Button value="sent">Sent</Radio.Button>
                                    <Radio.Button value="accepted">Accepted</Radio.Button>
                                    <Radio.Button value="rejected">Rejected</Radio.Button>
                                    <Radio.Button value="revoked">Revoked</Radio.Button>
                                </Radio.Group>
                            </div>
                        </>
                        :
                        ""
                    }

                    <ListGroup className="project-user-list" onScroll={() => onScrollUsers()} ref={listuserInnerRef}>
                        {getProjectusers?.users?.map((item, i) => {
                            return <>
                                <ListGroup.Item>
                                    <Row>
                                        <Col md={10}>
                                            <div className="people-message" onClick={(e) => getMessageList(projectId.type == "invitee" ? item.inviter_id : item.invitee_id, projectId.type == "invitee" ? item.inviter_name : item.invitee_name, item.user_dp)}>
                                                <div className="invite-pic-name">
                                                    <div className="msg-icon">
                                                        <Image className="profile-img" src={item.user_dp} />
                                                    </div>
                                                    <div className="people-detail">
                                                        <span className="mentor-text">{item.invitee_role}</span>
                                                        <span className="mentor-name">{projectId.type == "invitee" ? item.inviter_name : item.invitee_name}</span>
                                                    </div>
                                                </div>
                                                <div className="content">
                                                    <div className="msg-content">
                                                        {item.status == "rejected" ? item.remarks : item.last_message}
                                                    </div>
                                                    {/* <div className="revoke" onClick={() => revokeInvite(item)} >
                                                            Revoke
                                                        </div> */}
                                                </div>

                                            </div>
                                        </Col>
                                        <Col md={2}>
                                            <div className="message-info">
                                                <span className="sent-time">
                                                    {item.status == "sent" || item.status == "rejected" ? moment(item.created_on).format("hh:mm") :
                                                        item.last_message_at && moment(item.last_message_at).format("hh:mm")}
                                                </span>
                                                {item.unread_messages_count ? <Badge className="badge-count">{item.unread_messages_count}</Badge> : ""}
                                            </div>
                                            {
                                                status == "accepted" ?
                                                    <div className="revoke" onClick={() => revokeInvite(item)} >
                                                        Revoke
                                                    </div>
                                                    : ""
                                            }
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                            </>
                        })}

                    </ListGroup>
                    {getProjectusers?.users?.length == 0 ?
                        <div className="invite-content-empty">
                            <span className="empty-text">No Mentors or Collabrators available</span>
                            <Button className="invite-btn" onClick={() => SetPopUpType("invite")}>Invite</Button>
                        </div>
                        :
                        ""
                    }
                </div>
                <div className="people-chat">
                    <div className="chat-header">
                        <Row>
                            <Col md={10}>
                                <div className="invite-pic-name">
                                    <div className="msg-icon">
                                        {userDp ? <Image className="profile-img" src={userDp} /> : ""}

                                    </div>
                                    <div className="people-detail">
                                        <span className="mentor-name">{chatName ? chatName : "Please select user to start chat"}</span>
                                    </div>
                                </div>
                            </Col>
                            <Col md={2}>
                                <DownloadOutlined className="download-icon" />
                                <PrinterFilled className="print-icon" />
                                <StarFilled className="star-icon" />
                            </Col>
                        </Row>
                    </div>
                    <div className="message-list" onScroll={() => onScrollMessages()} ref={listInnerRef}>
                        {ChatHistory?.map((item, i) => {
                            return <>
                                <div className="speech-wrapper">
                                    {JSON.parse(coguideUser).id == item.from_user_id ?
                                        <div className="chatbox triangle right-top alt">
                                            <div className="txt">
                                                <p className="name"></p>{item.message}
                                                <span className="timestamp">{moment(item.sent_at).format("hh:mm a")}
                                                    {!item.read ?
                                                        <img src={`${process.env.PUBLIC_URL}/images/check-mark-icon.svg`} alt='logo' style={{ width: "15px", height: "15px", padding: "0px 2px" }} />
                                                        :
                                                        <img src={`${process.env.PUBLIC_URL}/images/double-tick-icon.svg`} alt='logo' style={{ width: "20px", height: "20px", padding: "0px 2px" }} />}</span>
                                            </div>
                                        </div>
                                        :
                                        <div className="speech-wrapper">
                                            <div className="chatbox_other triangle left-top">
                                                <div className="txt"><p className="name">
                                                </p>{item.message}
                                                    <span className="timestamp-sender">{moment(item.sent_at).format("hh:mm a")}</span>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>

                            </>
                        })}

                    </div>
                    {
                        status == "accepted" ?
                            <div className="message-text">
                                <TextArea placeholder="Message" className="message-area" value={messageTxt} onChange={(e) => SetMessageTxt(e.target.value)} />
                                <Tooltip placement="left" title={!chatName ? "Select User" : messageTxt ? "" : "Type Message"}>
                                    <div className="send-message"> {!chatName || !messageTxt ? <SendOutlined /> : <SendOutlined onClick={() => sendMessageAction()} />}</div></Tooltip>
                            </div>
                            :
                            ""
                    }


                </div>

            </div>

        </>
    )
}