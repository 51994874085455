import { FIELD_TYPES } from "../../widgets/fields";

export const FilterList = {
    roleType: {
        name: "role",
        placeholder: "Select",
        label: "Role Type",
        type: FIELD_TYPES.DROP_DOWN,
        isMandatory: false,
        isListed: true
    },
    userType: {
        name: "user",
        placeholder: "Select",
        label: "User",
        type: FIELD_TYPES.DROP_DOWN,
        isMandatory: false,
        isListed: true
    },
    applyAll: {
        name: "all",
        placeholder: "Apply For all Users",
        label: "",
        type: FIELD_TYPES.CHECK_BOX,
        isMandatory: false,
    }  
}