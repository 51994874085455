import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { CurrentUser, setLoading } from "../../store/slices/auth";
import { expectedDateFormat } from "../../utils/Utils";
import CustomTable from "../../widgets/table";
import { getProfileDetails } from "../profile/apiController";
import { getPieactive } from "./apiController";


export default function Submissions(props){
    const location = useLocation();
    const [forms, setforms] = useState<any | null>([]);
    const [profile, SetProfile] = useState<any[]>([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [permissionParams, SetPermissionParams] = useState<any>({});
  
  
    const getPermissions = async (data) => {
      await data.permissions && data.permissions.map(items => {
        console.log(Object.keys(items)[0].replace(/([^\w]+|\s+)/g, ''))
        
        if(Object.keys(items)[0].replace(/([^\w]+|\s+)/g, '') == "Tabs"){
          items[Object.keys(items)[0]].map(item => {
          if (item.permissions.filter(itm => itm.title == "allowed" && itm.is_selected == true).length != 0) {
            if (item.action == "project details ,") {
            SetPermissionParams(prevState => ({ ...prevState, projectdetail: "allowed" }))
            }else if(item.action == "study design,"){
            SetPermissionParams(prevState => ({ ...prevState, studydesign: "allowed" }))
            }else if(item.action == "Data collection,"){
            SetPermissionParams(prevState => ({ ...prevState, datacollection: "allowed" }))
            }else if(item.action == "Data analysis,"){
            SetPermissionParams(prevState => ({ ...prevState, dataanalysis: "allowed" }))
            }else if(item.action == "mentors,"){
            SetPermissionParams(prevState => ({ ...prevState, mentors: "allowed" }))
            }else if(item.action == "register events"){
            SetPermissionParams(prevState => ({ ...prevState, registerevents: "allowed" }))
            }
          }else{
            if (item.action == "project details ,") {
            SetPermissionParams(prevState => ({ ...prevState, projectdetail: "forbidden" }))
            }else if(item.action == "study design,"){
            SetPermissionParams(prevState => ({ ...prevState, studydesign: "forbidden" }))
            }else if(item.action == "Data collection,"){
            SetPermissionParams(prevState => ({ ...prevState, datacollection: "forbidden" }))
            }else if(item.action == "Data analysis,"){
            SetPermissionParams(prevState => ({ ...prevState, dataanalysis: "forbidden" }))
            }else if(item.action == "mentors,"){
            SetPermissionParams(prevState => ({ ...prevState, mentors: "forbidden" }))
            }else if(item.action == "register events"){
            SetPermissionParams(prevState => ({ ...prevState, registerevents: "forbidden" }))
            }
          }
    
          })
        }	
        
      })
      }

    useEffect(()=>{
        if (location.state) {
          let tempState: any = location.state;
          console.log("hello",tempState)
          getPermissions(tempState);
        }
        fetchInitalData();
    },[])



    /**
     * fetch intial data to be loaded
     * call to get submission list pieActive api
     */
    const fetchInitalData = async () =>{
        try{
            let userData = localStorage.getItem("coguide_user");
            if (userData) {
               dispatch(setLoading(true));
               let currentUser: CurrentUser = JSON.parse(userData);  
               const getActiveData = await getPieactive(currentUser.UserNumber);
               const activeInvites = Object.values(
                getActiveData?.data.reduce((acc, obj) => ({ ...acc, [obj.project_event_id]: obj }), {})
            );
               setforms(getActiveData && activeInvites)               
               dispatch(setLoading(false));
            } 
        }catch(e:any){
          dispatch(setLoading(false));
          throw e
        }
    }

    /**
     * COlumns for table data
     */
    let columns = [
        {
          id: "schedule_name",
          label: "Schedule Name",
          accessor: "project_event_name",
          sort: "asc",
        },
        {
            id: "org_name",
            label: "Organisation Name",
            accessor: "institutename",
            sort: "asc",
          },{
            id: "invite_expiry",
            label: "Invite Expiry Date",
            "accessor": (row: any) => {
                return expectedDateFormat(row.expiry_date, 'DD MMM YYYY')
            },
            sort: "asc",
          },
        {
          id: "view",
          label: "View",
          width: "50",
          className: "text-center",
          Cell: ({ row, data, index }) => {
            const { original } = row;
            return (
              <i
                className="icon icon-eye"
                onClick={() => onAction(original)}
              ></i>
            );
          },
        }
      ];

      /**
       * on view action redirect to information page
       */
      const onAction = (data) =>{
        console.log(data)
        navigate('/submissioninfo',{state:{data:data}})
      }

    return(
        <>
        <div className="p-3">
             <CustomTable
                    header="Records"
                    data={forms && forms || []}
                    tableProps={columns}
                    onAction={(data = null) => onAction(data)}
                  />

        </div>
        </>
    )
}