import { FIELD_TYPES } from "../../widgets/fields";

export const CreateDocumentElements = {
     name: {
          name: "name",
          placeholder: "Enter",
          label: "Document Name",
          type: FIELD_TYPES.TEXT,
          isMandatory: true
     },
     docdescription: {
          name: "docdescription",
          placeholder: "Enter",
          label: "Description",
          type: FIELD_TYPES.TEXT,
          isMandatory: true
     },
     automanual: {
          name: "automanual",
          placeholder: "Enter",
          label: "Auto",
          type: FIELD_TYPES.CHECK_BOX,
          isMandatory: true
     },
     templates: {
          name: "templates",
          placeholder: "Select",
          label: "Templates",
          type: FIELD_TYPES.DROP_DOWN,
          isMandatory:true
     },
     role: {
          name: "role",
          placeholder: "Select",
          label: "",
          type: FIELD_TYPES.DROP_DOWN,
          isMandatory: true,
          menuPlacement: 'top'
      },
      user: {
          name: "user",
          placeholder: "Select",
          label: "",
          type: FIELD_TYPES.DROP_DOWN,
          menuPlacement: 'top'
      },
      accessType: {
          name: "accessType",
          placeholder: "",
          label: "",
          type: FIELD_TYPES.RADIO_BUTTON,
          values: [{label: 'Read', value: 'r'}, {label: 'Write', value: 'w'},{label: 'Forbidden', value: 'f'}],
          isMandatory: true,
          isListed: true
      },
};