import { useEffect } from "react";
import { Card, CardBody, CardFooter, CardHeader } from "reactstrap";
import { CustomButton } from "../../widgets";
import { BarChartOutlined } from "@ant-design/icons";

export default function AnalysisListCardMobile(props) {
    const { data } = props;
    useEffect(() => {
        //console.log(data)
    }, [])
    
    return (
        <>
            <div className="analysis-list-card-mobile">
                <Card className="analysis-card">
                    <CardHeader>
                        <div className="analysis-head">
                            <div className="analysis-name">{data.name}</div>
                            <div className="analysis-type">{data.type}</div>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <div className="analysis-body">
                            <div className="created-date"><span style={{ color: "#000", opacity: "0.4" }}>Created Date: </span>{data.created_on}</div>
                            <div className="updated-date"><span style={{ color: "#000", opacity: "0.4" }} >Updated Date:</span>{data.updated_on || " -  "}</div>
                        </div>
                    </CardBody>
                    <CardFooter className="footer-card">
                        <div className="footer-action">
                            <CustomButton text="Edit" type="primary" onClick={()=>props.editConfig(data)}></CustomButton>
                            <CustomButton text="Analysis" type="primary" onClick={()=>props.viewAnalysis(data)}></CustomButton>
                        </div>                       
                    </CardFooter>
                </Card>
            </div>
        </>
    )
}