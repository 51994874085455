import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setLoading } from '../../store/slices/auth';
import { getCurrentPayment, hasPayment } from '../project/apiController';
import ModalPopup from '../../widgets/modal';
import PermissionData from './PermissionData';
import { expectedDateFormat } from '../../utils/Utils';
import PermissionModal from './PermissionModal';
import CustomTable from '../../widgets/table';
import { getProjectTeamList } from '../people/apiController';
import { getRoles } from '../dataCollection/apiController';

export default function TabPermission(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [popupType, setpopupType] = useState('');
    const [selectedData, setselectedData] = useState("");
    const { data, selectedTab, projectdata } = props;
    const [selectedIndex, SetSelectedIndex] = useState(selectedTab);
    const [userdata, setUserData] = useState<any | null>([]);
    const [popUp, SetpopUp] = useState(false);
    const [userList, SetUserList] = useState<any>([]);
    const [pageNo, Setpageno] = useState(1);
    const [perPage, Setperpage] = useState(100);
	const scrollRef =  React.useRef<any>();
	const [roleList, SetRoleList] = useState<any>([]);

    useEffect(() => {
        console.log(props.projectdata.isOwner)
    }, [userList])


    useEffect(() =>{
		try {
            const usertypes = getRoles();    
            usertypes.then(data => {
                SetRoleList(data.data.map(({
                    key: id,
                    name: title
                }) => ({
                    id,
                    title
                })));
            })

        } catch (e: any) {
            throw e
        }
	},[])
    
    /**
   * get userslist for project
   */
    const fetchInitialData = async (usertype) => {
        try {
            dispatch(setLoading(true));
            if (projectdata) {
                let projectid: any = { projectid: projectdata.id };
                let projectUsers = await getProjectTeamList(projectid, pageNo, perPage, "", "accepted", usertype);
                let userArray: any = [];
                await projectUsers.users && projectUsers?.users.map((item) => {
                    userArray.push(item)
                })
                SetUserList(userArray);
            }
            dispatch(setLoading(false));

        } catch (e: any) {
            dispatch(setLoading(false));
            throw e
        }
    }

    const checkNavigate = async (obj) => {
        SetSelectedIndex(obj.id)
        setselectedData(obj.id);
        fetchInitialData(obj.id);
    }


    /**
   * COlumns for table data
   */
    let columns = [
        {
            id: "invitee_name",
            label: "User",
            width: "80",
            "accessor": (row: any) => {
                return row.invitee_name
            },
            sort: "asc",
        },
        {
            id: "created_on",
            label: "Updated on",
            width: "40",
            "accessor": (row: any) => {
                return expectedDateFormat(row.created_on, 'DD MMM YYYY')
            },
            sort: "asc",
        },
        {
            id: "view",
            label: "View",
            width: "50",
            className: "text-center",
            Cell: ({ row, data }) => {
                const { original } = row;
                return (
                    <i
                        className="icon icon-eye"
                        onClick={() => onAction(1, original)}
                    ></i>
                );
            },
        },
        {
            id: "edit",
            label: "Edit",
            width: "50",
            className: "text-center",
            Cell: ({ row, data }) => {
                const { original } = row;
                return (
                    
                         props.projectdata && props.projectdata.isOwner ? 
                            <i
                            className="icon icon-edit"
                            onClick={() => onAction(2, original)}
                        ></i>
                        :
                        ""
                );
            },
        }
    ];

    /**
     * on view action redirect to information page
     */
    const onAction = (id, data) => {
        console.log(data)
        if(id == 1){
            data.isView = true
        }else{
            data.isView = false 
        }
        setUserData(data)
        SetpopUp(true)
    }

    const onClose = (close) => {
        SetpopUp(false)
    }

    useEffect(() => {
		const el = scrollRef.current;
		if (el) {
		  const onWheel = e => {
			if (e.deltaY == 0) return;
			e.preventDefault();
			el.scrollTo({
			  left: el.scrollLeft + e.deltaY,
			  behavior: "smooth"
			});
		  };
		  el.addEventListener("wheel", onWheel);
		  return () => el.removeEventListener("wheel", onWheel);
		}
	  }, []);

    return (
        <>
            <div className='permission-tabs-container' ref={scrollRef}>
                {data.map((obj, index) => (
                    <div className={`${selectedIndex === obj.id ? 'selected-tab-col tab-col' : 'tab-col'}`} key={index} onClick={() => checkNavigate(obj)}>
                        <div className='tab-text'>{obj.title}</div>
                    </div>
                ))}
            </div>
            {
                popUp ?
                    <PermissionModal projectid={props} {...props} onClose={(type) => onClose(type)} data={userdata} />
                    : ""
            }
            <div className="permissiondata-container">
                <div className="p-3">
                    <CustomTable
                        header="Users"
                        data={userList && userList || []}
                        tableProps={columns}
                        isAdd={props.projectdata && props.projectdata.isOwner}
                        onAction={(data) => onAction(5,{"isView":false})}
                    ></CustomTable>
                </div>
            </div>
        </>
    )
}
