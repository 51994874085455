import { TrophyFilled, CheckCircleFilled, EyeFilled, UserAddOutlined, UploadOutlined, FileTextFilled } from '@ant-design/icons';

export default function ProfileMetrics() {
  const metrics = [
    { icon: <TrophyFilled />, label: "Profile Rank", value: "Top 10%" },
    { icon: <CheckCircleFilled />, label: "Completion Status", value: "85%" },
    { icon: <EyeFilled />, label: "Profile Views", value: "1,234" },
    { icon: <UserAddOutlined />, label: "Profile Invitations", value: "12" },
    { icon: <UploadOutlined />, label: "Number of Submissions", value: "45" },
    { icon: <FileTextFilled />, label: "Number of Publications", value: "5" }
  ];

  return (
    <div className="profile-metrics">
      <h3 className="profile-metrics__header">Profile Metrics</h3>
      <div className="profile-metrics__body">
        {metrics.map((metric, index) => (
          <div key={index} className="profile-metrics__row">
            <div className="profile-metrics__icon">
              {metric.icon}
            </div>
            <div className="profile-metrics__label">
              {metric.label}
            </div>
            <div className="profile-metrics__value">
              {metric.value}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
