import { Switch } from "antd";
import { useEffect, useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setLoading } from "../../store/slices/auth";
import { CustomButton, showToaster, Toaster, toasterTypes } from "../../widgets";
import { FieldItem, FIELD_TYPES } from "../../widgets/fields";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import ModalComponent from "../common/Modal/modal";
import { getProjectTeamList, getUserTypes } from "../people/apiController";
import { getPermissionData, submitPermissionData } from "./apiController";
import { FilterList } from "./FormElements";
import { getRoles } from "../dataCollection/apiController";

export default function PermissionModal(props) {
    const [values, fields, handleChange, { validateValues, reset, updateValue }] = useFieldItem(FilterList, {}, { onValueChange });
    const [roleList, SetRoleList] = useState<any>([]);
    const [userList, SetUserList] = useState<any>([]);
    const [applyAll, SetApplyAll] = useState<any>([{ "value": "All", "label": "Apply For all Users" }])
    const [pageNo, Setpageno] = useState(1);
    const [perPage, Setperpage] = useState(100);
    const [projectData, SetProjectData] = useState<any>(props);
    const [permissionList, SetPermissionList] = useState<any>([]);
    const dispatch = useDispatch();

    useEffect(() => {
        
        try {
            SetProjectData(props)
            const usertypes = getRoles();
            if (props.data) {
                updateValue({
                    role: { label: props.data.invitee_role, value: props.data.invitee_role },
                    user: { label: props.data.invitee_name, value: props.data.invitee_id }
                })
                getPermissionListData(props.data.invitee_role, props.data.invitee_id)
            }
            usertypes.then(data => {
                SetRoleList(data.data.map(({
                    key: value,
                    name: label
                }) => ({
                    value,
                    label
                })));
            })
            //fetchInitialData();
        } catch (e: any) {
            throw e
        }
    }, [1])


    useEffect(() => {
    }, [projectData, permissionList]);

    /**
     * get userslist for project
     */
    const fetchInitialData = async (usertype) => {
        try {
            dispatch(setLoading(true));
            if (projectData) {
                let projectid: any = { projectid: projectData.projectdata.id };
                let projectUsers = await getProjectTeamList(projectid, pageNo, perPage, "", "accepted", usertype);
                let userArray: any = [];
                await projectUsers?.users.filter(item => item.invitee_role == usertype).map((item) => {
                    userArray.push({ label: item.invitee_name, value: item.invitee_id })
                })
                SetUserList(userArray);
            }
            dispatch(setLoading(false));

        } catch (e: any) {
            dispatch(setLoading(false));
            throw e
        }
    }

    /**
   * on value change update form data
   * @param name 
   * @param value 
   * @param values 
   * @param fieldValues 
   * @returns 
   */
    function onValueChange(name, value, values, fieldValues = null) {
        let resetValue = {};
        // updateValue({ permission: !values.permission })
        console.log(value)
        if (name == "role" && values['user']) {
            getPermissionListData(value.value, values['user'].value)
        } else if (name == "user" && values['role']) {
            getPermissionListData(values['role'].value, value.value)
        } else if (name == "role" && !values['user']) {
            getPermissionListData(value.value, "")
        }

        if (name == "role") {
            fetchInitialData(value.value);
        }
        console.log(name, value)

        console.log(values)
        return [{
            ...values,
            ...resetValue,
            [name]: value,
        }]
    }

    /**
     * get permission list on change of user type
     */
    const getPermissionListData = async (type, userid) => {
        try {
            dispatch(setLoading(true));
            let projectId = projectData.projectdata.id;
            const permissionData = await getPermissionData(type, userid, projectId);
            permissionData.data && permissionData.data?.map((item, i) => {
                let keyName = Object.keys(item);
                item[keyName[0]].map((items, j) => {
                    let updateArray: any = [];
                    items.permissions.map((permissionval, k) => {
                        let obj = {
                            value: permissionval.title,
                            label: permissionval.title,
                            isSelected: permissionval.is_selected
                        }
                        if (permissionval.is_selected) {
                            updateValue({ [items.action + i]: permissionval.title })
                        }

                        updateArray.push(obj)
                    })
                    items.permissions = updateArray
                })
            })
            console.log(values)
            SetPermissionList(permissionData.data);
            dispatch(setLoading(false));

        } catch (e: any) {
            dispatch(setLoading(false));
            throw e;
        }
    }

    /**
     * on submit data
     * reformat the structure
     */
    const onSubmit = async () => {
        dispatch(setLoading(true));

        let finalArray: any = [];
        await permissionList.map((item, i) => {
            let keyName = Object.keys(item);
            item[keyName[0]].map((items, j) => {
                let updateArray: any = [];
                let v = items.permissions.filter(itm => itm.is_selected == true)[0]
                items.permissions.map((permissionval, k) => {
                    let selectedValue = values[items.action + i];
                    console.log(items.action + i, selectedValue)
                    if (permissionval.value == selectedValue) {
                        let obj = {
                            title: permissionval.value,
                            permission: permissionval.label,
                            is_selected: true
                        }
                        updateArray.push(obj)

                    } else {
                        let obj = {
                            title: permissionval.value,
                            permission: permissionval.label,
                            is_selected: false
                        }
                        updateArray.push(obj)
                    }
                })
                items.permissions = updateArray
                finalArray.push(items)
            })

        })
        let projectId = projectData.projectdata.id;
        let userId = values?.user?.value || "";
        let role = values.role.value;
        try {
            let submitData = await submitPermissionData(role, userId, projectId, finalArray);
            if (submitData.success) {
                showToaster(toasterTypes.SUCCESS, submitData["message"])
            }
            dispatch(setLoading(false));
        } catch (e: any) {
            dispatch(setLoading(false));
            throw e;
        }
        console.log(values.user.value)

    }

    return (
        <>
            <ModalComponent
                width={"100%"}
                isOpen={true}
                onClose={() => props.onClose()}
                header="Permissions"
                centered={true}
                customClass='invite-modal permission-modal'
                body={
                    <>
                        <div className="permission-modal-container">
                            <Row>
                                <Col md={4}>
                                    <div className="filter">
                                        <FieldItem
                                            {...FilterList.roleType}
                                            value={values.role}
                                            values={roleList ? roleList : []}
                                            isDisabled={props.data && props.data.isView ? true : false}
                                            onChange={(...e) => handleChange(FilterList.roleType.name, ...e)}
                                            touched={FilterList.roleType && FilterList.roleType.hasError}
                                            error={FilterList.roleType && FilterList.roleType.errorMsg}
                                        />
                                    </div>
                                </Col>

                                <Col md={4}>
                                    <div className="filter">
                                        <FieldItem
                                            {...FilterList.userType}
                                            value={values.user}
                                            isDisabled={props.data && props.data.isView ? true : false}
                                            values={userList ? userList : []}
                                            onChange={(...e) => handleChange(FilterList.userType.name, ...e)}
                                            touched={FilterList.userType && FilterList.userType.hasError}
                                            error={FilterList.userType && FilterList.userType.errorMsg}
                                        />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="filter-all">
                                        <FieldItem
                                            {...FilterList.applyAll}
                                            value={values.all}
                                            values={applyAll}
                                            isDisabled={props.data && props.data.isView ? true : false}
                                            onChange={(...e) => handleChange(FilterList.applyAll.name, ...e)}
                                            touched={FilterList.applyAll && FilterList.applyAll.hasError}
                                            error={FilterList.applyAll && FilterList.applyAll.errorMsg}
                                        />
                                        
                                    </div>
                                </Col>

                            </Row>
                            <hr style={{ marginTop: '-30px' }}></hr>
                            <div className="config-section">
                                {
                                    permissionList && permissionList.map((item, index) => {
                                        return <>
                                            <Card className="config-card">
                                                <span className="config-title">{Object.keys(item)}</span>
                                                {
                                                    item[Object.keys(item)[0]].map((items, i) => {
                                                        return <Row>
                                                            <Col md={2}>
                                                                <div className="config-item">
                                                                    <span className="config-title">{items.action}</span>
                                                                </div>
                                                            </Col>
                                                            <Col md={8}>
                                                                <div className="config-item-option">
                                                                    <span className="config-title">
                                                                        <FieldItem
                                                                            name={items.action + index}
                                                                            value={values[items.action + index]}
                                                                            values={items.permissions}
                                                                            type={FIELD_TYPES.RADIO_BUTTON}
                                                                            isListed={true}
                                                                            onChange={(...e) => handleChange(items.action + index, ...e)}
                                                                            touched={fields[i] && fields[i]?.hasError}
                                                                            error={fields[i] && fields[i]?.errorMsg}
                                                                        />
                                                                    </span>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    })
                                                }
                                            </Card>
                                        </>
                                    })
                                }

                            </div>
                        </div>
                    </>
                }
                footer={
                    <Row style={{ paddingTop: "20px" }}>
                        {/* <Col md={8}>
                            <CustomButton text="Cancel" className="cancel-btn" />
                        </Col> */}
                        <Col md={4}>
                            {props.data && !props.data.isView ?
                                <CustomButton text="Save" className="save-btn" onClick={() => onSubmit()} />
                            :""}
                            </Col>
                    </Row>
                }
            />
        </>
    )
}