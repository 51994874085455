import { CaretDownFilled, CaretUpFilled } from "@ant-design/icons";

export default function Sort(props){
    return(
        <>
            <div style={{display:"flex"}}>
                <div>
                    {props.label}
                </div>
                <div style={{display:"flex",flexDirection:"column"}}>
                    <CaretUpFilled style={{color:props.colorinactive,fontSize:"10px", marginBottom:"-2px"}}/>
                    <CaretDownFilled style={{color:props.coloractive,fontSize:"10px"}}/>
                </div>
            </div>
        </>
    )
}