import React, { useEffect, useState } from 'react';
import { Col, Row } from "reactstrap";
import { CustomButton, showToaster, toasterTypes } from '../../widgets';
import ModalComponent from '../common/Modal/modal';
import { FieldItem } from "../../widgets/fields";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import { ContactFormElements } from './FormElements';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../store/slices/auth';
import { getCountries, updateContact } from './apiController';

export default function ContactDetails(props) {
	const initialValues = React.useMemo(() => (props.data ? {
		email: props.data.email, phonecode: props.data.phone && props.data.phone.toString().split(" ").length > 1 ? { label: props.data.phone.split(" ")[0], value: props.data.phone.split(" ")[0] } : {},
		 phone: props.data.phone && props.data.phone.toString().split(" ").length > 1 ? props.data.phone.split(" ")[1] : props.data.phone,
		  workEmail: props.data.work_email,
		  workphonecode: props.data.work_phone && props.data.work_phone.toString().split(" ").length > 1 ? { label: props.data.work_phone.split(" ")[0], value: props.data.work_phone.split(" ")[0] } : "",
		   workPhone: props.data.work_phone && props.data.work_phone.toString().split(" ").length > 1 ? props.data.work_phone.split(" ")[1] : props.data.work_phone
	} : { email: '', phonecode: {}, phone: '', workEmail: '', workphonecode: {}, workPhone: '' }), []);
	const [values, fields, handleChange, { validateValues, reset, updateValue }] = useFieldItem(ContactFormElements, initialValues, {});
	const [countryList, setcountryList] = useState([]);

	const dispatch = useDispatch();
	const checkSubmit = async () => {
		if (validateValues(["email", "phonecode", "phone", "workphonecode" ,"workPhone", "workEmail"])) {
			showToaster(toasterTypes.ERROR, "Please enter the proper values in the fields highlighted in red");
		} else {
			try {
				dispatch(setLoading(true));
				let response = await updateContact({
					"userNumber": props.userNumber,
					"email": values.email,
					"work_email": values.workEmail,
					"phone":  (values.phonecode.value ? values.phonecode.value:"") + " " +(values.phone ? values.phone : "") ,
					"work_phone": (values.workphonecode.value ? values.workphonecode.value:"") + " " +(values.workPhone ? values.workPhone : "")
				});
				showToaster(toasterTypes.SUCCESS, response.message);
				props.onClose('callAPI');
				dispatch(setLoading(false));
			} catch (e: any) {
				dispatch(setLoading(false));
			}
		}
	}

	const fetchCountries = async (country = '') => {
		try {
			dispatch(setLoading(true));
			let response = await getCountries();
			setcountryList(response.data.map(res => ({ label: res.CountryCode, value: res.CountryCode })));

			dispatch(setLoading(false));
		} catch (e: any) {
			dispatch(setLoading(false));
		}
	}

	useEffect(() => {
		fetchCountries();
	}, [])

	return (
		<ModalComponent
			width={window.innerWidth > 700 ? "800px" : "350px"}
			isOpen={true}
			onClose={() => props.onClose()}
			header="Contact"
			centered={true}
			customClass='profile-modal'
			body={
				<div>
					<Row>
						<Col md={5}>
							<FieldItem
								{...ContactFormElements.email}
								value={values.email}
								onChange={(...e) => handleChange(ContactFormElements.email.name, ...e)}
								touched={fields.email && fields.email.hasError}
								error={fields.email && fields.email.errorMsg}
							/>
						</Col>
						
						<Col md={7}>
							<div className="mobile" style={{ display: "flex", flexDirection: "row" }}>
								<Row>
									<Col md={5}>
								<FieldItem
									{...ContactFormElements.phonecode}
									value={values.phonecode}
									values={countryList}
									onChange={(...e) => handleChange(ContactFormElements.phonecode.name, ...e)}
									touched={fields.phonecode && fields.phonecode.hasError}
									error={fields.phonecode && fields.phonecode.errorMsg}
								/>
								</Col>
								<Col md={7}>
								<FieldItem
									{...ContactFormElements.phone}
									value={values.phone}
									onChange={(...e) => handleChange(ContactFormElements.phone.name, ...e)}
									touched={fields.phone && fields.phone.hasError}
									error={fields.phone && fields.phone.errorMsg}
								/>
								</Col>
								</Row>
							</div>

						</Col>
					</Row>
					<Row>
						<Col md={5}>
							<FieldItem
								{...ContactFormElements.workEmail}
								value={values.workEmail}
								onChange={(...e) => handleChange(ContactFormElements.workEmail.name, ...e)}
								touched={fields.workEmail && fields.workEmail.hasError}
								error={fields.workEmail && fields.workEmail.errorMsg}
							/>
						</Col>
						<Col md={7}>
							<div className="mobile" style={{ display: "flex", flexDirection: "row" }}>
							<Row>
									<Col md={5}>
								<FieldItem
									{...ContactFormElements.workphonecode}
									value={values.workphonecode}
									values={countryList}
									onChange={(...e) => handleChange(ContactFormElements.workphonecode.name, ...e)}
									touched={fields.workphonecode && fields.workphonecode.hasError}
									error={fields.workphonecode && fields.workphonecode.errorMsg}
								/>
								</Col>
								<Col md={7}>
								<FieldItem
									{...ContactFormElements.workPhone}
									value={values.workPhone}
									onChange={(...e) => handleChange(ContactFormElements.workPhone.name, ...e)}
									touched={fields.workPhone && fields.workPhone.hasError}
									error={fields.workPhone && fields.workPhone.errorMsg}
								/>
								</Col>
								</Row>
							</div>
						</Col>
					</Row>
				</div>
			}
			footer={
				<div>
					<CustomButton type="primary" isDisabled={false} text="Save" onClick={() => checkSubmit()}></CustomButton>
				</div>
			}
		/>
	)
}