import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { CustomButton } from '../button';

export default function ModalPopup(props) {
    const { modalType, modalText, actionText, buttonText } = props;
  return (
    <Modal
        isOpen={true}
        className="custom-modal delete-modal"
        centered={true}
    >
        <ModalHeader>
            <Row className='w-100 m-0'>
                <Col className='p-0'>Alert</Col>
                <Col className='p-0 text-right'>
                    <i className="icon icon-close" onClick={() => props.closeModal()}></i>
                </Col>
            </Row>
        </ModalHeader>
        <ModalBody>
            <div className='row align-items-center'>
                {modalType === 'delete' &&
                    <div className='col col-sm-2 text-center'>
                        <i className='icon icon-alert' />
                    </div>
                }
                <div className='col'>
                    <div className={`delete-text ${modalType !== 'delete' && actionText ? 'pt-3 pl-2' : modalType !== 'delete' ? 'pt-3 pb-3 pl-2' : ''}`}>{modalText}</div>
                    <div className='delete-label'>{actionText}</div>
                </div>
            </div>
        </ModalBody>
        <ModalFooter>
            <CustomButton type="primary" text={buttonText || 'Confirm'} onClick={props.onAction}></CustomButton>
        </ModalFooter>
    </Modal>
  )
}
