import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { authSelector } from '../../store/slices/auth';
import { expectedDateFormat } from '../../utils/Utils';
import PushToEditor from '../Editor/utils/PushToEditor';
import { CustomButton, showToaster, toasterTypes } from '../../widgets';
import { demo } from '../Editor/demo';
import { useEffect, useState } from 'react';
import SectionModal from '../Editor/utils/SectionModal';
import CopyTooltip from '../Editor/utils/CopyTooltip';

export default function ProjectCard(props) {
	const { currentUser } = useSelector(authSelector);
	const navigate = useNavigate();
	const { data, type } = props;
	const [selectedText, setSelectedText] = useState('');
	const [tooltipStyle, setTooltipStyle] = useState({});
	const [showTooltip, setShowTooltip] = useState(false);
	const [action, setAction] = useState("");
	
	/**
 * function call to Push code to editor
 */
	const addToEditor = (data) => {
		console.log(data)
		let Obj = {
			"type": "paragraph",
			"attrs": {
				"id": "",
				"class": "paragraph",
				"track": [],
				"group": "",
				"viewid": ""
			},
			"content": [
				{
					"type": "text",
					"text": selectedText
				}
			]
		};
		// let response = PushToEditor(Obj, data.section.value)
		// if (response) {
		// 	showToaster(toasterTypes.SUCCESS, "Pushed code to editor")
		// }
		setAction("")

	}

	useEffect(() => {
		// Attach the mouseup event listener to the document
		document.addEventListener('mouseup', getSelectedText);

		// Clean up the event listener on component unmount
		return () => {
			document.removeEventListener('mouseup', getSelectedText);
		};
	}, [selectedText]);

	/**
	 * selected text
	 * @param data 
	 */
	const getSelectedText = () => {
		const selection: any = window.getSelection();
		if (selection.toString().trim()) {
			const range = selection.getRangeAt(0);
			const rect = range.getBoundingClientRect();

			// Update the state with the selected text and tooltip position
			setSelectedText(selection.toString());
			setTooltipStyle({
				top: `${rect.top + window.scrollY + rect.height - 50}px`,
				left: `${rect.left + window.scrollX - 100}px`,
			});

			setShowTooltip(true);
		} else {
			setShowTooltip(false);
		}
	};

	const sectionAction = () => {
		setAction("section")
	}

	return (
		<div className='project-card'>
			{/* {
				action == "section" &&
				<SectionModal pushAction={addToEditor} onclose={() => setAction("")} />
			}
			{showTooltip && (
				<CopyTooltip tooltipStyle={tooltipStyle} data={selectedText} pushAction={sectionAction} />
			)} */}
			<div className='top-row'>
				<Row className='m-0'>
					<Col className='p-0'>
						<div className='created-date'>{expectedDateFormat(data.CreatedOn || data.UpdatedOn, 'DD MMM YYYY', 'YYYY-MM-DD')}</div>
					</Col>
					<Col className='p-0 row m-0 justify-content-end'>
						{/* <CustomButton text="Push to Editor" type={"primary"} onClick={() => addToEditor()}></CustomButton> */}
						
						<div className='data-entry-button'>
							<div className='data-entry-text' onClick={() => props.onData()}>{type === 'projects' ? 'Data Entry' : 'Data Collection'}</div>
						</div>
						{/* hide for time being Sachin & Phani on request delete */}
						{/* {currentUser?.UserType !== 'Internal User' && <i className="icon icon-delete" onClick={() => props.onDelete()}></i>}  */}
						<i className="icon icon-delete" onClick={() => props.onDelete()}></i>
						<i className="icon icon-edit" onClick={() => props.onEdit()}></i>
					</Col>
				</Row>
				<div className='topic-desc'  onClick={() => props.onprojectDetail()}>{data.TopicDesc}</div>
				<div className='topic-scroll' onClick={() => props.onprojectDetail()}>
					{data.TopicBigDesc?.map((obj, index) => (
						<div key={index} className='topic-objective'>{index + 1}. {obj}</div>
					))}
				</div>
			</div>
			
			<Row className='m-0 footer-row'>
				<Col className='p-0 add-people-text'>
					<div onClick={() => props.onPeople()}>
						People
					</div>
					<div onClick={() => props.onPlanner()}>
						Planner
					</div>
					<div onClick={() => props.onDocument()}>
						Document
					</div>
				</Col>

					
				{type === 'projects' ?
					<Col className='p-0 text-right'>
						{
							// props.isEventInvite && props.isEventInvite?.length != 0 ?
							<i className="icon icon-register" onClick={() => props.onRegisterEvent(data)}>RE</i>
							// :""
						}
						<i className="icon icon-study" onClick={() => props.onSampleSize(data)}></i>
						{data.Shared === 'y' && <i className="icon icon-share" onClick={() => props.onMove(data)}></i>}
					</Col> :
					<Col className='p-0 text-right'>
						<i className="icon icon-register" onClick={() => props.onRegisterEvent(data)}>RE</i>
						<i className="iconstudy icon-study" onClick={() => props.onSampleSize(data)}></i>
						<i className="icon icon-upload"></i>
					</Col>
				}
			</Row>
		</div>
	)
}
