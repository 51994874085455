import AjaxUtil from '../../axios/axios';

export const getProjectTypes = async() => {
    try {
        return await AjaxUtil.sendRequest(`projecttypes`, {}, false, { method: 'get', showError: true} );
    } catch (error) {
        throw error;
    }
}

export const createProject = async(topic) => {
    try {
        return await AjaxUtil.sendRequest(`topic`, topic, false, { method: 'post', showError: true} );
    } catch (error) {
        throw error;
    }
}

export const updateProject = async(topic, id) => {
    try {
        return await AjaxUtil.sendRequest(`topic/${id}`, topic, false, { method: 'put', showError: true} );
    } catch (error) {
        throw error;
    }
}