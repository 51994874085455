import AjaxUtil from '../../axios/axios';

export const getTopics = async(page, keyword='') => {
    let url;
    if(keyword) {
        url = `topicsearch/${keyword}/${page}/10`;
    } else {
        url = `topicsearch/all/${page}/10`;
    }
    try {
        return await AjaxUtil.sendRequest(`${url}`, {}, false, { method: 'get', showError: true} );
    } catch (error) {
        throw error;
    }
}