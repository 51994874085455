import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { CustomButton, showToaster, toasterTypes } from '../../widgets';
import { FieldItem } from '../../widgets/fields';
import useFieldItem from '../../widgets/fields/UseFieldItem';
import { AffiliationFormElements } from './FormElements';

export default function Keywords(props) {
    const navigate = useNavigate();
    const initialValues = useMemo(() => (props.previousState ? { keyword: props.previousState } : { keyword: [] }), [props.previousState]);
    const [values, fields, handleChange, { validateValues, updateValue }] = useFieldItem(AffiliationFormElements, initialValues, {});

    const submit = () => {
        if (validateValues(["keyword"])) {
            showToaster(toasterTypes.ERROR, "Please enter the proper values in the fields highlighted in red");
        } else {
            props.next(values);
        }
    };

    return (
        <div className='affiliation-page'>
            <div className='page-header'>
                <img src={`${process.env.PUBLIC_URL}/images/coguide_main_logo.png`} alt='logo' className='logo' />
                <div className='header-text'>{props.projectData ? 'Edit Project' : 'Create Project'}</div>
            </div>
            <div className='affiliation-container'>
                <div className='page-text'>Keywords</div>
                <Row className='keyword-margin'>
                    <Col md={12}>
                        <FieldItem
                            {...AffiliationFormElements.keyword}
                            value={values.keyword}
                            onChange={(...e) => handleChange(AffiliationFormElements.keyword.name, ...e)}
                            touched={fields.keyword && fields.keyword.hasError}
                            error={fields.keyword && fields.keyword.errorMsg}
                        />
                    </Col>
                    <Col>
                        <span>
                            By enabling the strict mode, ensure the integrity, reliability, transparency and compliance for the project.
                        </span>
                        <FieldItem
                            {...AffiliationFormElements.strictmode}
                            value={values.strictmode}
                            onChange={(...e) => handleChange(AffiliationFormElements.strictmode.name, ...e)}
                            touched={fields.strictmode && fields.strictmode.hasError}
                            error={fields.strictmode && fields.strictmode.errorMsg}
                        />
                    </Col>
                </Row>



            </div>
            <Row className='affiliation-footer m-0'>
                <Col className='p-0'>
                    <div className='cancel-text' onClick={() => navigate('/project')}>Cancel</div>
                </Col>
                <Col className='p-0'>
                    <div className='w-100 d-flex justify-content-end'>
                        <div className='mr-3'>
                            <CustomButton type="alert-primary" onClick={() => props.previous()} className="w-100" text="Previous"></CustomButton>
                        </div>
                        <CustomButton type="primary" onClick={() => submit()} className="w-100" text="Submit"></CustomButton>
                    </div>
                </Col>
            </Row>
            <div className='page-footer'></div>
        </div>
    )
}
