import { FIELD_TYPES } from "../../widgets/fields";

export const FormElements = {
    searchField: {
        name: "searchField",
        placeholder: "Search",
        label: "",
        type: FIELD_TYPES.SEARCH,
        isMandatory: true
    }
}