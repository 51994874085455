import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import ObjectiveGeneration from './ObjectiveGeneration';
import Progress from './Progress';
import TitleGeneration from './TitleGeneration';

export default function CreateProject() {

	const [pageType, setpageType] = useState('');
	const [pageState, setpageState] = useState(null);
	const [projectData, setprojectData] = useState<any | null>(null);
	const location = useLocation();

	useEffect(() => {
		if (location.state) {
			let stateValue: any = location.state;
			setprojectData(stateValue);
			let temp: any = {selectedProgress: 0, affiliationState: null, titleState: null, objectiveState: null};
			if(stateValue?.affiliation_info) {
				temp.affiliationState = {
					"country": {
						"label": "",
						"value": stateValue.affiliation_info.country || ''
					},
					"state": {
						"label": "",
						"value": stateValue.affiliation_info.state
					},
					"city": stateValue.affiliation_info.city,
					"college": {
						"label": stateValue.affiliation_info.institute_name,
						"value": stateValue.affiliation_info.institutecode
					},
					"projectType": {
						"label": "",
						"value": stateValue.affiliation_info.project_type
					},
					"funding": {
						"label": "",
						"value": stateValue?.funding_info?.funded_project ? 'yes' : 'no'
					},
					"fundType": {
						"label": "",
						"value": stateValue?.funding_info?.fund_type
					},
					"quantity": stateValue?.funding_info?.quantum_of_funding,
					"fundingAgency": stateValue?.funding_info?.funding_agency,
					"approvalStatus": {
						"label": "",
						"value": stateValue?.funding_info?.funding_status
					}
				}
			}
			temp.titleState = {
				title: stateValue.TopicDesc
			};
			temp.objectiveState = {
				data: stateValue.TopicBigDesc
			};
			temp.keywordState = stateValue.key_words;
			setpageState(temp);
		}
		setpageType('progress');
	}, [location.state]);

	const settitle = (title) => {
		let temp: any = cloneDeep(pageState);
		temp.titleState.title = title;
		setpageState(temp);
		setpageType('progress');
	}

	const setobjective = (objective) => {
		let temp: any = cloneDeep(pageState);
		temp.objectiveState.data[temp.objectiveState.index] = objective;
		setpageState(temp);
		setpageType('progress');
	}


	return (
		<div className='create-project-page'>
			{
				pageType === 'title' ? <TitleGeneration projectData={projectData} onCancel={() => setpageType('progress')} onTitle={(title) => settitle(title)} /> :
				pageType === 'objective' ? <ObjectiveGeneration projectData={projectData} onCancel={() => setpageType('progress')} onObjective={(objective) => setobjective(objective)} /> :
				pageType === 'progress' ? <Progress projectData={projectData} onPage={(type, pageState) => { setpageType(type); setpageState(pageState) }} pageState={pageState} /> : null
			}		
		</div>
	)
}
