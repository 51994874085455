import { CustomButton } from "../../widgets";
import ModalComponent from "../common/Modal/modal";
// import { Button, Checkbox, Form, Input, Select } from 'antd';
import { Col, Row } from "reactstrap";
import { FormElements } from "../home/FormElements";
import { FieldItem, FIELD_TYPES } from "../../widgets/fields";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import { useEffect, useState } from "react";
import { SearchPeopleForm } from "../dataCollection/FormElements";
import InviteCard from "./InviteCard";
import { Button, Form, Image, ListGroup } from "react-bootstrap";
import { getUsersList, getUserTypes, sendUserInvite } from "./apiController";
import { useNavigate } from "react-router-dom";
import { getRoles } from "../dataCollection/apiController";


export default function PeopleListChat(props){
    const navigate = useNavigate();
    const [emailValue, SetEmail] = useState("");
    const [typeValue, SetType] = useState("");
    const [userTypes,SetUserTypes] = useState<any | []>([]);
    const [userList,SetUserList] = useState<any | []>([]);
    const [sentRequest, SetsentRequest] = useState(false);
    const [getProjectusers,SetProjectUsers] = useState<any>([]);

    const FormElements = {
		search: {
			name: "search",
			placeholder: "Search",
			label: "",
			type: FIELD_TYPES.SEARCH,
			isMandatory: false,
		},
	}
	const [values, fields, handleChange] = useFieldItem(FormElements, {}, {});

    useEffect(()=>{
        fetchIntialData()
    },[])

     useEffect(()=>{
    },[userTypes,userList]);

    const fetchIntialData = async()=>{
        const usertypes = await getRoles();
        const userList = await getUsersList("mentor,colaborator",1,10);
     
        SetUserTypes(usertypes);
        SetUserList(userList);
        console.log(userList);
    }

    const getValues = () =>{
        console.log(values.search)
    }

    const inviteAction = async(e)=>{
        e.preventDefault();
        //console.log(e.target);
        const response = await sendUserInvite({email:emailValue,invite_type:typeValue});
        if(response.success){
            SetsentRequest(true);
        }
    }

    const setRequestSent = () =>{
        SetsentRequest(!sentRequest);
    }

    const redirectProfile = () =>{
       // navigate("/peopleprofilemobile");
    }

    return(
        <>
        <div className="people-invite-container-mob">

            
            <div className="search-invite-mob">
                
            <div className="invite-content-mob">
                        <FieldItem
                            className="search-text-mob"
                            {...FormElements.search}
                            value={values.search}
                            onChange={(...e) => handleChange(FormElements.search.name, ...e)}
                            touched={fields.search && fields.search.hasError}
                            error={fields.search && fields.search.errorMsg}
                        />
                      
                </div>
            </div>

             <div className="invite-user-list-mob">

                <b>People</b>
             <ListGroup className="project-user-list-mob">
                   
                            <ListGroup.Item>   
                                    <Row>
                                        <Col>
                                        <div className="people-message-mob">
                                        <div className="invite-pic-name">
                                            <div className="msg-icon">
                                                <Image className="profile-img" src={`${process.env.PUBLIC_URL}/images/invite.svg`} />
                                            </div>                             
                                            <div className="people-detail">
                                                <span className="mentor-name">Sachin</span>
                                                <span className="mentor-profession">Developer</span>
                                            </div>
                                            </div>  
                                            
                                        </div> 
                                        </Col>
                                        <Col>
                                        <div className="invite-btn-mob">         
                                            <span className="invite-btn">2 Messages</span>
                                            </div> 
                                        </Col>
                                    </Row>                                  
                                  
                            </ListGroup.Item>                     
                    
                </ListGroup> 
             </div>
        </div> 
        </>
    );
} 