import { cloneDeep, isArray } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { setLoading } from "../../store/slices/auth";
import { CustomButton, showToaster, toasterTypes } from "../../widgets";
import { FieldItem, FIELD_TYPES } from "../../widgets/fields";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import { uploadPhoto } from "../profile/apiController";
import dayjs from 'dayjs';
import {
  getNonSurveyForm,
  submitIdentifierForm,
  updateIdentifierForm,
  uploadRecord,
  validateIdentifierForm,
} from "./apiController";
import IdentifierLevel from "./IdentifierLevel";
import './DataEntry.css';
import { CreateVariableElements } from './FormElements';
import { MESSAGES } from "../../utils/Constants";
import { exit } from "process";
import { DownloadOutlined } from "@ant-design/icons";
import { ModifyReasonModal } from "./ModifyReasonModal";

export default function DataEntry(props) {
  // const [values, fields, handleChange, { validateValues, reset, updateValue }] =
  //   useFieldItem({}, {}, { onValueChange });
  const [values, fields, handleChange, { validateValues, reset, updateValue, resetValue, updateError }] = useFieldItem(CreateVariableElements, {}, { onValueChange });
  const [check, SetcheckData] = useState("");
  const [startDate, SetstartDate] = useState(new Date());
  const [isLocked, SetisLocked] = useState(false);
  const [isCategory, SetIsCategory] = useState("");

  interface ProjectData { }

  interface FormData {
    projectData: ProjectData;
    form_name: string;
    id: string;
    recordId?: string;
  }
  interface FormFields {
    form_type: string;
    form_fields: Array<FieldObject>;
  }
  interface FieldObject {
    id: string;
    field_type: string;
    field_name: string;
    field_use_type: string;
    field_selected_value?: any;
    field_value?: Array<Object>;
  }
  var selectedIndex: any = null;
  const [formData, setformData] = useState<FormData | null>(null);
  const [formFields, setformFields] = useState<FormFields | any>(null);
  const [selectedValues, setselectedValues] = useState<any | null>(null);
  const [isRootForm, setisRootForm] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [validMsg, SetvalidMsg] = useState({});
  const [errorMobile, SeterrorMobile] = useState(false);
  const [errorInteger, SeterrorInterger] = useState(false);
  const [errorEmail, SeterrorEmail] = useState(false);
  const [errorRange, SeterrorRange] = useState(false);
  const [errorMandatory, SeterrorMandatory] = useState(false);
  const [errorValidate, SeterrorValidate] = useState(false);

  /**
   * set version id
   */
  useEffect(() => {
    localStorage.setItem("versionid", Date.now().toString());
  }, [1])

  /**
   * make intial data function calls
   */
  useEffect(() => {
    if (location.state) {
      let temp: any = location.state;
      setselectedValues(temp.selectedValues);
      temp.data.recordId = temp.id || "";
      setformData(temp.data);
      console.log(temp)
      fetchFormData(location.state);
    }
  }, []);



  /**
   * validate fields on frontend or backend depending on the field type
   * @param obj 
   * @param validate 
   * @param selectedValue 
   */
  const checkFrontEndValidation = (obj, index) => {
    console.log('obj1::', obj);
    obj.validations?.map((items, i) => {
      if (items.startsWith('FE')) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const numberRegex = /^[0-9]\d{9}$/;
        if (items == "FE-EmailFormat") {
          if (!emailRegex.test(obj.field_selected_value)) {
            SetcheckData("yes");
            SeterrorEmail(true);
            updateError(index, { hasError: true, errorMsg: obj.field_selected_value == undefined || obj.field_selected_value == "" ? 'Email cannot be empty' : 'Please entry valid Email' });
            return;

          } else {
            SeterrorEmail(false);
            updateError(index, { hasError: false, errorMsg: "" });
            return;
          }
        } else if (items == "FE-Integer") {
          var result = (obj.field_selected_value - Math.floor(obj.field_selected_value)) !== 0;
          if (result) {
            SeterrorInterger(true);
            updateError(index, { hasError: true, errorMsg: obj.field_selected_value == undefined || obj.field_selected_value == "" ? 'Mobile cannot be empty' : 'Please entry valid Mobile' });
            return
          } else {
            SeterrorInterger(false);

            updateError(index, { hasError: false, errorMsg: "" });
            return
          }
        }
        else if (items == "FE-MobileNumber") {
          if (!numberRegex.test(obj.field_selected_value)) {
            SeterrorMobile(true);

            updateError(index, { hasError: true, errorMsg: obj.field_selected_value == undefined || obj.field_selected_value == "" ? 'Mobile cannot be empty' : 'Please entry valid Mobile' });
            return
          } else {
            SeterrorMobile(false);

            updateError(index, { hasError: false, errorMsg: "" });
            return
          }
        } else if (items == "FE-Mandatory") {
          if (obj.field_selected_value?.length == 0 || obj.field_selected_value == 'undefined' || obj.field_selected_value == null) {
            SeterrorMandatory(true);

            updateError(index, { hasError: true, errorMsg: 'Field cannot be empty' });
            return;
          } else {
            SeterrorMandatory(false);

            updateError(index, { hasError: false, errorMsg: '' });
            return;
          }
        } else if (items == "FE-Range") {
          const min = obj.field_min_value;
          const max = obj.field_max_value;
          // Check the range
          if (parseInt(obj.field_selected_value) < parseInt(min) || parseInt(obj.field_selected_value) > parseInt(max)) {
            SeterrorRange(true);

            updateError(index, { hasError: true, errorMsg: `Values should be between ${min} to ${max}` });
          } else {
            SeterrorRange(false);

            updateError(index, { hasError: false, errorMsg: '' });
          }

        } else {

        }


      } else if (items.startsWith('BE')) {
        let versionid: number = Number(localStorage.getItem("versionid"));

        const resObj = {
          field_id: obj.id,
          value: obj.field_selected_value,
          version_id: versionid
        }

        //console.log(resObj);
        const response = validateIdentifierForm("", [resObj]);
        response.then(respData => {
          SeterrorValidate(false);
        }).catch(err => {
          SeterrorValidate(true);
          //updateError(index, {hasError:true, errorMsg: "Please enter valid data"});

        })
      } else {

      }
    });


  }

  /**
   * check if root and action_enable false then execute the if condition
   * if non root or action enabled true execute the else part which redirects to identifierLevel.tsx
   * @param data 
   */
  const fetchFormData = async (data) => {
    try {
      let temp: any = location.state;

      dispatch(setLoading(true));
      let response = await getNonSurveyForm(data.data.id);
      console.log(response.data)
      if (response.data.form_type === "root" && !response.data.form_fields[response.data.form_fields.length - 1].action_enabled) {

        response.data.form_fields.forEach((obj) => {

          if (data.data.isPartialModify) {
            let tempValue = data.selectedValues.filter(
              (elem) => elem.id === obj.id && elem.field_selected_value && elem.field_selected_value != ""
            );
            if (data.data.isPartialModify && tempValue[0]?.field_selected_value.length != 0 && tempValue.length > 0) {
              obj.isPartial = true
            } else {
              obj.isPartial = false
            }

          }


          if (
            obj.field_type === "dropDown" ||
            obj.field_type === "radioButton" ||
            obj.field_type === "checkBox"
          ) {
            let options: any = [];
            obj.field_value.forEach((elem) => {
              options.push({
                label: elem,
                value: elem,
              });
            });
            if (data.selectedValues && data.selectedValues.length > 0) {
              let tempValue = data.selectedValues.filter(
                (elem) => elem.id === obj.id
              );
              if (tempValue.length === 1) {
                if (obj.field_type === "checkBox") {
                  //pushing to array if the values for checkbox is String
                  let selectedData = tempValue[0]?.field_selected_value;
                  if (typeof selectedData == "string") {
                    let dataArray: any = [];
                    dataArray.push(tempValue[0]?.field_selected_value)
                    selectedData = dataArray;
                  }
                  selectedData && selectedData.forEach((elem) => {

                    let tempVal = options.filter(
                      (subElem) => subElem.label === elem
                    );
                    console.log(obj.field_selected_value);
                    if (tempVal.length === 1) {
                      if (
                        obj.field_selected_value &&
                        obj.field_selected_value?.length > 0
                      ) {
                        obj.field_selected_value.push(elem);
                      } else {
                        obj.field_selected_value = [elem];
                      }
                    }
                  });
                  //}

                } else {
                  let tempVal = options.filter(
                    (elem) => elem.label === tempValue[0].field_selected_value
                  );
                  if (tempVal.length === 1) {
                    if (obj.field_type === "dropDown") {
                      obj.field_selected_value = tempVal[0];
                    } else {
                      obj.field_selected_value = tempVal[0].value;
                    }
                  }
                }
              }
            }
            obj.field_value = options;
          } else if (obj.field_type === "boolean") {
            obj.field_selected_value = false;
            if (data.selectedValues && data.selectedValues.length > 0) {
              let tempValue = data.selectedValues.filter(
                (elem) => elem.id === obj.id
              );
              if (tempValue.length === 1) {
                obj.field_selected_value = tempValue[0].field_selected_value;
              }
            }
          } else if (obj.field_type === "fileUpload") {
            // obj.field_selected_value = false;
            if (data.selectedValues && data.selectedValues.length > 0) {
              let tempValue = data.selectedValues.filter(
                (elem) => elem.id === obj.id
              );
              console.log(tempValue)
              if (tempValue.length > 0) {
                obj.field_selected_value = tempValue[0].field_selected_value;
              }
            }
          } else {
            if (data.selectedValues && data.selectedValues.length > 0) {
              let tempValue = data.selectedValues.filter(
                (elem) => elem.id === obj.id
              );
              if (tempValue.length === 1) {
                console.log(tempValue[0].field_selected_value)
                obj.field_selected_value =
                  obj.field_type === "dateField"
                    ? tempValue[0].field_selected_value ? tempValue[0].field_selected_value : null
                    : tempValue[0].field_selected_value;
              }
            }
          }
        });
        response.data.isPartial = data.data.isPartialModify
        setformFields(response.data);
      } else {

        response.data.form_fields.forEach((obj) => {
          console.log(data.data.isPartialModify)
          if (data.data.isPartialModify) {
            let tempValue = data.selectedValues.filter(
              (elem) => elem.id === obj.id
            );

            if (data.data.isPartialModify && tempValue[0]?.field_selected_value.length != 0 && tempValue.length > 0) {
              obj.isPartial = true
            } else {
              obj.isPartial = false
            }
          }

          if (obj.field_type === "dropDownPreviousResponses") {
            let options: any = [];

            obj.field_value.forEach((elem) => {
              options.push({
                label: elem.value,
                value: elem.version_id,
              });
            });

            if (data.selectedValues && data.selectedValues.length > 0) {
              let tempValue = data.selectedValues.filter(
                (elem) => elem.id === obj.id
              );
              let tempVal = options.filter(
                (elem) => elem.label === tempValue[0].field_selected_value
              );

              if (tempVal.length > 0) {
                obj.field_selected_value = tempVal[0];
              }
            }

            console.log(obj)
            obj.field_value = options;
          } else if (obj.field_type === "boolean") {
            obj.field_selected_value = false;
            if (data.selectedValues && data.selectedValues.length > 0) {
              let tempValue = data.selectedValues.filter(
                (elem) => elem.id === obj.id
              );
              if (tempValue.length === 1) {
                obj.field_selected_value = tempValue[0].field_selected_value;
              }
            }
          } else if (
            obj.field_type === "dropDown" ||
            obj.field_type === "radioButton" ||
            obj.field_type === "checkBox"
          ) {

            let options: any = [];
            obj.field_value.forEach((elem) => {
              options.push({
                label: elem,
                value: elem,
              });
            });

            if (data.selectedValues && data.selectedValues.length > 0) {

              let tempValue = data.selectedValues.filter(
                (elem) => elem.id === obj.id
              );

              if (tempValue.length === 1) {
                if (obj.field_type === "checkBox") {
                  //pushing to array if the values for checkbox is String
                  let selectedData = tempValue[0]?.field_selected_value;
                  if (typeof selectedData == "string") {
                    console.log("-------------", typeof selectedData)
                    let dataArray: any = [];
                    dataArray.push(tempValue[0]?.field_selected_value)
                    selectedData = dataArray;
                  }

                  selectedData?.forEach((elem) => {

                    let tempVal = options.filter(
                      (subElem) => subElem.label === elem
                    );

                    if (tempVal.length === 1) {
                      if (
                        obj.field_selected_value &&
                        obj.field_selected_value.length > 0
                      ) {
                        obj.field_selected_value.push(elem);
                      } else {
                        obj.field_selected_value = [elem];
                      }
                    }
                  });
                } else {
                  let tempVal = options.filter(
                    (elem) => elem.label === tempValue[0].field_selected_value
                  );

                  if (tempVal.length === 1) {
                    if (obj.field_type === "dropDown") {
                      obj.field_selected_value = tempVal[0];
                    } else {
                      console.log(obj.field_type, tempVal[0])
                      obj.field_selected_value = tempVal[0];

                    }
                  }
                }
              }

            }
            obj.field_value = options;
          } else {
            if (data.selectedValues && data.selectedValues.length > 0) {
              let tempValue = data.selectedValues.filter(
                (elem) => elem.id === obj.id
              );
              if (tempValue.length === 1) {
                console.log(tempValue[0].field_selected_value)

                obj.field_selected_value =
                  obj.field_type === "dateField"
                    ? tempValue[0].field_selected_value ? tempValue[0].field_selected_value : null
                    : tempValue[0].field_selected_value;
              }
            }
          }
        });

        response.data.form_fields = [response.data.form_fields];
        response.data.isPartial = data.data.isPartialModify;
        response.data.islocked = temp.data.locked;
        setformFields(response.data);

        setisRootForm(false);
      }
      dispatch(setLoading(false));
    } catch (e: any) {
      dispatch(setLoading(false));
    }
  };

  /**
   * on value change update form data
   * @param name 
   * @param value 
   * @param values 
   * @param fieldValues 
   * @returns 
   */
  function onValueChange(name, value, values, fieldValues = null) {
    console.log(value)
    let temp: any = cloneDeep(formFields);
    temp.form_fields[selectedIndex].field_selected_value = value;
    setformFields(temp);
    return [
      {
        ...values,
        [name]: value,
      },
    ];
  }

  /**
   * On upload get inout from upload  form
   */
  const onUpload = () => {
    let elem = document.getElementById("fileInput");
    elem?.click();
  };

  const onFileSelect = async (e) => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

    let id: any = formData?.id;
    const fileFormData = new FormData();
    fileFormData.append("file", e.target.files[0]);
    fileFormData.append("form_id", id);
    fileFormData.append("time_zone", tz)

    try {
      dispatch(setLoading(true));
      let response = await uploadRecord(fileFormData);
      if (response.success) {
        navigate("/dataCollection", { state: formData?.projectData });
        showToaster(toasterTypes.SUCCESS, response.message);
      }
      dispatch(setLoading(false));
    } catch (e: any) {
      dispatch(setLoading(false));
    }
  };

  const uploadDoc = async (data, resolve, reject) => {
    try {
      dispatch(setLoading(true));
      let response = await uploadPhoto(data);
      if (response.mediaUrl) {
        resolve(response.mediaUrl);
      }
      dispatch(setLoading(false));
    } catch (e: any) {
      reject("");
      dispatch(setLoading(false));
    }
  };

  /**
   * onSave trigger api to submit data to server
   * @returns 
   */
  const onSave = async (reason) => {
    let isEmpty = false;
    formFields?.form_fields.forEach((obj, i) => {
      //commented for time being ,as form fields are not mandatory
      // if (
      //   obj.field_selected_value === undefined ||
      //   obj.field_selected_value === "" ||
      //   (isArray(obj.field_selected_value) &&
      //     obj.field_selected_value.length === 0)
      // ) {
      //   isEmpty = true;
      // }

      if (obj.validations?.toString().startsWith('FE') || obj.validations?.toString().startsWith('BE') || fields[i] && fields[i].hasError) {
        if (
          obj.field_selected_value === undefined ||
          obj.field_selected_value === "" ||
          (isArray(obj.field_selected_value) &&
            obj.field_selected_value.length === 0)
        ) {
          isEmpty = true;
        }
      }

    });

    setformFields(formFields);
    //commented as form fields are not mandatory
    if (isEmpty) {
      showToaster(toasterTypes.ERROR, "Please answer the form");
      return;
    }
    try {
      dispatch(setLoading(true));
      let fields: any = [];
      formFields?.form_fields.forEach(async (obj) => {
        if (obj.field_type === "fileUpload") {
          if (typeof obj.field_selected_value != "string") {
            const formData = new FormData();
            formData.append("file", obj.field_selected_value && obj?.field_selected_value[0]);
            obj.field_selected_value && await new Promise(async (resolve, reject) => {
              await uploadDoc(formData, resolve, reject);
            })
              .then((result: any) => {
                obj.field_selected_value = result;
              })
              .catch((error) => {
                obj.field_selected_value = error;
              });
          }
        } else if (obj.field_type === "dropDown") {
          obj.field_selected_value = obj.field_selected_value?.value;
        }
        fields.push({
          id: obj.id,
          field_selected_value: obj.field_selected_value,
        });
        if (fields.length === formFields?.form_fields.length) {
          console.log(fields, formFields)
          let versionid: number = Number(localStorage.getItem("versionid"));

          let endDate = new Date();
          //get time zone
          const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

          let reqObj: any = {
            form_id: formData?.id,
            version_id: versionid,
            start_time: startDate,
            end_time: endDate,
            fields,
            update_remarks: reason,
            time_zone: tz

          };
          let response;
          if (selectedValues) {
            reqObj.id = formData?.recordId;
            console.log(formData)
            response = await updateIdentifierForm(reqObj);
          } else {
            response = await submitIdentifierForm(reqObj);
          }
          if (response.success) {
            showToaster(toasterTypes.SUCCESS, response.message);
            let temp: any = location.state;
            navigate("/project", { state: temp.projectData });
          }
        }
      });
    } catch (e: any) {
      dispatch(setLoading(false));
    }
  };

  const getReason = (reason) => {
    console.log(reason)
    onSave(reason)
    SetisLocked(false)
  }

  const onClose = () => {
    SetisLocked(false)
  }

  /**
   * get category to print
   */
  let cat = "";
  const getCategory = (data) => {
    if (data.field_category != cat) {
      cat = data.field_category;
      return true
    } else {
      return false
    }
  }

  return (
    <div className="view-form">
      {
        isLocked ?
          <ModifyReasonModal getReasonData={getReason} isClose={() => onClose()} />
          :
          ""
      }
      <div className='m-0 name-container'>
        <div className='p-0'>
          <div className='form-name'>{formData?.form_name}</div>
        </div>
        <div className='p-0 text-right'>
          <i className='icon icon-close' onClick={() => navigate('/project', { state: formData?.projectData })} />
        </div>
      </div>
      <>{console.log(formFields)}</>

      {formFields?.form_type === "root" && isRootForm && (
        <Row>
          {formFields?.form_fields.map((obj, index) => (
            <>
              {
                getCategory(obj) ?
                  <Col md={12}><hr></hr><h5>{obj.field_category}</h5></Col>
                  : ""
              }
              <Col md={obj.field_type === "checkBox" ? 12 : 6} key={index}>
                <FieldItem
                  name={obj.id}
                  placeholder={
                    obj.field_type === "number" ||
                      obj.field_type === "textBox" ||
                      obj.field_type === "textArea"
                      ? "Enter"
                      : "Select"
                  }
                  isDisabled={obj.isPartial ? true : false}
                  label={obj.field_name}
                  type={
                    obj.field_type === "fileUpload"
                      ? FIELD_TYPES.FILE_UPLOAD
                      : obj.field_type === "dateField"
                        ? FIELD_TYPES.DATEPICKER_NEW
                        : obj.field_type === "dropDown"
                          ? FIELD_TYPES.DROP_DOWN
                          : obj.field_type === "dropDownPreviousResponses"
                            ? FIELD_TYPES.DROP_DOWN
                            : obj.field_type === "boolean"
                              ? FIELD_TYPES.BOOLEAN
                              : obj.field_type === "radioButton"
                                ? FIELD_TYPES.RADIO_BUTTON
                                : obj.field_type === "checkBox"
                                  ? FIELD_TYPES.CHECK_BOX
                                  : obj.field_type === "textArea"
                                    ? FIELD_TYPES.TEXT_AREA
                                    : obj.field_type === "textBox"
                                      ? FIELD_TYPES.TEXT
                                      : obj.field_type === "time"
                                        ? FIELD_TYPES.TIME_PICKER
                                        : obj.field_type === "dateTime"
                                          ? FIELD_TYPES.DATE_TIME_PICKER
                                          : FIELD_TYPES.TEXT
                  }
                  isNumber={obj.field_type === "number" ? true : false}
                  value={obj.field_type == "dateField" ? obj.field_selected_value ? dayjs(obj.field_selected_value) : null : obj.field_selected_value}
                  values={obj.field_value}
                  isListed={true}
                  touched={fields[index] && fields[index].hasError}
                  error={fields[index] && fields[index].errorMsg}
                  onChange={(...e) => {
                    selectedIndex = index;
                    handleChange(obj.id, ...e);
                  }}
                  //onBlur={(e) => obj.id}
                  onBlur={() => checkFrontEndValidation(obj, index)}
                />
                {/* <span style={{fontSize:"12px",padding:"5px 0px"}}>{obj.field_type === "fileUpload" && typeof(obj.field_selected_value && obj.field_selected_value) != "object" ? obj.field_selected_value && obj.field_selected_value.substring(obj.field_selected_value.lastIndexOf('/') + 1) : "" }</span> */}
                {obj.field_type === "fileUpload" && typeof (obj.field_selected_value && obj.field_selected_value) != "object" ?
                  <span style={{ fontSize: "12px", padding: "10px" }}>{obj.field_selected_value && obj.field_selected_value.substring(obj.field_selected_value.lastIndexOf('/') + 1)}
                    <a href={obj.field_selected_value} download><DownloadOutlined style={{ fontSize: "20px", padding: "0px 5px" }} /> </a>
                  </span>
                  : ""}
              </Col>
            </>
          ))}
        </Row>
      )}
      {!isRootForm && (
        <IdentifierLevel
          recordId={formData?.recordId}
          formFieldsData={formFields}
          onUpload={() => onUpload()}
          selectedValues={selectedValues}
        ></IdentifierLevel>
      )}

      {isRootForm && (
        <Row className="footer-card">
          <div>
            <input
              type="file"
              value=""
              id="fileInput"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              className="hide-input"
              onChange={(e) => onFileSelect(e)}
            />
            <CustomButton
              type="alert-secondary"
              onClick={() => onUpload()}
              text="Upload"
            ></CustomButton>
          </div>
          <div>
            <CustomButton
              isDisabled={errorEmail == true || errorMandatory == true || errorMobile == true || errorRange == true || errorValidate == true || errorInteger == true ? "disabled" : ""}
              type="primary"
              onClick={() => { formData && !formData["locked"] ? onSave("") : SetisLocked(true) }}
              text="Update"
            ></CustomButton>
          </div>
        </Row>
      )}
    </div>
  );
}
