import React, { useEffect, useState } from 'react';
import { Col, Row } from "reactstrap";
import { CustomButton, showToaster, toasterTypes } from '../../widgets';
import ModalComponent from '../common/Modal/modal';
import { FieldItem } from "../../widgets/fields";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import { AwardsFormElements } from './FormElements';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../store/slices/auth';
import { addAward, updateAward } from './apiController';

export default function Awards(props) {
	const initialValues = React.useMemo(() => ({ awardType: null, awardName: '', awardAgency: '', description: '', url: '' }), []);
	const [values, fields, handleChange, { validateValues, updateValue }] = useFieldItem(AwardsFormElements, initialValues, {});
	const [awardsList, setawardsList] = useState([{ label: 'Academic', value: 'academic' }]);
	const { selectedIndex, data } = props;
	const dispatch = useDispatch();

	useEffect(() => {
		if (selectedIndex !== null && selectedIndex >= 0 && data && data.length > 0) {
			let awardData = data[selectedIndex];
			//As per discussion today changed award type to open text
			updateValue({ awardType: awardData.award_type, awardName: awardData.award_name, awardAgency:awardData.award_agency, description: awardData.award_description, url: awardData.award_url });
		}
	}, [data]);

	const checkSubmit = async () => {
		if (validateValues(["awardType", "awardName", "description", "url"])) {
			showToaster(toasterTypes.ERROR, "Please enter the proper values in the fields highlighted in red");
		} else {
			try {
				dispatch(setLoading(true));
				let reqObj: any = {
					award_type: values.awardType,
					award_name: values.awardName,
					award_agency: values.awardAgency,
					award_description: values.description,
					award_url: values.url
				}
				let response: any;
				if (selectedIndex !== null && selectedIndex >= 0) {
					response = await updateAward(reqObj, data[selectedIndex].id);
				} else {
					response = await addAward(reqObj);
				}
				showToaster(toasterTypes.SUCCESS, response.message);
				props.onClose('callAPI');
				dispatch(setLoading(false));
			} catch (e: any) {
				dispatch(setLoading(false));
			}
		}
	}

	return (
		<ModalComponent
			width={window.innerWidth > 700 ? "800px" : "350px"}
			isOpen={true}
			onClose={() => props.onClose()}
			header="Awards"
			centered={true}
			customClass='profile-modal'
			body={
				<div>
					<Row>
						<Col md={4}>
							<FieldItem
								{...AwardsFormElements.awardType}
								value={values.awardType}
								values={awardsList}
								onChange={(...e) => handleChange(AwardsFormElements.awardType.name, ...e)}
								touched={fields.awardType && fields.awardType.hasError}
								error={fields.awardType && fields.awardType.errorMsg}
							/>
						</Col>
						<Col md={4}>
							<FieldItem
								{...AwardsFormElements.awardName}
								value={values.awardName}
								onChange={(...e) => handleChange(AwardsFormElements.awardName.name, ...e)}
								touched={fields.awardName && fields.awardName.hasError}
								error={fields.awardName && fields.awardName.errorMsg}
							/>
						</Col>

						<Col md={4}>
							<FieldItem
								{...AwardsFormElements.awardAgency}
								value={values.awardAgency}
								onChange={(...e) => handleChange(AwardsFormElements.awardAgency.name, ...e)}
								touched={fields.awardAgency && fields.awardAgency.hasError}
								error={fields.awardAgency && fields.awardAgency.errorMsg}
							/>
						</Col>
					</Row>
					<Row>
						<Col md={12}>
							<FieldItem
								{...AwardsFormElements.url}
								value={values.url}
								onChange={(...e) => handleChange(AwardsFormElements.url.name, ...e)}
								touched={fields.url && fields.url.hasError}
								error={fields.url && fields.url.errorMsg}
							/>
						</Col>
					</Row>
					<Row>
						<Col md={12}>
							<FieldItem
								{...AwardsFormElements.description}
								value={values.description}
								onChange={(...e) => handleChange(AwardsFormElements.description.name, ...e)}
								touched={fields.description && fields.description.hasError}
								error={fields.description && fields.description.errorMsg}
							/>
						</Col>
					</Row>
					
				</div>
			}
			footer={
				<div>
					<CustomButton type="primary" isDisabled={false} text="Save" onClick={() => checkSubmit()}></CustomButton>
				</div>
			}
		/>
	)
}