import React, { useEffect, useRef, useState} from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { HuePicker } from 'react-color';
import { downloadChartAsImage } from './downloadChartAsImage';
import SectionModal from '../../Editor/utils/SectionModal';
import { uploadChartAsImage } from '../../Editor/utils/UploadToServer';


const BoxPlot = ({ containerid,chartstyle,data,label,chartdots,chartdatastyle,title,titlecolor,width,height,yaxislabelstyle,xaxislabelstyle,gridcolor,gridstatus,yaxisstyle,xaxisstyle,xaxistext,yaxistext }) => {
  const svgRef = useRef(null);
  const [showValues, setshowValue] = useState(true);
  const [ylabel,setylabel] = useState(yaxistext)
  const [xlabel,setxlabel] = useState(xaxistext)
  const [color, Setcolor] = useState("#000000")
  const [colorLabel, SetcolorLabel] = useState("black")
	const [hideColor, SethideColor] = useState(false)
  const [FontWeight, SetFontWeight] = useState(false)
  const [fontSize, SetFontSize] = useState('16');
  const [actionPush, setactionPush] = useState('');

  /**
  * function call to Push code to editor
  */
  const addToEditor = (data) => {
      console.log(data)
      uploadChartAsImage(data,containerid)
      setactionPush("")
  }

    // Function to calculate the median
    const calculateMedian = (values) => {
      const sortedValues = values.slice().sort((a, b) => a - b);
      const mid = Math.floor(sortedValues.length / 2);
      return sortedValues.length % 2 !== 0 ? sortedValues[mid] : (sortedValues[mid - 1] + sortedValues[mid]) / 2;
    };

  useEffect(() => {
    console.log(data)
    // Clear previous content
   d3.select(svgRef.current).selectAll('*').remove();

    // Create tooltip
    const q1tool = d3
    .select(svgRef.current)
    .append('div')
    .style('position', 'absolute')
    .style('background', '#f4f4f4')
    .style('padding', '2px')
    .style('border', '1px solid #ccc')
    .style('font-size',"12px")
    .style('border-radius', '5px')
    .style('opacity', 0);

    const q3tool = d3
    .select(svgRef.current)
    .append('div')
    .style('position', 'absolute')
    .style('background', '#f4f4f4')
    .style('padding', '2px')
    .style('border', '1px solid #ccc')
    .style('font-size',"12px")
    .style('border-radius', '5px')
    .style('opacity', 0);

    const mediantool = d3
    .select(svgRef.current)
    .append('div')
    .style('position', 'absolute')
    .style('background', '#f4f4f4')
    .style('padding', '2px')
    .style('border', '1px solid #ccc')
    .style('font-size',"12px")
    .style('border-radius', '5px')
    .style('opacity', 0);


    const mintool = d3
    .select(svgRef.current)
    .append('div')
    .style('position', 'absolute')
    .style('background', '#f4f4f4')
    .style('padding', '2px')
    .style('border', '1px solid #ccc')
    .style('font-size',"12px")
    .style('border-radius', '5px')
    .style('opacity', 0);

    const maxtool = d3
    .select(svgRef.current)
    .append('div')
    .style('position', 'absolute')
    .style('background', '#f4f4f4')
    .style('padding', '2px')
    .style('border', '1px solid #ccc')
    .style('font-size',"12px")
    .style('border-radius', '5px')
    .style('opacity', 0);

    // Set up dimensions
    const margin = { top: 20, right: 20, bottom: 50, left: 50 };
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    //Create SVG container
    const svg = d3
    .select(svgRef.current)
    .append('svg')
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)
    .append('g')
    .style("overflow", "visible")
    .attr('transform', `translate(${width / 4},${height / 6})`);
    ;

    // Create scales
    const xScale = d3.scaleBand()
      .domain(label.map((d, i) => d))
      .range([margin.left, innerWidth + margin.left])
      .padding(0.5);

    const yScale = d3.scaleLinear()
      .domain([d3.min(data, group => d3.min(group) - (d3.min(group)*10/100)), 
        d3.max(data, group => d3.max(group) + (d3.min(group)*10/100))])
      .range([innerHeight + margin.top, margin.top]);

     const colorScale = d3.scaleOrdinal(d3.schemeCategory10); // Or any other color scheme
    
     console.log(colorScale(1))
    // Draw boxplots
    svg.selectAll('g')
      .data(data)
      .enter()
      .append('g')
      .attr('transform', (d, i) => `translate(${xScale(label[i])},0)`)
      .each(function (group, i) {
      const boxHeight = Math.abs(yScale(d3.quantile(group, 0.25)) - yScale(d3.quantile(group, 0.75)));

      // Calculate box plot parameters
      const q1 = d3.quantile(group, 0.25);
      const q3 = d3.quantile(group, 0.75);
      const iqr = q3 - q1;
      const median = calculateMedian(group);
      const min = Math.min(...group);
      const max = Math.max(...group)

          
        //box
        d3.select(this)
          .append('rect')
          .attr('x', 0)
          .attr('y', yScale(d3.quantile(group, 0.75)))
          .attr('width', xScale.bandwidth())
          .attr('height', boxHeight)
          .attr('border','1px solid')
          .attr('fill', colorScale(i))
          //.on("mouseover", (d) => setHoveredValue({x:d.x,y:d.y,q1:q1,q3:q3,median:median,min:min,max:max}))
          // .on("mouseover", (d) =>{
          
          //   const xPosition = xScale.bandwidth()*2;
            
          //   console.log(q1tool.node().offsetWidth)
          //   //Calculate the left position for the tooltip
          // let left = q1tool.node().offsetWidth;

           
          //   q1tool.transition().duration(200).style('opacity', 0.9);
          //   q1tool.html(`${q1}<br/>`)
          //     .style('left', `${left}px`)
          //     .style('top', `${yScale(q1-10)}px`);
            
          //     q3tool.transition().duration(200).style('opacity', 0.9);
          //     q3tool.html(`${q3}<br/>`)
          //       .style('left', `${left}px`)
          //       .style('top', `${yScale(q3-10)}px`);
            
          //       mediantool.transition().duration(200).style('opacity', 0.9);
          //       mediantool.html(`${median}<br/>`)
          //         .style('left', `${left}px`)
          //         .style('top', `${yScale(median-10)}px`);
        
          //         mintool.transition().duration(200).style('opacity', 0.9);
          //         mintool.html(`${min}<br/>`)
          //           .style('left', `${left}px`)
          //           .style('top', `${yScale(min-10)}px`);
            
          //           maxtool.transition().duration(200).style('opacity', 0.9);
          //           maxtool.html(`${max}<br/>`)
          //             .style('left', `${left}px`)
          //             .style('top', `${yScale(max-10)}px`);
               
          // })
          // .on("mouseout",()=>{
          //   q1tool.transition()
          //   .duration(500)
          //   .style("opacity", 0);
          

          //   q3tool.transition()
          //   .duration(500)
          //   .style("opacity", 0);
           

          //   mediantool.transition()
          //   .duration(500)
          //   .style("opacity", 0);
            

          //   mintool.transition()
          //   .duration(500)
          //   .style("opacity", 0);
          

          //   maxtool.transition()
          //   .duration(500)
          //   .style("opacity", 0);
          // })
          ;

           /**
           * set values on top of chart
           */
        d3.select(this)
          .append('text')
          .text(`Q3: ${q3.toFixed(2).replace(/\.00$/, '')}`)
          .attr('x', xScale.bandwidth()) // Adjust as needed
          .attr('y', yScale(q3) - 2) // Adjust as needed
          .attr('text-anchor', 'start') // Adjust as needed
          .style("visibility", showValues  ? "visible" : "hidden")
          .style('font-size', '12px'); // Adjust as needed
           

          d3.select(this)
          .append('text')
          .text(`Q1: ${q1.toFixed(2).replace(/\.00$/, '')}`)
          .attr('x',xScale.bandwidth()) // Adjust as needed
          .attr('y', yScale(q1) + 12) // Adjust as needed
          .attr('text-anchor', 'start') // Adjust as needed
          .style("visibility", showValues  ? "visible" : "hidden")
          .style('font-size', '12px'); // Adjust as needed

          d3.select(this)
          .append('text')
          .text(`Med: ${median.toFixed(2).replace(/\.00$/, '')}`)
          .attr('x', xScale.bandwidth()) // Adjust as needed
          .attr('y', yScale(median) + 2) // Adjust as needed
          .attr('text-anchor', 'start') // Adjust as needed
          .style("visibility", showValues  ? "visible" : "hidden")
          .style('font-size', '12px'); // Adjust as needed

          d3.select(this)
          .append('text')
          .text(`Min: ${min.toFixed(2).replace(/\.00$/, '')}`)
          .attr('x', xScale.bandwidth()) // Adjust as needed
          .attr('y', yScale(min) + 2) // Adjust as needed
          .attr('text-anchor', 'start') // Adjust as needed
          .style("visibility", showValues  ? "visible" : "hidden")
          .style('font-size', '12px'); // Adjust as needed


          d3.select(this)
          .append('text')
          .text(`Max: ${max.toFixed(2).replace(/\.00$/, '')}`)
          .attr('x', xScale.bandwidth()) // Adjust as needed
          .attr('y', yScale(max) + 2) // Adjust as needed
          .attr('text-anchor', 'start') // Adjust as needed
          .style("fill","#000")
          .style("visibility", showValues  ? "visible" : "hidden")
          .style('font-size', '12px'); // Adjust as needed

        //median line
        d3.select(this)
          .append('line')
          .attr('x1', xScale.bandwidth()/10-5)
          .attr('y1', yScale(d3.quantile(group, 0.5)))
          .attr('x2', xScale.bandwidth())
          .attr('y2', yScale(d3.quantile(group, 0.5)))
          .attr('stroke', 'black');

        //upper line whisker
        d3.select(this)
          .append('line')
          .attr('x1', xScale.bandwidth() / 2)
          .attr('y1', yScale(d3.quantile(group, 0.75)))
          .attr('x2', xScale.bandwidth() / 2)
          .attr('y2', yScale(d3.max(group)))
          .attr('stroke', 'black');

          //lower whisker
        d3.select(this)
          .append('line')
          .attr('x1', xScale.bandwidth() / 2)
          .attr('y1', yScale(d3.quantile(group, 0.25)))
          .attr('x2', xScale.bandwidth() / 2)
          .attr('y2', yScale(d3.min(group)))
          .attr('stroke', 'black');

          //Add fence
          d3.select(this)
        .append('line') // Upper fence
        .attr('x1', xScale.bandwidth() / 2 - 10)
        .attr('y1', yScale(d3.max(group)))
        .attr('x2', xScale.bandwidth() / 2 + 10)
        .attr('y2', yScale(d3.max(group)))
        .attr('stroke', 'red')
        .attr('stroke-dash', '4');

          d3.select(this)
          .append('line') // Lower fence
          .attr('x1', xScale.bandwidth() / 2 - 10)
          .attr('y1', yScale(d3.min(group)))
          .attr('x2', xScale.bandwidth() / 2 + 10)
          .attr('y2', yScale(d3.min(group)))
          .attr('stroke', 'red')
          .attr('stroke-dash', '3');
  
      });


    // Add axes
    const xAxis = d3.axisBottom(xScale);
    const yAxis = d3.axisLeft(yScale);

    svg.append('g')
      .attr('transform', `translate(0,${innerHeight + margin.top})`)
      .attr('style',`  white-space: nowrap; overflow: hidden;  text-overflow: ellipsis; color:${xaxislabelstyle.color};
      textfont-weight: ${xaxislabelstyle.fontweight}; font-size:${xaxislabelstyle.fontsize}`)
      .call(xAxis);

    svg.append('g')
      .attr('transform', `translate(${margin.left},0)`)
      .attr('style',`  white-space: nowrap; overflow: hidden;  text-overflow: ellipsis; color:${yaxislabelstyle.color};
      textfont-weight: ${yaxislabelstyle.fontweight}; font-size:${yaxislabelstyle.fontsize}`)
      .call(yAxis);

      //add x axis label
      svg.append('text')
      .attr("class", "x label")
      .attr("text-anchor", "middle")
      .attr("x", width/2)
      .attr("y", height - 2)
      .attr("dy", xaxisstyle.dy)
      .attr("dx",width/10 - margin.left)
      .style('fill',colorLabel)
      .style('font-weight',FontWeight ? "700":"100")
      .style('font-size',fontSize)
      .text(xlabel)
      .on("click",() =>{
        let label = prompt("Edit X Axis Label")
          if(label){
          setxlabel(label)
          }
      });
 
      //add y axis label
      svg.append("text")
      .attr("class", "y label")
      .attr('transform', `translate(20)`)
      .attr("text-anchor", "end")
      .attr("y", 6)
      .attr("dy", yaxisstyle.dy)
      .attr("x",-height/6)
      .attr("transform", "rotate(-90)")
      .style('fill',colorLabel)
      .style('font-weight',FontWeight ? "700":"100")
      .style('font-size',fontSize)
      .text(ylabel)
      .on("click",() =>{
        let label = prompt("Edit Y Axis Label")
        if(label){
          setylabel(label)
          }
      });

    //title of the chart
     svg.append('text')
     .attr('class', 'title')
     .attr('x', width / 2)
     .attr('y', -margin.top)
     .attr('text-anchor', 'middle')
     .style('font-size',titlecolor.fontsize)
     .style('font-weight',titlecolor.fontweight)
     .style('fill',titlecolor.color)
     .text(title)
     
     return () => {
      d3.select(svgRef.current).selectAll('*').remove();
    };
  }, [data,showValues,ylabel,xlabel,color,colorLabel,FontWeight,fontSize]);

  const handleFontSizeChange = (event) =>{
    SetFontSize(event.target.value);
  }
  
  return <>
          <div className="controller no-print">
          {
                actionPush == "section" &&
                <SectionModal pushAction={addToEditor} onclose={() => setactionPush("")} />
            }
            <Button className='grid no-print' style={{backgroundColor:colorLabel}} onClick={() => SethideColor(!hideColor)}>
               Color
            </Button>
            <Button className='grid no-print' onClick={() => setshowValue(!showValues)}>
              {showValues ? 'Hide Values' : 'Show Values'}
            </Button>
            <Button className='grid no-print' style={{fontWeight:FontWeight ? "700":"100"}} onClick={() => SetFontWeight(!FontWeight)}>
               B
            </Button>
            <select className='grid no-print' id="font-size" value={fontSize} onChange={handleFontSizeChange}>
              <option value="12">12px</option>
              <option value="14">14px</option>
              <option value="16">16px</option>
              <option value="18">18px</option>
              <option value="20">20px</option>
              <option value="24">24px</option>
              <option value="28">28px</option>
              <option value="32">32px</option>
            </select>
            <Button className='grid no-print' onClick={() => downloadChartAsImage(containerid)}>
                Download
            </Button>
            <Button className='grid no-print' onClick={() => setactionPush("section")}>
              Copy to Editor
            </Button>
           </div>
           {hideColor ? 
        <div className='colorpicker no-print'>
            {/* <span>Bar</span>
            <HuePicker
                color={colorBar}
                onChange={(e)=>SetcolorBar(e.hex)}
            />  */}
            {/* <span>Box</span>
            <span style={{color:"blue", padding:"0px 5px"}} onClick={() => Setcolor("#000000")}>Reset</span>
               <HuePicker
                color={color}
                onChange={(e)=>Setcolor(e.hex)}
            /> */}
             <span>Label Color</span>
             <span style={{color:"blue", padding:"0px 5px"}} onClick={() => SetcolorLabel("#000000")}>Reset</span>
               <HuePicker
                color={colorLabel}
                onChange={(e)=>SetcolorLabel(e.hex)}
                presetColors={['#D0021B', '#F5A623', '#F8E71C', '#8B572A', '#7ED321', '#417505', '#BD10E0', '#9013FE', '#4A90E2', '#50E3C2', '#B8E986', '#000000', '#4A4A4A', '#9B9B9B', '#FFFFFF']}
            />
            </div>
            :""}
        <div ref={svgRef} id={containerid} style={{ position: 'relative'}}>
          </div>
  </>
};

export default BoxPlot;
