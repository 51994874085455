import { useEffect, useState } from "react";

export function useTimer(start) {
    const [count, setCount] = useState(start);
    let timerInterval: any = null;

    useEffect(() => {
        if (start) {
            timerInterval = setInterval(() => {
                let tempCount;
                setCount(prevCount => {
                    tempCount = prevCount -1;
                    return tempCount;
                })
                if(tempCount < 0 ) {
                    clear();
                }
            }, 1000);
        }
    }, [start]);
    const clear = () => {
        if(timerInterval === null){
            return;
        }
        clearInterval(timerInterval);

    }

    return count;
}