const LineHeight = {
     attrs: {
       style: { default: null },
       class: { default: 'lineheight' },
     },
     inclusive: false,
     parseDOM: [
       {
         tag: 'span.lineheight',
         getAttrs(hook, next) {
           Object.assign(hook, {
             class: hook.dom.getAttribute('class'),
             style: hook.dom.getAttribute('style'),
           });
           next();
         },
       },
     ],
     toDOM(hook, next) {
       // eslint-disable-next-line no-param-reassign
       hook.value = ['span', hook.node.attrs, 0];
       next();
     },
   };
   
   export default LineHeight;