//React
import React, { useState, useEffect } from 'react';

// React Strap
import { Row, Col, Input } from "reactstrap";

// Making the Component available to the rest of the application
export default function CustomPager(props) {
    const { totalPages, currentPage } = props;
    const [pageInput, setpageInput] = useState<any | 0>(0);

    // Method to be triggered when page is changed
    useEffect(() => {
        if(pageInput) {
            setpageInput(currentPage);
        }
    }, [currentPage]);

    // Method to be triggered on page change from input
    function handlePageNumber(e, type='') {
        let value = e.target.value;
        if(e.keyCode === 13) {
            if(pageInput) {
                e.preventDefault();
                let docId: any = document.getElementById('pagerInput')
                docId.blur();
            } else {
                let docId: any = document.getElementById('pagerInput')
                docId.blur();
                setpageInput(null);
            }
        } else if(type === 'change') {
            if(value === '' || (value > 0 && value <= totalPages)) {
                setpageInput(value);
            }
        }
    }
    
    // Method to be triggered on input blur
    function handleBlur(e) {
        if(pageInput && parseInt(currentPage) !== parseInt(pageInput)) {
            e.preventDefault();
            props.onChange(pageInput, false);
        } else if(pageInput === '') {
            setpageInput(null);
        }
    }
    
    return (
        <Row className="m-0 pager-container">
            <Col className="p-0" xs={9}>
                <div className="d-flex  justify-content-end container-height align-items-center">
                    <div className="items-text pr-2">Page</div>
                    <Input disabled={totalPages === 0} className="input-box p-1 pl-2" id="pagerInput"
                        onBlur={(e) => handleBlur(e)}
                        onKeyUp={(e) => handlePageNumber(e, 'key')}
                        onChange={(e) => handlePageNumber(e, 'change')}
                        maxLength={totalPages}
                        value={pageInput === "" ? "" : pageInput ? pageInput: currentPage}
                    />
                    <div className="items-text items-text-of pl-2">of</div>
                    <div className="items-text count-text pl-2">{totalPages}</div>
                    <div className='row m-0 align-items-center'>
                        <div className={`${parseInt(currentPage) === 1 ? 'disable-text pl-2' : 'enable-text pl-2'}`} onClick={() => props.onChange(currentPage - 1)}>Prev</div>
                        <div className='enable-text pl-2'>|</div>
                        <div className={`${totalPages === parseInt(currentPage) ? 'disable-text pl-2' : 'enable-text-next pl-2'}`} onClick={() => props.onChange(currentPage + 1)}>Next</div>
                    </div>
                </div>
            </Col>
        </Row>
    )
}
