import { ArrowLeftOutlined } from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import { Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { getAnalysisList } from "./apiController";
import { useDispatch } from "react-redux";
import { setLoading } from "../../store/slices/auth";
import { CustomButton } from "../../widgets";
import ConfigureAnalysisMobile from "./ConfigureAnalysisMobile";
import AnalysisListCardMobile from "./AnalysisListCardMobile";
import AnalysisReport from "./AnalysisReport";

export default function DataAnalysisMobile(props) {
    const [pageNum, SetPageNum] = useState(1);
    const [pageSize, SetPageSize] = useState(10);
    const [totalCount, SetCount] = useState(0);
    const [popupType, setpopupType] = useState("");
    const [analysisData, SetanalysisData] = useState<any>([]);
    const [selectedItem, SetSelectedItem] = useState<any>({});
    const listInnerRef = useRef<HTMLInputElement>(null);
    const [endScroll, setendScroll] = useState(false);

    const { data } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        getAnalysis(pageNum);
    }, [])

    useEffect(() => {
    }, [analysisData])

    /**
    * get analysis list
    */
    const getAnalysis = async (pagenum) => {
        try {
            dispatch(setLoading(true));
            let analysisListResponse = await getAnalysisList(props?.group.id, pageSize, pagenum,props?.formData.id);
            if(!analysisListResponse.data && pagenum > 1){
              setendScroll(true)                
            }else{
                let updateArray = [...analysisData,...analysisListResponse.data]
                SetanalysisData(updateArray)
                if (!totalCount) {
                    SetCount(analysisListResponse?.total_count);
                }
            }
            dispatch(setLoading(false));
        } catch (error) {
            dispatch(setLoading(false));
        }
    }

    /**
     * onclose configure
     */
    const onClose = () => {
        setpopupType("")
        SetSelectedItem("")
        getAnalysis(1);
    }

    /**
     * view analysis report
     */
    const viewAnalysisReport = (item) =>{
        SetSelectedItem(item)
        setpopupType("analysis")
    }

    /**
     * edit analysis configuration
     */
    const editAnalysisConfig = (item) =>{
        SetSelectedItem(item)
        setpopupType("configure")
    }

      /**
     * scroll to bottom get next set of data
     */
      const onScroll = async () => {
      
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            //alert(reached)
           if (-Math.abs(Math.ceil(Math.abs(scrollTop)))  === clientHeight - scrollHeight) {
                try {
                    if(!endScroll){
                        SetPageNum(pageNum+1)
                        getAnalysis(pageNum+1)
                    }
                   
                } catch (e: any) {
                    throw e
                }
            }
        }
    };

    return (
        <>
            {
                popupType == "configure" ?
                    <ConfigureAnalysisMobile data={data} {...props} selectedItem={selectedItem} onClose={() => onClose()} />
                    :
                    popupType == "analysis" ? 
                    <AnalysisReport data={data} selectedItem={selectedItem} {...props} onClose={() => onClose()} />
                    :
                    <div className="config-analysis-mobile">
                        <div className="header-container">
                            <div className='pl-3 pt-4 form-name'>
                                <Image className='profile-image' alt='Profile' src={`${process.env.PUBLIC_URL}/images/logo.png`} />
                                Data Analysis
                            </div>
                            <div className='pt-4 closeicon'>
                                <i className='icon icon-close' onClick={() => props.onClose()} />
                            </div>
                        </div>

                        {
                            analysisData.length == 0 ?
                            <div className="main-container">
                                    <span>No analysis are available proceed to create</span>
                                    <CustomButton type="primary" className="create-btn" text="Create" onClick={() => setpopupType('configure')} />
                                
                            </div>:""
                        }
                        {
                            analysisData &&
                            <div className="main-container-list" onScroll={() => onScroll()} ref={listInnerRef}>
                                {
                                    analysisData.map((items, i) => {
                                        return <div className="create-analysis-list" key={i}>
                                            <AnalysisListCardMobile data={items} viewAnalysis={viewAnalysisReport} editConfig={editAnalysisConfig}/>
                                        </div>
                                    })
                                }
                                {
                                    endScroll && analysisData ? 
                                    <div className="create-analysis">
                                        <span style={{color:"grey"}}>No more analysis are available</span>
                                     </div>:""
                                }
                            </div>
                        }
                        {
                            analysisData.length > 0 ?
                            <div className="create-analysis">
                                <CustomButton type="primary" className="create-btn" text="Create Configuration" onClick={() => setpopupType('configure')} />
                            </div>:""
                        }
                    </div>
            }
        </>
    )
}