import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { HuePicker } from 'react-color';
import SectionModal from '../../Editor/utils/SectionModal';
import { uploadChartAsImage } from '../../Editor/utils/UploadToServer';
import { downloadChartAsImage } from './downloadChartAsImage';


const GroupedBarChart = ({containerid,barstyle, data, bardatastyle, title, titlecolor, width, height, yaxislabelstyle, xaxislabelstyle, gridcolor, gridstatus, yaxisstyle, xaxisstyle, xaxistext, yaxistext }) => {
  const svgRef = useRef(null);
  const [hide, SetHide] = useState(bardatastyle.visible)
  const [showGridLines, setShowGridLines] = useState(true);
  const [ylabel, setylabel] = useState(yaxistext)
  const [xlabel, setxlabel] = useState(xaxistext)
  const [color, Setcolor] = useState("#000000")
  const [colorLabel, SetcolorLabel] = useState("black")
  const [hideColor, SethideColor] = useState(false)
  const [FontWeight, SetFontWeight] = useState(false)
  const [fontSize, SetFontSize] = useState('16');
  const [actionPush, setactionPush] = useState('');

  /**
  * function call to Push code to editor
  */
  const addToEditor = (data) => {
      console.log(data)
      uploadChartAsImage(data,containerid)
      setactionPush("")
  }

  useEffect(() => {
    if (!data || data.length === 0) return;
    const margin = { top: 20, right: 20, bottom: 30, left: 40 };
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;
    let topValue = 'hidden'

    const svg = d3
      .select(svgRef.current)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .style("overflow", "visible")
      .attr('transform', `translate(${width / 5},${height / 8})`);
    ;

    // Remove previous elements
    svg.selectAll('*').remove();

    //create scale
    const x0 = d3.scaleBand()
      .domain(data.map(d => d.group))
      .rangeRound([0, innerWidth])
      .paddingInner(0.1);

    const x1 = d3.scaleBand()
      .domain(data[0].values.map(d => d.name))
      .rangeRound([0, x0.bandwidth()])
      .padding(0.05);

    const y = d3.scaleLinear()
      .domain([0, d3.max(data, d => d3.max(d.values, d => parseInt(d.value)))])
      .rangeRound([innerHeight, 0]);

    const color = d3.scaleOrdinal()
      .range(d3.schemeCategory10);

    const xAxis = d3.axisBottom(x0);

    const yAxis = d3.axisLeft(y);



    //add grids
    svg
      .append('g')
      .attr('class', 'grid')
      .attr('transform', `translate(0,0)`)
      .style("stroke-dasharray", "5 5")
      .style("visibility", showGridLines ? "visible" : "hidden")
      .style("color", gridcolor)
      .call(d3.axisLeft(y).tickSize(-width).tickFormat(''));


    svg.append('g')
      .selectAll('g')
      .data(data)
      .enter().append('g')
      .attr('class', '.bar-group')
      .attr('transform', d => `translate(${x0(d.group)},0)`)
      .selectAll('rect')
      .data(d => d.values)
      .enter().append('rect')
      .attr('x', d => x1(d.name))
      .attr('y', d => y(d.value))
      .attr('width', x1.bandwidth())
      .attr('height', d => innerHeight - y(d.value))
      .attr('fill', d => color(d.name))
      .attr('data-category', (d) => d.name)
      .on("mouseenter", function (d) {
        d3.select(d.currentTarget)
          .transition()
          .duration(100)
          .attr('opacity', 0.6);

        // Show tooltip on mouseover
        tooltip.transition().duration(200).style('opacity', 0.9);
        tooltip.html(`${d.target.__data__.name}: ${d.target.__data__.value}`)
          .style('left', `${d.pageX}px`)
          .style('top', `${d.pageY - 28}px`);

      })
      .on("mouseleave", function (d) {
        d3.select(d.currentTarget)
          .transition()
          .duration(200)
          .attr('opacity', 1)
        //remove tooltip
        tooltip.transition().duration(500).style('opacity', 0);
      });

    // Create legends
    const legend = svg
      .selectAll('.legend')
      .data(color.domain())
      .enter()
      .append('g')
      .attr('class', 'legend')
      .attr('transform', (d, i) => `translate(0,${i * 20})`)
      .on('click', toggleLegend);



    legend
      .append('rect')
      .attr('x', width - margin.top)
      //.attr('y',height)
      .attr('width', 18)
      .attr('height', 18)
      .style('fill', color);

    legend
      .append('text')
      .attr('x', width)
      //.attr('y',height)
      .attr('dy', '0.70em')
      .style('text-anchor', 'start')
      .text((d) => d);


    // Add text labels on top of bars
    svg
      .selectAll('text.top-value')
      .data(data)
      .attr('class', 'top-value')
      .enter()
      .append('g')
      .attr('transform', d => `translate(${x0(d.group)},0)`)
      .selectAll('text')
      .data(d => d.values)
      .enter().append('text')
      .attr('x', d => x1(d.name) + x1.bandwidth() / 2)
      .attr('y', d => y(d.value) - 5) // Adjust the vertical position
      .text(d => d.value)
      .attr('text-anchor', 'middle')
      .style('fill', bardatastyle.color)
      .style('font-weight', bardatastyle.fontweight)
      .style('font-size', bardatastyle.fontsize)
      .style('visibility', bardatastyle.visible);

    //add axis
    svg.append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(0,${innerHeight})`)
      .call(xAxis)
      .style('font-weight', xaxislabelstyle.fontweight)
      .style('font-size', xaxislabelstyle.fontsize);

    svg.append('g')
      .attr('class', 'y-axis')
      .call(yAxis)
      .style('font-weight', yaxislabelstyle.fontweight)
      .style('font-size', yaxislabelstyle.fontsize);

    //title of the chart
    svg.append('text')
      .attr('class', 'title')
      .attr('x', width / 2)
      .attr('y', -margin.left)
      .attr('text-anchor', 'middle')
      .style('font-size', titlecolor.fontsize)
      .style('font-weight', titlecolor.fontweight)
      .style('fill', titlecolor.color)
      .text(title)



    //add x axis label
    svg.append('text')
      .attr("class", "x label")
      .attr("text-anchor", "middle")
      .attr("x", width / 3)
      .attr("y", height - 2)
      .attr("dy", xaxisstyle.dy)
      .attr("dx", width / 8 - margin.left)
      .style('fill', colorLabel)
      .style('font-weight', FontWeight ? "700" : "100")
      .style('font-size', fontSize)
      .text(xlabel)
      .on("click", () => {
        let label = prompt("Edit X Axis Label")
        if (label) {
          setxlabel(label)
        }
      });

    //add y axis label
    svg.append("text")
      .attr("class", "y label")
      .attr('transform', `translate(20)`)
      .attr("text-anchor", "middle")
      .attr("y", -margin.left - margin.left)
      .attr("dy", "3em")
      .attr("x", -height / 2)
      .attr("transform", "rotate(-90)")
      .style('fill', colorLabel)
      .style('font-weight', FontWeight ? "700" : "100")
      .style('font-size', fontSize)
      .text(ylabel)
      .on("click", () => {
        let label = prompt("Edit Y Axis Label")
        if (label) {
          setylabel(label)
        }
      });


    // Create tooltip
    const tooltip = d3
      .select(svgRef.current)
      .append('div')
      .style('position', 'absolute')
      .style('background', '#f4f4f4')
      .style('padding', '5px')
      .style('border', '1px solid #ccc')
      .style('border-radius', '5px')
      .style('color', "red")
      .style('opacity', 0);

    // Function to toggle legend visibility
    function toggleLegend(d) {
      const groupName = d.target.__data__;
      const selectedLegend = svg.selectAll('.legend').filter((legendName) => legendName === groupName);

      // Toggle legend
      selectedLegend.classed('disabled', !selectedLegend.classed('disabled'));

      // Toggle corresponding bars visibility
      const bars = svg.selectAll('rect').filter((d) => d.name === groupName);

      if (selectedLegend.classed('disabled')) {
        bars.style('display', 'none');
        SetHide('hidden')
      } else {
        SetHide('visible')
        bars.style('display', null); // Setting to null restores the default display property
      }
    }

    return () => {
      d3.select(svgRef.current).selectAll('*').remove();
    };

  }, [data, width, height, hide, showGridLines, ylabel, xlabel, color, colorLabel, FontWeight, fontSize]);

  const handleFontSizeChange = (event) => {
    SetFontSize(event.target.value);

  }

  return (
    <>
      <div className="controller no-print">
        {
          actionPush == "section" &&
          <SectionModal pushAction={addToEditor} onclose={() => setactionPush("")} />
        }
        <Button className='grid no-print' style={{ backgroundColor: colorLabel }} onClick={() => SethideColor(!hideColor)}>
          Color
        </Button>
        <Button className='grid no-print' onClick={() => setShowGridLines(!showGridLines)}>
          {showGridLines ? 'Hide Grid Lines' : 'Show Grid Lines'}
        </Button>
        <Button className='grid no-print' style={{ fontWeight: FontWeight ? "700" : "100" }} onClick={() => SetFontWeight(!FontWeight)}>
          B
        </Button>
        <select className='grid no-print' id="font-size" value={fontSize} onChange={handleFontSizeChange}>
          <option value="12">12px</option>
          <option value="14">14px</option>
          <option value="16">16px</option>
          <option value="18">18px</option>
          <option value="20">20px</option>
          <option value="24">24px</option>
          <option value="28">28px</option>
          <option value="32">32px</option>
        </select>
        <Button className='grid no-print' onClick={() => downloadChartAsImage(containerid)}>
                Download
            </Button>
            <Button className='grid no-print' onClick={() => setactionPush("section")}>
            Copy to Editor
            </Button>
      </div>
      {hideColor ?
        <div className='colorpicker no-print'>
          {/* <span>Bar</span>
            <HuePicker
                color={colorBar}
                onChange={(e)=>SetcolorBar(e.hex)}
            />  */}
          {/* <span>Dots</span>
            <span style={{color:"blue", padding:"0px 5px"}} onClick={() => Setcolor("#000000")}>Reset</span>
               <HuePicker
                color={color}
                onChange={(e)=>Setcolor(e.hex)}
            /> */}
          <span>Axis</span>
          <span style={{ color: "blue", padding: "0px 5px" }} onClick={() => SetcolorLabel("#000000")}>Reset</span>
          <HuePicker
            color={colorLabel}
            onChange={(e) => SetcolorLabel(e.hex)}
            presetColors={['#D0021B', '#F5A623', '#F8E71C', '#8B572A', '#7ED321', '#417505', '#BD10E0', '#9013FE', '#4A90E2', '#50E3C2', '#B8E986', '#000000', '#4A4A4A', '#9B9B9B', '#FFFFFF']}
          />
        </div>
        : ""}
      <div ref={svgRef} id={containerid}></div>

    </>
  );
};

// GroupedBarChart.prototype = {
//   data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
//   width: PropTypes.number,
//   heigth: PropTypes.number,
//   barstyle: PropTypes.object,
//   bardatastyle: PropTypes.object,
//   gridcolor: PropTypes.string,
//   gridstatus: PropTypes.string,
//   yaxistext: PropTypes.string,
//   yaxislabelstyle: PropTypes.object,
//   yaxisstyle: PropTypes.object,
//   xaxistext: PropTypes.string,
//   xaxislabelstyle: PropTypes.object,
//   xaxisstyle: PropTypes.object,
//   title: PropTypes.string,
//   titlecolor: PropTypes.object
// }

// GroupedBarChart.defaultProps = {
//   data: [
//     { group: 'Group A', values: [{ name: 'Category 1', value: 10 }, { name: 'Category 2', value: 20 }, { name: 'Category 3', value: 30 }] },
//     { group: 'Group B', values: [{ name: 'Category 1', value: 15 }, { name: 'Category 2', value: 25 }, { name: 'Category 3', value: 20 }] }],
//   width: "600",
//   height: "400",
//   barstyle: { color: "#000", hover: "#ccc" },
//   bardatastyle: { fontsize: "16px", fontweight: "500", color: "#000" },
//   gridcolor: "#ccc",
//   gridstatus: "hidden",
//   yaxistext: "Y Label",
//   yaxislabelstyle: { fontsize: "14px", fontweight: "400", color: "red" },
//   yaxisstyle: { fontsize: "14px", fontweight: "600", color: "#000" },
//   xaxistext: "X Label",
//   xaxislabelstyle: { fontsize: "14px", fontweight: "400", color: "red" },
//   xaxisstyle: { fontsize: "14px", fontweight: "600", color: "#000" },
//   title: "Bar Chart",
//   titlecolor: { fontsize: "24px", fontweight: "600", color: "#000" }
// }
export default GroupedBarChart;
