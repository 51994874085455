import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { authSelector } from '../../store/slices/auth';
import { expectedDateFormat } from '../../utils/Utils';
import { getPermissionData } from '../projectDetails/apiController';
import { use } from 'i18next';

export default function ProjectCardCollab(props) {
	const { currentUser } = useSelector(authSelector);
	const navigate = useNavigate();
	const [permissionData, setPermissionData] = useState(
		{}
	);
	const { data, type } = props;


	useEffect(() => {
		data.type = "invitee"
		getProjectData()
	}, [])

	const getProjectData = async () => {
		let temp: any = localStorage.getItem("coguide_user");
		let userid: any = JSON.parse(temp);
		const perData = await getPermissionData("collaborator", userid.id, data.id);
		let permArray = {};
		perData.data && perData.data.forEach((obj) => {
			if (Object.keys(obj)[0] == "Projects") {
				obj[Object.keys(obj)[0]].forEach((objItem) => {
					permArray[objItem.action] = objItem.permissions.filter(itemflt => itemflt.title == "allowed")[0]?.is_selected
				})
				//setPermission(obj[Object.keys(obj)[0]])
			}
		})
		console.log("permissionData", permArray)
		setPermissionData(permArray)
	}


	useEffect(() => {
	}, [permissionData])


	return (
		<div className='project-card'>
			<div className='top-row'>
				<Row className='m-0'>
					<Col className='p-0'>
						<div className='created-date'>{expectedDateFormat(data.createdon || data.UpdatedOn, 'DD MMM YYYY', 'YYYY-MM-DD')}</div>
					</Col>
					<Col className='p-0 row m-0 justify-content-end'>
						{
							permissionData && permissionData['data collections'] ?
								<div className='data-entry-button'>
									<div className='data-entry-text' onClick={() => props.onData()}>{type === 'projects' ? 'Data Entry' : 'Data Collection'}</div>
								</div>
								:
								""
						}

						{/* {currentUser?.UserType !== 'Internal User' && <i className="icon icon-delete" onClick={() => props.onDelete()}></i>}
						<i className="icon icon-edit" onClick={() => props.onEdit()}></i> */}
					</Col>
				</Row>
				<div className='topic-desc' onClick={() => props.onprojectDetail()}>{data.topicdesc}</div>
				<div className='topic-scroll' onClick={() => props.onprojectDetail()}>
					{data.topicbigdesc?.map((obj, index) => (
						<div key={index} className='topic-objective'>{index + 1}. {obj}</div>
					))}
				</div>
			</div>
			<Row className='m-0 footer-row'>
				<Col className='p-0 add-people-text'>
					{
						permissionData && permissionData['People'] ?
							<span onClick={() => navigate('/people', { state: data })}>
								People
							</span> : ""
					}

					{
						permissionData && permissionData['Planner'] ?
							<div onClick={() => navigate('/planner', { state: data })}>
								Planner
							</div> : ""
					}
					{
						permissionData && permissionData['Document'] ?
							<div onClick={() => props.onDocument()}>
								Document
							</div>
							: ""
					}

				</Col>
				{type === 'projects' ?
					<Col className='p-0 text-right'>
						<i className="icon icon-study" onClick={() => props.onSampleSize(data)}></i>
						{data.Shared === 'y' && <i className="icon icon-share" onClick={() => props.onMove(data)}></i>}
					</Col> :
					<Col className='p-0 text-right'>
						{
							permissionData && permissionData['study design'] ?
								<i className="icon icon-study" onClick={() => props.onSampleSize(data)}></i>
								: ""

						}
						<i className="icon icon-upload"></i>
					</Col>
				}
			</Row>
		</div>
	)
}
