import { FIELD_TYPES } from "../../widgets/fields";

export const FormElements = {
    availableIndex: {
        name: "availableIndex",
        placeholder: "",
        label: "",
        type: FIELD_TYPES.CHECK_BOX,
        values: [{label: 'Scopus', value: 'scopus'}, {label: 'Pubmed', value: 'pubmed'}, {label: 'PMC', value: 'pmc'}, {label: 'Medline', value: 'medline'}, {label: 'Embase', value: 'embase'}, {label: 'WOS', value: 'wos'}, {label: 'DOAJ', value: 'doaj'}],
        isMandatory: false,
        isListed: true,
        perRow: 'col-md-6'
    },
    impactMin: {
        name: "impactMin",
        placeholder: "Min",
        inputType: 'number',
        isMandatory: false
    },
    impactMax: {
        name: "impactMax",
        placeholder: "Max",
        inputType: 'number',
        isMandatory: false
    },
    cityMin: {
        name: "cityMin",
        placeholder: "Min",
        inputType: 'number',
        isMandatory: false
    },
    cityMax: {
        name: "cityMax",
        placeholder: "Max",
        inputType: 'number',
        isMandatory: false
    },
    searchField: {
        name: "searchField",
        placeholder: "Search",
        label: "",
        type: FIELD_TYPES.SEARCH,
        isMandatory: true
    }
}