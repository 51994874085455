import { useState } from "react";
import ModalComponent from "../common/Modal/modal";
import { QUESTION1LINK } from "../sampleSize/Videos";

export default function AnalysisVideo(props){
    const [isAnalysis,SetAnalysis] = useState(false);

     /**
     * video controls
     * @param e 
     */
     const getPlay = (e) => {
        let duration = e.target.duration;
        let currentTime = e.target.currentTime
        let isComplete = (duration * 90) / 100;
        if (currentTime > isComplete) {
            SetAnalysis(true)
        } else {
            SetAnalysis(true)
        }
    }
    return(
        <>
           <ModalComponent
               width="95%"
               isOpen={true}
               onClose={() => props.onClose()}
               header="Watch Video"
               centered={true}
               customClass='invite-modal video-modal'
               body={
                <>
                    <video controls width={"100%"} height={"500px"} 
                        onPause={(e) => getPlay(e)}
                        onEnded={(e) => getPlay(e)}>
                        <source src="https://f000.backblazeb2.com/file/cGbgmedia/Bot6_DiagnosticAccuracystudya5m1zcv5drxckn42gb50.mp4#t=1" type="video/mp4"/>
                    </video>
                </>
               }
               footer={
                <>
                  {isAnalysis ?
                  <div className={window.innerWidth > 750 ? "footer-data":"footer-data-mobile"}>
                    Hope you understand about outcome variables? 
                    <span style={{color:"blue",cursor:"pointer",padding:"0px 10px"}} 
                        onClick={() =>{props.getVideoStatus(true); props.onClose()}}  >
                            Proceed to Analysis
                    </span>
                  </div>
                  :""}
                </>
               }
               />
        </>
    )
}