import React, { useEffect, useState } from 'react';
import { Col, Row } from "reactstrap";
import { CustomButton, showToaster, toasterTypes } from '../../widgets';
import ModalComponent from '../common/Modal/modal';
import { FieldItem } from "../../widgets/fields";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import { AddressFormElements } from './FormElements';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../store/slices/auth';
import { getCountries, getStates, updateAddress } from './apiController';

export default function AddressDetails(props) {
	const initialValues = React.useMemo(() => ({ addressType: null, pinCode: '', country: null, state: null, city: '', address: '', same: false }), []);
	const [values, fields, handleChange, { validateValues, updateValue }] = useFieldItem(AddressFormElements, initialValues, {onValueChange});
	const [countryList, setcountryList] = useState([]);
	const [stateList, setstateList] = useState([]);
	const { data, selectedIndex } = props;

	const dispatch = useDispatch();

	useEffect(() => {
		console.log(data,selectedIndex)

		if(selectedIndex !== null && selectedIndex >= 0  && data) {

			data && updateValue({
				addressType: "present",
				pinCode: data[selectedIndex]?.pincode,
				country: {label: data[selectedIndex]?.country, value: data[selectedIndex].country_code},
				state: {label: data[selectedIndex].state, value: data[selectedIndex].state_code},
				city: data[selectedIndex].city,
				address: data[selectedIndex].address_text
			});
			fetchCountries(data[selectedIndex].country_code);
		} else {
			fetchCountries();
		}
	}, []);
	
	const fetchCountries = async(country='') => {
		try {
			dispatch(setLoading(true));
			let response = await getCountries();
			setcountryList(response.data.map(res => ({ label: res.Country, value: res.CountryCode })));
			if(country) {
				fetchStates(country);
			}
			dispatch(setLoading(false));
		} catch (e: any) {
			dispatch(setLoading(false));
		}
	}

	function onValueChange (name, value, values, fieldValues=null) {
		let resetValue = {};
        if(name === 'country') {
			fetchStates(value.value);
            resetValue = { ...resetValue, state: null }
		} else if(name === 'addressType') {
			if(selectedIndex !== null && selectedIndex >= 0  && data) {
				let selectedData = data.filter(obj => obj.address_type === values.addressType.value)[0];
				updateValue({
					pinCode: selectedData.pincode,
					country: {label: selectedData.country, value: selectedData.country_code},
					state: {label: selectedData.state, value: selectedData.state_code},
					city: selectedData.city,
					address: selectedData.address_text
				})
			} else {
				resetValue = { ...resetValue, 'same': null };
			}
		}
		return [{
			...values,
			...resetValue,
			[name]: value,
		}]
    }

	const fetchStates = async(value) => {
		try {
			dispatch(setLoading(true));
			let response = await getStates(value);
			setstateList(response.data.map(res => ({ label: res.State, value: res.StateCode })));
			dispatch(setLoading(false));
		} catch (e: any) {
			dispatch(setLoading(false));
		}
	}
	
	const checkSubmit = async() => {
		if (validateValues(["pinCode", "country", "state", "city", "address"])) {
			showToaster(toasterTypes.ERROR, "Please enter the proper values in the fields highlighted in red");
		} else {
			try {
				dispatch(setLoading(true));
				let reqObj = data;
				let country: any = countryList.filter((obj: any) => obj.value === values.country.value)[0];
				let state: any = stateList.filter((obj: any) => obj.value === values.state.value)[0];
				let addressData: any = [{
					"userNumber": props.userNumber,
					"address_type": "present",//values.addressType.value
					"country": country.label,
					"country_code": country.value,
					"state": state.label,
					"state_code": state.value,
					"city": values.city,
					// "longitude": 77.38,
					// "latitude": 12.58,
					"address_text": values.address,
					"pincode": values.pinCode
				}]
				console.log(addressData)

				//reqObj.push(addressData);
				//if(values.addressType?.value === 'present') {
					// if(reqObj) {
					// 	let index = reqObj.findIndex(elem => elem.address_type === addressData.address_type);
					// 	if(index < 0) {
					// 		reqObj.push(addressData);
					// 	} else {
					// 		reqObj[index] = addressData;
					// 	}
					// } else {
					// 	reqObj = [addressData];
					// }
				// } else {
				// 	let permanentIndex = reqObj.findIndex(elem => elem.address_type === 'permanent');
				// 	if(permanentIndex < 0) {
				// 		reqObj.push(addressData);
				// 	} else {
				// 		reqObj[permanentIndex] = addressData;
				// 	}
					
				// }
				let response = await updateAddress(addressData);
				showToaster(toasterTypes.SUCCESS, response.message);
				props.onClose('callAPI');
				dispatch(setLoading(false));
			} catch (e: any) {
				dispatch(setLoading(false));
			}
		}
	}

	return (
		<ModalComponent
			width={window.innerWidth > 700 ? "800px" : "350px"}
			isOpen={true}
			onClose={() => props.onClose()}
			header="Address"
			centered={true}
			customClass='profile-modal'
			body={
				<div>
					<Row>
						{/* <Col md={4}>
							<FieldItem
								{...AddressFormElements.addressType}
								value={values.addressType}
								isDisabled={selectedIndex !== null && selectedIndex >= 0  && data ? true : false}
								onChange={(...e) => handleChange(AddressFormElements.addressType.name, ...e)}
								touched={fields.addressType && fields.addressType.hasError}
								error={fields.addressType && fields.addressType.errorMsg}
							/>
						</Col> */}
						<Col md={4}>
							<FieldItem
								{...AddressFormElements.pinCode}
								value={values.pinCode}
								onChange={(...e) => handleChange(AddressFormElements.pinCode.name, ...e)}
								touched={fields.pinCode && fields.pinCode.hasError}
								error={fields.pinCode && fields.pinCode.errorMsg}
							/>
						</Col>
						<Col md={4}>
							<FieldItem
								{...AddressFormElements.country}
								value={values.country}
								values={countryList}
								onChange={(...e) => handleChange(AddressFormElements.country.name, ...e)}
								touched={fields.country && fields.country.hasError}
								error={fields.country && fields.country.errorMsg}
							/>
						</Col>
						<Col md={4}>
							<FieldItem
								{...AddressFormElements.state}
								value={values.state}
								values={stateList}
								onChange={(...e) => handleChange(AddressFormElements.state.name, ...e)}
								touched={fields.state && fields.state.hasError}
								error={fields.state && fields.state.errorMsg}
							/>
						</Col>
					</Row>
					<Row>
						
						<Col md={4}>
							<FieldItem
								{...AddressFormElements.city}
								value={values.city}
								onChange={(...e) => handleChange(AddressFormElements.city.name, ...e)}
								touched={fields.city && fields.city.hasError}
								error={fields.city && fields.city.errorMsg}
							/>
						</Col>
					
						<Col md={8}>
							<FieldItem
								{...AddressFormElements.address}
								value={values.address}
								onChange={(...e) => handleChange(AddressFormElements.address.name, ...e)}
								touched={fields.address && fields.address.hasError}
								error={fields.address && fields.address.errorMsg}
							/>
						</Col>
					</Row>
				</div>
			}
			footer={
				<Row className='w-100'>
					{/* {values.addressType?.value === 'present' &&
						<Col>
							<FieldItem
								{...AddressFormElements.same}
								value={values.same}
								onChange={(...e) => handleChange(AddressFormElements.same.name, ...e)}
								touched={fields.same && fields.same.hasError}
								error={fields.same && fields.same.errorMsg}
							/>
						</Col>
					} */}
					<Col className='d-flex m-0 justify-content-end'>
						<CustomButton type="primary" isDisabled={false} text="Save" onClick={() => checkSubmit()}></CustomButton>
					</Col>
				</Row>
			}
		/>
	)
}