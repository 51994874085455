import { useEffect, useState } from "react";
import { CustomButton } from "../../widgets";
import { FIELD_TYPES, FieldItem } from "../../widgets/fields";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import ModalComponent from "../common/Modal/modal";
import { getDocumentList } from "../documents/apiController";

const DocumentsModal = (props) =>{
     const [values, fields, handleChange, { validateValues, updateValue }] = useFieldItem({}, {}, { onValueChange });
     const [docs,setDocs] = useState([{label:"doc",value:"doc"}])

      /**
    * Upload the selected file on success update media URL and new_doc as true
    * @param name 
    * @param value 
    * @param values 
    * @param fieldValues 
    * @returns 
    */
    
     /**
      * 
      * @param name 
      * @param value 
      * @param values 
      * @param fieldValues 
      * @returns 
      */
     function onValueChange(name, value, values, fieldValues = null) {
          let resetValue = {};
          console.log(values)
          //console.log(formTemplates)

          return [{
               ...values,
               ...resetValue,
               [name]: value,
          }]
     }

     useEffect(()=>{
          getDocumetnsList();
     },[])

     /**
      * get list of documents
      */
     const getDocumetnsList = () =>{
          try {
               let docList = getDocumentList(props.projectData.projectdata.id);
               docList.then(data => {
                    setDocs(data.map(({
                         id: value,
                         name: label
                    }) => ({
                         value,
                         label
                    })));
               })
          } catch (error) {
               
          }
     }

    
     return(
          <>
                <ModalComponent
                        width={"30%"}
                        onClose={() => props.onClose()}
                        isOpen={true}
                        header={<b>Select Document</b>}
                        centered={true}
                        customClass="document-modal"
                        body={
                            <>
                                <FieldItem
                                    name={"documentlink"}
                                    value={values.documentlink}
                                    values={docs}
                                    type={FIELD_TYPES.DROP_DOWN}
                                    onChange={(...e) => {
                                        handleChange("documentlink", ...e);
                                    }}
                                    touched={fields.documentlink && fields.documentlink.hasError}
                                    error={fields.documentlink && fields.documentlink.errorMsg}
                                />
                            </>
                        }
                        footer={
                            <CustomButton type={"primary"} text="Select" onClick={() => props.getSelectedDoc(values)}/>
                        }
                    />
          </>
     )
}

export default DocumentsModal;