import AjaxUtil from '../../axios/axios';

export const getProfileDetails = async(userid) => {
    console.log(userid.userid);
    //?user_id=${userid.userid}
    
    try {
        if(userid?.userid){
            return await AjaxUtil.sendRequest(`profile?user_id=${userid?.userid}`, {}, false, { method: 'get', showError: true, isProfile: true} )
        } else{
            return await AjaxUtil.sendRequest(`profile`, {}, false, { method: 'get', showError: true, isProfile: true} );
        }
     
    } catch (error) {
        throw error;
    }
}

export const uploadPhoto = async(data) => {
    try {
        return await AjaxUtil.sendRequest(`upload/img`, data, false, { method: 'post', showError: true} );
    } catch (error) {
        throw error;
    }
}

export const deletePhoto = async(data) => {
    try {
        return await AjaxUtil.sendRequest(`deletemedia?media_url=${data}`, data, false, { method: 'delete', showError: true} );
    } catch (error) {
        throw error;
    }
}

export const updateProfile = async(data) => {
    try {
        return await AjaxUtil.sendRequest(`info`, data, false, { method: 'put', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const updateContact = async(data) => {
    try {
        return await AjaxUtil.sendRequest(`contact`, data, false, { method: 'put', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const updateAddress = async(data) => {
    try {
        return await AjaxUtil.sendRequest(`address`, data, false, { method: 'put', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const getCountries = async(data) => {
    try {
        return await AjaxUtil.sendRequest(`country`, data, false, { method: 'get', showError: true} );
    } catch (error) {
        throw error;
    }
}

export const getStates = async(data) => {
    try {
        return await AjaxUtil.sendRequest(`states/${data}`, data, false, { method: 'get', showError: true} );
    } catch (error) {
        throw error;
    }
}

export const getColleges = async(data) => {
    try {
        return await AjaxUtil.sendRequest(`colleges/${data}`, data, false, { method: 'get', showError: true} );
    } catch (error) {
        throw error;
    }
}

export const getSkills = async() => {
    try {
        return await AjaxUtil.sendRequest(`skills`, {}, false, { method: 'get', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const addSkillSet = async(data) => {
    try {
        return await AjaxUtil.sendRequest(`skill`, data, false, { method: 'post', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const updateSkillSet = async(data, id) => {
    try {
        return await AjaxUtil.sendRequest(`skill/${id}`, data, false, { method: 'put', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const deleteSkillSet = async(id) => {
    try {
        return await AjaxUtil.sendRequest(`skill/${id}`, {}, false, { method: 'delete', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const addWorkExperience = async(data) => {
    try {
        return await AjaxUtil.sendRequest(`work`, data, false, { method: 'post', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const updateWorkExperience = async(data, id) => {
    try {
        return await AjaxUtil.sendRequest(`work/${id}`, data, false, { method: 'put', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const deleteWorkExperience = async(id) => {
    try {
        return await AjaxUtil.sendRequest(`work/${id}`, {}, false, { method: 'delete', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const addEducation = async(data) => {
    try {
        return await AjaxUtil.sendRequest(`education`, data, false, { method: 'post', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const updateEducation = async(data, id) => {
    try {
        return await AjaxUtil.sendRequest(`education/${id}`, data, false, { method: 'put', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const deleteEducation = async(id) => {
    try {
        return await AjaxUtil.sendRequest(`education/${id}`, {}, false, { method: 'delete', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const addAward = async(data) => {
    try {
        return await AjaxUtil.sendRequest(`award`, data, false, { method: 'post', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const updateAward = async(data, id) => {
    try {
        return await AjaxUtil.sendRequest(`award/${id}`, data, false, { method: 'put', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const deleteAward = async(id) => {
    try {
        return await AjaxUtil.sendRequest(`award/${id}`, {}, false, { method: 'delete', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const addResearchProfile = async(data) => {
    try {
        return await AjaxUtil.sendRequest(`research/extprofile`, data, false, { method: 'post', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const updateResearchProfile = async(data, id) => {
    try {
        return await AjaxUtil.sendRequest(`research/extprofile/${id}`, data, false, { method: 'put', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const deleteResearchProfile = async(id) => {
    try {
        return await AjaxUtil.sendRequest(`research/extprofile/${id}`, {}, false, { method: 'delete', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

/**
 * insert research and publication data api
 */
 export const addResearchExperience = async(data) => {
    try {
        return await AjaxUtil.sendRequest(`/research/experience`,data, false, { method: 'post', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

/**
 * delete research experince
 */
export const deleteResearchExperience = async(id) => {
    try {
        return await AjaxUtil.sendRequest(`/research/experience/${id}`, {}, false, { method: 'delete', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

/**
 * Add research Work
 */
 export const addResearchWork = async(data) => {
    try {
        return await AjaxUtil.sendRequest(`/research/project`, data, false, { method: 'post', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

/**
 * Update Research Data
 */
export const updateResearchWork = async(data,id) =>{
    try {
        return await AjaxUtil.sendRequest(`/research/project/${id}`, data, false, { method: 'put', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

/**
 * delete research work
 */
 export const deleteResearchWork = async(id) => {
    try {
        return await AjaxUtil.sendRequest(`/research/project/${id}`, {}, false, { method: 'delete', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

/**
 * get course list
 */
export const getCourse = async() => {
    try {
        return await AjaxUtil.sendRequest(`course`, "", false, { method: 'get', showError: true} );
    } catch (error) {
        throw error;
    }
}

/**
 * get course list
 */
export const getFieldStudy = async(code) => {
    try {
        return await AjaxUtil.sendRequest(`subcourse/${code}/`,"", false, { method: 'get', showError: true});
    } catch (error) {
        throw error;
    }
}