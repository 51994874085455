import React, { useEffect, useMemo, useState } from 'react'
import { Button, Col, Row } from 'reactstrap';
import { CustomButton, showToaster, toasterTypes } from '../../widgets'
import { FieldItem } from '../../widgets/fields';
import useFieldItem from '../../widgets/fields/UseFieldItem';
import ModalComponent from '../common/Modal/modal'
import { CreateFormElements } from './FormElements';
import { List, arrayMove } from 'react-movable';
import { cloneDeep } from 'lodash';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../store/slices/auth';
import { arrangeCategories } from './apiController';
import { Input } from 'antd';


export default function Arrange(props) {

    const [categories, setcategories] = useState([]);
    const [emptyVariables, setemptyVariables] = useState<any | []>([]);
    const [selectedCategory, setselectedCategory] = useState<any | null>(null);
    const [isEditCat, SetEditCat] = useState();
    const [isEditText, SetEditText] = useState(false);
    const initialValues = useMemo(() => ({
		searchField: '',
        categoryName: '',
        variables: []
    }), []);
	const [values, fields, handleChange, { validateValues, reset, updateValue, resetValue }] = useFieldItem(CreateFormElements, initialValues, { onValueChange });
	const dispatch = useDispatch();

    useEffect(() => {
        let temp: any = [];
        let tempEmpty: any = [];
        Object.keys(props.variables).forEach((obj, index) => {
            if(obj !== 'empty') {
                temp.push({category: obj, data: props.variables[obj], catIndex: index});
            } else {
                tempEmpty = props.variables[obj];
            }
        });
        setcategories(temp);
        setemptyVariables(tempEmpty);
    }, []);


    useEffect(()=>{

    },[categories,isEditText,selectedCategory])
    
    function onValueChange (name, value, values) {
		return [{
			...values,
			[name]: value
		}]
    }

    function changeVariables(type, field_name, values, index) {
        if(index >= 0) {
            let tempEmpty: any = cloneDeep(emptyVariables);
            let temp: any = cloneDeep(categories);
            temp[selectedCategory.catIndex].data.push(tempEmpty[index]);
            tempEmpty.splice(index, 1);
            setemptyVariables(tempEmpty);
            setcategories(temp);
            setselectedCategory(temp[selectedCategory.catIndex]);
        }
        handleChange(type, field_name, values);
    }

    function changeCategoryData(categoryData) {
        values.categoryNameEdit = categoryData.category;
        handleChange(CreateFormElements.variables.name, categoryData.data.map(obj => obj.field_name), values);
        setselectedCategory(categoryData);
    }

    /**
     * Edit category name
     * @param catIndex 
     */
    function onEditCreate(catIndex) {

        let temp: any = cloneDeep(categories);
        //let tempIndex = temp.findIndex(elem => elem.category === values.categoryName);
        temp[catIndex].category = values.categoryNameEdit;       
        setcategories(temp);
        setselectedCategory(temp[catIndex]);
        SetEditText(false);
    }

    function onCreate() {
        let temp: any = cloneDeep(categories);
        let tempIndex = temp.findIndex(elem => elem.category === values.categoryName);
        if(tempIndex >= 0) {
			showToaster(toasterTypes.ERROR, 'Category is already exists');
            return;
        }
        temp.push({category: values.categoryName, data: [], catIndex: categories.length});
        setselectedCategory({category: values.categoryName, data: [], catIndex: categories.length});
        setcategories(temp);
        setTimeout(() => {
            resetValue({categoryName: ''});
        });
        handleChange(CreateFormElements.variables.name, [], values);
    }

    function reorder(categories, oldIndex, newIndex) {
        let temp: any = cloneDeep(categories);
        let tempOldIndex = temp[oldIndex].catIndex;
        temp[oldIndex].catIndex = temp[newIndex].catIndex;
        temp[newIndex].catIndex = tempOldIndex;
        setcategories(arrayMove(temp, oldIndex, newIndex));
        setselectedCategory(null);
    }

    function onRemove(key) {
        let temp  = cloneDeep(values);
        temp.variables.splice(key, 1);
        let tempIndex = selectedCategory.data.findIndex((elem: any) => elem.field_name === values.variables[key]);
        if(tempIndex >= 0) {
            let tempCategories: any = cloneDeep(categories);
            let tempEmpty: any = cloneDeep(emptyVariables);
            tempEmpty.push(selectedCategory.data[tempIndex]);
            setemptyVariables(tempEmpty);
            tempCategories[selectedCategory.catIndex].data.splice(tempIndex, 1);
            setcategories(tempCategories);
            setselectedCategory(tempCategories[selectedCategory.catIndex]);
        }
        handleChange(CreateFormElements.variables.name, temp.variables, values);
    }

    /**
     * submit datat to server
     */
    const onSave = async() => {
        try {
            dispatch(setLoading(true));
            let catData: any = [];
            categories.forEach((obj: any) => {
                obj.data.forEach(subObj => {
                    catData.push({id: subObj.id, field_category: obj.category, parent_id: subObj.parent_id});
                });
            });
            emptyVariables.forEach(obj => {
                catData.push({id: obj.id, field_category: '', parent_id: obj.parent_id});
            })
            let response = await arrangeCategories(props.formId, catData);
            if(response.success) {
                showToaster(toasterTypes.SUCCESS, response.message);
                props.onClose('save');
            }
			dispatch(setLoading(false));
        } catch (e: any) {
            dispatch(setLoading(false));
        }
    }

    /**
     * 
     * @param catIndex Edit icon click 
     */
    const editCategory = (catIndex) =>{
        console.log(catIndex)
        SetEditCat(catIndex);
        SetEditText(true);
    }

    return (
        <ModalComponent
            width="1000px"
            isOpen={true}
            onClose={() => props.onClose()}
            header="Arrange"
            centered={true}
            customClass='profile-modal arrange-modal'
            body={
                <div>
                    <Row className='m-0'>
                        <Col className='p-0 col-border' md={5}>
                            <Row className='m-0 pl-3 pr-3'>
                                <Col className='p-0' md={4}>
                                    <div className='categories-text'>Categories</div>
                                </Col>
                                <Col className='p-0' md={8}>
                                    <FieldItem
                                        {...CreateFormElements.searchField}
                                        value={values.searchField}
                                        onChange={(...e) => handleChange(CreateFormElements.searchField.name, ...e)}
                                    />
                                </Col>
                            </Row>
                            <Row className='m-0 category-bg pl-3 pr-3'>
                                <Col className='p-0' md={8}>
                                    <FieldItem
                                        {...CreateFormElements.categoryName}
                                        value={values.categoryName}
                                        onChange={(...e) => handleChange(CreateFormElements.categoryName.name, ...e)}
                                    />
                                </Col>
                                <Col className='p-0 add-align' md={4}>
                                    <CustomButton type="primary" className='add-button' isDisabled={values.categoryName ? false : true} text="Add" onClick={() => onCreate()}></CustomButton>
                                </Col>
                            </Row>
                            <div className='categories-scroll'>
                                <List
                                    values={categories}
                                    onChange={({ oldIndex, newIndex }) =>
                                        reorder(categories, oldIndex, newIndex)
                                    }
                                    renderList={({ children, props }) => <ul className='p-0' {...props}>{children}</ul>}
                                    renderItem={({value: { category, data, catIndex }, props }) => (
                                        <li {...props}>
                                            <Row {...props} className='m-0 category-card' onClick={() => changeCategoryData({category, data, catIndex: catIndex})}>
                                               {catIndex == isEditCat && isEditText  ?
                                               <>
                                                <Col className='p-0' md={8}>
                                                    <FieldItem                                                         
                                                        {...CreateFormElements.categoryNameEdit}
                                                        value={catIndex == isEditCat ? values.categoryNameEdit : category}
                                                        name={catIndex}
                                                        isDisabled={catIndex == isEditCat ? false : true}
                                                        onChange={(...e) => handleChange(CreateFormElements.categoryNameEdit.name, ...e)}
                                                        />

                                                    </Col>
                                                    <Col className='p-0 edit-align' md={4}>
                                                        <CustomButton type="primary" className='edit-button' isDisabled={values.categoryNameEdit ? false : true} text="Update" onClick={(e) => onEditCreate(catIndex)}></CustomButton>
                                                    </Col>
                                                    </>
                                                    : category}
                                                   
                                                        <Col className='p-0 text-right'>
                                                        {catIndex == isEditCat && isEditText  ? "" :
                                                            <>
                                                                <i className='icon icon-edit' onClick = {(e) => editCategory(catIndex)}/>                                                            
                                                            </>
                                                        }
                                                                <i className={catIndex == isEditCat && isEditText  ? "" : 'icon icon-drag'} data-movable-handle tabIndex={-1} />
                                                        </Col>
                                                 
                                            </Row>
                                        </li>
                                    )}
                                />
                            </div>
                        </Col>
                        {selectedCategory ?
                            <Col className='p-0'>

                    
                                <div className='category-text'>{selectedCategory.category}</div>
                                <FieldItem
                                    {...CreateFormElements.variables}
                                    value={values.variables}
                                    onChange={(...e) => handleChange(CreateFormElements.variables.name, ...e)}
                                    isRemove={true}
                                    onRemove={(key) => onRemove(key)}
                                />
                                <div className='row m-0 no-category-variables'>
                                    {emptyVariables.map((obj, index) => (
                                    <div key={index} className='p-0 category-border' onClick={() => changeVariables('variables', values.variables.concat(obj.field_name), values, index)}>
                                        <div className='variable-text'>{obj.field_name} +</div>
                                    </div>
                                    ))}
                                </div>
                            </Col> :
                            <Col className='p-0 row m-0 justify-content-center align-items-center'>
                                <div className='add-variables-text'>Select category to add variables</div>
                            </Col>
                        }
                    </Row>
                </div>
            }
            footer={
                <div>
                    <CustomButton type="primary" text="Save" onClick={() => onSave()}></CustomButton>
                </div>
            }
        />
    )
}
