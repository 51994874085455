import { cloneDeep } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { setLoading } from '../../store/slices/auth';
import { CustomButton, showToaster, toasterTypes } from '../../widgets';
import { FieldItem } from '../../widgets/fields';
import useFieldItem from '../../widgets/fields/UseFieldItem';
import ModalComponent from '../common/Modal/modal';
import { getProjectTeamList } from '../people/apiController';
import { createForm, updateForm, getRoles, getUsers, getFormTemplates, addGroups, editGroup } from './apiController';
import { CreateFormElements, CreateGroupElements } from './FormElements';
import { DeleteFilled } from '@ant-design/icons';

export default function CreateGroup(props) {
	const { projectData, onFormCreation, groupData } = props;
	const dispatch = useDispatch();
	const initialValues = useMemo(() => (
		props.groupData ?
			props.groupData :
			{ name: '', description: '' }
	), [props.previousState]);
	const [values, fields, handleChange, { validateValues, updateValue }] = useFieldItem(CreateGroupElements, initialValues, { onValueChange });
	const [permissions, setpermissions] = useState([{ id: 1, role: null, users: [], user: '', accessType: [] }]);
	const [roles, setroles] = useState<any | []>([]);
	const [formTemplates, setFormTemplates] = useState<any>([]);

	useEffect(() => {
		console.log(groupData)
	}, []);


	/**
	 * 
	 * @param name 
	 * @param value 
	 * @param values 
	 * @param fieldValues 
	 * @returns 
	 */
	function onValueChange(name, value, values, fieldValues = null) {
		let resetValue = {};
		console.log(values)
		//console.log(formTemplates)

		return [{
			...values,
			...resetValue,
			[name]: value,
		}]
	}





	/**
	 * on save 
	 * submit details to create form
	 */
	const onSave = async () => {
		console.log(props)
		if (validateValues(["name", "description"])) {
			showToaster(toasterTypes.ERROR, "Please enter the proper values in the fields highlighted in red");
		} else {
			try {
				dispatch(setLoading(true));
				let Obj = {
					"name": values.name,
					"description": values.description,
					"topicid": projectData.TopicID,
					"group_type": values.name + " group type"
				}

				if (!groupData) {
					let response = await addGroups(Obj);
					if (response) {
						dispatch(setLoading(false));
						onFormCreation();
						showToaster(toasterTypes.SUCCESS, "Group created successfully");
						props.onClose();

					}
				} else {
					Obj['id']= groupData.id;
					let response = await editGroup(groupData.id, Obj);
					if (response) {
						dispatch(setLoading(false));
						onFormCreation();
						showToaster(toasterTypes.SUCCESS, "Group updated successfully");
						props.onClose();

					}
				}



			} catch (e: any) {
				dispatch(setLoading(false));
				console.log(e)
			}
		}
	}



	return (
		<ModalComponent
			width={"50%"}
			isOpen={true}
			onClose={() => props.onClose()}
			header={groupData ? "Edit Group" : "Create Group"}
			centered={true}
			customClass='profile-modal form-modal'
			body={
				<div>
					<Row>
						<Col md={8}>
							<FieldItem
								{...CreateGroupElements.name}
								value={values.name}
								onChange={(...e) => handleChange(CreateGroupElements.name.name, ...e)}
								touched={fields.name && fields.name.hasError}
								error={fields.name && fields.name.errorMsg}
							/>
						</Col>
						<Col md={8}>
							<FieldItem
								{...CreateGroupElements.description}
								value={values.description}
								onChange={(...e) => handleChange(CreateGroupElements.description.name, ...e)}
								touched={fields.description && fields.description.hasError}
								error={fields.description && fields.description.errorMsg}
							/>
						</Col>
					</Row>

				</div>
			}
			footer={
				<div>
					<CustomButton type="primary" isDisabled={false} text="Save" onClick={() => onSave()}></CustomButton>
				</div>
			}
		/>
	)
}
