import { CopyOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import ModalComponent from "../common/Modal/modal";

const FormDataCardMobile = (props: any) => {
    const { data, permissionParams, addData, onActions, projectData, addVariable } = props;

    const [moreOption, setmoreOption] = useState(false);
    useEffect(() => {
        console.log(data, projectData)
    }, []);

    const onClose = () => {
        setmoreOption(false)
    }
    return (
        <>
            <div className="form-record-container">
                <div className="form-head">
                    <div className="form-name">
                        <span id="label">Form Name : </span>
                        <span id="label-name">{data.form_name}</span>
                    </div>
                    <div className={moreOption ? "more-options-open" : "more-options"} >
                        {
                            moreOption ?
                                <div className="more-container" onClick={() => setmoreOption(false)}>
                                    <div className="form-actions">
                                        <div className="view" onClick={() => onActions(1, data)}>
                                            {
                                                permissionParams.view == "allowed" || Object.keys(permissionParams).length == 0 ?
                                                    <i
                                                        className="icon icon-eye"

                                                    ></i>
                                                    : ""
                                            }
                                            <span id="label-tag">View</span>

                                        </div>

                                        <div className="edit" onClick={() => onActions(2, data)}>
                                            {
                                                permissionParams.edit == "allowed" || Object.keys(permissionParams).length == 0 ?
                                                    !data?.permission || data?.permission == "write" ?
                                                        !data.locked ?
                                                            <i
                                                                className="icon icon-edit"
                                                            ></i>
                                                            : "-"
                                                        : ""
                                                    : ""
                                            }
                                            <span id="label-tag">Edit</span>
                                        </div>
                                        <div className="delete" onClick={() => onActions(3, data)}>
                                            {
                                                permissionParams.delete == "allowed" || Object.keys(permissionParams).length == 0 ?
                                                    !data?.permission || data?.permission == "write" ?
                                                        !data.locked ?
                                                            <i
                                                                className="icon icon-delete"
                                                            ></i>
                                                            : "-"
                                                        : ""
                                                    :
                                                    ""
                                            }
                                            <span id="label-tag">Delete</span>
                                        </div>
                                    </div>
                                </div> : ""
                        }

                        <i className={moreOption ? "icon icon-close" : "icon icon-more"} onClick={() => setmoreOption(!moreOption)}></i>

                    </div>
                </div>

                <div className="form-records">
                    <span id="label-tag">Records</span>
                    <span id="label-tag-count">{data.total_responses}</span>
                    {
                        permissionParams.adddata == "allowed" || Object.keys(permissionParams).length == 0 ?
                            !data.survey_form ?
                                <span id="label-btn-add" onClick={() => addData(data)}>Add</span>
                                : <span id="label-btn-add">-</span>
                            : ""
                    }
                </div>
                <div className="form-variables">
                    <span id="label-tag">Variables</span>
                    <span id="label-tag-name"> </span>
                    {
                        permissionParams.update == "allowed" || Object.keys(permissionParams).length == 0 ?
                            !data?.permission || data?.permission == "write" ?
                                !data.locked ?
                                    <span id="label-btn-update" onClick={() => addVariable(data)}>Update</span>
                                    : "-"
                                : ""
                            : ""
                    }
                </div>
                <div className="form-info">
                    <div className="survey">
                        <span id="label-tag">Survey</span>
                        {
                            permissionParams.surveylink == "allowed" || Object.keys(permissionParams).length == 0 ? data.survey_form ? (
                                <i
                                    className="icon icon-copy"
                                    onClick={() => onActions(5, data)}
                                ></i>
                            ) : "-" : (
                                <>-</>)
                        }

                    </div>
                    <div className="islocked">
                        <span id="label-tag">IsLocked</span>
                        {
                            projectData?.strict_mode == true ?
                                data.locked ?
                                    <i className="icon icon-lock" onClick={() => onActions("unlock", data)}></i>
                                    :
                                    <i className="icon icon-lock_open" onClick={() => onActions("lock", data)}></i>
                                : "-"
                        }

                    </div>
                    <div className="clone">
                        <span id="label-tag">Clone</span>
                        <div className="clone-icon">
                            <CopyOutlined onClick={() => onActions("duplicate", data)} />
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default FormDataCardMobile;