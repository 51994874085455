import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from "reactstrap";
import { setLoading } from '../../store/slices/auth';
import { CustomButton, showToaster, toasterTypes } from '../../widgets';
import { FieldItem } from "../../widgets/fields";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import ModalComponent from '../common/Modal/modal';
import { addSkillSet, updateSkillSet } from './apiController';
import { SkillSetFormElements } from './FormElements';
import { uploadSelectedFile } from '../submissions/apiController';
import dayjs from 'dayjs';
import moment from 'moment';


export default function SkillSet(props) {
	const initialValues = React.useMemo(() => ({ skillName: '', rating: '', certificationName: '', score: '', issuingOrganisation: '', certificateUrl: '', certificateId: '', issuedDate: null, validUpto: null, certificateUpload: "" }), []);
	const [values, fields, handleChange, { validateValues, reset, updateValue }] = useFieldItem(SkillSetFormElements, initialValues, { onValueChange });
	const [certificates, setcertificates] = useState([]);
	const [certificateIndex, setcertificateIndex] = useState<any | null>(null);
	const [popupType, setpopupType] = useState('');
	const dispatch = useDispatch();
	const { selectedIndex, data } = props;
	const [loader, Setloader] = useState(false);
	let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

	useEffect(() => {
		if (selectedIndex !== null && selectedIndex >= 0 && data && data.skills && data.skills.length > 0) {
			let skillsData = data.skills[selectedIndex];
			updateValue({ skillName: skillsData.skill_name, rating: skillsData.self_rating });
			setcertificates(skillsData?.certifications || []);
		}
	}, [data]);

	const onCertificate = () => {
		if (validateValues(["skillName"])) {
			showToaster(toasterTypes.ERROR, "Please enter the proper values in the fields highlighted in red");
		} else {
			setpopupType('certificate');
		}
	}

	const editCertificate = (obj, index) => {
		setpopupType('certificate');
		setcertificateIndex(index);
		// To be deleted
		// obj = {
		// 	certification_score: 4.5,
		// 	certification_name: 'Certificate',
		// 	issued_date_month: 'May',
		// 	issued_date_year: '2022',
		// 	valid_upto_month: 'Apr',
		// 	valid_upto_year: '2024',
		// 	organisation: 'Organisation',
		// 	certification_url: 'www.google.com',
		// 	certificate_id: '123456'
		// }

		/**
		 * code to handle the legacy data
		 */
		var issuedate: any = null;
		var validdate: any = null;
		if (obj.issued_month) {
			var monthIndex = months.indexOf(obj.issued_month) + 1
			var monthIndexval = monthIndex > 10 ? monthIndex : "0" + monthIndex
			let monthDate = "01/" + monthIndexval
			issuedate = /^[a-zA-Z]*$/.test(obj.issued_month) ? dayjs(monthDate + "/" + obj.issued_year, "DD/MM/YYYY") : dayjs(obj.issued_month + "/" + obj.issued_year, "DD/MM/YYYY");
		} 

		if (obj.valid_upto_month) {
		var validmonthIndex = months.indexOf(obj.valid_upto_month) + 1
		var validmonthIndexval = validmonthIndex > 10 ? validmonthIndex : "0" + validmonthIndex
		let validmonthDate = "01/" + validmonthIndexval
		validdate = /^[a-zA-Z]*$/.test(obj.valid_upto_month) ? dayjs(validmonthDate + "/" + obj.valid_upto_year, "DD/MM/YYYY") : dayjs(obj.valid_upto_month + "/" + obj.valid_upto_year, "DD/MM/YYYY");
		}


		updateValue({ certificationName: obj.certification_name, score: obj.certification_score, issuingOrganisation: obj.organisation, certificateUrl: obj.certification_url, certificateId: obj.certificate_id, issuedDate: issuedate, validUpto: validdate , certificateUpload: "" })
	}

	const deleteCertificate = (index) => {
		let temp = cloneDeep(certificates);
		temp.splice(index, 1);
		setcertificates(temp);
	}

	/**
 * upload document 
 * argument file
 */
	const fileUpload = async (value) => {
		const formData = new FormData();
		formData.append("file", value[0]);
		try {
			Setloader(true);
			let response = await uploadSelectedFile(value["0"].name.substr(value["0"].name.lastIndexOf('.') + 1), formData)
			if (response.success) {
				console.log(response)
				updateValue({ "certificateUrl": response.mediaUrl })
				//showToaster(toasterTypes.SUCCESS,"File Uploaded successfully")
			}
			Setloader(false);

		} catch (e: any) {
			Setloader(false);
			throw e
		}
	}

	/**
	 * Upload the selected file on success update media URL and new_doc as true
	 * @param name 
	 * @param value 
	 * @param values 
	 * @param fieldValues 
	 * @returns 
	 */
	function onValueChange(name, value, values, fieldValues = null) {
		let resetValue = {};

		/**
		 * Upload file
		 */
		if (name == "certificateUpload") {
			console.log(value)
			fileUpload(value)
		}



		return [{
			...values,
			...resetValue,
			[name]: value,
		}]
	}


	const checkSubmit = async () => {
		let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
		if (popupType === 'certificate') {
			if (validateValues(["certificationName", "score", "issuingOrganisation", "certificateUrl", "certificateId", "issuedDate", "validUpto"])) {
				showToaster(toasterTypes.ERROR, "Please enter the proper values in the fields highlighted in red");
			} else {
				let certificateObj = {
					certification_score: parseFloat(values.score),
					certification_name: values.certificationName,
					issued_month:  values.issuedDate.format("DD/MM"),
					issued_year: parseInt(values.issuedDate.format("YYYY")),
					valid_upto_month: values.validUpto ? values.validUpto.format("DD/MM") : "",
					valid_upto_year: values.validUpto ? parseInt(values.validUpto.format("YYYY")) : 0,
					organisation: values.issuingOrganisation,
					certification_url: values.certificateUrl,
					certificate_id: values.certificateId
				}
				let temp: any = cloneDeep(certificates);
				if (certificateIndex !== null && certificateIndex >= 0) {
					temp[certificateIndex] = certificateObj;
				} else {
					temp.push(certificateObj);
				}
				setcertificates(temp);
				setcertificateIndex(null);
				setpopupType('skillName');
			}
		} else {
			if (validateValues(["skillName", "rating"])) {
				showToaster(toasterTypes.ERROR, "Please enter the proper values in the fields highlighted in red");
			} else {
				try {
					dispatch(setLoading(true));
					let reqObj: any = {
						source_id: null,
						source_type: values?.sourceType?.label || 'xyz',
						skill_name: values?.skillName || '',
						self_rating: parseFloat(values.rating),
						certifications: certificates
					}
					let response: any;
					if (selectedIndex !== null && selectedIndex >= 0) {
						reqObj.skill_master_id = data.skills[selectedIndex].skill_master_id;
						response = await updateSkillSet(reqObj, data.skills[selectedIndex].id);
					} else {
						response = await addSkillSet(reqObj);
					}
					showToaster(toasterTypes.SUCCESS, response.message);
					props.onClose('callAPI');
					dispatch(setLoading(false));
				} catch (e: any) {
					dispatch(setLoading(false));
				}
			}
		}
	}

	const renderCertificate = () => {
		return (
			<div>
				<div className='skill-text'>You are adding certification for the <span className='skill-name'>{values.skillName}</span> skill</div>
				<Row>
					<Col md={10}>
						<FieldItem
							{...SkillSetFormElements.certificationName}
							value={values.certificationName}
							onChange={(...e) => handleChange(SkillSetFormElements.certificationName.name, ...e)}
							touched={fields.certificationName && fields.certificationName.hasError}
							error={fields.certificationName && fields.certificationName.errorMsg}
						/>
					</Col>
					<Col md={2}>
						<FieldItem
							{...SkillSetFormElements.score}
							value={values.score}
							onChange={(...e) => handleChange(SkillSetFormElements.score.name, ...e)}
							touched={fields.score && fields.score.hasError}
							error={fields.score && fields.score.errorMsg}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={10}>
						<FieldItem
							{...SkillSetFormElements.issuingOrganisation}
							value={values.issuingOrganisation}
							onChange={(...e) => handleChange(SkillSetFormElements.issuingOrganisation.name, ...e)}
							touched={fields.issuingOrganisation && fields.issuingOrganisation.hasError}
							error={fields.issuingOrganisation && fields.issuingOrganisation.errorMsg}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={10}>
						<FieldItem
							{...SkillSetFormElements.certificateUrl}
							value={values.certificateUrl}
							onChange={(...e) => handleChange(SkillSetFormElements.certificateUrl.name, ...e)}
							touched={fields.certificateUrl && fields.certificateUrl.hasError}
							error={fields.certificateUrl && fields.certificateUrl.errorMsg}
						/>
					</Col>
					<Col md={2}>
						<FieldItem
							{...SkillSetFormElements.certificateId}
							value={values.certificateId}
							onChange={(...e) => handleChange(SkillSetFormElements.certificateId.name, ...e)}
							touched={fields.certificateId && fields.certificateId.hasError}
							error={fields.certificateId && fields.certificateId.errorMsg}
						/>
					</Col>
					<Col md={12}><span>OR</span></Col>
					<Col md={12}>
						<FieldItem
							{...SkillSetFormElements.certificateUpload}
							value={values.certificateUpload}
							onChange={(...e) => handleChange(SkillSetFormElements.certificateUpload.name, ...e)}
							touched={fields.certificateUpload && fields.certificateUpload.hasError}
							error={fields.certificateUpload && fields.certificateUpload.errorMsg}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={4}>
						<FieldItem
							{...SkillSetFormElements.issuedDate}
							value={values.issuedDate}
							onChange={(...e) => handleChange(SkillSetFormElements.issuedDate.name, ...e)}
							touched={fields.issuedDate && fields.issuedDate.hasError}
							error={fields.issuedDate && fields.issuedDate.errorMsg}
						/>
					</Col>
					<Col md={4}>
						<FieldItem
							{...SkillSetFormElements.validUpto}
							value={values.validUpto}
							onChange={(...e) => handleChange(SkillSetFormElements.validUpto.name, ...e)}
							touched={fields.validUpto && fields.validUpto.hasError}
							error={fields.validUpto && fields.validUpto.errorMsg}
						/>
					</Col>

				</Row>
			</div>
		)
	}

	const renderSkillSet = () => {
		return (
			<div>
				<Row>
					<Col md={10}>
						<FieldItem
							{...SkillSetFormElements.skillName}
							value={values.skillName}
							onChange={(...e) => handleChange(SkillSetFormElements.skillName.name, ...e)}
							touched={fields.skillName && fields.skillName.hasError}
							error={fields.skillName && fields.skillName.errorMsg}
						/>
					</Col>
					<Col md={2}>
						<FieldItem
							{...SkillSetFormElements.rating}
							value={values.rating}
							onChange={(...e) => handleChange(SkillSetFormElements.rating.name, ...e)}
							touched={fields.rating && fields.rating.hasError}
							error={fields.rating && fields.rating.errorMsg}
						/>
					</Col>
				</Row>
				<Row className='m-0 mt-2'>
					<Col className='p-0'>
						<div className='card-header-text'>Certification Details</div>
					</Col>
					<Col className='p-0'>
						<div className='d-flex m-0 justify-content-end'>
							<i className='icon-add c-pointer' onClick={() => onCertificate()}></i>
							<div className='add-text c-pointer' onClick={() => onCertificate()}>ADD CERTIFICATE</div>
						</div>
					</Col>
				</Row>
				{certificates.map((obj: any, index: any) => (
					<Row key={index} className='m-0 mt-2 skill-container'>
						<Col md={6} className='p-0'>
							<div className='skill-data'>
								<div className='logo-border'>
									<img src={`${process.env.PUBLIC_URL}/images/icon_skill.svg`} alt='logo' className='logo' />
								</div>
								<div>
									<div className='skill-name'>{obj.certification_name}</div>
									<div className='total-rating'>{obj.organisation}</div>
								</div>
							</div>
						</Col>
						<Col md={4} className='p-0'>
							<div className='pl-3'>
								<div className='certification-name'>Issued: 
								{moment(obj.issued_month + ' ' + obj.issued_year).format('DD MMM yyyy') != "Invalid date" ? obj.issued_month ? moment(obj.issued_month + '/' + obj.issued_year,"DD/MM/yyyy").format('MMM DD yyyy'):"" : "" || !obj.issued_month ? "" : moment(obj.issued_month + '/' + obj.issued_year, "DD/MM/YYYY").format('DD MMM yyyy')} 
								{obj.valid_upto_month ? /^[a-zA-Z]*$/.test(obj.valid_upto_month) ? " - "+moment("01/"+(months.indexOf(obj.valid_upto_month)+1)+"/"+obj.valid_upto_year).format('DD MMM yyyy') : " - "+moment(obj.valid_upto_month+"/"+obj.valid_upto_year,"DD MM yyyy").format('DD MMM yyyy'): ""}
							</div>
							<div className='certification-institute'>Certificate Id - {obj.certificate_id}<span className='link' onClick={() => window.open(obj.certification_url)}>Link</span></div>
							</div>
						</Col>

						{
							window.innerWidth > 700 ?
								<Col md={2} className='text-center'>
									<i className='icon-edit c-pointer mr-3' onClick={() => editCertificate(obj, index)}></i>
									<i className='icon-delete c-pointer' onClick={() => deleteCertificate(index)}></i>
								</Col>
								:
								<Row className='ml-2'>
									<Col xs={6}>
										<i className='icon-edit c-pointer' onClick={() => editCertificate(obj, index)}></i>
									</Col>
									<Col xs={6}>
										<i className='icon-delete c-pointer' onClick={() => deleteCertificate(index)}></i>
									</Col>
								</Row>
						}
					</Row>
				))}
			</div>
		)
	}

	return (
		<ModalComponent
			width={window.innerWidth > 700 ? "800px" : "350px"}
			isOpen={true}
			onClose={() => popupType === 'certificate' ? setpopupType('') : props.onClose()}
			header={popupType === 'certificate' ? "Add Certificate" : "Skill Set"}
			centered={true}
			customClass='profile-modal'
			body={popupType === 'certificate' ? renderCertificate() : renderSkillSet()}
			footer={
				<div>
					<CustomButton type="primary" isDisabled={false} text="Save" onClick={() => checkSubmit()}></CustomButton>
				</div>
			}
		/>
	)
}