import React from 'react'
import SideMenu from './sideMenu/sideMenu'
export default function PostLoginLayoutRoute(props) {
  return (
    <>
      {
        window.innerWidth > 700 ? 
            <div className='d-flex'>      
                  <SideMenu />     
                  <div className='col-10 p-0'>
                    {props.children}
                  </div>  
            </div>
            :
            <div className='mob-layout'>
              {props.children}   
              <SideMenu /> 
            </div> 
      } 
    </>
  )
}
