import { ArrowLeftOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { Card, Image } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { CardBody, CardFooter, CardTitle, Col, Row } from "reactstrap";
import { setLoading } from "../../store/slices/auth";
import { getLMSCourseModule } from "./apiController";
import { useDispatch } from "react-redux";

export default function CourseModules(props) {
    const [courselist, Setcourselist] = useState<any>([]);
    const [loading, Setloading] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        let locationData : any = location.state;
        fetchInitialData(locationData.id,locationData.uid);
    }, [])

    /**
     * on click view course get the list of modules
     */
    const viewCourse = (data) => {
        let ModuleData : any = location.state;
        navigate("/modulevideo",{state:{data:data,moduData:ModuleData.id,uid:ModuleData.uid}})
    }


    /**
     * fetch initial data course module list
     */
    const fetchInitialData = async (id,uid) => {
        try {
            dispatch(setLoading(true));
            let courses = await getLMSCourseModule(id,uid);
            Setcourselist(courses.data)
            dispatch(setLoading(false));

        } catch (e: any) {
            dispatch(setLoading(false));
            throw e
        }
    }
    return (
        <>
            <div className="module-container">
                <div className="head-module">
                    <ArrowLeftOutlined style={{ color: "#fff", fontSize: "24px", margin: "0px 10px" }} onClick={() => navigate("/learning")} /> Modules
                </div>
                <div className="module-list">
                    <Row>
                        {
                            courselist.map((item, id) => {
                                return <Col md={3} key={id}>
                                    <Card className="module-card">
                                        <CardBody>
                                           
                                           <Image className="thumbnail" src={item.thumbnail} alt={item.title} onLoad={() => setLoading(false)}/>  
                                            <span className="description" >{item.title}</span>
                                        </CardBody>
                                        <CardFooter>
                                            <div className="view-module" onClick={() => viewCourse(item)}>
                                                View Module
                                            </div>
                                        </CardFooter>
                                    </Card>
                                </Col>
                            })
                        }
                    </Row>
                </div>
            </div>
        </>
    )
}