
import { useEffect, useState } from 'react';

export default function ConfigChart(){
  const [categories,SetCategories] = useState([]);
  useEffect(()=>{
    let arrayCat = [];
    for(var obj in stackdata[0]){
    if(obj != "label"){
      arrayCat.push(obj)
    }
 }
 SetCategories(arrayCat)
},[])
  const data =[
    { group: 'Group A', values: [{ name: 'Category 1', value: 10 }, { name: 'Category 2', value: 20 }, { name: 'Category 3', value: 30 }] },
    { group: 'Group B', values: [{ name: 'Category 1', value: 15 }, { name: 'Category 2', value: 25 },{ name: 'Category 3', value: 20 }] },
    { group: 'Group C', values: [{ name: 'Category 1', value: 12 }, { name: 'Category 2', value: 56 },{ name: 'Category 3', value: 20 }] },
    { group: 'Group D', values: [{ name: 'Category 1', value: 45 }, { name: 'Category 2', value: 77 },{ name: 'Category 3', value: 20 }] },
  ];;

  const data1 = [
    { name: 'Series 1', values: [1, 2, 3, 4, 5] },
    { name: 'Series 2', values: [5, 4, 3, 2, 1] },
  ];
  
  const datal = [25, 70, 11, 45, 60, 46, 44,60,32,80,40,100];
  const datal1 =[
    {
        "x": "Death",
        "y": 121
    },
    {
        "x": "Alive",
        "y": 100
    },
    {
        "x": "Lost to followup",
        "y": 162
    }
];

      const datab = [
        { label: 'data  ddrer', value: 30 },
        { label: 'asdfasdf ddded asdfasf', value: 50 },
        { label: 'C', value: 20 },
        { label: 'r', value: 30 },
        { label: 'h', value: 50 },
        { label: 'w', value: 26 },
        { label: 'q', value: 32 },
        { label: 'i', value: 50 },
        { label: 'qqsf', value: 22 },
        { label: 'rw', value: 34 },
        { label: 'he', value: 56 },
        { label: 'wr', value: 25 },
        { label: 'qt', value: 34 },
        { label: 'ir', value: 10 },
        { label: 'yf', value: 60 },
        { label: 'yf2', value: 63 },
        { label: 'y34f', value: 65 }
        // Add more data as needed
      ];

      const groupdata = [
        { label: 'A', group: 'Group1', values: { Group1: 20, Group2: 30 } },
        { label: 'B', group: 'Group1', values: { Group1: 40, Group2: 50 } },
        { label: 'C', group: 'Group1', values: { Group1: 10, Group2: 25 } },
      ];

      const stackdata = [
        { label: 'A', category1: 20, category2: 30, category3: 10 },
        { label: 'B', category1: 40, category2: 10, category3: 25 },
        { label: 'C', category1: 15, category2: 25, category3: 35 },
      ];

 

   const scatter = [
    { x: "Male", y: 20 },
    { x:"Female", y: 40 }  ];
   
  const boxdata =  [
    [66,69],
    [2, 3, 4, 5, 6,12,14,15,16,17],
    [3, 4, 5, 6, 7,9,10],
    [3, 4, 5, 6, 7,8,9],
    [3, 4, 5, 6, 7,9],
   
  ];

  const datag = [
    { group: 'A', category: 'Cat1', value: 10 },
    { group: 'A', category: 'Cat2', value: 15 },
    { group: 'B', category: 'Cat1', value: 20 },
    { group: 'B', category: 'Cat2', value: 25 },
    // Add more data as needed
  ];

  const pidata = [
    { label: 'Category A', value: 30 },
    { label: 'Category B', value: 20 },
    { label: 'Category C', value: 30 },
    { label: 'Category D', value: 15 },

  ];

  const multidata = [
    { x: 1, line1: 5, line2: 20,line3: 0 },
    { x: 2, line1: 16, line2: 15,line3: 20  },
    { x: 3, line1: 20, line2: 30 ,line3: 30 },
    { x: 4, line1: 12, line2: 50 ,line3: 40},
    { x: 5, line1: 24, line2: 34,line3: 50 },
    { x: 6, line1: 26, line2: 30,line3: 60 },
    { x: 7, line1: 30, line2: 23,line3: 66 },
    { x: 8, line1: 33, line2: 15,line3: 66 },
    { x: 9, line1: 36, line2: 65,line3: 66 },
    { x: 10, line1: 40, line2: 34,line3: 66 },
    { x: 11, line1: 45, line2: 32,line3: 66 }];

    const paretoData = [
      { label: 'Category 1', value: 20 },
      { label: 'Category 2', value: 15 },
      { label: 'Category 3', value: 10 },
      { label: 'Category 4', value: 8 },
      { label: 'Category 5', value: 7 },
    ];

    

    return(
        <>
        {/* <div style={{display:"flex",flexDirection:"column",gap:"100px"}}>
        <LineChart 
        data={datal1}
            width={600} 
            height={300} 
            scaley={20}
            isarray={true}
            chartstyle={{color:"royalblue",hover:"#ccc",linecolor:"#cc5",radius:4}}
            chartdatastyle={{fontsize:"16px",fontweight:"500",color:"black",visible:"visible"}}
            gridcolor={"#ccc"}
            gridstatus={"hidden"}
            yaxistext={"Life Exp Y label"}
            yaxislabelstyle={{fontsize:"18px",fontweight:"400",color:"#000"}}
            yaxisstyle={{fontsize:"14px",fontweight:"600",color:"#000"}}
            xaxistext={"Lines per capital values "}
            xaxislabelstyle={{fontsize:"18px",fontweight:"400",color:"red",rotate:"-45"}}
            xaxisstyle={{fontsize:"18px",fontweight:"500",color:"#000",dy:"2em"}}
            title={"Income Chart"}
            titlecolor={"#000"}
            />

       <ParetoChart 
        data={paretoData}
        width={600}
        height={400}
        gridcolor={"#ccc"}
        gridstatus={"visible"}
        barstyle={{color:"orange",hover:"#ccc"}}
        bardatastyle={{fontsize:"16px",fontweight:"500",color:"black",visible:"visible"}}  
        linestyle={{color:"blue",hover:"#ccc"}}
        linedatastyle={{fontsize:"16px",fontweight:"500",color:"black",radius:3,visible:"visible"}}  
        title={"Income Chart"}
        titlecolor={{fontsize:"24px",fontweight:"600",color:"green"}}
        yaxistext={"Life Exp Y label"}
        yaxislabelstyle={{fontsize:"18px",fontweight:"400",color:"#000"}}
        yaxisstyle={{fontsize:"14px",fontweight:"600",color:"#000",dy:"-3em"}}
        xaxistext={"Income per capital values "}
        xaxislabelstyle={{fontsize:"18px",fontweight:"400",color:"#000",rotate:"-45"}}
        xaxisstyle={{fontsize:"18px",fontweight:"500",color:"#000",dy:"3em"}}
        />

      <MultiLineChart 
            data={multidata}
            width={300} 
            height={100} 
            scaley={20}
            isarray={true}
            yrange={0}
            chartstyle={{color:"royalblue",hover:"#ccc",linecolor:"#cc5",radius:4}}
            chartdatastyle={{fontsize:"16px",fontweight:"500",color:"black",visible:"visible"}}
            gridcolor={"#ccc"}
            gridstatus={"visible"}
            yaxistext={"Life Exp Y label"}
            yaxislabelstyle={{fontsize:"18px",fontweight:"400",color:"#000"}}
            yaxisstyle={{fontsize:"14px",fontweight:"600",color:"#000",dy:"-2em"}}
            xaxistext={"Income per capital values "}
            xaxislabelstyle={{fontsize:"18px",fontweight:"400",color:"#000",rotate:"-45"}}
            xaxisstyle={{fontsize:"18px",fontweight:"500",color:"#000",dy:"2em"}}
            title={"Income Chart"}
            titlecolor={{fontsize:"24px",fontweight:"600",color:"green"}}/>



    <PieChart 
    data={pidata} 
    width={600}
    height={300}
    chartStyle={{hover:"#ccc",fontsize:"14px",fontweight:"500",color:"#fff"}}/>
   


          <BoxPlot
            data={boxdata}
            width={600} 
            height={400} 
            chartstyle={{color:"lightblue",hover:"#ccc",dotradius:5,linecolor:"#ccc"}}
            chartdatastyle={{fontsize:"16px",fontweight:"500",color:"black",visible:"visible"}}
            chartdots={{color:"#000",radius:0}}
            gridcolor={"#ccc"}
            gridstatus={"visible"}
            yaxistext={"Life Exp Y label"}
            yaxislabelstyle={{fontsize:"18px",fontweight:"400",color:"#000"}}
            yaxisstyle={{fontsize:"14px",fontweight:"600",color:"#000",dy:"-2em"}}
            xaxistext={"Income per capital values "}
            xaxislabelstyle={{fontsize:"18px",fontweight:"400",color:"#000",rotate:"-45"}}
            xaxisstyle={{fontsize:"18px",fontweight:"500",color:"#000",dy:"2em"}}
            title={"Boxplot Chart"}
            titlecolor={{fontsize:"24px",fontweight:"600",color:"green"}} />


         <ScatterPlot 
          data={scatter}
          width={400} 
            height={500} 
            chartstyle={{color:"red",hover:"#ccc",dotradius:5,linecolor:"#ccc"}}
            chartdatastyle={{fontsize:"16px",fontweight:"500",color:"black",visible:"visible"}}
            gridcolor={"#ccc"}
            gridstatus={"visible"}
            yaxistext={"Life Exp Y label"}
            yaxislabelstyle={{fontsize:"18px",fontweight:"400",color:"#000"}}
            yaxisstyle={{fontsize:"14px",fontweight:"600",color:"#000"}}
            xaxistext={"Income per capital values "}
            xaxislabelstyle={{fontsize:"18px",fontweight:"400",color:"red",rotate:"-45"}}
            xaxisstyle={{fontsize:"18px",fontweight:"500",color:"#000",dy:"4em"}}
            title={"Scatter Chart"}
            titlecolor={{fontsize:"24px",fontweight:"600",color:"green"}} />
     
        

       <HorizontalBarChart 
          data={datab} 
          width={1000} 
          height={500} 
          barstyle={{color:"#80cbc4",hover:"#ccc"}}
          bardatastyle={{fontsize:"16px",fontweight:"500",color:"red",visible:"visible"}}
          gridcolor={"#cc1"}
          gridstatus={"visible"}
          yaxistext={"Life Exp label"}
          yaxislabelstyle={{fontsize:"18px",fontweight:"400",color:"#000",rotate:"-10"}}
          yaxisstyle={{fontsize:"18px",fontweight:"600",color:"#000"}}
          xaxistext={"Income per capital values "}
          xaxislabelstyle={{fontsize:"18px",fontweight:"400",color:"#000",rotate:"-45"}}
          xaxisstyle={{fontsize:"18px",fontweight:"500",color:"#000",dy:"6em"}}
          title={"Income Chart"}
          titlecolor={{fontsize:"24px",fontweight:"600",color:"green"}}
        />

        <BarChart
          width={800} 
          height={400} 
          barstyle={{color:"orange",hover:"#ccc"}}
          bardatastyle={{fontsize:"16px",fontweight:"500",color:"black",visible:"visible"}}
          gridcolor={"#ccc"}
          gridstatus={"visible"}
          yaxistext={"Life Exp Y label"}
          yaxislabelstyle={{fontsize:"18px",fontweight:"400",color:"#000"}}
          yaxisstyle={{fontsize:"14px",fontweight:"600",color:"#000"}}
          xaxistext={"Income per capital values "}
          xaxislabelstyle={{fontsize:"18px",fontweight:"400",color:"red",rotate:"0"}}
          xaxisstyle={{fontsize:"18px",fontweight:"500",color:"#000",dy:"3em"}}
          title={"Income Chart"}
          titlecolor={{fontsize:"24px",fontweight:"600",color:"green"}}/>

       <GroupedBarChart  
            data={data} 
            width={800} 
            height={400} 
            barstyle={{color:"#80cbc4",hover:"#ccc"}}
            bardatastyle={{fontsize:"16px",fontweight:"500",color:"black",visible:"visible"}}
            gridcolor={"#ccc"}
            gridstatus={"visible"}
            yaxistext={"Life Exp Y label"}
            yaxislabelstyle={{fontsize:"18px",fontweight:"400",color:"#000"}}
            yaxisstyle={{fontsize:"14px",fontweight:"600",color:"#000"}}
            xaxistext={"Income per capital values "}
            xaxislabelstyle={{fontsize:"18px",fontweight:"400",color:"red",rotate:"-45"}}
            xaxisstyle={{fontsize:"18px",fontweight:"500",color:"#000",dy:"1em"}}
            title={"Income Chart"}
            titlecolor={{fontsize:"24px",fontweight:"600",color:"green"}}/>
     
    
     <StackedBarChart 
     data={stackdata} 
     width={800} 
     height={400} 
     categories={categories}
     barstyle={{color:"#80cbc4",hover:"#ccc"}}
     bardatastyle={{fontsize:"16px",fontweight:"500",color:"red",visible:"visible"}}
     gridcolor={"#cc1"}
     gridstatus={"visible"}
     yaxistext={"Life Exp Y label"}
     yaxislabelstyle={{fontsize:"14px",fontweight:"400",color:"#000"}}
     yaxisstyle={{fontsize:"14px",fontweight:"600",color:"#000",dy:"1em"}}
     xaxistext={"Income per capital values "}
     xaxislabelstyle={{fontsize:"14px",fontweight:"400",color:"red",rotate:"-45"}}
     xaxisstyle={{fontsize:"18px",fontweight:"500",color:"#000",dy:"3em"}}
     title={"Income Chart"}
     titlecolor={{fontsize:"24px",fontweight:"600",color:"green"}}/>
    
    </div> */}
    

        </>
    )
}