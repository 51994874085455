  // Function to convert UTC date to IST and adjust the date if needed
  export const convertToIST = (utcDate) => {
    const date = new Date(utcDate);
    const months = [ 
      "January", "February",  
      "March", "April", "May",  
      "June", "July", "August", 
      "September", "October",  
      "November", "December" 
  ]; 
    // Get the IST offset from UTC in milliseconds
    // const IST_OFFSET = 5.5 * 60 * 60 * 1000;
    //const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    //const off = new Date().getTimezoneOffset();

    const offset = getTimezoneOffset(localStorage.getItem('timezone'));
    // console.log(off,"******************",offset* 60 * 60 * 1000); // Offset in hours (e.g., 5 for IST)

    // Convert the date to IST by adding the IST offset
    const istDate = new Date(date.getTime() + offset* 60 * 60 * 1000);

    
    // Format the IST date
    let hours = istDate.getUTCHours();
    let minutes = istDate.getUTCMinutes();
    let day = istDate.getUTCDate();
    let month = istDate.getUTCMonth() + 1;
    let year = istDate.getUTCFullYear();
  
    // Adjust the date if the time is 00:00
    if (hours === 0 && minutes === 0) {
      //day += 1;
      const nextDate = new Date(Date.UTC(year, month - 1, day));
      hours = nextDate.getUTCHours();
      minutes = nextDate.getUTCMinutes();
      day = nextDate.getUTCDate();
      month = nextDate.getUTCMonth() + 1;
      year = nextDate.getUTCFullYear();
    }
  
    // Return the formatted date string
    if(utcDate || utcDate == "-"){
      // console.log(utcDate)
      // console.log("*************************")
      console.log(`${day} ${month} ${year}, ${hours === 0 ? 12 : hours > 12 ? hours - 12 : 12 - hours}:${minutes < 10 ? '0' : ''}${minutes} ${hours >= 12 ? 'PM':'AM'} IST`)
      if(isNaN(day)){
        return '-'
      }else{
        return `${day} ${months[month-1]} ${year}, ${hours === 0 ? 12 : hours > 12 ? hours - 12 : hours}:${minutes < 10 ? '0' : ''}${minutes} ${hours >= 12 ? 'PM':'AM'} IST`;
      }
    }else{
      return "-"
    }
  };

  /**
   * function to get offset from the time zone selected
   * @param timeZone 
   * @returns 
   */
  function getTimezoneOffset(timeZone) {
    const now = new Date();
    const options : any = {
        timeZone,
        hour: '2-digit',
        minute: '2-digit',
        timeZoneName: 'short'
    };
    const formatter = new Intl.DateTimeFormat([], options);
    const parts : any = formatter.formatToParts(now);
    const timeZoneName : any = parts.find(part => part.type === 'timeZoneName').value;
    console.log(timeZoneName)
    // Extract offset from time zone name (e.g., "GMT+5" -> +5)
    const offsetMatch = timeZoneName.match(/GMT([+-]\d+):(\d+)/);
    if (offsetMatch) {
      const hours = parseInt(offsetMatch[1], 10);
      const minutes = parseInt(offsetMatch[2], 10);
      return hours + minutes / 60;
  }

    return 0;
}