import { useRef, useState } from "react";
import { Button } from "reactstrap";


const CopyTooltip = ({ tooltipStyle, data, pushAction }) => {

    const [action, setAction] = useState("");
    const tooltipRef = useRef(null);

    return (
        <>
           
            <div
                ref={tooltipRef}
                style={{
                    position: 'absolute',
                    ...tooltipStyle,
                    padding: '5px',
                    backgroundColor: '#fff',
                    borderRadius: "5px",
                    color: '#fff',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                    zIndex: 1000,
                }}
            >

                <Button onClick={() => pushAction()} style={{ backgroundColor: "#fff", border: "none", color: "black", padding: "10px", cursor: "pointer", fontWeight: "600" }}>Copy to Editor</Button>
            </div>
        </>
    )
}

export default CopyTooltip;