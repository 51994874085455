import { Image } from "react-bootstrap";
import { CustomButton } from "../../widgets";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setLoading } from "../../store/slices/auth";
import { deleteGroupVariable, getGroupVariable } from "./apiController";
import { Col, Row } from "reactstrap";
import { useNavigate } from "react-router-dom";
import AddGroupingMobile from "./AddGroupingMobile";

const GroupVariableMobile = (props) => {
    const [popupType, SetPopupType] = useState<any>("");
    const [selectedItem, SetSelectedItem] = useState<any>({});
    const [groupVariables, SetgroupVariables] = useState<any>([]);
    const dispatch = useDispatch();
    const { group, formData } = props;
    const navigate = useNavigate();

    useEffect(() => {
        console.log(props)
        fetchIntialData("");
    }, [])

    /**
     * fetch data
     */
    const fetchIntialData = async (action) => {
        try {
            dispatch(setLoading(true))
            let groupVaraibleResponse = await getGroupVariable(formData.id);
            console.log(groupVaraibleResponse)
            if (groupVaraibleResponse['data'] && groupVaraibleResponse['data'].length > 0) {
                SetgroupVariables(groupVaraibleResponse['data'])
            } else {
                if (action == "") {
                    SetPopupType("group")
                }
            }
            dispatch(setLoading(false))
        } catch (error: any) {
            dispatch(setLoading(false))
        }
    }

    /**
   * delete group variable with formid and fieldid
   */
    const deleteVariable = async (data) => {
        console.log(data)
        let deleteResponse = await deleteGroupVariable(formData.id, data.id);
        if (deleteResponse) {
            fetchIntialData("");
        }
    }

    const onBack = () => {
        SetPopupType("")
    }

    return (
        <>
            {
                popupType == "configure" ?
                    <AddGroupingMobile {...props} onBack={onBack} />
                    :
                    <div className="group-variable-list-mobile">
                        <div className="header-container">
                            <div className='pl-3 pt-4 form-name'>
                                <Image className='profile-image' alt='Profile' src={`${process.env.PUBLIC_URL}/images/logo.png`} />
                                Data Grouping
                            </div>
                            <div className='pt-4 closeicon'>
                                <i className='icon icon-close' onClick={() => props.onClose()} />
                            </div>
                        </div>
                        {
                            groupVariables && groupVariables.length == 0 ?
                                <div className="main-container">
                                    <span>No groupings are available proceed to create</span>
                                    <CustomButton type="primary" className="create-btn" text="Create" onClick={() => SetPopupType('configure')} />
                                </div> :
                                ""
                        }
                        {
                            groupVariables && groupVariables.length != 0 ?
                                <div className="variable-container">
                                    <div className="create-section">
                                        {/* <CustomButton className="create-btn" type={"primary"} text={"Create"} onClick={() => SetPopupType("group")} /> */}
                                        <div className="p-2 count">Group Variables | {groupVariables && groupVariables.length}</div>
                                    </div>
                                    <div className="variable-list">
                                        {
                                            groupVariables && groupVariables.map((items, index) => {
                                                return (<>
                                                    <div className="p-3 variables">
                                                        <Row>
                                                            <Col xs={10}>
                                                                <div className=" ml-3 variable-text">
                                                                    {items?.field_name}
                                                                </div>
                                                            </Col>

                                                            <Col className='p-0' xs={2}>
                                                                <div className='row m-0 justify-content-around'>
                                                                    {/* <i className='icon icon-eye' onClick={() => onAction(2,items)} /> */}
                                                                    <i className='icon icon-delete' onClick={() => { deleteVariable(items) }} />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={12}>
                                                                <div className=" ml-2 variable-expression">
                                                                    {
                                                                        items && items.expression[0].conditions?.map(valueitem => {
                                                                            return <span style={{ margin: "0px 5px" }}>{valueitem.key == "field" ? valueitem?.display_value || "" : valueitem.value}</span>
                                                                        })
                                                                    }
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </>)
                                            })
                                        }
                                    </div>
                                    <div className="create-btn-container">
                                        <CustomButton className="create-btn" type={"primary"} text={"Create"} onClick={() => SetPopupType("configure")} />
                                    </div>
                                </div>
                                : ""
                        }
                    </div>
            }
        </>
    )
}

export default GroupVariableMobile;