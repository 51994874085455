import { Service } from 'wax-prosemirror-core';
import VersionSection from './VersionSection';

export default class VersionConfig extends Service {
  boot() {
    const layout = this.container.get('Layout');

    layout.addComponent('versionSection', VersionSection);
  }

  register() {
    const versionConfig = this.config.get('config.VersionConfig');
  }
}

