import AjaxUtil from '../../axios/axios';


/**
 * get permissions data
 */
 export const getPermissionData = async(usertype,userid,projectid) => {
    try {
        return await AjaxUtil.sendRequest(`permissions?role=${usertype}&user_id=${userid}&project_id=${projectid}`, {}, false, { method: 'get', showError:true, isProfile:true});

    } catch (error) {
        throw error;
    }
}

/**
 * submit permission data
 */
 export const submitPermissionData = async(usertype,userid,projectid,data) => {
    try {
        return await AjaxUtil.sendRequest(`permissions?role=${usertype}&user_id=${userid}&project_id=${projectid}`,data, false, { method: 'post', showError:true, isProfile:true});

    } catch (error) {
        throw error;
    }
}