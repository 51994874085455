import { CloseOutlined, EditFilled, ForwardOutlined, RightCircleOutlined } from "@ant-design/icons";
import { useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Form } from "reactstrap";
import { CustomButton } from "../../widgets";
import { FieldItem } from "../../widgets/fields";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import Step from "../common/components/Step";
import { ObjectiveElements, TitleElements } from "../createProject/FormElements";


export default function ObjectiveGeneration(props) {
    const [tempData, SettempData] = useState([]);
    const navigate = useNavigate();
    const initialValues = useMemo(() => ({ phrase: '', trying: '', interventionGroup: '', comparatorGroup: '', studyPopulation: '', planning: '', timeline: '' }), []);
    const [values, fields, handleChange] = useFieldItem(ObjectiveElements, initialValues, { onValueChange });
    const [objectiveData, setobjectiveData] = useState([]);
    const [step, SetStep] = useState(1);

    function onValueChange(name, value, values, fieldValues) {
        checkValues(name, value);
    }

    const checkValues = (name, value) => {
        values[name] = value;
        let topicDescData: any = [];
        if (values.phrase && values.trying && values.studyPopulation) {
            if (values.interventionGroup === '' && values.comparatorGroup === '') {
                topicDescData = [{
                    data: `${values.phrase} the ${values.trying} ${values.studyPopulation} among ${values.planning && values.timeline ? 'using ' + values.planning + ' during ' + values.timeline : ''}`
                }, {
                    data: `${values.phrase} the incidence/prevalence/proportion of ${values.trying} among ${values.studyPopulation} ${values.planning && values.timeline ? 'using ' + values.planning + ' during ' + values.timeline : ''}`
                }]
            } else if (values.interventionGroup === '') {
                topicDescData = [{
                    data: `${values.phrase} the ${values.trying} between ${values.studyPopulation} and ${values.comparatorGroup} ${values.planning && values.timeline ? 'using ' + values.planning + ' during ' + values.timeline : ''}`
                }, {
                    data: `${values.phrase} the incidence/prevalence/proportion of ${values.trying} between ${values.studyPopulation} and ${values.comparatorGroup} ${values.planning && values.timeline ? 'using ' + values.planning + ' during ' + values.timeline : ''}`
                }]
            } else if (values.comparatorGroup === '') {
                topicDescData = [{
                    data: `${values.phrase} the ${values.trying} between ${values.studyPopulation} and ${values.interventionGroup} ${values.planning && values.timeline ? 'using ' + values.planning + ' during ' + values.timeline : ''}`
                }, {
                    data: `${values.phrase} the incidence/prevalence/proportion of ${values.trying} between ${values.studyPopulation} and ${values.interventionGroup} ${values.planning && values.timeline ? 'using ' + values.planning + ' during ' + values.timeline : ''}`
                }]
            } else {
                topicDescData = [{
                    data: `${values.phrase} the ${values.trying} between ${values.interventionGroup} and ${values.comparatorGroup} among ${values.studyPopulation} ${values.planning && values.timeline ? 'using ' + values.planning + ' during ' + values.timeline : ''}`
                }, {
                    data: `${values.phrase} the incidence/prevalence/proportion of ${values.trying} between ${values.interventionGroup} and ${values.comparatorGroup} among ${values.studyPopulation} ${values.planning && values.timeline ? 'using ' + values.planning + ' during ' + values.timeline : ''}`
                }, {
                    data: `${values.phrase} the efficiency/safety between ${values.interventionGroup} and ${values.comparatorGroup} among ${values.studyPopulation} ${values.planning && values.timeline ? 'using ' + values.planning + ' during ' + values.timeline : ''}`
                }]
            }
        }
        SettempData(topicDescData);
    }

    /**
     * next action
     */
    const next = () => {
        SetStep(step + 1)
        console.log(values)
    }

    /**
     * Generate objectives
     */
    const generateObjective = () => {
        setobjectiveData(tempData)
    }

    //On edit set the question number
    const editQuestion = (num) => {
        SetStep(num)
    }
    return (
        <>
            <div className="objective-generate-container">

                <div className="head">
                    <div className="title-div">
                        <span className="title-text">Objective Generation</span>
                    </div>
                    <div>
                        <CloseOutlined className="title-close" onClick={() => props.onClose()} />
                    </div>
                </div>
                <div className="objective-body">
                    {step != 1 ?
                        <div className="summary">
                            <div className="item">
                                <Step step={1} />
                                <div className="questions">
                                    <div className="question">{ObjectiveElements.phrase.label}</div>
                                    <span className="answer">{values['phrase']}</span>
                                </div>
                                <div className="edit">
                                    <EditFilled onClick={() => editQuestion(1)} />
                                </div>
                            </div>
                            {values['trying'] ?
                                <div className="item">
                                    <Step step={2} />
                                    <div className="questions">
                                        <div className="question">{`What are you trying to ${values.phrase}?`}</div>
                                        <span className="answer">{values['trying']}</span>
                                    </div>
                                    <div className="edit">
                                        <EditFilled onClick={() => editQuestion(2)} />
                                    </div>
                                </div>
                                : ""}

                            {values['studyPopulation'] ?
                                <div className="item">
                                    <Step step={3} />
                                    <div className="multi-text">
                                        <div className="questions">
                                            <div className="question">{ObjectiveElements.interventionGroup.label}</div>
                                            <span className="answer">{values['interventionGroup'] || "-"}</span>
                                        </div>
                                        <div className="questions">
                                            <div className="question">{ObjectiveElements.comparatorGroup.label}</div>
                                            <span className="answer">{values['comparatorGroup'] || "-"}</span>
                                        </div>
                                        <div className="questions">
                                            <div className="question">{ObjectiveElements.studyPopulation.label}</div>
                                            <span className="answer">{values['studyPopulation']}</span>
                                        </div>
                                    </div>
                                    <div className="edit">
                                        <EditFilled onClick={() => editQuestion(3)} />
                                    </div>
                                </div>
                                : ""}

                            {values['planning'] && values['timeline'] ?
                                <div className="item">
                                    <Step step={4} />
                                    <div className="multi-text">
                                        <div className="questions">
                                            <div className="question">{ObjectiveElements.planning.label}</div>
                                            <span className="answer">{values['planning'] || "-"}</span>
                                        </div>
                                        <div className="questions">
                                            <div className="question">{ObjectiveElements.timeline.label}</div>
                                            <span className="answer">{values['timeline'] || "-"}</span>
                                        </div>

                                    </div>
                                    <div className="edit">
                                        <EditFilled onClick={() => editQuestion(4)} />
                                    </div>
                                </div>
                                : ""}
                        </div>
                        : ""
                    }
                    {
                        step == 1 ?
                            <>
                                <Step step={1} />
                                <div className='form-container'>

                                    <FieldItem
                                        {...ObjectiveElements.phrase}
                                        value={values.phrase}
                                        onChange={(...e) => handleChange(ObjectiveElements.phrase.name, ...e)}
                                        touched={fields.phrase && fields.phrase.hasError}
                                        error={fields.phrase && fields.phrase.errorMsg}
                                    />
                                </div>
                            </>
                            : ""
                    }

                    {values.phrase && step == 2 ?
                        <div>
                            <Step step={2} />
                            <div className='form-container'>
                                <FieldItem
                                    {...ObjectiveElements.trying}
                                    value={values.trying}
                                    label={`What are you trying to ${values.phrase}?`}
                                    onChange={(...e) => handleChange(ObjectiveElements.trying.name, ...e)}
                                    touched={fields.trying && fields.trying.hasError}
                                    error={fields.trying && fields.trying.errorMsg}
                                />
                            </div>
                        </div>
                        : ""
                    }

                    {values.phrase && values.trying && step == 3 ?
                        <div>
                            <Step step={3} />
                            <div className='form-container'>
                                <Row>
                                    <Col md={6}>
                                        <FieldItem
                                            {...ObjectiveElements.interventionGroup}
                                            value={values.interventionGroup}
                                            onChange={(...e) => handleChange(ObjectiveElements.interventionGroup.name, ...e)}
                                            touched={fields.interventionGroup && fields.interventionGroup.hasError}
                                            error={fields.interventionGroup && fields.interventionGroup.errorMsg}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <FieldItem
                                            {...ObjectiveElements.comparatorGroup}
                                            value={values.comparatorGroup}
                                            onChange={(...e) => handleChange(ObjectiveElements.comparatorGroup.name, ...e)}
                                            touched={fields.comparatorGroup && fields.comparatorGroup.hasError}
                                            error={fields.comparatorGroup && fields.comparatorGroup.errorMsg}
                                        />
                                    </Col>
                                </Row>
                                <FieldItem
                                    {...ObjectiveElements.studyPopulation}
                                    value={values.studyPopulation}
                                    onChange={(...e) => handleChange(ObjectiveElements.studyPopulation.name, ...e)}
                                    touched={fields.studyPopulation && fields.studyPopulation.hasError}
                                    error={fields.studyPopulation && fields.studyPopulation.errorMsg}
                                />
                            </div>
                        </div>
                        : ""
                    }

                    {values.studyPopulation && step == 4 ?
                        <div>
                            <Step step={4} />
                            <div className='form-container'>
                                <Row>
                                    <Col md={6}>
                                        <FieldItem
                                            {...ObjectiveElements.planning}
                                            value={values.planning}
                                            onChange={(...e) => handleChange(ObjectiveElements.planning.name, ...e)}
                                            touched={fields.planning && fields.planning.hasError}
                                            error={fields.planning && fields.planning.errorMsg}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <FieldItem
                                            {...ObjectiveElements.timeline}
                                            value={values.timeline}
                                            onChange={(...e) => handleChange(ObjectiveElements.timeline.name, ...e)}
                                            touched={fields.timeline && fields.timeline.hasError}
                                            error={fields.timeline && fields.timeline.errorMsg}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        : ""
                    }
                    {
                        step == 4 ?
                            <CustomButton type="primary" onClick={() => generateObjective()} className="w-100" text="Generate"></CustomButton>
                            :
                            <CustomButton type="primary" onClick={() => next()} className="w-100" text="Next"></CustomButton>

                    }
                </div>



                <div className={objectiveData.length === 0 ? "object-footer" : "object-footer-data"}>

                    <span className="head-text">Objective suggestion</span>

                    {objectiveData.length === 0 ?
                        <>
                            <div className="circle-dash"></div>
                            <div className='empty-text-hold'>Hold tight we are processing...</div>

                        </>
                        : ""}
                    <div className="data-content">
                        {objectiveData.map((obj: any, index) => (
                            <>
                                <div key={index} className='title-container' >
                                    <div className="content">
                                        <div className='title-text'>Objective {index + 1}</div>
                                        <div className='title-value'>{obj.data}</div>
                                    </div>
                                    <div className="arrow">
                                        <RightCircleOutlined onClick={() => props.onSelect(obj.data)} />
                                    </div>
                                </div>
                                <hr></hr>
                            </>
                        ))}
                    </div>
                </div>
            </div>

        </>
    )
}