import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'reactstrap'
import { CustomButton, showToaster, toasterTypes } from '../../widgets';
import { FieldItem } from '../../widgets/fields'
import useFieldItem from '../../widgets/fields/UseFieldItem';
import ModalComponent from '../common/Modal/modal';
import { AffiliationFormElements } from './FormElements';

export default function TitleCreation(props) {
    const navigate = useNavigate();
    const initialValues = useMemo(() => (props.previousState ? props.previousState : { title: '' }), [props.previousState]);
    const [values, fields, handleChange, { validateValues }] = useFieldItem(AffiliationFormElements, initialValues, {});

    const next = () => {
        if (validateValues(["title"])) {
            showToaster(toasterTypes.ERROR, "Please enter the proper values in the fields highlighted in red");
        } else {
            props.next(values);
        }
    };

    return (
        <div className='affiliation-page'>
            <div className='page-header'>
                <img src={`${process.env.PUBLIC_URL}/images/coguide_main_logo.png`} alt='logo' className='logo' />
                <div className='header-text'>{props.projectData ? 'Edit Project' : 'Create Project'}</div>
            </div>
            <div className='affiliation-container'>
                <div className='page-text'>Title Creation
                </div>
                <Row>
                    <Col>
                        <FieldItem
                            {...AffiliationFormElements.title}
                            value={values.title}
                            onChange={(...e) => handleChange(AffiliationFormElements.title.name, ...e)}
                            touched={fields.title && fields.title.hasError}
                            error={fields.title && fields.title.errorMsg}
                        />
                    </Col>
                </Row>
                {/**Comented image - sachin UI  */}
                {/* <img src={`${process.env.PUBLIC_URL}/images/projectCreation.svg`} alt='creation' className='creation-image' /> */}
                <div className='help-text'>Need any help in Title Generation</div>
                <div className='help-text'>proceed with our</div>
                <span className='link-text' onClick={() => props.onTitle(values)}>Title Generation Wizard</span>

            </div>
            <Row className='affiliation-footer m-0'>
                <Col className='p-0'>
                    <div className='cancel-text' onClick={() => navigate('/project')}>Cancel</div>
                </Col>
                <Col className='p-0'>
                    <div className='w-100 d-flex justify-content-end'>
                        <div className='mr-3'>
                            <CustomButton type="alert-primary" onClick={() => props.previous()} className="w-100" text="Previous"></CustomButton>
                        </div>
                        <CustomButton type="primary" onClick={() => next()} className="w-100" text="Next"></CustomButton>
                    </div>
                </Col>
            </Row>
             
            <div className='page-footer'>
                <div style={{ textAlign: "center" }}>Watch video(s) to understand more about</div>
                <div style={{ textAlign: "center" }}><a target="_blank" href='https://reap.coguide.in/playvideo?rgt=https:%2F%2Ff000.backblazeb2.com%2Ffile%2FcGbgmedia%2FCP017_TitleinPICOSTformatmq4t3t3w5uzdrpp5r4pl.mp4#t=1'>How to write Scientific research title?</a></div>
            </div>
        </div>
    )
}
