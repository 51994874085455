import { Service } from 'wax-prosemirror-core';
import CustomTableStyle from './CustomTableStyle';

export default class CustomTableService extends Service {

  boot() {
    const layout = this.container.get('Layout');

    layout.addComponent('customTableCss', CustomTableStyle);
  }

  register() {
    const customTableCss = this.config.get('config.CustomTableStyle');
  }
}
