/**
 * table receives json data from htmlToJSON function
 */
export const TableData = (data, headtext) => {
  let tableRows = [];
  console.log(data)
  //create table headers difference is making heading text bold
  data.map((items, index) => {
    let tableCellArray = [];
    if (index === 0) {
      Object.keys(items).map((item, j) => {
        console.log(item ? item : "-")

        let tableCellObj1 = {
          "type": "table_cell",
          "attrs": {
            "colspan": 1,
            "rowspan": 1,
            "colwidth": null
          },
          "content": [
            {
              "type": "paragraph",
              "attrs": {
                "id": "",
                "class": "paragraph",
                "track": [],
                "group": "",
                "viewid": ""
              },
              "content": [
                {
                  "type": "text",
                  "marks": [{ "type": "strong" }],
                  "text": item ? item : "-"
                }
              ]
            }
          ]
        }
        tableCellArray.push(tableCellObj1)
      })
  

    let tableRowObj = {
      "type": "table_row",
      "content": tableCellArray
    }
    tableRows.push(tableRowObj)
  }
  })

  //create table data
  data.map((items, index) => {
    let tableCellArray = [];

    Object.keys(items).map((item, j) => {

      let tableCellObj = {
        "type": "table_cell",
        "attrs": {
          "colspan": 1,
          "rowspan": 1,
          "colwidth": null
        },
        "content": [
          {
            "type": "paragraph",
            "attrs": {
              "id": "",
              "class": "paragraph",
              "track": [],
              "group": "",
              "viewid": ""
            },
            "content": [
              {
                "type": "text",
                "text": items[item] == "" ? "-" : items[item]
              }
            ]
          }
        ]
      }
      tableCellArray.push(tableCellObj)
    })

    let tableRowObj = {
      "type": "table_row",
      "content": tableCellArray
    }
    tableRows.push(tableRowObj)

  })

  let tableData = {
    "type": "table",
    "content": [
      {
        "type": "table_body",
        "content": tableRows
      }
    ]
  }

  let paragraph = {
    "type": "heading6",
    "attrs": {
      "id": "",
      "class": "heading6",
      "track": [],
      "group": "",
      "viewid": ""
    },
    "content": [
      {
        "type": "text",
        "text": headtext || " "
      }
    ]
  };

  let finalResp = [paragraph, tableData]
  console.log(finalResp)

  return finalResp;

}

/**
 * function to create schema for table with multiple headers
 */
export const TableMultiHeaderData = (data, headers,headerText) => {
  let tableRows = [];

  //create table headers difference is making heading text bold
  headers.map((items, index) => {
    let tableCellArray = [];
    items.map((item, j) => {

      let tableCellObjhead = {
        "type": "table_cell",
        "attrs": {
          "colspan": 1,
          "rowspan": 1,
          "colwidth": null
        },
        "content": [
          {
            "type": "paragraph",
            "attrs": {
              "id": "",
              "class": "paragraph",
              "track": [],
              "group": "",
              "viewid": ""
            },
            "content": [
              {
                "type": "text",
                "marks": [{ "type": "strong" }],
                "text": item ? item : "  "
              }
            ]
          }
        ]
      }
      tableCellArray.push(tableCellObjhead)
    })

    let tableRowObj = {
      "type": "table_row",
      "content": tableCellArray
    }
    tableRows.push(tableRowObj)

  })

  //create table data
  data.map((items, index) => {
    let tableCellArray = [];

    Object.keys(items).map((item, j) => {

      let tableCellObj = {
        "type": "table_cell",
        "attrs": {
          "colspan": 1,
          "rowspan": 1,
          "colwidth": null
        },
        "content": [
          {
            "type": "paragraph",
            "attrs": {
              "id": "",
              "class": "paragraph",
              "track": [],
              "group": "",
              "viewid": ""
            },
            "content": [
              {
                "type": "text",
                "text": items[item] == "" ? "-" : items[item]
              }
            ]
          }
        ]
      }
      tableCellArray.push(tableCellObj)
    })

    let tableRowObj = {
      "type": "table_row",
      "content": tableCellArray
    }
    tableRows.push(tableRowObj)

  })

  let tableData = {
    "type": "table",
    "content": [
      {
        "type": "table_body",
        "content": tableRows
      }
    ]
  }

  let paragraph = {
    "type": "heading6",
    "attrs": {
      "id": "",
      "class": "heading6",
      "track": [],
      "group": "",
      "viewid": ""
    },
    "content": [
      {
        "type": "text",
        "text": headerText
      }
    ]
  };

  let finalResp = [paragraph, tableData]
  console.log(finalResp)

  return finalResp;
}

/**
 * function to create schema for table with multiple headers
 * for analysis data
 */
export const AnalysisTableMultiHeaderData = (data,HeadText) => {
  let tableRows = [];

  const objectWithMostKeys = data.rows.reduce((maxObj, currentObj) => {
    return Object.keys(currentObj).length > Object.keys(maxObj).length ? currentObj : maxObj;
  }, {});
  let maxItem = Object.keys(objectWithMostKeys).length;


   //create table headers difference is making heading text bold
   data.headers.map((items, index) => {
    let tableCellArray = [];
    items.map((item, j) => {
      let position = item.endPosition - item.startPosition
      let tableCellObjhead = {
        "type": "table_cell",
        "attrs": {
          "colspan":  position > 0 ? position+1 : 1,
          "rowspan":1,
          "colwidth": null
        },
        "content": [
          {
            "type": "paragraph",
            "attrs": {
              "id": "",
              "class": "paragraph",
              "track": [],
              "group": "",
              "viewid": ""
            },
            "content": [
              {
                "type": "text",
                "marks": [{ "type": "strong" }],
                "text": item.title ? item.title : "  "
              }
            ]
          }
        ]
      }
      tableCellArray.push(tableCellObjhead)
    })

    let tableRowObj = {
      "type": "table_row",
      "content": tableCellArray
    }
    tableRows.push(tableRowObj)

  })
  
  //create table data
  data.rows.map((items, index) => {
    let tableCellArray = [];

    items.map((item, j) => {
      let position = item.endPosition - item.startPosition

      let tableCellObj = {
        "type": "table_cell",
        "attrs": {
          "colspan": position > 0 ? position+1 : 0,
          "rowspan": 1,
          "colwidth": null
        },
        "content": [
          {
            "type": "paragraph",
            "attrs": {
              "id": "",
              "class": "paragraph",
              "track": [],
              "group": "",
              "viewid": ""
            },
            "content": [
              {
                "type": "text",
                "text": item.content == "" ? "-" : item.content
              }
            ]
          }
        ]
      }
      tableCellArray.push(tableCellObj)
    })

    let tableRowObj = {
      "type": "table_row",
      "content": tableCellArray
    }
    tableRows.push(tableRowObj)

  })

  let tableData = {
    "type": "table",
    "content": [
      {
        "type": "table_body",
        "content": tableRows
      }
    ]
  }

  let paragraph = {
    "type": "heading6",
    "attrs": {
      "id": "",
      "class": "heading6",
      "track": [],
      "group": "",
      "viewid": ""
    },
    "content": [
      {
        "type": "text",
        "text": HeadText
      }
    ]
  };

  let finalResp = [paragraph, tableData]
  console.log(finalResp)

  return finalResp;
}

/**
 * Input to function will be html table data
 * function converts html to json data
 * @param {*} htmlString 
 */
export const htmlToJSON = async (htmlString, classData = '') => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const table = document.querySelector(classData || '.custom-table');
  let headText = "";
  console.log(table)
  if(classData){
    headText = document.querySelector(classData + '-header').firstChild.nodeValue;
  }
  console.log(headText)
  console.log(classData)
  const headers = Array.from(table.querySelectorAll('thead th')).map(th => th.textContent.trim());
  const rows = Array.from(table.querySelectorAll('tbody tr'));

  const jsonData = rows.map(row => {
    const cells = Array.from(row.querySelectorAll('td'));
    return headers.reduce((acc, header, index) => {
      acc[header] = cells[index] ? cells[index].textContent.trim() : '';
      return acc;
    }, {});
  });
  let data = await TableData(jsonData, headText);
  return data;
};

/**
 * function to get data for multiple headers
 * function returns json data
 */
export async function parseTable(table,headerText="") {
  console.log(table)
  const headers = [];
  const rows = [];
  console.log(headerText)
  const headText = document.querySelector(headerText + '-header').firstChild.nodeValue;
  // Get the headers
  const headerRows = table.querySelectorAll("thead tr");
  headerRows.forEach((row) => {
    const cells = row.querySelectorAll("th");
    console.log(Array.from(cells).map(cell => cell.innerText.trim()))
    headers.push(Array.from(cells).map(cell => cell.innerText.trim()));
  });

  console.log(headers)
  // Get the body rows
  const bodyRows = table.querySelectorAll("tbody tr");
  bodyRows.forEach((row) => {
    const cells = row.querySelectorAll("td");
    rows.push(Array.from(cells).map(cell => cell.innerText.trim()));
  });

  // Combine headers and rows into a JSON structure
  const jsonResult = rows.map(row => {
    const rowObject = {};
    row.forEach((cell, index) => {
      // Assuming headers array is properly structured
      let header = headers[headers.length - 1][index];
      rowObject[header] = cell;
    });
    return rowObject;
  });

  let data = await TableMultiHeaderData(jsonResult, headers,headText)
  return data;
}


/**
 * function to get data for multiple headers
 * function returns json data
 */
export async function parseMultiAnalysisTable(table,headerText) {
  console.log(table)
  const tableHTML =  headerText?.current && headerText?.current;
  const headTitle = tableHTML.querySelectorAll('span');
  const headerRows = table.querySelectorAll('thead tr');
  const dataRows = table.querySelectorAll('tbody tr'); 
   
  //Extract title of the table
  let HeadText = '';
  headTitle.forEach((title) => {
    HeadText += title.innerText;
  })
  console.log(HeadText)

    // Extract multi-level headers
    let headers = [];
    // headerRows.forEach((headerRow) => {
    //   let headerData = [];
    //   const headerCells = headerRow.querySelectorAll('th');
    //   headerCells.forEach((headerCell) => {
    //     headerData.push(headerCell.innerText);
    //   });
    //   headers.push(headerData);
    // });
    headerRows.forEach((headerRow) => {
      let headerData = [];
      const headerCells = headerRow.querySelectorAll('th');
      let colIndex = 0;
      
      headerCells.forEach((headerCell) => {
        const colSpan = headerCell.colSpan || 1;
        headerData.push({
          title: headerCell.innerText,
          startPosition: colIndex,
          endPosition: colIndex + colSpan - 1
        });
        colIndex += colSpan;
      });
      headers.push(headerData);
    });

    // Extract row data based on the final header row (for simplicity)
    // let rowsData = [];
    // dataRows.forEach((row) => {
    //   let rowData = {};
    //   const cells = row.querySelectorAll('td');
    //   cells.forEach((cell, index) => {
    //     // If you want to use the most specific (last) header level
    //     const headerKey = headers[headers.length - 1][index] || `column${index + 1}`;
    //     rowData[headerKey] = cell.innerText;
    //   });
    //   rowsData.push(rowData);
    // });
     // Extract row data based on the final header row
     let rowsData = [];
     dataRows.forEach((row) => {
       let rowData = [];
       const cells = row.querySelectorAll('td');
       let cellIndex = 0;
       
       cells.forEach((cell, index) => {
         const colSpan = cell.colSpan || 1;
         const relevantHeaders = headers.map(headerRow => 
           headerRow.find(header => header.startPosition <= index && header.endPosition >= index)
         );
         
         rowData.push({
           content: cell.innerText,
           startPosition: cellIndex,
           endPosition: cellIndex + colSpan - 1,
           headers: relevantHeaders.map(header => header.title).join(' > ')
         });
         
         cellIndex += colSpan;
       });
       
       rowsData.push(rowData);
     });

    // Combine headers and rows into a structured JSON object
    const tableJson = {
      headers: headers,
      rows: rowsData,
    };

     console.log(tableJson);
  
  let dataResponse = await AnalysisTableMultiHeaderData(tableJson,HeadText)
  return dataResponse;
  //console.log(dataResponse)
}
