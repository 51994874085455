import React, { useEffect, useState } from 'react';
import { Col, Row } from "reactstrap";
import { CustomButton, showToaster, toasterTypes } from '../../widgets';
import ModalComponent from '../common/Modal/modal';
import { FieldItem } from "../../widgets/fields";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import { ProfileFormElements } from './FormElements';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../store/slices/auth';
import { deletePhoto, updateContact, updateProfile, uploadPhoto } from './apiController';

export default function ProfileDetails(props) {
    const initialValues = React.useMemo(() => (props.data.profile_title ? {
        profilePhoto: '', profileTitle: {label:props.data.profile_title,value:props.data.profile_title}, firstName: props.data.first_name, lastName: props.data.last_name} 
        : { profilePhoto: '', profileTitle: '', firstName: '', lastName: '' }), []);
    const [values, fields, handleChange, { validateValues, updateValue }] = useFieldItem(ProfileFormElements, initialValues, { onValueChange });
    const dispatch = useDispatch();
    const [image, setimage] = useState({ preview: "", raw: "" });

    useEffect(() => {
        console.log(props.data.profile_title)
        if (props.data.photo_link) {
            setimage({
                preview: props.data.photo_link,
                raw: ""
            });
        }

    }, []);

    function onValueChange(name, value, values, fieldValues = null) {
        if (name === 'profilePhoto') {
            if (value.target.files.length) {
                setimage({
                    preview: URL.createObjectURL(value.target.files[0]),
                    raw: value.target.files[0]
                });
            }
        }
        return [{
            ...values,
            [name]: value,
        }]
    }

    const checkSubmit = async () => {
        if (validateValues(["profileTitle", "firstName", "lastName"])) {
            showToaster(toasterTypes.ERROR, "Please enter the proper values in the fields highlighted in red");
        } else {
            if (values.profilePhoto) {
                if (props.data.photo_link) {
                    try {
                        dispatch(setLoading(true));
                        await deletePhoto(props.data.photo_link);
                        dispatch(setLoading(false));
                    } catch (e: any) {
                        dispatch(setLoading(false));
                    }
                }
                const formData = new FormData();
                formData.append('file', image.raw);
                try {
                    dispatch(setLoading(true));
                    let response = await uploadPhoto(formData);
                    if (response.mediaUrl) {
                        updateProfileData(response.mediaUrl);
                    }
                    dispatch(setLoading(false));
                } catch (e: any) {
                    dispatch(setLoading(false));
                }
            } else {
                updateProfileData();
            }
        }
    }

    const updateProfileData = async (profilePic = '') => {
        try {
            dispatch(setLoading(true));
            let response = await updateProfile({
                "profile_title": values.profileTitle.label,
                "first_name": values.firstName,
                "last_name": values.lastName,
                "photo_link": profilePic || props.data.photo_link
            });
            showToaster(toasterTypes.SUCCESS, response.message);
            props.onClose('callAPI');
            dispatch(setLoading(false));
        } catch (e: any) {
            dispatch(setLoading(false));
        }
    }

    return (
        <ModalComponent
            width={window.innerWidth > 700 ? "800px" : "350px"}
            isOpen={true}
            onClose={() => props.onClose()}
            header="Profile Details"
            centered={true}
            customClass='profile-modal'
            body={
                <div>
                    <Row>
                        <Col md={4} className="d-flex m-0 align-items-center">
                            <label htmlFor="upload-button">
                                {image.preview ? (
                                    <img src={image.preview} alt="dummy" width="300" height="300" />
                                ) : (
                                    <div className='profile-container'>
                                        <i className='icon-camera' />
                                    </div>
                                )}
                            </label>
                            <input
                                type="file"
                                accept="image/*"
                                id="upload-button"
                                style={{ display: "none" }}
                                onChange={(...e) => handleChange(ProfileFormElements.profilePhoto.name, ...e)}
                            />
                        </Col>
                        <Col md={8}>
                            <Row>
                                <Col md={12}>
                                    <FieldItem
                                        {...ProfileFormElements.profileTitle}
                                        value={values.profileTitle}
                                        onChange={(...e) => handleChange(ProfileFormElements.profileTitle.name, ...e)}
                                        touched={fields.profileTitle && fields.profileTitle.hasError}
                                        error={fields.profileTitle && fields.profileTitle.errorMsg}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FieldItem
                                        {...ProfileFormElements.firstName}
                                        value={values.firstName}
                                        onChange={(...e) => handleChange(ProfileFormElements.firstName.name, ...e)}
                                        touched={fields.firstName && fields.firstName.hasError}
                                        error={fields.firstName && fields.firstName.errorMsg}
                                    />
                                </Col>
                                <Col md={6}>
                                    <FieldItem
                                        {...ProfileFormElements.lastName}
                                        value={values.lastName}
                                        onChange={(...e) => handleChange(ProfileFormElements.lastName.name, ...e)}
                                        touched={fields.lastName && fields.lastName.hasError}
                                        error={fields.lastName && fields.lastName.errorMsg}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            }
            footer={
                <div>
                    <CustomButton type="primary" isDisabled={false} text="Save" onClick={() => checkSubmit()}></CustomButton>
                </div>
            }
        />
    )
}