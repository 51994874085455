
const data = {
    "type": "doc",
    "content": [
      {
        "type": "heading2",
        "attrs": {
          "id": "",
          "track": [],
          "group": "",
          "viewid": ""
        },
        "content": [
          {
            "type": "text",
            "text": "Introduction"
          }
        ]
      },
      {
        "type": "paragraph",
        "attrs": {
          "id": "",
          "class": "paragraph",
          "track": [],
          "group": "",
          "viewid": ""
        },
        "content": [
          {
            "type": "text",
            "text": "For Planning, capturing, Maintaining and Inference of evidence throughout the lifecycle of the Product or entity or concept in question."
          }
        ]
      },
      {
        "type": "paragraph",
        "attrs": {
          "id": "",
          "class": "paragraph",
          "track": [],
          "group": "",
          "viewid": ""
        },
        "content": [
          {
            "type": "text",
            "text": "coGuide’s REAP SAAS platform allows clients to define objectives, set up required protocols, plan & configure required data points, provides for continuous data collection & management and also provide configurable real time inferential analysis dashboards which consume data, generate analysis & evidence in real time."
          }
        ]
      },
      {
        "type": "paragraph",
        "attrs": {
          "id": "",
          "class": "paragraph",
          "track": [],
          "group": "",
          "viewid": ""
        }
      },
      {
        "type": "figure",
        "attrs": {
          "id": "",
          "track": [],
          "group": "",
          "viewid": ""
        },
        "content": [
          {
            "type": "image",
            "attrs": {
              "id": "75ebd563-f450-4f27-be1f-b009c7bec152",
              "src": "https://go.coguide.in/c1/download/chart_1722430809054.png",
              "alt": "",
              "title": null,
              "extraData": {},
              "fileid": null,
              "aria-describedby": "0e4c8928-b85f-4ff0-90b7-49471251d023",
              "aria-description": ""
            }
          }
        ]
      },
      {
        "type": "paragraph",
        "attrs": {
          "id": "",
          "class": "paragraph",
          "track": [],
          "group": "",
          "viewid": ""
        },
        "content": [
          {
            "type": "text",
            "text": "The platform also provides access to a diverse and top quality expert pool to guide with “evidence gap” & hand-holding of your evidence journey through its plug & play mentorship and collaboration modules."
          }
        ]
      },
      {
        "type": "heading3",
        "attrs": {
          "id": "",
          "track": [],
          "group": "",
          "viewid": ""
        },
        "content": [
          {
            "type": "text",
            "text": "Testing criteria"
          }
        ]
      },
      {
        "type": "paragraph",
        "attrs": {
          "id": "",
          "class": "paragraph",
          "track": [],
          "group": "",
          "viewid": ""
        }
      },
      {
        "type": "heading2",
        "attrs": {
          "id": "",
          "track": [],
          "group": "",
          "viewid": ""
        },
        "content": [
          {
            "type": "text",
            "text": "Report"
          }
        ]
      },
      {
        "type": "paragraph",
        "attrs": {
          "id": "",
          "class": "paragraph",
          "track": [],
          "group": "",
          "viewid": ""
        },
        "content": [
          {
            "type": "text",
            "text": "For Planning, capturing, Maintaining and Inference of evidence throughout the lifecycle of the Product or entity or concept in question."
          }
        ]
      },
      {
        "type": "paragraph",
        "attrs": {
          "id": "",
          "class": "paragraph",
          "track": [],
          "group": "",
          "viewid": ""
        },
        "content": [
          {
            "type": "text",
            "text": "coGuide’s REAP SAAS platform allows clients to define objectives, set up required protocols, plan & configure required data points, provides for continuous data collection & management and also provide configurable real time inferential analysis dashboards which consume data, generate analysis & evidence in real time."
          }
        ]
      },
      {
        "type": "paragraph",
        "attrs": {
          "id": "",
          "class": "paragraph",
          "track": [],
          "group": "",
          "viewid": ""
        }
      },
      {
        "type": "heading2",
        "attrs": {
          "id": "",
          "track": [],
          "group": "",
          "viewid": ""
        },
        "content": [
          {
            "type": "text",
            "text": "Conclusion"
          }
        ]
      }
    ]
  };
  
let parsedJSON = JSON.parse(localStorage.getItem("demoData")) || data;
export const demo = parsedJSON

