import AjaxUtil from '../../axios/axios';

export const getUserProjects = async(userNumber) => {
    try {
        return await AjaxUtil.sendRequest(`userprojects/${userNumber}`, {}, false, { method: 'get', showError: true} );
    } catch (error) {
        throw error;
    }
}

export const getUserTopics = async(courseId, userNumber) => {
    try {
        return await AjaxUtil.sendRequest(`usertopics/${userNumber}`, {}, false, { method: 'get', showError: true} );
    } catch (error) {
        throw error;
    }
}

export const deleteProject = async(userNumber, id, type) => {
    try {
        let request = ''
        if(type) {
            request = `topic/${id}`
        } else {
            request = `userproject/${userNumber}/${id}`
        }
        return await AjaxUtil.sendRequest(request, {}, false, { method: 'delete', showError: true} );
    } catch (error) {
        throw error;
    }
}

export const getCurrentPayment = async(userNumber, topicCode) => {
    try {
        return await AjaxUtil.sendRequest(`getCurrentPayment/${userNumber}/${topicCode}`, {}, false, { method: 'get', showError: true} );
    } catch (error) {
        throw error;
    }
}

export const hasPayment = async(userNumber, topicCode) => {
    try {
        return await AjaxUtil.sendRequest(`hasPayment/${userNumber}/${topicCode}`, {}, false, { method: 'get', showError: true} );
    } catch (error) {
        throw error;
    }
}

export const moveProject = async(data) => {
    try {
        return await AjaxUtil.sendRequest(`moveuserproject`, data, false, { method: 'post', showError: true} );
    } catch (error) {
        throw error;
    }
}

export const getPayment = async(userNumber, topicCode) => {
    try {
        return await AjaxUtil.sendRequest(`getCurrentPayment/${userNumber}/${topicCode}`, {}, false, { method: 'get', showError: true} );
    } catch (error) {
        throw error;
    }
}