import TextArea from "antd/es/input/TextArea"
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLoading } from "../../store/slices/auth";
import { FieldItem, FIELD_TYPES } from "../../widgets/fields";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import ModalComponent from "../common/Modal/modal"
import { CreateFormElements, CreateVariableElements } from "../dataCollection/FormElements";
import { updateInvitesStatus } from "./apiController";
import { FormElements } from "./FormElements";


export const RejectedModal = (props) =>{
    const dispatch = useDispatch();
    const [device,SetDevice] = useState(true);
    const [values, fields, handleChange, {}] = useFieldItem(FormElements, {}, {}); 
    const navigate = useNavigate();

    useEffect(() => {
        values.status = "rejected"
        console.log(values)
        if(window.innerWidth > 800){
            SetDevice(true)
        } else{
            SetDevice(false)
        }
    })

    const rejectAction = async () => {
        let data = {
            status:"rejected",
            remarks:values.reason
        }
        if(values.reason){
            try {
                dispatch(setLoading(true));
                await updateInvitesStatus(props.data[0].id,data);
                props.onClose()
                dispatch(setLoading(false));
            }  catch (e: any) {
                dispatch(setLoading(false));
            }            
        }
        //navigate("/invitereject")
    }


    
    return(
        <>
          <ModalComponent
               width={device ? "1000px" : "350px"}
               isOpen={true}
               onClose={() => props.onClose()}
               header="Rejected Reason"
               centered={true}
               customClass='invite-modal'
               body={
                <div className="invite-head">        
                        <FieldItem
                            className="reason_area"
                            type={FIELD_TYPES.TEXT_AREA}
                            {...FormElements.reason}
                            value={values.reason} 
                            onChange={(...e) => handleChange(FormElements.reason.name, ...e)}
                            touched={fields.reason && fields.reason.hasError}
                            error={fields.reason && fields.reason.errorMsg}
                        />
                </div>
               }
               footer={
                <div>
                    <Button className="reject-btn" onClick={() => rejectAction()} disabled={!values.reason ? true : false}>Reject</Button>
                </div>
            }
        />
        </>
    )
}