import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { WaxContext } from 'wax-prosemirror-core';
import { HuePicker, SketchPicker } from 'react-color';
import styled from 'styled-components';
import { AlignCenterOutlined, AlignLeftOutlined, AlignRightOutlined, BgColorsOutlined, FontColorsOutlined, FontSizeOutlined, LineHeightOutlined, TableOutlined } from "@ant-design/icons";
import { toggleMark } from 'prosemirror-commands';
import { Transaction } from "prosemirror-state";


const Wrapper = styled.div`
  font-size: 0;
  position: relative;
  z-index: 2;
  padding:0px 3px;
`;

const DropWrapper = styled.div`
  background: white;
  margin-top: 20px;
  position: absolute;
  top: 32px;
  width: max-content;
  color:black;
`;

const Highlighter = styled.div`
  cursor: pointer;
  display: inline-grid;
  height: 20px;
  margin: 5px;
  margin-top:10px;
  min-width: 20px;
`;

const HighlighterStyle = styled.div`
  cursor: pointer;
  padding:2px 5px;
  margin-top:10px;
  height:1px;
`;

const HighlighterStyleSize = styled.div`
cursor: pointer;
padding:2px 5px;
margin-top:10px;
height:20px;
display:flex;
flext-direction:row;
`;

const LineItems = styled.div`
 display:flex;
 flext-direction:column;
`;

const TextHighlightComponent = styled.div`
     color:red;
     display: flex;
     flex-direction: column;
     width: 80px;
     height:fit-content;
     padding: 10px 10px 0px;
     box-sizing: initial;
     background: rgb(255, 255, 255);
     border-radius: 4px;
     box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px, rgba(0, 0, 0, 0.15) 0px 8px 16px;
`;

const TextAlignmentComponent = styled.div`
     color:red;
     display: flex;
     flex-direction: row;
     height:35px;
     padding: 10px 10px 0px;
     box-sizing: initial;
     background: rgb(255, 255, 255);
     border-radius: 4px;
     box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px, rgba(0, 0, 0, 0.15) 0px 8px 16px;
`;

const StyledTextSize = styled.span`
  color: black;
  text-align: left;
  font-size:14px;
  margin: 4px 5px;
  cursor:pointer;
`;

const StyledText = styled.span`
  color: black;
  text-align: left;
  font-size:20px;
  margin: 1px 5px;
  cursor:pointer;
`;

const CustomTableStyle = () => {
     const { 
          app,
          pmViews: { main },
          activeViewId,
          activeView,
          placeholderPlugin
     } = useContext(WaxContext);
     const [tableStyle, SettableStyle] = useState(false);



     const updateTableCSS = () => {
          let cssStyles = 'border: 2px solid red;';
          const { state, dispatch } = activeView;
          const { tr } = state;

          // Find the table node
          const tableNode = findTable(state);
          if (!tableNode) {
               console.error('No table found in the current selection.');
               return;
          }

          const { node, pos } = tableNode;

          // Retrieve existing style and merge with new styles
          const existingStyles = node.attrs.style || '';
          const newStyles = `${cssStyles}`.trim();

          // Merge attributes
          const newAttrs = {
               style: newStyles
          };


          // Apply setNodeMarkup with forced update
          tr.setNodeMarkup(pos, null, newAttrs);

          // Dispatch the transaction with a small delay to force re-render
          setTimeout(() => dispatch(tr), 120);

          // Debugging output
          console.log('Updated node attributes after dispatch:', tr.doc.nodeAt(pos));
     };




     /**
      * update table style
      */
     // const TableStyleAction = () => {
     //      const { state, dispatch } = activeView;
     //      const { selection } = state;
     //      // Check if the selection is inside a table
     //      if (!isInTable(state)) {
     //           console.log("Selection is not inside a table.");
     //      }
     // }

     function findTable(state) {
          const { $from } = state.selection;
          for (let i = $from.depth; i > 0; i--) {
               const node = $from.node(i);
               if (node.type.name === 'table') {
                    return { node, pos: $from.before(i) };
               }
          }
          return null;
     }



     return (
          <>

               <Wrapper>
                    <Highlighter title="Custom table style">
                         <TableOutlined onClick={() => SettableStyle(!tableStyle)} />
                    </Highlighter>
                    <DropWrapper>
                         {
                              tableStyle &&
                              <TextAlignmentComponent>
                                   <StyledText title="Left" onClick={() => updateTableCSS()}>
                                        <select style={{ width: "100px" }} >
                                             <option value="left">
                                                 <hr></hr>
                                             </option>
                                             <option value="center">Center</option>
                                             <option value="right">Right</option>
                                             {/* {
                                                  FontFamilyList.map(item => {
                                                       return <option value={item.value}>{item.label}</option>

                                                  })
                                             } */}
                                        </select>
                                   </StyledText>
                              </TextAlignmentComponent>
                         }
                    </DropWrapper>
               </Wrapper>
          </>
     )
}

export default CustomTableStyle;