import { Menu } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { setLoading } from '../../store/slices/auth';
import { showToaster, toasterTypes } from '../../widgets';
import Tabs from '../sampleSize/Tabs';
import PeopleInvite from './PeopleInvite';
import PeopleInviteMobile from './PeopleInviteMobile';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { TabsData } from '../../utils/TabsData';
// import { deleteAward, deleteEducation, deleteResearchProfile, deleteSkillSet, deleteWorkExperience, getProfileDetails } from './apiController';

export default function People(props) {
	const dispatch = useDispatch();
	const [profile, setprofile] = useState<any | null>(null);
	const [popupType, setpopupType] = useState('');
	const [selectedIndex, setselectedIndex] = useState(null);
	const [projectData, setprojectData] = useState<any | null>(null);
	const location = useLocation();
	const navigate = useNavigate();
	


	useEffect(() => {
		let tempState: any = location.state;
		console.log(tempState)
		setprojectData(tempState);
	}, []);



	return (
		<>
			{window.innerWidth > 750 ?
				<div className='people-screen'>

					<>
						<Row className='page-header m-0'>
							<Col className={window.innerWidth > 750 ? "project-title" : "project-title-mob"} md={12}>
								<ArrowLeftOutlined style={{ color: "#fff", fontSize: "24px", margin: "0px 10px" }} onClick={() => window.innerWidth > 750 ? navigate("/project") : navigate("/projectmobile")} />
								{projectData?.TopicDesc || projectData?.topicdesc}
							</Col>
							<Col className='p-0'></Col>
						</Row>
						<Tabs data={TabsData} selectedTab={4} projectData={projectData} />
					</>

					<PeopleInvite />


				</div>
				:
				<div className='people-screen-mob'>
					<PeopleInviteMobile data={location.state} />
				</div>
			}


		</>
	)
}
