import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { CurrentUser } from '../../store/slices/auth';

export default function Success(props) {
    const [profile, setprofile] = useState<any | null>(null);
    const navigate = useNavigate();
    const location = useLocation();
    let projectData: any = location.state;
  

    useEffect(() => {
        console.log(projectData)
        let userData = localStorage.getItem('coguide_user');
        if (userData) {
            let currentUser: CurrentUser = JSON.parse(userData);
            setprofile(currentUser);
        }
    }, []);


    return (

        <>

            <div className="project-success-container-mob">
                <div className="success-image-sec">
                    <div className="success-checkmark">
                        <div className="check-icon">
                            <span className="icon-line line-tip"></span>
                            <span className="icon-line line-long"></span>
                            <div className="icon-circle"></div>
                            <div className="icon-fix"></div>
                        </div>
                    </div>
                </div>

                <div className="sucess-message">
                    <div className='message-invite'>Hello <span >{profile?.FullName},</span></div>

                    <span className="message-invite">Project {projectData.action == "update" ? 'Updated' : 'Created'}</span>
                </div>
                <div className="sucess-message-txt">
                    <span className="message-invite-txt">Successfully</span>
                </div>

                <div className="sucess-message-email">
                    <div className='affiliation-container'>
                        <h5>Project Details</h5>
                        <div className='title-text'>Title: {projectData.data.title.title}</div>
                        <span>Objectives:</span>
                        {projectData.data.objective.map((obj, index) => (
                            <div key={index} className='objective-text'>{index + 1}. {obj}</div>
                        ))}
                    </div>
                </div>
                <div className="timer-sec">
                    <Button onClick={() => navigate('/project')}>View Project</Button>
                </div>

            </div>
        </>
    )
}
