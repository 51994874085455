import { Card, CardBody, CardFooter, CardHeader } from "reactstrap";
import { CustomButton } from "../../widgets";
import { useEffect } from "react";
import { GroupOutlined, SettingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

export default function AnalysisCard(props) {
    const { data, onAction } = props;
    const navigate = useNavigate();

    useEffect(() => {
        console.log(props.data)
    }, [])
    

    return (
        <>
            <Card className="analysis-card">
                <CardHeader>
                    <span className="title">{data.form_name}</span>
                </CardHeader>
                <CardBody>
                    <span className="total-records">Total Records: </span>
                    <span className="total-records">{data.total_responses}</span>
                </CardBody>
                <CardFooter className="footer-card">
                    <div className="footer-action">
                        <div className="group">
                            <div className="grouping">
                                <GroupOutlined className="grouping" />
                            </div>
                            <span className="groupingtext" onClick={() => onAction(6, data)}>Grouping</span>
                        </div>
                        <div className="anlysis-parent">
                            <div className="analysis">
                                <SettingOutlined className="setting" />
                            </div>
                            <span className="analysistext" onClick={() => onAction(9, data)}>Analysis</span>
                        </div>
                    </div>
                </CardFooter>
            </Card>
        </>
    )
}