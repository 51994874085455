export const QUESTIONHEAD = "Watch video(s) to understand more about ";
export const QUESTION1LINK = "https://reap.coguide.in/playvideo?rgt=https://f000.backblazeb2.com/file/cGbgmedia/Bot6_DiagnosticAccuracystudya5m1zcv5drxckn42gb50.mp4#t=1";
export const QUESTION1TITLE = "What is diagnostic Accuracy study?";
export const QUESTION2LINK = "https://reap.coguide.in/playvideo?rgt=https:%2F%2Ff000.backblazeb2.com%2Ffile%2FcGbgmedia%2FCP020_Interventionstudyiwn1dxkjqd614m58f0fy.mp4#t=2";
export const QUESTION2TITLE = "What is intervention study?";
export const QUESTION3LINK = "https://reap.coguide.in/playvideo?rgt=https://f000.backblazeb2.com/file/cGbgmedia/Observationalstudies7whp6b5sxe7bujtsuuom.mp4";
export const QUESTION3TITLE = " Observational Studies?";
export const QUESTION4LINK = "https://reap.coguide.in/playvideo?rgt=https://f000.backblazeb2.com/file/cGbgmedia/CP021_Randomizationzwk1b182tvjzjpezi4hx.mp4";
export const QUESTION4TITLE = "What is randomization/random allocation?";
export const QUESTION6LINK = "https://reap.coguide.in/playvideo?rgt=https://f000.backblazeb2.com/file/cGbgmedia/CP022_Allocationconcealmentcegta5m1zcv5drxckn42.mp4";
export const QUESTION6TITLE = "Allocation concealment?";
export const QUESTION7LINK = "https://reap.coguide.in/playvideo?rgt=https://f000.backblazeb2.com/file/cGbgmedia/CP023_BlindingorMaskinggbryo0wbbyx32hfgwgo0.mp4";
export const QUESTION7TITLE = "Bliding/Masking?";

export const CROSSSECTIONSTUDYTITLE = "Cross sectional study";
export const CROSSSECTIONSTUDYLINK = "https://reap.coguide.in/playvideo?rgt=https://f000.backblazeb2.com/file/cGbgmedia/crosssectionala5m1zcv5drxckn42gb50.mp4";


export const COHORTSTUDYTITLE = "Cohort study";
export const COHORTSTUDYLINK = "https://reap.coguide.in/playvideo?rgt=https://f000.backblazeb2.com/file/cGbgmedia/cohort_lowe0133ols6k1hh2gdnyxx.mp4";

export const CASECONTROLTITLE = "case control study";
export const CASECONTROLLINK = "https://reap.coguide.in/playvideo?rgt=https://f000.backblazeb2.com/file/cGbgmedia/EV01_CaseControlStudyzwk1b182tvjzjpezi4hx.mp4";

export const RANDOMCONTROLTITLE = "Randomized controlled trial (RCT)";
export const RANDOMCONTROLLINK = "https://reap.coguide.in/playvideo?rgt=https://f000.backblazeb2.com/file/cGbgmedia/RCTvi7hvszwk1b182tvjzjp.mp4";
export const DASLINK1 = "https://reap.coguide.in/playvideo?rgt=https://f000.backblazeb2.com/file/cGbgmedia/Precision_low3eql4yyp7fyilbb72nrt.mp4";
export const DASLINK2 = "https://reap.coguide.in/playvideo?rgt=https:%2F%2Ff000.backblazeb2.com%2Ffile%2FcGbgmedia%2FCP007_Howtogetexpectedvaluesforsamplesizecalculation2wxpq19wu985c8gbzsxm.mp4";
export const DASTITLE1 = "Precision & confidence level";
export const DASTITLE2 = "How to get expected values for sample size calculation?";
export const NDASLINK1 = "https://reap.coguide.in/playvideo?rgt=https:%2F%2Ff000.backblazeb2.com%2Ffile%2FcGbgmedia%2FBot2_TypeofErrorsls6k1hh2gdnyxxvi7hvs.mp4";
export const NDASTITLE = "Understand errors";
export const DCSS1Link = "https://reap.coguide.in/playvideo?rgt=https:%2F%2Ff000.backblazeb2.com%2Ffile%2FcGbgmedia%2FBot1_Typeofvariablesfpllngzieyoh43e0133o.mp4";
export const DCSS1TITLE = "Understand type of variables";


export const Samplesize = "The following videos help in Understanding Sample size";
export const SSV1_name = "Why to calculate sample size?"
export const SSV1 = "https://reap.coguide.in/playvideo?rgt=https://f000.backblazeb2.com/file/cGbgmedia/whysample_lowuisgdhqo2eggdmcwhc7k.mp4";
export const SSV2_name = "Sample size essentials";
export const SSV2 = "https://reap.coguide.in/playvideo?rgt=https://f000.backblazeb2.com/file/cGbgmedia/Samplesize_essentialinformation_Part2861tzq18emrtj6gveuwf.mp4";
export const SSV3_name = "How to get expected values for sample size calculation?";
export const SSV3 = "https://reap.coguide.in/playvideo?rgt=https://f000.backblazeb2.com/file/cGbgmedia/CP007_Howtogetexpectedvaluesforsamplesizecalculation2wxpq19wu985c8gbzsxm.mp4";


export const QUESTIONSLIST = [{
    "key": ['observational'],
    "title": "Observational studies",
    "link": "https://reap.coguide.in/playvideo?rgt=https://f000.backblazeb2.com/file/cGbgmedia/Observationalstudies7whp6b5sxe7bujtsuuom.mp4"
},
{
    "key": ['randomization'],
    "title": "What is randomization/random allocation?",
    "link": "https://reap.coguide.in/playvideo?rgt=https://f000.backblazeb2.com/file/cGbgmedia/CP021_Randomizationzwk1b182tvjzjpezi4hx.mp4"
},
{
    "key": ['diagnostic accuracy'],
    "title": "What is diagnostic Accuracy study?",
    "link": "https://reap.coguide.in/playvideo?rgt=https://f000.backblazeb2.com/file/cGbgmedia/Bot6_DiagnosticAccuracystudya5m1zcv5drxckn42gb50.mp4#t=1"
},
{
    "key": ['intervention'],
    "title": "What is intervention study?",
    "link": "https://reap.coguide.in/playvideo?rgt=https://f000.backblazeb2.com/file/cGbgmedia/Typesofinterventionalstuideslrtmj7ppvjknidxl1p7z.mp4"
},
{
    "key": ['concealment',"allocation"],
    "title": "Allocation concealment?",
    "link": "https://reap.coguide.in/playvideo?rgt=https://f000.backblazeb2.com/file/cGbgmedia/CP022_Allocationconcealmentcegta5m1zcv5drxckn42.mp4"
},{
    "key": ['blinding','masking',"blinded"],
    "title": "Bliding/Masking?",
    "link": "https://reap.coguide.in/playvideo?rgt=https://f000.backblazeb2.com/file/cGbgmedia/CP023_BlindingorMaskinggbryo0wbbyx32hfgwgo0.mp4"
}]