import { FIELD_TYPES } from "../../widgets/fields";

export const AffiliationFormElements = {
    country: {
        name: "country",
        placeholder: "Select",
        label: "Country",
        type: FIELD_TYPES.DROP_DOWN,
        isMandatory: true
    },
    state: {
        name: "state",
        placeholder: "Select",
        label: "State",
        type: FIELD_TYPES.DROP_DOWN,
        isMandatory: true
    },
    city: {
        name: "city",
        placeholder: "Select",
        label: "City",
        isMandatory: true
    },
    college: {
        name: "college",
        placeholder: "Select",
        label: "Institution Name",
        type: FIELD_TYPES.DROP_DOWN,
        isMandatory: false
    },
    projectType: {
        name: "projectType",
        placeholder: "Select",
        label: "Project Type",
        type: FIELD_TYPES.DROP_DOWN,
        isMandatory: true
    },
    funding: {
        name: "funding",
        placeholder: "Select",
        label: "Funding",
        type: FIELD_TYPES.DROP_DOWN,
        values: [{label: 'Yes', value: 'yes'}, {label: 'No', value: 'no'}],
        isMandatory: true
    },
    fundType: {
        name: "fundType",
        placeholder: "Select",
        label: "Fund Type",
        type: FIELD_TYPES.DROP_DOWN,
        values: [{label: 'Self', value: 'self'}, {label: 'Extramural', value: 'extramural'}, {label: 'Intramural', value: 'intramural'}],
        isMandatory: true
    },
    quantity: {
        name: "quantity",
        placeholder: "Enter",
        label: "Quantum of Funding",
        isMandatory: true
    },
    fundingAgency: {
        name: "fundingAgency",
        placeholder: "Select",
        label: "Funding Agency",
        isMandatory: true
    },
    approvalStatus: {
        name: "approvalStatus",
        placeholder: "Select",
        label: "Approval Status",
        type: FIELD_TYPES.DROP_DOWN,
        values: [{label: 'Approved', value: 'approved'}, {label: 'Rejected', value: 'rejected'}, {label: 'In Review', value: 'in_review'}],
        isMandatory: true,
        menuPlacement: 'top'
    },
    title: {
        name: "title",
        placeholder: "Sample",
        label: "Title",
        type: FIELD_TYPES.TEXT_AREA,
        isMandatory: true
    },
    objective: {
        name: "objective",
        placeholder: "Sample",
        label: "Objective",
        type: FIELD_TYPES.TEXT_AREA,
        isMandatory: true
    },
    keyword: {
        name: "keyword",
        placeholder: "Select",
        label: "Keyword",
        type: FIELD_TYPES.MULTI_TEXT,
        isMandatory: true
    },
    strictmode: {
        name: "strictmode",
        placeholder: "",
        label: "Enable Strict Mode",
        type: FIELD_TYPES.BOOLEAN,
        isMandatory: false
    }
};

export const TitleElements = {
    studyGroup: {
        name: "studyGroup",
        placeholder: "Enter",
        label: "Number of study groups",
        inputType: 'number',
        isMandatory: true
    },
    interventionGroup: {
        name: "interventionGroup",
        placeholder: "Select",
        label: "Intervention Group",
        type: FIELD_TYPES.DROP_DOWN,
        values: [{label: 'Yes', value: 'yes'}, {label: 'No', value: 'no'}],
        isMandatory: true
    },
    studyPopulation: {
        name: "studyPopulation",
        placeholder: "Enter",
        label: "Study Population (on whom you do the study)",
        isMandatory: true
    },
    outcome: {
        name: "outcome",
        placeholder: "Enter",
        label: "Name of outcome",
        isMandatory: true
    },
    groupName: {
        name: "groupName",
        placeholder: "Enter",
        label: "Intervention group/Study group",
        isMandatory: true
    },
    groupType: {
        name: "groupType",
        placeholder: "Enter",
        label: "Control/Comparator Group",
        isMandatory: true
    },
    studyDesign: {
        name: "studyDesign",
        placeholder: "Enter",
        label: "Mention the study design (eg. Cross Sectional, Cohort)",
        isMandatory: true
    },
    timeline: {
        name: "timeline",
        placeholder: "Enter",
        label: "Timeline of study",
        isMandatory: false
    },
}

export const ObjectiveElements = {
    phrase: {
        name: "phrase",
        placeholder: "Select",
        label: "Choose the right phrase to describe your intention",
        type: FIELD_TYPES.RADIO_BUTTON,
        isListed: true,
        values: [{label: 'To Compare', value: 'To Compare'}, {label: 'To Determine', value: 'To Determine'}, {label: 'To Assess', value: 'To Assess'}, {label: 'To Estimate', value: 'To Estimate'}, {label: 'To Analyze', value: 'To Analyze'}],
        isMandatory: true
    },
    trying: {
        name: "trying",
        placeholder: "Enter",
        type: FIELD_TYPES.TEXT_AREA,
        isMandatory: true
    },
    interventionGroup: {
        name: "interventionGroup",
        placeholder: "Enter",
        label: "Name of Intervention Group (if applicable)"
    },
    comparatorGroup: {
        name: "comparatorGroup",
        placeholder: "Enter",
        label: "Name of Comparator Group (if applicable)"
    },
    studyPopulation: {
        name: "studyPopulation",
        placeholder: "Enter",
        label: "What is the study population/disease condition?",
        type: FIELD_TYPES.TEXT_AREA,
        isMandatory: true
    },
    planning: {
        name: "planning",
        placeholder: "Enter",
        label: "How are you planning to measure the outcome (What is/are tool(s)/method(s) used?)",
        type: FIELD_TYPES.TEXT_AREA
    },
    timeline: {
        name: "timeline",
        placeholder: "Enter",
        label: "Is there any specific timeline you are looking at (For example at post operative period/at 1 month/at 3 months/at 1 year etc.)",
        type: FIELD_TYPES.TEXT_AREA
    }
}