import { CustomButton } from "../../widgets";
import ModalComponent from "../common/Modal/modal";
// import { Button, Checkbox, Form, Input, Select } from 'antd';
import { Col, Row } from "reactstrap";
import { FormElements } from "../home/FormElements";
import { FieldItem, FIELD_TYPES } from "../../widgets/fields";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import { useEffect, useRef, useState } from "react";
import { SearchPeopleForm } from "../dataCollection/FormElements";
import InviteCard from "./InviteCard";
import { Button, Form, Image, ListGroup } from "react-bootstrap";
import { getUsersList, getUserTypes, sendUserInvite } from "./apiController";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoading } from "../../store/slices/auth";
import { CloseOutlined, LeftOutlined } from "@ant-design/icons";
import { Loader } from "../common/FullScreenLoader/fullScreenLoader";
import { getRoles } from "../dataCollection/apiController";


export default function PeopleInviteListMobile(props){
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [emailValue, SetEmail] = useState("");
    const [typeValue, SetType] = useState("");
    const [userTypes,SetUserTypes] = useState<any | []>([]);
    const [userList,SetUserList] = useState<any | []>([]);
    const [sentRequest, SetsentRequest] = useState(false);
    const [getProjectusers,SetProjectUsers] = useState<any>([]);
    const [selectedType, SetselectedType] = useState("");
    const [pageno, Setpageno] = useState(1);
    const [perPage, SetperPage] = useState(20);
    const listInnerRef = useRef<HTMLInputElement>(null);
    const location = useLocation();
    const [projectData,SetProjectData] = useState<any>(location.state);

    const FormElements = {
		search: {
			name: "search",
			placeholder: "Search",
			label: "",
			type: FIELD_TYPES.SEARCH,
			isMandatory: false,
		},
	}
	const [values, fields, handleChange, { validateValues, reset, updateValue,updateError }] = useFieldItem(FormElements, {}, { onValueChange });  

    useEffect(()=>{
        console.log(projectData)
        fetchIntialData()
    },[])

     useEffect(()=>{
    },[userTypes,userList]);

    /**
     * get intail data of doctypes and users profiles
     */
    const fetchIntialData = async()=>{
        try {
            dispatch(setLoading(true));
            const usertypes = await getRoles();
            const userlist = await getUsersList("",pageno,perPage);
            SetUserList(userlist.data);
            SetUserTypes(usertypes.data);
            dispatch(setLoading(false));
        } catch (e : any) {
            throw e
        }
    }

    const getValues = () =>{
        console.log(values.search)
    }

    /**
     * send invite with email and user type
     * @param e 
     */
    const inviteAction = async(e)=>{
        e.preventDefault();
        //console.log(e.target);
        try{
            const response = await sendUserInvite({email:emailValue,invite_type:typeValue});
            if(response.success){
                SetsentRequest(true);
            }
        }catch(a:any){
            throw e;
        }
      
    }

    const setRequestSent = () =>{
        SetsentRequest(!sentRequest);
    }

    /**
     * redirect to profile page
     * @param value 
     */
    const redirectProfile = (value) =>{
        console.log(projectData)
        if(selectedType != ""){ 
         navigate('/peopleprofilemobile',{ state: { userid: value.id, data:value, pagetype:"invite", projectId: projectData, usertype:selectedType} })
        }
    }

    /**
     * get selected value for usertype
     */
    const selectUserType = (value) =>{
        SetselectedType(value);
    }

     /**
     * 
     * @param name 
     * @param value 
     * @param values 
     * @param fieldValues 
     * @returns 
     */
      async function onValueChange (name, value, values, fieldValues=null) {
        if(name == "search"){
            updateValue({search:value})

            try {
                const userlist = await getUsersList(value,1,50);
                SetUserList(userlist.data);
                Setpageno(1)
                SetperPage(perPage)
    
            } catch (e:any) {
                throw e
            }
        }else{
            updateError('usertype', {hasError: false, errorMsg: ""})

            updateValue({usertype:value})
        }
       
		return [{
			...values,
			[name]: value,
		}]
    }

    /**
     * scroll to bottom get next set of data
     */
     const onScroll = async () => {
      
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            //alert(Math.round(scrollTop + clientHeight)+"--"+scrollHeight)
           if (Math.round(scrollTop + clientHeight)  === scrollHeight) {
                try{
                    const userListData = await getUsersList("",pageno+1,perPage);
                    Setpageno(pageno+1)
                    SetperPage(perPage)
                    let tempArray : any = [];
                    tempArray = [...userList,...userListData.data] 
                    SetUserList(tempArray)
                }catch(e:any){
                    throw e;
            }
              
            }
        }
    };


    return(
        <>
        <div className="people-invite-container-mob">
       
            <div className="search-invite-mob">
            <div className="info-bar">
                    <CloseOutlined className="arrow-back" onClick={() => navigate('/people',{ state: {projectid: projectData.projectid,projectdata: projectData}})}/>
                    {/* <span className="info-project-detail">Profile Details</span> */}
                    </div>
            <div className="invite-content-mob">
                        <FieldItem
                            className="search-text-mob"
                            {...FormElements.search}
                            value={values.search}
                            onChange={(...e) => handleChange(FormElements.search.name, ...e)}
                            touched={fields.search && fields.search.hasError}
                            error={fields.search && fields.search.errorMsg}
                        />
                      
                </div>
            </div>

            <div className="invite-type-bar-mob">
                <ul className="skillsets">
                    {
                        userTypes.map(item =>{
                            return <li className={selectedType == item.name ? "selected-skill" : "skill"} onClick={() => selectUserType(item.name)}>{item.name}</li> 
                        })
                    }
                </ul>
                { selectedType ? "" : <span className="error-msg">Please select user type</span>}
            </div>

             <div className="invite-user-list-mob">

                <b>{userList && userList?.length} Members</b>
             <ListGroup className="project-user-list-mob" onScroll={() => onScroll()} ref={listInnerRef}>
                   
                            <ListGroup.Item>  
                                {
                                    userList && userList?.map((item,i) =>{
                                         
                                        return <Row>
                                        <Col>
                                        <div className="people-message-mob">
                                        <div className="invite-pic-name">
                                            <div className="msg-icon">
                                                <Image className="profile-img" src={item?.photo_link || ""} />
                                            </div>                             
                                            <div className="people-detail">
                                                <span className="mentor-name">{item?.display_name ? item.display_name : item?.first_name ? item.first_name : item?.full_name}</span>
                                                <span className="mentor-profession">{item?.AboutMe}</span>
                                            </div>
                                            </div>  
                                            
                                        </div> 
                                        </Col>
                                        <Col>
                                        <div className="invite-btn-mob">         
                                            <Button className="invite-btn" onClick={() => redirectProfile(item)}>Invite</Button>
                                            </div> 
                                        </Col>
                                    </Row>            
                                 })
                                } 
                                                       
                            </ListGroup.Item>                     
                    
                </ListGroup> 
             </div>
        </div> 
        </>
    );
} 