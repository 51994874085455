import { RightOutlined } from '@ant-design/icons';
import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { CurrentUser, setLoading } from '../../store/slices/auth';
import { CustomButton, showToaster, toasterTypes } from '../../widgets';
import { FieldItem, FIELD_TYPES } from '../../widgets/fields';
import useFieldItem from '../../widgets/fields/UseFieldItem';
import ModalPopup from '../../widgets/modal';
import { getInvitesReceived } from '../people/apiController';
import InviteeCard from '../people/inviteeCard';
import { getProfileDetails } from '../profile/apiController';
import ProfileDetails from '../profile/ProfileDetails';
import ProjectDetail from '../projectDetails';
import { getPermissionData } from '../projectDetails/apiController';
import { getPieactive, getPieactiveReg } from '../submissions/apiController';
import { RightArrow } from '../surveyForm/Arrow';
import { deleteProject, getCurrentPayment, getPayment, getProjects, getUserProjects, getUserTopics, hasPayment, moveProject } from './apiController';
import ProjectCard from './ProjectCard';
import ProjectCardMentor from './ProjectCardMentor';
import Tabs from './Tabs';
import ProjectCardCollab from './ProjectCardCollab';

interface CurrentProjects {
	CreatedOn: string,
	PGCourse: string,
	TopicBigDesc: any
	TopicDesc: string
	TopicID: string
	TopicStatus: string
	TopicCode: string
	id: string
	UserNumber: string,
	type: string
}

export default function Project(props) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [selectedTab, setselectedTab] = useState(0);
	const [eventInvites, SetEventInvites] = useState<any>([]);
	const [currentProjects, setcurrentProjects] = useState([]);
	const [currentInvites, setcurrentInvites] = useState([]);
	const [userTopics, setuserTopics] = useState([]);
	const [popupType, setpopupType] = useState('');
	const [selectedData, setselectedData] = useState<CurrentProjects | null>(null);
	const [userData, setuserData] = useState<CurrentUser | null>(null);
	const [profile, setprofile] = useState<any | null>(null);
	const [pageno, Setpageno] = useState(1);
	const [perpage, Setperpage] = useState(50);
	const [mentorProject, SetmentorProjects] = useState([]);
	const [collabProject, SetcollabProjects] = useState([]);
	const [mentorCount, SetmentorCount] = useState(0);
	const [collabCount, SetcollabCount] = useState(0);
	const [inviteCount, SetinviteCount] = useState(0);
	const location = useLocation();

	useEffect(() => {

		let temp: any = localStorage.getItem("coguide_user");
		if (temp) {
			temp = JSON.parse(temp);
			setuserData(temp);
			fetchCurrentProjects(temp);
			//fetchCurrentTopics(temp);
		}
		let dataValue:any= location.state;

		if (dataValue && dataValue?.type == "document") {
			localStorage.setItem('projectData',JSON.stringify(dataValue.data))
			navigate('/document', { state: dataValue.data });
		}else if(location.state){
			localStorage.setItem('projectData',JSON.stringify(location.state))
			navigate('/dataCollection', { state: location.state });
		}
	}, []);

	const FormElements = {
		search: {
			name: "search",
			placeholder: "Search",
			label: "",
			type: FIELD_TYPES.SEARCH,
			isMandatory: false,
		},
	}
	const [values, fields, handleChange] = useFieldItem(FormElements, {}, {});

	const createProject = () => {
		navigate('/createProject');
	}

	/**
	 * fetch list of invites
	 * @param data 
	 */
	const fetchCurrentInvites = async (data) => {
		try {
			dispatch(setLoading(true));
			let receviedInvites = await getInvitesReceived(pageno, perpage)
			setcurrentInvites(receviedInvites?.users || []);
			SetinviteCount(receviedInvites["total-count"] ? receviedInvites["total-count"] : 0)
			dispatch(setLoading(false));
		} catch (e: any) {
			setcurrentInvites([]);
			dispatch(setLoading(false));
		}
	}


	/**
	 * fetch list of projects
	 * @getPieactive api to get list of reg events
	 * @getPieactiveReg api to get he RE event counts 
	 * @param data 
	 */
	const fetchCurrentProjects = async (data) => {
		try {
			dispatch(setLoading(true));
			let projects = await getUserProjects(data.UserNumber);

			console.log(projects?.data)
			setcurrentProjects(projects?.data || []);
			fetchCurrentInvites("");
			fetchProfileDetails();
			fetchUserProjectsCollab();
			fetchUserProjectsMentor();
			dispatch(setLoading(false));
		} catch (e: any) {
			setcurrentProjects([]);
			dispatch(setLoading(false));
		}
	}

	/**
	 * get mentor projects
	 */
	const fetchUserProjectsMentor = async () => {
		try {
			dispatch(setLoading(true));
			let projects = await getProjects(pageno, perpage, "mentor");
			let temp: any = localStorage.getItem("coguide_user");
			let userid: any = JSON.parse(temp);
			//console.log(temp1.id)
			projects?.users && projects?.users.map(async (item, i) => {
				const permissionData = await getPermissionData("mentor", userid.id, item.id);
				item.permissions = permissionData.data
			})
			SetmentorProjects(projects?.users || []);
			SetmentorCount(projects["total-count"] ? projects["total-count"] : 0)
			dispatch(setLoading(false));

			console.log(projects?.users)

		} catch (e: any) {
			SetmentorProjects([]);
			dispatch(setLoading(false));
		}
	}

	/**
	 * get collab projects
	 */
	const fetchUserProjectsCollab = async () => {
		try {
			dispatch(setLoading(true));
			let projects = await getProjects(pageno, perpage, "collaborator");
			let temp: any = localStorage.getItem("coguide_user");
			let userid: any = JSON.parse(temp);
			//console.log(temp1.id)
			projects?.users && projects?.users.map(async (item, i) => {
				const permissionData = await getPermissionData("collaborator", userid.id, item.id);
				item.permissions = permissionData.data
			})
			console.log(projects?.users)
			SetcollabCount(projects["total-count"] ? projects["total-count"] : 0)
			SetcollabProjects(projects?.users || []);
			dispatch(setLoading(false));
		} catch (e: any) {
			SetcollabProjects([]);
			dispatch(setLoading(false));
		}
	}


	/**
	 * fetch profile data
	 */
	const fetchProfileDetails = async () => {
		try {
			dispatch(setLoading(true));
			let response = await getProfileDetails({ userid: "" });
			setprofile(response);
			dispatch(setLoading(false));
		} catch (e: any) {
			dispatch(setLoading(false));
		}
	}

	/**
	 * get the user topic list
	 * @param data 
	 */
	const fetchCurrentTopics = async (data) => {
		try {
			dispatch(setLoading(true));
			let topics = await getUserTopics(data.PGCourse, data.UserNumber);
			setuserTopics(topics?.data || []);
			dispatch(setLoading(false));
		} catch (e: any) {
			setuserTopics([]);
			dispatch(setLoading(false));
		}
	}

	/**
	 * delete action for projects
	 */
	const onDelete = async () => {
		try {
			dispatch(setLoading(true));
			await deleteProject(userData?.UserNumber, selectedData?.TopicID || selectedData?.TopicCode, selectedTab);
			showToaster(toasterTypes.SUCCESS, 'Deleted Successfully');
			setpopupType('');
			setselectedData(null);
			dispatch(setLoading(false));
			if (selectedTab) {
				fetchCurrentTopics(userData);
			} else {
				fetchCurrentProjects(userData);
			}
		} catch (e: any) {
			setpopupType('');
			setselectedData(null);
			dispatch(setLoading(false));
		}
	}

	const onSampleSize = (data, type) => {
		setselectedData(data);
		//setpopupType('sampleSize');
		sampleSize(data, type);
	}

	const getDropDown = (data) => {
		data.Input.map((item, i) => {
			if (item.Type == "select") {
				let DropDownNew: any = [];
				item.DropDown.map((value, j) => {
					let indexName = value.map(e => e.Key).indexOf('name');
					let indexLabel = value.map(e => e.Key).indexOf('label');
					let indexValue = value.map(e => e.Key).indexOf('value');
					DropDownNew.push({ name: value[indexName].Value, label: value[indexLabel].Value, value: value[indexValue].Value })
				})
				item.DropDown = DropDownNew
			} else {

			}
		})
		return data
	}


	/**
	 * onclick study module
	 * @param data 
	 */
	const onStudyModule = async (data, type) => {
		try {
			if (type == "owner") {
				data.isOwner = true;
			} else {
				data.isOwner = false;
			}
			let dummyData: any = data;
			dispatch(setLoading(true));
			dummyData = await checkPayment(dummyData);
			let response = await hasPayment(dummyData?.UserNumber, dummyData?.TopicCode || dummyData?.TopicID);
			if (response.data) {
				let responseData = await getDropDown(dummyData)
				setpopupType('');
				setselectedData(null);
				navigate('/studyModule', { state: responseData });
			} else {
				setpopupType('subscriptions');
			}
			dispatch(setLoading(false));
		} catch (e: any) {
			setpopupType('');
			setselectedData(null);
			dispatch(setLoading(false));
		}
	}

	/**
	 * payment verification
	 * @param dummyData 
	 * @returns 
	 */
	const checkPayment = async (dummyData) => {
		let paymentData = await getCurrentPayment(dummyData?.UserNumber, dummyData?.TopicCode || dummyData?.TopicID);
		console.log("TOpics::::", paymentData)
		if (paymentData.data === "no_active_plan" || paymentData.data === "free_trial") {
			dummyData.type = 'can_buy';
		} else if (paymentData.data === "basic") {
			dummyData.type = 'can_upgrade';
		} else {
			dummyData.type = '';
		}
		return dummyData;
	}

	const sampleSize = async (data, type) => {
		try {
			if (type == "owner") {
				data.isOwner = true;
			} else {
				data.isOwner = false;
			}
			dispatch(setLoading(true));
			let dummyData: any = cloneDeep(data);
			dummyData = await checkPayment(dummyData);
			let response = await hasPayment(dummyData?.UserNumber, dummyData?.TopicCode || dummyData?.TopicID);
			if (response.data) {
				setpopupType('');
				setselectedData(null);
				console.log("Index:::::::", dummyData)
				localStorage.setItem('projectData',JSON.stringify(dummyData))
				navigate('/sampleSize', { state: dummyData });
			} else {
				setpopupType('subscriptions');
			}
			dispatch(setLoading(false));
		} catch (e: any) {
			setpopupType('');
			setselectedData(null);
			dispatch(setLoading(false));
		}
	}

	/**
	 * onclick data collection check for has payment done
	 * if true navigate to datacollection 
	 * if No pop up with payment option
	 * @param obj 
	 */
	const onDataEntry = async (obj, type) => {
		console.log(obj)
		if (type == "owner") {
			obj.isOwner = true;
		} else {
			obj.isOwner = false;
		}
		let usersData = localStorage.getItem("coguide_user");
		let userId = "";
		if (usersData) {
			let currentUser: CurrentUser = JSON.parse(usersData);
			userId = currentUser.UserNumber;
		}

		try {
			dispatch(setLoading(true));
			let response = await hasPayment(userId, obj?.TopicID);
			if (response.data) {
				localStorage.setItem('projectData',JSON.stringify(obj))
				navigate('/dataCollection', { state: obj });
			} else {
				setpopupType('subscriptions');
			}
			dispatch(setLoading(false));
		} catch (e: any) {
			dispatch(setLoading(false));
		}
	}

	const onDataCollection = async (obj, type) => {
		console.log(obj)
		if (type == "owner") {
			obj.isOwner = true;
		} else {
			obj.isOwner = false;
		}
		try {
			dispatch(setLoading(true));
			let response = await hasPayment(obj?.UserNumber, obj?.TopicCode);
			if (response.data) {
				setselectedData(obj);
				setpopupType('dataCollection');
			} else {
				setpopupType('subscriptions');
			}
			dispatch(setLoading(false));
		} catch (e: any) {
			setpopupType('');
			setselectedData(null);
			dispatch(setLoading(false));
		}
	}

	const dataCollection = (type) => {
		setpopupType('');
		setselectedData(null);
		localStorage.setItem('projectData',JSON.stringify(selectedData))
		navigate('/dataCollection', { state: selectedData });
		console.log(selectedData)
	}

	/**
	 * on click RE redirect to register events component
	 * @param data 
	 */
	const registerdEvent = async (data) => {
		console.log(data)
		try {
			const getActiveData = await getPieactiveReg(data?.TopicID, data?.UserNumber);
			SetEventInvites(getActiveData.data);
			if (getActiveData.data && getActiveData.data.length > 0) {
				localStorage.setItem('projectData',JSON.stringify(data))
				navigate('/registeredevents', { state: data });
			} else {
				showToaster(toasterTypes.ERROR, "Project not registered to any events")
			}
		} catch (error) {
			throw error;
		}
	}

	const onProjectEdit = (obj) => {
		localStorage.setItem('projectData',JSON.stringify(obj))
		navigate('/createProject', { state: obj });
	}

	const onMove = async () => {
		try {
			dispatch(setLoading(true));
			let response = await moveProject({ usernumber: selectedData?.UserNumber, topiccode: selectedData?.TopicID });
			if (response?.data === "Success") {
				fetchCurrentProjects(userData);
			}
			setpopupType('');
			setselectedData(null);
			dispatch(setLoading(false));
		} catch (e: any) {
			setpopupType('');
			setselectedData(null);
			dispatch(setLoading(false));
		}
	}

	const getInviteCount = (count) => {
		SetinviteCount(count);
		fetchUserProjectsCollab();
		fetchUserProjectsMentor();
	}

	/**
	 * on project card click get details of project
	 */
	const OnProjectDetail = (data, type) => {
		console.log(data)
		if (type == "owner") {
			data.isOwner = true;
		} else {
			data.isOwner = false;
		}
		localStorage.setItem('projectData',JSON.stringify(data))
		navigate("/projectdetail", { state: data })
	}

	/**
	 * on click people chat panel
	 */
	const onPeopleAction = (id, type) => {
		console.log(id)
		if (type == "owner") {
			id.isOwner = true;
		} else {
			id.isOwner = false;
		}
		//navigate('/people',{ state: { projectid: id} })
		localStorage.setItem('projectData',JSON.stringify(id))
		navigate('/people', { state: id })
	}

  /**
	 * on click planner
	 */
	const onPlannerAction = (id, type) => {
		console.log(id)
		if (type == "owner") {
			id.isOwner = true;
		} else {
			id.isOwner = false;
		}
		localStorage.setItem('projectData',JSON.stringify(id))
		navigate('/planner', { state: id })
	}

	/**
	 * onclick documents
	 */
	const onDocumentAction = async(data, type) => {
		console.log(data)
		if (type == "owner") {
			data.isOwner = true;
		} else {
			data.isOwner = false;
		}
		// let response = await hasPayment(obj?.UserNumber, obj?.TopicCode);
		localStorage.setItem('projectData',JSON.stringify(data))
		navigate('/document', { state: data })
	}

	// useEffect(() => {
	// 	fetchUserProjectsCollab();
	// 	fetchUserProjectsMentor();
	// },[selectedTab])
	

	return (
		<>
			<div className="project-container">
				<div className='header-bar'>
					<Row className='m-0 mb-3'>
						<Col className='p-0 d-flex m-0' md={5} xs={12}>
							<div className='profile-data'>
								{profile?.photo_link ?
									<div className='profile-container'>
										<img className='profile-image' alt='Profile' src={profile.photo_link} /> </div> :
									<div className='profile-container'>
										<img className='profile-image' alt='Profile' src={`${process.env.PUBLIC_URL}/images/logo.png`} />
									</div>
								}
								<div className='d-flex m-0 align-items-center'>

									<div className='name-container'>
										{!profile?.profile_title && <div className='greet-text'>Hello</div>}
										{/* <div className='title-text'>{profile?.profile_title}</div> */}
										<div className='first-text'>{profile?.profile_title} {profile?.first_name}<span className='last-text'>{profile?.last_name}</span></div>
										{(profile?.photo_link || profile?.first_name || profile?.profile_title) &&
											<div className='edit-container'><i className='icon-edit' onClick={() => setpopupType('profile')}></i></div>
										}
									</div>
									<div className='info-container'>
										<span className='mobile-text'>{profile?.mobilenumber}</span>
										<span className='email-text'>{profile?.emailid}</span>
									</div>
								</div>
							</div>
						</Col>
						<Col className='p-0 d-flex justify-content-end' md={5} xs={12}>
							{/* <div className='pending-action'>
						<span className='pending-text'>Pending Actions</span>
						<ul  className='pending-list'>
							<li>Add work experience <RightOutlined className='right-arrow'/></li>
							<li>Add work experience<RightOutlined className='right-arrow'/></li>
							<li>Add work experience<RightOutlined className='right-arrow'/></li>
						</ul>
					</div> */}
						</Col>
						<Col className='p-0 d-flex justify-content-end' md={2} xs={12}>
							<i className='icon icon-notification'></i>
						</Col>
					</Row>
				</div>

				<div className='project-page'>
					{popupType === 'delete' &&
						<ModalPopup
							modalType='delete'
							modalText="Are you sure, you want to delete?"
							actionText="This action cannot be undone"
							closeModal={() => { setpopupType(''); setselectedData(null); }}
							onAction={() => onDelete()}
						/>
					}
					{popupType === 'subscriptions' &&
						<ModalPopup
							modalType='confirm'
							modalText="Please subscribe or renew subscription to proceed. Do you want to a make payment now?"
							actionText=""
							buttonText="Pay"
							closeModal={() => { setpopupType(''); setselectedData(null); }}
							onAction={() => navigate('/subscriptions')}
						/>
					}
					{popupType === 'sampleSize' &&
						<ModalPopup
							modalType='confirm'
							modalText="Do you want to start project from Sample size calculation?"
							actionText=""
							closeModal={() => { setpopupType(''); setselectedData(null); }}
							onAction={() => sampleSize("", "owner")}
						/>
					}
					{popupType === 'dataCollection' &&
						<ModalPopup
							modalType='confirm'
							modalText="Do you want to start project from data entry?"
							actionText=""
							closeModal={() => { setpopupType(''); setselectedData(null); }}
							onAction={() => dataCollection("")}
						/>
					}
					{popupType === 'move' &&
						<ModalPopup
							modalType='delete'
							modalText="Are you sure you want to move?"
							actionText="This action cannot be undone"
							closeModal={() => { setpopupType(''); setselectedData(null); }}
							onAction={() => onMove()}
						/>
					}


					{popupType === 'profile' &&
						<ProfileDetails {...props} data={profile} userNumber={profile.usernumber} onClose={() => setpopupType('')} />
					}
					<Row className='m-0 mb-3'>
						{/* <Col className='p-0 d-flex m-0' md={6} xs={12}>
					<div className='project-header mr-4 pt-2'>Projects</div>
					<FieldItem
						{...FormElements.search}
						value={values.search}
						onChange={(...e) => handleChange(FormElements.search.name, ...e)}
						touched={fields.search && fields.search.hasError}
						error={fields.search && fields.search.errorMsg}
					/>
				</Col> */}
						<Col className='p-0 d-flex justify-content-start' md={10}>

							<Tabs
								data={[{ title: currentProjects.length, subTitle: 'My Projects' },
								//{ title: userTopics.length, subTitle: 'New Projects' },
								{ title: collabCount, subTitle: 'Collaborations' },
								{ title: mentorCount, subTitle: 'Mentoring Projects' },
								{ title: inviteCount, subTitle: 'Invites' }
								]}
								selected={selectedTab}
								onTab={(index) => setselectedTab(index)} 
							/>	

						</Col>
						<Col className='p-0 d-flex justify-content-end' md={2} xs={12}>
							<CustomButton type="primary" text="Create" onClick={createProject}></CustomButton>
						</Col>
					</Row>


					{/* {selectedTab == 4?
				<Row className='m-0'>
					{userTopics.map((obj, index) => (
						<Col className={`mt-4 p-0 ${index % 2 === 0 ? 'pr-3' : 'pl-3'}`} key={index} md={6} xs={12}>
							<ProjectCard
								data={obj}
								type='topics'
								onDelete={() => { setselectedData(obj); setpopupType('delete'); }}
								onSampleSize={(data) => { setselectedData(data); setpopupType('sampleSize'); }}
								onData={() => onDataCollection(obj)}
								onRegisterEvent={()=>registerdEvent(obj)}
								onEdit={() => onProjectEdit(obj)}
							/>
						</Col>
					))}
					{userTopics.length === 0 &&
						<div className='no-data-container'>
							<div className='no-data-text'>No Data</div>
						</div>
					}
				</Row> : */}

					{selectedTab == 0 ?
						<Row className='m-0'>
							{currentProjects.map((obj, index) => (
								<Col className={`mt-4 p-0 ${index % 2 === 0 ? 'pr-3' : 'pl-3'}`} key={index} md={6} xs={12}>
									<ProjectCard
										data={obj}
										type='projects'
										onDelete={() => { setselectedData(obj); setpopupType('delete'); }}
										onSampleSize={(data) => data.SDMName === 'NA' || data.SDMName === "" ? onSampleSize(data, "owner") : onStudyModule(data, "owner")}
										onData={() => onDataEntry(obj, "owner")}
										onEdit={() => onProjectEdit(obj)}
										onRegisterEvent={() => registerdEvent(obj)}
										onMove={(obj) => {
											setselectedData(obj)
											setpopupType('move')
										}}
										onprojectDetail={() => OnProjectDetail(obj, "owner")}
										isEventInvite={eventInvites && eventInvites.filter(item => item.project_id == obj["TopicID"])}
										onPeople={() => onPeopleAction(obj, "owner")}
										onPlanner={() => onPlannerAction(obj, "owner")}
										onDocument={() => onDocumentAction(obj, "owner")}
									/>
								</Col>
							))}
							{currentProjects.length === 0 &&
								<div className='no-data-container'>
									<div className='no-data-text'>No Data</div>
								</div>
							}
						</Row>

						:
						selectedTab == 1 ?
							<Row className='m-0'>
								{collabProject.map((obj, index) => (
									<Col className={`mt-4 p-0 ${index % 2 === 0 ? 'pr-3' : 'pl-3'}`} key={index} md={6} xs={12}>
										<ProjectCardCollab
											data={obj}
											type='collaborator'
											onDelete={() => { setselectedData(obj); setpopupType('delete'); }}
											onSampleSize={(data) => data.SDMName === 'NA' ? onSampleSize(data, "") : onStudyModule(data, "")}
											onData={() => onDataEntry(obj, "")}
											onEdit={() => onProjectEdit(obj)}
											onMove={(obj) => {
												setselectedData(obj)
												setpopupType('move')
											}}
											onprojectDetail={() => OnProjectDetail(obj, "collab")}
											onDocument={() => onDocumentAction(obj, "collab")}
										/>
									</Col>
								))}
								{collabProject.length === 0 &&
									<div className='no-data-container'>
										<div className='no-data-text'>No Data</div>
									</div>
								}
							</Row>
							:
							selectedTab == 2 ?
								<Row className='m-0'>
									{mentorProject.map((obj, index) => (
										<Col className={`mt-4 p-0 ${index % 2 === 0 ? 'pr-3' : 'pl-3'}`} key={index} md={6} xs={12}>

											<ProjectCardMentor
												data={obj}
												type='mentor'
												onDelete={() => { setselectedData(obj); setpopupType('delete'); }}
												onSampleSize={(data) => data.SDMName === 'NA' ? onSampleSize(data, "") : onStudyModule(data, "")}
												onData={() => onDataEntry(obj, "")}
												onEdit={() => onProjectEdit(obj)}
												onMove={(obj) => {
													setselectedData(obj)
													setpopupType('move')
												}}
												onprojectDetail={() => OnProjectDetail(obj, "mentor")}
												onDocument={() => onDocumentAction(obj, "mentor")}
											/>
										</Col>
									))}
									{mentorProject.length === 0 &&
										<div className='no-data-container'>
											<div className='no-data-text'>No Data</div>
										</div>
									}
								</Row>
								:
								selectedTab == 3 ?
									<div className="invites-container">
										<InviteeCard data={currentInvites} getCount={getInviteCount} />
									</div>
									:
									<div className='no-data-container'>
										<div className='no-data-text'>No Data</div>
									</div>
					}
				</div>
			</div>
		</>
	)
}