import { Col, Row } from "reactstrap";
import { CustomButton } from "../../widgets";
import { FIELD_TYPES, FieldItem } from "../../widgets/fields";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import ModalComponent from "../common/Modal/modal";
import { SendOutlined } from "@ant-design/icons";
import { postAskQuestion } from "./apiController";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Spin } from "antd";

const AskQuestions = (props) => {
    const [values, fields, handleChange, { }] = useFieldItem({}, {}, {});
    const { formId } = props;
    const dispatch = useDispatch();
    const [load, Setload] = useState(false);
    const [resultData, SetresultData] = useState<any>("")

    useEffect(() => {
        console.log(props)
    }, [load, resultData])

    const askQuestion = async () => {
        try {
            Setload(true)
            let question = { 'question': values.question }
            let response = await postAskQuestion(formId, question);
            SetresultData(response)
            Setload(false)

        } catch (error) {
            Setload(false)

        }
    }

    return (
        <>
            <ModalComponent
                width={"80%"}
                isOpen={true}
                onClose={() => props.isClose()}
                header="Ask your question"
                centered={true}
                customClass='question-modal'
                body={
                    <div className="question-head">
                        <div className="answer-spin">
                            {
                                !resultData && !load && <span className="hint"> Find you answers here</span>
                            }
                            <Spin className="spin" spinning={load}></Spin>
                        </div>
                        <div className="answer-panel">
                            {resultData}
                        </div>
                        <Row>
                            <Col md={11}>
                                <FieldItem
                                    name={"question"}
                                    className="reason_area"
                                    type={FIELD_TYPES.TEXT}
                                    value={values.question}
                                    placeholder={"Type your question here"}
                                    onChange={(...e) => handleChange("question", ...e)}
                                    touched={fields.question && fields.question.hasError}
                                    error={fields.question && fields.question.errorMsg}
                                />
                            </Col>
                            <Col md={1}>
                                <div className="send">
                                    <SendOutlined className="send-icon" onClick={() => askQuestion()} />
                                </div>
                            </Col>
                        </Row>
                    </div>
                }
                footer={
                    <div>
                    </div>
                }
            />
        </>
    )
}

export default AskQuestions;