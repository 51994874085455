import { FIELD_TYPES } from "../../widgets/fields";

export const FormElements = {
    ruleName: {
        name: "ruleName",
        placeholder: "Enter Rule Name",
        label: "Rule Name",
        isMandatory: true
    },
    ruleDescription: {
        name: "ruleDescription",
        placeholder: "Enter Rule Description",
        label: "Rule Description",
        type: FIELD_TYPES.TEXT_AREA,
        isMandatory: true
    },
    role: {
        name: "role",
        placeholder: "Select role",
        label: "Role",
        type: FIELD_TYPES.DROP_DOWN,
        isMandatory: true,
        values: [{label: 'Role 1', value: 'role1'}, {label: 'Role 2', value: 'role2'}]
    },
    multiRole: {
        name: "multiRole",
        placeholder: "Select role",
        label: "Multi Role",
        type: FIELD_TYPES.MULTI_SELECT,
        isMandatory: true,
        values: [{label: 'Role 1', value: 'role1'}, {label: 'Role 2', value: 'role2'}, {label: 'Role 3', value: 'role3'}]
    },
    radio: {
        name: "radio",
        placeholder: "",
        label: "Radio",
        type: FIELD_TYPES.RADIO_BUTTON,
        isMandatory: true,
        values: [{label: 'Radio 1', value: 'radio1'}, {label: 'Radio 2', value: 'radio2'}]
    },
    checkbox: {
        name: "checkbox",
        placeholder: "",
        label: "Check Box",
        type: FIELD_TYPES.CHECK_BOX,
        isMandatory: true,
        values: [{label: 'Checkbox 1', value: 'checkbox1'}, {label: 'Checkbox 2', value: 'checkbox2'}, {label: 'Checkbox 3', value: 'checkbox3'}]
    },
    searchField: {
        name: "searchField",
        placeholder: "Search",
        label: "Search",
        type: FIELD_TYPES.SEARCH,
        isMandatory: true
    },
    dateField: {
        name: "dateField",
        placeholder: "Date",
        label: "Date",
        type: FIELD_TYPES.DATE_PICKER,
        isMandatory: true
    },
    dateTimeField: {
        name: "dateTimeField",
        placeholder: "Date & Time",
        label: "Date & Time",
        type: FIELD_TYPES.DATE_TIME_PICKER,
        isMandatory: true
    },
    timeField: {
        name: "timeField",
        placeholder: "Time",
        label: "Time",
        type: FIELD_TYPES.TIME_PICKER,
        isMandatory: true
    },
    fileUpload: {
        name: "fileUpload",
        placeholder: "Only pdf allowed",
        label: "Upload File",
        type: FIELD_TYPES.FILE_UPLOAD,
        isMandatory: true
    }
}