const FIELD_TYPES = {
  TEXT: 1,
  TEXT_AREA: 2,
  DROP_DOWN: 3,
  MULTI_SELECT: 4,
  RADIO_BUTTON: 5,
  CHECK_BOX: 6,
  SEARCH: 7,
  DATE_PICKER: 8,
  DATE_TIME_PICKER: 9,
  FILE_UPLOAD: 10,
  MULTI_TEXT: 11,
  BOOLEAN: 12,
  NUMBER: 13,
  TIME_PICKER: 14,
  FILE_UPLOAD_ALL: 15,
  RESEARCHWORK_UPLOAD: 16,
  RADIO_BUTTON_PER:17,
  TEXT_AUTOCOMPLETE: 18,
  DATEPICKER_NEW:19,
  DROPDOWN_TOOLTIP:20,
};

export default FIELD_TYPES;
