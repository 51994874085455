import AjaxUtil from "../../axios/axios";

export const getPayment = async(id) => {
    try {
        return await AjaxUtil.sendRequest(`getPaymentsList/${id}`, {}, false, { method: 'get', showError: true} );
    } catch (error) {
        throw error;
    }
}

export const getSubscriptions = async() => {
    try {
        return await AjaxUtil.sendRequest(`plans`, {}, false, { method: 'get', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const getActivePlans = async() => {
    try {
        return await AjaxUtil.sendRequest(`plans/current`, {}, false, { method: 'get', showError: true, isProfile: true} );
    } catch (error) {
        throw error;
    }
}

export const getDiscount = async(code, plan) => {
    try {
        return await AjaxUtil.sendRequest(`couponCode/${code}/${plan}`, {}, false, { method: 'get', showError: true} );
    } catch (error) {
        throw error;
    }
}

export const createSubscriptionOrder = async(code, plan) => {
    try {
        return await AjaxUtil.sendRequest(`subscription/${code}/${plan}`, {}, false, { method: 'get', showError: true} );
    } catch (error) {
        throw error;
    }
}

export const createOrder = async(data) => {
    try {
        return await AjaxUtil.sendRequest(`createpayorder`, data, false, { method: 'post', showError: true} );
    } catch (error) {
        throw error;
    }
}

export const updateOrder = async(data) => {
    try {
        return await AjaxUtil.sendRequest(`updatepayorder`, data, false, { method: 'put', showError: true} );
    } catch (error) {
        throw error;
    }
}