import React from 'react';
import CustomTable from '../../widgets/table';
import data from './tableData.json';

export default function Table(props: any) {
  const columns = [
    {
      "id": "name",
      "label": "Name",
      "accessor": (row: any) => {
        return row.name + ' ' + row.surname
      },
      "sort": "asc",
      "width": 150
    },
    {
      "id": "position",
      "label": "Position",
      "accessor": "position",
      "sort": "asc",
      "width": 270
    },
    {
      "id": "office",
      "label": "Office",
      "accessor": "office",
      "sort": "asc",
      "width": 200
    },
    {
      "id": "age",
      "label": "Age",
      "accessor": "age",
      "sort": "asc",
      "width": 100
    },
    {
      "id": "startDate",
      "label": "Start date",
      "accessor": "date",
      "sort": "asc",
      "width": 150
    },
    {
      "id": "salary",
      "label": "Salary",
      "accessor": "salary",
      "sort": "asc",
      "width": 100
    },
    {
      "id": "extn",
      "label": "Extn.",
      "accessor": "extn",
      "sort": "asc",
      "width": 100
    },
    {
      "id": "email",
      "label": "E-mail",
      "accessor": "email",
      "sort": "asc",
      "width": 200,
      "className": "link-color"
    }
  ]

  function onAction(type: any) {
  }

  return (
    <CustomTable data={data} isPagination={true} tableProps={columns} onAction={(type: any) => onAction(type)}></CustomTable>
  )
}
