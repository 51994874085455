import React, { Suspense, useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import { AnalysisFormElements } from "./FieldElements";
import { FieldItem } from "../../widgets/fields";
import AnalysisVideo from "./AnalysisVideo";
import VariableList from "./VariableList";
import AddConfiguration from "./AddConfiguration";
import { getAnalysisChartTypes, getAnalysisTypes, getAnalysisVariables, getOutcomeVariable } from "./apiController";
import { useDispatch } from "react-redux";
import { setLoading } from "../../store/slices/auth";
import { Loader } from "../common/FullScreenLoader/fullScreenLoader";
import { showToaster, toasterTypes } from "../../widgets";

const SelectedVariables = React.lazy(() => import("./SelectedVariables"));

export default function ConfigureAnalysis(props) {
    const initialValues = useMemo(() => (
        { analysisName: "", analysisType: "" }
    ), [props.previousState]);
    const [values, fields, handleChange, { validateValues, reset, updateValue, updateError }] = useFieldItem(AnalysisFormElements, initialValues, { onValueChange });
    const [projectData, SetProjectData] = useState<any>([]);
    const [popupType, SetPopupType] = useState("");
    const [selectedVariables, SetSelectedVariable] = useState<any>([]);
    const [videoStatus, SetVideoStatus] = useState(false);
    const [anaylsisType, SetAnalysisType] = useState<any>([]);
    const [isConfig, SetConfig] = useState(false);
    const [savedFieldId, SetSavedFieldId] = useState<any>("");
    const [previousResponse, SetPreviousResponse] = useState<any>([]);
    const dispatch = useDispatch();

    useEffect(() => {
        console.log(props)
        fetchInitialData();
        SetProjectData(props.data)
    }, [])


    /**
     * fetch initial data
     */
    const fetchInitialData = async () => {
        try {
            dispatch(setLoading(true));
            let response = await getAnalysisTypes();
            SetAnalysisType(response.map(({
                key: value,
                name: label
            }) => ({
                value,
                label
            })))

            if (props?.selectedItem.length != 0) {
                fetchVariableData();
                let initalValue = response.filter(item => item.name == props?.selectedItem.type)
                updateValue({ analysisName: props?.selectedItem.name, analysisType: { label: initalValue[0].name, value: initalValue[0].key } })
            }

            dispatch(setLoading(false))
        } catch (e: any) {
            dispatch(setLoading(false))
            console.log(e)
        }
    }
    /**
     * handle on change values of form elements
     * @param name 
     * @param value 
     * @param values 
     * @param fieldValues 
     * @returns 
     */
    function onValueChange(name, value, values, fieldValues = null) {
        const resetValue = {}
        return [{
            ...values,
            ...resetValue,
            [name]: value,
        }]
    }

    /**
     * set variables on click of add
     */
    const addVariables = (data) => {
        data.charttype = {};
        console.log(data, selectedVariables)
        let checkDuplicateVariable = selectedVariables.filter(item => item.id == data.id);
        if (checkDuplicateVariable.length == 0) {
            SetSelectedVariable([...selectedVariables, data])
        } else {
            showToaster(toasterTypes.WARNING, "Variable already selected")
        }
    }

    /**
     * remove variable
     */
    const removeVariableItem = (id) => {
        SetSelectedVariable(selectedVariables.filter(item => item.id != id))
    }

    const watchVideo = () => {
        SetPopupType("video")
    }

    const onClose = () => {
        SetPopupType("")
    }

    /**
     * call back function to props to get video status
     * @param status 
     */
    const getVideoStatus = (status) => {
        SetVideoStatus(status)
    }

    /**
     * onclick add configuration redirect to page
     */
    const addConfiguration = (id) => {
        SetSavedFieldId(id);
        SetConfig(true)
    }

    /**
     * get variable list data
     */
    const fetchVariableData = async () => {
        try {
            dispatch(setLoading(true));
            console.log(props)
            let response = await getAnalysisVariables(props?.formData?.id);
            let outComeVariables = props?.selectedItem.id && await getOutcomeVariable(props?.selectedItem.id);
            let chartList = await getAnalysisChartTypes();
            let initialArray: any = [];
            SetPreviousResponse(outComeVariables);
            console.log(outComeVariables)
            outComeVariables && outComeVariables.fields.map(item => {
                let data = response.data.filter(itemfilter => itemfilter.id == item.field_id);
                let selectCharts: any = [];
                item.chart_type && item.chart_type.map(chartitem => {
                    let chartData = chartList?.filter(chartItem => chartItem.key == chartitem)
                    console.log(chartitem, chartData)

                    selectCharts.push({ label: chartData[0].name, value: chartData[0].key })
                })
                console.log(selectCharts)
                data[0]['chart_type'] = selectCharts && selectCharts || [];
                initialArray.push(data[0])
            })
            console.log(initialArray)
            SetSelectedVariable(initialArray)
            dispatch(setLoading(false))
        } catch (e: any) {
            dispatch(setLoading(false))
            console.log(e)
        }
    }


    return (
        <>
            {popupType == "video" ?
                <AnalysisVideo getVideoStatus={getVideoStatus} onClose={() => onClose()} />
                :
                <div className="analysis-container">
                    <div className="header-container">
                        <Row className='m-0'>
                            <Col className='pl-3 mt-3'>
                                <div className='form-name'>
                                    <img className='profile-image' alt='Profile' src={`${process.env.PUBLIC_URL}/images/logo.png`} />
                                    {isConfig ? "Add Configuration" : "Data Analysis"}
                                </div>
                            </Col>
                            <Col className='pr-4 mt-4 text-right'>
                                <i className='icon icon-close' onClick={() => props.onClose()} />
                            </Col>
                        </Row>
                    </div>
                    <div className="analysis-body">
                        <Row className="m-0 analysis-selector">
                            <Col className="mt-1" md={4}>
                                <FieldItem
                                    {...AnalysisFormElements.analysisName}
                                    value={values.analysisName}
                                    isDisabled={isConfig}
                                    onChange={(...e) => handleChange(AnalysisFormElements.analysisName.name, ...e)}
                                    touched={fields.analysisName && fields.analysisName.hasError}
                                    error={fields.analysisName && fields.analysisName.errorMsg}
                                />
                            </Col>
                            <Col className="mt-1" md={3}>
                                <FieldItem
                                    {...AnalysisFormElements.analysisType}
                                    value={values.analysisType}
                                    values={anaylsisType}
                                    isDisabled={isConfig}
                                    onChange={(...e) => handleChange(AnalysisFormElements.analysisType.name, ...e)}
                                    touched={fields.analysisType && fields.analysisType.hasError}
                                    error={fields.analysisType && fields.analysisType.errorMsg}
                                />
                            </Col>
                            {values && values?.analysisType?.value == "inferential_analysis" ?
                                <Col className="mt-4">
                                    <div className="inferential-question">
                                        Do you know your outcome variable?
                                        {!videoStatus ?
                                            <>
                                                <span style={{ color: "green", cursor: "pointer", padding: "0px 10px" }}>Yes</span>
                                                <span style={{ color: "red", cursor: "pointer", padding: "0px 10px" }} onClick={() => watchVideo()}>No</span>
                                            </>
                                            :
                                            <span style={{ color: "blue", cursor: "pointer", padding: "0px 10px" }} onClick={() => watchVideo()}>See Video</span>
                                        }
                                    </div>
                                </Col>
                                : ""}
                        </Row>
                    </div>
                    <div className="forms-container">
                        <Row>
                            {isConfig ?
                                <Col>
                                    <AddConfiguration data={props} id={savedFieldId} previousData={previousResponse} />
                                </Col>
                                :
                                <>
                                    <Col md={4}>
                                        <VariableList data={props} analyseType={values?.analysisType} addVariable={addVariables} />
                                    </Col>
                                    <Col md={8}>
                                        <Suspense fallback={"Loading..."}>
                                            <SelectedVariables analyseType={values?.analysisType} analyseName={values?.analysisName} data={props} variables={selectedVariables} removeItem={removeVariableItem} addConfig={addConfiguration} outComeData={previousResponse} />
                                        </Suspense>
                                    </Col>
                                </>
                            }
                        </Row>
                    </div>

                </div>
            }
        </>
    )
}