import { Card, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "../../widgets";



export default function InviteCard(props){ 
    return(
        <>
            <div className="invite-card-container">
                <Card className="invite-card">
                            <div className="user-content">
                                {props.badgeimg ?  <Image className="notify-badge" src={props.badgeimg}/> : ""}                               
                                <Image className="invite-profile-pic" src={props.profileimg}/>
                                <div className="invite-name">{props.name}</div>
                                <div className="invite-profession">{props.role}</div>
                                <span className="invite-content">{props.desc}</span>
                                <CustomButton type="primary" className='invite-button' text="View Profile" onClick={props.viewProfiledata}></CustomButton>
                            </div>
                </Card>
            </div>
        </>
    );
}