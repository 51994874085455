import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { uploadChartAsImage } from '../../Editor/utils/UploadToServer';
import SectionModal from '../../Editor/utils/SectionModal';
import { downloadChartAsImage } from './downloadChartAsImage';

const ParetoChart = ({ containerid, data, barstyle, bardatastyle, linestyle, linedatastyle, width, height, title, titlecolor, yaxislabelstyle, xaxislabelstyle, gridcolor, gridstatus, yaxisstyle, xaxisstyle, xaxistext, yaxistext }) => {
  const chartRef = useRef(null);
  const [showGridLines, setShowGridLines] = useState(true);
  const [ylabel, setylabel] = useState(yaxistext)
  const [xlabel, setxlabel] = useState(xaxistext)
  const [actionPush, setactionPush] = useState('');

  /**
  * function call to Push code to editor
  */
  const addToEditor = (data) => {
    console.log(data)
    uploadChartAsImage(data, containerid)
    setactionPush("")
  }

  useEffect(() => {
    // Function to create Pareto chart
    const margin = { top: 20, right: 20, bottom: 30, left: 40 };
    const widthvalue = width - margin.left - margin.right;
    const heightvalue = height;


    const createParetoChart = () => {

      // Clear any existing chart
      d3.select(chartRef.current).selectAll('*').remove();

      // Create SVG container
      const svg = d3
        .select(chartRef.current)
        .append('svg')
        .attr('width', widthvalue + margin.left + margin.right)
        .attr('height', heightvalue + margin.top + margin.bottom)
        .append('g')
        .style('fill', 'none')  // Set fill to none
        .attr('transform', `translate(${margin.left + margin.top},${margin.bottom})`);

      // Sort data in descending order
      data.sort((a, b) => b.value - a.value);

      // Calculate cumulative percentage
      let cumulativePercentage = 0;
      data.forEach((d) => {
        d.percentage = (d.value / data.reduce((acc, cur) => acc + cur.value, 0));
        cumulativePercentage += d.percentage;
        d.cumulativePercentage = cumulativePercentage;
      });


      // Draw gridlines
      svg
        .append('g')
        .attr('class', 'grid')
        .style('color', gridcolor)
        .attr('transform', `translate(2,${height})`)
        .style("stroke-dasharray", "5 5")
        .style("visibility", showGridLines ? "visible" : "hidden")
        .call(d3.axisBottom(x).tickSize(-height).tickFormat(''));

      svg
        .append('g')
        .attr('class', 'grid')
        .attr("transform", `translate(0,0)`)
        .style("stroke-dasharray", "5 5")
        .style("color", gridcolor)
        .style("visibility", showGridLines ? "visible" : "hidden")
        .call(d3.axisLeft(y).tickSize(-width).tickFormat(''));


      // Create bars
      svg
        .selectAll('.bar')
        .data(data)
        .enter()
        .append('rect')
        .attr('class', 'bar')
        .attr('x', (d) => x(d.label) + margin.top)
        .attr('width', x.bandwidth() / 2)
        .attr('y', (d) => y(d.value))
        .style('fill', barstyle.color)  // Set fill to none
        .attr('height', (d) => heightvalue - y(d.value));

      // Add values on top of bars
      svg
        .selectAll('.bar-value')
        .data(data)
        .enter()
        .append('text')
        .attr('class', 'bar-value')
        .attr('transform', `translate(${margin.left},0`)
        .attr('x', (d) => x(d.label) + x.bandwidth() / 2)
        .attr('y', (d) => y(d.value) + margin.top)
        .attr('text-anchor', 'middle')
        .style('fill', bardatastyle.color)
        .style('font-weight', bardatastyle.fontweight)
        .style('font-size', bardatastyle.fontsize)
        .style('visibility', bardatastyle.visible)
        .text((d) => d.value);


      // Create line for cumulative percentage
      const line = d3
        .line()
        .x((d) => x(d.label) + x.bandwidth() / 2)
        .y((d) => y2(d.cumulativePercentage))
        ;

      svg
        .append('path')
        .data([data])
        .attr('class', 'line')
        .attr('d', line)
        .style('fill', 'none')  // Set fill to none
        .style('stroke', linestyle.color);  // Set the stroke color as needed


      // Add dots along the line with values
      svg
        .selectAll('.dot')
        .data(data)
        .enter()
        .append('g')
        .attr('class', 'dot-group')
        .attr('transform', (d) => `translate(${x(d.label) + x.bandwidth() / 2},${y2(d.cumulativePercentage)})`)
        .append('circle')
        .attr('class', 'dot')
        .attr('r', linedatastyle.radius) // Adjust the radius of the circle as needed
        .style('fill', linedatastyle.color); // Set the fill color of the circle as needed

      // Add value labels for each dot
      svg
        .selectAll('.dot-group')
        .append('text')
        .attr('class', 'dot-label')
        .attr('x', 0)
        .attr('y', -margin.top) // Adjust the vertical position of the label
        .attr('fill', 'black')
        .attr('text-anchor', 'middle')
        .text(d => Math.round(d.cumulativePercentage) + '%');


      // Add axes
      svg.append('g').call(d3.axisLeft(y));
      svg.append('g').attr('transform', `translate(0,${heightvalue})`).call(d3.axisBottom(x));

      //add x axis label
      svg.append('text')
        .attr("class", "x label")
        .attr("text-anchor", "middle")
        .attr("x", width / 3)
        .attr("y", height - 2)
        .attr("dy", xaxisstyle.dy)
        .attr("dx", width / 8 - margin.left)
        .style('fill', xaxisstyle.color)
        .style('font-weight', xaxisstyle.fontweight)
        .style('font-size', xaxisstyle.fontsize)
        .text(xlabel)
        .on("click", () => {
          let label = prompt("Edit X Axis Label")
          if (label) {
            setxlabel(label)
          }
        });

      //add y axis label
      svg.append("text")
        .attr("class", "y label")
        .attr('transform', `translate(80)`)
        .attr("text-anchor", "middle")
        .attr("y", 6)
        .attr("dy", yaxisstyle.dy)
        .attr("x", -height / 2)
        .attr("transform", "rotate(-90)")
        .style('fill', yaxisstyle.color)
        .style('font-weight', yaxisstyle.fontweight)
        .style('font-size', yaxisstyle.fontsize)
        .text(ylabel)
        .on("click", () => {
          let label = prompt("Edit Y Axis Label")
          if (label) {
            setylabel(label)
          }
        });



      //title of the chart
      svg.append('text')
        .attr('class', 'title')
        .attr('x', width / 2 - margin.left)
        .attr('y', -10)
        .attr('text-anchor', 'middle')
        .style('font-size', titlecolor.fontsize)
        .style('font-weight', titlecolor.fontweight)
        .style('fill', titlecolor.color)
        .text(title)

    };

    // Set up scales
    const x = d3.scaleBand().range([0, widthvalue]).padding(0.1);
    const y = d3.scaleLinear().range([heightvalue, 0]);
    const y2 = d3.scaleLinear().range([heightvalue, 0]);

    x.domain(data.map((d) => d.label));
    y.domain([0, d3.max(data, (d) => parseInt(d.value))]);
    y2.domain([0, d3.max(data, (d) => parseInt(d.value))]);



    // Create Pareto chart on initial render
    createParetoChart();
  }, [data, showGridLines, xlabel, ylabel]);

  return <>
    <div className="controller no-print">
      <Button className='grid no-print' onClick={() => setShowGridLines(!showGridLines)}>
        {showGridLines ? 'Hide Grid Lines' : 'Show Grid Lines'}
      </Button>
      {
        actionPush == "section" &&
        <SectionModal pushAction={addToEditor} onclose={() => setactionPush("")} />
      }


      <Button className='grid no-print' onClick={() => downloadChartAsImage(containerid)}>
        Download
      </Button>
      <Button className='grid no-print' onClick={() => setactionPush("section")}>
      Copy to Editor
      </Button>
    </div>
    <div ref={chartRef} id={containerid}></div>
  </>
};


export default ParetoChart;
