import React, { useState, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";
// widgets
import { CustomButton, showToaster, toasterTypes } from "../../widgets";
// fields
import { FieldItem} from "../../widgets/fields";
import { FormElements } from "./FormElements";
import useFieldItem from "../../widgets/fields/UseFieldItem";
// redux
import { useSelector, useDispatch } from 'react-redux';
import { login, authSelector, setLoading, setAuthFailed, setLogOut} from '../../store/slices/auth';
// components
import OTPComponent from "../common/components/login/otp.component";
// api
import { send_otp, update_password } from './apiController';
import packageJson from '../../../package.json';

const LOGIN_MODES = {
    LOGIN: "LOGIN",
    FORGOT_PASSWORD: "FORGOT_PASSWORD",
    RESET_PASSWORD: "RESET_PASSWORD",
    DISPALY_MESSAGE: "DISPALY_MESSAGE",
};

export default function Login() {
    // Redux
    const dispatch = useDispatch();
    const { isLoading, isAuth } = useSelector(authSelector);
    // Navigation
    const navigate = useNavigate();
    // Use States
    const [loginModes, setloginModes] = useState(LOGIN_MODES.LOGIN);
    const [isShow, setisShow] = useState(false);
    const [otp, setOtp] = useState('');
    const [loginId, setloginId] = useState('');
    const [loginRef, SetLoginRef] = useState('as23424');
    const [userNumber, SetUserNumber] = useState('');

    useEffect(() => {
        
        let url: any = window.location.href;

        if(url.includes('formId')) {
            navigate('/surveyForm', {state: url});
        }else if(url.includes('docId')) {
            console.log("Hello&&&&&&&&&&&&&&77777",url)
           navigate('/editor', {state: url});
        }else if(isAuth) {
            navigate('/dashboard');
        } else if(url.includes('register')){
            navigate('/signup');
        }else {
           dispatch(setLogOut());
        }
    }, []);

    useEffect(()=> {
        let url: any = window.location.href;
        console.log(url.includes('docId'))
        if(!url.includes('docId') && !url.includes('formId') && isAuth) {
            navigate('/dashboard');
            console.log("hhhhhh")
        }
    }, [isAuth, navigate]);

    useEffect(()=>{
        console.log(otp)
    },[otp])

    // greeting Text
    var greetText='';
    if (new Date().getHours() < 12) {
        greetText = 'Good Morning';
    } else if (new Date().getHours() < 18) {
        greetText = 'Good Afternoon';
    } else {
        greetText = 'Good Evening';
    }

    // Fields
    const postValidation = (name, value, field) => {
        if(loginModes === LOGIN_MODES.RESET_PASSWORD) {
            let {password, confirmPassword} = values;
            if((name === 'confirmPassword' && password !== value) || (name === 'password' && confirmPassword !== value)) {
                return {
                    hasError: true,
                    errorMsg: 'Password and Confirm Password not Matching'
                };
            }
        }
        return { hasError: false, errorMsg: '' };
    }
    const onValueChange = (name, value, values, fieldValues) => {
        if(loginModes === LOGIN_MODES.RESET_PASSWORD) {
            let {password, confirmPassword} = values;
            if( name === 'confirmPassword' && password === value) {
                if(fieldValues?.password?.hasError) {
                    fieldValues.password.hasError = false;
                }
            } else if(name === 'password' && confirmPassword === value) {
                updateValue({'confirmPassword': confirmPassword});
                if(fieldValues?.confirmPassword?.hasError) {
                    fieldValues.confirmPassword.hasError = false;
                }
            }
        }
        return null;
    }
    const initialValues = useMemo(() => ({ userName: userNumber ? userNumber : "", password: "", confirmPassword: "" }), []);
    const [values, fields, handleChange, { validateValues, reset, updateValue }] = useFieldItem(FormElements, initialValues, {postValidation, onValueChange}); 
   
    // toggle Action
    const toggleAction = async (type: string, id?: string, data?: string) => {
        switch (type) {
            case LOGIN_MODES.RESET_PASSWORD:
                setOtp(id || '');
                //setloginModes(type);
                setloginId(values.userName || loginId);
                reset();
                break;
            case LOGIN_MODES.FORGOT_PASSWORD:
                setloginModes(type);
                try {
                    dispatch(setLoading(true))
                    setloginId(values.userName || loginId);
                    SetUserNumber(values.userName)
                    await send_otp(values.userName || loginId);
                    showToaster(toasterTypes.SUCCESS, 'OTP Sent Successfully!');
                    dispatch(setLoading(false))
                } catch(e: any) {
                    dispatch(setLoading(false))
                    dispatch(setAuthFailed({message: e.message}))
                }
                break;
            case LOGIN_MODES.LOGIN:
                setloginModes(type);
                if(id) {
                    SetUserNumber(id.toString())
                    console.log(type,id,data)
                    updateValue({'userName': id.toString()})
                }
                reset();
                break;
            case LOGIN_MODES.DISPALY_MESSAGE:
                if(!validateValues(["password", 'confirmPassword'])) {
                    
                    try {
                        dispatch(setLoading(true))
                        await update_password(loginId, values.password, otp);
                        dispatch(setLoading(false));
                        setloginModes(type);
                    } catch(e: any) {
                        dispatch(setLoading(false))
                        dispatch(setAuthFailed({message: e.message}))
                    }
                }
                break;
        }   
    };

    /**
     * login action
     */
    const doLogin = ()  => {
        if(validateValues(["userName", "password"])) {
            showToaster(toasterTypes.ERROR, "Please enter the proper values in the fields highlighted in red");
        } else {
            dispatch(login(values.userName, values.password));
        }
    }

    const forgetPassword = () => {
        if(validateValues(["userName"])) {
			showToaster(toasterTypes.ERROR, "Please enter the proper values in the fields highlighted in red");
        } else {
            toggleAction(LOGIN_MODES.FORGOT_PASSWORD)
        }
    }

    const renderSuccessMessage = () => {
        return (
            <div className="reset-password">
                <h2 className="welcome-text">Hi, {greetText}</h2>
                <p>Your Password changed successfully</p>
                <CustomButton type="primary" onClick={()=>{toggleAction(LOGIN_MODES.LOGIN, loginId)}} className="w-100" text="Login"></CustomButton>
            </div>
        );
    };

    const resetComponent = () => {
        return (
            <div className="reset-password">
                <div className="content-header">
                    <i className="icon icon-back_arrow" onClick={() => toggleAction(LOGIN_MODES.LOGIN)}></i>
                    <div className="headerName">Create new password</div>
                </div>
                <Row>
                <Col className="col-md-12">
                <OTPComponent
                    component='login'
                    headerName="Forget Password"
                    disabled={isLoading}
                    type="forgot-pwd"
                    loginId={!isNaN(values.userName) ? "+91 " + loginId : loginId}
                    callback={(next, otpVal?: string) => next === 'reset' ? toggleAction(LOGIN_MODES.RESET_PASSWORD, otpVal) : next === 'resend' ?  toggleAction(LOGIN_MODES.FORGOT_PASSWORD) : toggleAction(LOGIN_MODES.LOGIN, loginId)}
                />  
                    </Col>
                    <Col className="col-md-12">
                        <FieldItem
                            {...FormElements.password}
                            value={values.password}
                            onChange={(...e) =>handleChange(FormElements.password.name, ...e)}
                            icon="lock"
                            inputType={'password'}
                            isDisabled={otp.length != 6 ? true : false}
                            touched={fields.password && fields.password.hasError}
                            error={fields.password && fields.password.errorMsg}
                        />
                    </Col>
                    <Col className="col-md-12">
                        <FieldItem
                            {...FormElements.confirmPassword}
                            value={values.confirmPassword}
                            onChange={(...e) => handleChange(FormElements.confirmPassword.name, ...e)}
                            icon="lock_open"
                            isDisabled={otp.length != 6 ? true : false}
                            inputType={'text'}
                            touched={fields.confirmPassword && fields.confirmPassword.hasError}
                            error={fields.confirmPassword && fields.confirmPassword.errorMsg}
                        />
                    </Col>
                    <div className="col-md-12">
                        <CustomButton type="primary" className="w-100" onClick={() => {toggleAction(LOGIN_MODES.DISPALY_MESSAGE)}} text="Next" loading={isLoading}></CustomButton>
                    </div>
                </Row>
                <div className='right-bg-footer'>
                    <span>Not a member ?</span>
                    <span onClick={() => navigate('/signup')}>Sign Up</span>
                </div>
            </div>
        );
    };

    function loginComponent() {
        return (
            <div className="login-container">
                <Row>
                    <div className="pb-sm-1 pb-md-2 col-md-12 col-xs-12" >
                        <FieldItem
                            {...FormElements.userName}
                            value={values.userName}
                            onChange={(...e) =>handleChange(FormElements.userName.name, ...e)}
                            touched={fields.userName && fields.userName.hasError}
                            error={fields.userName && fields.userName.errorMsg}
                        />
                    </div>
                    <div className="col-md-12 col-xs-12">
                        <FieldItem
                            {...FormElements.password}
                            value={values.password}
                            onChange={(...e) => handleChange(FormElements.password.name, ...e)}
                            icon={isShow ? "lock_open" : "lock"}
                            touched={fields.password && fields.password.hasError}
                            error={fields.password && fields.password.errorMsg}
                            inputType={isShow ? 'text' : 'password'}
                            iconClick={() => setisShow(!isShow)}
                        />
                    </div>
                </Row>
                <div className="forget-text pb-3 text-right" >
                   <span className="forget-text-span" onClick={() => forgetPassword()}>Forget Password</span>
                </div>
                <CustomButton type="primary" onClick={() => doLogin()} className="w-100" text="Login" loading = {isLoading}></CustomButton>
                <div className='right-bg-footer'>
                    <span>Not a member ?</span>
                    <span onClick={() => navigate('/signup')}>Sign Up</span>
                </div>
                <span className="version">V{packageJson.version}</span>

            </div>
        )    
    }

    return (
        <div className="login-page">
            <div className='icon-height'>
                        <i className="icon icon-home home-icon c-pointer" onClick={() => window.open("https://coguide.in", "_self")} />
            </div>
            <div className="logo-container">
                <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="logo" className="logo" />
            </div>
            
            {loginModes === LOGIN_MODES.LOGIN  && <div className="welcome-text">Hello, {greetText}</div>}
            {loginModes === LOGIN_MODES.LOGIN && loginComponent()}
            {loginModes === LOGIN_MODES.FORGOT_PASSWORD && resetComponent()}
            {loginModes === LOGIN_MODES.DISPALY_MESSAGE && renderSuccessMessage()}
            {/* {loginModes === LOGIN_MODES.FORGOT_PASSWORD && (
                <OTPComponent
                    component='login'
                    headerName="Forget Password"
                    disabled={isLoading}
                    type="forgot-pwd"
                    loginId={!isNaN(values.userName) ? "+91 " + loginId : loginId}
                    callback={(next, otpVal?: string) => next === 'reset' ? toggleAction(LOGIN_MODES.RESET_PASSWORD, otpVal) : next === 'resend' ?  toggleAction(LOGIN_MODES.FORGOT_PASSWORD) : toggleAction(LOGIN_MODES.LOGIN, loginId)}
                />
            )} */}
        </div>
    );
}
