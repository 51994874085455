
const CustomSelect = ({ value, values, onChange }) => {
  return (
    <div className="custom-select-wrapper">
      <select
        className="custom-select"
        value={value}
        onChange={onChange}
      >
        {values.map((el, index) => (
          <option value={el.value} key={index}>
            {el.label}
          </option>
        ))}
      </select>

      {/* Custom dropdown icon */}
      <div className="dropdown-icon"></div>
    </div>
  );
};

export default CustomSelect;