import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { Label } from "reactstrap";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import { QuestionFormElements } from "./FormElements";
import { FIELD_TYPES, FieldItem } from "../../widgets/fields";
import { QUESTION2LINK, QUESTION2TITLE } from "../sampleSize/Videos";
import { ArrowLeftOutlined, CaretLeftOutlined, CaretRightOutlined, FundViewOutlined, PieChartOutlined } from "@ant-design/icons";
import { postUserActivityLms, postUserTestActivityLms } from "./apiController";
import { Carousel } from "react-responsive-carousel";
import { CustomButton, showToaster, toasterTypes } from "../../widgets";

export default function ModuleVideo() {
    const location = useLocation();
    const [data, SetData] = useState<any>([]);
    const [values, fields, handleChange, { validateValues, reset, updateValue }] = useFieldItem(QuestionFormElements, {}, { onValueChange });
    const [complete, SetComplete] = useState(false);
    const navigate = useNavigate();
    const [modData, SetModData] = useState<any>(location.state);
    const scrollRef = React.useRef<any>();
    const [videoIndex, SetVideoIndex] = useState(1);
    const [testActivity, SettestActivity] = useState<any>([]);
    const [questionData, SetQuestion] = useState<any>([]);
    const [userActivityData, SetUserActivityData] = useState<any>([])

    useEffect(() => {
        let questionData: any = location.state;
        SetUserActivityData(questionData.data)
        SetQuestion(questionData.data && questionData.data.questions)
        questionData.data && questionData?.data.questions.map((item, index) => {
            let option: any = [];
            let Obj = {
                "questionId": item.questionId,
                "question": item.question,
                "options_selected": []
            }
            SettestActivity(prevState => [...prevState, Obj])

            item.options && item.options?.map((items) => {
                option.push({ label: items.option, value: items.value })
            })
            item.options = option
        })
        SetData(questionData.data)

    }, [])

    useEffect(() => {

    }, [videoIndex])

  

    /**
     * on change value
     */
    function onValueChange(name, value, values, fieldValues = null) {
        console.log(name, value)
        let option = questionData[name-1].options.filter(item => item.value == value)
        testActivity[name-1].options_selected = option
        SettestActivity(testActivity)
        console.log(testActivity)

        return [
            {
                ...values,
                [name]: value,
            },
        ];
    }

    /**
     * update the user activity
     */
    const updateActivity = async (isAttempt, isComplete, viewPercent) => {
        try {
            let questionData: any = location.state;
            let uid = questionData.uid
            let id = questionData.moduData
            let moduleid = questionData.data.moduleId
            console.log(Math.floor(viewPercent))
            let Obj = {
                "module_m_id": questionData.data.id,
                "isAttempted": isAttempt,
                "isCompleted": isComplete,
                "contentId": videoIndex-1,
                "content_type": "video",
                "view_percent": Math.floor(viewPercent)
            }
            
            let response = await postUserActivityLms(uid, moduleid, id, Obj);

        } catch (e: any) {
        }
    }

    /**
     * update user test activity
     */
    const updateTestActivity = async () => {
        try {
            let questionData: any = location.state;
            let uid = questionData.uid
            let id = questionData.moduData
            let moduleid = questionData.data.moduleId

            let Obj = {
                "module_m_id": questionData.data.id,
                "course_id": questionData.moduData,
                "usernumber": uid,
                "contentId": videoIndex-1,
                "content_type": "video",
                "module_id": moduleid,
                "user_answers": testActivity
            }

            let response = await postUserTestActivityLms(Obj);
            if(response.success){
                showToaster(toasterTypes.SUCCESS,"Answers submitted successfully")
                navigate("/coursemodule", { state: { id: modData.moduData, uid: modData.uid } })
            }else{
                showToaster(toasterTypes.ERROR,"Sorry something went wrong. Try Again")
            }

        } catch (e: any) {
        }
    }

    /**
     * video controls
     * @param e 
     */
    const getPlay = (e) => {
        let duration = e.target.duration;
        let currentTime = e.target.currentTime
        let isComplete = (duration * 90) / 100;
        if (currentTime > isComplete) {
            SetComplete(true)
            let viewpercent = (currentTime / duration) * 100;
            updateActivity(true, true, viewpercent)
        } else {
            let viewpercent = (currentTime / duration) * 100;
            updateActivity(true, false, viewpercent)
            SetComplete(false)
        }
    }

    /**
     * change video
     */
    const changeVideo = (index) => {
        if (index == "next") {
            SetVideoIndex(videoIndex + 1)
        } else if (index == "prev") {
            SetVideoIndex(videoIndex - 1)
        } else {
            SetVideoIndex(index)
        }
    }

    return (
        <>
            <div className="video-container">
                <Row className="header">
                    <Col md={6}>
                        <ArrowLeftOutlined style={{ color: "#fff", fontSize: "24px", margin: "0px 10px" }} onClick={() => navigate("/coursemodule", { state: { id: modData.moduData, uid: modData.uid } })} />
                        <span className="quest-head">Go Back</span>
                    </Col>
                    <Col md={6}>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>

                        <div className="left-panel">
                            <div className="video">
                                <video src={data?.videos && data?.videos[videoIndex - 1]} width="100%" height="100%" controls
                                    onPause={(e) => getPlay(e)}
                                    onEnded={(e) => getPlay(e)} ></video>
                            </div>
                            {userActivityData.user_activity && userActivityData?.user_activity[videoIndex-1]?.attempt_count > 0 ? 
                            <>
                            <Row style={{marginBottom:"20px"}}>
                                <Col md={6}>
                                    <div style={{fontSize:"26px", marginLeft:"20%"}}><FundViewOutlined style={{color:"#006BD9"}}/> {userActivityData.user_activity && userActivityData.user_activity[videoIndex-1]?.attempt_count} <span style={{fontSize:"16px", color:"rgb(127 123 123)"}}>attemps</span> </div>
                                </Col>
                            
                                <Col md={6}>
                                    <div style={{fontSize:"26px"}}><PieChartOutlined style={{color:"#006BD9"}}/> {userActivityData.user_activity && userActivityData.user_activity[videoIndex-1]?.view_percent}%</div>
                                </Col>
                            </Row>
                            <hr></hr>
                            </>
                            :""
                            }
                            <Row>
                                <Col md={1}>
                                    <button className="progress-previous-btn" onClick={() => changeVideo("prev")} disabled={videoIndex == 1 ? true : false}>
                                        <CaretLeftOutlined color='#fff' />
                                    </button>
                                </Col>
                                <Col md={9}>

                                    <div className="video-slider">
                                        <ul className='progressbar-ul'>
                                            {
                                                data?.videos && data?.videos.map((item, index) => {
                                                    return <li className="video-li" key={index}>
                                                        <div className={videoIndex == index + 1 ? "video-count-select" : "video-count"} onClick={() => changeVideo(index + 1)}>
                                                            {index + 1}
                                                        </div>
                                                    </li>
                                                })
                                            }
                                        </ul>
                                    </div>
                                </Col>
                                <Col md={1}>
                                    <button className="progress-next-btn" onClick={() => changeVideo("next")} disabled={data?.videos && data?.videos.length == videoIndex ? true : false}>
                                        <CaretRightOutlined />
                                    </button>

                                </Col>
                            </Row>

                            <div className="description">
                                <h4>{data?.title}</h4>
                                <h6><b>Description</b></h6>
                                <p>{data?.description}</p>
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>

                        <span className="quest-head-right">Questions</span>

                        {complete ?
                            <div className="right-panel">
                                {
                                    data?.questions && data?.questions.map((item, index) => {
                                        return <>
                                            <span className="question">{item.questionId + ". "}{item.question}</span>
                                            <div className="options">
                                                <FieldItem
                                                    name={item.questionId}
                                                    value={values[item.questionId]}
                                                    values={item.options}
                                                    type={FIELD_TYPES.RADIO_BUTTON}
                                                    isMandatory={true}
                                                    onChange={(...e) => handleChange(item.questionId, ...e)}
                                                    touched={fields[item.questionId] && fields[item.questionId].hasError}
                                                    error={fields[item.questionId] && fields[item.questionId].errorMsg}
                                                />
                                            </div>
                                            <hr></hr>
                                        </>
                                    })
                                }
                                <div className="save-btn">
                                    <CustomButton type="primary" onClick={() => updateTestActivity()} className="w-50 save" text="Save"></CustomButton>
                                </div>
                            </div>
                            :
                            ""
                        }

                    </Col>
                </Row>

            </div>
        </>
    )
}