
import { useEffect, useState } from "react";

const PlanDataCardMobile = (props: any) => {
    const { data, permissionParams, onActions, projectData } = props;

    const [moreOption, setmoreOption] = useState(false);
    useEffect(() => {
        console.log(data, projectData)
    }, []);

    const onClose = () => {
        setmoreOption(false)
    }
    return (
        <>
            <div className="plan-record-container">
                <div className="plan-head">
                    <div className="plan-name">
                        <span id="label">Plan Name : </span>
                        <span id="label-name">{data.plan_name}</span>
                    </div>
                    <div className={moreOption ? "more-options-open" : "more-options"} >
                        {
                            moreOption ?
                                <div className="more-container" onClick={() => setmoreOption(false)}>
                                    <div className="plan-actions">
                                        <div className="view" onClick={() => onActions(1, data)}>
                                            {
                                                permissionParams.view == "allowed" || Object.keys(permissionParams).length == 0 ?
                                                    <i
                                                        className="icon icon-eye"

                                                    ></i>
                                                    : ""
                                            }
                                            <span id="label-tag">View</span>

                                        </div>

                                        <div className="edit" onClick={() => onActions(2, data)}>
                                            {
                                                permissionParams.edit == "allowed" || Object.keys(permissionParams).length == 0 ?
                                                    !data?.permission || data?.permission == "write" ?
                                                        !data.locked ?
                                                            <i
                                                                className="icon icon-edit"
                                                            ></i>
                                                            : "-"
                                                        : ""
                                                    : ""
                                            }
                                            <span id="label-tag">Edit</span>
                                        </div>
                                        <div className="delete" onClick={() => onActions(3, data)}>
                                            {
                                                permissionParams.delete == "allowed" || Object.keys(permissionParams).length == 0 ?
                                                    !data?.permission || data?.permission == "write" ?
                                                        !data.locked ?
                                                            <i
                                                                className="icon icon-delete"
                                                            ></i>
                                                            : "-"
                                                        : ""
                                                    :
                                                    ""
                                            }
                                            <span id="label-tag">Delete</span>
                                        </div>
                                    </div>
                                </div> : ""
                        }

                        <i className={moreOption ? "icon icon-close" : "icon icon-more"} onClick={() => setmoreOption(!moreOption)}></i>

                    </div>
                </div>

                <div className="plan-records">
                    <span id="label-tag">Total Tasks</span>
                    <span id="label-tag-count">{data.total_tasks}</span>
                </div>
                <div className="plan-variables">
                    <span id="label-tag">Status</span>
                    <span id="label-tag-name">In Progress</span>
                </div>
                <div className="plan-info">
                    <div className="survey">
                        <span id="label-tag">Start Date</span>
                        {
                            <span>{(new Date(data.plan_start_date)).toLocaleDateString()}</span>
                        }

                    </div>
                    <div className="islocked">
                        <span id="label-tag">End Date</span>
                        {
                            <span>{(new Date(data.plan_end_date)).toLocaleDateString()}</span>
                        }

                    </div>
                    <div className="clone">
                        <span id="label-tag">Progress</span>
                        {
                            permissionParams.progress == "allowed" || Object.keys(permissionParams).length == 0 ?
                                <i
                                    className="icon icon-eye"
                                    onClick={() => onActions(5, data)}
                                ></i> : ""
                        }
                    </div>
                </div>

            </div>
        </>
    )
}

export default PlanDataCardMobile;