import { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { FIELD_TYPES, FieldItem } from "../../widgets/fields";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import { CustomButton } from "../../widgets";
import { GroupFormElements } from "./FieldElements";
import { getVariable } from "./apiController";

const AddGroupConditionMobile = (props) => {
    const [values, fields, handleChange, { validateValues, reset, updateValue, updateError }] = useFieldItem(GroupFormElements, {}, { onValueChange });
    const { variablelist, getData, previousData, groupDetails, formvariables } = props;
    const [variableList, setvariableList] = useState<any>([]);
    const [variableData, SetvariableData] = useState<any>([]);

    let componentObj = {};
    let ComputeOperators = [
        { value: "Select", label: "Select" },
        { value: ">", label: ">" },
        { value: "<", label: "<" },
        { value: "==", label: "==" },
        { value: ">=", label: ">=" },
        { value: "<=", label: "<=" },
        { value: "!=", label: "!=" },
        { value: "contains", label: "contains" },
        { value: "and", label: "and" },
        { value: "or", label: "or" },
        { value: "not", label: "not" },
        { value: "in", label: "in" },
        { value: "+", label: "+" },
        { value: "-", label: "-" },
        { value: "*", label: "*" },
        { value: "/", label: "/" },
        { value: "%", label: "%" },
        { value: "(", label: "(" },
        { value: ")", label: ")" },
        { value: "[", label: "[" },
        { value: "]", label: "]" },
        { value: "sqrt", label: "sqrt" },
        { value: "square", label: "square" },
        { value: "cube", label: "cube" },
        { value: "power", label: "power" }
    ]

    // const { variables } = props;
    let Operators = [{ value: ">", label: ">" },
    { value: "<", label: "<" },
    { value: "==", label: "==" },
    { value: ">=", label: ">=" },
    { value: "<=", label: "<=" },
    { value: "!=", label: "!=" },
    { value: "contains", label: "contains" }];

    let LogicalOpt = [{ value: "Select", label: "Select" },
    { value: "and", label: "and" },
    { value: "or", label: "or" }];

    useEffect(() => {
        console.log(formvariables)
        fetchInitialData();
    }, [])

    useEffect(() => {
        console.log(previousData)
    }, [variableList])

    /**
     * fetch intial value
     */
    const fetchInitialData = () => {
        if (previousData && previousData.length > 0) {
            setvariableList(previousData[0].variablelist)
            previousData[0].variablelist.map((items, index) => {
                updateValue({
                    ['values' + items.key]: items.values,
                    ['opt' + items.key]: items.opt,
                    ['val' + items.key]: items.val,
                    ['logic' + items.key]: items.logic,
                    ['newcode' + items.key]: items.newcode,
                })
            })
            let originalVal = GroupFormElements.origionalvalue.values.filter(item => item.value == previousData[0].OriginalVal.isOriginal)[0];
            updateValue({ "origionalvalue": originalVal })
            if (originalVal.value == "custom") {
                updateValue({ "defaultvalue": previousData[0].OriginalVal.customVal })
            }
        } else {
            addConditionCard();
        }
    }

     /**
     * get variable data for selected variable 
     * @param id 
     */
     const getVariableData = async (id, key) => {
        try {
            let response = await getVariable(id);
            console.log(key)
            if (key) {
                let fieldValues: any = [];
                response.field_value.map((item, i) => {
                    let Obj = {
                        label: item,
                        value: item
                    }
                    fieldValues.push(Obj)
                })
                variableList.filter(item => item.key == key).map((items) => {
                    items.variablelist = fieldValues
                })
                console.log(variableList)
            } else {
                SetvariableData(response.field_value)
            }
        } catch (e: any) {

        }
    }
    /**
     * add new item
     * create new obj and update the state variableList
     */
    const addConditionCard = () => {
        console.log(variablelist)
        if (groupDetails.grouptype.label == "Categorical") {
            componentObj['key'] = variableList[variableList?.length - 1] ? variableList[variableList.length - 1].key + 1 : 1;
            componentObj['newcode'] = "";
            componentObj['fieldid'] = variablelist.id;
            componentObj['variablelist'] = [];
            componentObj['field_use_type'] = variablelist.field_type;
            let fieldValues: any = [];
            variablelist.field_value.map((item, i) => {
                let Obj = {
                    label: item,
                    value: item
                }
                fieldValues.push(Obj)
            })
            componentObj['field_value'] = fieldValues;
            setvariableList(prevState => [...prevState, componentObj])
        } else if (groupDetails.grouptype.label == "Numerical") {
            componentObj['key'] = variableList[variableList?.length - 1] ? variableList[variableList.length - 1].key + 1 : 1;
            componentObj['newcode'] = "";
            componentObj['fieldid'] = variablelist.id;
            componentObj['variablelist'] = [];
            componentObj['field_use_type'] = variablelist.field_type;
            let fieldValues: any = [];
            componentObj['field_value'] = fieldValues;
            setvariableList(prevState => [...prevState, componentObj])
        } else if (groupDetails.grouptype.label == "Compute") {
            componentObj['key'] = variableList[variableList?.length - 1] ? variableList[variableList.length - 1].key + 1 : 1;
            componentObj['newcode'] = "";
            componentObj['fieldid'] = variablelist.id;
            componentObj['variablelist'] = [];
            componentObj['field_use_type'] = variablelist.field_type;
            let fieldValues: any = [];
            // variablelist.field_value.map((item, i) => {
            //     let Obj = {
            //         label: item,
            //         value: item
            //     }
            //     fieldValues.push(Obj)
            // })
            console.log(formvariables)
            componentObj['field_value'] = fieldValues;
            setvariableList(prevState => [...prevState, componentObj])
        }
    }
    /**
    * on value change update form data
    * @param name 
    * @param value 
    * @param values 
    * @param fieldValues 
    * @returns 
    */
    function onValueChange(name, value, values, fieldValues = null) {
        let resetValue = {};
        console.log(name,value)
        console.log(variableList)
        if (name.replace(/[^A-Za-z]/g, "") == "logic" && value.label != "Select") {
            addConditionCard()
        }else if (value?.type == "radioButton") {
            getVariableData(value.id, name[name.length - 1])
            console.log(variableList)
        } 
        return [{
            ...values,
            ...resetValue,
            [name]: value,
        }]
    }

    /**
     * remove item from variable list
     */
    const removeItem = (key) => {
        let updateList = variableList.filter(item => item.key != key);
        setvariableList(updateList)
    }

    /**
     * add data
     */
    const addData = () => {
        let Response: any = [];
        let VariableList: any = [];
        if (groupDetails.grouptype.label == "Categorical") {
            variableList.map((item, index) => {
                item.values = values['values' + item.key]
                item.newcode = values['newcode' + item.key]
                VariableList.push(item)
            })

            let origionalVal = { isOriginal: values.origionalvalue.value, customVal: values.origionalvalue.value !== "original" ? values.defaultvalue : "" };
            Response.push({ variablelist: VariableList, OriginalVal: origionalVal })
        } else if (groupDetails.grouptype.label == "Numerical") {
            variableList.map((item, index) => {
                item.values = groupDetails.variable
                item.newcode = values['newcode' + item.key]
                item.opt = values['opt' + item.key]
                item.val = values['val' + item.key]
                item.logic = values['logic' + item.key]
                VariableList.push(item)
            })

            let origionalVal = { isOriginal: values.origionalvalue.value, customVal: values.origionalvalue.value !== "original" ? values.defaultvalue : "" };
            Response.push({ variablelist: VariableList, OriginalVal: origionalVal })

        }else{
            variableList.map((item, index) => {
                item.values = values['values' + item.key]
                item.newcode = values['newcode' + item.key]
                item.opt = values['opt' + item.key]
                item.val = values['val' + item.key]
                item.logic = values['logic' + item.key]
                VariableList.push(item)
            })

            let origionalVal = { isOriginal: values.origionalvalue.value, customVal: values.origionalvalue.value !== "original" ? values.defaultvalue : "" };
            Response.push({ variablelist: VariableList, OriginalVal: origionalVal })

        }

        getData(Response);
        props.onClose();

    }
    return (
        <>
            <div className="add-condition-group-container">
                <div className="header-container">
                    <div className='pl-3 pt-4 form-name'>
                        {/* <Image className='profile-image' alt='Profile' src={`${process.env.PUBLIC_URL}/images/logo.png`} /> */}
                        Create Conditions
                    </div>
                    <div className='pt-4 closeicon'>
                        <i className='icon icon-close' onClick={() => props.onClose()} />
                    </div>
                </div>
                <div className="condition-head p-3 mt-2">
                    <div className="counts">
                        Conditions | {variableList && variableList.length}
                    </div>
                    <div className="add-more" onClick={() => addConditionCard()}>
                        <i className="icon icon-add"></i>
                        <span>Add More</span>
                    </div>
                </div>
                <div className="condition-items">
                    {
                        variableList && variableList.map((item, index) => {
                            return (
                                <div className="condtion-card mt-2 ml-3 mr-3" key={index}>
                                    <div className="card-head p-2">
                                        <span>Condtion{index + 1}</span>
                                        {
                                            index != 0 &&
                                            <i className="icon icon-close" onClick={() => removeItem(item.key)}></i>
                                        }
                                    </div>
                                    <div className="card-body">
                                        {
                                            groupDetails.grouptype.label == "Categorical" ?
                                                <FieldItem
                                                    name={"values" + item.key}
                                                    type={FIELD_TYPES.MULTI_SELECT}
                                                    value={values["values" + item.key]}
                                                    values={item.field_value}
                                                    label={"Values"}
                                                    onChange={(...e) => handleChange("values" + item.key, ...e)}
                                                />
                                                :
                                                groupDetails.grouptype.label == "Numerical" ?
                                                <div className="variable mb-2">
                                                    {
                                                        groupDetails.variable.label
                                                    }
                                                </div>
                                                :""
                                        }

                                        {
                                            groupDetails.grouptype.label == "Numerical" ?
                                                <>
                                                    <FieldItem
                                                        name={"opt" + item.key}
                                                        type={FIELD_TYPES.DROP_DOWN}
                                                        value={values["opt" + item.key]}
                                                        values={ComputeOperators}
                                                        onChange={(...e) => handleChange("opt" + item.key, ...e)}
                                                    />
                                                    <FieldItem
                                                        name={"val" + item.key}
                                                        value={values["val" + item.key]}
                                                        placeholder="Value"
                                                        onChange={(...e) => handleChange("val" + item.key, ...e)}
                                                        touched={fields["val" + item.key] && fields["val" + item.key].hasError}
                                                        error={fields["val" + item.key] && fields["val" + item.key].errorMsg}
                                                    />
                                                    <FieldItem
                                                        name={"logic" + item.key}
                                                        type={FIELD_TYPES.DROP_DOWN}
                                                        value={values["logic" + item.key]}
                                                        values={groupDetails.grouptype.label == "Numerical" ? LogicalOpt : ComputeOperators}
                                                        onChange={(...e) => handleChange("logic" + item.key, ...e)}
                                                    />
                                                </>
                                                : ""
                                        }
                                        
                                        {
                                            groupDetails.grouptype.label == "Compute" ?
                                                <>
                                                    
                                                    <FieldItem
                                                        name={"values" + item.key}
                                                        type={FIELD_TYPES.DROP_DOWN}
                                                        value={values["values" + item.key]}
                                                        values={formvariables}
                                                        onChange={(...e) => handleChange("values" + item.key, ...e)}
                                                    />

                                                    <FieldItem
                                                        name={"opt" + item.key}
                                                        type={FIELD_TYPES.DROP_DOWN}
                                                        value={values["opt" + item.key]}
                                                        values={ComputeOperators}
                                                        onChange={(...e) => handleChange("opt" + item.key, ...e)}
                                                    />

                                                    <FieldItem
                                                        name={"val" + item.key}
                                                        value={values["val" + item.key]}
                                                        placeholder="Value"
                                                        type={values["values" + item.key]?.type == "radioButton" || values["values" + item.key]?.type == "checkbox" ? values["opt" + item.key]?.label == "contains" ? FIELD_TYPES.MULTI_SELECT : FIELD_TYPES.DROP_DOWN : FIELD_TYPES.TEXT}
                                                        values={values["values" + item.key]?.type == "radioButton" ? item.variablelist : ""}
                                                        onChange={(...e) => handleChange("val" + item.key, ...e)}
                                                        touched={fields["val" + item.key] && fields["val" + item.key].hasError}
                                                        error={fields["val" + item.key] && fields["val" + item.key].errorMsg}
                                                    />

                                                    <FieldItem
                                                        name={"logic" + item.key}
                                                        type={FIELD_TYPES.DROP_DOWN}
                                                        value={values["logic" + item.key]}
                                                        values={ComputeOperators}
                                                        onChange={(...e) => handleChange("logic" + item.key, ...e)}
                                                        touched={fields["logic" + item.key] && fields["logic" + item.key].hasError}
                                                        error={fields["logic" + item.key] && fields["logic" + item.key].errorMsg}
                                                    />

                                                    
                                                </>
                                                : ""
                                        }
                                        {index + 1 == variableList.length || values["logic" + item.key]?.label == "Select" ?
                                            <FieldItem
                                                name={"newcode" + item.key}
                                                value={values["newcode" + item.key]}
                                                values={[]}
                                                placeholder="New Code"
                                                onChange={(...e) => handleChange("newcode" + item.key, ...e)}
                                                touched={fields["newcode" + item.key] && fields["newcode" + item.key].hasError}
                                                error={fields["newcode" + item.key] && fields["newcode" + item.key].errorMsg}
                                            />
                                            :
                                            values["newcode" + item.key] ?
                                                <FieldItem
                                                    name={"newcode" + item.key}
                                                    value={values["newcode" + item.key]}
                                                    values={[]}
                                                    placeholder="New Code"
                                                    onChange={(...e) => handleChange("newcode" + item.key, ...e)}
                                                    touched={fields["newcode" + item.key] && fields["newcode" + item.key].hasError}
                                                    error={fields["newcode" + item.key] && fields["newcode" + item.key].errorMsg}
                                                />
                                                : ""}

                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className="condtion-card-org mt-2 ml-3 mr-3">
                        <div className="card-head p-2">
                            <span>Default Condtion</span>
                        </div>
                        <div className="card-body">
                            <FieldItem
                                {...GroupFormElements.origionalvalue}
                                value={values.origionalvalue}
                                onChange={(...e) => handleChange(GroupFormElements.origionalvalue.name, ...e)}
                                touched={fields.origionalvalue && fields.origionalvalue.hasError}
                                error={fields.origionalvalue && fields.origionalvalue.errorMsg}
                            />

                            {values?.origionalvalue && values?.origionalvalue?.value == "custom" ?
                                <FieldItem
                                    {...GroupFormElements.defaultvalue}
                                    value={values.defaultvalue}
                                    onChange={(...e) => handleChange(GroupFormElements.defaultvalue.name, ...e)}
                                    touched={fields.defaultvalue && fields.defaultvalue.hasError}
                                    error={fields.defaultvalue && fields.defaultvalue.errorMsg}
                                /> : ""}
                        </div>
                    </div>

                    <CustomButton className="create-btn mt-2" text="Add" type={"primary"} onClick={() => addData()}></CustomButton>
                </div>

            </div>
        </>
    )
}

export default AddGroupConditionMobile;