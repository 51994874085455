import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom"
import { setLoading } from "../../store/slices/auth";
import { expectedDateFormat } from "../../utils/Utils";
import { CustomButton, showToaster, toasterTypes } from "../../widgets";
import { FieldItem, FIELD_TYPES } from "../../widgets/fields";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import { ProjectList } from "../dataCollection/FormElements";
import { getUserProjects } from "../project/apiController";
import { getProjectEventDetail, RegisterEvent } from "./apiController";


export default function SubmissionInfo(){
    const initialValues = React.useMemo(() => ({ project: ''}), []);
	const [values, fields, handleChange, { validateValues, reset, updateValue }] = useFieldItem(ProjectList, initialValues, { onValueChange });  
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selectedData, SetSelectedData] = useState<any>([]);
    const [scheduleData, SetScheduleData] = useState<any>([]);
    const [projets, SetProjects] = useState<any>([]);
    const [projectlist, SetProjectlist] = useState<any>([]);
    useEffect(()=>{
        fetchInitialData();
    },[])

    /**
     * fetch intial data
     * read location data from state shared from navigation
     * update state with schedule api response
     */
    const fetchInitialData = async () => {
        try {
            dispatch(setLoading(true));
            SetProjectlist([]);
            const temp : any = location.state;
            SetSelectedData(temp.data);
            console.log(temp.data)
            const getSchedule = await getProjectEventDetail(temp.data.project_event_id);
            SetScheduleData(getSchedule.data)
            let institute = getSchedule.data.institutecode; 
            console.log(institute);
            const getUserProjectData = await getUserProjects(temp.data.usernumber);
            SetProjects(getUserProjectData);

            getUserProjectData.data.map(item => {
                // console.log(item.TopicDesc)
                // console.log(item.affiliation_info['institutecode']);
                if(item.affiliation_info['institutecode'].split("-")[0] == institute.split("-")[0] ) {
                SetProjectlist(prevData => [...prevData,{label:item.TopicDesc,value:item.TopicID}])
                }
            })
            dispatch(setLoading(false));

        } catch (e:any) {
            dispatch(setLoading(false));
            throw e;
        }
    }

      /**
     * on value change update form data
     * @param name 
     * @param value 
     * @param values 
     * @param fieldValues 
     * @returns 
     */
   	function onValueChange (name, value, values, fieldValues=null) {
		let resetValue = {};
        updateValue({project:value})
        console.log(values)

		return [{
			...values,
			...resetValue,
			[name]: value,
		}]
    }

    /**
     * On Register trigger
     * @obj
     * @scheduleId Id from schedule data selected id
     * @topicid TopicID of selected project
     * @pieactiveid Id from piractive api response of selected item
     */
    const onRegister = async() =>{
        let response;
        try {
            dispatch(setLoading(true));

            let obj = {
                scheduleId: scheduleData.id,
                topicid:values.project.value,
                pieactiveid:selectedData.id
            }
            response = await RegisterEvent(obj);
            // console.log(response)
            if(response.success == true){
                showToaster(toasterTypes.SUCCESS,response.message)
            }else {
            dispatch(setLoading(false));
            showToaster(toasterTypes.SUCCESS,response.message)
            }
            navigate("/submissions");
        } catch (e:any) {
            dispatch(setLoading(false));
            throw e
        }
    }

    /**
     * on skip redirect to submission page
     */
    const onSkip = () =>{
        navigate("/submissions");
    }
   
    return (
        <>
            <div className="submission-container">
                <Row>
                    <Col md={4}>
                        <div className="project-data">
                           <div className="summary-head">
                               Schedule Summary
                           </div>
                           <h4 className="project-name">{selectedData && selectedData?.project_event_name}</h4>
                           <hr></hr>
                           <div className="institute-data">
                             <span className="institute-label">Institution Name:</span>
                             <span className="institude-name">{selectedData && selectedData?.institutename}</span>
                           </div>
                           <hr></hr>
                           <div className="document-data">
                             <span className="document-label">Document Submission Deadline:</span>
                             <span className="document-name">{expectedDateFormat(scheduleData && scheduleData?.document_submission_date, 'DD MMM YYYY')}</span>
                           </div>
                           <hr></hr>
                           <Row>
                            <Col md={4}>
                                <div className="document-data">
                                <span className="time-label">Schedule Date:</span>
                                <span className="time-name">{expectedDateFormat(scheduleData?.day_plan && scheduleData?.day_plan?.schedule_date, 'DD MMM YYYY')}</span>
                            </div>
                            </Col>
                            <Col md={4}>
                                <div className="document-data">
                                <span className="time-label">From Time:</span>
                                <span className="time-name">{scheduleData?.day_plan && scheduleData?.day_plan[0]?.start_time}</span>
                            </div>
                            </Col>
                            <Col md={3}>
                                <div className="document-data">
                                <span className="time-label">To Time:</span>
                                <span className="time-name">{scheduleData?.day_plan && scheduleData?.day_plan[0]?.end_time}</span>
                            </div>
                            </Col>
                           </Row>
                        </div>
                    </Col>

                    <Col md={8}>
                        <div className="more-detail">
                            <h4 className="register-head">Register For Schedule</h4>
                            <div className="project-list">
                            <FieldItem
                                    {...ProjectList.fieldType}
                                    value={values.project}
                                    values={projectlist?projectlist : []}
                                    onChange={(...e) => handleChange(ProjectList.fieldType.name, ...e)}
                                    touched={ProjectList.fieldType && ProjectList.fieldType.hasError}
                                    error={ProjectList.fieldType && ProjectList.fieldType.errorMsg}
                                /> 
                                
                            </div>
                            <div className="submit-bottom">
                                    <CustomButton
                                    className="register-btn"
                                    isDisabled={!values.project ? "disabled" : ""}
                                    type="primary"
                                    text="Register"
                                    onClick = {()=>onRegister()}
                                    ></CustomButton>
                                <span className="skip" onClick={()=>onSkip()}>Skip For Now</span>
                            </div>
                        </div>
                    </Col>
                </Row>
               
            </div>
        </>
    )
}