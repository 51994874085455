import { Service } from 'wax-prosemirror-core';
import OutlineArea from './OutlineArea';

export default class OutlineSideBar extends Service {
  boot() {
    const layout = this.container.get('Layout');

    layout.addComponent('outlineArea', OutlineArea);
  }

  register() {
    const outlineConfig = this.config.get('config.OutlineSideBar');
  }
}

