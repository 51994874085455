import { LeftOutlined, SendOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Card, Form, Image, ListGroup } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation, useNavigate } from "react-router-dom";
import { Loader } from "../common/FullScreenLoader/fullScreenLoader";
import { getChatHistory, sendMessage } from "./apiController";

export default function PeopleChatMobile(props) {
    const location = useLocation();
    const [userInfo, SetUserInfo] = useState<any>(location.state);
    const [ChatHistory, SetgetChatHistory] = useState<any>([]);
    const [pageNo, SetPageNo] = useState(1);
    const [coguideUser, SetCoguideUser] = useState<any>({});
    const [loader, Setloader] = useState(false);
    const [messageTxt, SetMessageTxt] = useState("");
    const [perPage, SetPerPage] = useState(20);
    const [reached, Setreached] = useState(false);
    const navigate = useNavigate();
    const listInnerRef = useRef<HTMLInputElement>(null);


    useEffect(() => {
        Setloader(true)
        getChatHistory(userInfo?.data?.id, pageNo, perPage, userInfo.id).then(response => {
            SetgetChatHistory(response.messages);
        })
        Setloader(false)
        SetCoguideUser(localStorage.getItem("coguide_user"));

    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            getChatData()
        }, 8000);
        return () => clearInterval(interval);
      }, []);

    useEffect(() => {

    }, [ChatHistory])

    /**
     * get chat data
     */
    const getChatData = async () =>{
        try {
            SetPageNo(1);
            SetPerPage(20);
            let getMessageList = await getChatHistory(userInfo?.data?.id, 1, 20, userInfo.id);
            SetgetChatHistory(getMessageList.messages);
            Setreached(false);            
        } catch (e: any) {
            throw e
        }
    }

    /**
     * scroll to bottom get next set of data
     */
    const onScroll = async () => {
      
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            //alert(reached)
           if (-Math.abs(Math.ceil(Math.abs(scrollTop)))  === clientHeight - scrollHeight) {
                try {
                    SetPageNo(pageNo + 1);
                    SetPerPage(perPage);
                    let getMessageList = reached ? "" : await getChatHistory(userInfo?.data?.id, pageNo + 1, perPage, userInfo.id);

                    if (!getMessageList.messages) {
                        Setreached(true);
                        console.log(getMessageList.messages)
                    }
                    SetgetChatHistory([...ChatHistory, ...getMessageList.messages]);
                } catch (e: any) {
                    throw e
                }
            }
        }
    };

    /**
     * on send action trigger message send call
     */
    const sendMessageAction = async () => {
        if (messageTxt) {
            try {
                SetPageNo(1);
                SetPerPage(20);
                let sendmessage = await sendMessage(messageTxt, userInfo.id, userInfo.id, userInfo?.data?.id);
                SetMessageTxt("");
                let getMessageList = await getChatHistory(userInfo?.data?.id, 1, 20, userInfo.id);
                SetgetChatHistory(getMessageList.messages);
                Setreached(false);
                
            } catch (e: any) {
                throw e
            }
        }
    }

    return (
        <>
            <div className="people-chat-mob">
                <div className="info-bar" onClick={() => navigate("/people", { state: { projectid: userInfo?.data?.id, projectdata: userInfo?.data, type: userInfo?.type } })}>
                    <LeftOutlined className="arrow-back" />
                    <Image className="profile-img" src={userInfo?.userdp} />
                    <span className="info-project-detail">{userInfo?.inviteename}</span>
                </div>
                {loader ?
                    <div className="speech-wrapper-load"><Loader loaderText="Fetching Messages..." /></div>
                    :
                    <div className="message-list" onScroll={() => onScroll()} ref={listInnerRef}>
                        {ChatHistory?.map((item, i) => {
                            return <>
                                <div className="speech-wrapper">
                                    {JSON.parse(coguideUser).id == item.from_user_id ?
                                        <div className="chatbox triangle right-top alt">
                                            <div className="txt">
                                                <p className="name"></p>{item.message}
                                                <span className="timestamp">{moment(item.sent_at).format("hh:mm a")}
                                                    {!item.read ?
                                                        <img src={`${process.env.PUBLIC_URL}/images/check-mark-icon.svg`} alt='logo' style={{ width: "15px", height: "15px", padding: "0px 2px" }} />
                                                        :
                                                        <img src={`${process.env.PUBLIC_URL}/images/double-tick-icon.svg`} alt='logo' style={{ width: "20px", height: "20px", padding: "0px 2px" }} />}</span>                                    </div>
                                        </div>
                                        :
                                        <div className="speech-wrapper">
                                            <div className="chatbox_other triangle left-top">
                                                <div className="txt"><p className="name">
                                                </p>{item.message}
                                                    <span className="timestamp-sender">{moment(item.sent_at).format("hh:mm a")}</span>                                              </div>
                                            </div>
                                        </div>
                                    }
                                </div>

                            </>
                        })}
                    </div>
                }
                <div className="message-text">
                    <TextArea placeholder="Message" className="message-area" value={messageTxt} onChange={(e) => SetMessageTxt(e.target.value)} />
                    <div className="send-message"> {messageTxt ? <SendOutlined onClick={() => sendMessageAction()} /> : <SendOutlined/>}</div>
                </div>
            </div>
        </>
    )
}