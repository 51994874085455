import ModalComponent from "../common/Modal/modal";
// import { Button, Checkbox, Form, Input, Select } from 'antd';
import { Col, Row } from "reactstrap";
import { FieldItem, FIELD_TYPES } from "../../widgets/fields";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import React, { useEffect, useState } from "react";
import InviteCard from "./InviteCard";
import { Button, Form } from "react-bootstrap";
import { getUsersList, sendUserInvite } from "./apiController";
import { Loader } from "../common/FullScreenLoader/fullScreenLoader";
import { useNavigate } from "react-router-dom";
import { getRoles } from "../dataCollection/apiController";
import { CustomButton } from "../../widgets";


export default function InviteModal(props){
    const FormElements = {
		search: {
			name: "search",
			placeholder: "Search",
			label: "",
			type: FIELD_TYPES.SEARCH,
			isMandatory: false,
		},
        usertype:{
            name: "usertype",
			placeholder: "User Type",
			label: "",
			type: FIELD_TYPES.DROP_DOWN,
			isMandatory: false,
        }
	}

    const initialValues = React.useMemo(() => ({ search: '',usertype:''}), []);
	const [values, fields, handleChange, { validateValues, reset, updateValue,updateError }] = useFieldItem(FormElements, initialValues, { onValueChange });  
    const [emailValue, SetEmail] = useState("");
    const [typeValue, SetType] = useState("");
    const [userTypes,SetUserTypes] = useState<any | []>([]);
    const [userList,SetUserList] = useState<any | []>([]);
    const [sentRequest, SetsentRequest] = useState(false);
    const [progress,SetProgress] = useState(false);
    const [pageno,Setpageno]= useState(1);
    const[perpage,Setperpage] = useState(50);
    const [totalusers,SettotalUser] = useState(0);
    const navigate = useNavigate();


    useEffect(()=>{
        try{
            const usertypes = getRoles();
            const userList = getUsersList("",pageno,perpage);
            userList.then(data => {
                SetUserList(data.data);
                SettotalUser(data["total-count"])
            })
            usertypes.then(data => {
                SetUserTypes(data.data.map(({
                    key: value,
                    name: label
                }) => ({
                    value,
                    label
                })));
            })
        }catch(e:any){
            throw e
        }
       
    },[1])

     useEffect(()=>{
    },[userTypes,userList]);

 

    const getValues = () =>{
        console.log(values.search)
    }

    const inviteAction = async(e)=>{
        //e.preventDefault();
        //console.log("type:::"+typeValue);
        try{
            if(emailValue || typeValue){
                SetProgress(true);
                const response = await sendUserInvite({invitee_email:emailValue,invitee_role:typeValue,invitee_name:"",project_id:props.projectid,invitee_id:null});
                if(response.success){
                    SetProgress(false);
                    SetsentRequest(true);
                }
            }
        }catch(e:any){
            throw e;
        }
      
       
    }

    const setRequestSent = () =>{
        SetEmail("");
        SetType("");
        SetsentRequest(!sentRequest);
    }

    /**
     * 
     * @param name 
     * @param value 
     * @param values 
     * @param fieldValues 
     * @returns 
     */
    async function onValueChange (name, value, values, fieldValues=null) {
        if(name == "search"){
            updateValue({search:value})
            try {
                const userList = await getUsersList(value,1,50);
                SetUserList(userList.data);
                Setpageno(1)
                Setperpage(perpage)    
            } catch (e:any) {
                throw e
            }
        }else{
            updateError('usertype', {hasError: false, errorMsg: ""})
            updateValue({usertype:value})
        }
       
		return [{
			...values,
			[name]: value,
		}]
    }
    /**
     * on click invite view profile data
     */
    const viewProfile = async(value) =>{
        console.log(props.projectid)
        // const response = await sendUserInvite({invitee_email:value.emailid,invitee_role:"mentor",invitee_name:"",project_id:props.projectid,invitee_id:value.id});
        // if(response.success){
            
        // }
        if(values.usertype){
            updateError('usertype', {hasError: false, errorMsg: ""})
           navigate('/peopleprofile',{ state: { userid: value.id, data:value, pagetype:"invite", projectId: props.projectid.projectid, usertype:values.usertype.value} });
        }else{
            updateError("usertype", {hasError:true, errorMsg: `Please select User Type to invite`});
        }
    }

    /**
     * on scroll bottom 
     * call next set of data for users profile to invite
     */
     const handleScroll = async (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) { 
            try {
                const userListData = await getUsersList("",pageno+1,perpage);
                Setpageno(pageno+1)
                Setperpage(perpage)
                let tempArray : any = [];
                tempArray = [...userList,...userListData.data] 
                SetUserList(tempArray)
                console.log(userList)
            } catch (e:any) {
                throw e
            }

            console.log("bottom")
        }
     }

    return(
        <>
            <ModalComponent
               width="1000px"
               isOpen={true}
               onClose={() => props.onClose()}
               header="Invite People"
               centered={true}
               customClass='invite-modal people-modal'
               body={
                <div className="invite-head">
                    <div className="invite-bar">
                        {
                            !sentRequest ? 
                            <Form
                            name="basic"
                            autoComplete="off"
                            className="invite-form-items"
                            >                  
                                 <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm={2}>
                                    Invite with Email
                                    </Form.Label>
                                    <Col sm={5}>
                                    <Form.Control type="email" onChange={(e) => SetEmail(e.target.value)} placeholder="Email" />
                                    {/* <span style={{display:"block", fontSize:"10px", color:"red"}}>Please select </span> */}

                                    </Col>
                                    <Col sm={2}>                                    
                                    <Form.Select className="select-user" onChange={(e) => SetType(e.target.value)}>
                                    <option value="">User Type</option>
                                        {
                                            userTypes?.map((item,i)=>{
                                                return  <option value={item.value}>{item.label}</option>
                                            })
                                        }                                   
                                    </Form.Select>
                                    </Col>

                                    <Col>
                                        {
                                            !progress ? 
                                              <CustomButton className="invite-btn" type="primary" disabled={!emailValue || !typeValue ? true : false} onClick={(e) => inviteAction(e)} text={`Invite`}></CustomButton>
                                            :
                                                <div className="invite-load">  
                                                <Loader loaderText=" "/>
                                                </div>

                                        }
                                  </Col>
                                </Form.Group> 
                     
                            </Form>
                            :
                                <div className="success-request">
                                    <span className="tick">
                                    &#10003;
                                    </span>
                                    Invite sent to <span className="invite-message">{emailValue} </span>successfully
                                    <span className="invite-more" onClick={() => setRequestSent()}> Invite More</span>
                                </div>
                        }                      
                        
                    </div>

                    <Row className='m-0 mb-3 invite-search'>
                        <Col className='p-0 d-flex m-0' md={6} xs={12}>
                        {/* {totalusers} Available users */}
                            <div className='project-header mr-4 pt-2'>Invite using profiles</div>
                            <FieldItem
                                {...FormElements.search}
                                value={values.search}
                                values={values.search}
                                onChange={(...e) => handleChange(FormElements.search.name, ...e)}
                                touched={fields.search && fields.search.hasError}
                                error={fields.search && fields.search.errorMsg}
                            />
                        </Col>
                        <Col md={6} xs={12}>
                            <FieldItem 
                              className="usertype-invite"
                              {...FormElements.usertype}
                              value={values.usertype}
                              values={userTypes?userTypes:[]}
                              onChange={(...e) => handleChange(FormElements.usertype.name, ...e)}
                              touched={fields.usertype && fields.usertype.hasError}
                              error={fields.usertype && fields.usertype.errorMsg}
                            />
                            
                        </Col>
                    </Row>
                    
                    <div className="invite-items" onScroll={handleScroll}>
                                    
                                {
                                    userList && userList?.map((items,i) => {
                                        return <InviteCard
                                            badgeimg={""}
                                            profileimg={items.photo_link}
                                            name={items.display_name || items.first_name+" "+items.last_name}
                                            role={items.profession}
                                            desc={items.emailid}
                                            userid={items.id}
                                            viewProfiledata={()=>viewProfile(items)}
                                      />
                                    })
                                }
                                {
                                    userList ? "" : "No Data Found"
                                }
                              
                    </div>
                </div>
               }
               footer={
                <div>
                </div>
            }
               />
        </>
    );
}