import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap'
import { setLoading } from '../../store/slices/auth';
import Affiliation from './Affiliation';
import { createProject, updateProject } from './apiController';
import Keywords from './Keywords';
import ObjectiveCreation from './ObjectiveCreation';
import Success from './Success';
import TitleCreation from './TitleCreation';

export default function Progress(props) {

    const progressData = [{
        title: 'Affiliation & Funding info'
    }, {
        title: 'Title Creation'
    }, {
        title: 'Objectives'
    }, {
        title: 'Keywords'
    }];

    const [selectedProgress, setselectedProgress] = useState<any | null>(null);
    const [affiliationState, setaffiliationState] = useState<any | null>(null);
    const [titleState, settitleState] = useState<{ title: '' } | null>(null);
    const [objectiveState, setobjectiveState] = useState<any | null>(null);
    const [keywordState, setkeywordState] = useState<any | null>(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (props.pageState) {
            const { selectedProgress, affiliationState, titleState, objectiveState,keywordState } = props.pageState;
            setselectedProgress(selectedProgress);
            setaffiliationState(affiliationState);
            settitleState(titleState);
            setobjectiveState(objectiveState);
            setkeywordState(keywordState);
        } else {
            setselectedProgress(0);
        }
    }, [props.pageState]);

    async function onSubmit(data) {
        try {
            let userData: any = localStorage.getItem('coguide_user');
            if (userData) {
                userData = JSON.parse(userData);
                dispatch(setLoading(true));
                console.log(data)
                let topic: any = {
                    "TopicDesc": titleState?.title,
                    "TopicBigDesc": objectiveState.data,
                    "SpecCodes": [{ "speccode": userData.PGCourse }],
                    "UserNumber": userData.UserNumber,
                    "affiliation_info": {
                        "country": affiliationState.country.value,
                        "state": affiliationState.state.value,
                        "city": affiliationState.city,
                        "other_institute": false,
                        "institute_name": affiliationState.college.label,
                        "institutecode": affiliationState.college.value,
                        "project_type": affiliationState.projectType.value
                    },
                    "funding_info": {
                        "funded_project": affiliationState.funding.label === 'Yes',
                        "fund_type": affiliationState.fundType?.value || '',
                        "quantum_of_funding": affiliationState?.quantity || '',
                        "funding_agency": affiliationState?.fundingAgency || '',
                        "funding_status": affiliationState.approvalStatus?.value || 'NA'
                    },
                    "key_words": data.keyword.map((a: any) => a),
                    "strict_mode":data.strictmode
                }
                if(props.projectData) {
                    topic.TopicCode = props.projectData.TopicCode || props.projectData.TopicID;
                    await updateProject(topic, props.projectData.TopicCode || props.projectData.TopicID);
                } else {
                    await createProject(topic);
                }
                dispatch(setLoading(false));
                setselectedProgress(4);
            }
        } catch (e: any) {
            dispatch(setLoading(false));
        }
    }

    return (
        <Row className='m-0 progress-page'>
            <Col className='p-0 left-col' md={9}>
                {selectedProgress !== null && (
                    selectedProgress === 0 ? <Affiliation {...props} next={(data) => { setaffiliationState(data); setselectedProgress(1) }} previousState={affiliationState} /> :
                        selectedProgress === 1 ? <TitleCreation {...props} previous={() => setselectedProgress(0)} next={(data) => { settitleState(data); setselectedProgress(2); }} previousState={titleState} onTitle={(data) => { settitleState(data); props.onPage('title', { selectedProgress, affiliationState, titleState: data, objectiveState }) }} /> :
                            selectedProgress === 2 ? <ObjectiveCreation {...props} previous={() => setselectedProgress(1)} next={(data) => { setobjectiveState({ data }); setselectedProgress(3); }} previousState={objectiveState} onObjective={(data, index) => { setobjectiveState({ data, index }); props.onPage('objective', { selectedProgress, affiliationState, titleState, objectiveState: { data, index } }) }} /> :
                                selectedProgress === 3 ? <Keywords {...props} previous={() => setselectedProgress(2)} next={(data) => onSubmit(data)} previousState={keywordState} /> :
                                    <Success {...props} title={titleState} objective={objectiveState.data} />
                )}
            </Col>
            <Col className='p-0 right-col' md={3}>
                {progressData.map((obj, index) => (
                    <div key={index} className={`${selectedProgress === index ? 'selected-container' : selectedProgress > index ? 'completed-container' : ''} progress-container`}>
                        <div className='progress-number-container'>
                            <div className='progress-number'>{index + 1}</div>
                        </div>
                        <div className='progress-title'>{obj.title}</div>
                    </div>
                ))}
            </Col>
        </Row>
    )
}
