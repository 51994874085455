import { useContext, useEffect, useState } from "react";
import { getDocument, getVersionDoc } from "../../../documents/apiController";
import { WaxContext } from 'wax-prosemirror-core';
import { diffWords } from 'diff';
import { diffChars } from 'diff';

import { Decoration, DecorationSet } from 'prosemirror-view';
import 'prosemirror-view/style/prosemirror.css';

const VersionSelectPanel = () => {
     const [documentInfo, setDocumentInfo] = useState();
     const [currentVersion, setCurrentVersion] = useState();
     const [currentVersionData, setCurrentVersionData] = useState();

     const {
          app,
          pmViews: { main },
          activeViewId,
          activeView,
          placeholderPlugin
     } = useContext(WaxContext);

     useEffect(() => {
          getDocumentData()
     }, [])

     /**
      * get documet data by id
      */
     const getDocumentData = async () => {
          let documentName = localStorage.getItem('documentSelected');
          try {
               let documentDataResponse = await getDocument(documentName);
               setDocumentInfo(documentDataResponse.data)
               console.log(documentDataResponse.data)
          } catch (error) {

          }
     }

     const applyDecorations = (editorView, diffs) => {
          const { state } = editorView;
          let decorations = [];
          let pos = 0;
        
          diffs.forEach(part => {
            const { count, added, removed, value } = part;
            if (added) {
              decorations.push(
                Decoration.inline(pos, pos + count, { class: 'highlight-added' })
              );
            } else if (removed) {
              decorations.push(
                Decoration.inline(pos, pos + count, { class: 'highlight-removed' })
              );
            }
            pos += count;
          });
        
          const decos = DecorationSet.create(state.doc, decorations);
          editorView.dispatch(editorView.state.tr.setMeta('decorations', decos));
        };

        
     /**
      * get data by document id
      */
     const getDataByVersionId = async (id) => {
          let documentName = localStorage.getItem('documentSelected');
          console.log(JSON.parse(localStorage.getItem(documentName)))
          setCurrentVersion(id)
          try {
               let docData = await getVersionDoc(id)
               console.log(docData)
               if (docData) {
                    //if content null update with empty schema
                    if (docData.data.content == null) {
                         updateEditorContent({
                              content: [],
                              type: 'doc'
                         })
                         localStorage.setItem("currentVersionDoc", JSON.stringify({
                              content: [],
                              type: 'doc'
                         }))

                         setCurrentVersionData({
                              content: [],
                              type: 'doc'
                         })
                    } else {
                         updateEditorContent(docData.data.content)
                         setCurrentVersionData(docData.data.content)
                         localStorage.setItem("currentVersionDoc", JSON.stringify(docData.data.content))
                         let prevData = JSON.parse(localStorage.getItem(documentName))
                         console.log(docData.data.content)
                         let diffData = computeDiff(prevData.content, docData.data.content.content)
                         const { state } = activeView;
                         let decorations = createDecorations(state, diffData);
                         console.log(decorations)
                         activeView.updateState(state.reconfigure({
                              decorations:decorations
                         }))

               console.log(state)
               const oldContent = prevData.content;
const newContent = docData.data.content.content;

const diffs = diffChars(oldContent, newContent);
applyDecorations(activeView, diffs);
                         // const transaction = state.tr.setMeta('decorations', decorations);
                         // activeView.dispatch(transaction);
                    }
               }
          } catch (error) {
               throw error
          }
     }

     // update the editor with new data
     const updateEditorContent = (newDocJSONData) => {
          let newDocJSON = newDocJSONData
          const newDoc = main.state.config.schema.nodeFromJSON(newDocJSON);

          // Create a transaction to replace the document
          const transaction = activeView.state.tr.replaceWith(
               0,
               activeView.state.doc.content.size,
               newDoc.content
          );

          // Dispatch the transaction
          activeView.dispatch(transaction);
     };

     /**
      * function to get timestamp from mongo collection id
      * @param {*} id 
      * @returns 
      */
     const getTimestamp = (id) => {
          let timestamp = id.toString().substring(0, 8)
          let date = new Date(parseInt(timestamp, 16) * 1000)
          return date.toDateString()
     }
     let textContent = '';

     const getDocText = (node) => {
          node && node.forEach(child => {
               if ('content' in child) {
                    getDocText(child.content)
                    //console.log("NExtNode========", child)
               } else {
                    textContent += child.text
                    //getData(child.text)
               }
          });
          return textContent;
     };


     const computeDiff = (prevDoc, currentDoc) => {
          console.log(currentDoc)
          textContent = "";
          const prevText = getDocText(prevDoc);
          console.log("PrevText----------->", prevText)
          textContent = "";
          const currText = getDocText(currentDoc);
          console.log("currText----------->", currText)
          let data = diffWords(prevText, currText);
          return data;
          // console.log("Diff=====>",data)
     };


     /**
      * Text highlighter
      * @param {*} state 
      * @param {*} diffs 
      * @returns 
      */
     const createDecorations = (state, diffs) => {
          const decorations = [];
          let index = 0;

          diffs.forEach(part => {
               if (part.added) {
                    const from = index;
                    const to = from + part.value.length;
                    const decoration = Decoration.inline(from, to, { class: 'highlight' });
                    decorations.push(decoration);
               }

               if (!part.removed) {
                    index += part.value.length;
               }
          });

          return DecorationSet.create(state.doc, decorations);
     };

     useEffect(() => {
               const { state } = activeView;
              

               // Example: Manually highlight the first 10 characters
               if (state) {
                    const decorations = DecorationSet.create(state.doc, [
                         Decoration.inline(0, 10, { class: 'highlight' })
                    ]);
                    console.log("Decorations applied:", state); // Log to check if decoration is created

                    const tr = state.tr.setMeta('decorations', decorations);
                    activeView.dispatch(tr);

               }
          
     }, [activeView]);

     useEffect(() => {

     }, [documentInfo])

     return (
          <>
               <div className="version-list">
                    {
                         documentInfo && documentInfo.versions.map((item, i) => {
                              return <div className={item == currentVersion ? "version-selected p-2" : "version-data p-2"} onClick={() => getDataByVersionId(item)}>
                                   <div className="name">Version{i + 1}</div>
                                   {/* <div className="vid mt-1">Document id: {item}</div> */}
                                   <div className="doc-date">{getTimestamp(item)}</div>
                                   <hr></hr>
                              </div>
                         })
                    }
               </div>
          </>
     )
}

export default VersionSelectPanel;