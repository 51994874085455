import { useDispatch } from "react-redux";
import { CustomButton } from "../../widgets";
import { FIELD_TYPES, FieldItem } from "../../widgets/fields";
import ModalComponent from "../common/Modal/modal";
import { useEffect, useState } from "react";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import { setLoading } from "../../store/slices/auth";
import { getAnalysisChartTypes } from "./apiController";

export function SelectChartModalMobile(props){
    const dispatch = useDispatch();
    const [values, fields, handleChange, { validateValues, reset, updateValue, updateError }] = useFieldItem({}, {}, { onValueChange });
    const [variableData, SetVariableData] = useState<any>([]);
    const {selectedItem, getSelectedData} = props;

    useEffect(()=>{
        console.log(props)
        fetchInitialData()
    },[])

    /**
     * fetch list of charts
     */
    const fetchInitialData = async () => {
        try {
            dispatch(setLoading(true));
            let response = await getAnalysisChartTypes();
            let filterData = response.filter(item => selectedItem?.field_type == "number" ? item.key == "boxplot" || item.key == "qqplot" || item.key == "histogram" || item.key == "stemleaf" : item.key == "horizontalbarchart" || item.key == "pie" || item.key == "bar")
            console.log(filterData,selectedItem.field_type)
            SetVariableData(filterData.map(({
                key: value,
                name: label
            }) => ({
                value,
                label
            })));

            let tempCharts : any = [];
            selectedItem.charts && selectedItem.charts.map(item =>{
                tempCharts.push(item.value)
            })
            updateValue({charts:tempCharts})
            dispatch(setLoading(false))
        } catch (e: any) {
            dispatch(setLoading(false))
            console.log(e)
        }
    }

      /**
  * handle on change values of form elements
  * @param name 
  * @param value 
  * @param values 
  * @param fieldValues 
  * @returns 
  */
      function onValueChange(name, value, values, fieldValues = null) {
        const resetValue = {}
        console.log(name, values)

        return [{
            ...values,
            ...resetValue,
            [name]: value,
        }]
    }

    useEffect(()=>{
        console.log(variableData)
    },[variableData])

      /**
     * send selected Data
     */
      const addSelectedData = async () =>{
        let dataArray : any = [];
        let inputValues : any = values.charts;
        inputValues.map(item =>{
            let filterData = variableData && variableData.filter(items => items.value == item);
            dataArray.push(filterData[0])
        })
        getSelectedData(dataArray)
        props.onClose();
    }
    return(
        <>
         <ModalComponent
                width="95%"
                isOpen={true}
                onClose={() => props.onClose()}
                header={"Select Chart"}
                centered={true}
                customClass='invite-modal config-variable-modal'
                body={
                    <>
                      <div className="variable-container">
                            <FieldItem
                                name={"charts"}
                                value={values["charts"]}
                                values={variableData}
                                onChange={(...e) => handleChange("charts", ...e)}
                                type={FIELD_TYPES.CHECK_BOX}
                            />
                        </div>
                    </>
                }
                footer={
                    <CustomButton type="primary" text="Add" onClick={()=>addSelectedData()}></CustomButton>
                }
                />
        </>
    )
}
