import { FIELD_TYPES } from "../../widgets/fields";

export const GroupFormElements = {
    grouptype: {
        name: "grouptype",
        placeholder: "Enter",
        label: "Grouping Type",
        values:[{value:"category_grouping",label:"Categorical"},
                {value:"numericgrouping",label:"Numerical"},
                {value:"Advanced_compute",label:"Compute"}],
        type : FIELD_TYPES.DROP_DOWN,
        isMandatory: true
    },
    operators: {
        name: "operators",
        placeholder: "Select",
        label: "Operators Type",
        values:[{value:">",label:">"},
                {value:"<",label:"<"},
                {value:"==",label:"=="},
                {value:">=",label:">="},
                {value:"<=",label:"<="},
                {value:"!=",label:"!="}],
        type : FIELD_TYPES.DROP_DOWN,
        isMandatory: true
    },
    formType: {
        name: "formtype",
        placeholder: "Enter",
        label: "Form",
        type : FIELD_TYPES.DROP_DOWN,
    },
    variable:{
        name: "variable",
        placeholder: "Enter",
        label: "Variable",
        type : FIELD_TYPES.DROP_DOWN,
    },
    newValue: {
        name: "newvalue",
        placeholder: "Enter",
        label: "",
        type : FIELD_TYPES.TEXT,
        isMandatory: true   
    },
    variablelist:{
        name: "variablelist",
        placeholder: "Variables",
        label: "",
        type : FIELD_TYPES.MULTI_SELECT,
        isMandatory: true   
    },
    newcode:{
        name: "newcode",
        placeholder: "New Variable Name",
        label: "",
        type : FIELD_TYPES.TEXT,
        isMandatory: true   
    },
    newcolumnname:{
        name: "newcolumnname",
        placeholder: "New Variable Name",
        label: "New Variable Name",
        type : FIELD_TYPES.TEXT,
        isMandatory: true   
    },
    variablefieldtype:{
        name: "variablefieldtype",
        placeholder: "Field Type",
        label: "New Variable Datatype",
        type : FIELD_TYPES.DROP_DOWN,
        values:[{label:"Categorical",value:"textbox"},{label:"Numerical",value:"number"}],
        isMandatory: true   
    },
    origionalvalue:{
        name: "origionalvalue",
        label: "Orginal/Custom Value",
        type : FIELD_TYPES.DROP_DOWN,
        values:[{label:"Original",value:"original"},{label:"Custom",value:"custom"}],
        isMandatory: false   
    },
    defaultvalue:{
        name: "defaultvalue",
        placeholder: "Else - Default Value",
        label: "Default Value",
        type : FIELD_TYPES.TEXT,
        isMandatory: false   
    },
    codeeditor:{
        name: "codeeditor",
        placeholder: "",
        label: "",
        type : FIELD_TYPES.TEXT_AREA,
        isMandatory: true   
    }
}

export const AnalysisFormElements = {
    analysisName: {
        name: "analysisName",
        placeholder: "Enter",
        label: "Analysis Name",
        type : FIELD_TYPES.TEXT,
        isMandatory: true
    },
    analysisType: {
        name: "analysisType",
        placeholder: "Enter",
        label: "Analysis Type",
        type : FIELD_TYPES.DROP_DOWN,
        isMandatory: true
    }
}

export const VariableFormElements = {
    variableCheckBox: {
        name: "variablecheck",
        placeholder: "Enter",
        label: "",
        type : FIELD_TYPES.DROP_DOWN,
        isMandatory: true
    }
}
