import React from 'react';
import { Col, Row } from 'reactstrap';
import { Carousel } from 'react-responsive-carousel';
import { centerImage } from 'highcharts';

const PreLoginLayoutRoute = (props) => {
    const items = [{
        image: 'login_tools',
        title: 'coGuide',
        content: 'Why multiple tools for research? Checkout coGuide. Integrate research suite for every researcher.'
    }, {
        image: 'login_sample_size',
        title: 'Sample size for research in easy steps',
        content: 'Sample size is the most vital part of your research. Get the most comprehensive sample size output without the need for any satisfaction'
    }, {
        image: 'login_data_entry',
        title: 'Pic & Drop to create a data entry template',
        content: 'Unique pic and drop functionality to create a customized mobile friendly electronic data entry template. Enter data as and when you recruit participants and collect data'
    }, {
        image: 'login_data_analysis',
        title: 'Real time data analysis',
        content: 'Unique pic and drop functionality to create a customized mobile friendly electronic data entry template. Enter data as and when you recruit participants and collect data'
    }, {
        image: 'login_wizards',
        title: 'Simple to use Wizard for critical elements ',
        content: 'Provide answers to simple questions about your research idea and witness the title & objectives f your research constructed n internationally recommended PICOST and SMART formats respectively'
    }]
    return (
        <>
        {window.innerWidth > 700 ? 
        <Row className='pre-login-layout m-0'>
            <Col className="col-lg-6 col-xs-12 p-0">
            
                <div className='left-bg'>
                {/* <img src={`${process.env.PUBLIC_URL}/images/Logo_of_Rajiv_Gandhi_University_of_Health_Sciences.png`} style={{ width:"50%" }}/> */}
                    <Carousel autoPlay={true} infiniteLoop={true} showStatus={false} showIndicators={false} interval={5000} showArrows={false} showThumbs={false}>
                        {items.map((obj, index) => (
                            <div key={index}>
                                <img src={`${process.env.PUBLIC_URL}/images/${obj.image}.svg`} alt={obj.image} />
                                <div className='carousel-title'>{obj.title}</div>
                                <div className='carousel-content'>{obj.content}</div>
                            </div>
                        ))}
                    </Carousel>
                </div>
            </Col>
            <Col className="col-md-6 col-xs-12 p-0">
                <div className='right-bg'>
                    {/* <div className='icon-height'>
                        <i className="icon icon-home home-icon c-pointer" onClick={() => window.open("https://coguide.in", "_self")} />
                    </div> */}
                    <div className='right-bg-content'>{props.children}</div>
                </div>
            </Col>
        </Row>
        :
        <div className='pre-login-layout'>
                <div className='left-bg'>
                    <Carousel autoPlay={true} infiniteLoop={true} showStatus={false} showIndicators={false} interval={5000} showArrows={false} showThumbs={false}>
                        {items.map((obj, index) => (
                            <div key={index}>
                                <img className="image" src={`${process.env.PUBLIC_URL}/images/${obj.image}.svg`} alt={obj.image} />
                                {/* <div className='carousel-title'>{obj.title}</div> */}
                            </div>
                        ))}
                    </Carousel>
                </div>
           
                <div className='right-bg'>
                   
                    <div className='right-bg-content'>{props.children}</div>
                </div>
            
        </div>}
    </>
    )
};

export default PreLoginLayoutRoute;  