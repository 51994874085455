import { useState } from "react";
import ProjectDashboard from "./ProjectDashboard";

export default function ProjectsList(props) {
    const [action, setAction] = useState<any>("");
    const [selectedProject, setSelectedProject] = useState<any>("");

    const onAction = async (type, data: any = null) => {
        switch (type) {
            case "projectdashboard":
                setSelectedProject(data);
                setAction(type);
                break;
            default:
                setAction(type);
                break;
        }
    }
    return (
        <>
      {action == "" && <div>
         ProjectsList - 
         {props.selectedProjectStage.stage}
         {props.selectedProjectStage.projects.map((item)=>(
             <button onClick={() => onAction("projectdashboard",item)}>{item.name}</button>
         ))}
         <button onClick={() => props.onClose()}>Back</button>
      </div>}
      {
        action == "projectdashboard" && 
        <ProjectDashboard selectedProject={selectedProject} onClose={() => onAction("")}/>
      }
      </>
    )
  }
  