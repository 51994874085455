import { cloneDeep } from 'lodash';
import React, { useState } from 'react'
import { Col, Row } from 'reactstrap'
import { CustomButton, showToaster, toasterTypes } from '../../widgets';
import { FieldItem } from '../../widgets/fields';
import useFieldItem from '../../widgets/fields/UseFieldItem';

export default function Authenticators(props) {
    const {greetText, formElements} = props;
    const [formData, setformData] = useState(props.formData);
    const [values, fields, handleChange, { validateValues, reset, updateValue }] = useFieldItem(formElements, {}, {onValueChange});
    var changedIndex: any = null;
    function onValueChange (name, value, values) {
        let temp = cloneDeep(formData);
        temp.form_fields[changedIndex].field_selected_value = value;
        setformData(temp);
        return [{
            ...values,
            [name]: value
        }]
    }

    function takeSurvey() {
        let isAnswered = true;
        let temp: any = cloneDeep(formData);
        temp.form_fields.forEach(obj => {
            if(obj.field_selected_value === '' && obj.field_selected_value.length === 0) {
                isAnswered = false;
            }
            if(obj.field_type === 'checkBox') {
                let tempArray: any = [];
                obj.field_selected_value.forEach(elem => {
                    tempArray.push(obj.field_value[elem]);
                });
                obj.field_selected_value = tempArray;
            } else if(obj.field_type === 'radioButton') {
                obj.field_selected_value = obj.field_value[obj.field_selected_value];
            } else if(obj.field_type === 'dropDown') {
                obj.field_selected_value = obj.field_selected_value?.label;
            } else if(obj.field_type === 'number') {
                obj.field_selected_value = parseInt(obj.field_selected_value) || 0;
            }
        });
        if(isAnswered) {
            props.takeSurvey(formData.form_fields);
        } else {
			showToaster(toasterTypes.ERROR, 'Please answer the form');
        }
    }

    return (
    <Row className='pre-login-layout m-0'>
        <Col className="p-0" lg={6} md={12}>
            <div className='left-bg'>
                <img src={`${process.env.PUBLIC_URL}/images/login_tools.svg`} alt='logo' className='page-logo' />
                <div className='carousel-title'>{formData?.form_name}</div>
                <div className='carousel-content'>{formData?.form_desc}</div>
            </div>
        </Col>
        <Col className="p-0" lg={6} md={12}>
            <div className='right-bg'>
                <div className='right-bg-content'>
                    <div className="login-page">
                        <div className="logo-container">
                            <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="logo" className="logo" />
                        </div>
                        <div className="welcome-text">Hello, {greetText}</div>
                        <div className="login-container">
                            <Row className='form-scroll'>
                                {formData.form_fields.map((obj, index) => (
                                    <div key={index} className={`${index === 0 ? 'pb-1' : 'pb-3'} col-md-12 col-xs-12`}>
                                        <FieldItem
                                            {...formElements[obj.id]}
                                            value={obj.field_selected_value}
                                            values={formElements[obj.id]?.field_value || []}
                                            onChange={(...e) => {changedIndex=index;handleChange(formElements[obj.id].name, ...e);}}
                                            touched={fields[obj.id] && fields[obj.id].hasError}
                                            error={fields[obj.id] && fields[obj.id].errorMsg}
                                        />
                                    </div>
                                ))}
                            </Row>
                            <CustomButton type="primary" onClick={() => takeSurvey()} className="w-100" text="Take Survey"></CustomButton>
                        </div>
                    </div>
                </div>
            </div>
        </Col>
    </Row>
  )
}
