import { useEffect, useState } from "react";
import { Card, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { CardBody, CardFooter, CardTitle, Col, Row } from "reactstrap";
import { getLMSCourses } from "./apiController";
import { useDispatch } from "react-redux";
import { setLoading } from "../../store/slices/auth";
import { getProfileDetails } from "../profile/apiController";

export default function LearningModule(){
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [courselist,Setcourselist] = useState<any>([]);
    const [profile,setProfile] = useState<any>([]);

    useEffect(()=>{
        fetchProfileDetails();
    },[])

    /**
     * get user data
     */
    const fetchProfileDetails = async () => {
		try {
			dispatch(setLoading(true));
			let response = await getProfileDetails({ userid: "" });
			setProfile(response);
            console.log(response.usernumber)
            fetchInitialData(response.usernumber)
			dispatch(setLoading(false));
		} catch (e: any) {
			dispatch(setLoading(false));
		}
	}

    /**
     * on click view course get the list of modules
     */
    const viewCourse = (id) =>{
        navigate("/coursemodule",{state:{id:id,uid:profile.usernumber}})
    }

    /**
     * fetch initial data course list
     */
    const fetchInitialData = async (id) =>{
        try{
        dispatch(setLoading(true));
        let courses = await getLMSCourses(id);
        Setcourselist(courses.data)
        dispatch(setLoading(false));

        }catch(e:any){
            dispatch(setLoading(false));
            throw e
        }
    }
    
    return(
        <>
            <div className="learning-container">
                <div className="head-course">
                        Course
                </div>
                <div className="course-list">
                    <Row>
                    {
                        courselist.map((item,id) => {
                            return <Col md={3} key={id}>
                            <Card className="course-card">
                                <CardBody>
                                    <Image className="thumbnail" src={item.thumbnail}  alt={item.title}/>
                                    <span className="description" >{item.title}</span>    
                                </CardBody>
                                <CardFooter>
                                    <div className="view-course" onClick={() => viewCourse(item.id)}>
                                        View Course
                                    </div>
                                </CardFooter>
                            </Card>
                            </Col>
                        })
                    }
                    </Row>
                </div>
            </div>
        </>
    )
}