import React, { useEffect, useState } from 'react';
import { Col, Row } from "reactstrap";
import { CustomButton, showToaster, toasterTypes } from '../../widgets';
import ModalComponent from '../common/Modal/modal';
import { FieldItem } from "../../widgets/fields";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import { WorkFormElements } from './FormElements';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../store/slices/auth';
import { addWorkExperience, getColleges, getCountries, getProfileDetails, getSkills, getStates, updateWorkExperience } from './apiController';
import { SoundFilled } from '@ant-design/icons';
import dayjs from 'dayjs';
import SkillSet from './SkillSet';

export default function WorkExperience(props) {
	const initialValues = React.useMemo(() => ({ jobTitle: '', jobDescription: '', industry: null, employeeType: null, companyName: '', startingFrom: null, workedTill: null, country: null, state: null, city: '', skillsUsed: [], info: '' }), []);
	const [values, fields, handleChange, { validateValues, updateValue }] = useFieldItem(WorkFormElements, initialValues, { onValueChange });
	const [industryList, setindustryList] = useState([{ label: 'Health care', value: 'Health care' }, { label: 'Agriculture', value: 'Agriculture' }, { label: 'Life Science', value: 'Life Science' }, { label: 'Veterinary Sciences', value: 'Veterinary Sciences' }, { label: 'Engineering', value: 'Engineering' }, { label: 'Software', value: 'Software' }, { label: 'Hardware', value: 'Hardware' }, { label: 'Chemical', value: 'Chemical' }]);
	const [employeeTypeList, setemployeeTypeList] = useState([{ label: 'Permanent', value: 'permanent' }, { label: 'Contract', value: 'contract' }]);
	const [countryList, setcountryList] = useState([]);
	const [stateList, setstateList] = useState([]);
	const [skillsData, setskillsData] = useState([]);
	const dispatch = useDispatch();
	const { data, selectedIndex } = props;
	const [skilltemp, Setskilltemp] = useState<any[]>([]);
	const [profile, setprofile] = useState<any | null>(null);
	const [popupType, setpopupType] = useState('');
	const [collegelist, setcollegeList] = useState<any>([]);


	useEffect(() => {
		if (selectedIndex !== null && selectedIndex >= 0 && data && data.length > 0) {
			(async () => {
				let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
				var monthIndex = data[selectedIndex].end_month.split(' ').length != 2 ? months.indexOf(data[selectedIndex].end_month.split(' ')[0]) + 1 : months.indexOf(data[selectedIndex].end_month.split(' ')[1]) + 1
				var monthIndexval = monthIndex > 10 ? monthIndex : "0" + monthIndex
				let monthDate: any = data[selectedIndex].end_month.split(' ').length == 2 ?
					parseInt(data[selectedIndex].end_month.split(' ')[0]) < 10 ? "0" + data[selectedIndex].end_month.split(' ')[0] + "/" + monthIndexval : "" + data[selectedIndex].end_month.split(' ')[0] + "/" + monthIndexval
					:
					"01/" + monthIndexval
				var startmonthIndex = data[selectedIndex].start_month.split(' ').length == 2 ? months.indexOf(data[selectedIndex].start_month.split(' ')[1]) + 1 : months.indexOf(data[selectedIndex].start_month.split(' ')[0]) + 1
				var startmonthIndexval = startmonthIndex > 10 ? startmonthIndex : "0" + startmonthIndex
				let startmonthDate: any = data[selectedIndex].start_month.split(' ').length == 2 ?
					parseInt(data[selectedIndex].start_month.split(' ')[0]) < 10 ? "0" + data[selectedIndex].start_month.split(' ')[0] + "/" + startmonthIndexval : "" + data[selectedIndex].start_month.split(' ')[0] + "/" + startmonthIndexval
					:
					"01/" + startmonthIndexval

				fetchColleges(data[selectedIndex].location.state_code)

				updateValue({
					jobTitle: data[selectedIndex].job_title,
					jobDescription: data[selectedIndex].job_description,
					industry: industryList.filter(obj => obj.label === data[selectedIndex].industry)[0] || { label: data[selectedIndex].industry, value: data[selectedIndex].industry },
					employeeType: employeeTypeList.filter(obj => obj.label === data[selectedIndex].employment_type)[0],
					companyName: data[selectedIndex].company_name,
					startingFrom: !data[selectedIndex].start_month.match(/[a-zA-Z]/g) ? dayjs(data[selectedIndex].start_month + "/" + data[selectedIndex].start_year, "DD/MM/YYYY") : dayjs(startmonthDate + "/" + data[selectedIndex].start_year, "DD/MM/YYYY"),
					workedTill: data[selectedIndex].end_month != "till_date" ? !data[selectedIndex].end_month.match(/[a-zA-Z]/g) ? dayjs(data[selectedIndex].end_month + "/" + data[selectedIndex].end_year, "DD/MM/YYYY") : dayjs(monthDate + "/" + data[selectedIndex].end_year, "DD/MM/YYYY") : "",
					currentProfession: data[selectedIndex].end_month === 'till_date' ? WorkFormElements.currentProfession?.values[0] : WorkFormElements.currentProfession.values[1],
					country: { label: data[selectedIndex].location.country, value: data[selectedIndex].location.country_code },
					state: { label: data[selectedIndex].location.state, value: data[selectedIndex].location.state_code },
					city: data[selectedIndex].location.city,
					info: data[selectedIndex].documents?.length > 0 ? data[selectedIndex].documents[0] : '',
				});

			})();

			fetchCountries(data[selectedIndex].location.country_code);
		} else {
			fetchCountries();
		}
		fetchSkills();
	}, []);

	useEffect(() => {
		fetchSkills()
	}, [popupType])



	/**
	 * fetch list of skills from database by triggering API
	 * update state with list of skills
	 */
	const fetchSkills = async () => {

		try {
			dispatch(setLoading(true));
			let response = await getSkills();
			let tempData = response.skills.map(res => ({ label: res.skill_name, value: res._id }));
			setskillsData(tempData);
			if (selectedIndex !== null && selectedIndex >= 0 && data && data.length > 0) {
				let temp = data[selectedIndex].skills.map(({
					skill_name: label,
					_id: value
				}) => ({
					label,
					value

				}));
				//let temp = [];
				// let skills_used = [];
				// temp.forEach(obj => {
				// 	skills_used = skills_used.concat(tempData.filter(tempObj => tempObj.value === obj));
				// });

				updateValue({ skillsUsed: temp });
			}
			dispatch(setLoading(false));
		} catch (e: any) {
			dispatch(setLoading(false));
		}
	}

	/**
	 * get list of countries
	 * @param country 
	 */
	const fetchCountries = async (country = '') => {
		try {
			dispatch(setLoading(true));
			let response = await getCountries();
			setcountryList(response.data.map(res => ({ label: res.Country, value: res.CountryCode })));
			if (country) {
				fetchStates(country);
			}
			dispatch(setLoading(false));
		} catch (e: any) {
			dispatch(setLoading(false));
		}
	}

	/**
	 * fetch college list
	 */
	const fetchColleges = async (value) => {
		try {
			dispatch(setLoading(true));
			let response = await getColleges(value);
			setcollegeList(response.data?.map(res => ({ label: res.College, value: res.College, key: res.CollegeCode })) || []);
			dispatch(setLoading(false));
		} catch (e: any) {
			setcollegeList([]);
			dispatch(setLoading(false));
		}
	}

	/**
	 * 
	 * @param name 
	 * @param value 
	 * @param values 
	 * @param fieldValues 
	 * @returns 
	 */
	function onValueChange(name, value, values, fieldValues = null) {
		let resetValue = {};
		console.log(name, value)
		if (name === 'country') {
			fetchStates(value.value);
			resetValue = { ...resetValue, state: null }
		} else if (name === 'currentProfession' && value?.value === 'yes') {
			resetValue = { workedTill: null };
		} else if (name === 'state') {
			fetchColleges(value.value);
			resetValue = { ...resetValue, college: null }
		}
		return [{
			...values,
			...resetValue,
			[name]: value,
		}]
	}

	const fetchStates = async (value) => {
		try {
			dispatch(setLoading(true));
			let response = await getStates(value);
			setstateList(response.data.map(res => ({ label: res.State, value: res.StateCode })));
			dispatch(setLoading(false));
		} catch (e: any) {
			dispatch(setLoading(false));
		}
	}

	const checkSubmit = async () => {
		let valuesArray: any = ["jobTitle", "jobDescription", "industry", "employeeType", "companyName", "currentProfession", "city", "skillsUsed", "info"];
		if (values?.currentProfession?.value === 'yes') {
			valuesArray.push("workedTill");
		}
		if (validateValues(valuesArray)) {
			console.log(validateValues(valuesArray))

			showToaster(toasterTypes.ERROR, "Please enter the proper values in the fields highlighted in red");
		} else {
			let instituteCode = collegelist.filter(item => item.value == values.companyName)
			let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
			try {
				dispatch(setLoading(true));
				let reqObj: any = {
					"job_title": values.jobTitle,
					"job_description": values.jobDescription,
					"employment_type": values.employeeType.label,
					"company_name": values.companyName,
					"institute_code": instituteCode && instituteCode[0]?.key || "",
					"location": {
						"country": values.country.label,
						"country_code": values.country.value,
						"state": values.state.label,
						"state_code": values.state.value,
						"city": values.city
					},
					"start_month": values.startingFrom.format("DD/MM"),
					"start_year": parseInt(values.startingFrom.format("YYYY")),
					"end_month": values.workedTill ? values.workedTill.format("DD/MM") : "till_date",  // values.currentProfession.value === 'yes' ? 'till_date' : values.workedTill.getDate()+" "+months[values.workedTill.getMonth()],
					"end_year": values.workedTill ? parseInt(values.workedTill.format("YYYY")) : 0, // values.currentProfession.value === 'yes' ? 0 : values.workedTill.getFullYear(),
					"industry": values.industry.label || values.industry,
					"skills_used": values.skillsUsed.map((a: any) => a.value),
					"documents": [values.info]
				}
				let response: any;
				if (selectedIndex !== null && selectedIndex >= 0) {
					response = await updateWorkExperience(reqObj, data[selectedIndex].id);
				} else {
					response = await addWorkExperience(reqObj);
				}
				showToaster(toasterTypes.SUCCESS, response.message);
				props.onClose('callAPI');
				dispatch(setLoading(false));
			} catch (e: any) {
				dispatch(setLoading(false));
			}
		}
	}

	const fetchProfileDetails = async () => {
		try {
			dispatch(setLoading(true));
			let response = await getProfileDetails({ userid: "" });
			setprofile(response);
			dispatch(setLoading(false));
		} catch (e: any) {
			dispatch(setLoading(false));
		}
	}

	const closePopup = (type) => {
		if (type === 'callAPI') {
			fetchProfileDetails();
		}
		setpopupType('');
	}

	const onAdd = () => {
		setpopupType("skill")
	}

	return (
		<ModalComponent
			width={window.innerWidth > 700 ? "800px" : "350px"}
			isOpen={true}
			onClose={() => props.onClose()}
			header="Add Work"
			centered={true}
			customClass='profile-modal'
			body={
				<div>
					{
						popupType == "skill" ?
							<SkillSet {...props} data={data} selectedIndex={selectedIndex} userNumber={data.usernumber} onClose={(type) => closePopup(type)} />
							: ""
					}
					<Row>
						<Col md={8}>
							<FieldItem
								{...WorkFormElements.jobTitle}
								value={values.jobTitle}
								onChange={(...e) => handleChange(WorkFormElements.jobTitle.name, ...e)}
								touched={fields.jobTitle && fields.jobTitle.hasError}
								error={fields.jobTitle && fields.jobTitle.errorMsg}
							/>
						</Col>
					</Row>
					<Row>
						<Col md={8}>
							<FieldItem
								{...WorkFormElements.jobDescription}
								value={values.jobDescription}
								onChange={(...e) => handleChange(WorkFormElements.jobDescription.name, ...e)}
								touched={fields.jobDescription && fields.jobDescription.hasError}
								error={fields.jobDescription && fields.jobDescription.errorMsg}
							/>
						</Col>
					</Row>
					<div className='sub-header-text mb-3'>Institute Details</div>
					<Row>
						<Col md={4}>
							<FieldItem
								{...WorkFormElements.industry}
								value={values.industry}
								values={industryList}
								onChange={(...e) => handleChange(WorkFormElements.industry.name, ...e)}
								touched={fields.industry && fields.industry.hasError}
								error={fields.industry && fields.industry.errorMsg}
							/>
						</Col>
						<Col md={4}>
							<FieldItem
								{...WorkFormElements.employeeType}
								value={values.employeeType}
								values={employeeTypeList}
								onChange={(...e) => handleChange(WorkFormElements.employeeType.name, ...e)}
								touched={fields.employeeType && fields.employeeType.hasError}
								error={fields.employeeType && fields.employeeType.errorMsg}
							/>
						</Col>


						<Col md={4}>
							<FieldItem
								{...WorkFormElements.country}
								value={values.country}
								values={countryList}
								onChange={(...e) => handleChange(WorkFormElements.country.name, ...e)}
								touched={fields.country && fields.country.hasError}
								error={fields.country && fields.country.errorMsg}
							/>
						</Col>
					</Row>
					<Row>

						<Col md={4}>
							<FieldItem
								{...WorkFormElements.state}
								value={values.state}
								values={stateList}
								onChange={(...e) => handleChange(WorkFormElements.state.name, ...e)}
								touched={fields.state && fields.state.hasError}
								error={fields.state && fields.state.errorMsg}
							/>
						</Col>
						<Col md={4}>
							<FieldItem
								{...WorkFormElements.city}
								value={values.city}
								onChange={(...e) => handleChange(WorkFormElements.city.name, ...e)}
								touched={fields.city && fields.city.hasError}
								error={fields.city && fields.city.errorMsg}
							/>
						</Col>
						<Col md={4}>
							<FieldItem
								{...WorkFormElements.companyName}
								value={values.companyName}
								values={collegelist && collegelist}
								onChange={(...e) => handleChange(WorkFormElements.companyName.name, ...e)}
								touched={fields.companyName && fields.companyName.hasError}
								error={fields.companyName && fields.companyName.errorMsg}
							/>
						</Col>
					</Row>
					<Row>
						<Col md={4}>
							<FieldItem
								{...WorkFormElements.currentProfession}
								value={values.currentProfession}
								onChange={(...e) => handleChange(WorkFormElements.currentProfession.name, ...e)}
								touched={fields.currentProfession && fields.currentProfession.hasError}
								error={fields.currentProfession && fields.currentProfession.errorMsg}
							/>
						</Col>
						<Col md={4}>
							<FieldItem
								{...WorkFormElements.startingFrom}
								value={values.startingFrom}
								placeholder='DD MM YYYY'
								onChange={(...e) => handleChange(WorkFormElements.startingFrom.name, ...e)}
								touched={fields.startingFrom && fields.startingFrom.hasError}
								error={fields.startingFrom && fields.startingFrom.errorMsg}
							/>
						</Col>
						<Col md={4}>
							<FieldItem
								{...WorkFormElements.workedTill}
								value={values.workedTill}
								placeholder='DD MM YYYY'
								onChange={(...e) => handleChange(WorkFormElements.workedTill.name, ...e)}
								touched={fields.workedTill && fields.workedTill.hasError}
								error={fields.workedTill && fields.workedTill.errorMsg}
							/>
						</Col>
					</Row>
					<Row>
						<Col md={12}>
							<FieldItem
								{...WorkFormElements.skillsUsed}
								value={values.skillsUsed}
								values={skillsData}
								addButton='Add Skill'
								onAdd={() => onAdd()}
								onChange={(...e) => handleChange(WorkFormElements.skillsUsed.name, ...e)}
								touched={fields.skillsUsed && fields.skillsUsed.hasError}
								error={fields.skillsUsed && fields.skillsUsed.errorMsg}
							/>
						</Col>
					</Row>
					<Row>
						<Col md={12}>
							<FieldItem
								{...WorkFormElements.info}
								value={values.info}
								onChange={(...e) => handleChange(WorkFormElements.info.name, ...e)}
								touched={fields.info && fields.info.hasError}
								error={fields.info && fields.info.errorMsg}
							/>
						</Col>
					</Row>
				</div>
			}
			footer={
				<div>
					<CustomButton type="primary" isDisabled={false} text="Save" onClick={() => checkSubmit()}></CustomButton>
				</div>
			}
		/>
	)
}