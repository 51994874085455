import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { uploadChartAsImage } from '../../Editor/utils/UploadToServer';
import { downloadChartAsImage } from './downloadChartAsImage';
import { Button } from 'reactstrap';
import SectionModal from '../../Editor/utils/SectionModal';

const QQPlot = ({ containerid,data, width, height }) => {
  const svgRef = useRef(null);
  const [actionPush, setactionPush] = useState('');

  /**
  * function call to Push code to editor
  */
  const addToEditor = (data) => {
    console.log(data)
    uploadChartAsImage(data,containerid)
    setactionPush("")
  }

  useEffect(() => {
    if (!data) return;

    const margin = { top: 20, right: 20, bottom: 30, left: 40 };
    //const width = 600 - margin.left - margin.right;
    //const height = 400 - margin.top - margin.bottom;

    const svg = d3.select(svgRef.current)
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${width/2},${margin.top+ margin.bottom})`);
      
    const x = d3.scaleLinear()
      .domain([0, d3.max(data)])
      .range([0, width]);

    const y = d3.scaleLinear()
      .domain([0, 1])
      .range([height, 0]);

    const line = d3.line()
      .x(d => x(d[0]))
      .y(d => y(d[1]));

    const sortedData = data.slice().sort((a, b) => a - b);
    const normalQuantiles = sortedData.map((d, i) => [d, (i + 1) / (sortedData.length + 1)]);

    svg.append('g')
      .attr('class', 'x axis')
      .attr('transform', `translate(0,${height})`)
      .call(d3.axisBottom(x));

    svg.append('g')
      .attr('class', 'y axis')
      .call(d3.axisLeft(y));
    // Add diagonal line
    console.log(d3.min(data), d3.max(data))
    const diagonalLineData = [[d3.min(data), 0], [d3.max(data), 1]];
    svg.append('path')
      .datum(diagonalLineData)
      .attr('class', 'diagonal-line')
      .attr('d', line)
      .attr('fill', 'none')
      .attr('stroke', 'red')
      .attr('stroke-width', 1);


    // Add dots
    //  svg.selectAll('.dot')
    //  .data(normalQuantiles)
    //  .enter().append('circle')
    //  .attr('class', 'dot')
    //  .attr('cx', d => x(d[0]))
    //  .attr('cy', d => y(d[1]))
    //  .attr('r', 1)
    //  .attr('fill', 'steelblue');

    // Add halo
    svg.selectAll('.halo')
      .data(normalQuantiles)
      .enter().append('circle')
      .attr('class', 'halo')
      .attr('cx', d => x(d[0]))
      .attr('cy', d => y(d[1]))
      .attr('r', 2)
      .attr('fill', 'none')
      .attr('stroke', 'steelblue')
      .attr('stroke-width', 1.5);

  }, [data]);

  return <>
    <div className="controller no-print">
      {
        actionPush == "section" &&
        <SectionModal pushAction={addToEditor} onclose={() => setactionPush("")} />
      }


      <Button className='grid no-print' onClick={() => downloadChartAsImage(containerid)}>
        Download
      </Button>
      <Button className='grid no-print' onClick={() => setactionPush("section")}>
      Copy to Editor
      </Button>
      {/* <h3>QQ Plot</h5> */}
    </div>
    <div id={containerid}>
    <div ref={svgRef} id={containerid}></div>
    </div>

  </>
};

export default QQPlot;
