import { useEffect, useState } from "react";
import ModalComponent from "../common/Modal/modal";
import { FIELD_TYPES, FieldItem } from "../../widgets/fields";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import { CustomButton, showToaster, toasterTypes } from "../../widgets";
import { getTargetFormList } from "./apiController";

export default function UidGenerateLink(props){
    const [forms,Setforms] = useState([]);
    const {formData,action} = props;
	const [values, fields, handleChange, { validateValues, reset, updateValue, resetValue, updateError }] = useFieldItem({}, {}, { onValueChange });

    useEffect(() => {
        fetchInitialData();        
    },[])

    /**
     * fetch target form list
     * update the state with key-value
     */
    const fetchInitialData = async() =>{
        try{
            let response = await getTargetFormList(formData.id);
            Setforms(response.map(({
                id: value,
                form_name: label
            }) => ({
                value,
                label
            })));
        }catch(err){

        }
    }

    /**
     * 
     * @param name 
     * @param value 
     * @param values 
     * @param fieldValues 
     * @returns 
     */
	function onValueChange(name, value, values, fieldValues = null) {
        console.log(value)
		return [{
			...values,
			[name]: value,
		}]
	}


    return(
        <>
        <ModalComponent
				width="500px"
				isOpen={true}
				onClose={() => props.onClose()}
				header={action == "survey" ? "Generate Child Survey Link" : "Generate UID link"}
				centered={true}
				customClass='uidmodal'
				body={
                    <>
                        <FieldItem
                        name="formitem"
                        type={FIELD_TYPES.DROP_DOWN}
                        label={"Target Form"}
						values={forms}
                        value={values["formitem"]}
                        onChange={(...e) => handleChange("formitem", ...e)}
                        />
                    </>
                }
                footer={
                    <CustomButton type="primary" text={"Generate"} onClick={() =>{values.formitem ? props.onGenerate(values.formitem) : showToaster(toasterTypes.ERROR,"Please select target form")}}></CustomButton>
                }
                />
        </>
    )
}