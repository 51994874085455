import { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import ModalComponent from "../common/Modal/modal";
import { CustomButton, showToaster, toasterTypes } from "../../widgets";
import AddConfigurationMobile from "./AddConfigurationMobile";
import { config } from "process";
import ConfigSelectorCard from "./ConfigSelectorCard";
import { setLoading } from "../../store/slices/auth";
import { useDispatch } from "react-redux";
import { getAnalysisChartTypes, getAnalysisVariables, getOutcomeVariable, updateSelectedConfig } from "./apiController";

export default function ConfigurationListMobile(props) {
    const [popUpType, SetPopupType] = useState("");
    const [configData, setconfigData] = useState<any>([]);
    const [editconfigData, seteditconfigData] = useState<any>("");
    const [chartTypes, SetChartTypes] = useState<any>([]);
    const dispatch = useDispatch();

    useEffect(() => {
        console.log(props)
        fetchIntialData();
    }, [])

    useEffect(()=>{

    },[configData])


    const onClose = () => {
        SetPopupType("")
        seteditconfigData("")
    }

       /**
     * get chart types
     * get variables data
     * find previous data variables details and update the state SetAddedConfig 
     */
       const fetchIntialData = async () => {
        try {
            dispatch(setLoading(true));
            let response = await getAnalysisChartTypes();
            
            SetChartTypes(response.map(({
                key: value,
                name: label
            }) => ({
                value,
                label
            })))

            getPreviousData(response.map(({
                key: value,
                name: label
            }) => ({
                value,
                label
            })))

            dispatch(setLoading(false));
        } catch (e: any) {
            dispatch(setLoading(false));
        }
    }

      /**
     * set inital data if exist
     * rearrange the array in to format required for setting previous values
     */
      const getPreviousData = async (datachart) => {
        dispatch(setLoading(true))
        let responseVariables = await getAnalysisVariables(props.formData?.id);
        let responseArray: any = [];
        if (props.configData?.configs && props.configData?.configs.length > 0) {
            props.configData?.configs.map((items, i) => {
                let outComeValue = responseVariables.data.filter(item => item.id == items.outcome_fields[0].field_id)
                let explanatoryValue: any = [];
                items && items.explanatory_fields.map(expItem => {

                    let explanatoryValueItem = responseVariables.data.filter(itemexp => itemexp.id == expItem.field_id)
                    explanatoryValue.push({
                        "label": explanatoryValueItem[0]?.field_name,
                        "value": explanatoryValueItem[0]?.field_name,
                        "id": explanatoryValueItem[0]?.id
                    })

                })

                /**
                 * chart data for multi data
                 */
                let chartTypes: any = [];
                items.chart_type && items.chart_type?.map(item => {
                    let chart = datachart.filter(chartItem => chartItem.value == item)
                    chartTypes.push(chart[0])
                })

                console.log(chartTypes)

                let chart = datachart.filter(chartItem => chartItem.value == items.chart_type)
                let Obj = {
                    id: i + 1,
                    charttype: chartTypes,
                    outcomeVar: [{
                        "label": outComeValue[0]?.field_name,
                        "value": outComeValue[0]?.field_name,
                        "id": outComeValue[0]?.id
                    }],
                    expoVariable: explanatoryValue,
                    formula: items.formulas && items.formulas,
                    spliton: items.spliton && items.spliton,
                    feature: items.features && items.features

                }
                responseArray.push(Obj)
            })
            dispatch(setLoading(false))

            setconfigData(responseArray)
        }
    }


    /**
     * get configuration
     * remove previous item from array if the data is to update
     */
    const getAddedConfiguration = (data,id) => {
        if(id == ""){
            data.id = configData.length > 0 ? configData.length + 1 : 1;
            setconfigData(prevData => [...prevData, data])
        }else{
            let removePreviousItem : any = configData.filter(item => item.id != id);
            data.id = id;
            removePreviousItem.push(data)
            setconfigData(removePreviousItem)
        }
        console.log(configData)
    }

    /**
    * delete config
    */
    const removeConfigItem = (id) => {
        console.log(id)
        let removePreviousItem : any = configData.filter(item => item.id != id);
        setconfigData(removePreviousItem)
    }

    /**
     * edit config
     */
    const editConfigItem = (id) => {
        console.log(configData[id - 1])
        seteditconfigData(configData[id - 1])
        SetPopupType("add")
    }

      /**
     * save configuration
     */
      const saveConfiguration = async () => {
        try {
            dispatch(setLoading(true))
            let responseOutcome = await getOutcomeVariable(props.id);
            let configArray: any = [];
            configData && configData.map((item, index) => {
                let id = index + 1;
                let outcomeArray: any = [];
                let explanatoryArray: any = [];
                outcomeArray.push({ "field_id": item.outcomeVar[0].id });
                item.expoVariable.map(expoItem => {
                    explanatoryArray.push({
                        "field_id": expoItem.id
                    })
                })
                let chartTemp: any = [];
                item.charttype.map((item, i) => {
                    chartTemp.push(item.value)
                })

                console.log(item)
                configArray.push({
                    "name": "config-" + id,
                    "outcome_fields": outcomeArray,
                    "explanatory_fields": explanatoryArray,
                    "chart_type": chartTemp,
                    "formulas": item.formula || [],
                    "spliton": item.spliton || [],
                    "features": item.feature || [],
                    "combined": item?.combined && item?.combined[0] || false
                })
            })
            let response = {
                "name": responseOutcome.name,
                "topic_id": responseOutcome.topic_id,
                "type": responseOutcome.type,
                "group_id": responseOutcome.group_id,
                "form_id": responseOutcome.form_id,
                "fields": responseOutcome.fields,
                "configs": configArray
            }

            console.log(response)
            let responseUpdate = await updateSelectedConfig(props.id, response)
            if (responseUpdate) {
                showToaster(toasterTypes.SUCCESS, "Configurations Updated Successfully");
            }
            dispatch(setLoading(false))
        } catch (err: any) {
            dispatch(setLoading(false))
            console.log(err)
        }
    }

    return (
        <>
            {
                popUpType == "add" ?
                    <AddConfigurationMobile {...props} previousData={editconfigData} getData={getAddedConfiguration} onClose={onClose} />
                    :
                    <div className="analysis-add-config-mobile">
                        <div className="header-container">
                            <div className='pl-3 pt-4 form-name'>
                                <Image className='profile-image' alt='Profile' src={`${process.env.PUBLIC_URL}/images/logo.png`} />
                                Configurations
                            </div>
                            <div className='pt-4 closeicon'>
                                <i className='icon icon-close' onClick={() => props.onClose()} />
                            </div>
                        </div>
                        <div className="create-container">
                            {
                                configData.length > 0 ?
                                    <div className="create-head" onClick={() => SetPopupType('add')}>
                                        <div><i className='icon icon-add'></i></div>
                                        <div style={{ width: "95%" }}>Add</div>
                                    </div>
                                    : ""
                            }
                        </div>

                        <div className="analysis-config-body">
                            <div className="config-list">
                                {
                                    configData &&
                                    <div className="p-2 selector-card">
                                        {
                                            configData && configData?.map((item, index) => {
                                                return <ConfigSelectorCard key={index} index={index + 1} configChartData={item} removeItem={removeConfigItem} editItem={editConfigItem} />
                                            })
                                        }
                                    </div>
                                }
                            </div>
                            {configData.length == 0 ?
                                <div className="main-container">
                                    <div className="create-analysis">
                                        <span>No configuration added, proceed to create</span>
                                        <CustomButton type="primary" className="create-btn" text="Create" onClick={() => SetPopupType('add')} />
                                    </div>
                                </div> : ""
                            }
                        </div>
                        {configData.length != 0 ?
                            <div className="submit-config">
                                <CustomButton type="primary" text="Submit" onClick={()=>saveConfiguration()}></CustomButton>
                            </div> : ""
                        }
                    </div>
            }
        </>
    )
}