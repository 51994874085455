import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Image, ListGroup, Row } from "react-bootstrap";
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { setLoading } from '../../store/slices/auth';
import { CustomButton } from "../../widgets";
import { getInvitesReceived, updateInvitesStatus } from './apiController';
import { RejectedModal } from './RejectedModal';


export default function InviteeCard(props){
    const [popUpType,SetPopUpType] = useState("");
    const [update, SetUpdate] = useState("");
    const [currentInvites,setcurrentInvites] = useState<any>([]);
    const viewProfileNav = useNavigate();
    const dispatch = useDispatch();

    const viewProfile = (id) =>{
       viewProfileNav('/peopleprofile',{ state: id });
       localStorage.setItem("profiletype","invite");
    }

    useEffect(() => {
        invitesReceived();
    },[])

    async function onReject(type:'') {
        SetPopUpType('');  
        try {
            dispatch(setLoading(true));
            let receviedInvites = await getInvitesReceived(1,10)
			setcurrentInvites(receviedInvites?.users || []);
            let count = receviedInvites["total-count"] ? receviedInvites["total-count"] : 0;
            props.getCount(count);
            dispatch(setLoading(false));
        }  catch (e: any) {
            dispatch(setLoading(false));
          }      
    }

    const invitesReceived = async () =>{
        try {
            dispatch(setLoading(true));
            let receviedInvites = await getInvitesReceived(1,10)
			setcurrentInvites(receviedInvites?.users || []);
            let count = receviedInvites["total-count"] ? receviedInvites["total-count"] : 0;
            props.getCount(count);
            dispatch(setLoading(false));
        }  catch (e: any) {
            dispatch(setLoading(false));
          } 
    }
    useEffect(()=>{

    },[currentInvites])

    const acceptInvite = async (id) => {
        let data = {
            status:"accepted"
        }
        try {
            dispatch(setLoading(true));
            let updated = await updateInvitesStatus(id,data);
            dispatch(setLoading(false));
            dispatch(setLoading(true));
            let receviedInvites = await getInvitesReceived(1,10)
			setcurrentInvites(receviedInvites?.users || []);
            let count = receviedInvites["total-count"] ? receviedInvites["total-count"] : 0;
            props.getCount(count);
            dispatch(setLoading(false));
        }  catch (e: any) {
            dispatch(setLoading(false));
          }
    }
    useEffect(()=>{
        console.log(props.data)
    },[])


 

    return(
        <>
             <ListGroup className="invtee-user-list">
                   {currentInvites?.filter(items => items.status == "sent").map((item,i) => {
                      return  <ListGroup.Item> 
                        <Row>
                            <Col md={10}>
                            <div className="invitee-card" onClick={() => viewProfile(item)}>
                            <div className="invite-pic-name">
                                <div className="msg-icon">
                                    <Image className="profile-img" src={item.user_dp} />
                                </div>                             
                                <div className="people-detail">
                                    <span className="invitee-name">{item.inviter_name}</span>
                                    <span className="invitee-profession">{item.invitee_role}</span>
                                    {/* <span className="invitee-description">Developer description here</span> */}
                                </div>
                                </div>  
                                
                            </div> 
                            </Col>

                            <Col md={2}>
                             {
                                 popUpType == "reject" ? 
                                 <RejectedModal {...props} onClose={(type) => onReject(type)}/>
                                 :""
                             }
                            <CloseCircleOutlined className="reject" onClick={() => SetPopUpType("reject")} />
                            <CheckCircleOutlined className="accept" onClick={()=> acceptInvite(item.id)}/>
                            </Col>
                          
                        </Row>                                  
                      
                </ListGroup.Item>  
                   })}
                                      
           
       </ListGroup> 
        </>
    );
}