import { useLayoutEffect, useState, useRef, useEffect } from 'react';

import { Wax } from 'wax-prosemirror-core';
import { debounce } from 'lodash';
import config from './config/configversion';
import { demo } from './demo';
import 'wax-prosemirror-core/dist/index.css'
import 'wax-prosemirror-services/dist/index.css'
import 'wax-table-service/dist/index.css'
import { Button, Col, Row } from 'reactstrap';
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ArrowLeftOutlined } from '@ant-design/icons';
import { getDocument, getRMSDocument, postRevisedData } from '../documents/apiController';
import { CustomButton, showToaster, toasterTypes } from '../../widgets';
import axios from 'axios';
import EditoriaLayoutVersion from './layout/EditoriaLayoutVersion';

const renderImage = file => {
  const reader = new FileReader();
  return new Promise((accept, fail) => {
    reader.onload = () => accept(reader.result);
    reader.onerror = () => fail(reader.error);
    // Some extra delay to make the asynchronicity visible
    setTimeout(() => reader.readAsDataURL(file), 150);
  });
};

const user = {
  userId: 'b3cfc28e-0f2e-45b5-b505-e66783d4f946',
  userColor: {
    addition: 'royalblue',
    deletion: 'indianred',
  },
  username: 'admin',
};



const VersionSelector = (props) => {
  const [width] = useWindowSize();
  const navigate = useNavigate();
  const location = useLocation();
  const editorRef = useRef();
  const [docData, setDocData] = useState(location.state)
  const [documentData, setDocumentData] = useState();
  const [documentInfo, setDocumentInfo] = useState();
  const [count, setCount] = useState(0)
  let documentName = "";

  /**
  * set document data
  */
  const getDocumentData = async () => {
    documentName = localStorage.getItem('documentSelected');

    try {
      let documentDataResponse = await getDocument(documentName);
      setDocumentInfo(documentDataResponse.data)
      console.log(documentDataResponse.data)
    } catch (error) {

    }

    let parsedJSON = JSON.parse(localStorage.getItem(documentName));
    setDocumentData(parsedJSON)
  }


  useEffect(() => {

    getDocumentData();

  }, [])





  /**
   * onclick documents
   */
  const onBackAction = async () => {
    localStorage.removeItem('currentVersionDoc');
    navigate('/editor')
  }

  /**
  * onclick documents
  */
  const onRestoreAction = async () => {
    let documentName = localStorage.getItem('documentSelected');
    let currentVersionData = localStorage.getItem('currentVersionDoc');
    localStorage.setItem(documentName, currentVersionData);
    navigate('/editor')
  }



  return <>
    <Row className='page-header m-0'>
      <Col className={"project-title"} md={10}>
        <ArrowLeftOutlined style={{ color: "#fff", fontSize: "24px", margin: "0px 10px" }} onClick={() => onBackAction()} />
        <span style={{ color: "#fff", fontSize: "24px", margin: "0px 10px" }}>{documentInfo && documentInfo.name}</span>
      </Col>
      <Col className='p-0'>
        <div onClick={() => onRestoreAction()} className='restore-btn p-1'>Restore this version</div>
      </Col>
    </Row>
    {
      documentData &&
        <Wax
          ref={editorRef}
          config={config}
          autoFocus
          placeholder="Type Something..."
          fileUpload={file => renderImage(file)}
          value={documentData}
          readonly
          targetFormat='JSON'
          layout={EditoriaLayoutVersion}
          // addSection={addSection}
          user={user}
          scrollMargin={500}
          scrollThreshold={500}
        />
    }



  </>;
};

function useWindowSize() {
  const [size, setSize] = useState([window.innerWidth, window.innerHeight]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export default VersionSelector;
