import React from 'react'
import { Row } from 'reactstrap';

export default function Tabs(props) {
	const { data, selected } = props;
	return (
		<Row className='tabs-container m-0'>
			{data.map((obj, index) => (
				<div className={`${selected === index ? 'selected-tab tab-col' : 'tab-col'} mr-5`} key={index} onClick={() => props.onTab(index)}>
					<div className={`${selected === index ? 'font-open-sans-bold' : 'font-open-sans-semi-bold'} tab-title`}>{obj.title}</div>
					<div className={`${selected === index ? 'font-open-sans-semi-bold' : 'font-open-sans-regular'} tab-subtitle`}>{obj.subTitle}</div>
				</div>
			))}
		</Row>
	)
}
