import AjaxUtil from '../../axios/axios';

export const send_otp = async(mobileNumber) => {
    try {
        let request = {usernumber: "dumy", toemail: "dummy", tosms: mobileNumber}
        return await AjaxUtil.sendRequest('otpgeneration', request, false, { method: 'post', showError: true} );
    } catch (error) {
        throw error;
    }
}

export const update_password = async(mobileNumber, password, otp) => {
    try {
        let request = {password: AjaxUtil.encryptData(password), otp: otp}
        return await AjaxUtil.sendRequest('updatepassword/'+mobileNumber, request, false, { method: 'put', showError: true} );
    } catch (error) {
        throw error;
    }
}