import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap'
import { setLoading } from '../../store/slices/auth';
import { CustomButton } from '../../widgets';
import CustomPager from '../../widgets/customPager';
import { FieldItem } from '../../widgets/fields';
import useFieldItem from '../../widgets/fields/UseFieldItem';
import { getJournals } from './apiController';
import { FormElements } from './FormElements';

export default function JournalSearch() {
	const dispatch = useDispatch();
    const initialValues = useMemo(() => ({
        availableIndex: [],
        searchField: '',
        impactMin: '',
        impactMax: '',
        cityMin: '',
        cityMax: ''
    }), []);
    const [values, fields, handleChange, { validateValues, reset, updateValue, resetValue }] = useFieldItem(FormElements, initialValues, {});
    const [totalPages, settotalPages] = useState(0);
    const [currentPage, setcurrentPage] = useState(1);
    const [journals, setjournals] = useState([]);
    const [selectedIndex, setselectedIndex] = useState<any | null>(null);

    useEffect(() => {
        fetchJournals(1);
    }, []);

    const fetchJournals = async(value, type='') => {
		try {
			dispatch(setLoading(true));
            let reqObj:  any = {
                "search_word": type ? '' : values.searchField,
                "Scopus": values.availableIndex.includes('scopus'),
                "PubMed": values.availableIndex.includes('pubmed'),
                "PMC": values.availableIndex.includes('pmx'),
                "Medline": values.availableIndex.includes('medline'),
                "Embase": values.availableIndex.includes('embase'),
                "Wos": values.availableIndex.includes('wos'),
                "DOAJ": values.availableIndex.includes('doaj'),
                "L_Cite_score": parseInt(values.cityMin || 0),
                "H_Cite_score": parseInt(values.cityMax || 0),
                "L_Impact_factor": parseInt(values.impactMin || 0),
                "H_Impact_factor": parseInt(values.impactMax || 0)
            }
			let response = await getJournals(value, reqObj);
            response.data.forEach(obj => {
                obj.available = [];
                obj.notAvailable = [];
                obj[obj.Scopus ? 'available' : 'notAvailable'].push('Scopus Indexed');
                obj[obj.PubMed ? 'available' : 'notAvailable'].push('PubMed Indexed');
                obj[obj.PMC ? 'available' : 'notAvailable'].push('PMC Indexed');
                obj[obj.Medline ? 'available' : 'notAvailable'].push('Medline Indexed');
                obj[obj.Embase ? 'available' : 'notAvailable'].push('Embase Indexed');
                obj[obj.Wos ? 'available' : 'notAvailable'].push('Wos Indexed');
                obj[obj.DOAJ ? 'available' : 'notAvailable'].push('DOAJ Indexed');
                obj[obj['Open Access'] ? 'available' : 'notAvailable'].push('Open Access');
                obj[obj['Submission fee'] ? 'available' : 'notAvailable'].push('Pay Submission');
            })
			setjournals(response.data || []);
            setcurrentPage(value);
            settotalPages(response?.total_pages || 0);
			dispatch(setLoading(false));
		} catch (e: any) {
            setjournals([]);
			dispatch(setLoading(false));
		}
	}

    const clearSearch = () => {
        resetValue({searchField: ''});
        fetchJournals(1, 'clear');
    }

    const onPagerChange = (page) => {
        fetchJournals(page);
    }

    return (
        <div className='journal-page'>
            <Row className='m-0 h-100'>
                <Col className='p-0 h-100' md={3}>
                    <div className='filter-container h-100'>
                        <div className='journals-text'>Journals Filter</div>
                        <div className='filter-header'>Available index</div>
                        <div className='filter-padding'>
                            <FieldItem
                                {...FormElements.availableIndex}
                                value={values.availableIndex}
                                onChange={(...e) => handleChange(FormElements.availableIndex.name, ...e)}
                            />
                        </div>
                        <div className='filter-header'>Impact Factor</div>
                        <Row className='m-0 filter-padding'>
                            <Col className='p-0 pr-2'>
                                <FieldItem
                                    {...FormElements.impactMin}
                                    value={values.impactMin}
                                    onChange={(...e) => handleChange(FormElements.impactMin.name, ...e)}
                                />
                            </Col>
                            <Col className='p-0 pl-2'>
                                <FieldItem
                                    {...FormElements.impactMax}
                                    value={values.impactMax}
                                    onChange={(...e) => handleChange(FormElements.impactMax.name, ...e)}
                                />
                            </Col>
                        </Row>
                        <div className='filter-header'>Cite score</div>
                        <Row className='m-0 filter-padding'>
                            <Col className='p-0 pr-2'>
                                <FieldItem
                                    {...FormElements.cityMin}
                                    value={values.cityMin}
                                    onChange={(...e) => handleChange(FormElements.cityMin.name, ...e)}
                                />
                            </Col>
                            <Col className='p-0 pl-2'>
                                <FieldItem
                                    {...FormElements.cityMax}
                                    value={values.cityMax}
                                    onChange={(...e) => handleChange(FormElements.cityMax.name, ...e)}
                                />
                            </Col>
                        </Row>
                        <div className='apply-button w-100'>
                            <div className='row m-0 justify-content-center'>
                                <CustomButton type="primary" onClick={() => fetchJournals(1)} text="Apply" />
                            </div>
                        </div>
                    </div>
                </Col>
                <Col className='p-0 data-container' md={9}>
                    <Row className='m-0'>
                        <Row className='w-100 m-0 align-items-center'>
                            <Col className='p-0' md={3}>
                                <div className='journals-text'>Journals</div>
                            </Col>
                            <Col className='p-0' md={9}>
                                <FieldItem
                                    {...FormElements.searchField}
                                    value={values.searchField}
                                    onChange={(...e) => handleChange(FormElements.searchField.name, ...e)}
                                    onClear={() => clearSearch()}
                                    onKeyUp={() => fetchJournals(1)}
                                />
                            </Col>
                        </Row>
                    </Row>
                    <Row>
                        <div className='journals-scroll'>
                        {journals.map((obj: any, index) => (
                            <div className='journal-container' key={index} onClick={() => setselectedIndex(selectedIndex === index ? null : index)}>
                                <Row className='m-0 journal-header'>
                                    <Col className='p-0' md={8}>
                                        <div className='name-text'>Name: {obj['Journal Name']}</div>
                                    </Col>
                                    <Col className='p-0 row m-0'>
                                        <div className='label-text'>Cite Score: <span className='value-text'>{obj['cite score 2021']}</span></div>
                                        <div className='label-text pl-3'>Impact Factor: <span className='value-text'>{obj['Impact factor']}</span></div>
                                    </Col>
                                </Row>
                                <div className='journal-body'>
                                    <Row className='m-0'>
                                        <Col className='p-0' md={2}>
                                            <div className='label-text'>Department</div>
                                            <div className='value-text'>{obj.Department}</div>
                                        </Col>
                                        <Col className='p-0' md={2}>
                                            <div className='label-text'>Speciality</div>
                                            <div className='value-text'>{obj.Specialty}</div>
                                        </Col>
                                        <Col className='p-0' md={5}>
                                            <div className='label-text'>Publisher_Name</div>
                                            <div className='value-text'>{obj.Publisher_Name}</div>
                                        </Col>
                                        <Col className='p-0' md={3}>
                                            <div className='label-text'>Url</div>
                                            <div className='value-text'>{obj['Journal URL']}</div>
                                        </Col>
                                    </Row>
                                    {index === selectedIndex &&
                                        <>
                                            <Row className='m-0 journal-border'>
                                                <Col className='p-0' md={4}>
                                                    <div className='label-text'>Systematic review & meta analysis</div>
                                                    <div className='value-text'>{obj['Systematic review and meta analysis']}</div>
                                                </Col>
                                                <Col className='p-0' md={5}>
                                                    <div className='label-text'>Case Study/ case report/ case series:</div>
                                                    <div className='value-text'>{obj['Case Study/ case report/ case series']}</div>
                                                </Col>
                                                <Col className='p-0' md={3}>
                                                    <div className='label-text'>Best Quarter to Publish</div>
                                                    <div className='value-text'>{obj['SJR Best Quartile']}</div>
                                                </Col>
                                            </Row>
                                            <Row className='m-0'>
                                            <Col className='p-0' md={8}>
                                                    {obj.available.length > 0 && <div className='body-label'>Available</div>}
                                                    {obj.available.length > 0 &&
                                                        <Row className='available-container m-0'>
                                                            {obj.available.map((subObj, subIndex) => (
                                                                <Col md={3} key={subIndex}>
                                                                    <div className='available-card'>
                                                                        <div className='container-text'>{subObj}</div>
                                                                    </div>
                                                                </Col>
                                                            ))}
                                                        </Row>
                                                    }
                                                    {obj.notAvailable.length > 0 && <div className='body-label'>Not Available</div>}
                                                    {obj.notAvailable.length > 0 &&
                                                        <Row className='available-container m-0'>
                                                            {obj.notAvailable.map((subObj, subIndex) => (
                                                                <Col md={3} key={subIndex}>
                                                                    <div className='not-available-card'>
                                                                        <div className='container-text'>{subObj}</div>
                                                                    </div>
                                                                </Col>
                                                            ))}
                                                        </Row>
                                                    }
                                                    <Row className='m-0 pt-3'>
                                                        <Col className='p-0' md={4}>
                                                            <div className='label-text'>Publication charge <span className='small-text'>(subscription)</span> author</div>
                                                            <div className='value-text'>{obj['Publication charge (subscription) author']}</div>
                                                        </Col>
                                                        <Col className='p-0' md={5}>
                                                            <div className='label-text'>Gold open access to Users</div>
                                                            <div className='value-text'>{obj['Gold open access (Users)']}</div>
                                                        </Col>
                                                        <Col className='p-0' md={3}>
                                                            <div className='label-text'>Fast track charge</div>
                                                            <div className='value-text'>{obj['Fast track charge']}</div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col className='p-0 values-container' md={4}>
                                                    <Row className='m-0 value-card align-items-center'>
                                                        <Col className='p-0 label-text'>Cite score 2021</Col>
                                                        <Col className='p-0 value-text'>{obj['cite score 2021']}</Col>
                                                    </Row>
                                                    <Row className='m-0 value-card align-items-center'>
                                                        <Col className='p-0 label-text'>Impact factor</Col>
                                                        <Col className='p-0 value-text'>{obj['Impact factor']}</Col>
                                                    </Row>
                                                    <Row className='m-0 value-card align-items-center'>
                                                        <Col className='p-0 label-text'>Minimum Time in Weeks to Publish</Col>
                                                        <Col className='p-0 value-text'>{obj['Timeline (Weeks)']}</Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </>
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                    </Row>
                    <CustomPager totalPages={totalPages} currentPage={currentPage} onChange={(page) => onPagerChange(page)} />
                </Col>
            </Row>
        </div>
    )
}
