import React, { useEffect, useMemo, useState } from 'react'
import { Col, Row } from "reactstrap";
import { FIELD_TYPES, FieldItem } from "../../widgets/fields";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import { copyData } from "../../utils/Utils";
import CustomPager from '../../widgets/customPager';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../store/slices/auth';
import {getTopics} from "./apiController";


const TopicsLibrary = () => {
    const dispatch = useDispatch();
    const [totalPages, settotalPages] = useState(0);
    const [currentPage, setcurrentPage] = useState(1);
    const [topicsData, settopicsData] = useState<any | []>([]);
    const FormElements = {
            searchField: {
                name: "searchField",
                placeholder: "Search",
                label: "",
                type: FIELD_TYPES.SEARCH,
                isMandatory: true
            }
    }

    const initialValues = useMemo(() => ({
        searchField: ''
    }), []);
    const [values, fields, handleChange, { validateValues, reset, updateValue, resetValue }] = useFieldItem(FormElements, initialValues, { onValueChange });

    function onValueChange(name, value, values, fieldValues = null) {
        return [{
            ...values,
            [name]: value
        }]
    }

    useEffect(() => {
        fetchTopics(currentPage);
    }, []);


    const handleTextCopy = (copy) => {
        copyData('test', false)
    }

    const onPagerChange = (page) => {
        fetchTopics(page);
    }

    const fetchTopics = async(page, type='') => {
        try {
            dispatch(setLoading(true));
            setcurrentPage(page);
            let response = await getTopics(page, type ? '' : values.searchField);
            settotalPages(response?.total_pages || 0);
            settopicsData(response?.data || []);
            dispatch(setLoading(false));
        } catch (e: any) {
            dispatch(setLoading(false));
        }
    }

    const clearSearch = () => {
        resetValue({'searchField': ''});
        fetchTopics(1, 'clear');
    }

    return (
        <>
            <Row className='topic-header m-0'>
                <Col className='p-0 project-title d-flex justify-content-center flex-column' >
                    <div className='header-title pl-5 pr-5 mb-3'>
                        Topic Library
                    </div>
                    <div className='group-header pl-5 pr-5'>
                        The Internet is used for information sharing. The information can be shared between two
                        very faraway places. News is also shared on the internet. We can easily know what’s
                        happening in other parts of the world. Today, 90% of works are carried out with the help
                        of the internet. We have machines that can’t run without internet connectivity.
                        Even airplanes today have an internet connection. A lot of companies do business on the
                        internet. They sell their products over the internet. The buyer also makes all the
                        payments over the internet. The bill is also generated over the internet only.
                        The Internet is also a useful learning platform. Data is updated regularly on the internet.
                        Students can read their study material over the internet. You can also do research on the
                        internet if you want. E-mailing is another major use of the internet today. Companies use
                        e-mail as a communication and sharing platform. The Internet has become very useful for
                        society.
                    </div>
                </Col>
                <Col className='search-label-column pt-5 pb-5 pl-4 pr-4' md={8}>
                    <Row className='search-label-row m-0'>
                        <label className='topic-label mr-4'>Topics</label>
                        <Col className='p-0' md={3}>
                            <FieldItem
                                {...FormElements.searchField}
                                value={values.searchField}
                                onChange={(...e) => handleChange(FormElements.searchField.name, ...e)}
                                onClear={() => clearSearch()}
                                onKeyUp={() => fetchTopics(currentPage)}
                            />
                        </Col>
                    </Row>
                    <div className='topics-scroll'>
                        {topicsData.map((card, index) => {
                            return (
                                <div className='mt-3' key={index}>
                                    <div className="topic-card">
                                        <div className="topic-card-header">
                                            <button className="btn" onClick={handleTextCopy}></button>
                                            <div className='topic-card-title'>{card?.departments && card?.departments[0] || ''}</div>
                                        </div>
                                        <div className="topic-card-body">
                                            <div className="blockquote mb-0">{card?.TopicDesc || ''}</div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <CustomPager totalPages={totalPages} currentPage={currentPage} onChange={(page) => onPagerChange(page)} />
                </Col>
            </Row>


        </>
    )
}

export default TopicsLibrary