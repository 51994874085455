import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { CustomButton, showToaster, toasterTypes } from '../../widgets';
import { FieldItem } from '../../widgets/fields';
import useFieldItem from '../../widgets/fields/UseFieldItem';
import { AffiliationFormElements } from '../createProject/FormElements';

export default function Keywords(props) {
    const navigate = useNavigate();
    const initialValues = useMemo(() => (props.data.keywords ? {keyword: props.data.keywords} : { keyword: [] }), [props.data.keywords]);
    const [values, fields, handleChange, { validateValues, updateValue }] = useFieldItem(AffiliationFormElements, initialValues, {}); 

    const submit = () => {
        if (validateValues(["keyword"])) {
			showToaster(toasterTypes.ERROR, "Please enter the proper values in the fields highlighted in red");
		} else {
            props.next(values);
		}
    };

    return (
        <>
        <div className='keywords-page'>
           
            <div className='affiliation-container'>
                <div className='page-text'>Keywords</div>
                <Row className='keyword-margin'>
                    <Col md={12}>
                        <FieldItem
                            {...AffiliationFormElements.keyword}
                            value={values.keyword}
                            onChange={(...e) =>handleChange(AffiliationFormElements.keyword.name, ...e)}
                            touched={fields.keyword && fields.keyword.hasError}
                            error={fields.keyword && fields.keyword.errorMsg}
                        />
                    </Col>
                </Row>
            </div>
           
            <div className='page-footer'></div>
        </div>

        <div className="footer-button">
                <Row className='title-footer m-0'>
                    <Col className='p-0'>
                        <div className='cancel-text' onClick={() => navigate('/project')}>Cancel</div>
                    </Col>
                    <Col className='p-0 d-flex m-0 justify-content-end'>
                        <CustomButton type="alert-primary" onClick={() => props.previous(values,"keywords")} className="w-100" text="Previous"></CustomButton>
                        <CustomButton type="primary" onClick={() => submit()} className="w-100" text="Submit"></CustomButton>
                    </Col>
                </Row>
            </div>
        </>
    )
}
