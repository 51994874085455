import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { CurrentUser } from '../../store/slices/auth';

export default function Success(props) {
    const [profile, setprofile] = useState<any | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
		let userData = localStorage.getItem('coguide_user');
		if(userData) {
			let currentUser: CurrentUser = JSON.parse(userData);
            setprofile(currentUser);
        }
    }, []);
    

    return (
        <div className='affiliation-page'>
            <div className='page-header'>
                <img src={`${process.env.PUBLIC_URL}/images/coguide_main_logo.png`} alt='logo' className='logo' />
                <div className='header-text'>{props.projectData ? 'Edit Project' : 'Create Project'}</div>
            </div>
            <div className='affiliation-container'>
                <div className='user-text'>Hello <span className='font-open-sans-semi-bold'>{profile?.FullName}</span></div>
                <div className='create-text'>You have {props.projectData ? 'edited' : 'created'} the project</div>
                <div className='success-text'>Successfully</div>
                <div className='project-text'>Project Details</div>
                <div className='title-text'>{props.title.title}</div>
                {props.objective.map((obj, index) => (
                    <div key={index} className='objective-text'>{index + 1}. {obj}</div>
                ))}
            </div>
            <Row className='affiliation-footer m-0'>
                <Col className='p-0 d-flex m-0 justify-content-end'>
                    <div className='proceed-text' onClick={() => navigate('/project')}>View Projects<i className='icon icon-right_arrow' /></div>
                </Col>
            </Row>
            <div className='page-footer'></div>
        </div>
    )
}
