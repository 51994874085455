import { useEffect, useState } from "react";
import ModalComponent from "../common/Modal/modal";
import { getAnalysisVariables } from "./apiController";
import { useDispatch } from "react-redux";
import { setLoading } from "../../store/slices/auth";
import { FIELD_TYPES, FieldItem } from "../../widgets/fields";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import { CustomButton } from "../../widgets";

export default function ConfigVariableModal(props) {
    const dispatch = useDispatch();
    const [values, fields, handleChange, { validateValues, reset, updateValue, updateError }] = useFieldItem({}, {}, { onValueChange });
    const [variableData, SetVariableData] = useState<any>([]);
    const { data, addVariable, selectedPrevData } = props;

    useEffect(() => {
        console.log(props)
        fetchInitialData();
    }, [])

    /**
     * fetch initial data
     *  get variables list and format it according to checkbox value and label
     * set the state with new data
     */

    const fetchInitialData = async () => {
        try {
            dispatch(setLoading(true));
            let response = await getAnalysisVariables(data?.formData?.id);
            SetVariableData(response.data.map(({
                id: value,
                field_name: label,
                field_type: field_type,
                field_value: field_value,
                field_use_type: field_use_type,
            }) => ({
                value,
                label,
                field_type,
                field_value,
                field_use_type

            })));

            if(selectedPrevData){
                let tempArray : any = [];
                selectedPrevData.map(item =>{
                    tempArray.push(item.value)
                })
                updateValue({variable:tempArray})
            }
            dispatch(setLoading(false))
        } catch (e: any) {
            dispatch(setLoading(false))
            console.log(e)
        }
    }

    /**
  * handle on change values of form elements
  * @param name 
  * @param value 
  * @param values 
  * @param fieldValues 
  * @returns 
  */
    function onValueChange(name, value, values, fieldValues = null) {
        const resetValue = {}
        console.log(name, values)
        if(name == "searchfield"){
            if(value.length > 0){
                SetVariableData(variableData.filter(item => item.label.toLowerCase().includes(value.toLowerCase())))
              }else{
                fetchInitialData()
              } 
        }

        return [{
            ...values,
            ...resetValue,
            [name]: value,
        }]
    }

    /**
     * send selected Data
     */
    const addSelectedData = async () =>{
        let dataArray : any = [];
        let inputValues : any = values.variable;
        inputValues.map(item =>{
            let tempData = selectedPrevData && selectedPrevData.filter(items => items.value == item);
            let filterData = variableData && variableData.filter(items => items.value == item);
            if(tempData.length > 0){
                filterData[0].charts = tempData[0].charts
            }
            dataArray.push(filterData[0])
        })
        addVariable(dataArray)
    }

    return (
        <>
            <ModalComponent
                width="95%"
                isOpen={true}
                onClose={() => props.onClose()}
                header={"Variables | "+ variableData.length}
                centered={true}
                customClass='invite-modal config-variable-modal'
                body={
                    <>
                      <div id="search">
                        <FieldItem
                            name={"searchfield"}
                            value={values.searchfield}
                            type={FIELD_TYPES.SEARCH}
                            onChange={(...e) => handleChange("searchfield", ...e)}
                        />
                    </div>

                        <div className="variable-container">
                            <FieldItem
                                name={"variable"}
                                value={values["variable"]}
                                values={variableData}
                                onChange={(...e) => handleChange("variable", ...e)}
                                type={FIELD_TYPES.CHECK_BOX}
                            />
                        </div>
                    </>
                }
                footer={
                    <>
                        <CustomButton text="Add" type="primary" onClick={() => {addSelectedData(); props.onClose();} }/>
                    </>
                }
            />
        </>
    )
}