import html2canvas from 'html2canvas';

/**
 * Download chart as Image
 */
export const downloadChartAsImage = (containerid) => {
     html2canvas(document.querySelector("#"+containerid)).then(canvas => {
         const link = document.createElement("a");
         link.download = "chart.png";
         link.href = canvas.toDataURL("image/png");
         link.click();

     });
 };