import React, { useMemo, useState } from 'react'
import { Col, Row } from 'reactstrap'
import { FieldItem } from '../../widgets/fields'
import useFieldItem from '../../widgets/fields/UseFieldItem'
import { TitleElements } from './FormElements';
import Step from '../common/components/Step';

export default function TitleGeneration(props) {
    const initialValues = useMemo(() => ({ studyGroup: '', interventionGroup: null, studyPopulation: '', outcome: '', groupName: '', studyDesign: '', timeline: '' }), []);
	const [values, fields, handleChange,{updateError}] = useFieldItem(TitleElements, initialValues, { onValueChange });
    const [titleData, settitleData] = useState([]);

    function onValueChange (name, value, values, fieldValues) {
            checkValues(name, value);
        
    }
    //check if study group greater than 2
    const onBlurValue = () => {
        if(values["studyGroup"] > 2){
            updateError("studyGroup", {hasError:true, errorMsg: 'More than 2 groups will be coming soon'})
        }
    }

    const checkValues = (name, value) => {
        values[name] = value;
        let topicNameData: any = [];
        console.log(values)
        if(values.studyPopulation && values.outcome && values.studyDesign && ((values.studyGroup === '2' && values.groupType) || values.studyGroup) && ((values.interventionGroup?.value === 'yes' && values.groupName) || values.interventionGroup?.value === 'no')) {
            var isVowel = ("aeiouAEIOU").includes(values.studyDesign.charAt(0));
            if(values.interventionGroup?.value === 'no') {
                values.groupName = '';
            }
            if(values.studyGroup === "1") {
                values.groupType = '';
                if(values.interventionGroup?.value === 'yes') {
                    topicNameData = [{
                        data: `To assess the impact of ${values.groupName} on ${values.outcome} among the ${values.studyPopulation} ${values.timeline ? 'for a duration of ' + values.timeline : ''}, ${isVowel ? 'an' : 'a'} ${values.studyDesign}`
                    }, {
                        data: `${isVowel ? 'An' : 'A'} ${values.studyDesign} to assess the impact of ${values.groupName} on ${values.outcome} among the study ${values.studyPopulation} ${values.timeline ? 'for a duration of ' + values.timeline : ''}`
                    }];
                } else {
                    topicNameData = [{
                        data: `To determine the ${values.outcome} among the ${values.studyPopulation}  ${values.timeline ? 'for a duration of ' + values.timeline : ''}, ${isVowel ? 'an' : 'a'} ${values.studyDesign}`
                    }, {
                        data: `To assess the incidence/prevalence of ${values.outcome} among the ${values.studyPopulation} ${values.timeline ? 'for a duration of ' + values.timeline : ''}, ${isVowel ? 'an' : 'a'} ${values.studyDesign}`
                    }, {
                        data: `To estimate the ${values.outcome} among the ${values.studyPopulation} ${values.timeline ? 'for a duration of ' + values.timeline : ''}, ${isVowel ? 'an' : 'a'} ${values.studyDesign}`
                    }, {
                        data: `${isVowel ? 'An' : 'A'} ${values.studyDesign} to determine the ${values.outcome} level among the ${values.studyPopulation} ${values.timeline ? 'for a duration of ' + values.timeline : ''}`
                    }, {
                        data: `${isVowel ? 'An' : 'A'} ${values.studyDesign} to assess the incidence/prevalence of ${values.outcome} among the ${values.studyPopulation} ${values.timeline ? 'for a duration of ' + values.timeline : ''}`
                    }, {
                        data: `${isVowel ? 'An' : 'A'} ${values.studyDesign} to estimate the ${values.outcome} among the ${values.studyPopulation} ${values.timeline ? 'for a duration of ' + values.timeline : ''}`
                    }];
                }
            } else {
                if(values.interventionGroup?.value === 'yes') {
                    topicNameData = [{
                        data: `To compare the ${values.timeline ? values.timeline : ''} ${values.outcome} between ${values.groupName} and ${values.groupType} groups among the ${values.studyPopulation}, ${isVowel ? 'an' : 'a'} ${values.studyDesign}`
                    }, {
                        data: `${isVowel ? 'An' : 'A'} ${values.studyDesign} to compare the ${values.timeline ? values.timeline : ''} ${values.outcome} between ${values.groupName} and ${values.groupType} groups among the study ${values.studyPopulation}`
                    }, {
                        data: `To compare the ${values.outcome} at/in the ${values.timeline ? values.timeline : ''} between ${values.groupName} and ${values.groupType} groups among the study ${values.studyPopulation}, ${isVowel ? 'an' : 'a'} ${values.studyDesign}`
                    }, {
                        data: `${isVowel ? 'An' : 'A'} ${values.studyDesign} to compare the ${values.outcome} at/in the ${values.timeline ? values.timeline : ''} between ${values.groupName} and ${values.groupType} groups among the study ${values.studyPopulation}`
                    }];
                } else {
                    topicNameData = [{
                        data: `${isVowel ? 'An' : 'A'} ${values.studyDesign} to compare the ${values.outcome} between ${values.studyPopulation} and ${values.groupType}`
                    }, {
                        data: `Compraging the ${values.outcome} levels between ${values.studyPopulation} and ${values.groupType}, ${isVowel ? 'an' : 'a'} ${values.studyDesign}`
                    }, {
                        data: `Association between ${values.outcome} and ${values.studyPopulation}, ${isVowel ? 'an' : 'a'} ${values.studyDesign}`
                    }, {
                        data: `${isVowel ? 'An' : 'A'} ${values.studyDesign} to assess the association between ${values.studyPopulation} and ${values.outcome}`
                    }];
                }
            }
        }
        settitleData(topicNameData);
    }

    return (
        <Row className='m-0 generation-page'>
            <Col className='p-0 left-col' md={8}>
                <div className='generation-text'>Title Generation</div>
                <Step step={1} />
                <div className='form-container'>
                    <Row>
                        <Col md={6}>
                            <FieldItem
                                {...TitleElements.studyGroup}
                                value={values.studyGroup}
                                onChange={(...e) => handleChange(TitleElements.studyGroup.name, ...e)}
                                touched={fields.studyGroup && fields.studyGroup.hasError}
                                error={fields.studyGroup && fields.studyGroup.errorMsg}
                                onBlur={onBlurValue}
                            />
                        </Col>
                        <Col md={6}>
                            <FieldItem
                                {...TitleElements.interventionGroup}
                                value={values.interventionGroup}
                                onChange={(...e) => handleChange(TitleElements.interventionGroup.name, ...e)}
                                touched={fields.interventionGroup && fields.interventionGroup.hasError}
                                error={fields.interventionGroup && fields.interventionGroup.errorMsg}
                            />
                        </Col>
                    </Row>
                </div>
                {values.studyGroup && values.interventionGroup?.value && values["studyGroup"] <= 2 ?
                    <div>
                        <Step step={2} />
                        <div className='form-container'>
                            <Row>
                                <Col md={6}>
                                    <FieldItem
                                        {...TitleElements.studyPopulation}
                                        value={values.studyPopulation}
                                        onChange={(...e) => handleChange(TitleElements.studyPopulation.name, ...e)}
                                        touched={fields.studyPopulation && fields.studyPopulation.hasError}
                                        error={fields.studyPopulation && fields.studyPopulation.errorMsg}
                                    />
                                </Col>
                                <Col md={6}>
                                    <FieldItem
                                        {...TitleElements.outcome}
                                        value={values.outcome}
                                        onChange={(...e) => handleChange(TitleElements.outcome.name, ...e)}
                                        touched={fields.outcome && fields.outcome.hasError}
                                        error={fields.outcome && fields.outcome.errorMsg}
                                    />
                                </Col>
                                {values.interventionGroup?.value === 'yes' &&
                                    <Col md={6}>
                                        <FieldItem
                                            {...TitleElements.groupName}
                                            value={values.groupName}
                                            onChange={(...e) => handleChange(TitleElements.groupName.name, ...e)}
                                            touched={fields.groupName && fields.groupName.hasError}
                                            error={fields.groupName && fields.groupName.errorMsg}
                                        />
                                    </Col>
                                }
                                {values.studyGroup === '2' &&
                                    <Col md={6}>
                                        <FieldItem
                                            {...TitleElements.groupType}
                                            value={values.groupType}
                                            onChange={(...e) => handleChange(TitleElements.groupType.name, ...e)}
                                            touched={fields.groupType && fields.groupType.hasError}
                                            error={fields.groupType && fields.groupType.errorMsg}
                                        />
                                    </Col>
                                }
                                <Col md={6}>
                                    <FieldItem
                                        {...TitleElements.studyDesign}
                                        value={values.studyDesign}
                                        onChange={(...e) => handleChange(TitleElements.studyDesign.name, ...e)}
                                        touched={fields.studyDesign && fields.studyDesign.hasError}
                                        error={fields.studyDesign && fields.studyDesign.errorMsg}
                                    />
                                </Col>
                                <Col md={6}>
                                    <FieldItem
                                        {...TitleElements.timeline}
                                        value={values.timeline}
                                        onChange={(...e) => handleChange(TitleElements.timeline.name, ...e)}
                                        touched={fields.timeline && fields.timeline.hasError}
                                        error={fields.timeline && fields.timeline.errorMsg}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </div>
                    :""
                }
                <div className='cancel-text' onClick={() => props.onCancel()}>Cancel</div>
            </Col>
            <Col className='p-0 right-col' md={4}>
                <div className='suggestions-text'>Suggestions</div>
                {titleData.length === 0 &&
                    <div className='empty-container'>
                        <div className='empty-text'>Hold tight we processing<br />Your inputs</div>
                    </div>
                }
                {titleData.map((obj: any, index) => (
                    <div key={index} className='title-container' onClick={() => props.onTitle(obj.data)}>
                        <div className='title-text'>Title {index + 1}<span className='display-image'><i className='icon icon-front_arrow' /></span></div>
                        <div className='title-value'>{obj.data}</div>
                    </div>
                ))}
            </Col>
        </Row>
    )
}
