import { FIELD_TYPES } from "../../../widgets/fields";

export const CreateSectionElements = {
     docselected: {
          name: "docselected",
          placeholder: "Enter",
          label: "Document Name",
          type: FIELD_TYPES.DROP_DOWN,
          isMandatory: true
     },
     section: {
          name: "section",
          placeholder: "Enter",
          label: "Section",
          type: FIELD_TYPES.DROP_DOWN,
          isMandatory: true
     },
     newsection: {
          name: "newsection",
          placeholder: "Enter New Section",
          label: "New Section",
          type: FIELD_TYPES.TEXT,
          isMandatory: true
     }     
};