import _, { cloneDeep } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap';
import { setLoading } from '../../store/slices/auth';
import { CustomButton, showToaster, toasterTypes } from '../../widgets';
import { FieldItem, FIELD_TYPES } from '../../widgets/fields';
import useFieldItem from '../../widgets/fields/UseFieldItem';
import ModalPopup from '../../widgets/modal';
import Actions from './Actions';
import AddPermissions from './AddPermissions';
import { arrangeCategories, deleteVariable, getFormVariables, getVariable, getVariables } from './apiController';
import Arrange from './Arrange';
import CreateVariable from './CreateVariable';
import ViewForm from './ViewForm';
import { Loader } from '../common/FullScreenLoader/fullScreenLoader';
import PreviewAction from './PreviewAction';
import { EyeFilled } from '@ant-design/icons';


export default function AddVariables(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [variables, setvariables] = useState([]);
    const [variablesResp, setvariablesResp] = useState([]);
    const [formVariables, setformVariables] = useState<any | null>(null);
    const [dependentformVariables, setdependentformVariables] = useState<any | null>(null);
    const [formData, setformData] = useState<any | null>(null);
    const [isAddVariable, setisAddVariable] = useState(false);
    const [isLast, setisLast] = useState(false);
    const [page, setpage] = useState(0);
    const [variablesLength, setvariablesLength] = useState(0);
    const [selectedCategory, setselectedCategory] = useState<any | null>(null);
    const [popupType, setpopupType] = useState('');
    const [projectData, setprojectData] = useState<any | null>(null);
    const [previousState, setpreviousState] = useState<any | null>(null);
    const [selectedVariable, setselectedVariable] = useState<any | null>(null);
    const [permissionParams, SetPermissionParams] = useState<any>({});
    const [isLoad, SetLoad] = useState(false);
    const [editable, SetEditable] = useState(false);
    const [actionEnabled, SetactionEnabled] = useState(true);

    useEffect(() => {
        console.log(permissionParams)
    }, [permissionParams])

    const FormElements = {
        searchField: {
            name: "searchField",
            placeholder: "Search",
            label: "",
            type: FIELD_TYPES.SEARCH,
            isMandatory: true
        }
    }
    const initialValues = useMemo(() => ({
        searchField: ''
    }), []);
    const [values, fields, handleChange, { validateValues, reset, updateValue }] = useFieldItem(FormElements, initialValues, { onValueChange });
    useEffect(() => {
        if (location.state) {
            let temp: any = location.state;
            console.log(temp)
            setformData(temp?.data);
            getPermissions(temp?.projectData);
            setprojectData(temp?.projectData);
            fetchFormVariables(temp?.data);
        }
        fetchVariables(0, '');
    }, []);

    function onValueChange(name, value, values, fieldValues = null) {
        setTimeout(() => {
            fetchVariables(0, value);
        }, 0);
        return [{
            ...values,
            [name]: value
        }]
    }

    const fetchVariables = async (selectedPage: any = '', keyword: any = '') => {
        let tempVariables: any = selectedPage !== 0 ? variables : [];
        try {
            if (keyword != '') {
                //dispatch(setLoading(true));
                SetLoad(true)
                let response = await getVariables(keyword === undefined ? values.searchField : keyword, selectedPage + 1);
                setpage(selectedPage + 1);
                let variablesData: any = tempVariables.concat(response?.data || []);
                if (variablesData.length + 10 > parseInt(response?.headers["x-variables-count"] || 0)) {
                    setisLast(true);
                } else {
                    setisLast(false);
                }
                setvariables(variablesData);
                //dispatch(setLoading(false));
                SetLoad(false)
            } else {
                setvariables([]);
                SetLoad(false)
                //dispatch(setLoading(false));
            }

        } catch (e: any) {
            setvariables([]);
            dispatch(setLoading(false));
        }
    }

    const fetchFormVariables = async (formData) => {
        try {
            dispatch(setLoading(true));
            let response = await getFormVariables(formData.id);
            if (response.data && response.data.length > 0) {
                setvariablesLength(response.data.length);
                let catList: any = []
                let cats: Set<string> = new Set<string>();
                let i: number = 0;
                response.data.map((obj, index) => {
                    obj.field_category = obj.field_category || 'empty';
                    obj.order = index;
                    cats.add(obj.field_category);
                    if (i != cats.size) {
                        catList.push(obj.field_category);
                        i = cats.size;
                    }
                });


                let temp: any = _.groupBy(response.data, "field_category");
                if (temp.empty) {
                    let data = cloneDeep(temp.empty);
                    delete temp.empty;
                    temp.empty = data;
                }
                var tempDict = {};
                for (var j = 0; j < catList.length; j++) {
                    tempDict[catList[j]] = _.sortBy(temp[catList[j]], ['order']);
                }

                setvariablesResp(response.data);
                setformVariables(tempDict);
                
                // ready array of variables and flat it to single 
                // variables list wit value and label object
                //set it to state
                let tempDependent : any = [];
                Object.keys(tempDict).map(items =>{
                    tempDependent.push(tempDict[items].map(({
                        id: value,
                        field_name: label
                    }) => ({
                        value,
                        label
                    })))
                })
                setdependentformVariables(tempDependent.flat())
                
            } else {
                setvariablesResp([]);
                setformVariables(null);
            }
            dispatch(setLoading(false));
        } catch (e: any) {
            setformVariables([]);
            dispatch(setLoading(false));
        }
    }

    function onCreateVariable(type: '') {
        setpreviousState(null);
        setisAddVariable(false);
        if (type) {
            fetchFormVariables(formData);
        }
    }

    function onAddPermissions(type: '') {
        setpopupType('');
        if (type) {
            fetchFormVariables(formData);
        }
    }

    function onArrange(type: '') {
        setpopupType('');
        if (type) {
            fetchFormVariables(formData);
        }
    }

    function onAction(type: '') {
        setpopupType('');
        if (type) {
            fetchFormVariables(formData);
        }
    }

    const onvariableAdd = async (data) => {
        setpreviousState({
            fields: {
                fieldName: data.fieldname,
                fieldDescription: data.fieldname,
                fieldType: data.fieldtype === "string" ? "textBox" : data.fieldtype === "number" ? "number" : data.fieldtype === "date" ? "date" : "dropDown",
                useType: '',
                validation: [],
                visibility: [],
                timeSeries: null,
                timeStamp: ''
            },
            options: data.fieldselectvalue ? data.fieldselectvalue.map(obj => obj.value) : [],
            category: data.fieldcategory,
            data
        });
        setisAddVariable(true);
        SetactionEnabled(false);
        SetEditable(true)
    }

    const onEdit = async (data,actionValue) => {
        try {
            dispatch(setLoading(true));
            let response = await getVariable(data.id);
            console.log(response)
            if (response?.id) {
                setpreviousState({
                    fields: {
                        id:response.id,
                        fieldName: response.field_name,
                        fieldDescription: response.field_description,
                        fieldType: response.field_type,
                        useType: response.field_use_type || '',
                        dependentType: response.dependent_fields || [],
                        validation: response.validations || [],
                        visibility: response.field_visibility || [],
                        timeSeries: response.is_time_series === true ? { label: 'Yes', value: 'yes' } : { label: 'No', value: 'no' },
                        timeStamp: response.time_series_time_stamp || '',
                        minValue: response.field_min_value || '',
                        maxValue: response.field_max_value || ''
                    },
                    options: data.field_value || [],
                    category: data.field_category,
                    data: response,
                    isEdit:actionValue,
                    isActionEnable: response?.actions && Object.keys(response?.actions).length ? true : false
                });
                SetactionEnabled(response?.actions && Object.keys(response?.actions).length ? true : false)
                SetEditable(actionValue)
                setisAddVariable(true);
            }
            dispatch(setLoading(false));
        } catch (e: any) {
            dispatch(setLoading(false));
        }
    }

    const onDelete = async () => {
        try {
            dispatch(setLoading(true));
            let response = await deleteVariable(formData.id, selectedVariable.id);
            if (response.success) {
                showToaster(toasterTypes.SUCCESS, response.message);
                setpopupType('');
                setselectedVariable(null);
                fetchFormVariables(formData);
            }
            dispatch(setLoading(false));
        } catch (e: any) {
            dispatch(setLoading(false));
        }
    }

    /**
     * get permissions
     */
    const getPermissions = async (data) => {
        console.log(data)
        await data.permissions && data.permissions.map(items => {

            if (Object.keys(items)[0] == "Variable creation") {
                items[Object.keys(items)[0]].map(item => {
                    if (item.permissions.filter(itm => itm.title == "allowed" && itm.is_selected == true).length != 0) {
                        if (item.action == "permissions") {
                            SetPermissionParams(prevState => ({ ...prevState, permissions: "allowed" }))
                        } else if (item.action == "arrange") {
                            SetPermissionParams(prevState => ({ ...prevState, arrange: "allowed" }))
                        } else if (item.action == "actions") {
                            SetPermissionParams(prevState => ({ ...prevState, actions: "allowed" }))
                        } else if (item.action == "add variables") {
                            SetPermissionParams(prevState => ({ ...prevState, addvariables: "allowed" }))
                        } else if (item.action == "edit variable icon") {
                            SetPermissionParams(prevState => ({ ...prevState, editvariable: "allowed" }))
                        } else if (item.action == "delete variable icon") {
                            SetPermissionParams(prevState => ({ ...prevState, deletevariable: "allowed" }))
                        } else if (item.action == "update variable button") {
                            SetPermissionParams(prevState => ({ ...prevState, updatevariable: "allowed" }))
                        }
                    } else {
                        if (item.action == "permissions") {
                            SetPermissionParams(prevState => ({ ...prevState, permissions: "forbidden" }))
                        } else if (item.action == "arrange") {
                            SetPermissionParams(prevState => ({ ...prevState, arrange: "forbidden" }))
                        } else if (item.action == "actions") {
                            SetPermissionParams(prevState => ({ ...prevState, actions: "forbidden" }))
                        } else if (item.action == "add variables") {
                            SetPermissionParams(prevState => ({ ...prevState, addvariables: "forbidden" }))
                        } else if (item.action == "edit variable icon") {
                            SetPermissionParams(prevState => ({ ...prevState, editvariable: "forbidden" }))
                        } else if (item.action == "delete variable icon") {
                            SetPermissionParams(prevState => ({ ...prevState, deletevariable: "forbidden" }))
                        } else if (item.action == "update variable button") {
                            SetPermissionParams(prevState => ({ ...prevState, updatevariable: "forbidden" }))
                        }
                    }
                })
            }
        })
    }

    /**
     * create
     */
    const create = async () => {
        try {
            dispatch(setLoading(true));
            let catData: any = [];
            Object.values(formVariables).forEach((obj: any) => {
                obj.forEach(subObj => {
                    catData.push({ id: subObj.id, field_category: subObj.field_category === 'empty' ? '' : subObj.field_category });
                });
            });
            let response = await arrangeCategories(formData.id, catData);
            if (response.data && response.data.length > 0) {
                props.onClose('save');
            }
            dispatch(setLoading(false));
            navigate('/project', { state: projectData });
            console.log(projectData)
        } catch (e: any) {
            dispatch(setLoading(false));
        }
    }


    return (
        <div>
            {isAddVariable &&
                <CreateVariable {...props} previousState={previousState} variableData={dependentformVariables} formData={formData} projectData={projectData} forms={formData.formsList} onClose={(type) => onCreateVariable(type)} isEdit={editable} isAction = {actionEnabled}/>
            }
            {popupType === 'permissions' &&
                <AddPermissions {...props} projectData={formData.projectData} formId={formData.id} variables={variablesResp} onClose={(type) => onAddPermissions(type)} />
            }
            {popupType === 'previewactions' &&
                <PreviewAction {...props} projectData={formData.projectData} formId={formData.id} variables={variablesResp} onClose={(type) => onAddPermissions(type)} />
            }
            {popupType === 'arrange' &&
                <Arrange {...props} projectData={formData.projectData} variables={formVariables} formId={formData.id} onClose={(type) => onArrange(type)} />
            }
            {popupType === 'delete' &&
                <ModalPopup
                    modalType='delete'
                    modalText="Are you sure, you want to delete?"
                    actionText="This action cannot be undone"
                    closeModal={() => { setpopupType(''); setselectedVariable(null); }}
                    onAction={() => onDelete()}
                />
            }
            {popupType === 'create' &&
                <Modal
                    isOpen={true}
                    className="custom-modal confirm-modal"
                    centered={true}
                >
                    <ModalHeader>
                        <Row className='w-100 m-0'>
                            <Col className='p-0'>Alert</Col>
                            <Col className='p-0 text-right'>
                                <i className="icon icon-close" onClick={() => setpopupType('')}></i>
                            </Col>
                        </Row>
                    </ModalHeader>
                    <ModalBody>
                        <div className='row align-items-center'>
                            <div className='col'>
                                <div className='modal-text'>Do you want to see the view of the form before Proceeding</div>
                                <div className='form-text' onClick={() => setpopupType('view')}>View Form</div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <CustomButton type="primary" text="Proceed" onClick={() => create()}></CustomButton>
                    </ModalFooter>
                </Modal>
            }
            {popupType === 'view' ?
                <ViewForm {...props} isPreview={true} projectData={formData.projectData} variables={formVariables} formData={formData} onClose={() => setpopupType('')} /> :
                popupType === 'actions' ?
                    <Actions {...props} projectData={formData.projectData} variables={variablesResp} formId={formData.id} onClose={(type) => onAction(type)} />
                     :
                    <Row className='add-variables m-0'>
                        <Col className='col-md-4 p-0'>
                            <div className='variables-list'>
                                <Row className='m-0'>
                                    <Col><div className='variables-text'>Variables</div></Col>
                                    {
                                        permissionParams.addvariables == "allowed" || Object.keys(permissionParams).length == 0 ?
                                            <Col className='text-right pt-4'><i className="icon icon-add_circle" onClick={() =>{ setisAddVariable(true); SetactionEnabled(false);
                                                SetEditable(false)}}></i></Col>
                                            : ""
                                    }
                                   
                                </Row>
                                <div className='search-container'>
                                    <FieldItem
                                        {...FormElements.searchField}
                                        value={values.searchField}
                                        onChange={(...e) => handleChange(FormElements.searchField.name, ...e)}
                                    />
                                </div>
                                <div style={{ marginLeft: "20px" }}>
                                    {isLoad ? <Loader loaderText="Searching" /> : ""}
                                </div>
                                <div style={{ marginLeft: "30%" }}>
                                    {variables.length == 0 && values.searchField != "" && !isLoad ? <span>Opps No Variables Found</span> : ""}
                                </div>
                                <div className="variables-scroll">
                                    <InfiniteScroll
                                        dataLength={variables.length}
                                        next={() => fetchVariables(page, values.searchField)}
                                        hasMore={!isLast}
                                        loader={<h4></h4>}
                                        height={800}
                                    >
                                        {variables.map((obj: any, index) => (
                                            <div key={index} className='row m-0 variable-container'>
                                                <div className='col-md-4 variable-bg'>
                                                    <div className='variable-name'>{obj.fieldname}</div>
                                                </div>
                                                <div className='col-md-6 options-container'>
                                                    {(obj.fieldtype === 'number' || obj.fieldtype === 'textBox' || obj.fieldtype === 'date') ?
                                                        <div className='open-text'>{obj.fieldtype === 'date' ? 'Date' : 'Open Text'}</div> :
                                                        obj.fieldtype === 'select' ?
                                                            <Row className='w-100'>
                                                                {obj.fieldselectvalue.map((subObj, subIndex) => (
                                                                    <Col key={subIndex} md={3}>
                                                                        <div className='option-value'>{subObj.key}</div>
                                                                    </Col>
                                                                ))}
                                                            </Row> :
                                                            <></>
                                                    }
                                                </div>
                                                <div className='col-md-2 add-container'>
                                                    <div className='add-text' onClick={() => onvariableAdd(obj)}>Add</div>
                                                </div>
                                            </div>
                                        ))}
                                    </InfiniteScroll>
                                </div>
                            </div>
                        </Col>
                        <Col className='col-md-8 p-0'>
                            <div className='form-name-container'>
                                <div className='form-name'>{formData?.form_name}</div>
                            </div>
                            <Row className='variable-header m-0'>
                                <Col className='p-0' md={4}>
                                    <div className='variables-text'>Variables | {variablesLength}</div>
                                </Col>
                                <Col className='p-0 row' md={8}>
                                    {
                                        permissionParams.permissions == "allowed" || Object.keys(permissionParams).length == 0 ?
                                            <div className='col-md-3 variable-options-text' onClick={() => setpopupType('permissions')}>
                                                <i className='icon icon-permission' />
                                                Permissions</div>
                                            : ""
                                    }
                                    {
                                        permissionParams.arrange == "allowed" || Object.keys(permissionParams).length == 0 ?
                                            <div className='col-md-3 variable-options-text' onClick={() => setpopupType('arrange')}>
                                                <i className='icon icon-arrange' />
                                                Arrange</div> : ""
                                    }
                                    {
                                        permissionParams.actions == "allowed" || Object.keys(permissionParams).length == 0 ?
                                            <div className='col-md-3 variable-options-text' onClick={() => setpopupType('actions')}>
                                                <i className='icon icon-action' />
                                                Actions</div> : ""
                                    }
                                    
                                    {/* <div className='col-md-3 variable-options-text' onClick={() => setpopupType('previewactions')}>
                                                <EyeFilled className="eye"/>
                                                Preview
                                    </div> */}                                  

                                </Col>
                            </Row>
                            <div className='added-variables-scroll'>
                                {formVariables && Object.keys(formVariables).map((obj, index) => (
                                    <div key={index}>
                                        {obj !== 'empty' &&
                                            <Row className='m-0 category-container' onClick={() => setselectedCategory(selectedCategory === index ? null : index)}>
                                                <Col className='p-0' md={10}>
                                                    <div className='category-text'>{obj}</div>
                                                </Col>
                                                <Col className='p-0' md={2}>
                                                    {selectedCategory === index ?
                                                        <i className='icon icon-arrow_down' /> :
                                                        <i className='icon icon-arrow_up' />
                                                    }
                                                </Col>
                                            </Row>
                                        }
                                        {(obj === 'empty' || selectedCategory === index) &&
                                            <div className={`${obj === 'empty' ? 'mt-2' : ''}`}>
                                                {formVariables[obj].map((subObj, subIndex) => (
                                                    <Row key={subIndex} className='m-0 variable-conatiner'>
                                                        <Col className='p-0 variable-text' md={3}>{subObj.field_name}</Col>
                                                        <Col className='p-0' md={7}>
                                                            {(subObj.field_type === 'number' || subObj.field_type === 'textBox' || subObj.field_type === 'textArea' || subObj.field_type === 'dateField' || subObj.field_type === 'boolean') ?
                                                                <div className='open-text'>{subObj.field_type === 'fileUpload' ? 'File Upload' : subObj.field_type === 'time' ? 'Time' : subObj.field_type === 'dateTime' ? 'Date Time' : subObj.field_type === 'number' ? 'number' : subObj.field_type === 'dateField' ? 'Date' : subObj.field_type === 'boolean' ? 'Boolean' : 'Open Text'}</div> :
                                                                subObj.field_type === 'dropDown' || subObj.field_type === 'radioButton' || subObj.field_type === 'checkBox' ?
                                                                    <Row className='w-100-option'>
                                                                        {subObj.field_value.map((optionObj, optionIndex) => (
                                                                            <Col key={optionIndex} md={4}>
                                                                                <span className='optiondata'>{optionObj}</span>
                                                                            </Col>
                                                                        ))}
                                                                    </Row> :
                                                                    <></>
                                                            }
                                                        </Col>
                                                        <Col className='p-0' md={2}>
                                                            <div className='row m-0 justify-content-around'>
                                                                {
                                                                    permissionParams.editvariable == "allowed" || Object.keys(permissionParams).length == 0 ?
                                                                    <i className='icon icon-edit' onClick={() => onEdit(subObj,false)} />
                                                                    //   :<i className='icon icon-eye' onClick={() => onEdit(subObj,true)} /> 
                                                                      : ""
                                                                }
                                                                <i className={`icon icon-loop ${subObj.is_time_series ? 'highlighted-color' : ''}`} />
                                                                {
                                                                    permissionParams.deletevariable == "allowed" || Object.keys(permissionParams).length == 0 ?
                                                                        <i className='icon icon-close' onClick={() => { setpopupType('delete'); setselectedVariable(subObj); }} />
                                                                        : ""
                                                                }
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                ))}
                                            </div>
                                        }
                                    </div>
                                ))}
                            </div>
                            <Row className='m-0 footer-container'>
                                <CustomButton type="alert-primary" onClick={() => navigate('/project', { state: projectData })} className="w-100" text="Cancel"></CustomButton>
                                <CustomButton type="primary" onClick={() => setpopupType('create')} className="w-100" text="Update"></CustomButton>
                            </Row>
                        </Col>
                    </Row>
            }
        </div>
    )
}
