import AjaxUtil from '../../axios/axios';

export const getQuestion = async(question) => {
    try {
        return await AjaxUtil.sendRequest(`question&answer/${question}`, {}, false, { method: 'get', showError: true} );
    } catch (error) {
        throw error;
    }
}

export const getInputData = async(id) => {
    try {
        return await AjaxUtil.sendRequest(`sdm/${id}`, {}, false, { method: 'get', showError: true} );
    } catch (error) {
        throw error;
    }
}

export const createSampleSize = async(data) => {
    try {
        return await AjaxUtil.sendRequest(`userproject`, data, false, { method: 'post', showError: true} );
    } catch (error) {
        throw error;
    }
}