import { useState } from "react";
import ProjectsList from "./ProjectsList";

export default function ProjectMetrics(props) {

    
    
    return (
        <>

        <div>
         ProjectMetrics - 
         {
            props.data.map((item) => (
                <button onClick={()=> props.onAction('projectslist',item.stage)}>{item.stage} - {item.projects.length}</button>
            ))
         }
         </div>
      
      </>
    )
  }
  