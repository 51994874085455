import TextArea from "antd/es/input/TextArea"
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLoading } from "../../store/slices/auth";
import { FieldItem, FIELD_TYPES } from "../../widgets/fields";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import ModalComponent from "../common/Modal/modal"
import { CreateFormElements, CreateVariableElements, ReasonFormElements } from "./FormElements";


export const ModifyReasonModal = (props) =>{
    const [device,SetDevice] = useState(true);
    const [values, fields, handleChange, {}] = useFieldItem(ReasonFormElements, {}, {}); 
    const navigate = useNavigate();

    useEffect(() => {
        console.log(values)

    })



    
    return(
        <>
          <ModalComponent
               width={"100%"}
               isOpen={true}
               onClose={() => props.isClose()}
               header="Modify Reason"
               centered={true}
               customClass='invite-modal'
               body={
                <div className="invite-head">        
                        <FieldItem
                            className="reason_area"
                            type={FIELD_TYPES.TEXT_AREA}
                            {...ReasonFormElements.reason}
                            value={values.reason} 
                            onChange={(...e) => handleChange(ReasonFormElements.reason.name, ...e)}
                            touched={fields.reason && fields.reason.hasError}
                            error={fields.reason && fields.reason.errorMsg}
                        />
                </div>
               }
               footer={
                <div>
                    <Button className="reject-btn" onClick={() => props.getReasonData(values.reason)} disabled={!values.reason ? true : false}>Submit</Button>
                </div>
            }
        />
        </>
    )
}