import { useCallback, useEffect, useRef, useState } from "react"
import AnalysisChart from "./AnalysisCharts";
import { Col, Row } from "reactstrap";
import BarChart from "../charts/BarChart";
import PieChart from "../charts/PieChart";
import StackedBarChart from "../charts/StackedBarchart";
import LineChart from "../charts/LineChart";
import GroupedBarChart from "../charts/GroupedBarchart";
import { CustomButton, showToaster, toasterTypes } from "../../../widgets";
import NormalityCharts from "./NormalityCharts";
import PushToEditor from "../../Editor/utils/PushToEditor";
import { htmlToJSON, parseMultiAnalysisTable, parseTable } from "../../Editor/utils/ContentType";
import SectionModal from "../../Editor/utils/SectionModal";

//chart for crosstabs
async function createChartData(data) {
    let dataArray: any = [];
    let charttype = "";
    let xlabel = "";
    let chartArray: any = [];

    //data && data?.charts.map(item => {
    if (data?.charts == "bar" || data?.charts == "pie") {
        data['charts'] = "clusterBarChart"
    }
    if (data?.charts == "clusterBarChart") {
        for (var val in data?.tabledata) {
            let valueArray: any = [];
            data?.colvariablevalues[data?.colvariablenames].map(item => {
                valueArray.push({ name: item, value: data.tabledata[val][item + "_per"].toFixed(2) })
            })
            charttype = data?.charts;
            xlabel = data?.rowvariablenames;
            dataArray.push({ group: val, values: valueArray })

        }

        chartArray.push({ charttype: charttype, data: dataArray, xaxis: xlabel })
    } else if (data?.charts == "pie") {
        for (var val in data.tabledata) {
            data?.colvariablevalues[data?.colvariablenames].map(item => {
                dataArray.push({
                    percentage: data.tabledata[val][item + "_per"].toFixed(2),
                    frequency: data.tabledata[val][item + "_per"].toFixed(2),
                    variable: item
                })
            })
            charttype = data?.charts;
            xlabel = data?.rowvariablenames;
        }
        chartArray.push({ charttype: charttype, data: dataArray, xaxis: xlabel })
    } else if (data?.charts == "bar1") {
        data.rowvariablevalues[data?.rowvariablenames].map(item => {
            dataArray.push({
                percentage: data.tabledata[item]["mean"].toFixed(2).replace(/\.00$/, ''),
                frequency: data.tabledata[item]["mean"].toFixed(2),
                variable: item
            })
        })
    }
    //})
    return chartArray
}



/**
 * function can be used for all charts with mean values
 * @param data 
 * @returns 
 */
async function createChartMeanMedian(data) {
    let dataArray: any = [];
    let charttype = "";
    let xlabel = "";
    let chartArray: any = [];
    // data.charts && data.charts.map(item => {
    let item = data.charts;

    if (item == "bar") {
        dataArray = [];
        await data?.rowvariablevalues.map(item => {
            dataArray.push({
                percentage: data.tabledata[item]["mean"].toFixed(2).replace(/\.00$/, ''),
                frequency: data.tabledata[item]["mean"].toFixed(2),
                variable: item
            })
        })
        charttype = item;
        xlabel = data?.rowvariablenames[0];

        chartArray.push({ charttype: charttype, data: dataArray, xaxis: xlabel })
    } else if (item == "pie") {
        let totalMean = 0;
        dataArray = [];
        data?.rowvariablevalues.map(item => {
            totalMean += data.tabledata[item]["mean"];
        })

        data?.rowvariablevalues.map(item => {
            dataArray.push({
                percentage: (data.tabledata[item]["mean"] * 100 / totalMean).toFixed(2),
                frequency: data.tabledata[item]["mean"].toFixed(2) * 100 / totalMean,
                variable: item
            })

        })
        charttype = item;
        xlabel = data?.rowvariablenames;

        chartArray.push({ charttype: charttype, data: dataArray, xaxis: xlabel })
    } else if (item == "clusterBarChart") {
        let valueArray: any = [];
        dataArray = [];

        data?.rowvariablevalues.map(item => {
            valueArray.push({ name: item, value: data?.tabledata[item]["mean"].toFixed(2) })
        })
        charttype = item;
        xlabel = data?.rowvariablenames[0];
        dataArray.push({ group: 'Mean', values: valueArray })

        chartArray.push({ charttype: charttype, data: dataArray, xaxis: xlabel })

    } else if (item == "stackedBarChart") {
        let valueObject: any = {};
        dataArray = [];
        let categoriesArray: any = [];
        valueObject['label'] = data?.rowvariablenames[0];
        data?.rowvariablevalues.map(item => {
            categoriesArray.push(item)
            valueObject[item] = data?.tabledata[item]["mean"].toFixed(2)
        })
        charttype = item;
        xlabel = data?.rowvariablenames[0];
        dataArray.push(valueObject)

        chartArray.push({ charttype: charttype, data: dataArray, xaxis: xlabel, categories: categoriesArray })
    } else if (item == "lineChart") {
        let valueArray: any = []; dataArray = [];
        data?.rowvariablevalues.map(item => {
            valueArray.push({ x: item, y: parseInt(data?.tabledata[item]["mean"].toFixed(2)) })
        })
        charttype = item;
        xlabel = data?.rowvariablenames[0];
        dataArray.push(valueArray)

        chartArray.push({ charttype: charttype, data: valueArray, xaxis: xlabel })
    }
    //  })

    return chartArray;
}

async function createChartMedian(data) {
    let dataArray: any = [];
    let charttype = "";
    let xlabel = "";
    let chartArray: any = [];
    // data.charts && data.charts.map(item => {
    let item = data.charts;

    if (item == "bar") {
        dataArray = [];

        data?.rowvariablevalues?.map(item => {
            dataArray.push({
                percentage: data.tabledata[item]["median"].toFixed(2).replace(/\.00$/, ''),
                frequency: data.tabledata[item]["median"].toFixed(2),
                variable: item
            })
        })
        charttype = item;
        xlabel = data?.rowvariablenames;
        chartArray.push({ charttype: charttype, data: dataArray, xaxis: xlabel })
    } else if (item == "pie") {
        let totalMean = 0;
        dataArray = [];
        data?.rowvariablevalues.map(item => {
            totalMean += data.tabledata[item]["median"];
        })

        data?.rowvariablevalues.map(item => {
            dataArray.push({
                percentage: (data.tabledata[item]["median"] * 100 / totalMean).toFixed(2),
                frequency: data.tabledata[item]["median"].toFixed(2) * 100 / totalMean,
                variable: item
            })

        })
        charttype = item;
        xlabel = data?.rowvariablenames;

        chartArray.push({ charttype: charttype, data: dataArray, xaxis: xlabel })
    } else if (item == "clusterBarChart") {
        let valueArray: any = [];
        dataArray = [];

        data?.rowvariablevalues.map(item => {
            valueArray.push({ name: item, value: data?.tabledata[item]["mean"] && data?.tabledata[item]["mean"].toFixed(2) })
        })
        charttype = item;
        xlabel = data?.rowvariablenames[0];
        dataArray.push({ group: 'Median', values: valueArray })

        chartArray.push({ charttype: charttype, data: dataArray, xaxis: xlabel })

    } else if (item == "stackedBarChart") {
        let valueObject: any = {};
        dataArray = [];
        let categoriesArray: any = [];
        valueObject['label'] = data?.rowvariablenames[0];
        data?.rowvariablevalues.map(item => {
            categoriesArray.push(item)
            valueObject[item] = data?.tabledata[item]["median"].toFixed(2)
        })
        charttype = item;
        xlabel = data?.rowvariablenames[0];
        dataArray.push(valueObject)

        chartArray.push({ charttype: charttype, data: dataArray, xaxis: xlabel, categories: categoriesArray })
    } else if (item == "lineChart") {
        let valueArray: any = []; dataArray = [];
        data?.rowvariablevalues.map(item => {
            valueArray.push({ x: item, y: parseInt(data?.tabledata[item]["median"].toFixed(2)) })
        })
        charttype = item;
        xlabel = data?.rowvariablenames[0];
        dataArray.push(valueArray)

        chartArray.push({ charttype: charttype, data: valueArray, xaxis: xlabel })
    }
    //  })

    return chartArray;
}



/**
 * cross tab row propotion
 */
export function CrossTabRow(props) {
    const [data, setData] = useState(props.data);
    const [chart, setchart] = useState();
    const [chartData, Setchartdata] = useState<any>([]);
    const [count, Setcount] = useState(0);
    const [editHead, SetEditHead] = useState(false);
    const [head, setHead] = useState("");
    const [ChiSqr, SetChiSqr] = useState("");
    const [PValue, SetPValue] = useState("");
    const [actionPush, setactionPush] = useState('');
    const [selectedTable, setSelectedTable] = useState('');
    const [tableType, setTableType] = useState("");
    const tableRef = useRef<HTMLTableElement>(null);
    const tableHeadRef = useRef<HTMLTableElement>(null);


    /**
    * function call to Push code to editor
    */
    const addToEditor = (data) => {
        console.log(data)
        getTableJson(data)
    }

    const getTableJson = (data) => {
        const tableHTML = tableRef?.current;

        console.log(tableHTML, selectedTable)

        let response: any = "";
        if (tableType == "multi") {
            response = parseMultiAnalysisTable(tableHTML, tableHeadRef)
        } else {
            response = htmlToJSON(tableHTML, selectedTable)
        }
        console.log(response)
        response.then(tableData => {
            let pushResponse;
            tableData.map(item => {
                console.log(item, data.section.value)
                pushResponse = PushToEditor(item, data.section.value)
            })
            if (pushResponse) {
                showToaster(toasterTypes.SUCCESS, "Pushed code to editor")
            }

        })
        setactionPush("")
    };

    const setCopyAction = (data) => {
        setTableType("multi")
        setSelectedTable(data)
        setactionPush("section")
    }

    useEffect(() => {
        console.log(props.data)
        let countval = 0;
        data?.rowvariablevalues[data?.rowvariablenames].map(item => {
            if (data?.tabledata[item]['Chi square value']) {
                SetChiSqr(data?.tabledata[item]['Chi square value'].toFixed(2))
            }

            if (data?.tabledata[item]['P value']) {
                SetPValue(parseFloat(data?.tabledata[item]['P value']).toFixed(3))
            }
            data?.colvariablevalues[data.colvariablenames].map(itemkey => {
                countval += data?.tabledata[item][itemkey];
            })
        })
        Setcount(countval)
        setchart(data.charts)
        getData(props.data)
    }, [])

    const getData = async (data) => {
        //let chartObject = await createChartData(data);
        //Setchartdata(chartObject)
    }
    useEffect(() => {
        let tabletitle = `${data.tablename}` + `(N=${count})` + `[${data.tabletype}]`;
        setHead(tabletitle)
    }, [chartData, count])

    const onEditHead = (data) => {
        setHead(data)
    }


    return (
        <>
            {data &&
                <div className="tablerol">
                    {
                        actionPush == "section" &&
                        <SectionModal pushAction={addToEditor} onclose={() => setactionPush("")} />
                    }
                    <Row>
                        <Col md={9}>
                            {
                                editHead ?
                                    <input style={{ width: "100%" }} className="head" value={head} onChange={(e) => onEditHead(e.target.value)} onBlur={() => SetEditHead(false)} />
                                    :
                                    <div className={"rowdata" + props.keyValue + "-header"} ref={tableHeadRef}><span>Table {props.id} : </span><span onClick={() => SetEditHead(true)}>{head}</span></div>
                            }
                        </Col>
                        <Col md={3}>
                            <CustomButton text="copy to editor" onClick={() => setCopyAction("rowdata" + props.keyValue)}></CustomButton>
                        </Col>
                    </Row>
                    {
                        <div className={"rowdata" + props.keyValue} ref={tableRef}>
                            <table >
                                <thead>
                                    <tr>
                                        <th>Parameter</th>
                                        {data?.colvariablevalues[data?.colvariablenames].length > 0 ?
                                            <th colSpan={data?.colvariablevalues[data?.colvariablenames].length} scope="colgroup">{data?.colvariablenames}</th>
                                            : ""}
                                        <th scope="col" >Chi Square Value</th>
                                        <th scope="col">P Value</th>
                                    </tr>
                                    <tr>
                                        <th scope="col"> </th>
                                        {
                                            data?.colvariablevalues[data?.colvariablenames].map(item => {
                                                return <th scope="col">{item}</th>
                                            })
                                        }
                                        <th scope="col"> </th>
                                        <th scope="col"> </th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ textAlign: "left" }} colSpan={data?.colvariablevalues[data?.colvariablenames].length + 3}>
                                            {data?.rowvariablenames} with {data?.colvariablenames}
                                        </td>
                                    </tr>
                                    {
                                        //data.rowvariablevalues[data.rowvariablenames].map((item, i) => {
                                        Object.keys(data?.tabledata).map((item, i) => {

                                            let countN = 0
                                            return <>
                                                <tr>

                                                    <td>{item} (N={data?.colvariablevalues[data.colvariablenames].map((itemkey, i) => {
                                                        countN = countN + parseInt(data?.tabledata[item][itemkey]);
                                                        let datacount = data?.colvariablevalues[data.colvariablenames].length - 1 == i ? countN : ""
                                                        return datacount
                                                    })})</td>

                                                    {
                                                        data?.colvariablevalues[data?.colvariablenames].map(itemval => {
                                                            return <><td>{data?.tabledata[item][itemval]} ({data?.tabledata[item][itemval + '_per'].toFixed(2)}%)</td>
                                                            </>
                                                        })
                                                    }

                                                    {
                                                        i == 0 ?
                                                            <>
                                                                <td rowSpan={Object.keys(data?.tabledata).length}>{ChiSqr ? ChiSqr : "*"}</td>
                                                                <td rowSpan={Object.keys(data?.tabledata).length}>{PValue ? PValue : "*"}</td>
                                                            </> : <>
                                                            </>
                                                    }



                                                </tr>
                                            </>
                                        })

                                    }
                                    <tr>
                                        <td style={{ textAlign: "left" }} colSpan={data?.colvariablevalues[data?.colvariablenames].length + 3}>
                                            Note {PValue ? "" : "(*)"}: {data?.Note}
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            }


            <AnalysisChart data={data} type={1} />


        </>
    )
}

/**
 * cross tab col propotion
 */
export function CrossTabCol(props) {
    const [data, setData] = useState(props.data);
    const [chartData, Setchartdata] = useState<any>([]);
    const [count, Setcount] = useState(0);
    const [editHead, SetEditHead] = useState(false);
    const [head, setHead] = useState("");
    const [ChiSqr, SetChiSqr] = useState("");
    const [PValue, SetPValue] = useState("");
    const [actionPush, setactionPush] = useState('');
    const [selectedTable, setSelectedTable] = useState('');
    const [tableType, setTableType] = useState("");
    const tableRef = useRef<HTMLTableElement>(null);
    const tableHeadRef = useRef<HTMLTableElement>(null);


    /**
    * function call to Push code to editor
    */
    const addToEditor = (data) => {
        console.log(data)
        getTableJson(data)
    }

    const getTableJson = (data) => {
        const tableHTML = tableRef?.current;

        console.log(tableHTML, selectedTable)

        let response: any = "";
        if (tableType == "multi") {
            response = parseMultiAnalysisTable(tableHTML, tableHeadRef)
        } else {
            response = htmlToJSON(tableHTML, selectedTable)
        }
        console.log(response)
        response.then(tableData => {
            let pushResponse;
            tableData.map(item => {
                console.log(item, data.section.value)
                pushResponse = PushToEditor(item, data.section.value)
            })
            if (pushResponse) {
                showToaster(toasterTypes.SUCCESS, "Pushed code to editor")
            }

        })
        setactionPush("")
    };

    const setCopyAction = (data) => {
        setTableType("multi")
        setSelectedTable(data)
        setactionPush("section")
    }

    useEffect(() => {
        console.log(props.data)

        let countval = 0;
        data?.rowvariablevalues[data?.rowvariablenames].map(item => {
            if (data?.tabledata[item]['Chi square value']) {
                SetChiSqr(data?.tabledata[item]['Chi square value'].toFixed(2))
            }

            if (data?.tabledata[item]['P value']) {
                SetPValue(data?.tabledata[item]['P value'].toFixed(3))
            }


        })

        data?.colvariablevalues[data.colvariablenames].map(itemkey => {
            Object.keys(data.tabledata).map(item => {
                countval += data?.tabledata[item][itemkey];
            })
        })


        Setcount(countval)
        getData(props.data);
    }, [])

    useEffect(() => {
        let tabletitle = `${data.tablename}` + `(N=${count})` + `[${data.tabletype}]`;
        setHead(tabletitle)
    }, [chartData, count])

    const getData = async (data1) => {
        let chartObject = await createChartData(data1);
        Setchartdata(chartObject)
    }

    const onEditHead = (data) => {
        setHead(data)
    }

    return (
        <>
            {data &&
                <div className="tablerol">
                    {
                        actionPush == "section" &&
                        <SectionModal pushAction={addToEditor} onclose={() => setactionPush("")} />
                    }

                    <Row>
                        <Col md={9}>
                            {
                                editHead ?
                                    <input style={{ width: "100%" }} className="head" value={head} onChange={(e) => onEditHead(e.target.value)} onBlur={() => SetEditHead(false)} />
                                    :
                                    <div className={"colpropdata" + props.keyValue + "-header"} ref={tableHeadRef}><span>Table {props.id} : </span><span onClick={() => SetEditHead(true)}>{head}</span></div>
                            }
                        </Col>
                        <Col md={3}>
                            <CustomButton text="copy to editor" onClick={() => setCopyAction("colpropdata" + props.keyValue)}></CustomButton>
                        </Col>
                    </Row>
                    {
                        <div className={"colpropdata" + props.keyValue} ref={tableRef}>
                            <table>
                                <thead>
                                    <tr>
                                        <th >Parameter</th>
                                        {data?.colvariablevalues[data?.colvariablenames].length > 0 ?
                                            <th colSpan={data?.colvariablevalues[data?.colvariablenames].length} scope="colgroup">{data?.colvariablenames}</th>
                                            : ""}
                                        <th scope="col" >Chi Square Value</th>
                                        <th scope="col" >P Value</th>
                                    </tr>
                                    <tr>
                                        <th scope="col"> </th>

                                        {
                                            data?.colvariablevalues[data?.colvariablenames].map(item => {
                                                return <th scope="col">{item}</th>
                                            })
                                        }
                                        <th scope="col"> </th>
                                        <th scope="col"> </th>

                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td style={{ textAlign: "left" }} colSpan={data?.colvariablevalues[data?.colvariablenames].length + 3}>
                                            {data?.rowvariablenames} with {data?.colvariablenames}
                                        </td>
                                    </tr>
                                    {
                                        Object.keys(data?.tabledata).map((item, i) => {
                                            let countN = 0;
                                            return <>
                                                <tr>

                                                    {/* <td>{item} (N={data?.colvariablevalues[data.colvariablenames].map(itemkey => {
                                                    let countN = 0;
                                                    countN += data?.tabledata[item][itemkey];
                                                    return countN
                                                })})</td> */}

                                                    <td>{item} (N={data?.colvariablevalues[data.colvariablenames].map((itemkey, i) => {
                                                        countN = countN + parseInt(data?.tabledata[item][itemkey]);
                                                        let datacount = data?.colvariablevalues[data.colvariablenames].length - 1 == i ? countN : ""
                                                        return datacount
                                                    })})</td>
                                                    {
                                                        data?.colvariablevalues[data?.colvariablenames].map(itemval => {
                                                            return <><td>{data?.tabledata[item][itemval]} ({data?.tabledata[item][itemval + '_per'].toFixed(2)}%)</td>
                                                            </>
                                                        })
                                                    }
                                                    {
                                                        i == 0 ?
                                                            <>
                                                                <td rowSpan={Object.keys(data?.tabledata).length}>{ChiSqr ? ChiSqr : "*"}</td>
                                                                <td rowSpan={Object.keys(data?.tabledata).length}>{PValue ? PValue : "*"}</td>
                                                            </> : <></>
                                                    }

                                                </tr>
                                            </>
                                        })

                                    }
                                    <tr>
                                        <td style={{ textAlign: "left" }} colSpan={data?.colvariablevalues[data?.colvariablenames].length + 3}>
                                            Note {PValue ? "" : "(*)"}: {data?.Note}
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    }

                    <AnalysisChart data={data} type={1} />

                </div>
            }


        </>
    )
}

/**
 * independednt T test
 */
export function IndependentTTest(props) {
    const [data, setData] = useState(props.data);
    const [editHead, SetEditHead] = useState(false);
    const [head, setHead] = useState("");
    const [count, Setcount] = useState(0);
    const [chartData, Setchartdata] = useState<any>([]);
    const [normality, setNormality] = useState(false);
    const [actionPush, setactionPush] = useState('');
    const [selectedTable, setSelectedTable] = useState('');
    const [tableType, setTableType] = useState("");
    const tableRef = useRef<HTMLTableElement>(null);
    const tableHeadRef = useRef<HTMLTableElement>(null);


    /**
    * function call to Push code to editor
    */
    const addToEditor = (data) => {
        console.log(data)
        getTableJson(data)
    }

    const getTableJson = (data) => {
        const tableHTML = tableRef?.current;

        console.log(tableHTML, selectedTable)

        let response: any = "";
        if (tableType == "multi") {
            response = parseMultiAnalysisTable(tableHTML, tableHeadRef)
        } else {
            response = htmlToJSON(tableHTML, selectedTable)
        }
        console.log(response)
        response.then(tableData => {
            let pushResponse;
            tableData.map(item => {
                console.log(item, data.section.value)
                pushResponse = PushToEditor(item, data.section.value)
            })
            if (pushResponse) {
                showToaster(toasterTypes.SUCCESS, "Pushed code to editor")
            }

        })
        setactionPush("")
    };

    const setCopyAction = (data) => {
        setTableType("multi")
        setSelectedTable(data)
        setactionPush("section")
    }

    const onClose = () => {
        setNormality(false)
    }

    useEffect(() => {
        let countval = 0;
        data?.rowvariablevalues.map(item => {
            countval += data?.tabledata[item]['count'];
        })
        Setcount(countval)

        getChartData(data)
    }, [])

    const onEditHead = (data) => {
        setHead(data)
    }

    const getChartData = async (data) => {
        let chartdata = await createChartMeanMedian(data)
        Setchartdata(chartdata)
        props.updateComp(chartdata)
        console.log(chartData)
    }

    useEffect(() => {
        let tabletitle = `${data.tablename}` + `(N=${count})` + `[${data.tabletype}]`;
        setHead(tabletitle)
    }, [chartData, count])



    return (
        <>
            {data &&
                <div className="tablerol">
                    {
                        actionPush == "section" &&
                        <SectionModal pushAction={addToEditor} onclose={() => setactionPush("")} />
                    }
                    <Row>
                        <Col md={9}>
                            {
                                editHead ?
                                    <input style={{ width: "100%" }} className="head" value={head} onChange={(e) => onEditHead(e.target.value)} onBlur={() => SetEditHead(false)} />
                                    :
                                    <div className={"indepttestdata" + props.keyValue + "-header"} ref={tableHeadRef}><span>Table {props.id} : </span><span onClick={() => SetEditHead(true)}>{head}</span></div>
                            }
                        </Col>
                        <Col md={3}>
                            <CustomButton text="copy to editor" onClick={() => setCopyAction("indepttestdata" + props.keyValue)}></CustomButton>
                        </Col>
                    </Row>
                    {
                        <div className={"indepttestdata" + props.keyValue} ref={tableRef}>
                            <table>
                                <thead>
                                    <tr>
                                        <th >Parameter</th>
                                        <th colSpan={data?.rowvariablevalues.length} scope="colgroup">{data?.rowvariablenames[0]}</th>
                                        <th scope="col" >P Value</th>
                                    </tr>
                                    <tr>
                                        <th ></th>
                                        {
                                            data?.rowvariablevalues.map(item => {
                                                return <th scope="col">{item} (N={data?.tabledata[item]['count']})</th>
                                            })
                                        }
                                        <th ></th>
                                    </tr>
                                    <tr>
                                        <th ></th>
                                        {
                                            data?.rowvariablevalues.map(item => {
                                                return <th scope="col">Mean ± SD</th>
                                            })
                                        }
                                        <th ></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{data?.colvariablenames[0]}</td>
                                        {
                                            data?.rowvariablevalues.map(item => {
                                                return <>
                                                    <td>{data?.tabledata[item]['mean'].toFixed(2)} ± {data?.tabledata[item]['std'].toFixed(2)}</td>
                                                </>
                                            })
                                        }
                                        {
                                            Object.keys(data?.tabledata).map((item, i) => {
                                                return <>
                                                    {
                                                        i == 0 ? <td >{data?.tabledata[item]['P Value'].toFixed(3)}</td>
                                                            : ""
                                                    }
                                                </>
                                            })
                                        }
                                    </tr>

                                    <tr>
                                        <td style={{ textAlign: "left" }} colSpan={data?.rowvariablevalues.length + 2}>
                                            Normality Check : {data?.normality_check}
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    }

                </div>
            }
            <br></br>
            <CustomButton type="primary" text="Show Normality Plots" style={{ margin: "10px 0px" }} onClick={() => setNormality(true)} />
            {
                normality ?
                    <NormalityCharts onClose={onClose} data={data} />
                    : ""
            }
            <AnalysisChart data={data} type={2} />

        </>
    )
}

/**
 * independednt T test Row
 */
export function IndependentTTestRow(props) {
    const [data, setData] = useState(props.data);
    const [editHead, SetEditHead] = useState(false);
    const [head, setHead] = useState("");
    const [count, Setcount] = useState(0);
    const [chartData, Setchartdata] = useState<any>([]);
    const [normality, setNormality] = useState(false);
    const [actionPush, setactionPush] = useState('');
    const [selectedTable, setSelectedTable] = useState('');
    const [tableType, setTableType] = useState("");
    const tableRef = useRef<HTMLTableElement>(null);
    const tableHeadRef = useRef<HTMLTableElement>(null);


    /**
    * function call to Push code to editor
    */
    const addToEditor = (data) => {
        console.log(data)
        getTableJson(data)
    }

    const getTableJson = (data) => {
        const tableHTML = tableRef?.current;

        console.log(tableHTML, selectedTable)

        let response: any = "";
        if (tableType == "multi") {
            response = parseMultiAnalysisTable(tableHTML, tableHeadRef)
        } else {
            response = htmlToJSON(tableHTML, selectedTable)
        }
        console.log(response)
        response.then(tableData => {
            let pushResponse;
            tableData.map(item => {
                console.log(item, data.section.value)
                pushResponse = PushToEditor(item, data.section.value)
            })
            if (pushResponse) {
                showToaster(toasterTypes.SUCCESS, "Pushed code to editor")
            }

        })
        setactionPush("")
    };

    const setCopyAction = (data) => {
        setTableType("multi")
        setSelectedTable(data)
        setactionPush("section")
    }
    const onClose = () => {
        setNormality(false)
    }

    useEffect(() => {
        let tabletitle = `${data.tablename}` + `(N=${count})` + `[${data.tabletype}]`;
        setHead(tabletitle)
    }, [chartData, count])

    useEffect(() => {
        let countval = 0;
        data?.rowvariablevalues.map(item => {
            countval += data?.tabledata[item]['count'];
        })
        Setcount(countval)

        const fetchData = async () => {
            await getChartData(data)
        }

        fetchData();


    }, [])

    const onEditHead = (data) => {
        setHead(data)
    }

    const getChartData = async (data) => {
        let chartdata = await createChartMeanMedian(data)
        Setchartdata(chartdata)
    }

    useEffect(() => {
    }, [chartData, count])

    return (
        <>
            {data &&
                <div className="tablerol">
                    {
                        actionPush == "section" &&
                        <SectionModal pushAction={addToEditor} onclose={() => setactionPush("")} />
                    }
                    <Row>
                        <Col md={9}>
                            {
                                editHead ?
                                    <input style={{ width: "100%" }} className="head" value={head} onChange={(e) => onEditHead(e.target.value)} onBlur={() => SetEditHead(false)} />
                                    :
                                    <div className={"indepttestdatarow" + props.keyValue + "-header"} ref={tableHeadRef}><span>Table {props.id} : </span><span onClick={() => SetEditHead(true)}>{head}</span></div>
                            }
                        </Col>
                        <Col md={3}>
                            <CustomButton text="copy to editor" onClick={() => setCopyAction("indepttestdatarow" + props.keyValue)}></CustomButton>
                        </Col>
                    </Row>
                    {
                        <div className={"indepttestdatarow" + props.keyValue} ref={tableRef}>
                            <table>
                                <thead>
                                    <tr>
                                        <th >Parameter</th>
                                        <th colSpan={data?.colvariablenames.length} scope="colgroup">{data?.colvariablenames[0]}</th>
                                        <th scope="col" >Mean Difference</th>
                                        <th scope="col" >Lower CI</th>
                                        <th scope="col" >Upper CI</th>
                                        <th scope="col" >P Value</th>
                                    </tr>

                                    <tr>
                                        <th ></th>
                                        <th scope="col">Mean ± SD</th>
                                        <th ></th>
                                        <th ></th>
                                        <th ></th>
                                        <th ></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ textAlign: "left" }} colSpan={6}>
                                            {data?.rowvariablenames[0]} with {data?.colvariablenames[0]}
                                        </td>
                                    </tr>
                                    {
                                        data?.rowvariablevalues.map((item, i) => {
                                            return <>

                                                <tr>

                                                    <td>{item}({data?.tabledata[item]['count']})</td>

                                                    <td>{data?.tabledata[item]['mean'].toFixed(2)} ± {data?.tabledata[item]['std'].toFixed(2)}</td>


                                                    {
                                                        i == 0 ?
                                                            <>

                                                                <td rowSpan={2}>{data?.tabledata[item]['Mean Difference'].toFixed(2)}</td>
                                                                <td rowSpan={2}>{data?.tabledata[item]['Lower CI'].toFixed(2)}</td>
                                                                <td rowSpan={2}>{data?.tabledata[item]['Upper CI'].toFixed(2)}</td>
                                                                <td rowSpan={2}>{data?.tabledata[item]['P Value'].toFixed(3)}</td>


                                                            </>

                                                            : ""
                                                    }



                                                </tr>
                                            </>
                                        })
                                    }
                                    <tr>
                                        <td style={{ textAlign: "left" }} colSpan={6}>
                                            Normality Check : {data?.normality_check}
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            }
            <br></br>
            <CustomButton type="primary" text="Show Normality Plots" style={{ margin: "10px 0px" }} onClick={() => setNormality(true)} />
            {
                normality ?
                    <NormalityCharts onClose={onClose} data={data} />
                    : ""
            }
            <AnalysisChart data={data} type={2} />

        </>
    )
}


/**
 * Mann Whitney test
 */
export function MannWhitney(props) {
    const [data, setData] = useState(props.data);
    const [editHead, SetEditHead] = useState(false);
    const [head, setHead] = useState("");
    const [count, Setcount] = useState(0);
    const [chartData, Setchartdata] = useState<any>([]);
    const [normality, setNormality] = useState(false);
    const [actionPush, setactionPush] = useState('');
    const [selectedTable, setSelectedTable] = useState('');
    const [tableType, setTableType] = useState("");
    const tableRef = useRef<HTMLTableElement>(null);
    const tableHeadRef = useRef<HTMLTableElement>(null);


    /**
    * function call to Push code to editor
    */
    const addToEditor = (data) => {
        console.log(data)
        getTableJson(data)
    }

    const getTableJson = (data) => {
        const tableHTML = tableRef?.current;

        console.log(tableHTML, selectedTable)

        let response: any = "";
        if (tableType == "multi") {
            response = parseMultiAnalysisTable(tableHTML, tableHeadRef)
        } else {
            response = htmlToJSON(tableHTML, selectedTable)
        }
        console.log(response)
        response.then(tableData => {
            let pushResponse;
            tableData.map(item => {
                console.log(item, data.section.value)
                pushResponse = PushToEditor(item, data.section.value)
            })
            if (pushResponse) {
                showToaster(toasterTypes.SUCCESS, "Pushed code to editor")
            }

        })
        setactionPush("")
    };

    const setCopyAction = (data) => {
        setTableType("multi")
        setSelectedTable(data)
        setactionPush("section")
    }

    const onClose = () => {
        setNormality(false)
    }

    useEffect(() => {
        let tabletitle = `${data.tablename}` + `(N=${count})` + `[${data.tabletype}]`;
        setHead(tabletitle)
    }, [chartData, count])

    useEffect(() => {
        let countval = 0;
        data?.rowvariablevalues.map(item => {
            countval += data?.tabledata[item]['count'];
        })
        Setcount(countval)



        getChartData(data)
    }, [])

    const getChartData = async (data) => {
        let chartdata = await createChartMedian(data)
        Setchartdata(chartdata)
    }

    const onEditHead = (data) => {
        setHead(data)
    }
    return (
        <>
            {data &&
                <div className="tablerol">
                    {
                        actionPush == "section" &&
                        <SectionModal pushAction={addToEditor} onclose={() => setactionPush("")} />
                    }

                    <Row className="m-2">
                        <Col md={9}>
                            {
                                editHead ?
                                    <input style={{ width: "100%" }} className="head" value={head} onChange={(e) => onEditHead(e.target.value)} onBlur={() => SetEditHead(false)} />
                                    :
                                    <div className={"mannwhitneydata" + props.keyValue + "-header"} ref={tableHeadRef}><span>Table {props.id} : </span><span onClick={() => SetEditHead(true)}>{head}</span></div>
                            }
                        </Col>
                        <Col md={3}>
                            <CustomButton text="copy to editor" onClick={() => setCopyAction("mannwhitneydata" + props.keyValue)}></CustomButton>
                        </Col>
                    </Row>
                    {
                        <div className={"mannwhitneydata" + props.keyValue} ref={tableRef}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Parameter</th>
                                        <th colSpan={data?.colvariablenames.length} scope="colgroup">{data?.colvariablenames[0]}</th>
                                        <th scope="col" >P Value</th>
                                    </tr>

                                    <tr>
                                        <th></th>
                                        <th scope="col">Median(IQR)</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        data?.rowvariablevalues.map((item, i) => {
                                            return <>
                                                <tr>
                                                    <td>{item}(N={data?.tabledata[item]['count']})</td>
                                                    <td>{data?.tabledata[item]['median'].toFixed(2)} {data?.tabledata[item]['Quartiles']}</td>
                                                    {
                                                        i == 0 ?
                                                            <>
                                                                <td rowSpan={2}>{data?.tabledata[item]['P Value'].toFixed(3)}</td>
                                                            </>
                                                            : ""
                                                    }
                                                </tr>
                                            </>
                                        })
                                    }
                                    <tr>
                                        <td style={{ textAlign: "left" }} colSpan={2}>
                                            Normality Check : {data?.normality_check}
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            }
            <br></br>
            <CustomButton type="primary" text="Show Normality Plots" style={{ margin: "10px 0px" }} onClick={() => setNormality(true)} />
            {
                normality ?
                    <NormalityCharts onClose={onClose} data={data} />
                    : ""
            }
            <AnalysisChart data={data} type={3} />

        </>
    )
}


/**
 * One way Annova
 */
export function OneWayAnnova(props) {
    const [data, setData] = useState(props.data);
    const [chartData, Setchartdata] = useState<any>([]);
    const [count, Setcount] = useState(0);
    const [editHead, SetEditHead] = useState(false);
    const [head, setHead] = useState("");
    const [categories, SetCategories] = useState([]);
    const [normality, setNormality] = useState(false);
    const [actionPush, setactionPush] = useState('');
    const [selectedTable, setSelectedTable] = useState('');
    const [tableType, setTableType] = useState("");
    const tableRef = useRef<HTMLTableElement>(null);
    const tableHeadRef = useRef<HTMLTableElement>(null);


    /**
    * function call to Push code to editor
    */
    const addToEditor = (data) => {
        console.log(data)
        getTableJson(data)
    }

    const getTableJson = (data) => {
        const tableHTML = tableRef?.current;

        console.log(tableHTML, selectedTable)

        let response: any = "";
        if (tableType == "multi") {
            response = parseMultiAnalysisTable(tableHTML, tableHeadRef)
        } else {
            response = htmlToJSON(tableHTML, selectedTable)
        }
        console.log(response)
        response.then(tableData => {
            let pushResponse;
            tableData.map(item => {
                console.log(item, data.section.value)
                pushResponse = PushToEditor(item, data.section.value)
            })
            if (pushResponse) {
                showToaster(toasterTypes.SUCCESS, "Pushed code to editor")
            }

        })
        setactionPush("")
    };

    const setCopyAction = (data) => {
        setTableType("multi")
        setSelectedTable(data)
        setactionPush("section")
    }

    const onClose = () => {
        setNormality(false)
    }

    useEffect(() => {
        let countval = 0;
        data?.rowvariablevalues.map(item => {
            countval += data?.tabledata[item]['count'];
        })
        Setcount(countval)

        getChartData(data)
    }, [data])

    const getChartData = async (data) => {
        let chartdata = await createChartMeanMedian(data)
        Setchartdata(chartdata)
    }

    useEffect(() => {
        let tabletitle = `${data.tablename}` + `(N=${count})` + `[${data.tabletype}]`;
        setHead(tabletitle)
    }, [chartData, count])

    const onEditHead = (data) => {
        setHead(data)
    }

    return (
        <>
            {data &&
                <div className="tablerol">
                    {
                        actionPush == "section" &&
                        <SectionModal pushAction={addToEditor} onclose={() => setactionPush("")} />
                    }

                    <Row className="m-2">
                        <Col md={9}>
                            {
                                editHead ?
                                    <input style={{ width: "100%" }} className="head" value={head} onChange={(e) => onEditHead(e.target.value)} onBlur={() => SetEditHead(false)} />
                                    :
                                    <div className={"onewayannovadata" + props.keyValue + "-header"} ref={tableHeadRef}><span>Table {props.id} : </span><span onClick={() => SetEditHead(true)}>{head}</span></div>
                            }
                        </Col>
                        <Col md={3}>
                            <CustomButton text="copy to editor" onClick={() => setCopyAction("onewayannovadata" + props.keyValue)}></CustomButton>
                        </Col>
                    </Row>
                    {
                        <div className={"onewayannovadata" + props.keyValue} ref={tableRef}>
                            <table>
                                <thead>
                                    <tr>
                                        <th >Parameter</th>
                                        <th colSpan={data?.rowvariablevalues?.length} scope="colgroup">{data?.rowvariablenames[0]}</th>
                                        <th scope="col" >P Value</th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        {
                                            data?.rowvariablevalues.map(item => {
                                                return <th scope="col">{item} (N={data?.tabledata[item]['count']})</th>
                                            })
                                        }
                                        <th></th>

                                    </tr>
                                    <tr>
                                        <th></th>

                                        {
                                            data?.rowvariablevalues.map(item => {
                                                return <th scope="col">Mean ± SD</th>
                                            })
                                        }
                                        <th></th>

                                    </tr>
                                </thead>
                                <tbody>


                                    <tr>

                                        <td>{data?.colvariablenames[0]}</td>
                                        {
                                            data?.rowvariablevalues.map(item => {
                                                return <>
                                                    <td>{data?.tabledata[item]['mean'].toFixed(2)} ± {data?.tabledata[item]['std'].toFixed(2)}</td>
                                                </>
                                            })
                                        }

                                        {
                                            Object.keys(data?.tabledata).map((item, i) => {
                                                return <>
                                                    {
                                                        Object.keys(data?.tabledata).length == i + 1 ? <td>{data?.tabledata[item]?.['P Value (simple)'] && data?.tabledata[item]?.['P Value (simple)'].toFixed(3)}</td>
                                                            : ""
                                                    }
                                                </>
                                            })
                                        }
                                    </tr>

                                    <tr>
                                        <td style={{ textAlign: "left" }} colSpan={data?.rowvariablevalues.length + 2}>
                                            Normality Check : {data?.normality_check}
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            }
            <br></br>
            <CustomButton type="primary" text="Show Normality Plots" style={{ margin: "10px 0px" }} onClick={() => setNormality(true)} />
            {
                normality ?
                    <NormalityCharts onClose={onClose} data={data} />
                    : ""
            }
            <AnalysisChart data={data} type={2} />


        </>
    )
}

/**
 * One way onnova  Row
 */
export function OneWayAnnovaRow(props) {
    const [data, setData] = useState(props.data);
    const [editHead, SetEditHead] = useState(false);
    const [head, setHead] = useState("");
    const [chartData, Setchartdata] = useState<any>([]);
    const [count, Setcount] = useState(0);
    const [normality, setNormality] = useState(false);
    const [actionPush, setactionPush] = useState('');
    const [selectedTable, setSelectedTable] = useState('');
    const [tableType, setTableType] = useState("");
    const tableRef = useRef<HTMLTableElement>(null);
    const tableHeadRef = useRef<HTMLTableElement>(null);


    /**
    * function call to Push code to editor
    */
    const addToEditor = (data) => {
        console.log(data)
        getTableJson(data)
    }

    const getTableJson = (data) => {
        const tableHTML = tableRef?.current;

        console.log(tableHTML, selectedTable)

        let response: any = "";
        if (tableType == "multi") {
            response = parseMultiAnalysisTable(tableHTML, tableHeadRef)
        } else {
            response = htmlToJSON(tableHTML, selectedTable)
        }
        console.log(response)
        response.then(tableData => {
            let pushResponse;
            tableData.map(item => {
                console.log(item, data.section.value)
                pushResponse = PushToEditor(item, data.section.value)
            })
            if (pushResponse) {
                showToaster(toasterTypes.SUCCESS, "Pushed code to editor")
            }

        })
        setactionPush("")
    };

    const setCopyAction = (data) => {
        setTableType("multi")
        setSelectedTable(data)
        setactionPush("section")
    }

    const onClose = () => {
        setNormality(false)
    }

    useEffect(() => {
        let countval = 0;
        data?.rowvariablevalues.map(item => {
            countval += data?.tabledata[item]['count'];
        })
        Setcount(countval)

        getChartData(data)
    }, [])

    const onEditHead = (data) => {
        setHead(data)
    }

    const getChartData = async (data) => {
        let chartdata = await createChartMeanMedian(data)
        Setchartdata(chartdata)
    }

    useEffect(() => {
        let tabletitle = `${data.tablename}` + `(N=${count})` + `[${data.tabletype}]`;
        setHead(tabletitle)

    }, [chartData, count])

    return (
        <>
            {data &&
                <div className="tablerol">
                    {
                        actionPush == "section" &&
                        <SectionModal pushAction={addToEditor} onclose={() => setactionPush("")} />
                    }

                    <Row className="m-2">
                        <Col md={9}>
                            {
                                editHead ?
                                    <input style={{ width: "100%" }} className="head" value={head} onChange={(e) => onEditHead(e.target.value)} onBlur={() => SetEditHead(false)} />
                                    :
                                    <div className={"onewayannovarow" + props.keyValue + "-header"} ref={tableHeadRef}><span>Table {props.id} : </span><span onClick={() => SetEditHead(true)}>{head}</span></div>
                            }
                        </Col>
                        <Col md={3}>
                            <CustomButton text="copy to editor" onClick={() => setCopyAction("onewayannovarow" + props.keyValue)}></CustomButton>
                        </Col>
                    </Row>
                    {
                        <div className={"onewayannovarow" + props.keyValue} ref={tableRef}>
                            <table>
                                <thead>
                                    <tr>
                                        <th >Parameter</th>
                                        <th colSpan={data?.colvariablenames.length} scope="colgroup">{data?.colvariablenames[0]}</th>
                                        <th scope="col" >Mean Difference</th>
                                        <th scope="col" >Lower CI</th>
                                        <th scope="col" >Upper CI</th>
                                        <th scope="col" >P Value</th>
                                    </tr>

                                    <tr>
                                        <th></th>
                                        <th scope="col">Mean ± SD</th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ textAlign: "left" }} colSpan={6}>
                                            {data?.rowvariablenames[0]} with {data?.colvariablenames[0]}
                                        </td>
                                    </tr>
                                    {
                                        data?.rowvariablevalues.map((item, i) => {
                                            return <>
                                                <tr>
                                                    <td>{item}({data?.tabledata[item]['count']})</td>
                                                    <td>{data?.tabledata[item]['mean'].toFixed(2)} ± {data?.tabledata[item]['std'].toFixed(2)}</td>
                                                    <>
                                                        <td >{data?.tabledata[item]['Mean Difference'] && data?.tabledata[item]['Mean Difference'].toFixed(2)}</td>
                                                        <td>{data?.tabledata[item]['Lower CI'] && data?.tabledata[item]['Lower CI'].toFixed(2)}</td>
                                                        <td >{data?.tabledata[item]['Upper CI'] && data?.tabledata[item]['Upper CI'].toFixed(2)}</td>
                                                        <td >{data?.tabledata[item]['P Value (simple)'] && data?.tabledata[item]['P Value (simple)'].toFixed(3)}</td>
                                                    </>
                                                </tr>
                                            </>
                                        })
                                    }
                                    <tr>
                                        <td style={{ textAlign: "left" }} colSpan={6}>
                                            Normality Check : {data?.normality_check}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            }
            <br></br>
            <CustomButton type="primary" text="Show Normality Plots" style={{ margin: "10px 0px" }} onClick={() => setNormality(true)} />
            {
                normality ?
                    <NormalityCharts onClose={onClose} data={data} />
                    : ""
            }
            <AnalysisChart data={data} type={2} />


        </>
    )
}

/**
 * Kruskal Wallis test
 */
export function KruskalWallis(props) {
    const [data, setData] = useState(props.data);
    const [editHead, SetEditHead] = useState(false);
    const [head, setHead] = useState("");
    const [count, Setcount] = useState(0);
    const [chartData, Setchartdata] = useState<any>([]);
    const [normality, setNormality] = useState(false);
    const [actionPush, setactionPush] = useState('');
    const [selectedTable, setSelectedTable] = useState('');
    const [tableType, setTableType] = useState("");
    const tableRef = useRef<HTMLTableElement>(null);
    const tableHeadRef = useRef<HTMLTableElement>(null);


    /**
    * function call to Push code to editor
    */
    const addToEditor = (data) => {
        console.log(data)
        getTableJson(data)
    }

    const getTableJson = (data) => {
        const tableHTML = tableRef?.current;

        console.log(tableHTML, selectedTable)

        let response: any = "";
        if (tableType == "multi") {
            response = parseMultiAnalysisTable(tableHTML, tableHeadRef)
        } else {
            response = htmlToJSON(tableHTML, selectedTable)
        }
        console.log(response)
        response.then(tableData => {
            let pushResponse;
            tableData.map(item => {
                console.log(item, data.section.value)
                pushResponse = PushToEditor(item, data.section.value)
            })
            if (pushResponse) {
                showToaster(toasterTypes.SUCCESS, "Pushed code to editor")
            }

        })
        setactionPush("")
    };

    const setCopyAction = (data) => {
        setTableType("multi")
        setSelectedTable(data)
        setactionPush("section")
    }

    const onClose = () => {
        setNormality(false)
    }
    useEffect(() => {
        let countval = 0;
        data?.rowvariablevalues.map(item => {
            countval += data?.tabledata[item]['count'];
        })
        Setcount(countval)

        getChartData(data)
    }, [])

    const onEditHead = (data) => {
        setHead(data)
    }

    const getChartData = async (data) => {
        let chartdata = await createChartMedian(data)
        Setchartdata(chartdata)
    }

    useEffect(() => {
        let tabletitle = `${data.tablename}` + `(N=${count})` + `[${data.tabletype}]`;
        setHead(tabletitle)
    }, [chartData, count])



    return (
        <>
            {data &&
                <div className="tablerol">
                    {
                        actionPush == "section" &&
                        <SectionModal pushAction={addToEditor} onclose={() => setactionPush("")} />
                    }

                    <Row className="m-2">
                        <Col md={9}>
                            {
                                editHead ?
                                    <input style={{ width: "100%" }} className="head" value={head} onChange={(e) => onEditHead(e.target.value)} onBlur={() => SetEditHead(false)} />
                                    :
                                    <div className={"krushwallisdata" + props.keyValue + "-header"} ref={tableHeadRef}><span>Table {props.id} : </span><span onClick={() => SetEditHead(true)}>{head}</span></div>
                            }
                        </Col>
                        <Col md={3}>
                            <CustomButton text="copy to editor" onClick={() => setCopyAction("krushwallisdata" + props.keyValue)}></CustomButton>
                        </Col>
                    </Row>
                    {
                        <div className={"krushwallisdata" + props.keyValue} ref={tableRef}>
                            <table>
                                <thead>
                                    <tr>
                                        <th >Parameter</th>
                                        <th colSpan={data?.colvariablenames.length} scope="colgroup">{data?.colvariablenames[0]}</th>
                                        <th scope="col" >Kruskal Wallis Test(P Value)</th>
                                    </tr>

                                    <tr>
                                        <th></th>
                                        <th scope="col">Median(IQR)</th>
                                        <th></th>

                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        data?.rowvariablevalues.map((item, i) => {
                                            return <>

                                                <tr>

                                                    <td>{item}(N={data?.tabledata[item]['count']})</td>

                                                    <td>{data?.tabledata[item]['median'].toFixed(2)} {data?.tabledata[item]['Quartiles']}</td>

                                                    {
                                                        i == 0 ?
                                                            <>
                                                                <td rowSpan={data?.rowvariablevalues.length}>{data?.tabledata[item]['P Value'].toFixed(3)}</td>
                                                            </>
                                                            : ""
                                                    }

                                                </tr>
                                            </>
                                        })
                                    }
                                    <tr>
                                        <td style={{ textAlign: "left" }} colSpan={6}>
                                            Normality Check : {data?.normality_check}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            }
            <br></br>
            <CustomButton type="primary" text="Show Normality Plots" style={{ margin: "10px 0px" }} onClick={() => setNormality(true)} />
            {
                normality ?
                    <NormalityCharts onClose={onClose} data={data} />
                    : ""
            }
            <AnalysisChart data={data} type={3} />

        </>
    )
}

/**
 * correlation
 */
export function Correlation(props) {
    const [data, setData] = useState(props.data);
    const [editHead, SetEditHead] = useState(false);
    const [head, setHead] = useState("");
    const [count, Setcount] = useState(0);
    const [chartData, Setchartdata] = useState<any>([]);
    const [normality, setNormality] = useState(false);
    const [actionPush, setactionPush] = useState('');
    const [selectedTable, setSelectedTable] = useState('');
    const [tableType, setTableType] = useState("");
    const tableRef = useRef<HTMLTableElement>(null);
    const tableHeadRef = useRef<HTMLTableElement>(null);


    /**
    * function call to Push code to editor
    */
    const addToEditor = (data) => {
        console.log(data)
        getTableJson(data)
    }

    const getTableJson = (data) => {
        const tableHTML = tableRef?.current;

        console.log(tableHTML, selectedTable)

        let response: any = "";
        if (tableType == "multi") {
            response = parseMultiAnalysisTable(tableHTML, tableHeadRef)
        } else {
            response = htmlToJSON(tableHTML, selectedTable)
        }
        console.log(response)
        response.then(tableData => {
            let pushResponse;
            tableData.map(item => {
                console.log(item, data.section.value)
                pushResponse = PushToEditor(item, data.section.value)
            })
            if (pushResponse) {
                showToaster(toasterTypes.SUCCESS, "Pushed code to editor")
            }

        })
        setactionPush("")
    };

    const setCopyAction = (data) => {
        setTableType("multi")
        setSelectedTable(data)
        setactionPush("section")
    }

    const onClose = () => {
        setNormality(false)
    }

    useEffect(() => {
        let tabletitle = `${data.tablename}` + `(N=${count})` + `[${data.tabletype}]`;
        setHead(tabletitle)
    }, [chartData, count])

    useEffect(() => {
        console.log("Props---->", props)
        let countval = 0;
        data?.tabledata.map(item => {
            countval += item['count'];
        })
        Setcount(countval)
        getChartData(data)
    }, [])

    const getChartData = async (data) => {
        let chartdata = await createChartMedian(data)
        Setchartdata(chartdata)
    }

    const onEditHead = (data) => {
        setHead(data)
    }
    return (
        <>
            <div className="tablerol">
                {
                    actionPush == "section" &&
                    <SectionModal pushAction={addToEditor} onclose={() => setactionPush("")} />
                }

                <Row className="m-2">
                    <Col md={9}>
                        {
                            editHead ?
                                <input style={{ width: "100%" }} className="head" value={head} onChange={(e) => onEditHead(e.target.value)} onBlur={() => SetEditHead(false)} />
                                :
                                <div className={"correlationdata" + props.keyValue + "-header"} ref={tableHeadRef}><span>Table {props.id} : </span><span onClick={() => SetEditHead(true)}>{head}</span></div>
                        }
                    </Col>
                    <Col md={3}>
                        <CustomButton text="copy to editor" onClick={() => setCopyAction("correlationdata" + props.keyValue)}></CustomButton>
                    </Col>
                </Row>
                {
                    <div className={"correlationdata" + props.keyValue} ref={tableRef}>
                        <table>
                            <thead>
                                <tr>
                                    <th >Parameter</th>
                                    {data?.colvariablenames.length > 0 ?
                                        <th colSpan={data?.colvariablenames.length} scope="colgroup">{data?.colvariablenames}</th>
                                        : ""}
                                    <th scope="col" >P Value</th>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th scope="col">rValue</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data?.rowvariablenames.map((item, i) => {
                                        return <tr>
                                            <td>{item}</td>
                                            <td>{data?.tabledata[i]['r Value'].toFixed(3)}</td>
                                            <td>{data?.tabledata[i]['P Value'].toFixed(3)}</td>
                                        </tr>
                                    })
                                }

                                <tr>
                                    <td style={{ textAlign: "left" }} colSpan={data?.rowvariablenames.length + 2}>
                                        Normality Check : {data?.normality_check}
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                }
            </div>
            <br></br>
            <CustomButton type="primary" text="Show Normality Plots" style={{ margin: "10px 0px" }} onClick={() => setNormality(true)} />
            {
                normality ?
                    <NormalityCharts onClose={onClose} data={data} />
                    : ""
            }
            <AnalysisChart data={data} type={4} />

        </>
    )
}

/**
 * correlation
 */
export function SpearCorrelation(props) {
    const [data, setData] = useState(props.data);
    const [editHead, SetEditHead] = useState(false);
    const [head, setHead] = useState("");
    const [count, Setcount] = useState(0);
    const [chartData, Setchartdata] = useState<any>([]);
    const [normality, setNormality] = useState(false);
    const [actionPush, setactionPush] = useState('');
    const [selectedTable, setSelectedTable] = useState('');
    const [tableType, setTableType] = useState("");
    const tableRef = useRef<HTMLTableElement>(null);
    const tableHeadRef = useRef<HTMLTableElement>(null);


    /**
    * function call to Push code to editor
    */
    const addToEditor = (data) => {
        console.log(data)
        getTableJson(data)
    }

    const getTableJson = (data) => {
        const tableHTML = tableRef?.current;

        console.log(tableHTML, selectedTable)

        let response: any = "";
        if (tableType == "multi") {
            response = parseMultiAnalysisTable(tableHTML, tableHeadRef)
        } else {
            response = htmlToJSON(tableHTML, selectedTable)
        }
        console.log(response)
        response.then(tableData => {
            let pushResponse;
            tableData.map(item => {
                console.log(item, data.section.value)
                pushResponse = PushToEditor(item, data.section.value)
            })
            if (pushResponse) {
                showToaster(toasterTypes.SUCCESS, "Pushed code to editor")
            }

        })
        setactionPush("")
    };

    const setCopyAction = (data) => {
        setTableType("multi")
        setSelectedTable(data)
        setactionPush("section")
    }

    const onClose = () => {
        setNormality(false)
    }

    useEffect(() => {
        let tabletitle = `${data.tablename}` + `(N=${count})` + `[${data.tabletype}]`;
        setHead(tabletitle)
    }, [chartData, count])

    useEffect(() => {
        let countval = 0;
        data?.tabledata.map(item => {
            countval += item['count'];
        })
        Setcount(countval)
        getChartData(data)
    }, [])

    const getChartData = async (data) => {
        let chartdata = await createChartMedian(data)
        Setchartdata(chartdata)
    }

    const onEditHead = (data) => {
        setHead(data)
    }
    return (
        <>
            <div className="tablerol">
                {
                    actionPush == "section" &&
                    <SectionModal pushAction={addToEditor} onclose={() => setactionPush("")} />
                }

                <Row className="m-2">
                    <Col md={9}>
                        {
                            editHead ?
                                <input style={{ width: "100%" }} className="head" value={head} onChange={(e) => onEditHead(e.target.value)} onBlur={() => SetEditHead(false)} />
                                :
                                <div className={"speardata" + props.keyValue + "-header"} ref={tableHeadRef}><span>Table {props.id} : </span><span onClick={() => SetEditHead(true)}>{head}</span></div>
                        }
                    </Col>
                    <Col md={3}>
                        <CustomButton text="copy to editor" onClick={() => setCopyAction("speardata" + props.keyValue)}></CustomButton>
                    </Col>
                </Row>
                {
                    <div className={"speardata" + props.keyValue} ref={tableRef}>
                        <table>
                            <thead>
                                <tr>
                                    <th >Parameter</th>
                                    {data?.colvariablenames.length > 0 ?
                                        <th colSpan={data?.colvariablenames.length} scope="colgroup">{data?.colvariablenames}</th>
                                        : ""}
                                    <th scope="col" >P Value(spearman)</th>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th scope="col">rsValue(spearman)</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data?.rowvariablenames.map((item, i) => {
                                        return <tr>
                                            <td>{item}</td>
                                            <td>{data?.tabledata[i]['rs Value(spearman)'].toFixed(3)}</td>
                                            <td>{data?.tabledata[i]['P Value(spearman)'].toFixed(3)}</td>
                                        </tr>
                                    })
                                }
                                <tr>
                                    <td style={{ textAlign: "left" }} colSpan={data?.rowvariablenames.length + 2}>
                                        Normality Check : {data?.normality_check}
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                }
            </div>
            <br></br>
            <CustomButton type="primary" text="Show Normality Plots" style={{ margin: "10px 0px" }} onClick={() => setNormality(true)} />
            {
                normality ?
                    <NormalityCharts onClose={onClose} data={data} />
                    : ""
            }
            <AnalysisChart data={data} type={4} />

        </>

    )

}

//paired T test
export function PairedTTest(props) {
    const [data, setData] = useState(props.data);
    const [editHead, SetEditHead] = useState(false);
    const [head, setHead] = useState("");
    const [count, Setcount] = useState(0);
    const [chartData, Setchartdata] = useState<any>([]);
    const [normality, setNormality] = useState(false);
    const [actionPush, setactionPush] = useState('');
    const [selectedTable, setSelectedTable] = useState('');
    const [tableType, setTableType] = useState("");
    const tableRef = useRef<HTMLTableElement>(null);
    const tableHeadRef = useRef<HTMLTableElement>(null);


    /**
    * function call to Push code to editor
    */
    const addToEditor = (data) => {
        console.log(data)
        getTableJson(data)
    }

    const getTableJson = (data) => {
        const tableHTML = tableRef?.current;

        console.log(tableHTML, selectedTable)

        let response: any = "";
        if (tableType == "multi") {
            response = parseMultiAnalysisTable(tableHTML, tableHeadRef)
        } else {
            response = htmlToJSON(tableHTML, selectedTable)
        }
        console.log(response)
        response.then(tableData => {
            let pushResponse;
            tableData.map(item => {
                console.log(item, data.section.value)
                pushResponse = PushToEditor(item, data.section.value)
            })
            if (pushResponse) {
                showToaster(toasterTypes.SUCCESS, "Pushed code to editor")
            }

        })
        setactionPush("")
    };

    const setCopyAction = (data) => {
        setTableType("multi")
        setSelectedTable(data)
        setactionPush("section")
    }

    const onClose = () => {
        setNormality(false)
    }

    useEffect(() => {
        let tabletitle = `${data.tablename}` + `(N=${count})` + `[${data.tabletype}]`;
        setHead(tabletitle)
    }, [chartData, count])

    useEffect(() => {
        let countval = 0;

        for (var val in data?.tabledata) {
            countval = data?.tabledata[val].count;
        }
        Setcount(countval)

        getChartData(data)
    }, [])

    const getChartData = async (data) => {
        let chartdata = await createChartMedian(data)
        Setchartdata(chartdata)
    }

    const onEditHead = (data) => {
        setHead(data)
    }
    return (
        <>
            <div className="tablerol">
                {
                    actionPush == "section" &&
                    <SectionModal pushAction={addToEditor} onclose={() => setactionPush("")} />
                }

                <Row className="m-2">
                    <Col md={9}>
                        {
                            editHead ?
                                <input style={{ width: "100%" }} className="head" value={head} onChange={(e) => onEditHead(e.target.value)} onBlur={() => SetEditHead(false)} />
                                :
                                <div className={"paireddata" + props.keyValue + "-header"} ref={tableHeadRef}><span>Table {props.id} : </span><span onClick={() => SetEditHead(true)}>{head}</span></div>
                        }
                    </Col>
                    <Col md={3}>
                        <CustomButton text="copy to editor" onClick={() => setCopyAction("paireddata" + props.keyValue)}></CustomButton>
                    </Col>
                </Row>
                {
                    <div className={"paireddata" + props.keyValue} ref={tableRef}>
                        <table>
                            <thead>
                            <tr>
                                <th>Parameters</th>
                                <th >Mean ± SD</th>
                                <th >Mean Difference</th>
                                <th colSpan={2} scope="colgroup">95% CI of mean difference</th>
                                <th >P Value</th>
                            </tr>
                            <tr>
                                <th></th>
                                <th></th>
                                <th>Lower</th>
                                <th scope="col">Upper</th>
                                <th></th>

                            </tr>
                            </thead>
                            <tbody>
                                {
                                    Object.keys(data?.tabledata).map((item, i) => {
                                        return <tr>
                                            <td>{item}</td>
                                            <td>{data?.tabledata[item]['mean'].toFixed(2)} ± {data?.tabledata[item]['std'].toFixed(2)}</td>

                                            {
                                                i == 0 ?
                                                    <>
                                                        <td rowSpan={Object.keys(data?.tabledata).length}>{data?.tabledata[item]['Mean Difference'].toFixed(2)}</td>
                                                        <td rowSpan={Object.keys(data?.tabledata).length}>{data?.tabledata[item]['Lower CI'].toFixed(2)}</td>
                                                        <td rowSpan={Object.keys(data?.tabledata).length}>{data?.tabledata[item]['Upper CI'].toFixed(2)}</td>
                                                        <td rowSpan={Object.keys(data?.tabledata).length}>{data?.tabledata[item]['P Value'].toFixed(3)}</td>
                                                    </>
                                                    : <></>
                                            }
                                        </tr>
                                    })
                                }
                                <tr>
                                    <td style={{ textAlign: "left" }} colSpan={data?.rowvariablenames.length + 5}>
                                        Normality Check : {data?.normality_check}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                }
            </div>
            <br></br>
            <CustomButton type="primary" text="Show Normality Plots" style={{ margin: "10px 0px" }} onClick={() => setNormality(true)} />
            {
                normality ?
                    <NormalityCharts onClose={onClose} data={data} />
                    : ""
            }
            <AnalysisChart data={data} type={5} />

        </>

    )

}


//paired T test
export function Wilcoxon(props) {
    const [data, setData] = useState(props.data);
    const [editHead, SetEditHead] = useState(false);
    const [head, setHead] = useState("");
    const [count, Setcount] = useState(0);
    const [chartData, Setchartdata] = useState<any>([]);
    const [normality, setNormality] = useState(false);
    const [actionPush, setactionPush] = useState('');
    const [selectedTable, setSelectedTable] = useState('');
    const [tableType, setTableType] = useState("");
    const tableRef = useRef<HTMLTableElement>(null);
    const tableHeadRef = useRef<HTMLTableElement>(null);


    /**
    * function call to Push code to editor
    */
    const addToEditor = (data) => {
        console.log(data)
        getTableJson(data)
    }

    const getTableJson = (data) => {
        const tableHTML = tableRef?.current;

        console.log(tableHTML, selectedTable)

        let response: any = "";
        if (tableType == "multi") {
            response = parseMultiAnalysisTable(tableHTML, tableHeadRef)
        } else {
            response = htmlToJSON(tableHTML, selectedTable)
        }
        console.log(response)
        response.then(tableData => {
            let pushResponse;
            tableData.map(item => {
                console.log(item, data.section.value)
                pushResponse = PushToEditor(item, data.section.value)
            })
            if (pushResponse) {
                showToaster(toasterTypes.SUCCESS, "Pushed code to editor")
            }

        })
        setactionPush("")
    };

    const setCopyAction = (data) => {
        setTableType("multi")
        setSelectedTable(data)
        setactionPush("section")
    }
    const onClose = () => {
        setNormality(false)
    }

    useEffect(() => {
        let tabletitle = `${data.tablename}` + `(N=${count})` + `[${data.tabletype}]`;
        setHead(tabletitle)
    }, [chartData, count])

    useEffect(() => {
        let countval = 0;

        for (var val in data?.tabledata) {
            countval = data?.tabledata[val].count;
        }
        Setcount(countval)

        getChartData(data)
    }, [])

    const getChartData = async (data) => {
        let chartdata = await createChartMedian(data)
        Setchartdata(chartdata)
    }

    const onEditHead = (data) => {
        setHead(data)
    }
    return (
        <>
            <div className="tablerol">
                {
                    actionPush == "section" &&
                    <SectionModal pushAction={addToEditor} onclose={() => setactionPush("")} />
                }

                <Row className="m-2">
                    <Col md={9}>
                        {
                            editHead ?
                                <input style={{ width: "100%" }} className="head" value={head} onChange={(e) => onEditHead(e.target.value)} onBlur={() => SetEditHead(false)} />
                                :
                                <div className={"wilcoxdata" + props.keyValue + "-header"} ref={tableHeadRef}><span>Table {props.id} : </span><span onClick={() => SetEditHead(true)}>{head}</span></div>
                        }
                    </Col>
                    <Col md={3}>
                        <CustomButton text="copy to editor" onClick={() => setCopyAction("wilcoxdata" + props.keyValue)}></CustomButton>
                    </Col>
                </Row>
                {
                    <div className={"wilcoxdata" + props.keyValue} ref={tableRef}>
                        <table>
                            <thead>
                                <tr>
                                    <th>Parameters</th>
                                    <th>Median (IQR)</th>
                                    <th >P Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    Object.keys(data?.tabledata).map((item, i) => {
                                        return <tr>
                                            <td>{item}</td>
                                            <td>{data?.tabledata[item]['median'].toFixed(2)}{data?.tabledata[item]['Quartiles']}</td>
                                            {
                                                i == 0 ?
                                                    <td rowSpan={Object.keys(data?.tabledata).length}>{data?.tabledata[item]['P Value'].toFixed(3)}</td>
                                                    : <></>
                                            }
                                        </tr>
                                    })
                                }
                                <tr>
                                    <td style={{ textAlign: "left" }} colSpan={data?.rowvariablenames.length + 2}>
                                        Normality Check : {data?.normality_check}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                }
            </div>

            <br></br>
            <CustomButton type="primary" text="Show Normality Plots" style={{ margin: "10px 0px" }} onClick={() => setNormality(true)} />
            {
                normality ?
                    <NormalityCharts onClose={onClose} data={data} />
                    : ""
            }
            <AnalysisChart data={data} type={5} />

        </>

    )

}