import { ScrollMenu } from "react-horizontal-scrolling-menu";
import { Card } from "reactstrap";
import { LeftArrow, RightArrow } from "./Arrow";
import { CaretRightOutlined, EditFilled, EyeFilled } from '@ant-design/icons';
import moment from "moment";

export default function ScrollMenuQuestion(props: { data: string, getAnswerClicked:any }) {

    return (
        <>
            <li>
               <>
                {props.data == "No Data Found" ? <span style={{paddingRight:"30px"}}>Start your Journey by Answering</span> :
                props.data["fieldtype"] != "dateTime" &&  props.data["fieldtype"] != "dateField" ?
                <Card className="progressbar-answers" onClick={() => props.getAnswerClicked(props.data)}>
                    {typeof (props.data["value"]) != "object" ?
                            <div className="card-element"><span className="card_content" >{"Question"}{props.data["index"] ? props.data["index"] + 1 : 1} : {props.data["value"] ? props.data["value"] : ""} </span><EditFilled className="edit_pencil"/></div>
                        :
                        !Array.isArray(props.data["value"]) ?
                         <div className="card-element"><span className="card_content">{"Question"}{props.data["index"] ? props.data["index"] + 1 : 1} : {props.data["value"] ? props.data["value"].value : ""} </span><EditFilled className="edit_pencil"/></div>
                            :
                        <div className="card-element"><span className="card_content" >{"Question"}{props.data["index"] ? props.data["index"] + 1 : 1} : {props.data["value"] ? props.data["value"].map((val, i) => val + ",") : ""} </span><EditFilled className="edit_pencil"/></div>
                    }
                </Card>
                :
                <Card className="progressbar-answers" onClick={() => props.getAnswerClicked(props.data)}>

                    <div className="card-element"> <span className="card_content">{"Question"}{props.data["index"] ? props.data["index"] + 1 : 1} : {props.data["value"] ? moment(props.data["value"]).format('YYYY-MM-DD HH:MM:SS') : ""} </span><EditFilled className="edit_pencil"/></div>
                </Card>
                }
                </>
            </li>
  
        </>
    );

}