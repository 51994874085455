import React from 'react'
import { Spinner } from "reactstrap"

export function CustomButton(props: any) {
  return (
    <div className='custom-button'>
        <div onClick={() => !props.loading&&props.onClick()} className={`${props.className ? props.className : ''} ${props.isDisabled ? 'disabled' : ''} ${props.type === 'primary' ? 'primary-button' : props.type === 'alert-primary' ? 'alert-primary-button' : props.type === 'secondary' ? 'secondary-button' : 'alert-secondary-button'}`}>
            {!props.loading && <div className='button-text'>{props.text}</div>}
            {props.loading &&
              <Spinner animation="border" role="status" variant="primary" style={{color: 'white'}}>
                <span className="visually-hidden d-none">Loading...</span>
              </Spinner>
            }
        </div>
    </div>
  )
}
