import { TrophyFilled, BookFilled, VideoCameraFilled, LineChartOutlined } from '@ant-design/icons';

export default function LearningMetrics() {
  const metrics = [
    { icon: <TrophyFilled />, label: "Number of Awards Received", value: "3" },
    { icon: <BookFilled />, label: "Courses Completed", value: "12" },
    { icon: <VideoCameraFilled />, label: "Videos Viewed", value: "47" },
    { icon: <LineChartOutlined />, label: "Post Test Aggregate Scoring", value: "92%" }
  ];

  return (
    <div className="learning-metrics">
      <h3 className="learning-metrics__header">Learning Metrics</h3>
      <div className="learning-metrics__body">
        {metrics.map((metric, index) => (
          <div key={index} className="learning-metrics__row">
            <div className="learning-metrics__icon">
              {metric.icon}
            </div>
            <div className="learning-metrics__label">
              {metric.label}
            </div>
            <div className="learning-metrics__value">
              {metric.value}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
