
import { LeftOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Sheet from "react-modal-sheet";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { CurrentUser, setLoading } from "../../store/slices/auth";
import { CustomButton } from "../../widgets";
import { createProject, updateProject } from "../createProject/apiController";
import AffiliationFunding from "./AffiliationFunding";
import Keywords from "./Keywords";
import ObjectiveCreation from "./ObjectiveCreation";
import ObjectiveGeneration from "./ObjectiveGenerate";
import TitleCreation from "./TitleCreation";
import TitleGeneration from "./TitleGeneration";

export default function CreateProjectMobile() {
    const [PopUpType, SetPopUpType] = useState(true);
    const [selectedTab, SetSelectedTab] = useState<any>(1);
    const [createData, SetCreateData] = useState<any>({ affiliation: {}, title: {}, objective: [''], keywords: [] });
    const [profile, setprofile] = useState<any | null>(null);
    const dispatch = useDispatch();
    const [titleType, SetTitleType] = useState("create");
    const [objNum,SetObjNum] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();

    const progressData = [{
        index: 1,
        title: 'Affiliation&Funding'
    }, {
        index: 2,
        title: 'Title'
    }, {
        index: 3,
        title: 'Objectives'
    }, {
        index: 4,
        title: 'Keywords'
    }];


    useEffect(() => {
        let userData = localStorage.getItem('coguide_user');
        if (userData) {
            let currentUser: CurrentUser = JSON.parse(userData);
            setprofile(currentUser);
            console.log(currentUser);
        }
    }, [])

    /**
     * inital values in Edit
     */

	useEffect(() => {
		if (location.state) {
			let stateValue: any = location.state;
			//SetCreateData(stateValue);
            console.log(stateValue)
			let temp: any = createData;
			if(stateValue?.affiliation_info) {
				temp.affiliation = {
					"country": {
						"label": "",
						"value": stateValue.affiliation_info.country || ''
					},
					"state": {
						"label": "",
						"value": stateValue.affiliation_info.state
					},
					"city": stateValue.affiliation_info.city,
					"college": {
						"label": stateValue.affiliation_info.institute_name,
						"value": stateValue.affiliation_info.institutecode
					},
					"projectType": {
						"label": "",
						"value": stateValue.affiliation_info.project_type
					},
					"funding": {
						"label": "",
						"value": stateValue?.funding_info?.funded_project ? 'yes' : 'no'
					},
					"fundType": {
						"label": "",
						"value": stateValue?.funding_info?.fund_type
					},
					"quantity": stateValue?.funding_info?.quantum_of_funding,
					"fundingAgency": stateValue?.funding_info?.funding_agency,
					"approvalStatus": {
						"label": "",
						"value": stateValue?.funding_info?.funding_status
					}
				}
			}
			temp.title = {
				title: stateValue.TopicDesc
			};
			temp.objective = stateValue.TopicBigDesc;		
			temp.keywords = stateValue.key_words;
			SetCreateData(temp);
            console.log(temp)
		}
        console.log(createData)

		//setpageType('progress');
	}, [location.state]);

    useEffect(() => {

    }, [titleType,createData])

    /**
     * next button action
     */
    const next = (values, type) => {
        console.log(values, type)
        if (selectedTab != 4) {
            SetSelectedTab(selectedTab + 1)
        }

        console.log(values)

        if (type == "affiliation") {
            createData.affiliation = values;
            SetCreateData(prevData => {
                return { ...prevData, createData }
            })
        } else if (type == "title") {
            createData.title = values
            SetCreateData(prevData => {
                return { ...prevData, createData }
            })
        } else if (type == "objective") {
            createData.objective = values;
            SetCreateData(prevData => {
                return { ...prevData, createData }
            })
        }

        console.log(createData)
    }

    /**
     * previous button click
     */
    const previous = (values, type) => {
        if (selectedTab != 1) {
            SetSelectedTab(selectedTab - 1)
        }

        if (type == "keywords") {
            createData.keywords = values.keyword;
            SetCreateData(prevData => {
                return { ...prevData, createData }
            })
        } else if (type == "objective") {
            createData.objective = values;
            SetCreateData(prevData => {
                return { ...prevData, createData }
            })
        }
        console.log(createData)
    }

    /**
     * on submit create project
     */
    const onSubmit = async (values) => {
        createData.keywords = values.keyword;
        SetCreateData(prevData => {
            return { ...prevData, createData }
        })

        try {
            console.log(createData?.funding)

            dispatch(setLoading(true));
            const finalObj: any = {
                "TopicDesc": createData?.title.title,
                "TopicBigDesc": createData?.objective,
                "SpecCodes": [
                    {
                        "speccode": profile.PGCourse
                    }
                ],
                "UserNumber": profile.UserNumber,
                "affiliation_info": {
                    "country": createData?.affiliation.country.value,
                    "state": createData?.affiliation.state.value,
                    "city": createData?.affiliation.city,
                    "other_institute": false,
                    "institute_name": createData?.affiliation.college.label,
                    "institutecode": createData?.affiliation.college.value,
                    "project_type": createData?.affiliation.projectType.value
                },
                "funding_info": {
                    "funded_project": createData?.affiliation.funding.label === "Yes" ? true : false,
                    "fund_type": createData?.affiliation?.fundType?.value || "",
                    "quantum_of_funding": createData?.affiliation?.quantity || "",
                    "funding_agency": createData?.affiliation?.fundingAgency || "",
                    "funding_status": createData?.affiliation?.approvalStatus?.value || "NA"
                },
                "key_words": createData?.keywords || values
            }
            console.log(finalObj)

              if(location.state) {

                   let stateValue: any = location.state; 
                   console.log(stateValue)

                   stateValue.TopicCode = stateValue.TopicCode || stateValue.TopicID;
                   let response = await updateProject(finalObj, stateValue.TopicCode || stateValue.TopicID);
                   if (response.message == "OK") {
                    console.log("Created")
                    navigate("/success", { state: { data: createData, action: "update" } })
                    dispatch(setLoading(false));
                }
                } else {
                   let response = await createProject(finalObj);
                   if (response.message == "Created") {
                    console.log("Created")
                    navigate("/success", { state: { data: createData, action: "create" } })
                    dispatch(setLoading(false));
                }
                }
           
        } catch (e: any) {
            dispatch(setLoading(false));
        }
    }

    const onCloseGeneration = () => {
        SetTitleType("create")
    }

    /**
     * on select data
     */
    const onSelect = (values) => {
        console.log(values)
        createData.title = { title: values }
        SetCreateData(prevData => {
            return { ...prevData, createData }
        })
        SetTitleType("create")
    }

     /**
     * on select objective data push to array
     */
      const onSelectObj = (values) => {
        console.log(objNum,values)
        if(createData.objective[0] == "" && objNum == 0){
            createData.objective.splice(0,1);
            createData.objective.splice(objNum, 0, values);
        }else{
            createData.objective.splice(objNum, 0, values);
        }
        SetCreateData(prevData => {
            return { ...prevData, createData }
        })
        SetTitleType("create")
    }

    /**
    * Generate title 
    */
    const generateTitle = () => {
        SetTitleType("title")
        //navigate("/titlegenerate", { state: props.data });
    }

     /**
    * Generate objective 
    */
      const generateObject = (i) => {
        SetTitleType("object")
        SetObjNum(i)
        console.log(i)
        //navigate("/titlegenerate", { state: props.data });
    }
    return (
        <>
            {titleType == "create" ?
                <div className="create-project-mobile">
                    <div className="info-bar" onClick={() => navigate("/projectmobile")}>
                        <LeftOutlined className="arrow-back" />
                        <span className="info-create-project poppins-light">{ location.state ? "Update Project" : "Create Project"}</span>
                    </div>
                    <div className="project-tabs">
                        {
                            progressData.map((item, i) => {
                                return <div className={`${selectedTab === i + 1 ? 'selected-tab-col tab-col' : 'tab-col'}`} key={item.index}>
                                    <div className='tab-text'>{item.title}</div>
                                </div>
                            })
                        }
                    </div>
                    <div className="create-project-body">
                        {
                            selectedTab == 1 ?
                                <AffiliationFunding next={next} data={createData && createData} />
                                :
                                selectedTab == 2 ?
                                    <TitleCreation next={next} previous={previous} data={createData} onGenerate={generateTitle} />
                                    :
                                    selectedTab == 3 ?
                                        <ObjectiveCreation next={next} previous={previous} data={createData} onGenerate={generateObject} />
                                        :
                                        selectedTab == 4 ?
                                            <Keywords next={onSubmit} previous={previous} data={createData} />
                                            :
                                            ""
                        }
                    </div>

                </div>
                :
                titleType == "title" ?
                <TitleGeneration onClose={onCloseGeneration} onSelect={onSelect} />
                :
                <ObjectiveGeneration onClose={onCloseGeneration} onSelect={onSelectObj} />
            }
        </>
    )
}