import { Spin } from 'antd';
import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux';
import { authSelector, setLoading } from '../../../store/slices/auth';
import Emitter from './emitter';

export const Loader = (props: any) => {
    return (
      <div className={`loader_container ${props.loaderTextPosition && (props.loaderTextPosition==="right" || props.loaderTextPosition==="bottom") ? props.loaderTextPosition : "bottom"}`}>
        <div className={`loader ${props.size ? props.size : "default"}`}></div>
        {props.isPercentageEnabled ? <div className={`loaderPercentage ${props.size ? props.size : "default"}`} style={{color: props.loaderTextColor ? props.loaderTextColor : "#000000"}}>{props.percentageValue && props.percentageValue ? props.percentageValue : ""}</div> : ""}
        
        <div className={`loaderText ${props.size ? props.size : "default"}`}><span style={{color: props.loaderTextColor ? props.loaderTextColor : "#000000"}}><Spin style={{padding:"0px 5px"}}/>{props.loaderText ? props.loaderText : "Loading..."}</span></div>
      </div>
    )
};


export const FullScreenLoader = () => {
    const [text, settext] = useState('');
    useEffect(()=>{
        Emitter.on('SHOW', (newValue: any) => { 
            setLoading(true);
            settext(newValue || 'Loading...');
        });
        Emitter.on('HIDE', () => {
            setLoading(false);
            settext('');
        });
    },[])
    const { isLoading } = useSelector(authSelector);
    return (
        <div>
            {isLoading &&
                <div className="full-screen-loader-wrapper">
                    
                    <Loader
                        size="medium"
                        loaderText={text}
                        loaderTextColor="#000000"
                        loaderTextPosition="bottom"
                        isPercentageEnabled={true}
                    />
                </div>}
        </div>
            
    )
}

class loaderClass {
    show(text: string) { Emitter.emit('SHOW', text); }
    hide() { Emitter.emit('HIDE', ''); }
}
const loaderActions = new loaderClass();
export default loaderActions;
