import React, { useEffect, useState } from 'react';
import { Col, Row } from "reactstrap";
import { CustomButton, showToaster, toasterTypes } from '../../widgets';
import ModalComponent from '../common/Modal/modal';
import { FieldItem } from "../../widgets/fields";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import { ResearchFormElements } from './FormElements';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../store/slices/auth';
import { addResearchProfile, updateResearchProfile } from './apiController';

export default function Awards(props) {
	const initialValues = React.useMemo(() => ({ url: '', platform: null }), []);
	const [values, fields, handleChange, { validateValues, reset, updateValue, updateError }] = useFieldItem(ResearchFormElements, initialValues, {});

	const [researchsList, setresearchsList] = useState([{ label: 'Research Gate', value: 'researchGate' }, { label: 'Google Scholar / Orcid', value: 'scholar' }]);
	const { selectedIndex, data } = props;
	const dispatch = useDispatch();

	useEffect(() => {
		if (selectedIndex !== null && selectedIndex >= 0 && data && data.length > 0) {
			let researchData = data[selectedIndex];
			updateValue({ url: researchData.profile_link, platform: ResearchFormElements.platform.values.filter(obj => obj.value === researchData.profile_type)[0] });
		}
	}, [data]);

	useEffect(() => {
	}, [researchsList]);

	/**
	 * on value change
	 */
	// function onValueChange(name, value, values, fieldValues = null) {
	// 	if (name == "url") {
	// 		var expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
	// 		let matchUrl = expression.test(value);
	// 		if (!matchUrl) {
	// 			setresearchsList([{ label: 'Research Gate', value: 'researchGate' }, { label: 'Orcid', value: 'scholar' }])
	// 			//showToaster(toasterTypes.ERROR,"Check URL format: Example: https://coguide.in")
	// 			updateError(name, { hasError: true, errorMsg: "Check URL format: Example: https://coguide.in" });
	// 		}
	// 	}
	// 	return [{
	// 		...values,
	// 		[name]: value,
	// 	}]
	// }

	const checkSubmit = async () => {
		if (validateValues(["platform", "url"])) {
			showToaster(toasterTypes.ERROR, "Please enter the proper values in the fields highlighted in red");
		} else {
			try {
				dispatch(setLoading(true));
				let reqObj: any = {
					"usernumber": props.userNumber,
					"profile_type": values.platform.value,
					"profile_link": values.url
				}
				let response: any;
				if (selectedIndex !== null && selectedIndex >= 0) {
					response = await updateResearchProfile(reqObj, data[selectedIndex].id);
				} else {
					response = await addResearchProfile(reqObj);
				}
				showToaster(toasterTypes.SUCCESS, response.message);
				props.onClose('callAPI');
				dispatch(setLoading(false));
			} catch (e: any) {
				dispatch(setLoading(false));
			}
		}
	}

	return (
		<ModalComponent
			width={window.innerWidth > 700 ? "800px" : "350px"}
			isOpen={true}
			onClose={() => props.onClose()}
			header="Research Profile Links"
			centered={true}
			customClass='profile-modal'
			body={
				<div>
					<Row>
						<Col md={4}>
							<FieldItem
								{...ResearchFormElements.platform}
								value={values.platform}
								onChange={(...e) => handleChange(ResearchFormElements.platform.name, ...e)}
								touched={fields.platform && fields.platform.hasError}
								error={fields.platform && fields.platform.errorMsg}
							/>
						</Col>

						<Col md={8}>
							<FieldItem
								{...ResearchFormElements.url}
								value={values.url}
								values={values.url}
								onChange={(...e) => handleChange(ResearchFormElements.url.name, ...e)}
								touched={fields.url && fields.url.hasError}
								error={fields.url && fields.url.errorMsg}
							/>
						</Col>

					</Row>
				</div>
			}
			footer={
				<div>
					<CustomButton type="primary" isDisabled={false} text="Save" onClick={() => checkSubmit()}></CustomButton>
				</div>
			}
		/>
	)
}