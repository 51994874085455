import { useEffect, useState } from "react";
import { Image } from "react-bootstrap"
import { useNavigate } from "react-router-dom";
import { Collapse } from "reactstrap";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import { FieldItem } from "../../widgets/fields";
import { useDispatch } from "react-redux";
import { setLoading } from "../../store/slices/auth";
import { CustomButton, showToaster, toasterTypes } from "../../widgets";
import { getForms, getGroups, getVariable, postGroupVariable } from "./apiController";
import { getFormRecords } from "../dataCollection/apiController";
import { GroupFormElements } from "./FieldElements";
import AddGroupConditionMobile from "./AddGroupConditionMobile";

const AddGroupingMobile = (props) => {
    const dispatch = useDispatch();
    let componentObj = {};
    const [isOpenCondition, setisOpenCondition] = useState(true)
    const [isOpenGroup, setisOpenGroup] = useState(true)
    const [values, fields, handleChange, { validateValues, reset, updateValue, updateError }] = useFieldItem(GroupFormElements, {}, { onValueChange });
    const [variableList, SetVariableList] = useState<any>([]);
    const [selectedgroupType, SetGroupType] = useState("");
    const [projectData, SetProjectData] = useState<any>([]);
    const [groupData, SetGroup] = useState<any>([]);
    const [variableData, SetvariableData] = useState<any>([]);
    const [itemCount, SetItemCount] = useState(0);
    const [formList, SetFormList] = useState<any>([]);
    const [formvariables, setformVariables] = useState<any>([]);
    const [formAddvariables, setformAddVariables] = useState<any>([]);
    const [formvariablesValue, setformVariablesValue] = useState<any>([]);
    const [isSuggestion, SetSuggestion] = useState(false);
    const [pageSize, SetPageSize] = useState(10);
    const [variables, Setvariables] = useState<any>([])
    const [popupType, SetpopupType] = useState("");
    const [formuladata, Setformuladata] = useState<any>({ display_formula: "", formula: "" });

    let ComputeOperators = [
        { value: ">", label: ">" },
        { value: "<", label: "<" },
        { value: "==", label: "==" },
        { value: ">=", label: ">=" },
        { value: "<=", label: "<=" },
        { value: "!=", label: "!=" },
        { value: "contains", label: "contains" },
        { value: "and", label: "and" },
        { value: "or", label: "or" },
        { value: "not", label: "not" },
        { value: "in", label: "in" },
        { value: "+", label: "+" },
        { value: "-", label: "-" },
        { value: "*", label: "*" },
        { value: "/", label: "/" },
        { value: "%", label: "%" },
        { value: "(", label: "(" },
        { value: ")", label: ")" },
        { value: "[", label: "[" },
        { value: "]", label: "]" },
        { value: "sqrt", label: "sqrt" },
        { value: "square", label: "square" },
        { value: "cube", label: "cube" },
        { value: "power", label: "power" }
    ]
    // const { variables } = props;
    let Operators = [{ value: ">", label: ">" },
    { value: "<", label: "<" },
    { value: "==", label: "==" },
    { value: ">=", label: ">=" },
    { value: "<=", label: "<=" },
    { value: "!=", label: "!=" },
    { value: "contains", label: "contains" }];

    let LogicalOpt = [{ value: "Select", label: "Select" },
    { value: "and", label: "and" },
    { value: "or", label: "or" }];



    useEffect(() => {
        console.log(props)
        fetchColumns();
        SetProjectData(props.data)
        fetchGroups(props.data.TopicID)
        fetchFormVariables("");
    }, [])

    useEffect(() => {

    }, [popupType])

    /**
    * on value change update form data
    * @param name 
    * @param value 
    * @param values 
    * @param fieldValues 
    * @returns 
    */
    function onValueChange(name, value, values, fieldValues = null) {
        let resetValue = {};
        console.log(value)
        if (name == "grouptype") {
            fetchFormVariables(value.label);
            SetGroupType(value.label)
            SetVariableList([])
            SetpopupType("")
            values.variable = {}
            values.newcolumnname = [];
            values = [];
        } else if (name == "variable") {
            SetItemCount(0);
            SetVariableList([]);
            SetItemCount(itemCount + 1);
            values.newcolumnname = [];
            if (value.type == "radioButton" || value.type == "checkBox") {
                SetGroupType("Categorical")
                console.log(value)
                getVariableData(value.value, "")
            } else {
                SetGroupType("Numeric")
                SetvariableData([])
            }
            let componentObj = formvariablesValue.filter(item => item.field_name == value.label);
            SetVariableList(componentObj)
            console.log(value)
        }

        return [{
            ...values,
            ...resetValue,
            [name]: value,
        }]
    }


    /**
    * get variable data for selected variable 
    * @param id 
    */
    const getVariableData = async (id, key) => {
        try {
            let response = await getVariable(id);
            SetvariableData(response)
            console.log(response)
        } catch (e: any) {

        }
    }
    /**
     * get variables data
     * @param formData 
     */
    const fetchFormVariables = async (groupdata) => {
        try {
            setformVariables([]);
            setformVariablesValue(variables);
            if (groupdata == "Categorical") {
                variables.filter(itemfield => itemfield.field_type == "radioButton" || itemfield.field_type == "checkBox").map((item, i) => {
                    let Obj = {
                        label: item.label,
                        value: item.id,
                        type: item.field_type
                    }
                    setformVariables(prevState => [...prevState, Obj]);
                })
            } else if (groupdata == "Numerical") {
                variables.filter(itemfield => itemfield.field_type == "number").map((item, i) => {
                    let Obj = {
                        label: item.label,
                        value: item.id,
                        type: item.field_type
                    }
                    setformVariables(prevState => [...prevState, Obj]);
                })
            } else if (groupdata == "Compute") {
                variables.map((item, i) => {
                    let Obj = {
                        label: item.label,
                        value: item.id,
                        type: item.field_type
                    }
                    let ObjAdd = {
                        label: item.label,
                        value: item.label,
                        id: item.id,
                        type: item.field_type
                    }
                    setformVariables(prevState => [...prevState, Obj]);
                    setformAddVariables(prevState => [...prevState, ObjAdd]);
                })
            }

        } catch (e: any) {
            setformVariables([]);
        }
    }

    /**
     * get variable list
     */
    const fetchColumns = async () => {
        try {
            dispatch(setLoading(true));
            let response = await getFormRecords(props?.formData.id, 1, pageSize);
            if (response.data && response.data.length > 0) {
                let tempHeaders: any = [];
                response.data.forEach((obj, index) => {
                    obj.fields.forEach(subObj => {
                        if (index > 0) {
                            let check = tempHeaders.filter(elem => elem.value === subObj.id);
                            if (check.length === 0) {
                                tempHeaders.push({
                                    label: subObj.field_name,
                                    value: subObj.id,
                                    data: subObj,
                                    type: subObj.field_type
                                });
                            }
                        } else {
                            tempHeaders.push({
                                label: subObj.field_name,
                                value: subObj.id,
                                data: subObj,
                                type: subObj.field_type
                            });
                        }
                    });
                });
                console.log(tempHeaders)

                let tempColumns: any = [];
                tempHeaders.forEach(obj => {
                    tempColumns.push({
                        "id": obj.value,
                        "label": obj.label,
                        accessor: elem => (obj.data.field_type === 'fileUpload' && elem[obj.value]) ? <div className='link-text' onClick={() => window.open(elem[obj.value])}>Link</div> : elem[obj.value]?.toString() || '-',
                        "sort": "asc",
                        "field_type": obj.type
                    })
                })

                Setvariables(tempColumns);
            }
            dispatch(setLoading(false));
        } catch (e: any) {
            dispatch(setLoading(false));
        }
    };


    /**
     * get groups
     * @param id 
     */
    const fetchGroups = async (id) => {
        try {
            dispatch(setLoading(true));
            let tempGroups = await getGroups(id);
            SetGroup(tempGroups?.data || []);
            fetchForms(tempGroups?.data[0].id);
            dispatch(setLoading(false));
        } catch (e: any) {
            SetGroup([]);
            dispatch(setLoading(false));
        }
    };

    /**
     * fetch form list
     * @param index 
     * @param id 
     */
    const fetchForms = async (id) => {
        try {
            dispatch(setLoading(true));
            let tempForms = await getForms(id);
            SetFormList([]);
            tempForms.map((item, i) => {
                let Obj = {
                    label: item.form_name,
                    value: item.id
                }
                SetFormList(prevState => [...prevState, Obj]);
            })
            SetSuggestion(true)
            dispatch(setLoading(false));
        } catch (e: any) {
            dispatch(setLoading(false));
        }
    };

    /**
     * @param data get data from addgroups component call back
     */
    const getData = (data) => {
        console.log(data)
        SetVariableList(data)
        setisOpenGroup(false)
    }
    /**
     * recreate the array of items for submissions
     * call to API
     */
    const saveData = async () => {
        console.log(variableList[0])
        let regroupfields: any = [];
        if (values.grouptype.value == "category_grouping") {
            variableList[0].variablelist.map((item, index) => {
                let oldCodesVal: any = [];
                let condition: any = [];
                item.values.map(items => {
                    console.log(items)
                    oldCodesVal.push(items.label)
                })
                console.log(item)
                //newCode: values["newcode" + item.key],
                condition.push({
                    "key": "field",
                    "field_id": item.fieldid,
                    "value_type": "list",
                    "value": oldCodesVal,
                    "display_value": values.variable.label + " Contains " + oldCodesVal
                })

                regroupfields.push({
                    expression_type: "condition",
                    "conditions": condition,
                    value: item["newcode"]
                })
            })
        } else if (values.grouptype.value == "numericgrouping") {
            let condition: any = [];
            let valueField = "";
            console.log(values)
            variableList[0].variablelist.map((item, index) => {
                condition.push({
                    "key": "field",
                    "field_id": item.values.value,
                    "value_type": "expression",
                    "display_value": item.values.label + item.opt.value + item.val,
                    "value": "x" + item.opt.value + item.val
                })

                if (!item.newcode && item.logic?.value != "Select" && item.logic) {
                    condition.push({
                        "key": "condition",
                        "value_type": "logical_operator",
                        "value": item.logic.value
                    })
                }
                valueField = item.newcode

                if (item.newcode) {
                    regroupfields.push({
                        expression_type: "condition",
                        "conditions": condition,
                        value: valueField
                    })

                    condition = []
                }
            })
        } else {
            let condition: any = [];
            let valueField = "";
            variableList[0].variablelist.map((item, index) => {
                if (item.opt?.value == "contains") {
                    let oldCodesVal: any = [];
                    if (typeof (item.val) == "object") {
                        item.val.map(items => {
                            console.log(items)
                            oldCodesVal.push(items.label)
                        })
                    }
                    condition.push({
                        "key": "field",
                        "field_id": item.values?.value,
                        "value_type": "list",
                        "display_value": typeof (item.val) == "object" ? item.values?.label + " contains " + oldCodesVal : item.values.label + " Contains " + item.val,
                        "value": typeof (item.val) == "object" ? oldCodesVal : item.val
                    })
                } else {
                    let valuesData = "";
                    let displayData = "";
                    if (item.values?.type == "radioButton" || item.values?.type == "checkBox") {
                        valuesData = `'x'` + item.opt?.value + `'` + item.val.label + `'`
                        displayData = item.values?.label + item.opt?.value + `'` + item.val.label + `'`
                    } else {
                        valuesData = item.val;
                        if (item.values.type != "number") {
                            valuesData = `'x'` + item.opt?.value + `'` + valuesData + `'`
                            displayData = item.values?.label + item.opt?.value + item.val
                        } else {
                            valuesData = 'x' + item.opt?.value + item.val
                            displayData = item.values?.label + `'` + item.val + `'`
                        }
                    }
                    condition.push({
                        "key": "field",
                        "field_id": item.values?.value,
                        "value_type": "expression",
                        "value": valuesData,
                        "display_value": displayData
                    })
                }

                if (!item.newcode && item.logic?.value != "Select" && item.logic) {
                    condition.push({
                        "key": "condition",
                        "value_type": "logical_operator",
                        "value": item.logic.value
                    })
                }

                valueField = item.newcode;

                if (item.newcode) {
                    regroupfields.push({
                        expression_type: "condition",
                        "conditions": condition,
                        value: valueField
                    })
                    condition = []
                }
            })
            // regroupfields.push({
            //     "conditions": condition,
            //     value: valueField
            // })
        }

        let topicId = props.data.TopicID;

        let finalObj = {
            "topic_id": topicId,
            "field_name": values.newcolumnname || "",
            "field_description": values.newcolumnname,
            "field_type": values.variablefieldtype.value,
            "field_use_type": "Grouping",
            "expression": regroupfields,
            "default_value": variableList[0]?.OriginalVal.isOriginal == "custom" ? variableList[0].customVal : "$$original_value$$"
        }

        console.log(finalObj)

        try {
            dispatch(setLoading(true));
            let submitResponse = await postGroupVariable(props && props.formData?.id, finalObj);
            if (submitResponse) {
                showToaster(toasterTypes.SUCCESS, "Variable created Successfully")
                dispatch(setLoading(false));
                props.onClose();
            }
        } catch (e: any) {
            console.log(e)
            dispatch(setLoading(false));
        }

    }


    /**
     * onclose popup
     */
    const onClose = () => {
        SetpopupType("")
        Setformuladata({})
    }


    useEffect(() => {

    }, [variableData, variableList, isOpenGroup])

    return (
        <>
            {
                popupType == "condition" ?
                    <AddGroupConditionMobile
                        {...props}
                        onClose={onClose}
                        variablelist={variableData}
                        getData={getData}
                        previousData={variableList}
                        groupDetails={values}
                        formvariables={formAddvariables} />
                    :
                    <div className="add-grouping-mobile">
                        <div className="header-container">
                            <div className=' pl-3 pt-4 closeicon'>
                                <i className='icon icon-back_arrow' onClick={() => props.onBack()} />
                            </div>
                            <div className='pl-1 pt-4 form-name'>
                                <Image className='profile-image' alt='Profile' src={`${process.env.PUBLIC_URL}/images/logo.png`} />
                                Configure Group
                            </div>

                        </div>
                        <div className="grouping-details" onClick={() => setisOpenGroup(!isOpenGroup)}>
                            <div className="group pt-3">Grouping Details</div>
                            <div className="group-arrow pt-2">
                                {isOpenGroup ?
                                    <i className="icon icon-arrow_up" onClick={() => setisOpenGroup(false)}></i>
                                    :
                                    <i className="icon icon-arrow_down" onClick={() => setisOpenGroup(true)}></i>
                                }
                            </div>
                        </div>
                        <Collapse isOpen={isOpenGroup} className="group-form-items">
                            <div className="group-form">
                                <FieldItem
                                    {...GroupFormElements.grouptype}
                                    value={values.grouptype}
                                    values={GroupFormElements.grouptype.values}
                                    onChange={(...e) => handleChange(GroupFormElements.grouptype.name, ...e)}
                                    touched={fields.grouptype && fields.grouptype.hasError}
                                    error={fields.grouptype && fields.grouptype.errorMsg}
                                />
                                {values && values.grouptype?.value != "Advanced_compute" ?
                                    <>
                                        <FieldItem
                                            {...GroupFormElements.variable}
                                            value={values.variable}
                                            values={formvariables}
                                            isMandatory={selectedgroupType == "Compute" ? false : true}
                                            onChange={(...e) => handleChange(GroupFormElements.variable.name, ...e)}
                                            touched={fields.variable && fields.variable.hasError}
                                            error={fields.variable && fields.variable.errorMsg}
                                        />
                                    </>
                                    : ""}

                                <FieldItem
                                    {...GroupFormElements.newcolumnname}
                                    value={values.newcolumnname}
                                    onChange={(...e) => handleChange(GroupFormElements.newcolumnname.name, ...e)}
                                    touched={fields.newcolumnname && fields.newcolumnname.hasError}
                                    error={fields.newcolumnname && fields.newcolumnname.errorMsg}
                                />

                                <FieldItem
                                    {...GroupFormElements.variablefieldtype}
                                    value={values.variablefieldtype}
                                    onChange={(...e) => handleChange(GroupFormElements.variablefieldtype.name, ...e)}
                                    touched={fields.variablefieldtype && fields.variablefieldtype.hasError}
                                    error={fields.variablefieldtype && fields.variablefieldtype.errorMsg}
                                />
                            </div>
                        </Collapse>
                        <div className="grouping-details" onClick={() => setisOpenCondition(!isOpenCondition)}>
                            <div className="group pt-3">Conditions</div>
                            <div className="group-arrow pt-2">
                                {isOpenCondition ?
                                    <i className="icon icon-arrow_up" onClick={() => setisOpenCondition(false)}></i>
                                    :
                                    <i className="icon icon-arrow_down" onClick={() => setisOpenCondition(true)}></i>
                                }
                            </div>
                        </div>
                        <Collapse isOpen={isOpenCondition} className="condition-collapse">
                            <div className={variableList && variableList.length > 0 ? "conditions" : "condtionempty"}>
                                {
                                    variableList && variableList.length > 0 &&
                                    <div className="add-details">
                                        <div className="group pt-3"></div>
                                        <div className="group-add pt-3 pr-2" onClick={() => { SetpopupType("condition"); }}>
                                            <i className="icon icon-add" ></i>
                                            Add/Update
                                        </div>
                                    </div>
                                }

                                {
                                    variableList && variableList.length > 0 ?
                                        <><div className="variable-list mt-1 pt-2">
                                            {
                                                variableList[0].variablelist.map((items, index) => {
                                                    return <>
                                                        <div className="variable-item" key={index}>
                                                            <h6>Condition{index + 1}</h6>
                                                            <div className="values-container">
                                                                <span className="label">Values</span>
                                                                <div className="values">
                                                                    {
                                                                        values.grouptype && values.grouptype.label == "Categorical" &&
                                                                        items.values.map(fieldval => {
                                                                            return <span className="label-value pl-2 pr-2"> {fieldval.label}</span>
                                                                        })
                                                                    }
                                                                    {
                                                                        values.grouptype && values.grouptype.label == "Numerical" &&
                                                                        <><span className="label-value pl-2 pr-2"> {items.values?.label + items.opt?.value + items.val}</span>
                                                                            {items.logic && items.logic.value != "Select" ? <span className="label-value pl-2 pr-2"> {" " + items.logic.value}</span> : ""}
                                                                        </>
                                                                    }
                                                                    {
                                                                        values.grouptype && values.grouptype.label == "Compute" &&
                                                                        <><span className="label-value pl-2 pr-2"> {items.values?.label + items.opt?.value + (typeof items.val == "string" ? items.val : items.val.value)}</span>
                                                                            {items.logic && items.logic.value != "Select" ? <span className="label-value pl-2 pr-2"> {" " + items.logic.value}</span> : ""}
                                                                        </>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="newcode-container">
                                                                {
                                                                    items.newcode &&
                                                                    <><span className="label">New code/value</span>
                                                                        <div className="values">
                                                                            <span> {items.newcode}</span>
                                                                        </div></>
                                                                }

                                                            </div>
                                                        </div>
                                                    </>
                                                })
                                            }
                                            {
                                                <div className="variable-item">
                                                    <h6>Default Condition</h6>
                                                    <div className="newcode-container">
                                                        <span className="label">Default value</span>
                                                        <div className="values">
                                                            <span> {variableList[0]?.OriginalVal.isOriginal == "custom" ? variableList[0].OriginalVal.customVal : "Original Value"}</span>
                                                        </div>
                                                    </div>


                                                </div>
                                            }
                                        </div>
                                        </>
                                        :
                                        <div className="no-items">
                                            <p>Start adding conditions</p>
                                            <div className="condition-buttons">
                                            {
                                                values.grouptype && values.grouptype.label == "Compute" &&
                                                <CustomButton className="create-btn" type={"alert-primary"} text="Add Formula" onClick={() => { SetpopupType("condition"); }}></CustomButton>
                                            }
                                            <CustomButton 
                                            className="create-btn" 
                                            type={"alert-primary"} 
                                            text="Add Condition" 
                                            onClick={() => { SetpopupType("condition"); }}
                                            isDisabled={values.grouptype && values.variable || values.grouptype && values.grouptype.label == "Compute" ? false : true}
                                            ></CustomButton>
                                            </div>
                                        </div>
                                }
                                {
                                    variableList && variableList.length > 0 &&
                                    <div className="submit-btn">
                                        <CustomButton className="create-btn" type={"alert-primary"} text="Submit" onClick={() => { saveData() }}></CustomButton>
                                    </div>
                                }

                            </div>
                        </Collapse>

                    </div>
            }
        </>
    )
}

export default AddGroupingMobile;