import React from "react";
import Select from 'react-select';
import { Label } from "reactstrap";
import Validation from "../../utils/Validation";
import FIELD_TYPES from "./FieldTypes";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FileUpload from "../fileupload";
import { isFunction } from "lodash";
import TagsInput from 'react-tagsinput';
import Switch from "react-switch";
import TimePicker from "react-time-picker";
import 'react-time-picker/dist/TimePicker.css';
import DateTimePicker from "react-datetime-picker";
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import FileUploadAll from "../fileupload/fileuplaodall";
import ResearchWorkUpload from "../fileupload/researchworkupload";
import { AutoComplete, DatePicker } from "antd";
import { Tooltip as ReactTooltip } from "react-tooltip";

// TODO disable, readonly prop integrations
interface Props {
    id?: string;
    label?: React.ReactNode;
    labelIcon?: React.ReactNode;
    placeholder?: string;
    name?: string;
    picker?: string;
    onChange?: (event: React.FormEvent<HTMLInputElement>) => void | any;
    onClear?: (event: React.FormEvent<HTMLInputElement>) => void | any;
    className?: string;
    type?: number;
    inputType?: string;
    isMandatory?: boolean;
    isDisabled?: boolean;
    value?: any;
    maxLength?: string | number;
    isNumber?: boolean;
    touched?: boolean;
    error?: boolean;
    values?: Array<Object> | object;
    isListed?: boolean,
    isTerms?: boolean,
    perRow?: string,
    dateFormat?: string;
    todayButton?: boolean;
    minDate?: Date;
    maxDate?: Date;
    excludeDates?: Array<Date>;
    acceptFiles?: String;
    icon?: String;
    iconClick?: (event) => void | any;
    showMonthYearPicker?: boolean;
    timeInterval?: number;
    showTimeSelect?: boolean;
    showTimeSelectOnly?: boolean;
    showMonthPicker?: boolean;
    showYearPicker?: boolean;
    addButton?: string;
    onAdd?: (event) => void | any;
    menuPlacement?: string;
    onBlur?: (event) => void | any;
    onKeyUp?: (event) => void | any;
    onFocus?: (event) => void | any;
    isRemove?: boolean;
    onRemove?: (event) => void | any;
}

const FieldItem: React.FunctionComponent<Props> = (props: any) => {
    const {
        id,
        label,
        labelIcon,
        placeholder,
        name,
        picker,
        onChange,
        onClear,
        className,
        type,
        inputType,
        isMandatory,
        isDisabled,
        value,
        defaultvalues,
        maxLength,
        isNumber,
        touched,
        error,
        values,
        isListed,
        isTerms,
        perRow,
        dateFormat,
        timeFormat,
        todayButton,
        minDate,
        maxDate,
        excludeDates,
        icon,
        iconClick,
        showMonthYearPicker,
        timeInterval,
        showTimeSelect,
        showTimeSelectOnly,
        showMonthPicker,
        showYearPicker,
        addButton,
        onAdd,
        menuPlacement,
        onFocus,
        isRemove,
        onKeyUp
    } = props;

    const customOption = (props) => {
        const { data, innerRef, innerProps } = props;

        return (
            <div
                ref={innerRef}
                {...innerProps}
                data-tooltip-id={"my-tooltip"}
                data-tooltip-content="sadfdf"
                style={{ padding: "8px", cursor: "pointer" }}
            >
                {data.label}
                <ReactTooltip id={"my-tooltip"} place="right" />
            </div>
        );
    };


    const options = [
        { value: '' },
    ];


    const onBlur = (e: any) => {
        if (onChange)

            onChange(e, { isTouched: true });

        if (isFunction(props.onBlur())) {
            props.onBlur();
        }

    }

    const onReset = (e) => {
        return "";
    }

    const onKeyUpEvent = (e: any) => {
        if (onKeyUp && e.keyCode === 13)
            onKeyUp();
    }

    const numberValid = (event: any) => {
        const value = event?.target?.value || event;
        let pattern = new RegExp(/^[0-9 ]+$/i);
        if (!pattern.test(value) && value !== "") {
            return false;
        } else {
            onChange(event);
        }
        console.log(value);
    };

    const getInputContainer = () => {
        return (
            <div className="form-group mb-3">
                {label ? (
                    <div className="form-label">
                        {label.toString()}
                        {labelIcon && <i className={`icon ${labelIcon}`} />}
                        {isMandatory && (
                            <label className="specialcharacter-required">*</label>
                        )}
                    </div>
                ) : ""}
                <div>{getInput()}</div>
                <div className="text-help">{touched && error ? error : ""}</div>
            </div>
        );
    };

    const checkValid = (value) => {
        return value
    }

    const renderInput = () => {
        return (
            <div className="icon-position">
                <input
                    value={value}
                    name={name}
                    placeholder={
                        placeholder ? Validation.capitalizeFirstLetter(placeholder) : ""
                    }
                    type={isNumber ? "number" : inputType}
                    className={`text-input ${className} ${(touched && error) ? 'error-field' : ''}`}
                    onChange={(e) => onChange(e.target.value)}
                    maxLength={maxLength ? maxLength : ""}
                    disabled={isDisabled}
                    style={isDisabled ? { backgroundColor: "#ccc" } : {}}
                    onBlur={isNumber ? (e) => onBlur(e) : (e) => onBlur("j")}
                />
                {icon && <i className={`icon icon-${icon}`} onClick={() => isFunction(iconClick) && iconClick()} />}
            </div>
        )
    }


    /**
     * render input auto complete
     */
    const renderInputAutoComplete = () => {
        return (
            <div >
                <AutoComplete
                    style={{ width: "100%", padding: "5px 0px" }}
                    options={values}
                    placeholder="Field Type"
                    defaultValue={value}
                    value={value}
                    filterOption={true}
                    onChange={(e) => onChange(e)}
                />
            </div>
        )
    }

    /**
     * render input number
     */
    const renderInputNumber = () => {
        return (
            <div className="icon-position">
                <input
                    value={value}
                    name={name}
                    placeholder={
                        placeholder ? Validation.capitalizeFirstLetter(placeholder) : ""
                    }
                    type="number"
                    className={`text-input ${className} ${(touched && error) ? 'error-field' : ''}`}
                    onChange={(e) => onChange(e.target.value)}
                    maxLength={maxLength ? maxLength : ""}
                    disabled={isDisabled}
                    onBlur={(e) => onBlur(e)}
                />
                {icon && <i className={`icon icon-${icon}`} onClick={() => isFunction(iconClick) && iconClick()} />}
            </div>
        )
    }

    const renderTextArea = () => {
        return (
            <textarea
                value={value}
                name={name}
                placeholder={
                    placeholder ? Validation.capitalizeFirstLetter(placeholder) : ""
                }
                className={`text-area ${className} ${(touched && error) ? 'error-field' : ''}`}
                onChange={(e) => onChange(e.target.value)}
                disabled={isDisabled}
                onBlur={(e) => onBlur(e.target.value)}
                onFocus={() => isFunction(onFocus()) && onFocus()}
            />
        )
    }

    const renderDropdown = () => {
        return (
            <div className="custom-dropdown">
                <Select
                    className={`basic-single ${className} ${(touched && error) ? 'error-field' : ''}`}
                    classNamePrefix="select"
                    placeholder={
                        placeholder ? Validation.capitalizeFirstLetter(placeholder) : "Select"
                    }
                    isDisabled={isDisabled}
                    isSearchable={true}
                    defaultValue={value}
                    value={value}
                    isClearable={false}
                    options={values}
                    menuPlacement={menuPlacement}
                    menuPosition="fixed"
                    onChange={(e) => onChange(e)}
                    onBlur={(e) => onBlur(e.target.value)}
                />

            </div>
        );
    }

    const renderDropdownTooltip = () => {
        return (
            <div className="custom-dropdown">
                <Select
                    className={`basic-singletip ${className} ${(touched && error) ? 'error-field' : ''}`}
                    classNamePrefix="select"
                    placeholder={
                        placeholder ? Validation.capitalizeFirstLetter(placeholder) : "Select"
                    }
                    isDisabled={isDisabled}
                    isSearchable={true}
                    value={value}
                    isClearable={false}
                    options={values}
                    menuPlacement={menuPlacement}
                    menuPosition="fixed"
                    onChange={(e) => onChange(e)}
                    onBlur={(e) => onBlur(e.target.value)}
                    components={{
                        SingleValue: ({ data, ...props }) => (
                          <span
                            {...props.innerProps}
                            data-tooltip-id={id}
                            data-tooltip-content={value.label}
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: "90%", // Restrict text to fit within container
                              display: "block",
                            }}
                          >
                            {data.label}
                          </span>
                        ),
                      }}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          minHeight: "50px",
                          height: "50px",
                          display: "flex",
                          alignItems: "center", // Center content vertically
                          padding: "0 8px",
                        }),
                        valueContainer: (provided) => ({
                          ...provided,
                          padding: "0px", // Remove extra padding around selected value
                          height: "50px",
                          display: "flex",
                          alignItems: "center", // Ensure the text is vertically centered
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "100%",
                          display: "flex",
                          alignItems: "center", // Center text inside the box
                        }),
                        option: (provided) => ({
                          ...provided,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "100%", // Keep text inside the option box
                          height: "50px",
                          display: "flex",
                          alignItems: "center", // Center text in each option
                        }),
                        indicatorsContainer: (provided) => ({
                          ...provided,
                          height: "50px",
                          display: "flex",
                          alignItems: "center",
                        }),
                      }}
                    
                />


                <ReactTooltip id={id} place="bottom" className="tooltip-custom" />

            </div>
        );
    }


    const renderMultiSelectDropdown = () => {
        return (
            <div className="custom-dropdown d-flex m-0">
                <Select
                    className={`w-100 basic-multi ${className} ${(touched && error) ? 'error-field' : ''}`}
                    classNamePrefix="select"
                    defaultValue={value}
                    value={value}
                    isMulti={true}
                    isDisabled={isDisabled}
                    isClearable={false}
                    isSearchable={true}
                    options={values}
                    components={addButton && { DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                    onChange={(e) => onChange(e)}
                />
                {addButton &&
                    <div className="button-poistion" onClick={() => onAdd()}>
                        <div className="icon"><i className="icon icon-add_circle"></i></div>
                        <div className="button-text">{addButton}</div>
                    </div>
                }
            </div>
        );
    }

    const renderRadioButton = () => {
        return (
            <div className={`custom-radio ${isListed ? 'row' : ''}`}>
                {values.map((obj, index) => (
                    <div key={index} className={`align-center mb-2 ${isDisabled ? 'pe-none' : ''} ${isListed ? 'pl-3 pr-3' : ''}`}>
                        <img src={`${process.env.PUBLIC_URL}/images/${value === obj.value ? "radio_selected" : "radio_idle"}.svg`} alt="radio" onClick={(e) => onChange(obj.value)} />
                        <Label check>{obj.label}</Label>
                    </div>
                ))}
            </div>
        )
    }

    const renderPermissionRadioButton = () => {
        return (
            <div className={`custom-radio-per ${isListed ? 'row' : ''}`}>
                {values.map((obj, index) => (
                    <div key={index} className={`align-center mb-2 ${isDisabled ? 'pe-none' : ''} ${isListed ? 'pl-3 pr-3' : ''}`}>
                        <img src={`${process.env.PUBLIC_URL}/images/${value === obj.value ? "radio_selected" : "radio_idle"}.svg`} alt="radio" onClick={(e) => onChange(obj.value)} />
                        <Label check style={{ padding: "0px 10px" }}>{obj.label}</Label>
                    </div>
                ))}
            </div>
        )
    }

    const renderCheckbox = () => {
        const submitData = (e) => {
            if (value?.length > 0) {
                console.log(value)
                let index = value.indexOf(e);
                if (index >= 0) {
                    value.splice(index, 1)
                } else {
                    value.push(e);
                }
                onChange(value);
            } else {
                onChange([e]);
            }
        }
        return (
            <div className={isTerms ? `custom-terms` : `custom-checkbox ${isListed ? 'row' : ''}`}>
                {values.map((obj, index) => {
                    const selectedImage = ((value && value.includes(obj.value)) ? 'checkbox_selected' : 'checkbox_idle')
                    return (
                        <div key={index} className={`align-center mb-2 ${isListed ? 'pl-3 pr-3 ' + perRow : ''}`}>
                            <img src={`${process.env.PUBLIC_URL}/images/${selectedImage}.svg`} alt="checkbox" onClick={() => submitData(obj.value)} />
                            <Label check>{obj.label}</Label>
                        </div>
                    )
                })}
            </div>
        )
    }


    const renderSearch = () => {
        return (
            <div className="search-field">
                <input
                    value={value}
                    name={name}
                    placeholder={
                        placeholder ? Validation.capitalizeFirstLetter(placeholder) : ""
                    }
                    type="text"
                    className={`text-input ${className} ${(touched && error) ? 'error-field' : ''} ${value ? 'has-content' : ""}`}
                    onChange={(e) => (isNumber ? numberValid(e.target.value) : onChange(e.target.value))}
                    maxLength={maxLength ? maxLength : ""}
                    disabled={isDisabled}
                    onBlur={(e) => onBlur(e.target.value)}
                    onKeyUp={(e) => onKeyUpEvent(e)}
                    onReset={(e) => onReset(e)}
                />
                <div className="search-icon"><i className="icon icon-search"></i></div>
                <div className="close-icon" onClick={(e) => onChange("")}><i className="icon icon-close"></i></div>

            </div>
        )
    }

    const renderDatepicker = () => {
        return (
            <div className="custom-datepicker">
                <ReactDatePicker
                    selected={value == "Invalid Date" ? null : value}
                    name={name}
                    className={`text-input ${className} ${(touched && error) ? 'error-field' : ''}`}
                    onChange={(e: any) => onChange(e)}
                    placeholderText={placeholder || "DD - MMM - YYYY"}
                    disabled={isDisabled}
                    dateFormat={dateFormat}
                    todayButton={todayButton ? 'Today' : false}
                    minDate={minDate}
                    maxDate={maxDate}
                    excludeDates={excludeDates}
                    disabledKeyboardNavigation
                    formatWeekDay={(nameOfDay: any) => nameOfDay.substr(0, 3)}
                    showYearDropdown
                    showMonthYearPicker={showMonthYearPicker}
                    timeInterval={timeInterval}
                    showTimeSelect={showTimeSelect}
                    showTimeSelectOnly={showTimeSelectOnly}
                    showMonthPicker={showMonthPicker}
                    showYearPicker={showYearPicker}
                />
            </div>
        )
    }

    const renderDatepickernew = () => {
        return (
            <div className="custom-datepicker">
                <DatePicker
                    value={value}
                    name={name}
                    className={`text-input ${className} ${(touched && error) ? 'error-field' : ''}`}
                    onChange={(e: any) => onChange(e)}
                    placeholder={placeholder || "DD - MM - YYYY"}
                    disabled={isDisabled}
                    format={picker ? 'MM/YYYY' : 'DD/MM/YYYY'}
                    //picker={picker ? picker : "week"}                         
                />
            </div>
        )
    }

    const renderTimepicker = () => {
        return (
            <div className="custom-datepicker">
                <TimePicker
                    amPmAriaLabel="AM"
                    format="h:mm:s a"
                    maxDetail="second"
                    value={value}
                    onChange={(e: any) => onChange(e)}
                />
            </div>
        )
    }

    const renderDateTimepicker = () => {
        return (
            <div className="custom-datepicker">
                <DateTimePicker
                    format="y-MM-dd h:mm a"
                    disableClock={true}
                    value={value}
                    onChange={(e: any) => onChange(e)}
                    disabled={isDisabled}
                />

            </div>
        )
    }

    function defaultRenderTag(multiProps) {
        let { tag, key, disabled, classNameRemove, onRemove, getTagDisplayValue, ...other } = multiProps;
        return (
            <span key={key} {...other}>
                {getTagDisplayValue(tag)}
                <a className={classNameRemove} onClick={(e) => props.onRemove(key)} />
            </span>
        )
    }

    const renderMultiText = () => {
        return (
            isRemove ?
                <TagsInput
                    value={value}
                    name={name}
                    className={`multi-text ${className} ${(touched && error) ? 'error-field' : ''}`}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                    addKeys={[13]}
                    onlyUnique={true}
                    inputFieldPosition="bottom"
                    addOnBlur={true}
                    inputProps={{ placeholder: placeholder ? Validation.capitalizeFirstLetter(placeholder) : "" }}
                    renderTag={(props) => defaultRenderTag(props)}
                /> :
                <TagsInput
                    value={value}
                    name={name}
                    className={`multi-text ${className} ${(touched && error) ? 'error-field' : ''}`}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                    addKeys={[13]}
                    onlyUnique={true}
                    inputFieldPosition="bottom"
                    addOnBlur={true}
                    inputProps={{ placeholder: placeholder ? Validation.capitalizeFirstLetter(placeholder) : "" }}
                />
        )
    }

    const renderBoolean = () => {
        return (
            <Switch
                checked={value}
                onChange={(e) => onChange(e)}
                disabled={isDisabled}
                onColor="#86d3ff"
                offHandleColor="#ccc"
                onHandleColor="#2693e6"
                handleDiameter={18}
                uncheckedIcon={false}
                checkedIcon={false}
                height={15}
                width={40}
                className="react-switch"
                id="material-switch"
            />
        )
    }

    const getInput = () => {
        switch (type) {
            case FIELD_TYPES.TEXT:
                return (
                    renderInput()
                );
            case FIELD_TYPES.NUMBER:
                return (
                    renderInputNumber()
                );
            case FIELD_TYPES.TEXT_AREA:
                return (
                    renderTextArea()
                );
            case FIELD_TYPES.DROP_DOWN:
                return (
                    renderDropdown()
                );
            case FIELD_TYPES.DROPDOWN_TOOLTIP:
                return (
                    renderDropdownTooltip()
                );
            case FIELD_TYPES.MULTI_SELECT:
                return (
                    renderMultiSelectDropdown()
                );
            case FIELD_TYPES.RADIO_BUTTON:
                return (
                    renderRadioButton()
                )
            case FIELD_TYPES.RADIO_BUTTON_PER:
                return (
                    renderPermissionRadioButton()
                )
            case FIELD_TYPES.CHECK_BOX:
                return (
                    renderCheckbox()
                )
            case FIELD_TYPES.SEARCH:
                return (
                    renderSearch()
                )
            case FIELD_TYPES.DATE_PICKER:
                return (
                    renderDatepicker()
                );
            case FIELD_TYPES.FILE_UPLOAD:
                return (
                    <FileUpload {...props} isDisabled={isDisabled} onUpload={(data) => onChange(data)} />
                );
            case FIELD_TYPES.FILE_UPLOAD_ALL:
                return (
                    <FileUploadAll {...props} isDisabled={isDisabled} onUpload={(data) => onChange(data)} />
                );
            case FIELD_TYPES.RESEARCHWORK_UPLOAD:
                return (
                    <ResearchWorkUpload {...props} isDisabled={isDisabled} onUpload={(data) => onChange(data)} />
                );
            case FIELD_TYPES.MULTI_TEXT:
                return (
                    renderMultiText()
                )
            case FIELD_TYPES.BOOLEAN:
                return (
                    renderBoolean()
                )
            case FIELD_TYPES.TIME_PICKER:
                return (
                    renderTimepicker()
                )
            case FIELD_TYPES.DATE_TIME_PICKER:
                return (
                    renderDateTimepicker()
                )
            case FIELD_TYPES.TEXT_AUTOCOMPLETE:
                return (
                    renderInputAutoComplete()
                )
            case FIELD_TYPES.DATEPICKER_NEW:
                return (
                    renderDatepickernew()
                )
            default:
                return (
                    renderInput()
                );
        }
    };

    const getFinalData = () => {
        // use this function to update parent div style
        return <div className="field-items">{getInputContainer()}</div>;
    };

    return getFinalData();
};

FieldItem.defaultProps = {
    label: "",
    labelIcon: "",
    placeholder: "",
    name: "",
    onChange: (event) => { },
    onClear: (event) => { },
    className: "",
    type: FIELD_TYPES.TEXT,
    inputType: 'text',
    values: [],
    value: '',
    isMandatory: false,
    isDisabled: false,
    touched: false,
    error: false,
    isListed: false,
    perRow: '',
    dateFormat: 'dd/MM/yyyy',
    todayButton: false,
    excludeDates: [],
    acceptFiles: 'pdf|png|csv|jpeg|jpg|svg',
    iconClick: (event) => { },
    showMonthYearPicker: false,
    timeInterval: 5,
    showTimeSelect: false,
    showTimeSelectOnly: false,
    showMonthPicker: false,
    showYearPicker: false,
    addButton: '',
    onAdd: (event) => { },
    menuPlacement: 'bottom',
    onBlur: () => { },
    onKeyUp: () => { },
    onFocus: () => { },
    isRemove: false,
    onRemove: () => { }
};

export default FieldItem;