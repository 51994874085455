import { useEffect, useState } from "react";
import { CustomButton, showToaster, toasterTypes } from "../../../widgets"
import { FIELD_TYPES, FieldItem } from "../../../widgets/fields"
import useFieldItem from "../../../widgets/fields/UseFieldItem";
import ModalComponent from "../../common/Modal/modal";
import { demo } from "../demo";
import { getDocument, getDocumentList } from "../../documents/apiController";
import { CreateSectionElements } from "./DocumentElements";
import PushToEditor from "./PushToEditor";

const SectionModal = ({ pushAction, onclose }) => {
    const [values, fields, handleChange, { validateValues, updateValue }] = useFieldItem(CreateSectionElements, {}, { onValueChange });
    const [documentList, setDocumentList] = useState([]);
    const [sections, setSections] = useState([]);
    const [action, setAction] = useState("");
    const [newSection, setNewSection] = useState(false);
    console.log(demo)

    let sectionItems = [];


    /**
* handle on change values of form elements
* @param name 
* @param value 
* @param values 
* @param fieldValues 
* @returns 
*/
    function onValueChange(name, value, values, fieldValues = null) {
        const resetValue = {}
        console.log(value)
        if (name == "docselected") {
            getDocumentData(value.value)
            localStorage.setItem("documentSelected", value.value)
        } else if (name == "section") {
            if (value.value == "add") {
                setNewSection(true)
            }
        }
        return [{
            ...values,
            ...resetValue,
            [name]: value,
        }]
    }

    /**
     * get documetn data from API
     * check if data already exist in localstorage
     * if yes dont update new from API else copy
     * filterHeadings -  Filter and show the sections list if New show input text
     * @param {*} id 
     */
    const getDocumentData = async (id) => {
        let documentData = await getDocument(id);
        console.log(documentData.data.revision.content)
        let documentName = localStorage.getItem("documentSelected")
        let getDocumentData = JSON.parse(localStorage.getItem(documentName))
        if (!getDocumentData) {
            localStorage.setItem(documentName, documentData && JSON.stringify(documentData.data.revision.content))
        }

        console.log(getDocumentData)
        let HeaderData = getDocumentData ? getDocumentData : documentData.data.revision.content;

        let filterHeadings = HeaderData && HeaderData.content.filter((item, i) => item.type == "heading2")
        filterHeadings && filterHeadings.map(item => {
            sectionItems.push({
                label: item.content[0].text,
                value: item.content[0].text
            })
        })
        sectionItems.push({
            label: "Add New Section",
            value: "add"
        })

        setSections(sectionItems)
        console.log(sectionItems)
    }

    useEffect(() => {
        getDocuments();
    }, [])

    /**
      * get documents list
      */
    const getDocuments = async () => {
        let parsedJSON = JSON.parse(localStorage.getItem("projectData"));
        console.log(parsedJSON)
        let documentResponse = await getDocumentList(parsedJSON.id);
        console.log(documentResponse)
        setDocumentList(documentResponse.map(({
            id: value,
            name: label
        }) => ({
            value,
            label
        })));

        console.log(documentList)

    }

    /**
     * submit to section
     */
    const onSubmitSection = () => {
        console.log(values)
        if (validateValues(['section', 'docselected'])) {
            //showToaster(toasterTypes.ERROR, "Please select values");
        } else {
            if (values.section.value == "add") {
                let Obj = {
                    "type": "heading2",
                    "attrs": {
                        "id": "",
                        "class": "heading2",
                        "track": [],
                        "group": "",
                        "viewid": ""
                    },
                    "content": [
                        {
                            "type": "text",
                            "text": values.newsection
                        }
                    ]
                };
                let response = PushToEditor(Obj, "newsection")
                if (response) {
                    let updatedData = {
                        docselected: values.docselected,
                        section:{label:values.newsection,value:values.newsection},
                        newsection: values.newsection
                    }
                    console.log(updatedData)
                    pushAction(updatedData)
                }
            } else {
                pushAction(values)
            }
        }
    }
    useEffect(() => {

    }, [documentList, sections])


    return (
        <ModalComponent
            width={"350px"}
            isOpen={true}
            onClose={() => onclose()}
            header={"Under which section you want add the content?"}
            centered={true}
            customClass='template-modal'
            body={
                <div className="form-template">
                    <FieldItem
                        {...CreateSectionElements.docselected}
                        value={values.docselected}
                        values={documentList}
                        onChange={(...e) => handleChange(CreateSectionElements.docselected.name, ...e)}
                        touched={fields.docselected && fields.docselected.hasError}
                        error={fields.docselected && fields.docselected.errorMsg}
                    />
                    <FieldItem
                        {...CreateSectionElements.section}
                        value={values.section}
                        values={sections}
                        onChange={(...e) => handleChange(CreateSectionElements.section.name, ...e)}
                        touched={fields.section && fields.section.hasError}
                        error={fields.section && fields.section.errorMsg}
                    />
                    {
                        newSection ?
                            <FieldItem
                                {...CreateSectionElements.newsection}
                                value={values.newsection}
                                type={FIELD_TYPES.TEXT}
                                onChange={(...e) => handleChange(CreateSectionElements.newsection.name, ...e)}
                                touched={fields.newsection && fields.newsection.hasError}
                                error={fields.newsection && fields.newsection.errorMsg}
                            />
                            : ""
                    }

                </div>
            }
            footer={
                <>
                    <CustomButton text="Push" type="primary" onClick={() => onSubmitSection()}></CustomButton>
                </>
            }
        />
    )
}

export default SectionModal;