import { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { CustomButton, showToaster, toasterTypes } from "../../widgets";
import { FieldItem } from "../../widgets/fields";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import { AffiliationFormElements } from "../createProject/FormElements";
import TitleGeneration from "./TitleGeneration";

export default function TitleCreation(props) {
    const navigate = useNavigate();
    const initialValues = useMemo(() => (props.data?.title ? props.data.title : { title: '' }), [props.data.title]);
    const [values, fields, handleChange, { validateValues }] = useFieldItem(AffiliationFormElements, initialValues, {});



    /**
     * next button action
     * validate form and push up data
     */
    const next = () => {
        if (validateValues(["title"])) {
            showToaster(toasterTypes.ERROR, "Please enter the proper values in the fields highlighted in red");
        } else {
            props.next(values, "title");
        }
    };




    return (
        <>

            <div className="title-page">
                <div className="title-container">
                    <h5>Title creation</h5>
                    <Row>
                        <Col>
                            <FieldItem
                                {...AffiliationFormElements.title}
                                value={values.title}
                                onChange={(...e) => handleChange(AffiliationFormElements.title.name, ...e)}
                                touched={fields.title && fields.title.hasError}
                                error={fields.title && fields.title.errorMsg}
                            />
                        </Col>
                    </Row>

                    <div className='help-text'>Need any help in Title Generation</div>
                    <div className='help-text'>proceed with our</div>
                    <span className='link-text' onClick={() => props.onGenerate()}>Title Generation Wizard</span>

                    <div className='video-footer'>
                        <div style={{ textAlign: "center" }}>Watch video(s) to understand more about</div>
                        <div style={{ textAlign: "center" }}><a target="_blank" href='https://reap.coguide.in/playvideo?rgt=https:%2F%2Ff000.backblazeb2.com%2Ffile%2FcGbgmedia%2FCP017_TitleinPICOSTformatmq4t3t3w5uzdrpp5r4pl.mp4#t=1'>How to write Scientific research title?</a></div>
                    </div>
                </div>

            </div>

            <div className="footer-button">
                <Row className='title-footer m-0'>
                    <Col className='p-0'>
                        <div className='cancel-text' onClick={() => navigate('/project')}>Cancel</div>
                    </Col>
                    <Col className='p-0 d-flex m-0 justify-content-end'>
                        <CustomButton type="primary" onClick={() => props.previous()} className="w-100" text="Previous"></CustomButton>
                        <CustomButton type="primary" onClick={() => next()} className="w-100" text="Next"></CustomButton>
                    </Col>
                </Row>
            </div>

        </>
    )
}