import { GraphicWalker } from "@kanaries/graphic-walker";
// @ts-ignore
import { transData } from "@kanaries/graphic-walker/dataSource/utils";
import { useEffect, useMemo, useRef, useState } from "react";
import SectionModal from "../../Editor/utils/SectionModal";
import { uploadChartGraphicWalker } from "../../Editor/utils/UploadToServer";
import { ToolbarItemProps } from "@kanaries/graphic-walker/dist/components/toolbar";
import { ArrowLeftOutlined, CopyOutlined } from '@ant-design/icons';
import { Col, Row } from "reactstrap";
import CopyTooltip from "../../Editor/utils/CopyTooltip";


const GraphicWalkerVisualization = (props) => {
  const chartRef : any = useRef(null);
  const [actionPush, setactionPush] = useState("");
  const projectData = useMemo(() => JSON.parse(localStorage.getItem("projectData") || "{}"), []);
  const projectName = useMemo(() => projectData.TopicDesc, [projectData]);
  const [selectedText, setSelectedText] = useState('');
	const [tooltipStyle, setTooltipStyle] = useState({});
	const [showTooltip, setShowTooltip] = useState(false);
  const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null);
  
  useEffect(() => {
    const handleClick = (e) => {
      e.stopPropagation();
      setShowTooltip(false)};
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, []);


  let transformedData;
  try {
    if ('last' in Array.prototype) {
          delete Array.prototype.last;
        }
        if ('reduceOneRight' in Array.prototype) {
          delete Array.prototype.reduceOneRight;
        }
    transformedData = transData(props?.data);
  } catch (error) {
    console.error("Error transforming data:", error);
  }
  

  async function copyChartToEditor(event) {
    let varible = await chartRef.current.exportChart();
    let svgString = varible.charts[0].data;
    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(svgString, 'image/svg+xml');
    const svgData = new XMLSerializer().serializeToString(svgDoc.documentElement);

    const img = new Image();
    img.src = 'data:image/svg+xml;base64,' + btoa(svgData);

    img.onload = function () {
      const canvas = document.createElement("canvas");
      const ctx: any = canvas.getContext("2d");

      canvas.width = img.width;
      canvas.height = img.height;

      ctx.drawImage(img, 0, 0);

      const pngDataUrl = canvas.toDataURL("image/png");

      const byteString = atob(pngDataUrl.split(',')[1]);
      const mimeString = pngDataUrl.split(',')[0].split(':')[1].split(';')[0];
      const buffer = new ArrayBuffer(byteString.length);
      const uintArray = new Uint8Array(buffer);

      for (let i = 0; i < byteString.length; i++) {
        uintArray[i] = byteString.charCodeAt(i);
      }

      const blob = new Blob([buffer], { type: mimeString });
      setactionPush("");
      uploadChartGraphicWalker(blob, event.section.value);
    };

  }

  async function copyCanvasChartToEditor(event){
    if (canvas) {
      const pngDataUrl = canvas.toDataURL("image/png");

      const byteString = atob(pngDataUrl.split(',')[1]);
      const mimeString = pngDataUrl.split(',')[0].split(':')[1].split(';')[0];
      const buffer = new ArrayBuffer(byteString.length);
      const uintArray = new Uint8Array(buffer);

      for (let i = 0; i < byteString.length; i++) {
        uintArray[i] = byteString.charCodeAt(i);
      }

      const blob = new Blob([buffer], { type: mimeString });
      setactionPush("");
      uploadChartGraphicWalker(blob, event.section.value);
    }
    
    
  }

  const customToolbarButton: ToolbarItemProps = {
    key: 'copy-to-editor',
    icon: () => <CopyOutlined />,
    label: 'Copy to Editor',
    onClick: () => setactionPush("section")
  };


  const sectionAction = () => {
		setactionPush("interpreter");
    setShowTooltip(false);
	}

  const preventDefaultRightClick = (event) => {
    event.preventDefault();
    if(event.target.tagName.toLowerCase() == "canvas"){
      setCanvas(event.target);
    }
    
    if (event.target) {
      const rect = event.target.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      setTooltipStyle({
        top: `${y}px`,
        left: `${x}px`,
        zIndex: 10000
      });
      setShowTooltip(true);
    }else{
      setShowTooltip(false);
    }
  }
  
  if (!props.data || props.data.length === 0) {
    return <div>No data to visualize</div>;
  }

  return (
    <>
      {showTooltip && (
				<CopyTooltip tooltipStyle={tooltipStyle} data={selectedText} pushAction={sectionAction} />
			)}
      {
        actionPush == "section" &&
        <SectionModal pushAction={copyChartToEditor} onclose={() => setactionPush("")} />
      }
      {
        actionPush == "interpreter" &&
        <SectionModal pushAction={copyCanvasChartToEditor} onclose={() => setactionPush("")} />
      }
      <Row className='page-header m-0'>
      <Col className={window.innerWidth > 750 ? "project-title" : "project-title-mob"} md={11}>
        <ArrowLeftOutlined style={{ color: "#fff", fontSize: "24px", margin: "0px 10px" }} onClick={() => props.onClose()} />
        {
            <span style={{ color: "#fff", fontSize: "24px", margin: "0px 10px" }}>{projectName}</span>
        }
      </Col>
    </Row>
      <div className="graphic-walker" onContextMenu={preventDefaultRightClick}>
        {
          transformedData &&
        <GraphicWalker ref={chartRef} 
        data={transformedData?.dataSource || []}
          fields={transformedData?.fields || []}
          appearance="light"
          toolbar={{
            extra: [customToolbarButton]
          }}
          keepAlive={true}
        />
      }   
      </div>
    </>
  );

};
export default GraphicWalkerVisualization;