import { FIELD_TYPES } from "../../widgets/fields";

export const CreateFormElements = {
  name: {
    name: "name",
    placeholder: "Enter",
    label: "Plan Name",
    isMandatory: true,
  },
  description: {
    name: "description",
    placeholder: "Enter",
    label: "Plan Description",
    isMandatory: true,
  },
  taskName: {
    name: "taskname",
    placeholder: "Enter Task Name",
    label: "",
    type: FIELD_TYPES.TEXT,
    maxLength: 256
  },
  taskDescription: {
    name: "taskdescription",
    placeholder: "Enter Task Description",
    label: "",
    type: FIELD_TYPES.TEXT,
  },
  plannedDuration: {
    name: "plannedduration",
    placeholder: "Enter Planned Duration",
    label: "",
    type: FIELD_TYPES.NUMBER,
  },
  plannedDurationUnits: {
    name: "planneddurationunits",
    placeholder: "Select Units",
    label: "",
    type: FIELD_TYPES.DROP_DOWN,
    values: [
      { value: "Percentage", label: "Percentage" },
      { value: "Hours", label: "Hours" },
      { value: "Days", label: "Days" },
      { value: "Weeks", label: "Weeks" },
      { value: "Months", label: "Months" },
    ],
    menuPlacement: "top",
  },
  startdate: {
    name: "startdate",
    placeholder: "Start Date",
    label: "Plan Start Date",
    type: FIELD_TYPES.DATE_TIME_PICKER,
    isMandatory: false,
  },
  enddate: {
    name: "enddate",
    placeholder: "End Date",
    label: "Plan End Date",
    type: FIELD_TYPES.DATE_TIME_PICKER,
    isMandatory: false,
  },
  plannedStartDate:{
    name: "plannedstartdate",
    placeholder: "Planned start Date",
    label: "Planned Start Date",
    type: FIELD_TYPES.DATE_TIME_PICKER,
    isMandatory: false,
  },
  plannedEndDate:{
    name: "plannedenddate",
    placeholder: "Planned End Date",
    label: "Planned End Date",
    type: FIELD_TYPES.DATE_TIME_PICKER,
    isMandatory: false,
  },
  formTemplate: {
    name: "formTemplate",
    placeholder: "Select",
    label: "Form Template",
    type: FIELD_TYPES.DROP_DOWN,
    isMandatory: false,
  },
  planRadioButtons: {
    name: "planradiobuttons",
    placeholder: "",
    label: "",
    type: FIELD_TYPES.RADIO_BUTTON,
    values: [{label: 'Automatic', value: 'a'}, {label: 'Manual', value: 'm'}],
    isMandatory: true,
    isListed: true
  },
  planTemplates: {
    name: "planTemplates",
    placeholder: "Custom",
    label: "Plan Template",
    type: FIELD_TYPES.DROP_DOWN,
  },
  scaleDropdown: {
    name: "scaleDropdown",
    placeholder: "Select Scale",
    label: "Scale",
    type: FIELD_TYPES.DROP_DOWN,
    values: [
      { value: "days", label: "Days" },
      { value: "months", label: "Months" },
      { value: "years", label: "Years" },
    ],
  },
  startrange: {
    name: "startrange",
    placeholder: "Start Range",
    label: "Start Range",
    type: FIELD_TYPES.DATE_TIME_PICKER,
    isMandatory: false,
  },
  endrange: {
    name: "endrange",
    placeholder: "End Range",
    label: "End Range",
    type: FIELD_TYPES.DATE_TIME_PICKER,
    isMandatory: false,
  },
};
