import React, { useEffect, useState } from 'react';
import { Col, Row } from "reactstrap";
import { CustomButton, showToaster, toasterTypes } from '../../widgets';
import ModalComponent from '../common/Modal/modal';
import { FieldItem } from "../../widgets/fields";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import { PublicationFormElements } from './FormElements';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../store/slices/auth';
import { addResearchExperience, uploadPhoto } from './apiController';

export default function PublicationDetails(props) {
	const initialValues = React.useMemo(() => ({ orcid: '', hIndex: '', iIndex: '', totalProjects: '', totalPatents: '', publicationCounts: '', areasOfInterest: '', rolePlayed: [], author: [] }), []);
	const { selectedIndex, data } = props;
	const [values, fields, handleChange, { validateValues, reset, updateValue }] = useFieldItem(PublicationFormElements, initialValues, {});
	const dispatch = useDispatch();
	const [popupType, setpopupType] = useState('');

	useEffect(() => {
		let publicationData = data?.research_experience || {};

		if (Object.keys(publicationData).length) {
			updateValue({ orcid: publicationData?.orcid, hIndex: publicationData?.h_index, iIndex: publicationData?.i10_index, totalProjects: publicationData?.projects_count, publicationCounts: publicationData?.publication_count, totalPatents: publicationData?.patent_count, areasOfInterest: '', rolePlayed: [], author: [] });
		}

		//setcertificates(skillsData?.certifications || []);

	}, [data]);

	const checkSubmit = async () => {
		if (validateValues(["orcid", "hIndex", "iIndex", "totalProjects", "totalPatents"])) {
			showToaster(toasterTypes.ERROR, "Please enter the proper values in the fields highlighted in red");
		} else {

			let data = {
				"orcid": "",//values.orcid
				"h_index": values.hIndex,
				"i10_index": values.iIndex,
				"projects": [
					"62b6b3d247a91262dea1ea9f",
					"62b6b3d247a91262dea1ea9e"
				],
				"projects_count":parseInt(values.totalProjects),
				"areas_of_interest": [],
				"publication_count": parseInt(values.publicationCounts),
				"patent_count": parseInt(values.totalPatents),
				"software_skills": []
			}
			
			// Call API
			try {
				dispatch(setLoading(true));
				let response = await addResearchExperience(data);
				showToaster(toasterTypes.SUCCESS, response.message);
				props.onClose('callAPI');
				dispatch(setLoading(false));
			} catch (e: any) {
				dispatch(setLoading(false));
			}
		}
	}

	const onCertificate = () => {
		if (validateValues(["skillName"])) {
			showToaster(toasterTypes.ERROR, "Please enter the proper values in the fields highlighted in red");
		} else {
			setpopupType('addproject');

		}
	}

	const uploadDocument = (e) => {
		console.log(e);
	}

	/**
	 * render project details
	 * @returns 
	 */
	const onRenderProject = () => {
		return (
			<div>
				<Row>
					<Col md={4}>
						<FieldItem
							{...PublicationFormElements.projectType}
							value={values.projectType}
							onChange={(...e) => handleChange(PublicationFormElements.projectType.name, ...e)}
							touched={fields.projectType && fields.projectType.hasError}
							error={fields.projectType && fields.projectType.errorMsg}
						/>
					</Col>

					<Col md={4}>
						<FieldItem
							{...PublicationFormElements.projectName}
							value={values.projectName}
							onChange={(...e) => handleChange(PublicationFormElements.projectName.name, ...e)}
							touched={fields.projectName && fields.projectName.hasError}
							error={fields.projectName && fields.projectName.errorMsg}
						/>
					</Col>

					<Col md={4}>
						<FieldItem
							{...PublicationFormElements.projectDescription}
							value={values.orcid}
							onChange={(...e) => handleChange(PublicationFormElements.projectDescription.name, ...e)}
							touched={fields.projectDescription && fields.projectDescription.hasError}
							error={fields.projectDescription && fields.projectDescription.errorMsg}
						/>
					</Col>

				</Row>
				<Row>
					<Col md={4}>
						<FieldItem
							{...PublicationFormElements.projectBudget}
							value={values.projectBudget}
							onChange={(...e) => handleChange(PublicationFormElements.projectBudget.name, ...e)}
							touched={fields.projectBudget && fields.projectBudget.hasError}
							error={fields.projectBudget && fields.projectBudget.errorMsg}
						/>
					</Col>

					<Col md={4}>
						<FieldItem
							{...PublicationFormElements.fundingAgency}
							value={values.fundingAgency}
							onChange={(...e) => handleChange(PublicationFormElements.fundingAgency.name, ...e)}
							touched={fields.fundingAgency && fields.fundingAgency.hasError}
							error={fields.fundingAgency && fields.fundingAgency.errorMsg}
						/>
					</Col>

					<Col md={4}>
						<FieldItem
							{...PublicationFormElements.doi}
							value={values.doi}
							onChange={(...e) => handleChange(PublicationFormElements.doi.name, ...e)}
							touched={fields.doi && fields.doi.hasError}
							error={fields.doi && fields.doi.errorMsg}
						/>
					</Col>


				</Row>

				<Row>
					<Col md={4}>
						<FieldItem
							{...PublicationFormElements.citationCount}
							value={values.citationCount}
							onChange={(...e) => handleChange(PublicationFormElements.citationCount.name, ...e)}
							touched={fields.citationCount && fields.citationCount.hasError}
							error={fields.citationCount && fields.citationCount.errorMsg}
						/>
					</Col>

					<Col md={4}>
						<FieldItem
							{...PublicationFormElements.publishedDate}
							value={values.publishedDate}
							onChange={(...e) => handleChange(PublicationFormElements.publishedDate.name, ...e)}
							touched={fields.publishedDate && fields.publishedDate.hasError}
							error={fields.publishedDate && fields.publishedDate.errorMsg}
						/>
					</Col>

					<Col md={4}>
						<FieldItem
							{...PublicationFormElements.volume}
							value={values.volume}
							onChange={(...e) => handleChange(PublicationFormElements.volume.name, ...e)}
							touched={fields.volume && fields.volume.hasError}
							error={fields.volume && fields.volume.errorMsg}
						/>
					</Col>

				</Row>

				<Row>

					<Col md={4}>
						<FieldItem
							{...PublicationFormElements.pageNo}
							value={values.pageNo}
							onChange={(...e) => handleChange(PublicationFormElements.pageNo.name, ...e)}
							touched={fields.pageNo && fields.pageNo.hasError}
							error={fields.pageNo && fields.pageNo.errorMsg}
						/>
					</Col>
					<Col md={4}>
						<FieldItem
							{...PublicationFormElements.journal}
							value={values.journal}
							onChange={(...e) => handleChange(PublicationFormElements.journal.name, ...e)}
							touched={fields.journal && fields.journal.hasError}
							error={fields.journal && fields.page.errorMsg}
						/>
					</Col>

					<Col md={4}>
						<FieldItem
							{...PublicationFormElements.projectStatus}
							value={values.projectStatus}
							onChange={(...e) => handleChange(PublicationFormElements.projectStatus.name, ...e)}
							touched={fields.projectStatus && fields.projectStatus.hasError}
							error={fields.projectStatus && fields.projectStatus.errorMsg}
						/>
					</Col>

				</Row>

				<Row>

					<Col md={4}>
						<FieldItem
							{...PublicationFormElements.rolePlayed}
							value={values.rolePlayed}
							onChange={(...e) => handleChange(PublicationFormElements.rolePlayed.name, ...e)}
							touched={fields.rolePlayed && fields.rolePlayed.hasError}
							error={fields.rolePlayed && fields.rolePlayed.errorMsg}
						/>
					</Col>

					<Col md={4}>
						<FieldItem
							{...PublicationFormElements.author}
							value={values.author}
							onChange={(...e) => handleChange(PublicationFormElements.author.name, ...e)}
							touched={fields.author && fields.author.hasError}
							error={fields.author && fields.author.errorMsg}
						/>
					</Col>

					<Col md={4}>
						<FieldItem
							{...PublicationFormElements.documentsUpload}
							value={values.documentsUpload}
							onChange={(...e) => uploadDocument(PublicationFormElements.documentsUpload.name)}
							touched={fields.documentsUpload && fields.documentsUpload.hasError}
							error={fields.documentsUpload && fields.documentsUpload.errorMsg}
						/>
					</Col>
				</Row>
			</div>
		)
	}

	const onRenderPublication = () => {
		return (
			<div>
				<Row>
					{/* <Col md={4}>
						<FieldItem
							{...PublicationFormElements.orcid}
							value={values.orcid}
							onChange={(...e) => handleChange(PublicationFormElements.orcid.name, ...e)}
							touched={fields.orcid && fields.orcid.hasError}
							error={fields.orcid && fields.orcid.errorMsg}
						/>
					</Col> */}
					<Col md={4}>
						<FieldItem
							{...PublicationFormElements.hIndex}
							value={values.hIndex}
							onChange={(...e) => handleChange(PublicationFormElements.hIndex.name, ...e)}
							touched={fields.hIndex && fields.hIndex.hasError}
							error={fields.hIndex && fields.hIndex.errorMsg}
						/>
					</Col>
					<Col md={4}>
						<FieldItem
							{...PublicationFormElements.iIndex}
							value={values.iIndex}
							onChange={(...e) => handleChange(PublicationFormElements.iIndex.name, ...e)}
							touched={fields.iIndex && fields.iIndex.hasError}
							error={fields.iIndex && fields.iIndex.errorMsg}
						/>
					</Col>
				
					<Col md={4}>
						<FieldItem
							{...PublicationFormElements.totalProjects}
							value={values.totalProjects}
							onChange={(...e) => handleChange(PublicationFormElements.totalProjects.name, ...e)}
							touched={fields.totalProjects && fields.totalProjects.hasError}
							error={fields.totalProjects && fields.totalProjects.errorMsg}
						/>
					</Col>
					<Col md={4}>
						<FieldItem
							{...PublicationFormElements.totalPatents}
							value={values.totalPatents}
							isNumber={true}
							onChange={(...e) => handleChange(PublicationFormElements.totalPatents.name, ...e)}
							touched={fields.totalPatents && fields.totalPatents.hasError}
							error={fields.totalPatents && fields.totalPatents.errorMsg}
						/>
					</Col>
					<Col md={4}>
						<FieldItem
							{...PublicationFormElements.publicationCounts}
							value={values.publicationCounts}
							isNumber={true}
							onChange={(...e) => handleChange(PublicationFormElements.publicationCounts.name, ...e)}
							touched={fields.publicationCounts && fields.publicationCounts.hasError}
							error={fields.publicationCounts && fields.publicationCounts.errorMsg}
						/>
					</Col>
				</Row>
				{/* <Row>
					<Col md={10}>
						<FieldItem
							{...PublicationFormElements.areasOfInterest}
							value={values.areasOfInterest}
							onChange={(...e) => handleChange(PublicationFormElements.areasOfInterest.name, ...e)}
							touched={fields.areasOfInterest && fields.areasOfInterest.hasError}
							error={fields.areasOfInterest && fields.areasOfInterest.errorMsg}
						/>
					</Col>
				</Row> */}

				{/* <Row className='m-0 mt-2'>
					<Col className='p-0'>
						<div className='card-header-text'>Project Details</div>
					</Col>
					<Col className='p-0'>
						<div className='d-flex m-0 justify-content-end'>
							<i className='icon-add c-pointer' onClick={() => onCertificate()}></i>
							<div className='add-text c-pointer' onClick={() => onCertificate()}>ADD PROJECT</div>
						</div>
					</Col>
				</Row> */}
			</div>
		)
	}


	return (
		<ModalComponent
			width={window.innerWidth > 700 ? "800px" : "350px"}
			isOpen={true}
			onClose={() => popupType === 'addproject' ? setpopupType('') : props.onClose()}
			header={popupType === 'addproject' ? "Research Project" : "Research & Publications"}
			centered={true}
			customClass='profile-modal'
			body={
				popupType == "addproject" ? onRenderProject() : onRenderPublication()
			}
			footer={
				<div>
					<CustomButton type="primary" isDisabled={false} text="Save" onClick={() => checkSubmit()}></CustomButton>
				</div>
			}
		/>
	)
}