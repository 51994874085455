import { CloseCircleFilled } from "@ant-design/icons";
import { Button, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function InviteReject(){

    const navigate = useNavigate();

    const redirectAction = () =>{
        navigate("/project");
    }
    return(
        <>
            <div className="invite-reject-container-mob">
                    <div className="success-image-sec">
                         <div className="success-checkmark">
                            <CloseCircleFilled className="closeicon"/>                    
                         
                        </div>                     
                    </div>
                    <div className="sucess-message">
                        <span className="message-invite">Invite Rejected</span>
                    </div>
                    <div className="reject-message-txt">
                        <span className="message-invite-txt">Successfully</span>
                    </div>

                    <div className="sucess-message-email">
                        <span className="message-invite-email">sample@gmail.com</span>
                    </div>
                              
                           
                    {/* <div className="sucess-message">
                        <span className="message-invite">Invite Sent</span>
                        <br></br>
                        <span className="success-text">Successfully</span>
                        <div className="sucess-email">
                        <span className="sent-email">samplework@gmail</span>
                    </div>
                    </div> */}
                   
                    <div className="timer-sec">
                        <Button onClick={() => redirectAction()}>Continue to Dashboard</Button>
                    </div>

            </div>
        </>
    );
}