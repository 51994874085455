import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { CustomButton, showToaster, toasterTypes } from '../../widgets';
import { FieldItem } from '../../widgets/fields';
import useFieldItem from '../../widgets/fields/UseFieldItem';
import ModalPopup from '../../widgets/modal';
import { FormElements } from './FormElements';
import Table from './table';

export default function Home() {
	const { t } = useTranslation();
	const [isModal, setisModal] = useState(false);
	const initialValues = useMemo(() => (true ? {
		ruleName: 'test',
	} : {}
	), []);
	const [values, fields, handleChange, { validateValues, reset, updateValue }] = useFieldItem(FormElements, initialValues, {});
	
	function submit() {
		if (validateValues(["ruleName", "ruleDescription", "role", "multiRole", "radio", "checkbox", "dateField", "dateTimeField", "fileUpload"])) {
			showToaster(toasterTypes.ERROR, "Please enter the proper values in the fields highlighted in red");
		} else {
			showToaster(toasterTypes.SUCCESS, "Submitted successfully");
			reset();
		}
	}

	function update() {
		updateValue({"ruleDescription": "Updated"});
	}
	
	return (
		<div className='p-5'>
			{isModal &&
				<ModalPopup
					modalType='delete'
					modalText="Are you sure, you want to delete?"
					actionText="This action cannot be undone"
					closeModal={() => setisModal(false)}
					onAction={() => setisModal(false)}
				/>
			}
			<div className='font-title mb-4'>{t('dashboard.header', 'Dashboard')}</div>
			<Row className='mb-4'>
				<Col className='col-md-7'>
					<FieldItem
						{...FormElements.ruleName}
						value={values.ruleName}
						onChange={(...e) => handleChange(FormElements.ruleName.name, ...e)}
						icon="phone"
						touched={fields.ruleName && fields.ruleName.hasError}
						error={fields.ruleName && fields.ruleName.errorMsg}
					/>
					<FieldItem
						{...FormElements.ruleDescription}
						value={values.ruleDescription}
						onChange={(...e) => handleChange(FormElements.ruleDescription.name, ...e)}
						touched={fields.ruleDescription && fields.ruleDescription.hasError}
						error={fields.ruleDescription && fields.ruleDescription.errorMsg}
					/>
				</Col>
				<Col className='col-md-5'>
					<FieldItem
						{...FormElements.role}
						value={values.role}
						onChange={(...e) => handleChange(FormElements.role.name, ...e)}
						touched={fields.role && fields.role.hasError}
						error={fields.role && fields.role.errorMsg}
					/>
					<FieldItem
						{...FormElements.multiRole}
						value={values.multiRole}
						onChange={(...e) => handleChange(FormElements.multiRole.name, ...e)}
						touched={fields.multiRole && fields.multiRole.hasError}
						error={fields.multiRole && fields.multiRole.errorMsg}
					/>
				</Col>
			</Row>
			<Row className='mb-4'>
				<Col className='col-md-4'>
					<FieldItem
						{...FormElements.dateField}
						value={values.dateField}
						onChange={(...e) => handleChange(FormElements.dateField.name, ...e)}
						minDate={new Date("04-10-2022")}
						maxDate={new Date("12-31-2022")}
						excludeDates={[new Date("05-14-2022")]}
						todayButton={true}
						touched={fields.dateField && fields.dateField.hasError}
						error={fields.dateField && fields.dateField.errorMsg}
					/>
				</Col>
				<Col className='col-md-8'>
					<FieldItem
						{...FormElements.fileUpload}
						value={values.fileUpload}
						onChange={(...e) => handleChange(FormElements.fileUpload.name, ...e)}
						touched={fields.fileUpload && fields.fileUpload.hasError}
						error={fields.fileUpload && fields.fileUpload.errorMsg}
					/>
				</Col>
			</Row>
			<Row className='mb-4'>
				<Col className='col-md-4'>
					<FieldItem
						{...FormElements.radio}
						value={values.radio}
						isListed={true}
						onChange={(...e) => handleChange(FormElements.radio.name, ...e)}
						touched={fields.radio && fields.radio.hasError}
						error={fields.radio && fields.radio.errorMsg}
					/>
				</Col>
				<Col className='col-md-4'>
					<FieldItem
						{...FormElements.checkbox}
						value={values.checkbox}
						onChange={(...e) => handleChange(FormElements.checkbox.name, ...e)}
						touched={fields.checkbox && fields.checkbox.hasError}
						error={fields.checkbox && fields.checkbox.errorMsg}
					/>
				</Col>
				<Col className='col-md-4'>
					<FieldItem
						{...FormElements.searchField}
						value={values.searchField}
						onChange={(...e) => handleChange(FormElements.searchField.name, ...e)}
					/>
				</Col>
			</Row>
			<Row>
				<Col><CustomButton type="primary" isDisabled={false} text="Submit" onClick={submit}></CustomButton></Col>
				<Col><CustomButton type="alert-primary" isDisabled={false} text="Submit"></CustomButton></Col>
				<Col><CustomButton type="secondary" isDisabled={false} text="Update" onClick={update}></CustomButton></Col>
				<Col><CustomButton type="alert-secondary" isDisabled={false} text="Modal" onClick={() => setisModal(true)}></CustomButton></Col>
			</Row>
			<Row className='mt-4 mb-4'>
				<Col><CustomButton type="primary" isDisabled={true} text="Submit"></CustomButton></Col>
				<Col><CustomButton type="alert-primary" isDisabled={true} text="Submit"></CustomButton></Col>
				<Col><CustomButton type="secondary" isDisabled={true} text="Submit"></CustomButton></Col>
				<Col><CustomButton type="alert-secondary" isDisabled={true} text="Submit"></CustomButton></Col>
			</Row>
			<Table />
		</div>
	)
}
