import { DeleteFilled } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { FieldItem, FIELD_TYPES } from "../../widgets/fields";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import { DocTypeList } from "../dataCollection/FormElements";


export default function AddDocument(props){
    const [values, fields, handleChange, { validateValues, reset, updateValue }] = useFieldItem(DocTypeList, {}, { onValueChange });  
    const { data, type } = props;
    const [docTypes,SetDocTypes] = useState<any>([])

    useEffect(()=>{
        SetDocTypes(props.docData.data.map(({
            id: value,
            document_type:label
          }) => ({
            value,
            label
          })))
    },[]);

     /**
     * on value change update form data
     * @param name 
     * @param value 
     * @param values 
     * @param fieldValues 
     * @returns 
     */
      function onValueChange(name, value, values, fieldValues=null) {
		let resetValue = {};
        updateValue({doctypelist:value})
       
		return [{
			...values,
			...resetValue,
			[name]: value,
		}]
    }

    /**
     * get image name and image details send as props argument to parent function
     * @param name 
     * @param value 
     */
    const uploadImage = (name,value)=>{
        values.image = {file:value}
        props.onDocUpload(values,props.index)
    }

    return(
        <>
           <div className="add-doc-container">
                <div className="checklist">
                    <FieldItem
                        className="doctype-dropdown"
                        value={values.doctypelist}
                        values={docTypes?docTypes : []}
                        type={FIELD_TYPES.DROP_DOWN}
                        onChange={(...e) => handleChange(DocTypeList.fieldType.name, ...e)}
                        touched={DocTypeList.fieldType && DocTypeList.fieldType.hasError}
                        error={DocTypeList.fieldType && DocTypeList.fieldType.errorMsg}
                    /> 
                     <FieldItem
                        className="file-upload"
                        type={FIELD_TYPES.FILE_UPLOAD_ALL}
                        onChange={(...e) => uploadImage("image",...e)}
                    />
                    <DeleteFilled className="delete" onClick={()=> props.onDelete()}/>

                </div>
           </div> 
        </>
    )
}