import { useEffect, useState } from "react";
import ModalComponent from "../common/Modal/modal";
import { FIELD_TYPES, FieldItem } from "../../widgets/fields";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import { Col, Row } from "reactstrap";
import { CustomButton } from "../../widgets";

export default function CustomFilter(props) {
    const { data, submitAction,filterdata} = props;
    const [fieldData, SetfieldData] = useState<any>([]);
    const [values, fields, handleChange, { validateValues, reset, updateValue, updateError }] = useFieldItem({}, {}, { onValueChange });

    useEffect(() => {
        console.log(props)
        SetfieldData(data)
        updateValue(filterdata)
    }, [])

    /**
  * handle on change values of form elements
  * @param name 
  * @param value 
  * @param values 
  * @param fieldValues 
  * @returns 
  */
    function onValueChange(name, value, values, fieldValues = null) {
        const resetValue = {}
        console.log(values)

        return [{
            ...values,
            ...resetValue,
            [name]: value,
        }]
    }

    /**
     * on Apply create the body params format
     * get field types from fieldData
     */
    const applyFilter = async () => {
        let searchArray : any = [];
        for (var val in values) {
            if (values[val]) {
                let dataType = await fieldData && fieldData.filter(item => item.id == val);
                if(values[val].split(",").length > 1 && dataType[0].data.field_type == "number"){
                    let fieldValues = values[val].split(",");
                    let convertToNumberArray = fieldValues.map(Number)
                    let Obj =   {
                        "field_id":val,
                        "search_value":convertToNumberArray,
                        "field_type":dataType[0].data.field_type
                    };
                    searchArray.push(Obj)
                }else if(values[val].split(",").length > 1 ){
                    let Obj =   {
                        "field_id":val,
                        "search_value":values[val].split(","),
                        "field_type":dataType[0].data.field_type
                    };
                    searchArray.push(Obj)
                }else{
                    let Obj =   {
                        "field_id":val,
                        "search_value":dataType[0].data.field_type == "number" ? parseInt(values[val]):values[val],
                        "field_type":dataType[0].data.field_type
                    };
                    searchArray.push(Obj)
                }
            }
        }

        let finalSearchObject = {
            "search_data" : searchArray
        }
        console.log(finalSearchObject)
        submitAction(finalSearchObject,values,1);
    }

    return (
        <>
            <ModalComponent
                width="90%"
                isOpen={true}
                onClose={() => props.onClose()}
                header={"Filter By"}
                centered={true}
                customClass='filter'
                body={
                    <>
                        <span style={{ color: "#ccc", fontSize: "14px" }}>Note: To search multiple records of same field add values comma seprated</span>
                        <div className="p-3 custom-filter-container">
                            <Row>
                                {
                                    fieldData && fieldData.filter(items => items.data != "").map((item, i) => {
                                        return <Col md={4}>
                                            <FieldItem
                                                name={item.id}
                                                value={values[item.id]}
                                                label={item.label}
                                                onChange={(...e) => handleChange(item.id, ...e)}
                                            // type={item && item?.data.field_type == "number" ? FIELD_TYPES.NUMBER : FIELD_TYPES.TEXT}
                                            />
                                        </Col>
                                    })
                                }
                            </Row>
                        </div>
                    </>
                }
                footer={
                    <>
                        <CustomButton type="primary" text="Apply" onClick={() => applyFilter()} />
                    </>
                }
            />
        </>
    )
}