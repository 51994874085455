import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { authSelector } from '../store/slices/auth';

export default function SecuredLogin(props: any) {
  const { isAuth } = useSelector(authSelector)
  const login = isAuth;
if (login) {
      return props.children
  } else {
      return <Navigate to="/login" />;
  }
}

