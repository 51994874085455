import { ArrowLeftOutlined, CameraFilled, CameraTwoTone, InfoCircleFilled, QuestionCircleFilled } from '@ant-design/icons';
import { Radio } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { cloneDeep, sampleSize } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { setLoading } from '../../store/slices/auth';
import { CustomButton, showToaster, toasterTypes } from '../../widgets';
import { FieldItem, FIELD_TYPES } from '../../widgets/fields';
import useFieldItem from '../../widgets/fields/UseFieldItem';
import ModalPopup from '../../widgets/modal';
import Step from '../common/components/Step';
import ModalComponent from '../common/Modal/modal';
import { createSampleSize, getInputData, getQuestion } from './apiController';
import Tabs from './Tabs';
import { DASLINK1, DASLINK2, DASTITLE1, DASTITLE2, DCSS1Link, DCSS1TITLE, QUESTION1LINK, QUESTION1TITLE, QUESTION2LINK, QUESTION3TITLE, QUESTION3LINK, QUESTION2TITLE, QUESTION4TITLE, QUESTION4LINK, QUESTION6TITLE, QUESTION6LINK, QUESTION7TITLE, QUESTION7LINK, QUESTIONHEAD, Samplesize, SSV1, SSV1_name, SSV2, SSV2_name, SSV3, SSV3_name, CROSSSECTIONSTUDYLINK, CROSSSECTIONSTUDYTITLE, COHORTSTUDYLINK, COHORTSTUDYTITLE, CASECONTROLLINK, CASECONTROLTITLE, RANDOMCONTROLLINK, RANDOMCONTROLTITLE, NDASLINK1, NDASTITLE, QUESTIONSLIST } from './Videos';
import { TabsData } from '../../utils/TabsData';
import { calcCCS_P, calcDCSS1_P, calcDCSS_P, calcICSCBA_CC, calcICSCBA_N, calcICSCBA_P, calcRCTNRCT_N, calcRCTNRCT_P, calcSampleSizeForExpectedSensitivity } from '../studyModule/formula';

export default function SampleSize() {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const [projectData, setprojectData] = useState<any | null>(null);
	const [questionsData, setquestionsData] = useState<any | null>([]);
	const [inputData, setinputData] = useState<any | null>(null);
	const [formElements, setformElements] = useState<any | null>({});
	const [undertakingData, setundertakingData] = useState<any | null>(null);
	const [preFilled, SetPreFilled] = useState<any>([]);
	const [popUp, setPopUp] = useState(false);
	const [values, fields, handleChange, { validateValues, updateValue, updateError }] = useFieldItem(formElements, {}, {});
	const [selectedField, SetselectedField] = useState("");
	const [updatedFieldValue, SetUpdatedValue] = useState<any>({ "P1": '', "P2": '', "OR": '' });
	const [changedField, SetChangedField] = useState("");
	const [error, seterror] = useState(false);
	const [endTemplates, SetTemplate] = useState<any>([]);
	const [isTypeVariable, SetisTypeVariable] = useState<any>([]);
	const [permissionParams, SetPermissionParams] = useState<any>({});



	/**
   * 
   * @param data 
   */
	const getPermissions = async (data) => {
		await data.permissions && data.permissions.map(items => {
			console.log(Object.keys(items)[0].replace(/([^\w]+|\s+)/g, ''))

			if (Object.keys(items)[0].replace(/([^\w]+|\s+)/g, '') == "studydesign") {
				items[Object.keys(items)[0]].map(item => {
					if (item.permissions.filter(itm => itm.title == "allowed" && itm.is_selected == true).length != 0) {
						if (item.action == "previous button") {
							SetPermissionParams(prevState => ({ ...prevState, previous: "allowed" }))
						} else if (item.action == "cancel button") {
							SetPermissionParams(prevState => ({ ...prevState, cancel: "allowed" }))
						} else if (item.action == "save & continue button") {
							SetPermissionParams(prevState => ({ ...prevState, save: "allowed" }))
						}
					} else {
						if (item.action == "previous") {
							SetPermissionParams(prevState => ({ ...prevState, previous: "forbidden" }))
						} else if (item.action == "save & continue button") {
							SetPermissionParams(prevState => ({ ...prevState, save: "forbidden" }))
						} else if (item.action == "cancel") {
							SetPermissionParams(prevState => ({ ...prevState, cancel: "forbidden" }))
						}
					}

				})
			}

		})
	}

	useEffect(() => {
		let value: any = location.state;
		console.log("******************")
		console.log(value)
		getPermissions(value)
		if (value?.typedata == "STD") {
			if (value.data) {
				setprojectData(value.data);
				let temp: any = [];

				let valueData = {
					"data": {
						"Id": "1",
						"Code": value.data.SDMCode,
						"Name": value.data.SDMName,
						"SubCode": value.data.SDMSubCode,
						"SubData": [
							{
								"SubCode": value.data.SDMSubCode,
								"SubName": value.data.SDMSubName,
								"GroupSize": "1 Group",
								"SampleSize": value.data.SampleSize,
								"Formula": value.data.Formula,
								"SSC": "Nil",
								"SSIT": "Nil",
								"SSOT": "Nil",
								"Input": value.data.Input,
								"Output": value.data.Output
							}
						],
						"CreatedBy": value.data.CreatedBy,
						"CreatedOn": value.data.CreatedOn,
						"UpdatedBy": "",
						"UpdatedOn": ""
					}
				};

				console.log(valueData.data)
				valueData.data?.SubData[0]?.Input.map(item => {
					if (item.Name == "Prevalence of exposure in cases group") {
						SetUpdatedValue(prevState => {
							return {
								...prevState,
								"P1": parseFloat(item.Value)
							}
						})
					} else if (item.Name == "Prevalence of exposure in control group") {
						SetUpdatedValue(prevState => {
							return {
								...prevState,
								"P2": parseFloat(item.Value)
							}
						})
					} else if (item.Name == "Expected Odds ratio ") {
						SetUpdatedValue(prevState => {
							return {
								...prevState,
								"OR": parseFloat(item.Value)
							}
						})
					}
				})
				SetPreFilled(valueData);
				console.log(value.data?.QuestionHistory[0])
				onUndertakingChangePrev(valueData.data);
				if (value.data?.SDMSubCode != "DAS") {
					getInputs(value.data.SDMCode, valueData);
				}
				setquestionsData(value.data.QuestionHistory);

				//getQuestionData('Q1', 0, []);
			} else {
				navigate('/project');
			}
		} else {
			if (location.state) {
				setprojectData(location.state);
				getQuestionData('Q1', 0, []);
			} else {
				navigate('/project');
			}
		}

	}, [location.state, navigate]);

	// useEffect(()=>{

	// },[selectedField,updatedFieldValue])

	const onChange = (index, subData) => {
		setinputData(null);
		SetTemplate([]);
		console.log("Line 113", subData)

		let temp = cloneDeep(questionsData);
		if (temp[index].selectedAnswer !== subData?.AnswerId) {
			temp[index].selectedAnswer = subData.AnswerId;
			if (subData.Action === "QA") {
				getQuestionData(subData.Target, index + 1, temp);
				SetUpdatedValue({ "P1": '', "P2": '', "OR": '' })
			} else if (subData.Action === "STD") {
				temp.splice(index + 1, temp.length - index - 1);
				setquestionsData(temp);
				console.log(temp)
				getInputs(subData.Target, "");
			}
		}
	}

	/**
	 * 
	 * @param question 
	 * @param index 
	 * @param temp 
	 */
	const resetQdata = async () => {
		let value: any = location.state;
		console.log(value?.typedata);
		if (value.typedata) {
			value.typedata = "";
			location.state = value;
		}
	}

	const getQuestionData = async (question, index, temp) => {
		try {
			dispatch(setLoading(true));
			console.log(question)
			let response = await getQuestion(question);
			console.log(response)
			if (temp.length === index) {
				temp.push(response.data);
				setquestionsData(temp);
			} else {
				temp.splice(index, temp.length - index);
				temp.push(response.data);
				setquestionsData(temp);

			}
			dispatch(setLoading(false));
		} catch (e: any) {
			dispatch(setLoading(false));
		}
	}

	/**
	 * on selecting option from the question 
	 * get the values
	 * @param targetVal 
	 */
	const getInputs = async (targetVal, valueData) => {
		try {
			dispatch(setLoading(true));
			let value: any = location.state;

			let response: any = {};
			console.log(targetVal)
			response = await getInputData(targetVal);
			SetisTypeVariable([]);
			console.log(response);
			if (response.data.SubData.length === 1) {
				console.log(response.data.SubData[0])
				let temp = {};
				response.data.SubData[0].Input.forEach((obj) => {
					let type = obj.PlaceHolder.replace('$', '').replace('{', '').replace('}', '')
					temp[type] = {
						name: type,
						placeholder: obj.Type === "select" ? "Select" : obj.Type === "number" ? "Enter Number" : "Enter",
						label: obj.Name,
						type: obj.Type === "select" ? FIELD_TYPES.DROP_DOWN : FIELD_TYPES.TEXT,
						isMandatory: true
					}
					if (obj.Type === 'select') {
						obj.DropDown.filter((subObj) => subObj.value == obj.Value).map(objValue => {
							updateValue({ [type]: objValue.name ? { label: objValue.name, value: objValue.value } : null });
						})
					} else {
						updateValue({ [type]: obj.Value });
					}

					if (type === 'select') {
						obj.DropDown.forEach((subObj) => {
							subObj.label = subObj.name;
						});
						temp[type].values = obj.DropDown;
					} else {
						temp[type].inputType = obj.Type === "number" ? "number" : "text";
					}
				});
				setformElements(temp);
				console.log(temp)

				response.data.SubData[0].Input.map((item) => {
					let exist = item.Name.toLowerCase();
					console.log("!!!!!!!!!!")
					if (exist.includes("expected")) {
						SetTemplate(prevState => { return [...prevState, "expected"] })
					} else if (exist.includes("precision")) {
						SetTemplate(prevState => { return [...prevState, "precision"] })
					} else if (exist.includes("error")) {
						SetTemplate(prevState => { return [...prevState, "error"] })
					}
					console.log(endTemplates)
					//SetTemplate([...new Set(endTemplates)])
				})
				setinputData(response.data.SubData[0]);
				console.log("****************326**********")
				console.log(response.data)

				if (value?.typedata != "STD") {
					onUndertakingChange(response.data)
				}



			} else {
				console.log(response.data)
				response.data.SubData.map((item) => {
					let value = item.SubName.toLowerCase();
					if (value.includes("numerical outcome") || value.includes("proportion as outcome") || value.includes("rate as outcome")) {
						SetisTypeVariable(prevState => { return [...prevState, value] });
						console.log("Length::::::::::::::::::::::", value)

					}
				})

				setundertakingData(response.data);
			}
			dispatch(setLoading(false));
		} catch (e: any) {
			dispatch(setLoading(false));
		}
	}

	/**
	 * on previous click
	 */
	const previous = async () => {

		SetUpdatedValue({ "P1": '', "P2": '', "OR": '' })
		updateValue({});
		setformElements({});
		setinputData(null);
		changeLast('question');
		let value: any = location.state;
		// if(value.data.SDMCode == "DAS"){
		// 	getQuestionData('Q1', 0, []);
		// }else{
		// 	getInputs(value.data.SDMCode || value.SDMCode, "");
		// }
		//location.state = value.data
		console.log(value.data.SDMCode)
	}

	const changeLast = (type = '') => {
		console.log(type, questionsData, undertakingData)
		SetisTypeVariable([]);
		if (type) {
			let dummy = cloneDeep(undertakingData);
			console.log(dummy)
			if (dummy?.selectedAnswer) {
				delete dummy.selectedAnswer;
			}
			setundertakingData(dummy);
			undertakingData.SubData.map((item) => {
				let value = item.SubName.toLowerCase();
				if (value.includes("numerical outcome") || value.includes("proportion as outcome") || value.includes("rate as outcome")) {
					SetisTypeVariable(prevState => { return [...prevState, value] });
				}
			})
			console.log(isTypeVariable)
			// if(!dummy?.Code){
			// 	let temp = cloneDeep(questionsData);
			// 	delete temp[temp.length - 1].selectedAnswer;
			// 	setquestionsData(temp);
			// }		

		} else {
			let temp = cloneDeep(questionsData);
			delete temp[temp.length - 1].selectedAnswer;
			console.log(temp)

			setquestionsData(temp);
			console.log("Line 252", questionsData)

		}
	}

	/**
	 * on next validate values
	 */
	const next = async () => {
		console.log(questionsData)
		console.log(projectData)

		if (validateValues(Object.keys(formElements))) {
			showToaster(toasterTypes.ERROR, "Please enter the proper values in the fields highlighted in red");
		} else {
			let userData: any = localStorage.getItem('coguide_user');
			if (userData) {
				userData = JSON.parse(userData);
			}
			let questionhistory: any = [];
			console.log(questionsData)
			questionsData.forEach((obj) => {
				let value: any = location.state;
				console.log(obj)
				if (value?.typedata != "STD") {
					let ans = obj.Answers && obj.Answers.find(subObj => subObj.AnswerId === obj.selectedAnswer)
					console.log(ans)
					questionhistory.push({
						action: ans?.Action == undefined ? ans?.action : ans.Action,
						answer: ans?.Answer == undefined ? ans?.answer : ans.Answer,
						answerid: ans.AnswerId == undefined ? ans?.answerid : ans.AnswerId,
						question: obj.Question == undefined ? obj.question : obj.Question,
						questionid: obj.QuestionId == undefined ? obj.questionid : obj.QuestionId,
						target: ans?.Target == undefined ? obj.target : obj.Target
					})
				} else {
					console.log(obj);
					questionhistory.push({
						action: obj.Action == undefined ? obj.action : obj.Action,
						answer: obj.Answer == undefined ? obj.answer : obj.Answer,
						answerid: obj.AnswerId == undefined ? obj.answerid : obj.AnswerId,
						question: obj.Question == undefined ? obj.question : obj.Question,
						questionid: obj.QuestionId == undefined ? obj.questionid : obj.QuestionId,
						target: obj.Target == undefined ? obj.target : obj.Target
					})
				}

			});

			inputData.Input.forEach((obj) => {
				let type = obj.PlaceHolder.replace('$', '').replace('{', '').replace('}', '')
				if (obj.PlaceHolder === "P2") {

				} else if (obj.PlaceHolder === "OR") {

				}
				if (typeof values[type] == "object") {
					obj.Value = values[type].value
					console.log(obj.Value)
				} else {
					obj.Value = values[type]
				}
			})
			console.log(values)
			console.log(projectData)
			let reqObj = {
				Input: inputData.Input,
				Name: "Default",
				Output: inputData.Output,
				SDMCode: undertakingData?.Code || inputData?.SubCode,
				SDMName: undertakingData?.Name || inputData?.SubName,
				SDMSubcode: inputData?.SubCode || '',
				SDMSubname: inputData?.SubName || '',
				SpecCode: userData.PGCourse,
				TopicBigDesc: projectData.TopicBigDesc,
				TopicDesc: projectData.TopicDesc,
				TopicID: projectData.TopicCode || projectData.TopicID,
				TopicStatus: "approved",
				UserNumber: userData.UserNumber,
				formula: inputData.Formula || '',
				groupsize: inputData.GroupSize || '',
				questionhistory,
				samplesize: inputData.SampleSize || '',
				ssc: inputData.SSC || '',
				ssit: inputData.SSIT || '',
				ssot: inputData.SSOT || '',
				type: projectData.type
			}
			projectData.QuestionHistory = questionhistory;
			
			let calArray: any = [];

			switch (reqObj.SDMSubcode) {
				case "DAS":
					calArray = await calcSampleSizeForExpectedSensitivity(reqObj.Input);
					break;
				case "RCTNRCT_N":
					calArray = await calcRCTNRCT_N(reqObj.Input);
					break;
				case "RCTNRCT_P":
					calArray = await calcRCTNRCT_P(reqObj.Input);
					break;
				case "ICSCBA_N":
					calArray = await calcICSCBA_N(reqObj.Input);
					break;
				case "ICSCBA_P":
					calArray = await calcICSCBA_P(reqObj.Input);
					break;
				case "DCSS_P":
					calArray = await calcDCSS_P(reqObj.Input);
					break;
				case "DCSS1_P":
					calArray = await calcDCSS1_P(reqObj.Input);
					break;
				case "CCS_N":
					calArray = await calcRCTNRCT_N(reqObj.Input);
					break;
				case "CCS_P":
					calArray = await calcCCS_P(reqObj.Input);
					break;
				case "ICSCBA_CC":
					calArray = await calcICSCBA_CC(reqObj.Input);
					break;
				case "DCSS_CC":
					calArray = await calcICSCBA_CC(reqObj.Input);
					break;
				default:
					break;
			}

			console.log(calArray.filter(item => item.PlaceHolder == "${SampleSize}"))
			let samplesize_num = calArray.filter(item => item.PlaceHolder == "${SampleSize}")[0].Value;
			
			reqObj['samplesize_num'] = samplesize_num;

			console.log("################->")
			console.log(reqObj)
			console.log("################->")
			try {
				dispatch(setLoading(true));
				let response = await createSampleSize(reqObj);
				if (response.message?.toLowerCase() === 'created') {
					projectData.Output = inputData?.Output ? inputData.Output : '';
					console.log(reqObj.Input)
					projectData.Input = reqObj.Input
					console.log(projectData, questionhistory)
					projectData.SDMName = reqObj.SDMName;
					projectData.SDMCode = reqObj.SDMCode;
					projectData.SDMSubCode = reqObj.SDMSubcode;
					projectData.SDMSubName = reqObj.SDMSubname;
					console.log(projectData)

					navigate('/studyModule', { state: projectData });
				}
				dispatch(setLoading(false));
			} catch (e: any) {
				dispatch(setLoading(false));
			}
		}
	}

	/**
	 * 
	 * @param data 
	 * if previous button is called
	 */
	const onUndertakingChangePrev = (data) => {
		console.log(data)
		//let dummy = cloneDeep(undertakingData);
		//dummy.selectedAnswer = data.SubCode;
		//setundertakingData(dummy);
		data.SubData[0]?.Input?.map((item) => {
			let exist = item.Name.toLowerCase();
			console.log("!!!!!!!!!!")
			if (exist.includes("expected")) {
				SetTemplate(prevState => { return [...prevState, "expected"] })
			} else if (exist.includes("precision")) {
				SetTemplate(prevState => { return [...prevState, "precision"] })
			} else if (exist.includes("error")) {
				SetTemplate(prevState => { return [...prevState, "error"] })
			}

			console.log(endTemplates)
			//SetTemplate([...new Set(endTemplates)])
		})
		if (data.SubData[0].Input?.length > 0) {
			let temp = {};
			data.SubData[0].Input.forEach((obj) => {
				let type = obj.PlaceHolder.replace('$', '').replace('{', '').replace('}', '')
				temp[type] = {
					name: type,
					value: obj.Value ? obj.Value : obj.Type === "select" ? null : "",
					placeholder: obj.Type === "select" ? "Select" : obj.Type === "number" ? "Enter Number" : "Enter",
					label: obj.Name,
					type: obj.Type === 'select' ? FIELD_TYPES.DROP_DOWN : FIELD_TYPES.TEXT,
					isMandatory: true
				}
				if (obj.Type === 'select') {
					if (obj.Name == "Alpha error") {
						let indexValue = data.SubData[0].Input.map(e => e.Name).indexOf('Direction');

						if (data.SubData[0].Input[indexValue]?.Value == "one sided") {
							obj.DropDown.filter((subObj) => subObj.value.split(':')[0] == obj.Value).map(objValue => {
								updateValue({ [type]: objValue.name ? { label: objValue.name, value: objValue.value } : null });
							})
						} else {
							console.log(obj.DropDown)
							obj.DropDown.filter((subObj) => subObj.value.split(':')[1] == obj.Value).map(objValue => {
								updateValue({ [type]: objValue.name ? { label: objValue.name, value: objValue.value } : null });
							})
						}
						obj.DropDown.filter((subObj) => subObj.value == obj.Value).map(objValue => {
							updateValue({ [type]: objValue.name ? { label: objValue.name, value: objValue.value } : null });
						})
					} else {
						obj.DropDown.filter((subObj) => subObj.value == obj.Value).map(objValue => {
							updateValue({ [type]: objValue.name ? { label: objValue.name, value: objValue.value } : null });
						})
					}
					//updateValue({[type]: obj.Value ? {label: obj.Value, value: obj.Value} : null});
				} else {
					updateValue({ [type]: obj.Value });
				}
				if (obj.Type === 'select') {
					obj.DropDown.forEach((subObj) => {
						subObj.label = subObj.name;
					});
					console.log(obj.DropDown)

					temp[type].values = obj.DropDown;
				} else {
					temp[type].inputType = obj.Type === "number" ? "number" : "text";
				}
			});
			setformElements(temp);
			console.log(temp)
			setundertakingData(data);
			console.log("****************595**********")
			console.log(data.SubData[0])
			setinputData(data.SubData[0]);
		}
	}


	/**
	 * 
	 * @param data 
	 */
	const onUndertakingChange = (data) => {
		console.log(data)
		let dummy = cloneDeep(undertakingData);
		console.log(undertakingData)
		SetisTypeVariable([]);

		dummy.selectedAnswer = data.SubCode;
		setundertakingData(undertakingData);
		undertakingData.SubData.map((item) => {
			let value = item.SubName.toLowerCase();
			if (value.includes("numerical outcome") || value.includes("proportion as outcome") || value.includes("rate as outcome")) {
				SetisTypeVariable(prevState => { return [...prevState, value] });
			}
		})
		if (data.Input?.length > 0) {
			let temp = {};
			data.Input.forEach((obj) => {
				let type = obj.PlaceHolder.replace('$', '').replace('{', '').replace('}', '')
				temp[type] = {
					name: type,
					value: obj.Value ? obj.Value : obj.Type === "select" ? null : "",
					placeholder: obj.Type === "select" ? "Select" : obj.Type === "number" ? "Enter Number" : "Enter",
					label: obj.Name,
					type: obj.Type === 'select' ? FIELD_TYPES.DROP_DOWN : FIELD_TYPES.TEXT,
					isMandatory: true
				}
				if (obj.Type === 'select') {
					obj.DropDown.filter((subObj) => subObj.value == obj.Value).map(objValue => {
						updateValue({ [type]: objValue.name ? { label: objValue.name, value: objValue.value } : null });
					})

					//updateValue({[type]: obj.Value ? {label: obj.Value, value: obj.Value} : null});
				} else {
					updateValue({ [type]: obj.Value });
				}
				if (obj.Type === 'select') {
					obj.DropDown.forEach((subObj) => {
						subObj.label = subObj.name;
					});
					console.log(obj.DropDown)

					temp[type].values = obj.DropDown;
				} else {
					temp[type].inputType = obj.Type === "number" ? "number" : "text";
				}
			});
			setformElements(temp);
			console.log(temp)
			console.log("****************654**********")
			console.log(data)
			setinputData(data);

			data?.Input?.map((item) => {
				let exist = item.Name.toLowerCase();
				if (exist.includes("expected")) {
					SetTemplate(prevState => { return [...prevState, "expected"] })
				} else if (exist.includes("precision")) {
					SetTemplate(prevState => { return [...prevState, "precision"] })
				} else if (exist.includes("error")) {
					SetTemplate(prevState => { return [...prevState, "error"] })
				}
				//SetTemplate([...new Set(endTemplates)])
			})
		}
	}

	/**
	 * on blur validate values
	 */
	const onBlurAction = async (value) => {
		SetChangedField(value)
		if (projectData.SDMSubCode == "ICSCBA_CC" || projectData.SDMSubCode == "DCSS_CC" && value == "R") {
			if (parseFloat(values["R"]) < -1 || parseFloat(values["R"]) > 1) {
				updateError(value, { hasError: true, errorMsg: "Please enter value between -1 & 1" })
				seterror(true)
			} else {
				updateError(value, { hasError: false, errorMsg: "" })
				seterror(false)
			}
		}
		if (value == "OR" && values["OR"] != updatedFieldValue["OR"]) {
			if (updatedFieldValue["OR"] != '') {
				setPopUp(true)
			}
			updateValue({ "OR": values["OR"] })
			console.log(value, updatedFieldValue)
			SetUpdatedValue({ ...updatedFieldValue, "OR": parseFloat(values["OR"]) })

		} else if (value == "P1" && values["P1"] != updatedFieldValue["P1"]) {
			if (updatedFieldValue["P1"] != '') {
				setPopUp(true)
			}
			updateValue({ "P1": values["P1"] })
			SetUpdatedValue({ ...updatedFieldValue, "P1": parseFloat(values["P1"]) })
		} else if (value == "P2" && values["P2"] != updatedFieldValue["P2"]) {
			if (updatedFieldValue["P2"] != '') {
				setPopUp(true)
			}
			updateValue({ "P2": values["P2"] })
			SetUpdatedValue({ ...updatedFieldValue, "P2": parseFloat(values["P2"]) })
		} else {

		}
		//Run this for the first time only
		if (values["P1"] && values["P2"] && !values["OR"]) {
			let p1: number = parseInt(values["P1"]);
			let p2: number = parseInt(values["P2"]);
			let valuep1: number = p1 * (100 - p2);
			let valuep2: number = p2 * (100 - p1);
			console.log(typeof valuep2)
			let OR: number = parseFloat((valuep1 / valuep2).toFixed(2));
			updateValue({ "OR": OR })
		}

		if (value == "SN" || value == "SNP") {
			let SN = parseInt(values["SN"]);
			let SNP = parseInt(values["SNP"]);
			let total = SN + SNP;
			if (total > 100) {
				updateError(value, { hasError: true, errorMsg: "Sum of Expected Sensitivity and Desired Precision For Sensitivity cannot exceed 100" })
			} else {
				updateError("SN", { hasError: true, errorMsg: "" })
				updateError("SNP", { hasError: true, errorMsg: "" })
			}
		} else if (value == "SP" || value == "SPP") {
			let SP = parseInt(values["SP"]);
			let SPP = parseInt(values["SPP"]);
			let total = SP + SPP;
			if (total > 100) {
				updateError(value, { hasError: true, errorMsg: "Sum of Expected Specificity and Desired Precision For Specificity cannot exceed 100" })
			} else {
				updateError("SP", { hasError: true, errorMsg: "" })
				updateError("SPP", { hasError: true, errorMsg: "" })
			}
		}
	}

	/**
	 * onChange odds ratio
	 */
	const onChangeOdds = (e) => {
		SetselectedField(e.target.value);
	}

	const onSubmitAction = () => {
		if (selectedField == "P1") {
			let P2 = parseFloat(values["P2"]);
			let Or: number = parseFloat(updatedFieldValue["OR"]);
			// let P1: number = parseFloat(((Or * P2 * 100) / (100 - P2 + Or * P2)).toFixed(2))
			let P1: number = parseFloat(((100 * Or * P2) / (100 - P2 + (Or * P2))).toFixed(2))

			updateValue({ "P1": P1 })
			SetUpdatedValue({ ...updatedFieldValue, "P1": P1 })

		} else if (selectedField == "P2") {
			let P1 = parseFloat(values["P1"] || 0);
			let Or: number = parseFloat(updatedFieldValue["OR"]);
			// console.log(P1);
			// console.log(Or);
			// let P2: number = parseFloat(((Or * P1 * 100) / (100 - P1 + Or * P1)).toFixed(2))
			let P2: number = parseFloat(((100 * P1) / (Or * 100 - (Or * P1) + P1)).toFixed(2))
			updateValue({ "P2": P2 })
			SetUpdatedValue({ ...updatedFieldValue, "P2": P2 })

		} else if (selectedField == "OR") {
			let p1: number = parseFloat(values["P1"]);
			let p2: number = parseFloat(values["P2"]);
			let valuep1: number = p1 * (100 - p2);
			let valuep2: number = p2 * (100 - p1);
			console.log(typeof valuep2)
			let OR: number = parseFloat((valuep1 / valuep2).toFixed(2));
			updateValue({ "OR": OR })
			SetUpdatedValue({ ...updatedFieldValue, "OR": OR })

		} else {

		}

		setPopUp(false);
	}

	return (
		<div className='study-module-page'>

			<Modal isOpen={popUp}>
				<ModalHeader>
					Select field to be recalculated
				</ModalHeader>
				<ModalBody>
					<div>
						<Radio.Group onChange={(e) => onChangeOdds(e)}>
							{
								changedField == "P1" ?
									<>
										<Radio value={"P2"}>Prevalence of exposure in control group</Radio>
										<Radio value={"OR"}>Expected Odds ratio</Radio>
									</>
									:
									changedField == "P2" ?
										<>
											<Radio value={"P1"}>Prevalence of exposure in case group</Radio>
											<Radio value={"OR"}>Expected Odds ratio</Radio>
										</>
										:
										<>
											<Radio value={"P1"}>Prevalence of exposure in case group</Radio>
											<Radio value={"P2"}>Prevalence of exposure in control group</Radio>
										</>
							}

						</Radio.Group>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button className='submit' onClick={() => onSubmitAction()} style={{ backgroundColor: "blue" }}>Submit</Button>
					<Button className="cancel" onClick={() => setPopUp(false)}>Close</Button>
				</ModalFooter>
			</Modal>

			<Row className='page-header m-0'>
				<Col className="p-0" md={12}>
					<ArrowLeftOutlined style={{ color: "#fff", fontSize: "24px", margin: "0px 10px" }} onClick={() => navigate("/project")} />
					<span className="project-title">
						{projectData?.TopicDesc || projectData?.topicdesc}
					</span>
				</Col>
				<Col className='p-0'></Col>
			</Row>
			<Tabs data={TabsData} selectedTab={1} projectData={projectData} />

			<div className="question-video-head">
				<div className="question-text font-weight-bold" style={{ textAlign: "left" }}>{Samplesize}</div>
				<div style={{ textAlign: "left", marginLeft: '2%', marginTop: '1%' }}><a href={SSV1} target="_blank">{SSV1_name}</a></div>
				<div style={{ textAlign: "left", marginLeft: '2%' }}><a href={SSV2} target="_blank">{SSV2_name}</a></div>
				<div style={{ textAlign: "left", marginLeft: '2%' }}><a href={SSV3} target="_blank">{SSV3_name}</a></div>
			</div>

			{inputData ?
				<div>
					<div className='questions-container'>
						<div className='sample-text'>It seems you are undertaking a "{undertakingData?.Name || inputData?.SubName}". Proceed for sample size calculation by filling the below fields.</div>
						<Row>
							{Object.keys(formElements).map((obj, index) => (

								<Col key={index} md={4}>
									<FieldItem
										{...formElements[obj]}
										value={values[obj]}
										onChange={(...e) => handleChange(formElements[obj].name, ...e)}
										touched={fields[obj] && fields[obj].hasError}
										error={fields[obj] && fields[obj].errorMsg}
										onBlur={() => onBlurAction(formElements[obj].name)}
									/>
								</Col>
							))}

						</Row>
						{
							<>
								<div style={{ textAlign: "center" }}>{QUESTIONHEAD}</div>
								{endTemplates && endTemplates.includes("precision") ? <div style={{ textAlign: "center" }}><a href={DASLINK1} target="_blank">{DASTITLE1}</a></div> : ""}
								{/* {endTemplates && endTemplates.includes("expected") ? <div style={{ textAlign: "center" }}><a href={DASLINK2} target="_blank">{DASTITLE2}</a></div> : ""} */}
								{endTemplates && endTemplates.includes("error") ? <div style={{ textAlign: "center" }}><a href={NDASLINK1} target="_blank">{NDASTITLE}</a></div> : ""}

							</>

						}
					</div>
					<div className='footer-container'>
						<div className='w-100 d-flex justify-content-end'>
							<div className='mr-3'>
								{permissionParams.previous == "allowed" || Object.keys(permissionParams).length == 0 ?
									<CustomButton type="alert-primary" onClick={() => previous()} className="w-100" text="Previous"></CustomButton>
									: ""
								}
							</div>
							{
								error ? ""
									:
									permissionParams.save == "allowed" || Object.keys(permissionParams).length == 0 ?
										<CustomButton type="primary" onClick={() => next()} className="w-100" text="Next"></CustomButton>
										: ""

							}
						</div>
					</div>
				</div> :
				undertakingData ?
					<div>
						<div className='questions-container'>
							<div className='question-text font-weight-bold'>It seems like you are undertaking a "{undertakingData.Name}". Select the type of outcome/exposure from below</div>
							<div className="field-items">
								<div className='form-group'>
									<div className="custom-radio row">
										{undertakingData.SubData?.map((subObj, subIndex) => (
											<div key={subIndex} className="align-center mb-2 pl-3 pr-3">
												<img src={`${process.env.PUBLIC_URL}/images/${undertakingData.selectedAnswer === subObj.SubCode ? "radio_selected" : "radio_idle"}.svg`} alt="radio" onClick={() => onUndertakingChange(subObj)} />
												<Label check>{subObj.SubName}</Label>
											</div>
										))}
									</div>
								</div>
								{/* {

									undertakingData?.subData && undertakingData?.subData[0].SubName == "Numerical Outcome" ?
										<>
											<div style={{ textAlign: "center" }}>{QUESTIONHEAD}</div>
											<div style={{ textAlign: "center" }}><a href={DCSS1Link} target="_blank">{DCSS1TITLE}</a></div>
										</>
										:""									
								} */}
								{undertakingData.Code == "DCSS_3" ?
									<>
										<div style={{ textAlign: "center" }}>{QUESTIONHEAD}</div>
										<div style={{ textAlign: "center" }}><a href={CROSSSECTIONSTUDYLINK} target="_blank">{CROSSSECTIONSTUDYTITLE}</a></div>
									</>
									:
									undertakingData.Code == "CCS" ?
										<>
											<div style={{ textAlign: "center" }}>{QUESTIONHEAD}</div>
											<div style={{ textAlign: "center" }}><a href={CASECONTROLLINK} target="_blank">{CASECONTROLTITLE}</a></div>
											<div style={{ textAlign: "center" }}><a href={DCSS1Link} target="_blank">{DCSS1TITLE}</a></div>
										</>
										:
										undertakingData.Code == "RCT" ?
											<>
												<div style={{ textAlign: "center" }}>{QUESTIONHEAD}</div>
												<div style={{ textAlign: "center" }}><a href={RANDOMCONTROLLINK} target="_blank">{RANDOMCONTROLTITLE}</a></div>
												<div style={{ textAlign: "center" }}><a href={DCSS1Link} target="_blank">{DCSS1TITLE}</a></div>
											</>
											: ""
								}

								{
									isTypeVariable && isTypeVariable.length == 3 && undertakingData.Code != "RCT" && !undertakingData.Name.toLowerCase().includes("cohort") ?
										<>
											<div style={{ textAlign: "center" }}>{QUESTIONHEAD}</div>
											<div style={{ textAlign: "center" }}><a href={DCSS1Link} target="_blank">{DCSS1TITLE}</a></div>
										</> : ""
								}
								{
									undertakingData.Name.toLowerCase().includes("cohort") && isTypeVariable && isTypeVariable.length == 3 ?
										<>
											<div style={{ textAlign: "center" }}>{QUESTIONHEAD}</div>
											<div style={{ textAlign: "center" }}><a href={COHORTSTUDYLINK} target="_blank">{COHORTSTUDYTITLE}</a></div>
											<div style={{ textAlign: "center" }}><a href={DCSS1Link} target="_blank">{DCSS1TITLE}</a></div>
										</>
										:
										undertakingData.Name.toLowerCase().includes("cohort") ?
											<>
												<div style={{ textAlign: "center" }}>{QUESTIONHEAD}</div>
												<div style={{ textAlign: "center" }}><a href={COHORTSTUDYLINK} target="_blank">{COHORTSTUDYTITLE}</a></div>
											</>
											:
											""

								}
								{
									undertakingData.Code == "DAS" ?
										<>
											<div style={{ textAlign: "center" }}>{QUESTIONHEAD}</div>
											<div style={{ textAlign: "center" }}><a href={DASLINK1} target="_blank">{DASTITLE1}</a></div>
											<div style={{ textAlign: "center" }}><a href={DASLINK2} target="_blank">{DASTITLE2}</a></div>
										</>
										:
										""
								}
							</div>
						</div>
						<div className='footer-container'>
							<div className='w-100 d-flex justify-content-end'>
								{permissionParams.previous == "allowed" || Object.keys(permissionParams).length == 0 ?
									<CustomButton type="alert-primary" onClick={() => { getQuestionData('Q1', 0, []); setundertakingData(null); changeLast(); setformElements({}); updateValue({}); resetQdata(); }} className="w-100" text="Previous"></CustomButton>
									: ""
								}
							</div>
						</div>
					</div> :
					<div className='questions-container'>
						{questionsData.map((obj, index) => (
							<div key={index} className="field-items">
								{obj.Answers && <><Step step={index + 1} text='Question' /></>}

								{obj.Answers && <div className='question-text'>{obj.Question}</div>}
								{!obj.Answers && <div className='note-text'>{obj.Question}</div>}
								<div className='form-group'>
									<div className="custom-radio row">
										{obj.Answers?.map((subObj, subIndex) => (
											<div key={subIndex} className="align-center mb-2 pl-3 pr-3">
												<img src={`${process.env.PUBLIC_URL}/images/${obj.selectedAnswer === subObj.AnswerId ? "radio_selected" : "radio_idle"}.svg`} alt="radio" onClick={() => onChange(index, subObj)} />
												<Label check>{subObj.Answer}</Label>
											</div>
										))}
									</div>
									{
										QUESTIONSLIST.map((item, i) => (
											item.key.map(items => (
												obj.Question.toLowerCase().includes(items.toLowerCase()) ?
													<>
														<div>
															{QUESTIONHEAD}
															<a href={item.link} target={"_blank"}>{item.title}</a>
														</div>
													</>
													:
													""
											))
										))
									}
									{/* {index + 1 == 1 ? <><div>{QUESTIONHEAD} <a href={QUESTION1LINK} target={"_blank"}>{QUESTION1TITLE}</a></div></>
										:
										index + 1 == 2 ?
											<><div>{QUESTIONHEAD} <a href={QUESTION2LINK} target={"_blank"}>{QUESTION2TITLE}</a>	</div></>
											:
											index + 1 == 3 ?
												<><div>{QUESTIONHEAD} <a href={QUESTION3LINK} target={"_blank"}>{QUESTION3TITLE}</a>	</div></>
												: index + 1 == 4 ?
													<><div>{QUESTIONHEAD} <a href={QUESTION4LINK} target={"_blank"}>{QUESTION4TITLE}</a>	</div></>
													:
													index + 1 == 6 ?
														<><div>{QUESTIONHEAD} <a href={QUESTION6LINK} target={"_blank"}>{QUESTION6TITLE}</a>	</div></>
														:
														index + 1 == 7 ?
															<><div>{QUESTIONHEAD} <a href={QUESTION7LINK} target={"_blank"}>{QUESTION7TITLE}</a>	</div></>
															:
															""
									} */}
								</div></div>
						))}
					</div>
			}
		</div>
	)
}
