import { FIELD_TYPES } from "../../widgets/fields";

export const FormElements = {
    mobileNumber: {
        name: "mobileNumber",
        placeholder: "Phone Number",
        label: "Phone",
        isMandatory: false,
        icon: "phone",
        regex: /^\+?[0-9]{1,3}-?[0-9]{3,14}$⁠/,
        minLength: 3,
        maxLength: 14,
        messages: {
            regex: 'Invalid format',
            minLength: 'Phone number should be 10 digits'
        }
    },
    email: {
        name: "email",
        placeholder: "Email",
        label: "Email",
        isMandatory: true,
        icon: "email",
        regex: /\S+@\S+\.\S+/,
        messages: {
            regex: 'Email is not valid',
        }
    },
    displayName: {
        name: "displayName",
        placeholder: "Name",
        label: "Display Name",
        isMandatory: true,
        icon: "profile"
    },
    password: {
        name: "password",
        placeholder: "Password",
        label: "Password",
        isMandatory: true
    },
    confirmPassword: {
        name: "confirmPassword",
        placeholder: "Confirm Password",
        label: "confirmPassword",
        isMandatory: true
    },
    termsConditions: {
        name: "termsConditions",
        type:FIELD_TYPES.CHECK_BOX,
        values:[{label:"I agree to Terms & Condition",value:"accept"}],
        isMandatory: true,
        isTerms:true
    },
    accountType: {
        name: "accountType",
        placeholder: "Select Account Type",
        label: "Account Type",
        type: FIELD_TYPES.DROP_DOWN,
        isMandatory: true
    }
    
};