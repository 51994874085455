import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '../store'
import { RootState } from '../rootReducer';
import AjaxUtil from '../../axios/axios';

// interfaces
export interface AuthError {
    message: string
}

export interface AuthState {
    isAuth: boolean
    currentUser?: CurrentUser,
    currentChat?: CurrentChat,
    isLoading: boolean
    error: AuthError
}

export interface CurrentUser {
    EmailID: string
    CashbackAmount: string
    FullName: string
    MobileNumber: string
    PGCourse: string
    PackageID: string
    ReferralCode: string
    UserNumber: string
    UserStatus: string
    UserType: string
}

export interface CurrentChat{
    id: string,
    name:string,
    userdp:string
}

export const initialState: AuthState = {
    isAuth: false,
    isLoading: false,
    error: {message: ''},
}

// slice
export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setLoading: (state, {payload}: PayloadAction<boolean>) => {
            state.isLoading = payload
        },
        setAuthSuccess: (state, { payload }: PayloadAction<CurrentUser>) => {
            state.currentUser = payload
            state.isAuth = true
            startRefreshTokenTimer();
        },
        setProjectData:(state, { payload }: PayloadAction<CurrentChat>) => {
            state.currentChat = payload
            state.isAuth = true
        },
        setLogOut: (state) => {
            state.isAuth = false
            state.currentUser = undefined
        },
        setAuthFailed: (state, { payload }: PayloadAction<AuthError>) => {
            state.error = payload
            state.isAuth = false
        },
    },
})
// actions
export const { setAuthSuccess, setLogOut, setLoading, setAuthFailed} = authSlice.actions;

// async methods thunk
export const login = (userName, password): AppThunk => async (dispatch, getState) => {
    try {
        dispatch(setLoading(true));
        const currentUser = await AjaxUtil.sendRequest('login', {"username":userName,"password": AjaxUtil.encryptData(password) }, false, {isAuth: true, method: 'post', showError: true} );
        dispatch(setAuthSuccess(
            {  ...currentUser.data }
        ));
    } catch (error) {
        dispatch(setAuthFailed({message: "error"}))
    } finally {
        dispatch(setLoading(false))
    }
}

export const logOut_thunk = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setLoading(true))
        await fetch("https://reqres.in/api/products/3");
        dispatch(setLogOut());
    } catch (error) {
        dispatch(setAuthFailed({message: "error"}))
    } finally {
        dispatch(setLoading(false))
    }
}

const startRefreshTokenTimer = () => {
    // set a timeout to refresh the token a minute before it expires
    let expireDate: any = localStorage.getItem("coguide_expire");
    const expires = new Date(expireDate);
    if(expires.getTime()<Date.now()){
        logout();
    }
    const timeout = expires.getTime() - Date.now() - (60 * 1000);
    setTimeout(() => {
        refreshToken();
    }, timeout);
    const refreshToken = async() => {
        let response  = await AjaxUtil.sendRequest('refresh_token', {}, false, {method: 'get', showError: true} );
        localStorage.setItem("coguide_token",response['token']);
        localStorage.setItem("coguide_expire",response['expire']);
        startRefreshTokenTimer();
    }
}

const logout = () => {
    // remove user from local storage to log user out
    localStorage.removeItem('coguide_token');
    localStorage.removeItem('coguide_expire');
    localStorage.removeItem("coguide_user");
}

//selector
export const authSelector = (state: RootState) => state.auth;

//default export
export default authSlice.reducer;

// https://blog.logrocket.com/using-typescript-with-redux-toolkit/