import { LeftOutlined } from "@ant-design/icons";
import moment from "moment";
import { useEffect, useState } from "react";
import { Card, Col, Image, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, CardTitle } from "reactstrap";
import { CurrentUser, setLoading } from '../../store/slices/auth';
import { showToaster, toasterTypes } from "../../widgets";
import { getProfileDetails } from '../profile/apiController';
import { sendUserInvite } from "./apiController";



export default function PeopleProfileMobile(){
    const dispatch = useDispatch();
    const navigate = useNavigate();	
	const [profile, setprofile] = useState<any | null>(null);
    const location = useLocation();
    const [userId, SetUserId] = useState<any>(location.state);

    // get userId
    //let userId = location?.state;



  //  const value = userid?.state?.id;
    //const {id} = state.id;
    useEffect(() =>{  
        console.log(userId.projectId);
      
        //console.log("state:::",userid?.state?.id);
        fetchProfileDetails(location.state && location.state);
    },[]);

    /**
	 * fetch profile data
	 */
	const fetchProfileDetails = async (userid) => {
		try {
			dispatch(setLoading(true));
			let response = await getProfileDetails(userid);
			setprofile(response);
			dispatch(setLoading(false));
		} catch (e: any) {
			dispatch(setLoading(false));
		}
	}


     /**
     * send invite
     * get inputs from location 
     */
      const sudmitInvite = async ()=>{
       console.log(userId.projectId.projectid);
        try{
            dispatch(setLoading(false));
            const response = await sendUserInvite({invitee_email:userId.data.emailid,invitee_role:userId.usertype,invitee_name:"",project_id:{projectid : userId.projectId.projectid},invitee_id:userId.data.id});
            console.log(response) 
            dispatch(setLoading(false));

            if(response["success"]){
                dispatch(setLoading(false));
                 navigate("/invitesuccess");
             }else{
                dispatch(setLoading(false));

                 showToaster(toasterTypes.INFO,response["message"])
             }
        }catch(e:any){
            throw e;
        }
      
    }

    return(
        <>
            
            <div className="people-profile-container-mob">
            <div className="info-bar">
                    <LeftOutlined className="arrow-back" onClick={() => navigate("/peopleinvitemobile",{state:userId.projectId})}/>
                        <span className="info-project-detail"> Project Details</span>
                    </div>
                
                <div className="people-profile-data">
                    <div className="contact-skill">
                        <Row>                        
                            <Col md={6}>
                                    <Card className="contact-card">
                                        <div className='card-container'>
                                     
                                                <div className='profile-container-mob'>
                                                    <img className='profile-image-mob' alt='Profile' src={profile && profile?.photo_link || `${process.env.PUBLIC_URL}/images/logo.png`} />
                                                    <div className="profile-detail-data-mob">
                                                        <span className="profile-name-mob">{profile?.first_name} {profile?.last_name}</span>
                                                        <span className="profile-prof-mob"></span>
                                                    </div>
                                                </div>                                            
                                                 
                                                <div className='contact-details'>
                                                    <div>
                                                        <i className='icon-email'></i>
                                                        <span className='profile-data'>{profile?.emailid}</span>
                                                    </div>
                                                    <div>
                                                        <i className='icon-phone'></i>
                                                        <span className='profile-data'>{profile?.mobilenumber}</span>
                                                    </div>                                               
                                                        <div className='mt-2'>
                                                            <i className='icon-email'></i>
                                                            <span className='profile-data'></span>
                                                        </div>                                                
                                                        <div>
                                                            <i className='icon-phone'></i>
                                                            <span className='profile-data'></span>
                                                        </div>                                                
                                                </div>
                                            
                                        </div>
                                        </Card>
                                    </Col>
                                    
                                    <Col md={6}>
                                        <div className='card-container'>
                                            <Row className='m-0 mb-3'>
                                                <Col className='p-0'>
                                                    <Card className="skillset-card">
                                                        <CardTitle>Skill Set</CardTitle>
                                                        {profile?.skills ? 
                                                            <div className="skillsets">
                                                                    <span className="skill">Experties1</span>
                                                                    <span className="skill">Experties2</span>
                                    
                                                            </div>
                                                        : 
                                                            <span>No Skills</span>
                                                    }
                                                    </Card>
                                                </Col>                                            
                                            </Row>                                    
                                        </div>
                                    </Col>
                        </Row>
                    </div>
                    <Card className="work-exp-card">
                        <CardTitle>Work Experience</CardTitle>
                        <div className="work-exp-data">
                            {profile?.work_details ? "" :"No Data"}
                        {profile?.work_details?.map((obj, index) => (

                            <Row className="row-work">
                                <Col md={6}>
                                <div className='work-data'>
                                    <div className='logo-border'>
                                        <img src={`${process.env.PUBLIC_URL}/images/icon_skill.svg`} alt='logo' className='logo' />
                                    </div>
                                    <div>
                                        <div className='work-name'>{obj.company_name}, <span className="designation-text">Designation</span></div>
                                        <div className='work-desc'>{obj.job_description}</div>
                                        <div className='work-rating'>{moment(obj.start_month + ' ' + obj.start_year).format('MMM yyyy')} to {obj.end_month === 'till_date' ? 'Till Date' : moment(obj.end_date).format('MMM yyyy')}</div>
                                        <div className='work-city'>{obj?.location?.city}, {obj?.location?.state}, {obj?.location?.country}</div>
                                    </div>
                                </div>
                                </Col>

                                <Col md={6}>
                                    <div className="work-info">
                                            <span className='work-city'>Descriptions/ Activities/ Links</span>
                                            <p></p>
                                            <a href="https://reaptest.coguide.com">https://reaptest.coguide.com</a>
                                    </div>
                                </Col>
                                <hr></hr>
                            </Row>

                            ))}
                            
                            <hr></hr>

                        </div>
                    </Card>

                    <Card className="work-exp-card">
                        <CardTitle>Education</CardTitle>
                        <div className="work-exp-data">
                            {profile?.education_details ? "":"No Data"}
                        {profile?.education_details?.map((obj, index) => (

<Row className="row-work">
    <Col md={6}>
    <div className='work-data'>
        <div className='logo-border'>
            <img src={`${process.env.PUBLIC_URL}/images/icon_skill.svg`} alt='logo' className='logo' />
        </div>
        <div>
            <div className='work-name'>{obj.institute} <span className="designation-text">Designation</span></div>
            <div className='work-desc'>{obj.description}</div>
            <div className='work-rating'>{moment(obj.start_month + ' ' + obj.start_year).format('MMM yyyy')} to {obj.end_month === 'till_date' ? 'Till Date' : moment(obj.end_date).format('MMM yyyy')}s</div>
            <div className='work-city'>{obj?.location?.city}, {obj?.location?.state}, {obj?.location?.country}</div>
        </div>
    </div>
    </Col>

    <Col md={6}>
        <div className="work-info">
                <span className='work-city'>Descriptions/ Activities/ Links</span>
                <p></p>
                <a href="https://reaptest.coguide.com">https://reaptest.coguide.com</a>
    </div>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     </Col>
    <hr></hr>
    </Row>  
))}
  
                            
                            <hr></hr>

                       
                        </div>
                    </Card>

                    <Card className="research-card">
                        <CardTitle>Research and Publication</CardTitle>
                        <div className="research-data">
                           <div className="research-item">                                                   
                              <Image className="research-doc" src={`${process.env.PUBLIC_URL}/images/icon_skill.svg`}/>
                              <span></span>
                           </div>
                           <div className="research-item">                                                          
                              <Image className="research-doc" src={`${process.env.PUBLIC_URL}/images/icon_skill.svg`}/>
                              <span></span>
                           </div>
                        </div>
                    </Card>
                </div>
                <Button className="invite-btn" onClick={() => sudmitInvite()}>Invite</Button>
            </div>
        </>
    );
}