import React, { useMemo, useState } from 'react'
import { Col, Row } from 'reactstrap'
import { FieldItem } from '../../widgets/fields'
import useFieldItem from '../../widgets/fields/UseFieldItem'
import { ObjectiveElements } from './FormElements'
import Step from '../common/components/Step'

export default function ObjectiveGeneration(props) {
    const initialValues = useMemo(() => ({ phrase: '', trying: '', interventionGroup: '', comparatorGroup: '', studyPopulation: '', planning: '', timeline: '' }), []);
	const [values, fields, handleChange] = useFieldItem(ObjectiveElements, initialValues, { onValueChange });
    const [objectiveData, setobjectiveData] = useState([]);

    function onValueChange (name, value, values, fieldValues) {
        checkValues(name, value);
    }

    const checkValues = (name, value) => {
        values[name] = value;
        let topicDescData: any = [];
        if(values.phrase && values.trying && values.studyPopulation) {
            if(values.interventionGroup === '' && values.comparatorGroup === '') {
                topicDescData = [{
                    data: `${values.phrase} the ${values.trying} ${values.studyPopulation} among ${values.planning && values.timeline ? 'using ' + values.planning + ' during ' + values.timeline : ''}`
                }, {
                    data: `${values.phrase} the incidence/prevalence/proportion of ${values.trying} among ${values.studyPopulation} ${values.planning && values.timeline ? 'using ' + values.planning + ' during ' + values.timeline : ''}`
                }]
            } else if(values.interventionGroup === '') {
                topicDescData = [{
                    data: `${values.phrase} the ${values.trying} between ${values.studyPopulation} and ${values.comparatorGroup} ${values.planning && values.timeline ? 'using ' + values.planning + ' during ' + values.timeline : ''}`
                }, {
                    data: `${values.phrase} the incidence/prevalence/proportion of ${values.trying} between ${values.studyPopulation} and ${values.comparatorGroup} ${values.planning && values.timeline ? 'using ' + values.planning + ' during ' + values.timeline : ''}`
                }]
            } else if(values.comparatorGroup === '') {
                topicDescData = [{
                    data: `${values.phrase} the ${values.trying} between ${values.studyPopulation} and ${values.interventionGroup} ${values.planning && values.timeline ? 'using ' + values.planning + ' during ' + values.timeline : ''}`
                }, {
                    data: `${values.phrase} the incidence/prevalence/proportion of ${values.trying} between ${values.studyPopulation} and ${values.interventionGroup} ${values.planning && values.timeline ? 'using ' + values.planning + ' during ' + values.timeline : ''}`
                }]
            } else {
                topicDescData = [{
                    data: `${values.phrase} the ${values.trying} between ${values.interventionGroup} and ${values.comparatorGroup} among ${values.studyPopulation} ${values.planning && values.timeline ? 'using ' + values.planning + ' during ' + values.timeline : ''}`
                }, {
                    data: `${values.phrase} the incidence/prevalence/proportion of ${values.trying} between ${values.interventionGroup} and ${values.comparatorGroup} among ${values.studyPopulation} ${values.planning && values.timeline ? 'using ' + values.planning + ' during ' + values.timeline : ''}`
                }, {
                    data: `${values.phrase} the efficiency/safety between ${values.interventionGroup} and ${values.comparatorGroup} among ${values.studyPopulation} ${values.planning && values.timeline ? 'using ' + values.planning + ' during ' + values.timeline : ''}`
                }]
            }
        }
        setobjectiveData(topicDescData);
    }

    return (
        <Row className='m-0 generation-page'>
            <Col className='p-0 left-col' md={8}>
                <div className='generation-text'>Objective Generation</div>
                <Step step={1} />
                <div className='form-container'>
                    <FieldItem
                        {...ObjectiveElements.phrase}
                        value={values.phrase}
                        onChange={(...e) => handleChange(ObjectiveElements.phrase.name, ...e)}
                        touched={fields.phrase && fields.phrase.hasError}
                        error={fields.phrase && fields.phrase.errorMsg}
                    />
                </div>
                {values.phrase &&
                    <div>
                        <Step step={2} />
                        <div className='form-container'>
                            <FieldItem
                                {...ObjectiveElements.trying}
                                value={values.trying}
                                label= {`What are you trying to ${values.phrase}?`}
                                onChange={(...e) => handleChange(ObjectiveElements.trying.name, ...e)}
                                touched={fields.trying && fields.trying.hasError}
                                error={fields.trying && fields.trying.errorMsg}
                            />
                        </div>
                    </div>
                }
                {values.phrase && values.trying &&
                    <div>
                        <Step step={3} />
                        <div className='form-container'>
                            <Row>
                                <Col md={6}>
                                    <FieldItem
                                        {...ObjectiveElements.interventionGroup}
                                        value={values.interventionGroup}
                                        onChange={(...e) => handleChange(ObjectiveElements.interventionGroup.name, ...e)}
                                        touched={fields.interventionGroup && fields.interventionGroup.hasError}
                                        error={fields.interventionGroup && fields.interventionGroup.errorMsg}
                                    />
                                </Col>
                                <Col md={6}>
                                    <FieldItem
                                        {...ObjectiveElements.comparatorGroup}
                                        value={values.comparatorGroup}
                                        onChange={(...e) => handleChange(ObjectiveElements.comparatorGroup.name, ...e)}
                                        touched={fields.comparatorGroup && fields.comparatorGroup.hasError}
                                        error={fields.comparatorGroup && fields.comparatorGroup.errorMsg}
                                    />
                                </Col>
                            </Row>
                            <FieldItem
                                {...ObjectiveElements.studyPopulation}
                                value={values.studyPopulation}
                                onChange={(...e) => handleChange(ObjectiveElements.studyPopulation.name, ...e)}
                                touched={fields.studyPopulation && fields.studyPopulation.hasError}
                                error={fields.studyPopulation && fields.studyPopulation.errorMsg}
                            />
                        </div>
                    </div>
                }
                {values.studyPopulation &&
                    <div>
                        <Step step={4} />
                        <div className='form-container'>
                            <Row>
                                <Col md={6}>
                                    <FieldItem
                                        {...ObjectiveElements.planning}
                                        value={values.planning}
                                        onChange={(...e) => handleChange(ObjectiveElements.planning.name, ...e)}
                                        touched={fields.planning && fields.planning.hasError}
                                        error={fields.planning && fields.planning.errorMsg}
                                    />
                                </Col>
                                <Col md={6}>
                                    <FieldItem
                                        {...ObjectiveElements.timeline}
                                        value={values.timeline}
                                        onChange={(...e) => handleChange(ObjectiveElements.timeline.name, ...e)}
                                        touched={fields.timeline && fields.timeline.hasError}
                                        error={fields.timeline && fields.timeline.errorMsg}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </div>
                }
                <div className='cancel-text' onClick={() => props.onCancel()}>Cancel</div>
            </Col>
            <Col className='p-0 right-col' md={4}>
                <div className='suggestions-text'>Suggestions</div>
                {objectiveData.length === 0 &&
                    <div className='empty-container'>
                        <div className='empty-text'>Hold tight we are processing<br />Your inputs</div>
                    </div>
                }
                {objectiveData.map((obj: any, index) => (
                    <div key={index} className='title-container' onClick={() => props.onObjective(obj.data)}>
                        <div className='title-text'>Objective {index + 1}<span className='display-image'><i className='icon icon-front_arrow' /></span></div>
                        <div className='title-value'>{obj.data}</div>
                    </div>
                ))}
            </Col>
        </Row>
    )
}
