import React from 'react'

export default function Step(props) {
  return (
    <div className='step-container'>
        <div className='step-text'>{props.text || Step} {props.step}</div>
        <div className='step-line'></div>
    </div>
  )
}
