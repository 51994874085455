import React from 'react'
import { Col } from 'react-bootstrap';
import { Row } from 'reactstrap';

export default function Tabs(props) {
	const { data, selected } = props;
	return (
		<div className='tabs-container-mob'>
			{data.map((obj, index) => (
				
					<div className={`${selected === index ? 'tab-item-select' : 'tab-item'}`} key={index} onClick={() => props.onTab(index)}>
						<div className={`${selected === index ? 'font-open-sans-bold' : 'font-open-sans-semi-bold'} tab-title`}>{obj.title}</div>

						<div className={`${selected === index ? 'font-open-sans-semi-bold' : 'font-open-sans-regular'} tab-subtitle`}>{obj.subTitle}</div>
					</div>
			
			))}
		</div>
	)
}
