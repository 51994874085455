import { useEffect } from "react";
import { Input, InputGroup, InputGroupText } from "reactstrap"

export const ColumnFilter = ({ column }) => {
    const { filtervalue, setFilter } = column;

    useEffect(() =>{
        console.log(column)
    },[])
    
    return (
        <span>
            <Input
                className="search"
                style={{ height: "20px", fontSize:"12px", margin:"2px 0px" }}
                value={filtervalue}
                placeholder="Search"
                onChange={(e) => setFilter(e.target.value)}
            />
        </span>
    )
}